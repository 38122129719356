import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeactivateGuardService } from './modules/shared/services/deactivate-guard.service';

const routes: Routes = [
  {
    path: 'admin',
    canDeactivate: [DeactivateGuardService],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('./modules/backend/backend.module').then((m) => m.BackendModule)
  },
  {
    path: '',
    loadChildren: () => import('./modules/frontend/frontend.module').then((m) => m.FrontendModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
