import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styles: []
})
export class DialogComponent<T> implements AfterViewInit {
  @Input() options = new DialogBoxOptions();
  @Output() okPressed = new EventEmitter<void>();
  @Output() cancelPressed = new EventEmitter<void>();

  @ViewChild('content', { read: ViewContainerRef }) container;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  componentRef: any = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    public dialogService: DialogService
  ) {}

  ngAfterViewInit() {
    this.container.clear();
    const factory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(
      this.options.component
    );
    this.componentRef = this.container.createComponent(factory);
    const nativeElement = this.componentRef.location.nativeElement;
    for (const key in this.options.styles) {
      if (this.options.styles.hasOwnProperty(key)) {
        nativeElement.style[key] = this.options.styles[key];
      }
    }
    for (const key in this.options.inputs) {
      if (this.options.inputs.hasOwnProperty(key)) {
        this.componentRef.instance[key] = this.options.inputs[key];
      }
    }
    this.componentRef.instance.parentRef = this;
    this.changeDetectorRef.detectChanges();
  }

  pressOK() {
    if (this.componentRef.instance.onBeforeOK && !this.componentRef.instance.onBeforeOK()) {
      return;
    }

    if (this.componentRef.instance.onExit) {
      this.componentRef.instance.onExit();
    }
    this.okPressed.emit();
  }

  pressCancel() {
    if (this.componentRef.instance.onBeforeCancel && !this.componentRef.instance.onBeforeCancel()) {
      return;
    }

    if (this.componentRef.instance.onExit) {
      this.componentRef.instance.onExit();
    }
    this.cancelPressed.emit();
  }

  setTitle(title: string) {
    this.options.title = title;
  }

  scrollToTop() {
    this.scrollContainer?.nativeElement?.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
  }

  useFullHeight() {
    this.options.fullHeight = true;
    this.changeDetectorRef.detectChanges();
  }

  hideDialog() {
    if (this.options.cancelText) {
      this.pressCancel();
    } else {
      this.pressOK();
    }
  }
}

export interface OnBeforeCancel {
  onBeforeCancel: () => boolean;
}

export interface OnBeforeOK {
  onBeforeOK: () => boolean;
}

export class DialogBoxOptions {
  component: Type<any>;
  inputs: any;
  active = true;
  title = '';
  message = '';
  fullHeight = false;
  cancelText = null;
  okText = 'OK';
  styles = {};
}
