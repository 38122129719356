import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isBrowser: boolean;
  title = 'JoshsFrogsClient';
  staging = environment.staging;

  constructor(@Inject(PLATFORM_ID) private platformId: object, private router: Router) {}
  ngOnInit() {
    this.isBrowser = isPlatformBrowser(this.platformId);

    /*
    this.router.events.subscribe((event) => {
      // Track page views when the NavigationEnd event occurs
      if (event instanceof NavigationEnd) {
        if (this.isBrowser) {
          if (window.location.hostname === 'joshsfrogs.com') {
            // @ts-ignore
            rg4js('trackEvent', {
              type: 'pageView',
              path: event.url
            });
          }
        }
      }

      // Track navigation errors when the NavigationError event occurs
      if (event instanceof NavigationError) {
        // Track navigation error
        if (this.isBrowser) {
          if (window.location.hostname === 'joshsfrogs.com') {
            // @ts-ignore
            rg4js('send', {
              error: event.error
            });
          }
        }
      }
    });
    */
  }
}
