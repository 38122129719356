<div id="drop-zone"
     class="p-3 bg-gray-100 border-2 border-dashed border-gray-300 rounded-lg flex justify-center content-center flex-col relative overflow-hidden"
     [ngClass]="{'drag-over': dragIsActive, 'border-red-600' : error}"
     [ngStyle]="{'max-height': maxHeight, 'min-height': minHeight}"
     (drop)="onDrop($event)" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)"
     (dragover)="onDragOver($event)">
  <app-alert [error]="error" innerClass="mb-4 rounded-t-lg absolute top-3 right-3 left-3" [closeable]="false"></app-alert>

  <input type="file" class="file-input hidden" [accept]="filePickerFilter" (change)="onFileSelected($event)" #fileUpload>

  <div class="text-lg text-center flex flex-col min-h-0">
    <div class="pb-2">
      Drag image here or <button type="button" class="link-jf font-bold" (click)="fileUpload.click()">select a file</button>
    </div>
    <img [src]="previewUrl" [alt]="fileName" class="m-auto w-auto min-h-0" style="max-height: 350px;">
    <div *ngIf="!fileName" class="text-base font-extralight text-gray-600">
      No file uploaded yet.
    </div>
    <div *ngIf="fileName" class="text-base break-words filename">
      {{fileName}}
    </div>
  </div>
</div>
