<form [formGroup]="form" class="text-left">
  <app-alert [error]="error" innerClass="mb-4"></app-alert>
  <div class="grid grid-cols-1 gap-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 text-sm">
    <div class="col-span-1">
      <label for="title" class="input-label">Title</label>
      <input type="text" id="title" formControlName="title" class="input-jf" placeholder="Title">
    </div>

    <div class="col-span-1">
      <label for="keywords" class="input-label">Keywords</label>
      <input type="text" id="keywords" formControlName="keywords" class="input-jf" placeholder="Keywords">
    </div>

    <div class="col-span-1 sm:col-span-2">
      <span class="input-label">Preview Size</span>

      <div class="flex gap-3 flex-wrap mt-2.5 text-xs">
        <div class="flex items-center">
          <input [(ngModel)]="imageSize"  [value]="0" id="small-display" name="image-size" type="radio"
                 [ngModelOptions]="{standalone: true}"
                 class="form-radio input-jf h-4 w-4">
          <label for="small-display" class="cursor-pointer pl-1.5">Small</label>
        </div>
        <div class="flex items-center">
          <input [(ngModel)]="imageSize" [value]="1" id="medium-display" name="image-size" type="radio"
                 [ngModelOptions]="{standalone: true}"
                 class="form-radio input-jf h-4 w-4">
          <label for="medium-display" class="cursor-pointer pl-1.5">Medium</label>
        </div>
        <div class="flex items-center">
          <input [(ngModel)]="imageSize" [value]="2" id="large-display" name="image-size" type="radio"
                 [ngModelOptions]="{standalone: true}"
                 class="form-radio input-jf h-4 w-4">
          <label for="large-display" class="cursor-pointer pl-1.5">Large</label>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="rounded-lg shadow-md border border-gray-300 overflow-auto my-4">
  <table class="w-full h-full">
    <thead>
    <tr class="text-gray-700 overflow-hidden border-b border-gray-300 bg-gray-100 divide-x divide-gray-300">
      <th scope="col" class="text-left text-xs font-medium uppercase tracking-wider h-full p-3 w-0" [ngStyle]="{width: imageSizes[imageSize].header}">
        Media
      </th>
      <th scope="col" class="text-left text-xs font-medium uppercase tracking-wider h-full p-0">
        <app-column-sort [sort]="pageable.sort ? pageable.sort[0] : null" (sortChanged)="setSort($event)" field="title" display="title" class="p-3"></app-column-sort>
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="medias && medias.length > 0">
      <tr *ngFor="let media of medias; index as i;"
          [ngClass]="{
          'hover:bg-blue-100 cursor-pointer' : !selectLimit || selectedMedia.size < selectLimit || selectedMedia.has(media.id),
          'bg-jfgreen-50' : selectedMedia.has(media.id),
          'bg-white' : i % 2 === 0,
          'bg-gray-50' : i % 2 === 1
          }"
          (click)="(!selectLimit || selectedMedia.size < selectLimit || selectedMedia.has(media.id)) ? selectMedia(media) : null">
        <td class="text-sm px-3 py-1 text-left">
          <div class="flex h-full" [ngClass]="imageSizes[imageSize].image">
            <!--For tailwind purge: "max-h-10 max-h-20 max-h-40"-->
            <app-thumbnail-md [src]="media.url | hostname" [alt]="media.title" class="flex-grow block rounded-lg bg-white border border-gray-200"></app-thumbnail-md>
          </div>
        </td>
        <td class="text-sm px-3 py-1 text-left">
          <div class="text-gray-900 font-medium" style="overflow-wrap: anywhere">{{media.title}}</div>

          <div class="flex gap-1 items-center break-all">
            <div class="multiline-ellipsis-2 text-gray-500">{{media.url | hostname}}</div>
            <a class="text-blue-600 hover:text-blue-900" href="{{media.url | hostname}}" (click)="$event.stopPropagation()" target="_blank">
              <fa-icon [icon]="faExternalLink" class="p-1"></fa-icon>
            </a>
          </div>
        </td>
      </tr>
    </ng-container>
    <tr *ngIf="((!medias && !loading) || medias?.length === 0)">
      <td colspan="2" class="h-20 bg-white text-center">
        <fa-icon [icon]="faExclamationCircle"></fa-icon>
        No results found.
      </td>
    </tr>
    <tr *ngIf="!medias && loading">
      <td colspan="2" class="h-60 bg-white text-center">
        <app-loading></app-loading>
      </td>
    </tr>
    </tbody>
  </table>
  <app-pagination class="block bg-gray-200" [count]="count" [pageSize]="pageable.pageSize" [page]="pageable.page" (pageChanged)="pageChanged($event)"></app-pagination>
</div>
<div class="flex items-end justify-between mt-4 gap-2">
  <div>
    <app-loading *ngIf="medias && loading" class="whitespace-nowrap" message="Loading..."></app-loading>
  </div>

  <button type="submit" class="button-jf" (click)="submit()">{{submitText}} ({{selectedMedia.size}} item<ng-container *ngIf="selectedMedia.size !== 1">s</ng-container>)</button>
</div>
