import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../../../shared/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class GorgiasChatService {
  url = 'https://config.gorgias.chat/bundle-loader/01H122KC86FHJSPP1M7BZ02P78';
  admin = false;
  gorgiasChat = null;

  userChangedSubscription: Subscription;

  constructor(private userService: UserService) {}

  public hide(): void {
    this.gorgiasChat?.hideChat(true);
  }

  public popout(): void {
    this.gorgiasChat?.open();
  }

  public show(): void {
    if (!this.admin) {
      if (this.gorgiasChat) {
        this.gorgiasChat.hideChat(false);
      } else {
        this.loadScript();
      }
    }
  }

  private identify(email: string): void {
    this.gorgiasChat?.captureUserEmail(email);
  }

  private loadScript(): void {
    const script: any = document.createElement('script');
    script.id = 'gorgias-chat-widget-install-v3';
    script.type = 'text/javascript';
    script.src = this.url;
    script.async = true;
    const initGorgiasChatPromise =
      (window as any).GorgiasChat ?
        (window as any).GorgiasChat.init() :
        new Promise<void>(resolve => { window.addEventListener('gorgias-widget-loaded', () => { resolve(); }); });
    initGorgiasChatPromise.then(async () => {
      this.gorgiasChat = (window as any).GorgiasChat;
      this.gorgiasChat.hidePoweredBy(true);
      this.userChangedSubscription = this.userService.userChanged$.subscribe((user) => {
        if (user == null) {
          this.admin = false;
          this.show();
        } else if (user.admin) {
          this.admin = true;
          this.hide();
        } else if (user.email) {
          this.admin = false;
          this.identify(user.email);
        }
      });
    });
    // finally append the script tag in the DOM
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
