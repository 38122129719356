<form [formGroup]="form" (ngSubmit)="uploadMedia();" class="text-left">
  <app-alert [error]="error" innerClass="mb-4"></app-alert>
  <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
    <div>
      <label class="input-label">File</label>
      <app-image-uploader [callback]="imageCallback" maxHeight="24.25rem" [error]="mediaError"></app-image-uploader>
    </div>
    <div class="flex flex-col gap-4">
      <div>
        <label for="title" class="input-label">Title</label>
        <input type="text" id="title" formControlName="title" class="input-jf" placeholder="Title">
      </div>

      <div>
        <label for="keywords" class="input-label">Keywords</label>
        <input type="text" id="keywords" formControlName="keywords" class="input-jf" placeholder="Keywords">
      </div>

      <div class="flex flex-col md:flex-row gap-4 mt-auto" [ngClass]="showExisting ? 'justify-between' : 'justify-end'">
        <button *ngIf="showExisting" type="button" class="button-jf button-blue" [disabled]="submitting" (click)="showMediaSelectDialog()">
          <ng-container *ngIf="submitting !== 'add'">Choose Existing Media</ng-container>
          <app-loading *ngIf="submitting === 'add'" message="Adding"></app-loading>
        </button>

        <button type="submit" class="button-jf" [disabled]="submitting">
          <ng-container *ngIf="submitting !== 'upload'">Upload Media</ng-container>
          <app-loading *ngIf="submitting === 'upload'" message="Uploading"></app-loading>
        </button>
      </div>
    </div>
  </div>
</form>
