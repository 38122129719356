import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { faCaretDown, faCaretUp, faSort } from '@fortawesome/pro-solid-svg-icons';
import { GraphQlSortInput, SortDirection } from '../../../../../generated/graphql';

@Component({
  selector: 'app-column-sort',
  templateUrl: './column-sort.component.html',
  styleUrls: ['./column-sort.component.scss']
})
export class ColumnSortComponent implements OnInit {
  @Output() sortChanged = new EventEmitter<GraphQlSortInput>();
  @Input() sort: GraphQlSortInput;
  @Input() field: string;
  @Input() display: string;

  faSort = faSort;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  @HostListener('click', ['$event'])
  onClick(e) {
    this.setSort();
  }

  constructor() {}

  ngOnInit(): void {}

  setSort() {
    if (this.sort) {
      if (this.sort.field === this.field) {
        if (this.sort.direction === SortDirection.Asc) {
          this.sort = { field: this.field, direction: SortDirection.Desc };
        } else {
          delete this.sort;
        }
      } else {
        this.sort = { field: this.field, direction: SortDirection.Asc };
      }
    } else {
      this.sort = { field: this.field, direction: SortDirection.Asc };
    }
    this.sortChanged.emit(this.sort);
  }
}
