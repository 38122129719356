import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimesCircle,
  IconDefinition
} from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styles: []
})
export class AlertComponent implements OnInit {
  @Input() message: string;
  @Output() messageChange = new EventEmitter<string>();

  @Input() set title(title: string) {
    this._title = title;
    this.severityTitle = title;
  }
  _title: string;

  @Input() closeable = true;
  @Input() innerClass: string;
  @Input() set error(error: Error) {
    if (error) {
      this.severity = 'E';
      this.message = error.message ? error.message : JSON.stringify(error);
    } else {
      this.message = null;
    }
  }
  private severityData = 'S';
  @Input() set severity(severity: string) {
    if (severity) {
      this.severityData = severity;
      this.updateSeverityVariables();
    }
  }
  severityTitle = this.getSeverityTitle();
  severityClasses = this.getSeverityClasses();
  severityButtonClasses = this.getSeverityButtonClasses();
  severityIcon = this.getSeverityIcon();

  faTimes = faTimes;

  constructor() {}

  ngOnInit(): void {}

  updateSeverityVariables() {
    this.severityTitle = this.getSeverityTitle();
    this.severityClasses = this.getSeverityClasses();
    this.severityButtonClasses = this.getSeverityButtonClasses();
    this.severityIcon = this.getSeverityIcon();
  }

  close() {
    this.message = null;
    this.messageChange.emit(null);
  }

  private getSeverityClasses(): string {
    switch (this.severityData) {
      case 'S': {
        return 'bg-green-100 text-green-600 border-green-600';
      }
      case 'W': {
        return 'bg-yellow-100 text-yellow-600 border-yellow-600';
      }
      case 'E': {
        return 'bg-red-100 text-red-600 border-red-600';
      }
      case 'I': {
        return 'bg-blue-100 text-blue-600 border-blue-600';
      }
    }
  }

  private getSeverityButtonClasses(): string {
    switch (this.severityData) {
      case 'S': {
        return 'bg-green-100 hover:bg-green-200 focus:ring-offset-green-100 focus:ring-green-600';
      }
      case 'W': {
        return 'bg-yellow-100 hover:bg-yellow-200 focus:ring-offset-yellow-100 focus:ring-yellow-600';
      }
      case 'E': {
        return 'bg-red-100 hover:bg-red-200 focus:ring-offset-red-100 focus:ring-red-600';
      }
      case 'I': {
        return 'bg-blue-100 hover:bg-blue-200 focus:ring-offset-blue-100 focus:ring-blue-600';
      }
    }
  }

  private getSeverityIcon(): IconDefinition {
    switch (this.severityData) {
      case 'S': {
        return faCheckCircle;
      }
      case 'W': {
        return faExclamationCircle;
      }
      case 'E': {
        return faTimesCircle;
      }
      case 'I': {
        return faInfoCircle;
      }
    }
  }

  private getSeverityTitle(): string {
    if (this._title) {
      return this._title;
    } else {
      switch (this.severityData) {
        case 'S': {
          return 'Success';
        }
        case 'W': {
          return 'Warning';
        }
        case 'E': {
          return 'Error';
        }
        // case 'I': {
        //   return 'Info';
        // }
      }
    }
  }
}
