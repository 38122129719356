import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { environment } from '../environments/environment';

const uri = '/api/graphql'; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink) {
  // Get the authentication token from local storage if it exists
  const auth = setContext((operation, context: any) => {
    if (typeof localStorage !== 'undefined' && localStorage) {
      const accessToken = localStorage.getItem('access_token');
      if (accessToken) {
        context.headers = context.headers || {};
        context.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return context;
  });

  // If this is in the SSR, we need to use the full URL
  // But if this is on a development computer, fall back on
  // the Angular proxy settings
  let fullUri = uri;
  if (typeof window === 'undefined') {
    fullUri = environment.apiBaseUrl + uri;
  }

  return {
    link: ApolloLink.from([
      new RetryLink({
        delay: {
          initial: 300,
          max: Infinity,
          jitter: true
        },
        attempts: {
          max: 5,
          retryIf: (error, operation) => {
            if (!!error) {
              return !(error.status && (error.status === 504 || error.status === 524 || error.status === 401));
            } else {
              return false;
            }
          }
        }
      }),
      auth,
      httpLink.create({ uri: fullUri })
    ]),
    cache: new InMemoryCache()
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink]
    }
  ]
})
export class GraphQLModule {}
