import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { filter, throttleTime } from 'rxjs/operators';
import { GorgiasChatService } from '../../frontend/shared/services/gorgias-chat.service';
import { DialogBoxOptions, DialogComponent } from '../components/dialog/dialog.component';
import { MessageBoxComponent, MessageBoxOptions } from '../components/message-box/message-box.component';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  componentRef: any[] = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private router: Router,
    private platformService: PlatformService,
    private gorgiasChatService: GorgiasChatService
  ) {
    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e) => {
      this.closeAllDialogs();
    });

    if (platformService.isBrowser) {
      fromEvent(document, 'keydown')
        .pipe(
          filter((e: KeyboardEvent) => e.code === 'Escape'),
          throttleTime(200, undefined, { leading: true, trailing: true })
        )
        .subscribe((event) => {
          if (this.componentRef.length > 0) {
            const dialogComponent = this.componentRef[this.componentRef.length - 1].instance;
            dialogComponent.hideDialog();
          }
        });
    }
  }

  showErrorMessageBox(error: any) {
    const messageBoxOptions = new MessageBoxOptions();
    messageBoxOptions.title = 'Error';
    if (error.message) {
      messageBoxOptions.message = error.message;
    } else {
      messageBoxOptions.message = JSON.stringify(error);
    }
    messageBoxOptions.okText = 'OK';
    messageBoxOptions.severity = 'E';
    this.showMessageBox(messageBoxOptions, () => {});
  }

  showMessageBox(
    options: MessageBoxOptions,
    okPressed?: () => void,
    cancelPressed?: () => void
  ) {
    // this.hideDialog();
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      MessageBoxComponent
    );
    const componentRef = factory.create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    componentRef.instance.options = options;

    componentRef.instance.okPressed.subscribe(() => {
      this.hideDialog();
      if (okPressed) {
        okPressed();
      }
    });

    componentRef.instance.cancelPressed.subscribe(() => {
      this.hideDialog();
      if (cancelPressed) {
        cancelPressed();
      }
    });

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    this.componentRef.push(componentRef);
    document.body.appendChild(domElem);
    document.body.classList.add('overflow-hidden');
    this.gorgiasChatService.hide();
  }

  showDialog<T>(
    options: DialogBoxOptions,
    okPressed?: () => void,
    cancelPressed?: () => void
  ): any {
    // this.hideDialog();
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      DialogComponent
    );
    const componentRef = factory.create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    componentRef.instance.options = options;

    componentRef.instance.okPressed.subscribe(() => {
      this.hideDialog();
      if (okPressed) {
        okPressed();
      }
    });

    componentRef.instance.cancelPressed.subscribe(() => {
      this.hideDialog();
      if (cancelPressed) {
        cancelPressed();
      }
    });

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    document.body.classList.add('overflow-hidden');
    this.gorgiasChatService.hide();

    this.componentRef.push(componentRef);
    return componentRef;
  }

  hideDialog() {
    if (this.componentRef.length > 0) {
      const componentRef = this.componentRef[this.componentRef.length - 1];
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
      this.componentRef.pop();
      if (this.componentRef.length === 0) {
        document?.body.classList.remove('overflow-hidden');
        this.gorgiasChatService.show();
      }
    }
  }

  closeAllDialogs() {
    while (this.componentRef.length) {
      this.hideDialog();
    }
  }
}
