import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faCopy, faExclamationCircle, faExternalLink } from '@fortawesome/pro-duotone-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { Subscription } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import {
  GraphQlPageableInput,
  GraphQlSortInput,
  MediaEntity,
  MediaFilterGQL,
  SortDirection
} from '../../../../../../generated/graphql';
import { DialogComponent } from '../../../../shared/components/dialog/dialog.component';

@Component({
  selector: 'app-media-select',
  templateUrl: './media-select.component.html',
  styles: []
})
export class MediaSelectComponent implements OnInit {
  @Input() submitText = 'Select Media';
  @Input() selectLimit: number;
  @Input() parentRef: DialogComponent<MediaSelectComponent>;
  @Input() callback: (media: Map<string, MediaEntity>) => void;
  faExclamationCircle = faExclamationCircle;
  faInfoCircle = faInfoCircle;
  faCopy = faCopy;
  faExternalLink = faExternalLink;

  imageSize = 0;
  imageSizes = [
    { image: 'max-h-10', header: '4rem' },
    { image: 'max-h-20', header: '6.5rem' },
    { image: 'max-h-40', header: '11.5rem' }
  ];

  form: FormGroup;

  search: string;
  medias: MediaEntity[];
  selectedMedia = new Map<string, MediaEntity>();
  loading = true;
  error: Error;
  filterSubscription: Subscription;

  count = 0;
  pageable: GraphQlPageableInput = {
    page: 1,
    pageSize: 5,
    sort: [{ field: 'title', direction: SortDirection.Asc }]
  };

  constructor(
    private fb: FormBuilder,
    private mediaFilterGQL: MediaFilterGQL
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      keywords: [{ value: '', disabled: false }],
      title: [{ value: '', disabled: false }]
    });

    this.form.valueChanges
      .pipe(throttleTime(300, undefined, { leading: true, trailing: true }))
      .subscribe((value) => {
        this.pageable.page = 1;
        this.searchMedia();
      });

    this.searchMedia();
    this.parentRef.useFullHeight();
  }

  setSort(sort: GraphQlSortInput) {
    if (sort) {
      this.pageable.sort = [sort];
    } else {
      delete this.pageable.sort;
    }
    this.searchMedia();
  }

  pageChanged(page: number) {
    this.pageable.page = page;
    this.searchMedia();
  }

  searchMedia() {
    this.filterSubscription?.unsubscribe();
    this.loading = true;
    delete this.error;
    const value = { ...this.form.value };

    if (value.title === '') {
      delete value.title;
    } else {
      value.title = `%${value.title}%`;
    }

    if (value.keywords === '') {
      delete value.keywords;
    } else {
      value.keywords = `%${value.keywords}%`;
    }

    this.filterSubscription = this.mediaFilterGQL
      .fetch({
        pageable: this.pageable,
        ...value
      })
      .pipe(map((result) => result.data.mediaFilter))
      .subscribe(
        (result) => {
          this.medias = result.data;
          this.pageable.page = result.page;
          this.pageable.pageSize = result.pageSize;
          this.count = result.count;
          this.loading = false;
        },
        (error) => {
          this.error = error;
          this.loading = false;
        }
      );
  }

  selectMedia(media: MediaEntity) {
    if (this.selectedMedia.has(media.id)) {
      this.selectedMedia.delete(media.id);
    } else {
      this.selectedMedia.set(media.id, media);
    }
  }

  submit() {
    if (this.callback) {
      this.callback(this.selectedMedia);
    }
    this.parentRef.pressOK();
  }
}
