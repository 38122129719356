<span class="relative flex justify-between gap-1.5 items-center h-full">
  <ng-container *ngIf="this.sort?.field === field">
    <span class="text-black">{{display || field}}</span>
    <fa-icon [icon]="this.sort.direction === 'ASC' ? faCaretUp : faCaretDown" class="flex text-base text-black"></fa-icon>
  </ng-container>
  <ng-container *ngIf="this.sort?.field !== field">
    <span>{{display || field}}</span>
    <fa-icon *ngIf="this.sort?.field !== field" [icon]="faSort" class="flex text-gray-300 text-base"></fa-icon>
  </ng-container>
</span>
