<div *ngIf="message" class="flex gap-4 items-center justify-between p-4 border-b-4 text-left {{innerClass}}" [ngClass]="severityClasses">
  <div class="flex gap-4 items-center min-w-0 flex-grow">
    <fa-icon class="text-2xl" [icon]="severityIcon"></fa-icon>
    <div class="min-w-0 flex justify-between gap-4 sm:items-center flex-grow flex-col sm:flex-row">
      <div>
        <div class="text-lg leading-6 font-medium">{{severityTitle}}</div>
        <p class="text-sm leading-5 text-black break-words flex justify-between">{{message}}</p>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
  <button *ngIf="closeable" type="button" [ngClass]="severityButtonClasses" class="flex-none rounded-md p-1 -mr-2 focus:outline-none focus:ring-2 focus:ring-offset-2" (click)="close()">
    <fa-icon class="h-5 w-5 text-xl justify-center flex" [icon]="faTimes"></fa-icon>
  </button>
</div>
<!--"bg-green-100 text-green-600 border-green-600 hover:bg-green-200 focus:ring-offset-green-100 focus:ring-green-600"-->
<!--"bg-yellow-100 text-yellow-600 border-yellow-600 hover:bg-yellow-200 focus:ring-offset-yellow-100 focus:ring-yellow-600"-->
<!--"bg-red-100 text-red-600 border-red-600 hover:bg-red-200 focus:ring-offset-red-100 focus:ring-red-600"-->
<!--"bg-blue-100 text-blue-600 border-blue-600 hover:bg-blue-200 focus:ring-offset-blue-100 focus:ring-blue-600"-->
