import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OnBeforeOK } from '../dialog/dialog.component';

export interface ImageFile {
  name: string,
  type: string,
  sizeInKb: number,
  base64: string
}

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit, OnBeforeOK {
  @Input()
  callback: (value: ImageFile) => void;
  @Input()
  maxHeight: string;
  @Input()
  minHeight = '24.25rem';
  @ViewChild('fileUpload')
  fileUpload: ElementRef;

  fileName = '';
  previewUrl = '';
  dragIsActive = false;
  @Input()
  error: Error;

  // due to the way browsers handle drag and drop events when users
  // drag over a div's inner children, we need to maintain a counter
  // to determine when they are dragging outside of the outer container
  dndCounter = 0;

  private allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
  private maxFileSizeInMB = 20;

  filePickerFilter = this.allowedFileTypes.map(x => `.${x.replace("image/", "")}`).join(", ");

  constructor(private sanitizer:DomSanitizer) { }
  ngOnInit(): void {}

  onBeforeOK(): boolean {
    if (!this.fileName) {
      this.error = new Error("No file selected.");
    }
    return !this.error;
  }

  onDragEnter(event: any): void {
    this.dndCounter++;
    this.updateDragStyles();
  }

  onDragLeave(event: any): void {
    this.dndCounter--;
    this.updateDragStyles();
  }

  onDrop(event: any): void {
    this.dndCounter = 0;
    event.preventDefault();
    this.dragIsActive = false;

    // Credit: https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...event.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile();
          this.showFilePreview(file);
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...event.dataTransfer.files].forEach((file, i) => {
        this.showFilePreview(file);
      });
    }

  }

  onDragOver(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    this.dragIsActive = true;
  }

  onFileSelected(event: any): void  {
    const file:File = event.target.files[0];
    this.showFilePreview(file);
  }

  private getFileSizeInMb(sizeInBytes: number): number {
    if (!sizeInBytes) return 0;
    const fileSizeInMb = (sizeInBytes / (1000*1000));
    const roundedSize = Math.round(fileSizeInMb * 100) / 100;
    return roundedSize;
  }

  private updateDragStyles() {
    this.dragIsActive = this.dndCounter > 0;
  }

  private reset() {
    this.fileName = '';
    this.previewUrl = '';
    this.fileUpload.nativeElement.value = "";
    this.callback?.(null);
  }

  private showFilePreview(file: File) {
    if (!file) return;

    this.error = undefined;

     // reject if wrong file type
    if (!this.allowedFileTypes.includes(file.type)) {
      this.error = new Error(`Must be one of these file types: ${this.filePickerFilter}.`);
      this.reset();
      return;
    }

    const fileSizeInMb = Math.round(this.getFileSizeInMb(file.size) * 100) / 100;
    if (fileSizeInMb > this.maxFileSizeInMB) {
      this.error = new Error(`File size is ${fileSizeInMb} MB, but must not be greater than ${this.maxFileSizeInMB} MB.`);
      this.reset();
      return;
    }

    this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)) as string;
    this.fileName = file.name;

    const ctx = this;
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = function(event) {
      const encodedImage = btoa(event.target.result as string);
      const fileInfo = {
         name: file.name,
         type: file.type,
         sizeInKb: file.size / 1000,
         base64: encodedImage
      } as ImageFile;

      ctx.callback?.(fileInfo);
    };
    reader.onerror = function(err: any) {
      console.error(err);
      ctx.error = new Error('Unexpected error when processing image.');
    };
  }
}
