import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Built-in java.math.BigDecimal */
  BigDecimal: any;
  /** Built-in scalar representing an instant in time */
  Instant: any;
  /** Built-in scalar representing a local date */
  LocalDate: any;
  /** Built-in scalar representing a local time */
  LocalTime: any;
  /** Long type */
  Long: any;
  /** Use SPQR's SchemaPrinter to remove this from SDL */
  UNREPRESENTABLE: any;
  /** UUID String */
  UUID: any;
};










export type AddonEntity = {
  __typename?: 'AddonEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Addon's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Addon's per item */
  perItem?: Maybe<Scalars['Boolean']>;
  /** Addon's slug */
  slug?: Maybe<Scalars['String']>;
  /** Addon's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Addon's weight */
  weight?: Maybe<Scalars['BigDecimal']>;
};

export type AddonSetDestinationRequirementEntity = {
  __typename?: 'AddonSetDestinationRequirementEntity';
  /** Addon rule's addon set */
  addonSet?: Maybe<AddonSetEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Addon rule's comparison */
  comparison?: Maybe<Comparison>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Addon rule's safety temperature */
  safetyTemp?: Maybe<Scalars['Int']>;
  /** Shipping rule's slug */
  slug?: Maybe<Scalars['String']>;
  /** Addon rule's temperature */
  temp?: Maybe<Scalars['Int']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type AddonSetEntity = {
  __typename?: 'AddonSetEntity';
  /** Addon set's addons */
  addons?: Maybe<Array<Maybe<AddonEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Addon set's name */
  name?: Maybe<Scalars['String']>;
  /** Addon set's priority */
  priority?: Maybe<Scalars['Long']>;
  /** Addon set's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type AddonSetSourceRequirementEntity = {
  __typename?: 'AddonSetSourceRequirementEntity';
  /** Addon rule's addon set */
  addonSet?: Maybe<AddonSetEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Addon rule's comparison */
  comparison?: Maybe<Comparison>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipping rule's slug */
  slug?: Maybe<Scalars['String']>;
  /** Addon rule's temperature */
  temp?: Maybe<Scalars['Int']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type AddressEntity = {
  __typename?: 'AddressEntity';
  /** Address' type */
  addressType?: Maybe<AddressType>;
  /** Address' validation source */
  addressValidationSource?: Maybe<AddressValidationSource>;
  /** Address' city name */
  city?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Address' company */
  company?: Maybe<Scalars['String']>;
  /** Address' ISO country code */
  country?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Address' first name */
  firstName?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Address' last name */
  lastName?: Maybe<Scalars['String']>;
  /** Address' latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Address' first line */
  line1?: Maybe<Scalars['String']>;
  /** Address' last line */
  line2?: Maybe<Scalars['String']>;
  /** Address' longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Address' Magento Id */
  magentoId?: Maybe<Scalars['Long']>;
  /** Address' postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Address' residential status */
  residential?: Maybe<Scalars['Boolean']>;
  /** Address' state (or province) abbreviation */
  state?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type AffiliateProductListEntity = {
  __typename?: 'AffiliateProductListEntity';
  /** Affiliate list's items */
  affiliateProductListItems?: Maybe<Array<Maybe<AffiliateProductListItemEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Affiliate list's html content */
  htmlContent?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Affiliate list's name */
  name?: Maybe<Scalars['String']>;
  /** Affiliate list's sequence */
  sequence?: Maybe<Scalars['Int']>;
  /** Affiliate list's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type AffiliateProductListItemEntity = {
  __typename?: 'AffiliateProductListItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Kit item's position */
  position?: Maybe<Scalars['Long']>;
  /** Affiliate product list item's product */
  product?: Maybe<ProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type AmazonProfileEntity = {
  __typename?: 'AmazonProfileEntity';
  /** Amazon profile's ASIN */
  asin?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Amazon profile's FBA Seller SKU */
  fbaSellerSku?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Amazon profile's MFN Seller SKU */
  sellerSku?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type AmazonSalesOrderEntity = {
  __typename?: 'AmazonSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Amazon created at */
  amazonCreatedAt?: Maybe<Scalars['Instant']>;
  /** Amazon updated at */
  amazonUpdatedAt?: Maybe<Scalars['Instant']>;
  /** Billing address' type */
  billingAddressType?: Maybe<AddressType>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Sales order's fulfillment network */
  fulfillmentNetwork?: Maybe<FulfillmentNetworkType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payment terms account */
  paymentTermsAccount?: Maybe<PaymentTermsAccountEntity>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Sales order's listed items */
  salesOrderListedItems?: Maybe<Array<Maybe<SalesOrderListedItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' type */
  shippingAddressType?: Maybe<AddressType>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type AnimalEntity = {
  __typename?: 'AnimalEntity';
  /** Animal's children */
  children?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Animal's explicit products */
  explicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Animal's name */
  name?: Maybe<Scalars['String']>;
  /** Animal's parent */
  parent?: Maybe<AnimalEntity>;
  /** Animal's route key */
  routeKey?: Maybe<Scalars['String']>;
  /** Animal's route path */
  routePath?: Maybe<Scalars['String']>;
  /** Animal's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ApplicationEntity = {
  __typename?: 'ApplicationEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type BatchPrintQueueEntity = {
  __typename?: 'BatchPrintQueueEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Queue's combos only */
  combosOnly?: Maybe<Scalars['Boolean']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Queue's enabled */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Queue's name */
  name?: Maybe<Scalars['String']>;
  /** Queue's priority */
  priority?: Maybe<Scalars['Long']>;
  /** Queue's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Queue's singles only */
  singlesOnly?: Maybe<Scalars['Boolean']>;
  /** Queue's slug */
  slug?: Maybe<Scalars['String']>;
  /** Queue's templates */
  template?: Maybe<Scalars['String']>;
  unshippedCount?: Maybe<Scalars['Int']>;
  unshippedShipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Queue's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
  /** Queue's zone */
  zone?: Maybe<ZoneEntity>;
};

export type BinEntity = {
  __typename?: 'BinEntity';
  /** Bin's human-readable ID */
  binId?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Bin's zone membership */
  zone?: Maybe<ZoneEntity>;
};

export type BlockEntity = {
  __typename?: 'BlockEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Block's html content */
  htmlContent?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Block's name */
  name?: Maybe<Scalars['String']>;
  /** Block's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type BlogEntity = {
  __typename?: 'BlogEntity';
  /** Blog's active */
  active?: Maybe<Scalars['Boolean']>;
  /** Content's author */
  author?: Maybe<Scalars['String']>;
  /** Blog's topics */
  blogTopics?: Maybe<Array<Maybe<BlogTopicEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Content's medias */
  medias?: Maybe<Array<Maybe<ContentMediaEntity>>>;
  /** Blog's meta description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Blog's meta keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Content's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Blog's products */
  products?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Content's published revision */
  publishedRevision?: Maybe<ContentRevisionEntity>;
  /** Content's read time */
  readTime?: Maybe<Scalars['Int']>;
  /** Content's revisions */
  revisions?: Maybe<Array<Maybe<ContentRevisionEntity>>>;
  /** Content's slug */
  slug?: Maybe<Scalars['String']>;
  /** Blog's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type BlogTopicEntity = {
  __typename?: 'BlogTopicEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Blog topic's name */
  name?: Maybe<Scalars['String']>;
  /** Blog topic's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type BomItemEntity = {
  __typename?: 'BomItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** BOM item's component */
  component?: Maybe<SimpleProductEntity>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** BOM item's position */
  position?: Maybe<Scalars['Long']>;
  /** BOM item's quantity */
  quantity?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type BrandEntity = {
  __typename?: 'BrandEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Brand's name */
  name?: Maybe<Scalars['String']>;
  /** Brand's products */
  products?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Brand's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CareSheetEntity = {
  __typename?: 'CareSheetEntity';
  /** Content's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Content's author */
  author?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Content's medias */
  medias?: Maybe<Array<Maybe<ContentMediaEntity>>>;
  /** Content's description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Content's keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Content's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Care sheet's products */
  products?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Content's published revision */
  publishedRevision?: Maybe<ContentRevisionEntity>;
  /** Content's read time */
  readTime?: Maybe<Scalars['Int']>;
  /** Content's revisions */
  revisions?: Maybe<Array<Maybe<ContentRevisionEntity>>>;
  /** Content's slug */
  slug?: Maybe<Scalars['String']>;
  /** Content's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CarrierRestrictionEntity = {
  __typename?: 'CarrierRestrictionEntity';
  /** Shipping rule's carriers */
  carriers?: Maybe<Array<Maybe<Carrier>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipping rule's packagings */
  packagings?: Maybe<Array<Maybe<Packaging>>>;
  /** Shipping rule's services */
  services?: Maybe<Array<Maybe<Service>>>;
  /** Shipping rule's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CartCustomItemEntity = {
  __typename?: 'CartCustomItemEntity';
  /** Cart item's carrier preference */
  carrierPreference?: Maybe<Carrier>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Cart item's delivery date preference */
  deliveryDatePreference?: Maybe<Scalars['LocalDate']>;
  /** Cart item's earliest possible ship date */
  earliestPossibleShipDate?: Maybe<Scalars['LocalDate']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Cart item's product price */
  productPrice?: Maybe<Scalars['BigDecimal']>;
  /** Cart item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Cart item's sales tax rules */
  salesTaxRules?: Maybe<SalesTaxRules>;
  /** Cart item's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Cart item's weight */
  weight?: Maybe<Scalars['BigDecimal']>;
};

export type CartDiscount = {
  __typename?: 'CartDiscount';
  /** Cart discount's amount */
  amount?: Maybe<Scalars['BigDecimal']>;
  /** Cart discount's name */
  name?: Maybe<Scalars['String']>;
  /** Cart discount's reward points */
  rewardPoints: Scalars['Long'];
};

export type CartEntity = {
  __typename?: 'CartEntity';
  /** Billing address' type */
  billingAddressType?: Maybe<AddressType>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Cart's batch item count */
  cartBatchItemsCount?: Maybe<Scalars['Long']>;
  /** Cart's custom items */
  cartCustomItems?: Maybe<Array<Maybe<CartCustomItemEntity>>>;
  /** Cart's discounts */
  cartDiscounts?: Maybe<Array<Maybe<CartDiscount>>>;
  /** Cart's item groups */
  cartItemGroups?: Maybe<Array<Maybe<CartItemGroupEntity>>>;
  /** Cart's items */
  cartItems?: Maybe<Array<Maybe<CartItemEntity>>>;
  /** Cart's item count */
  cartItemsCount?: Maybe<Scalars['Long']>;
  /** Cart's listed items */
  cartListedItems?: Maybe<Array<Maybe<CartListedItemEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Cart's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Cart's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Cart's email */
  email?: Maybe<Scalars['String']>;
  /** Cart's fulfillment network */
  fulfillmentNetwork?: Maybe<FulfillmentNetworkType>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  notifications?: Maybe<Array<Maybe<CartNotification>>>;
  /** Cart's phone */
  phone?: Maybe<Scalars['String']>;
  /** Cart's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Cart's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' type */
  shippingAddressType?: Maybe<AddressType>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Cart's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Cart's subtotal */
  subtotal?: Maybe<Scalars['BigDecimal']>;
  /** Cart's tax-exempt flag */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Cart's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CartItemEntity = {
  __typename?: 'CartItemEntity';
  /** Cart item's carrier preference */
  carrierPreference?: Maybe<Carrier>;
  /** Cart item's group */
  cartItemGroup?: Maybe<CartItemGroupEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Cart item's delivery date preference */
  deliveryDatePreference?: Maybe<Scalars['LocalDate']>;
  /** Cart item's discount price */
  discountPrice?: Maybe<Scalars['BigDecimal']>;
  /** Cart item's earliest possible ship date */
  earliestPossibleShipDate?: Maybe<Scalars['LocalDate']>;
  /** Cart item's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Cart item's regular price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Cart item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Cart item's regular price */
  regularPrice?: Maybe<Scalars['BigDecimal']>;
  /** Cart item's sale price */
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Cart item's service type preference */
  serviceTypePreference?: Maybe<ServiceType>;
  /** Cart item's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CartItemGroupEntity = {
  __typename?: 'CartItemGroupEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Cart item group's discount price */
  discountPrice?: Maybe<Scalars['BigDecimal']>;
  /** Cart item group's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Cart item group's kit product */
  kitProduct?: Maybe<KitProductEntity>;
  /** Cart item group's regular price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Cart item group's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Cart item group's regular price */
  regularPrice?: Maybe<Scalars['BigDecimal']>;
  /** Cart item group's sale price */
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CartListedItemEntity = {
  __typename?: 'CartListedItemEntity';
  /** Cart listed item's carrier preference */
  carrierPreference?: Maybe<Carrier>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Cart listed item's delivery date preference */
  deliveryDatePreference?: Maybe<Scalars['LocalDate']>;
  /** Cart listed item's earliest possible ship date */
  earliestPossibleShipDate?: Maybe<Scalars['LocalDate']>;
  /** Cart listed item's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Cart listed item's listed product */
  listedProduct?: Maybe<ListedProductEntity>;
  /** Cart listed item's listing */
  listing?: Maybe<ListingEntity>;
  /** Cart listed item's overridden shipping needs type */
  overriddenShippingNeedsType?: Maybe<ShippingNeedsType>;
  /** Cart listed item's regular price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Cart listed item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Cart listed item's sale price */
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Cart listed item's service type preference */
  serviceTypePreference?: Maybe<ServiceType>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type CartNotification = {
  __typename?: 'CartNotification';
  /** Cart notification's cart item */
  cartItem?: Maybe<CartItemEntity>;
  /** Cart notification's cart item group */
  cartItemGroup?: Maybe<CartItemGroupEntity>;
  /** Cart notification's cart listed item */
  cartListedItem?: Maybe<CartListedItemEntity>;
  /** Cart notification's message */
  message?: Maybe<Scalars['String']>;
  /** Cart notification's type */
  type?: Maybe<CartNotificationType>;
};

export type CategoryEntity = {
  __typename?: 'CategoryEntity';
  /** Category's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Category's ancestors */
  ancestors?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Category's children */
  children?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Category's explicit products */
  explicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Category's implicit products */
  implicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Category's Magento Id */
  magentoIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Category's meta description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Category's meta keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Category's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Category's name */
  name?: Maybe<Scalars['String']>;
  /** Category's parent */
  parent?: Maybe<CategoryEntity>;
  /** Category's path */
  path?: Maybe<Scalars['String']>;
  /** Category's published revision */
  publishedRevision?: Maybe<CategoryRevisionEntity>;
  /** Category's route key */
  routeKey?: Maybe<Scalars['String']>;
  /** Category's route path */
  routePath?: Maybe<Scalars['String']>;
  /** Category's slug */
  slug?: Maybe<Scalars['String']>;
  /** Category's thumbnail media */
  thumbnailMedia?: Maybe<MediaEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Category's wholesale flag */
  wholesale?: Maybe<Scalars['Boolean']>;
};

export type CategoryResults = {
  __typename?: 'CategoryResults';
  /** Result's category */
  category?: Maybe<CategoryEntity>;
  /** Result's products */
  products?: Maybe<GraphQlPage_ProductEntity>;
};

export type CategoryRevisionEntity = {
  __typename?: 'CategoryRevisionEntity';
  /** Category revision's html content */
  htmlContent?: Maybe<Scalars['String']>;
};

export type ChewyProfileEntity = {
  __typename?: 'ChewyProfileEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Chewy profile's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Chewy profile's description */
  description?: Maybe<Scalars['String']>;
  /** Chewy profile's enabled flag */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Chewy profile's sku */
  sku?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ChewySalesOrderEntity = {
  __typename?: 'ChewySalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' type */
  billingAddressType?: Maybe<AddressType>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Sales order's fulfillment network */
  fulfillmentNetwork?: Maybe<FulfillmentNetworkType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payment terms account */
  paymentTermsAccount?: Maybe<PaymentTermsAccountEntity>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Sales order's listed items */
  salesOrderListedItems?: Maybe<Array<Maybe<SalesOrderListedItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' type */
  shippingAddressType?: Maybe<AddressType>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type ClientTokenDetail = {
  __typename?: 'ClientTokenDetail';
  /** Client token */
  clientToken?: Maybe<Scalars['String']>;
};

export type ConfigurableItemEntity = {
  __typename?: 'ConfigurableItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Configurable item's position */
  position?: Maybe<Scalars['Long']>;
  /** Configurable item's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ConfigurableProductEntity = {
  __typename?: 'ConfigurableProductEntity';
  /** Product's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Product's Amazon profile */
  amazonProfile?: Maybe<AmazonProfileEntity>;
  /** Product's average rating */
  averageRating?: Maybe<Scalars['Float']>;
  /** Product's blogs */
  blogs?: Maybe<Array<Maybe<BlogEntity>>>;
  /** Simple product's brand */
  brand?: Maybe<BrandEntity>;
  /** Product's care sheets */
  careSheets?: Maybe<Array<Maybe<CareSheetEntity>>>;
  /** Product's Chewy profile */
  chewyProfile?: Maybe<ChewyProfileEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Configurable's items */
  configurableItems?: Maybe<Array<Maybe<ConfigurableItemEntity>>>;
  /** Product's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Product's number of ratings */
  countRating?: Maybe<Scalars['Long']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Product's cross sell items */
  crossSellItems?: Maybe<Array<Maybe<CrossSellItemEntity>>>;
  crossSells?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Product's dropshipped flag */
  dropshipped?: Maybe<Scalars['Boolean']>;
  /** Product's dropship partner */
  dropshipper?: Maybe<PartnerEntity>;
  /** Product's explicit animals */
  explicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's explicit categories */
  explicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's explicit department */
  explicitDepartment?: Maybe<DepartmentEntity>;
  /** Product's explicit discounts */
  explicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's explicit promotions */
  explicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's featured status */
  featured?: Maybe<Scalars['Boolean']>;
  /** Product's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Product's implicit animals */
  implicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's implicit categories */
  implicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's implicit departments */
  implicitDepartments?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Product's implicit discounts */
  implicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's implicit promotions */
  implicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's important status */
  important?: Maybe<Scalars['Boolean']>;
  /** Product's live flag */
  live?: Maybe<Scalars['Boolean']>;
  /** Product's Magento Id */
  magentoId?: Maybe<Scalars['String']>;
  /** Product's manufacture time */
  manufactureTime?: Maybe<Scalars['BigDecimal']>;
  /** Product's medias */
  medias?: Maybe<Array<Maybe<ProductMediaEntity>>>;
  /** Product's meta description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Product's meta keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Product's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Product's Pet Supplies Plus profile */
  petSuppliesPlusProfile?: Maybe<PetSuppliesPlusProfileEntity>;
  /** Product's Petco dropship profile */
  petcoDropshipProfile?: Maybe<PetcoDropshipProfileEntity>;
  /** Product's Petco store direct profile */
  petcoStoreDirectProfile?: Maybe<PetcoStoreDirectProfileEntity>;
  /** Product's popularity */
  popularity?: Maybe<Scalars['BigDecimal']>;
  /** Product's prep type */
  prepType?: Maybe<PrepType>;
  /** Product's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Product's price tiers */
  priceTiers?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's published revision */
  publishedRevision?: Maybe<ProductRevisionEntity>;
  /** Product's quantity available for sale */
  quantityAvailableForSale?: Maybe<Scalars['Long']>;
  /** Product's quantity available for sale on speciality markets */
  quantityAvailableForSpeciality?: Maybe<Scalars['Long']>;
  /** Product's reseller profiles */
  resellerProfiles?: Maybe<Array<Maybe<ResellerProfileEntity>>>;
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's shipping estimates */
  shippingEstimates?: Maybe<Array<Maybe<ShippingEstimateEntity>>>;
  /** Product's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Product's shipping rule set */
  shippingRuleSet?: Maybe<ShippingRuleSetEntity>;
  /** Product's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Product's slug */
  slug?: Maybe<Scalars['String']>;
  /** Product's special price */
  specialPrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's subscription status */
  subscription?: Maybe<Scalars['Boolean']>;
  tieredPrices?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's title */
  title?: Maybe<Scalars['String']>;
  /** Product's UPC */
  upc?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Product's upsells */
  upsellProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  userReview?: Maybe<ReviewEntity>;
  /** Product's variation key */
  variationKey?: Maybe<Scalars['String']>;
  /** Product's variation set */
  variationSet?: Maybe<VariationSetEntity>;
  /** Product's visible flag */
  visible?: Maybe<Scalars['Boolean']>;
  /** Product's Walmart profile */
  walmartProfile?: Maybe<WalmartProfileEntity>;
  /** Product's wholesale price */
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
};

export type ContentEntity = {
  __typename?: 'ContentEntity';
  /** Content's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Content's author */
  author?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Content's medias */
  medias?: Maybe<Array<Maybe<ContentMediaEntity>>>;
  /** Content's description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Content's keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Content's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Content's published revision */
  publishedRevision?: Maybe<ContentRevisionEntity>;
  /** Content's read time */
  readTime?: Maybe<Scalars['Int']>;
  /** Content's revisions */
  revisions?: Maybe<Array<Maybe<ContentRevisionEntity>>>;
  /** Content's slug */
  slug?: Maybe<Scalars['String']>;
  /** Content's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ContentMediaEntity = {
  __typename?: 'ContentMediaEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Media's content */
  content?: Maybe<ContentEntity>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Media's type */
  mediaType?: Maybe<MediaType>;
  /** Media's sequence */
  sequence?: Maybe<Scalars['Int']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Media's url */
  url?: Maybe<Scalars['String']>;
};

export type ContentRevisionEntity = {
  __typename?: 'ContentRevisionEntity';
  /** Product revision's html content */
  htmlContent?: Maybe<Scalars['String']>;
};

export type CrossSellItemEntity = {
  __typename?: 'CrossSellItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Cross sell's item */
  crossSellProduct?: Maybe<ProductEntity>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Cross sell's position */
  position?: Maybe<Scalars['Long']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  /** Dashboard's stats, last week */
  lastWeek?: Maybe<Stats>;
  /** Dashboard's late count */
  lateCount?: Maybe<Scalars['Long']>;
  /** Dashboard's needs scheduling count */
  needsSchedulingCount?: Maybe<Scalars['Long']>;
  /** Dashboard's next open date */
  nextOpenDate?: Maybe<Scalars['String']>;
  /** Dashboard's on-hold count */
  onHoldCount?: Maybe<Scalars['Long']>;
  /** Dashboard's pending count */
  pendingCount?: Maybe<Scalars['Long']>;
  /** Dashboard's summaries */
  summaries?: Maybe<Array<Maybe<Summary>>>;
  /** Dashboard's stats, this week */
  thisWeek?: Maybe<Stats>;
  /** Dashboard's timezone */
  timezone?: Maybe<Scalars['String']>;
  /** Dashboard's today */
  today?: Maybe<Scalars['String']>;
  /** Dashboard's warehouse */
  warehouse?: Maybe<Scalars['String']>;
};

export type DepartmentEntity = {
  __typename?: 'DepartmentEntity';
  /** Department's children */
  children?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Department's explicit products */
  explicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Department's implicit products */
  implicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Department's name */
  name?: Maybe<Scalars['String']>;
  /** Department's parent */
  parent?: Maybe<DepartmentEntity>;
  /** Department's promotions */
  promotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Department's route key */
  routeKey?: Maybe<Scalars['String']>;
  /** Department's route path */
  routePath?: Maybe<Scalars['String']>;
  /** Department's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type DestinationRestrictionEntity = {
  __typename?: 'DestinationRestrictionEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Destination restriction's domestic only */
  domesticOnly?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Destination restriction's prohibited states */
  prohibitedStates?: Maybe<Array<Maybe<State>>>;
  /** Destination restriction's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type DiscountEntity = {
  __typename?: 'DiscountEntity';
  /** Discount's active status */
  active?: Maybe<Scalars['Boolean']>;
  /** Discount's categories */
  categories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Discount's departments */
  departments?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Discount's amount */
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  /** Discount's type */
  discountType?: Maybe<DiscountType>;
  /** Discount's eligible cart value */
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  /** Discount's eligible coupon code */
  eligibleCouponCode?: Maybe<Scalars['String']>;
  /** Discount's eligible products */
  eligibleProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Discount's eligible trade show */
  eligibleTradeShow?: Maybe<TradeShowEntity>;
  /** Discount's eligible user type */
  eligibleUserType?: Maybe<UserType>;
  /** Discount's end date */
  endDate?: Maybe<Scalars['Instant']>;
  /** Discount's explicit products */
  explicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Discount's content */
  htmlContent?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Discount's implicit products */
  implicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Discount's name */
  name?: Maybe<Scalars['String']>;
  /** Discount's permit stacking status */
  permitStacking?: Maybe<Scalars['Boolean']>;
  /** Discount's slug */
  slug?: Maybe<Scalars['String']>;
  /** Discount's start date */
  startDate?: Maybe<Scalars['Instant']>;
  /** Discount's teaser */
  teaser?: Maybe<Scalars['String']>;
  unusedCustomCouponCodeCount?: Maybe<Scalars['Int']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type DropshipInventoryDetails = {
  __typename?: 'DropshipInventoryDetails';
  /** Result's dropshipper profile */
  dropshipperProfile?: Maybe<DropshipperProfileEntity>;
  /** Result's warehouse quantity available */
  warehouseQuantityAvailable?: Maybe<Scalars['Long']>;
  /** Result's warehouse quantity on shelf */
  warehouseQuantityOnShelf?: Maybe<Scalars['Long']>;
  /** Result's warehouse quantity reserved */
  warehouseQuantityReserved?: Maybe<Scalars['Long']>;
  /** Result's warehouse quantity unshipped */
  warehouseQuantityUnshipped?: Maybe<Scalars['Long']>;
};

export type DropshipperProfileEntity = {
  __typename?: 'DropshipperProfileEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Dropshipper's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Profile's dropshipper */
  dropshipper?: Maybe<PartnerEntity>;
  /** Dropshipper's SKU */
  dropshipperSku?: Maybe<Scalars['String']>;
  /** Dropshipper's enabled */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Profile's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Dropshipper's title */
  title?: Maybe<Scalars['String']>;
  /** Dropshipper's UPC */
  upc?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type GeolocationType = {
  __typename?: 'GeolocationType';
  /** Geolocation's Cit */
  city?: Maybe<Scalars['String']>;
  /** Geolocation's Country */
  country?: Maybe<Scalars['String']>;
  /** Geolocation's IP Address */
  ip?: Maybe<Scalars['String']>;
  /** Geolocation's Latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Geolocation's Longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Geolocation's State */
  state?: Maybe<Scalars['String']>;
  /** Geolocation's Zip */
  zip?: Maybe<Scalars['String']>;
};

export type GiftCardEntity = {
  __typename?: 'GiftCardEntity';
  /** Gift card's activator */
  activatedBy?: Maybe<Scalars['String']>;
  /** Gift card's balance */
  balance?: Maybe<Scalars['BigDecimal']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Gift card's code */
  code?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Gift card's pin */
  pin?: Maybe<Scalars['String']>;
  /** Gift card's transactions */
  transactions?: Maybe<Array<Maybe<GiftCardTransactionEntity>>>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type GiftCardTransactionEntity = {
  __typename?: 'GiftCardTransactionEntity';
  /** Gift card transaction's amount */
  amount?: Maybe<Scalars['BigDecimal']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Gift card transaction's comment */
  comment?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Gift card transaction's timestamp */
  timestamp?: Maybe<Scalars['Instant']>;
  /** Gift card transaction's type */
  transactionType?: Maybe<GiftCardTransactionType>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type GraphQlPage_AnimalEntity = {
  __typename?: 'GraphQLPage_AnimalEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_BlockEntity = {
  __typename?: 'GraphQLPage_BlockEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<BlockEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_BlogEntity = {
  __typename?: 'GraphQLPage_BlogEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<BlogEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_BrandEntity = {
  __typename?: 'GraphQLPage_BrandEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<BrandEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_CareSheetEntity = {
  __typename?: 'GraphQLPage_CareSheetEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<CareSheetEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_CategoryEntity = {
  __typename?: 'GraphQLPage_CategoryEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ConfigurableProductEntity = {
  __typename?: 'GraphQLPage_ConfigurableProductEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ConfigurableProductEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_DepartmentEntity = {
  __typename?: 'GraphQLPage_DepartmentEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_DiscountEntity = {
  __typename?: 'GraphQLPage_DiscountEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_DropshipInventoryDetails = {
  __typename?: 'GraphQLPage_DropshipInventoryDetails';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<DropshipInventoryDetails>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_DropshipperProfileEntity = {
  __typename?: 'GraphQLPage_DropshipperProfileEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<DropshipperProfileEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_GiftCardEntity = {
  __typename?: 'GraphQLPage_GiftCardEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<GiftCardEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_KitProductEntity = {
  __typename?: 'GraphQLPage_KitProductEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<KitProductEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ListedProductEntity = {
  __typename?: 'GraphQLPage_ListedProductEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ListedProductEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_MediaEntity = {
  __typename?: 'GraphQLPage_MediaEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<MediaEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_PartnerEntity = {
  __typename?: 'GraphQLPage_PartnerEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<PartnerEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_PaymentTermsAccountEntity = {
  __typename?: 'GraphQLPage_PaymentTermsAccountEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<PaymentTermsAccountEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ProductEntity = {
  __typename?: 'GraphQLPage_ProductEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ProductEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ProfitabilitySummary = {
  __typename?: 'GraphQLPage_ProfitabilitySummary';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ProfitabilitySummary>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_PromotionEntity = {
  __typename?: 'GraphQLPage_PromotionEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_PurchaseOrderEntity = {
  __typename?: 'GraphQLPage_PurchaseOrderEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<PurchaseOrderEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_PurchaseRequestEntity = {
  __typename?: 'GraphQLPage_PurchaseRequestEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<PurchaseRequestEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ReviewEntity = {
  __typename?: 'GraphQLPage_ReviewEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ReviewEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_SalesOrderEntity = {
  __typename?: 'GraphQLPage_SalesOrderEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<SalesOrderEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ShipmentBatchEntity = {
  __typename?: 'GraphQLPage_ShipmentBatchEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ShipmentBatchEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ShipmentEntity = {
  __typename?: 'GraphQLPage_ShipmentEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_ShippingRuleSetEntity = {
  __typename?: 'GraphQLPage_ShippingRuleSetEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<ShippingRuleSetEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_SimpleProductEntity = {
  __typename?: 'GraphQLPage_SimpleProductEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<SimpleProductEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_SubscriptionEntity = {
  __typename?: 'GraphQLPage_SubscriptionEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<SubscriptionEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_SupplierEntity = {
  __typename?: 'GraphQLPage_SupplierEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<SupplierEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_TeamEntity = {
  __typename?: 'GraphQLPage_TeamEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<TeamEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_TradeShowEntity = {
  __typename?: 'GraphQLPage_TradeShowEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<TradeShowEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_UserEntity = {
  __typename?: 'GraphQLPage_UserEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<UserEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type GraphQlPage_WarehouseEntity = {
  __typename?: 'GraphQLPage_WarehouseEntity';
  /** Total elements count. */
  count: Scalars['Long'];
  /** Collection of object on the current page. */
  data?: Maybe<Array<Maybe<WarehouseEntity>>>;
  /** The current page number. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** If the collection is not pages it means it displays all elements in the current view. */
  paged: Scalars['Boolean'];
  /** Total pages count. */
  pagesCount?: Maybe<Scalars['Int']>;
};

export type HoldLocation = {
  __typename?: 'HoldLocation';
  /** Hold location's address type */
  addressType?: Maybe<AddressType>;
  /** Hold location's city */
  city?: Maybe<Scalars['String']>;
  /** Hold location's company */
  company?: Maybe<Scalars['String']>;
  /** Hold location's country */
  country?: Maybe<Scalars['String']>;
  /** Hold location's line 1 */
  line1?: Maybe<Scalars['String']>;
  /** Hold location's line 2 */
  line2?: Maybe<Scalars['String']>;
  /** Hold location's postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Hold location's state */
  state?: Maybe<Scalars['String']>;
};

export type HolidayTimeEntity = {
  __typename?: 'HolidayTimeEntity';
  closeTime?: Maybe<Scalars['LocalTime']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  date?: Maybe<Scalars['LocalDate']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  openTime?: Maybe<Scalars['LocalTime']>;
  shipsScheduled?: Maybe<Scalars['Boolean']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type InventoryConsistency = {
  __typename?: 'InventoryConsistency';
  /** Result's lost sales */
  lostSales?: Maybe<Scalars['BigDecimal']>;
  /** Result's product */
  product?: Maybe<SimpleProductEntity>;
  /** Result's warehouse quantity available */
  quantityAvailableForSale?: Maybe<Scalars['Long']>;
  /** Result's warehouse quantity on shelf */
  quantityOnShelf?: Maybe<Scalars['Long']>;
  /** Result's stock-out rate */
  stockOutRate?: Maybe<Scalars['Float']>;
  /** Result's consumption rate */
  weeklyConsumptionRate?: Maybe<Scalars['Float']>;
};

export type InventoryDetails = {
  __typename?: 'InventoryDetails';
  /** Result's product */
  product?: Maybe<SimpleProductEntity>;
  /** Result's warehouse quantity available */
  warehouseQuantityAvailable?: Maybe<Scalars['Long']>;
  /** Result's warehouse quantity on shelf */
  warehouseQuantityOnShelf?: Maybe<Scalars['Long']>;
  /** Result's warehouse quantity unshipped */
  warehouseQuantityUnshipped?: Maybe<Scalars['Long']>;
  /** Result's warehouse run out days, 50% confidence */
  warehouseRunOutDays50?: Maybe<Scalars['Float']>;
  /** Result's warehouse run out days, 85% confidence */
  warehouseRunOutDays85?: Maybe<Scalars['Float']>;
  /** Result's warehouse run out days, 97% confidence */
  warehouseRunOutDays97?: Maybe<Scalars['Float']>;
  /** Result's warehouse weekly consumption rate */
  warehouseWeeklyConsumptionRate?: Maybe<Scalars['Float']>;
  /** Result's warehouse weekly consumption variance */
  warehouseWeeklyConsumptionVariance?: Maybe<Scalars['Float']>;
};

export type InventoryHistory = {
  __typename?: 'InventoryHistory';
  /** Result's quantity changed */
  changeQuantity?: Maybe<Scalars['Long']>;
  /** Result's change reason */
  changeType?: Maybe<InventoryChangeType>;
  /** Result's quantity available for sale */
  quantityAvailable?: Maybe<Scalars['Long']>;
  /** Result's quantity on shelf */
  quantityOnShelf?: Maybe<Scalars['Long']>;
  /** Result's timestamp */
  timestamp?: Maybe<Scalars['Instant']>;
  /** Result's user */
  user?: Maybe<Scalars['String']>;
};

export type InventoryQuantityCacheEntity = {
  __typename?: 'InventoryQuantityCacheEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Cache's quantity available for sale */
  quantityAvailableForSale?: Maybe<Scalars['Long']>;
  /** Cache's quantity on shelf */
  quantityOnShelf?: Maybe<Scalars['Long']>;
  /** Cache's quantity reserved */
  quantityReserved?: Maybe<Scalars['Long']>;
  /** Cache's timestamp */
  timestamp?: Maybe<Scalars['Instant']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Cache's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
};

export type InventoryStatsCacheEntity = {
  __typename?: 'InventoryStatsCacheEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Cache's timestamp */
  timestamp?: Maybe<Scalars['Instant']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Cache's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
  /** Cache's weekly consumption rate */
  weeklyConsumptionRate?: Maybe<Scalars['Float']>;
  /** Cache's weekly consumption variance */
  weeklyConsumptionVariance?: Maybe<Scalars['Float']>;
};

export type JournalEntry = {
  __typename?: 'JournalEntry';
  /** Journal entry's accounts receivable */
  accountsReceivable?: Maybe<Scalars['BigDecimal']>;
  /** Journal entry's end date */
  endDate?: Maybe<Scalars['LocalDate']>;
  /** Journal entry's product refunds */
  productRefunds?: Maybe<Scalars['BigDecimal']>;
  /** Journal entry's product sales */
  productSales?: Maybe<Scalars['BigDecimal']>;
  /** Journal entry's sales tax expenses */
  salesTaxExpense?: Maybe<Scalars['BigDecimal']>;
  /** Journal entry's sales tax liability */
  salesTaxLiability?: Maybe<Scalars['BigDecimal']>;
  /** Journal entry's shipping refunds */
  shippingRefunds?: Maybe<Scalars['BigDecimal']>;
  /** Journal entry's shipping sales */
  shippingSales?: Maybe<Scalars['BigDecimal']>;
  /** Journal entry's start date */
  startDate?: Maybe<Scalars['LocalDate']>;
};

export type KitItemEntity = {
  __typename?: 'KitItemEntity';
  /** Kit item's altnerate quantity */
  altQuantity?: Maybe<Scalars['Long']>;
  /** Kit item's alternate simple product */
  altSimpleProduct?: Maybe<SimpleProductEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Kit item's position */
  position?: Maybe<Scalars['Long']>;
  /** Kit item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Kit item's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type KitProductEntity = {
  __typename?: 'KitProductEntity';
  /** Product's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Product's Amazon profile */
  amazonProfile?: Maybe<AmazonProfileEntity>;
  /** Product's average rating */
  averageRating?: Maybe<Scalars['Float']>;
  /** Product's blogs */
  blogs?: Maybe<Array<Maybe<BlogEntity>>>;
  /** Simple product's brand */
  brand?: Maybe<BrandEntity>;
  /** Product's care sheets */
  careSheets?: Maybe<Array<Maybe<CareSheetEntity>>>;
  /** Product's Chewy profile */
  chewyProfile?: Maybe<ChewyProfileEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Product's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Product's number of ratings */
  countRating?: Maybe<Scalars['Long']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Product's cross sell items */
  crossSellItems?: Maybe<Array<Maybe<CrossSellItemEntity>>>;
  crossSells?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Product's dropshipped flag */
  dropshipped?: Maybe<Scalars['Boolean']>;
  /** Product's dropship partner */
  dropshipper?: Maybe<PartnerEntity>;
  /** Product's explicit animals */
  explicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's explicit categories */
  explicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's explicit department */
  explicitDepartment?: Maybe<DepartmentEntity>;
  /** Product's explicit discounts */
  explicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's explicit promotions */
  explicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's featured status */
  featured?: Maybe<Scalars['Boolean']>;
  /** Product's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Product's implicit animals */
  implicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's implicit categories */
  implicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's implicit departments */
  implicitDepartments?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Product's implicit discounts */
  implicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's implicit promotions */
  implicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's important status */
  important?: Maybe<Scalars['Boolean']>;
  /** Kit's items */
  kitItems?: Maybe<Array<Maybe<KitItemEntity>>>;
  /** Product's live flag */
  live?: Maybe<Scalars['Boolean']>;
  /** Product's Magento Id */
  magentoId?: Maybe<Scalars['String']>;
  /** Product's manufacture time */
  manufactureTime?: Maybe<Scalars['BigDecimal']>;
  /** Product's medias */
  medias?: Maybe<Array<Maybe<ProductMediaEntity>>>;
  /** Product's meta description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Product's meta keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Product's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Product's Pet Supplies Plus profile */
  petSuppliesPlusProfile?: Maybe<PetSuppliesPlusProfileEntity>;
  /** Product's Petco dropship profile */
  petcoDropshipProfile?: Maybe<PetcoDropshipProfileEntity>;
  /** Product's Petco store direct profile */
  petcoStoreDirectProfile?: Maybe<PetcoStoreDirectProfileEntity>;
  /** Product's popularity */
  popularity?: Maybe<Scalars['BigDecimal']>;
  /** Product's prep type */
  prepType?: Maybe<PrepType>;
  /** Product's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Product's price tiers */
  priceTiers?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's published revision */
  publishedRevision?: Maybe<ProductRevisionEntity>;
  /** Product's quantity available for sale */
  quantityAvailableForSale?: Maybe<Scalars['Long']>;
  /** Product's quantity available for sale on speciality markets */
  quantityAvailableForSpeciality?: Maybe<Scalars['Long']>;
  /** Product's reseller profiles */
  resellerProfiles?: Maybe<Array<Maybe<ResellerProfileEntity>>>;
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's shipping estimates */
  shippingEstimates?: Maybe<Array<Maybe<ShippingEstimateEntity>>>;
  /** Product's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Product's shipping rule set */
  shippingRuleSet?: Maybe<ShippingRuleSetEntity>;
  /** Product's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Product's slug */
  slug?: Maybe<Scalars['String']>;
  /** Product's special price */
  specialPrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's subscription status */
  subscription?: Maybe<Scalars['Boolean']>;
  tieredPrices?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's title */
  title?: Maybe<Scalars['String']>;
  /** Product's UPC */
  upc?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Product's upsells */
  upsellProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  userReview?: Maybe<ReviewEntity>;
  /** Product's variation key */
  variationKey?: Maybe<Scalars['String']>;
  /** Product's variation set */
  variationSet?: Maybe<VariationSetEntity>;
  /** Product's visible flag */
  visible?: Maybe<Scalars['Boolean']>;
  /** Product's Walmart profile */
  walmartProfile?: Maybe<WalmartProfileEntity>;
  /** Product's wholesale price */
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
};

export type LineItem = {
  __typename?: 'LineItem';
  /** Line item's line amount */
  lineAmount?: Maybe<Scalars['BigDecimal']>;
  /** Line item's name */
  name?: Maybe<Scalars['String']>;
  /** Line item's order number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Line item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Line item's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Line item's unit amount */
  unitAmount?: Maybe<Scalars['BigDecimal']>;
};

export type ListedProductEntity = {
  __typename?: 'ListedProductEntity';
  /** Product's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Product's Amazon profile */
  amazonProfile?: Maybe<AmazonProfileEntity>;
  /** Product's average rating */
  averageRating?: Maybe<Scalars['Float']>;
  /** Listed product's bins */
  bins?: Maybe<Array<Maybe<BinEntity>>>;
  /** Product's blogs */
  blogs?: Maybe<Array<Maybe<BlogEntity>>>;
  /** Simple product's brand */
  brand?: Maybe<BrandEntity>;
  /** Product's care sheets */
  careSheets?: Maybe<Array<Maybe<CareSheetEntity>>>;
  /** Product's Chewy profile */
  chewyProfile?: Maybe<ChewyProfileEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Product's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Product's number of ratings */
  countRating?: Maybe<Scalars['Long']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Product's cross sell items */
  crossSellItems?: Maybe<Array<Maybe<CrossSellItemEntity>>>;
  crossSells?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Product's dropshipped flag */
  dropshipped?: Maybe<Scalars['Boolean']>;
  /** Product's dropship partner */
  dropshipper?: Maybe<PartnerEntity>;
  /** Product's explicit animals */
  explicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's explicit categories */
  explicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's explicit department */
  explicitDepartment?: Maybe<DepartmentEntity>;
  /** Product's explicit discounts */
  explicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's explicit promotions */
  explicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's featured status */
  featured?: Maybe<Scalars['Boolean']>;
  /** Product's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Listed product's height */
  height?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Product's implicit animals */
  implicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's implicit categories */
  implicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's implicit departments */
  implicitDepartments?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Product's implicit discounts */
  implicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's implicit promotions */
  implicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's important status */
  important?: Maybe<Scalars['Boolean']>;
  /** Listed product's length */
  length?: Maybe<Scalars['BigDecimal']>;
  /** Listed product's listed product type */
  listedProductType?: Maybe<ListedProductType>;
  /** Listed product's listings */
  listings?: Maybe<Array<Maybe<ListingEntity>>>;
  /** Product's live flag */
  live?: Maybe<Scalars['Boolean']>;
  /** Product's Magento Id */
  magentoId?: Maybe<Scalars['String']>;
  /** Product's manufacture time */
  manufactureTime?: Maybe<Scalars['BigDecimal']>;
  /** Product's medias */
  medias?: Maybe<Array<Maybe<ProductMediaEntity>>>;
  /** Product's meta description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Product's meta keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Product's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Product's Pet Supplies Plus profile */
  petSuppliesPlusProfile?: Maybe<PetSuppliesPlusProfileEntity>;
  /** Product's Petco dropship profile */
  petcoDropshipProfile?: Maybe<PetcoDropshipProfileEntity>;
  /** Product's Petco store direct profile */
  petcoStoreDirectProfile?: Maybe<PetcoStoreDirectProfileEntity>;
  /** Product's popularity */
  popularity?: Maybe<Scalars['BigDecimal']>;
  /** Product's prep type */
  prepType?: Maybe<PrepType>;
  /** Product's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Product's price tiers */
  priceTiers?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's published revision */
  publishedRevision?: Maybe<ProductRevisionEntity>;
  /** Product's quantity available for sale */
  quantityAvailableForSale?: Maybe<Scalars['Long']>;
  /** Product's quantity available for sale on speciality markets */
  quantityAvailableForSpeciality?: Maybe<Scalars['Long']>;
  /** Product's reseller profiles */
  resellerProfiles?: Maybe<Array<Maybe<ResellerProfileEntity>>>;
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Listed product's sales tax rules */
  salesTaxRules?: Maybe<SalesTaxRules>;
  /** Product's shipping estimates */
  shippingEstimates?: Maybe<Array<Maybe<ShippingEstimateEntity>>>;
  /** Listed product's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Listed product's shipping rule set */
  shippingRuleSet?: Maybe<ShippingRuleSetEntity>;
  /** Product's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Product's slug */
  slug?: Maybe<Scalars['String']>;
  /** Product's special price */
  specialPrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's subscription status */
  subscription?: Maybe<Scalars['Boolean']>;
  tieredPrices?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's title */
  title?: Maybe<Scalars['String']>;
  /** Product's UPC */
  upc?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Product's upsells */
  upsellProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  userReview?: Maybe<ReviewEntity>;
  /** Product's variation key */
  variationKey?: Maybe<Scalars['String']>;
  /** Product's variation set */
  variationSet?: Maybe<VariationSetEntity>;
  /** Product's visible flag */
  visible?: Maybe<Scalars['Boolean']>;
  /** Product's Walmart profile */
  walmartProfile?: Maybe<WalmartProfileEntity>;
  /** Listed product's warehouses */
  warehouses?: Maybe<Array<Maybe<WarehouseEntity>>>;
  /** Listed product's weight */
  weight?: Maybe<Scalars['BigDecimal']>;
  /** Product's wholesale price */
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
  /** Listed product's width */
  width?: Maybe<Scalars['BigDecimal']>;
  /** Listed product's zones */
  zones?: Maybe<Array<Maybe<ZoneEntity>>>;
};

export type ListingEntity = {
  __typename?: 'ListingEntity';
  /** Listing's birth date */
  birthDate?: Maybe<Scalars['LocalDate']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Listing's comments */
  comments?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Listing's height */
  height?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Listing's identifier */
  identifier?: Maybe<Scalars['String']>;
  /** Listing's length */
  length?: Maybe<Scalars['BigDecimal']>;
  /** Listing's listed product */
  listedProduct?: Maybe<ListedProductEntity>;
  /** Listing's listing media entities */
  listingMedias?: Maybe<Array<Maybe<ListingMediaEntity>>>;
  /** Listing's weight unit */
  listingWeightUnit?: Maybe<ListingWeightUnit>;
  /** Listing's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Listing's quantity */
  quantity?: Maybe<Scalars['Long']>;
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Listing's sex */
  sex?: Maybe<SexType>;
  /** Listing's special price */
  specialPrice?: Maybe<Scalars['BigDecimal']>;
  /** Listing's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Listing's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
  /** Listing's weight */
  weight?: Maybe<Scalars['BigDecimal']>;
  /** Listing's weight date */
  weightDate?: Maybe<Scalars['LocalDate']>;
  /** Listing's width */
  width?: Maybe<Scalars['BigDecimal']>;
};

export type ListingMediaEntity = {
  __typename?: 'ListingMediaEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Listing media's listing */
  listing?: Maybe<ListingEntity>;
  /** Listing media's media */
  media?: Maybe<MediaEntity>;
  /** Listing media's type */
  mediaType?: Maybe<MediaType>;
  /** Listing media's sequence */
  sequence?: Maybe<Scalars['Int']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Listing media's url */
  url?: Maybe<Scalars['String']>;
};

export type LiveArrivalGuaranteeEntity = {
  __typename?: 'LiveArrivalGuaranteeEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Live arrival rule's max temp */
  maxTemp?: Maybe<Scalars['Int']>;
  /** Live arrival rule's min temp */
  minTemp?: Maybe<Scalars['Int']>;
  /** Shipping rule's slug */
  slug?: Maybe<Scalars['String']>;
  /** Live arrival rule's transit days */
  transitDays?: Maybe<Scalars['Long']>;
  /** Live arrival rule's transit time type */
  transitTimeType?: Maybe<TransitTimeType>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type MagentoSalesOrderEntity = {
  __typename?: 'MagentoSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' type */
  billingAddressType?: Maybe<AddressType>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Sales order's fulfillment network */
  fulfillmentNetwork?: Maybe<FulfillmentNetworkType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Magento created at */
  magentoCreatedAt?: Maybe<Scalars['Instant']>;
  /** Magento order status */
  magentoStatus?: Maybe<Scalars['String']>;
  /** Magento updated at */
  magentoUpdatedAt?: Maybe<Scalars['Instant']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payment terms account */
  paymentTermsAccount?: Maybe<PaymentTermsAccountEntity>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Sales order's listed items */
  salesOrderListedItems?: Maybe<Array<Maybe<SalesOrderListedItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' type */
  shippingAddressType?: Maybe<AddressType>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type MediaEntity = {
  __typename?: 'MediaEntity';
  /** Media's categories */
  categories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Media's keywords */
  keywords?: Maybe<Scalars['String']>;
  /** Media's listing medias */
  listingMedias?: Maybe<Array<Maybe<ListingMediaEntity>>>;
  /** Media's product medias */
  productMedias?: Maybe<Array<Maybe<ProductMediaEntity>>>;
  /** Media's review */
  review?: Maybe<ReviewEntity>;
  /** Media's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Media's url */
  url?: Maybe<Scalars['String']>;
};

/** Mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  /** Removes an listed product */
  listedProductRemove?: Maybe<Scalars['Boolean']>;
  /** Handles an enrollment request */
  partnerJoin?: Maybe<Scalars['Boolean']>;
  /** Uploads an image and adds it to the kit */
  kitProductUploadImage?: Maybe<KitProductEntity>;
  /** Clears the cart's tax exempt flag */
  assistedCartClearTaxExempt?: Maybe<CartEntity>;
  /** Cancels the order and removes the shipments */
  salesOrderOnlineRefund?: Maybe<SalesOrderEntity>;
  /** Add cross sells to a kit product */
  kitProductAddCrossSellProducts?: Maybe<KitProductEntity>;
  /** Sets a warehouse's alternate slug */
  warehouseSetAlternateSlug?: Maybe<WarehouseEntity>;
  /** Filters to shipments with a simple product */
  shipmentFilterUnshippedBySimpleProduct?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Sets whether stacking is permitted for the discount */
  discountSetPermitStacking?: Maybe<DiscountEntity>;
  /** Sets a simple product's shipping needs type */
  simpleProductSetShippingNeedsType?: Maybe<SimpleProductEntity>;
  /** Returns possible delivery dates */
  scheduledShipmentPossibleDeliveryDatesByHash?: Maybe<Array<Maybe<Scalars['String']>>>;
  dropshipWarehouseWorkScheduleClearOpenCloseTime?: Maybe<WarehouseEntity>;
  /** Add a price tier to a kit product */
  kitProductAddPriceTier?: Maybe<KitProductEntity>;
  /** Sets a category's meta keywords */
  categorySetMetaKeywords?: Maybe<CategoryEntity>;
  /** Vaults a payment method for the specified user. */
  assistedPaymentMethodCreate?: Maybe<PaymentMethodDetail>;
  /** Adds an existing image to the blog */
  blogAddImage?: Maybe<BlogEntity>;
  /** Looks up weather for shipment */
  shipmentLookupWeather?: Maybe<Array<Maybe<Weather>>>;
  /** Sets the cart's tradeshow */
  assistedCartSetTradeshow?: Maybe<CartEntity>;
  /** Updates a subscription's next delivery date for a single item or for the whole subscription */
  assistedSubscriptionSetNextDeliveryDate?: Maybe<SubscriptionEntity>;
  /** Set the position of a product list item */
  affiliateSetProductListItemPosition?: Maybe<AffiliateProductListEntity>;
  /** Remove an existing review */
  reviewRemove?: Maybe<ReviewEntity>;
  /** Splits a shipment into two smaller shipments */
  shipmentSplit?: Maybe<ShipmentEntity>;
  /** Removes departments from the discount */
  discountRemoveDepartments?: Maybe<DiscountEntity>;
  /** Sets the slug of the discount */
  discountSetSlug?: Maybe<DiscountEntity>;
  /** Sets a listed product's shipping needs type */
  listedProductSetShippingNeedsType?: Maybe<ListedProductEntity>;
  /** Syncs purchase orders */
  maintenanceSalesOrderIdentifyHouseholds?: Maybe<Scalars['Boolean']>;
  /** Sets a kit product's featured flag */
  kitProductSetFeatured?: Maybe<KitProductEntity>;
  /** Adds carriers to a warehouse */
  warehouseAddCarriers?: Maybe<WarehouseEntity>;
  /** Sets the type of the promotion */
  promotionSetPromotionType?: Maybe<PromotionEntity>;
  /** Sets a category's image */
  categorySetImage?: Maybe<CategoryEntity>;
  /** Sets the name of the discount */
  discountSetName?: Maybe<DiscountEntity>;
  /** Sets an listed product's special price */
  listedProductSetSpecialPrice?: Maybe<ListedProductEntity>;
  /** Summarizes a product's description */
  productSummarize?: Maybe<Scalars['String']>;
  /** Approves a purchase request */
  purchaseRequestApprove?: Maybe<PurchaseRequestEntity>;
  /** Custom refunds an order. */
  salesOrderCustomRefundSummarize?: Maybe<RefundEntity>;
  /** Removes stale carts from the database */
  maintenanceDeleteStaleCarts?: Maybe<Scalars['Boolean']>;
  /** Clones a configurable product */
  configurableProductClone?: Maybe<ConfigurableProductEntity>;
  /** Assigns a department to a product */
  productSetDepartment?: Maybe<ProductEntity>;
  /** Removes zones from a warehouse */
  warehouseRemoveZones?: Maybe<WarehouseEntity>;
  /** Sets a configurable product's slug */
  configurableProductSetSlug?: Maybe<ConfigurableProductEntity>;
  /** Removes a warehouse from a simple product */
  simpleProductRemoveWarehouse?: Maybe<SimpleProductEntity>;
  /** Creates a ticket with customer service */
  scheduledShipmentContactCustomerService?: Maybe<Scalars['Boolean']>;
  /** Sends the tradeshow report */
  tradeShowSendReport?: Maybe<Scalars['Boolean']>;
  /** Creates a new tradeshow */
  tradeShowCreateShow?: Maybe<TradeShowEntity>;
  /** Clears the service level for a cart item */
  assistedCartClearServiceTypePreference?: Maybe<CartEntity>;
  /** Refreshes a sales order (with Magento) by entity id */
  magentoSalesOrderRefresh?: Maybe<MagentoSalesOrderEntity>;
  /** Sets the active flag */
  promotionSetActive?: Maybe<PromotionEntity>;
  /** Reorders off of a past sales order */
  cartReorder?: Maybe<CartEntity>;
  /** Refreshes a sales order (with Magento) by entity id */
  magentoSalesOrderRefreshAsync: Scalars['Boolean'];
  /** Removes departments from the promotion */
  promotionRemoveDepartments?: Maybe<PromotionEntity>;
  /** Create a subscription for a new item */
  assistedSubscriptionCreate?: Maybe<SubscriptionEntity>;
  /** Triggers a re-sync of MFN inventory with Amazon */
  maintenanceAmazonUpdateInventory?: Maybe<Scalars['Boolean']>;
  /** Generates a commercial invoice PDF */
  shipmentGenerateCommercialInvoice?: Maybe<Scalars['String']>;
  /** Creates a web flow for Josh's Frogs Pay checkout */
  cartAuthorizeUrl?: Maybe<Scalars['String']>;
  /** Adds categories to the discount */
  discountAddCategories?: Maybe<DiscountEntity>;
  /** Updates a queue */
  queueUpdate?: Maybe<QueueEntity>;
  /** Sets the sequence of a product media */
  simpleProductSetProductMediaSequence?: Maybe<SimpleProductEntity>;
  /** Sets a warehouse's longitude location */
  warehouseSetLongitude?: Maybe<WarehouseEntity>;
  /** Generates laser labels PDF */
  dropshipShipmentGenerateLaserLabels?: Maybe<Scalars['String']>;
  /** Sets a partner's dropshipper flag */
  partnerSetDropshipper?: Maybe<PartnerEntity>;
  /** Sets the eligible coupon code of the promotion */
  promotionSetEligibleCouponCode?: Maybe<PromotionEntity>;
  /** Remove an existing review */
  assistedReviewRemove?: Maybe<ReviewEntity>;
  /** Clears the service level */
  subscriptionClearServiceLevel?: Maybe<SubscriptionEntity>;
  /** Creates a partner */
  partnerCreate?: Maybe<PartnerEntity>;
  /** Adds departments to the discount */
  discountAddDepartments?: Maybe<DiscountEntity>;
  /** Triggers sync of products with Poynt */
  maintenancePoyntSyncProducts?: Maybe<Scalars['Boolean']>;
  /** Deletes a brand */
  brandDelete: Scalars['Boolean'];
  /** Recomputes shipping estimates for an listed product */
  listedProductRecomputeShippingEstimate?: Maybe<ListedProductEntity>;
  /** Updates Drop Shipper Inventory */
  maintenanceDropshipperInventory?: Maybe<Scalars['Boolean']>;
  /** Sets the eligible cart value of the discount */
  discountSetEligibleCartValue?: Maybe<DiscountEntity>;
  /** Set the position of a cross sell item */
  kitProductSetCrossSellItemPosition?: Maybe<KitProductEntity>;
  /** Clears the shipping address */
  assistedCartClearShippingAddress?: Maybe<CartEntity>;
  /** Generates a packing list PDF */
  dropshipShipmentGeneratePackingList?: Maybe<Scalars['String']>;
  /** Creates a simple product */
  simpleProductCreate?: Maybe<SimpleProductEntity>;
  /** Sets the carrier preference */
  assistedSubscriptionSetCarrierPreference?: Maybe<SubscriptionEntity>;
  /** Remove a price tier from a simple product */
  simpleProductRemovePriceTier?: Maybe<SimpleProductEntity>;
  /** Updates NW Zoo Inventory */
  maintenanceNwZoologicalInventory?: Maybe<Scalars['Boolean']>;
  /** Update an existing promotion */
  promotionUpdate?: Maybe<PromotionEntity>;
  /** Sets an affiliate's slug */
  affiliateSetSlug?: Maybe<PartnerEntity>;
  /** Sets the discount amount of the discount */
  discountSetDiscountAmount?: Maybe<DiscountEntity>;
  /** Provides cart info for the user's ID */
  assistedCartLookup?: Maybe<CartEntity>;
  /** Sets the shipping zip code, as a shortcut for shipping rates */
  cartSetShippingZip?: Maybe<CartEntity>;
  /** Create */
  petcoSdProfileCreate?: Maybe<PetcoStoreDirectProfileEntity>;
  /** Removes a component item from the simple product */
  simpleProductRemoveComponentItem?: Maybe<SimpleProductEntity>;
  /** Remove a cross sell from an listed product */
  listedProductRemoveCrossSellProduct?: Maybe<ListedProductEntity>;
  /** Adds a payment terms payment to an order */
  salesOrderAddPaymentTermsPayment?: Maybe<SalesOrderEntity>;
  /** Schedule a shipment for today or a future date */
  scheduledShipmentSchedule?: Maybe<ShipmentEntity>;
  /** Sets the billing address to one of the user's addresses */
  assistedCartSetBillingAddressById?: Maybe<CartEntity>;
  dropshipWarehouseWorkScheduleCreateHoliday?: Maybe<WarehouseEntity>;
  /** Sets a simple product's cost */
  simpleProductSetCost?: Maybe<SimpleProductEntity>;
  /** Removes a product media from the configurable product */
  configurableProductRemoveProductMedia?: Maybe<ConfigurableProductEntity>;
  /** Update's a user's email address */
  userUpdateEmail?: Maybe<UserEntity>;
  /** Creates a listing for a listed product */
  listedProductCreateListing?: Maybe<ListedProductEntity>;
  /** Summarizes the cart's totals */
  assistedCartSummarize?: Maybe<SalesOrderEntity>;
  /** Clears the cart's phone */
  cartClearPhone?: Maybe<CartEntity>;
  /** Remove a price tier from an listed product */
  listedProductRemovePriceTier?: Maybe<ListedProductEntity>;
  /** Sets a kit product's published revision */
  kitProductSetPublishedRevision?: Maybe<KitProductEntity>;
  /** Confirms a shipment */
  shipmentConfirm?: Maybe<ShipmentEntity>;
  /** Subscribe to a new item */
  assistedSubscriptionAddItem?: Maybe<SubscriptionEntity>;
  /** Generates a shipment dashboard */
  shipmentDashboard?: Maybe<Dashboard>;
  /** Attempts to pre-label a shipment */
  shipmentAttemptPreLabel?: Maybe<ShipmentEntity>;
  /** Sets a partner's affiliate flag */
  partnerSetAffiliate?: Maybe<PartnerEntity>;
  /** Sets the fulfillment network for a cart */
  assistedCartSetFulfillmentNetwork?: Maybe<CartEntity>;
  /** Denies a purchase request */
  purchaseRequestDeny?: Maybe<PurchaseRequestEntity>;
  /** Clears the fulfilment network for a cart */
  assistedCartClearFulfillmentNetwork?: Maybe<CartEntity>;
  /** Returns shipping estimates for an listed product */
  listedProductShippingEstimates?: Maybe<Array<Maybe<ShippingEstimateEntity>>>;
  /** Enrolls a workstation for use with AutoPrint */
  autoprintEnrollWorkstation?: Maybe<WorkstationEntity>;
  /** Ships a multi-piece shipment */
  shipmentShipMultiPiece?: Maybe<ShipmentEntity>;
  /** Reprocess a shipment */
  shipmentReprocess?: Maybe<ShipmentEntity>;
  /** Redirects based on a path */
  redirectFindByPath?: Maybe<Scalars['String']>;
  /** Creates a ticket with customer service */
  scheduledShipmentContactCustomerServiceByHash?: Maybe<Scalars['Boolean']>;
  /** Calculates the implicit products a promotion */
  promotionEvaluateImplicitProducts?: Maybe<PromotionEntity>;
  /** Updates a subscription's next delivery date for a single item or for the whole subscription */
  subscriptionSetNextDeliveryDate?: Maybe<SubscriptionEntity>;
  /** Set the html content of a block */
  blockSetHtmlContent?: Maybe<BlockEntity>;
  /** Create a subscription for a new item */
  subscriptionCreate?: Maybe<SubscriptionEntity>;
  /** Sets an listed product's weight */
  listedProductSetWeight?: Maybe<ListedProductEntity>;
  /** Set a simple product's map price */
  simpleProductSetMapPrice?: Maybe<SimpleProductEntity>;
  /** Loads dimension data */
  maintenanceLoadDimensionData?: Maybe<Scalars['Int']>;
  /** Clears a supplier from a simple product */
  simpleProductClearSupplier?: Maybe<SimpleProductEntity>;
  /** Sets the cart's email */
  cartSetEmail?: Maybe<CartEntity>;
  /** Remove a cross sell item from a listed product */
  listedProductRemoveCrossSellItem?: Maybe<ListedProductEntity>;
  /** Sets a carrier preference for a cart item */
  cartSetCarrierPreference?: Maybe<CartEntity>;
  /** Create */
  petcoDsProfileCreate?: Maybe<PetcoDropshipProfileEntity>;
  /** Sets a simple product's featured flag */
  simpleProductSetFeatured?: Maybe<SimpleProductEntity>;
  /** Confirm user's email */
  userConfirmEmail?: Maybe<Scalars['Boolean']>;
  /** Sets inventory for a simple product */
  dropshipInventorySetDetails?: Maybe<DropshipInventoryDetails>;
  /** Deletes a care sheet */
  careSheetDelete: Scalars['Boolean'];
  /** Change a user's password */
  userChangePasswordByHash?: Maybe<Scalars['Boolean']>;
  /** Sets a configurable product's meta title */
  configurableProductSetMetaTitle?: Maybe<ConfigurableProductEntity>;
  /** Sets the shipping zip code, as a shortcut for shipping rates */
  assistedCartSetShippingZip?: Maybe<CartEntity>;
  /** Sets the supplier's email */
  supplierSetEmail?: Maybe<SupplierEntity>;
  /** Adds a purchase request item */
  purchaseRequestAddItem?: Maybe<PurchaseRequestEntity>;
  /** Completes checkout and processes payment. */
  assistedCartCheckout?: Maybe<SalesOrderEntity>;
  /** Sets the subscription's shipping address to the same as its billing address */
  subscriptionSetShippingAddressFromBillingAddress?: Maybe<SubscriptionEntity>;
  /** Cancels the order and removes the shipments */
  salesOrderCancelByHash?: Maybe<SalesOrderEntity>;
  dropshipWarehouseWorkScheduleRemoveHoliday?: Maybe<WarehouseEntity>;
  /** Sets the cart's contact information */
  cartSetContact?: Maybe<CartEntity>;
  /** Assigns a brand to an listed product */
  listedProductSetBrand?: Maybe<ListedProductEntity>;
  /** Replenishes a new gift card, with amount */
  giftCardReload?: Maybe<GiftCardEntity>;
  /** Sets the cart's tradeshow */
  cartSetTradeshow?: Maybe<CartEntity>;
  /** Update's a user's admin status */
  userUpdateAdmin?: Maybe<UserEntity>;
  /** Sets the discount amount of the promotion */
  promotionSetDiscountAmount?: Maybe<PromotionEntity>;
  /** Verifies an existing address */
  addressVerifyRaw?: Maybe<AddressEntity>;
  /** Sets a listing's birth date */
  listedProductSetListingBirthDate?: Maybe<ListingEntity>;
  /** Sets the sequence of a product media */
  kitProductSetProductMediaSequence?: Maybe<KitProductEntity>;
  /** Set the html content of a discount */
  discountSetHtmlContent?: Maybe<DiscountEntity>;
  /** Sets a kit product's meta title */
  kitProductSetMetaTitle?: Maybe<KitProductEntity>;
  /** Adds a Braintree payment to an order */
  salesOrderAddBraintreeOfflinePayment?: Maybe<SalesOrderEntity>;
  /** Generates an invoice PDF from a hash */
  salesOrderGenerateInvoiceByHash?: Maybe<Scalars['String']>;
  /** Uploads an image and adds it to the configurable product */
  configurableProductUploadImage?: Maybe<ConfigurableProductEntity>;
  /** Sets a delivery date preference for a cart item */
  cartSetDeliveryDatePreference?: Maybe<CartEntity>;
  /** Marks the purchase request as received */
  purchaseRequestReceive?: Maybe<PurchaseRequestEntity>;
  /** Sets the eligible cart value of the promotion */
  promotionSetEligibleCartValue?: Maybe<PromotionEntity>;
  /** Reindexes all blogs */
  maintenanceSearchReindexBlog?: Maybe<Scalars['Boolean']>;
  /** Creates a supplier */
  supplierCreate?: Maybe<SupplierEntity>;
  /** Sets a simple product's height */
  simpleProductSetHeight?: Maybe<SimpleProductEntity>;
  /** Sets the eligible user type of the promotion */
  promotionSetEligibleUserType?: Maybe<PromotionEntity>;
  /** Creates a new address */
  assistedAddressCreate?: Maybe<AddressEntity>;
  /** Verifies an existing address */
  addressVerify?: Maybe<AddressEntity>;
  /** Sets an affiliate's attribution period */
  affiliateSetAttributionPeriod?: Maybe<PartnerEntity>;
  /** Sets a product's prep type */
  simpleProductSetPrepType?: Maybe<SimpleProductEntity>;
  /** Refreshes a simple product (with Magento) by sku */
  simpleProductRefreshBySku?: Maybe<SimpleProductEntity>;
  /** Creates a new category */
  categoryCreate?: Maybe<CategoryEntity>;
  /** Removes a kit product */
  kitProductRemove?: Maybe<Scalars['Boolean']>;
  /** Removes a warehouse from a listed product */
  listedProductRemoveWarehouse?: Maybe<ListedProductEntity>;
  /** Returns unused single-use coupon codes */
  discountListUnusedCustomCouponCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Set the title of a blog */
  blogSetTitle?: Maybe<BlogEntity>;
  /** Sets a kit product's subscription flag */
  kitProductSetSubscription?: Maybe<KitProductEntity>;
  /** Subscribe to a new item */
  subscriptionAddItem?: Maybe<SubscriptionEntity>;
  /** Sets an affiliate's commission */
  affiliateSetCommission?: Maybe<PartnerEntity>;
  /** Updates a subscription's billing address */
  subscriptionSetBillingAddress?: Maybe<SubscriptionEntity>;
  /** Assigns a supplier to a simple product */
  simpleProductSetSupplier?: Maybe<SimpleProductEntity>;
  /** Sets a listed product's type */
  listedProductSetListedProductType?: Maybe<ListedProductEntity>;
  /** Sets a department's parent */
  departmentSetParent?: Maybe<DepartmentEntity>;
  /** Sets a listing's weight */
  listedProductSetListingWeight?: Maybe<ListingEntity>;
  /** Add blog topics to a blog */
  blogAddBlogTopics?: Maybe<BlogEntity>;
  /** Deletes a discount */
  discountDelete?: Maybe<Scalars['Boolean']>;
  /** Lists batch print queues for this warehouse */
  batchPrintQueueInfo?: Maybe<BatchPrintQueueEntity>;
  /** Clears a brand from an listed product */
  listedProductClearBrand?: Maybe<ListedProductEntity>;
  /** Sets the name of the promotion */
  promotionSetName?: Maybe<PromotionEntity>;
  /** Set the html content for an affiliate's product list */
  affiliateSetProductListHtmlContent?: Maybe<AffiliateProductListEntity>;
  /** Deletes a blog */
  blogDelete: Scalars['Boolean'];
  /** Sets a listing's price */
  listedProductSetListingPrice?: Maybe<ListingEntity>;
  /** Removes explicit products from the discount */
  discountRemoveExplicitProducts?: Maybe<DiscountEntity>;
  /** Sends a scheduling needed notice */
  assistedScheduledShipmentSendSchedulingNeededNotification?: Maybe<ShipmentEntity>;
  /** Adds items to a product list */
  affiliateAddProductListItems?: Maybe<AffiliateProductListEntity>;
  /** Clears the cart's tax exempt flag */
  cartClearTaxExempt?: Maybe<CartEntity>;
  /** Removes bin from a listed product */
  listedProductClearBin?: Maybe<ListedProductEntity>;
  /** Sets a delivery date preference for a cart item */
  assistedCartSetDeliveryDatePreference?: Maybe<CartEntity>;
  /** Sets the start date of the promotion */
  promotionSetStartDate?: Maybe<PromotionEntity>;
  /** Sets a vaulted payment method as the default for the current user. */
  checkoutSetDefaultPaymentMethod: Scalars['Boolean'];
  /** Sets a configurable product's meta keywords */
  configurableProductSetMetaKeywords?: Maybe<ConfigurableProductEntity>;
  /** Remove a cross sell item from a kit product */
  kitProductRemoveCrossSellItem?: Maybe<KitProductEntity>;
  /** Records an off-line shipment for a shipment */
  dropshipShipmentShipOffline?: Maybe<ShipmentEntity>;
  /** Sets a simple product's visible flag */
  simpleProductSetVisible?: Maybe<SimpleProductEntity>;
  /** Removes a product media from the listed product */
  listedProductRemoveProductMedia?: Maybe<ListedProductEntity>;
  /** Generates a CSV */
  purchaseOrderGenerateCSV?: Maybe<Scalars['String']>;
  /** Sets the brands's name */
  brandSetName?: Maybe<BrandEntity>;
  /** Remove a product from a blog */
  blogRemoveProduct?: Maybe<BlogEntity>;
  /** Removes a simple product */
  simpleProductRemove?: Maybe<Scalars['Boolean']>;
  /** Remove a cross sell item from a simple product */
  simpleProductRemoveCrossSellItem?: Maybe<SimpleProductEntity>;
  /** Uploads an image and adds it to the listed product */
  listedProductUploadImage?: Maybe<ListedProductEntity>;
  /** Vaults a payment method for the current user. */
  checkoutCreatePaymentMethod?: Maybe<PaymentMethodDetail>;
  /** Refreshes a sales order (with PSP) by entity id */
  pspCorporateSalesOrderRefresh?: Maybe<PspCorporateSalesOrderEntity>;
  /** Updates a subscription's shipping address */
  assistedSubscriptionSetShippingAddress?: Maybe<SubscriptionEntity>;
  /** Add a price tier to an listed product */
  listedProductAddPriceTier?: Maybe<ListedProductEntity>;
  /** Changes quantity of selected listed product in user's cart */
  assistedCartChangeQuantityListedProduct?: Maybe<CartEntity>;
  /** Gets the next shipment to print at this workstation's printer */
  autoprintGetNextForPrinter?: Maybe<Array<Maybe<PrintJob>>>;
  /** Sends a shipper performance report */
  maintenanceSendShipperPerformance?: Maybe<Scalars['Boolean']>;
  /** Sets the billing address to one of the user's addresses */
  cartSetBillingAddressById?: Maybe<CartEntity>;
  /** Lists all POS terminals */
  tradeShowListTerminals?: Maybe<Array<Maybe<PosTerminalEntity>>>;
  /** Updates a zone */
  zoneUpdate?: Maybe<ZoneEntity>;
  /** Approves a pending review */
  assistedReviewApprove?: Maybe<ReviewEntity>;
  /** Processes a subscription */
  assistedSubscriptionProcess?: Maybe<SubscriptionEntity>;
  /** Create a new review */
  assistedReviewCreate?: Maybe<ReviewEntity>;
  /** Sets a kit product's wholesale price */
  kitProductSetWholesalePrice?: Maybe<KitProductEntity>;
  /** Creates a new kit product */
  kitProductCreate?: Maybe<KitProductEntity>;
  dropshipWarehouseWorkScheduleSetOpenCloseTime?: Maybe<WarehouseEntity>;
  /** Sets a simple product's meta title */
  simpleProductSetMetaTitle?: Maybe<SimpleProductEntity>;
  /** Sets the end date of the promotion */
  promotionSetEndDate?: Maybe<PromotionEntity>;
  /** Sets the payment token */
  assistedSubscriptionSetPaymentToken?: Maybe<SubscriptionEntity>;
  /** Exports simple products */
  simpleProductExport?: Maybe<Array<Maybe<SimpleProductEntity>>>;
  /** Sets the billing address */
  assistedCartSetBillingAddress?: Maybe<CartEntity>;
  /** Update's a user's name */
  userUpdateName?: Maybe<UserEntity>;
  /** Clears the cart's tradeshow */
  assistedCartClearTradeshow?: Maybe<CartEntity>;
  /** Updates a shipment's address */
  scheduledShipmentUpdateAddress?: Maybe<ShipmentEntity>;
  /** Sets a simple product's special price */
  simpleProductSetSpecialPrice?: Maybe<SimpleProductEntity>;
  /** Clears the cart's email */
  cartClearEmail?: Maybe<CartEntity>;
  /** Add an animal to a listed product */
  listedProductAddAnimal?: Maybe<ListedProductEntity>;
  /** Generates a packing list as a label */
  dropshipShipmentGeneratePackingListZpl?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Creates a new address */
  addressCreate?: Maybe<AddressEntity>;
  /** Deletes an existing address */
  assistedAddressDelete: Scalars['Boolean'];
  /** Indexes a product */
  productIndex?: Maybe<ProductEntity>;
  /** Sets a category's published revision */
  categorySetPublishedRevision?: Maybe<CategoryEntity>;
  /** Sets a simple product's live flag */
  simpleProductSetLive?: Maybe<SimpleProductEntity>;
  /** Remove an animal from a simple product */
  simpleProductRemoveAnimal?: Maybe<SimpleProductEntity>;
  /** Sets a shipment to be reprinted */
  autoprintReprint: Scalars['Boolean'];
  /** Clones a blog */
  blogClone?: Maybe<BlogEntity>;
  warehouseWorkScheduleClearOpenCloseTime?: Maybe<WarehouseEntity>;
  /** Sets the shipping address */
  assistedCartSetShippingAddress?: Maybe<CartEntity>;
  /** Adds a printer to an existing workstation */
  autoprintAddPrinter?: Maybe<PrinterEntity>;
  /** Set the position of a kit item */
  kitProductSetKitItemPosition?: Maybe<KitProductEntity>;
  /** Acknowledges a shipment's print job has downloaded */
  autoprintDownloadAcknowledgeForPrinter: Scalars['Boolean'];
  /** Clears the cart's coupon code */
  assistedCartClearCouponCode?: Maybe<CartEntity>;
  /** Set the slug for an affiliate's product list */
  affiliateSetProductListSlug?: Maybe<AffiliateProductListEntity>;
  /** Sets a simple product's price */
  simpleProductSetPrice?: Maybe<SimpleProductEntity>;
  /** Adds a warehouse to a listed product */
  listedProductAddWarehouse?: Maybe<ListedProductEntity>;
  /** Sets a configurable product's SKU */
  configurableProductSetSku?: Maybe<ConfigurableProductEntity>;
  /** Registers a new user, with raw password */
  userRegister?: Maybe<UserEntity>;
  /** Sets a configurable product's UPC */
  configurableProductSetUpc?: Maybe<ConfigurableProductEntity>;
  /** Updates a subscription's shipping address */
  subscriptionSetShippingAddress?: Maybe<SubscriptionEntity>;
  /** Create a new review for approval */
  reviewCreate?: Maybe<ReviewEntity>;
  /** Add a preparation to a product by shipment item */
  preppedProductsAddPreparationListedLineItem?: Maybe<PreparationSummary>;
  /** Sets a vaulted payment method as the default for the specified user. */
  assistedPaymentMethodSetDefault: Scalars['Boolean'];
  /** Add an animal to a configurable product */
  configurableProductAddAnimal?: Maybe<ConfigurableProductEntity>;
  /** Add cross sells to an listed product */
  listedProductAddCrossSellProducts?: Maybe<ListedProductEntity>;
  /** Creates a new block */
  blockCreate?: Maybe<BlockEntity>;
  /** Set the name for an affiliate's product list */
  affiliateSetProductListName?: Maybe<AffiliateProductListEntity>;
  /** Sets shipping preferences for a cart item */
  cartSetShippingPreferences?: Maybe<CartEntity>;
  /** Skips the subscription's next delivery */
  subscriptionSkipNextDelivery?: Maybe<SubscriptionEntity>;
  /** Refreshes a kit product (with Magento) by sku */
  kitProductRefreshBySku?: Maybe<KitProductEntity>;
  /** Sets the product's BOM */
  simpleProductSetBom?: Maybe<SimpleProductEntity>;
  /** Recomputes shipping estimates for a simple product */
  simpleProductRecomputeShippingEstimate?: Maybe<SimpleProductEntity>;
  /** Removes a configurable product */
  configurableProductRemove?: Maybe<Scalars['Boolean']>;
  /** Release subscriptions */
  maintenanceReleaseSubscriptions?: Maybe<Scalars['Boolean']>;
  /** Clears a product's discontinuation reason */
  simpleProductClearDiscontinuationReason?: Maybe<SimpleProductEntity>;
  /** Sets a kit product's title */
  kitProductSetTitle?: Maybe<KitProductEntity>;
  /** Sets a listing's warehouse */
  listedProductSetListingWarehouse?: Maybe<ListingEntity>;
  /** Updates a user's password */
  userUpdatePassword?: Maybe<UserEntity>;
  /** Cancels the order and removes the shipments */
  salesOrderOfflineRefund?: Maybe<SalesOrderEntity>;
  /** Verifies an existing address */
  assistedAddressVerify?: Maybe<AddressEntity>;
  /** Remove a product list for an affiliate */
  affiliateDeleteProductList?: Maybe<PartnerEntity>;
  /** Clears the billing address */
  cartClearBillingAddress?: Maybe<CartEntity>;
  /** Looks up weather for a zip code */
  shipmentLookupWeatherByZip?: Maybe<Array<Maybe<Weather>>>;
  /** Creates a new blog topic */
  blogTopicCreate?: Maybe<BlogTopicEntity>;
  /** Deletes an existing block */
  blockDelete: Scalars['Boolean'];
  /** Sets the shipping address to one of the user's addresses */
  assistedCartSetShippingAddressById?: Maybe<CartEntity>;
  /** Validates the address, updates it if necessary */
  salesOrderValidateAddress?: Maybe<SalesOrderEntity>;
  /** Clears a product from shipping alone */
  simpleProductClearShipsAlone?: Maybe<SimpleProductEntity>;
  /** Recomputes shipping estimates for a simple product */
  simpleProductRecomputeShippingEstimatesAsync?: Maybe<SimpleProductEntity>;
  /** Uploads an image and sets it on the category */
  categoryUploadImage?: Maybe<CategoryEntity>;
  /** Sanitize a blog */
  blogSanitize?: Maybe<BlogEntity>;
  /** Sets the subscription's shipping address to the same as its billing address */
  assistedSubscriptionSetShippingAddressFromBillingAddress?: Maybe<SubscriptionEntity>;
  /** Sets the shipping rule set on a product */
  simpleProductSetShippingRuleSet?: Maybe<SimpleProductEntity>;
  /** Creates a new shipment batch */
  shipmentBatchCreate?: Maybe<ShipmentBatchEntity>;
  /** Adds a Braintree payment to an order */
  salesOrderAddBraintreePayment?: Maybe<SalesOrderEntity>;
  /** Removes all quantity of selected kit product from user's cart */
  cartRemoveAllKitProduct?: Maybe<CartEntity>;
  /** Sets a simple product's weight */
  simpleProductSetWeight?: Maybe<SimpleProductEntity>;
  /** Updates a shipment's address */
  dropshipShipmentUpdateAddress?: Maybe<ShipmentEntity>;
  /** Gets items eligible for refund. */
  salesOrderGetRefundableItems?: Maybe<Array<Maybe<SalesOrderLineItem>>>;
  /** Unsubscribe from an existing item */
  assistedSubscriptionRemoveItem?: Maybe<SubscriptionEntity>;
  /** Refreshes a sales order (with Petco DS) by entity id */
  petcoDsSalesOrderRefresh?: Maybe<PetcoDropshipSalesOrderEntity>;
  /** Sets an listed product's cost */
  listedProductSetCost?: Maybe<ListedProductEntity>;
  /** Clears the cart's pickup warehouse */
  cartClearPickupWarehouse?: Maybe<CartEntity>;
  /** Marks the purchase request as ordered */
  purchaseRequestOrder?: Maybe<PurchaseRequestEntity>;
  /** Resends a shipment notification */
  shipmentResendShipmentNotification?: Maybe<ShipmentEntity>;
  /** Updates an item's next delivery */
  assistedSubscriptionSetItemNextDelivery?: Maybe<SubscriptionEntity>;
  /** Sets a simple product's SKU */
  simpleProductSetSku?: Maybe<SimpleProductEntity>;
  /** Validates the address, updates it if necessary */
  partnerValidateAddress?: Maybe<PartnerEntity>;
  /** Adds a cash payment to an order */
  salesOrderAddCashPayment?: Maybe<SalesOrderEntity>;
  /** Updates bin on a simple product */
  simpleProductSetBin?: Maybe<SimpleProductEntity>;
  /** Calculates a sales tax rate based on zip code */
  salesTaxRate?: Maybe<TaxDetail>;
  /** Validates the address, updates it if necessary */
  supplierValidateAddress?: Maybe<SupplierEntity>;
  /** Sets a listed product's live flag */
  listedProductSetLive?: Maybe<ListedProductEntity>;
  /** Remove a cross sell from a simple product */
  simpleProductRemoveCrossSellProduct?: Maybe<SimpleProductEntity>;
  /** Add a category to a product */
  productAddCategory?: Maybe<ProductEntity>;
  /** Deletes an existing tradeshow */
  tradeShowDeleteShow: Scalars['Boolean'];
  /** Update an existing review */
  assistedReviewUpdate?: Maybe<ReviewEntity>;
  /** Creates a new care sheet */
  careSheetCreate?: Maybe<CareSheetEntity>;
  /** Sets an listed product's visible flag */
  listedProductSetVisible?: Maybe<ListedProductEntity>;
  /** Sets a listing's identifier */
  listedProductSetListingIdentifier?: Maybe<ListingEntity>;
  /** Sets a simple product's UPC */
  simpleProductSetUpc?: Maybe<SimpleProductEntity>;
  /** Sets a simple product's hs code */
  simpleProductSetHsCode?: Maybe<SimpleProductEntity>;
  /** Sets the active flag */
  discountSetActive?: Maybe<DiscountEntity>;
  /** Sets the teaser of the promotion */
  promotionSetTeaser?: Maybe<PromotionEntity>;
  /** Removes categories from the promotion */
  promotionRemoveCategories?: Maybe<PromotionEntity>;
  /** Gets earliest delivery date option */
  assistedCartGetEarliestDeliveryDate?: Maybe<Scalars['LocalDate']>;
  /** Sets a configurable product's featured flag */
  configurableProductSetFeatured?: Maybe<ConfigurableProductEntity>;
  /** Cancels a shipment within an order */
  shipmentCancelAndRefund?: Maybe<ShipmentEntity>;
  /** Generates a PDF */
  purchaseOrderGeneratePDF?: Maybe<Scalars['String']>;
  /** Adds quantity of selected listed product to user's cart */
  cartAddListedProduct?: Maybe<CartEntity>;
  /** Sets a configurable product's title */
  configurableProductSetTitle?: Maybe<ConfigurableProductEntity>;
  /** Sets a kit product's slug */
  kitProductSetSlug?: Maybe<KitProductEntity>;
  /** Removes bin from a simple product */
  simpleProductClearBin?: Maybe<SimpleProductEntity>;
  /** Remove an animal from a kit product */
  kitProductRemoveAnimal?: Maybe<KitProductEntity>;
  /** Remove an item from a product list */
  affiliateRemoveProductListItem?: Maybe<AffiliateProductListEntity>;
  /** Records an off-line shipment for an order */
  shipmentShipOffline?: Maybe<ShipmentEntity>;
  /** Deletes a category */
  categoryDelete: Scalars['Boolean'];
  /** Triggers a re-sync of MFN inventory with Walmart */
  maintenanceWalmartUpdateInventory?: Maybe<Scalars['Boolean']>;
  /** Sets a listing's weight date */
  listedProductSetListingWeightDate?: Maybe<ListingEntity>;
  /** Reroutes a shipment to a warehouse and queue */
  shipmentReroute?: Maybe<ShipmentEntity>;
  /** Sets a product as shipping alone */
  simpleProductSetShipsAlone?: Maybe<SimpleProductEntity>;
  /** Acknowledges a shipment's print job has been cancelled */
  autoprintCancelAcknowledgeForPrinter: Scalars['Boolean'];
  /** Sets a kit product's SKU */
  kitProductSetSku?: Maybe<KitProductEntity>;
  /** Assigns a brand to a simple product */
  simpleProductSetBrand?: Maybe<SimpleProductEntity>;
  /** Sets the cart's coupon code */
  cartSetCouponCode?: Maybe<CartEntity>;
  /** Removes a product media from the kit */
  kitProductRemoveProductMedia?: Maybe<KitProductEntity>;
  /** Adds a Braintree payment to an order */
  salesOrderAddBraintreeOnlinePayment?: Maybe<SalesOrderEntity>;
  /** Sets a warehouse's latitude location */
  warehouseSetLatitude?: Maybe<WarehouseEntity>;
  /** Sets the eligible coupon code of the discount */
  discountSetEligibleCouponCode?: Maybe<DiscountEntity>;
  /** Sets a kit product's UPC */
  kitProductSetUpc?: Maybe<KitProductEntity>;
  /** Sets a blog's meta description */
  blogSetMetaDescription?: Maybe<BlogEntity>;
  /** Unsubscribe from an existing item */
  subscriptionRemoveItem?: Maybe<SubscriptionEntity>;
  /** Add cross sell items to a simple product */
  simpleProductAddCrossSellItems?: Maybe<SimpleProductEntity>;
  /** Sets the sequence of a content media */
  careSheetSetContentMediaSequence?: Maybe<CareSheetEntity>;
  /** Retrieves cross-sells for the given cart */
  cartCrossSells?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Splits a shipment into two smaller shipments */
  shipmentSplitAfterShip?: Maybe<ShipmentEntity>;
  /** Creates a new POS terminal */
  tradeShowCreateTerminal?: Maybe<PosTerminalEntity>;
  /** Refunds the order's sales tax */
  salesOrderSalesTaxRefund?: Maybe<SalesOrderEntity>;
  /** Sets a simple product's title */
  simpleProductSetTitle?: Maybe<SimpleProductEntity>;
  /** Provides cart info for current user */
  cartInfo?: Maybe<CartEntity>;
  /** Updates rate quotes */
  maintenanceUpdateRateQuotes?: Maybe<Scalars['Boolean']>;
  /** Add products to a care sheet */
  careSheetAddProducts?: Maybe<CareSheetEntity>;
  /** Sets the subscription's pause reason */
  assistedSubscriptionSetPauseReason?: Maybe<SubscriptionEntity>;
  /** Updates an existing address */
  assistedAddressUpdate?: Maybe<AddressEntity>;
  /** Removes categories from the discount */
  discountRemoveCategories?: Maybe<DiscountEntity>;
  /** Gets rates for a multi-piece shipment */
  dropshipShipmentRateMultiPiece?: Maybe<Array<Maybe<RateQuote>>>;
  /** Update's a user's tax exempt status */
  userUpdateTaxExempt?: Maybe<UserEntity>;
  /** Retrieves cross-sells for the given cart */
  assistedCartCrossSells?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Releases a subscription order */
  subscriptionSalesOrderRelease?: Maybe<SubscriptionSalesOrderEntity>;
  /** Removes a custom product to user's cart */
  assistedCartRemoveCustomProduct?: Maybe<CartEntity>;
  /** Changes a shipment's shipping needs */
  shipmentSetShippingNeeds?: Maybe<ShipmentEntity>;
  /** Sets a partner's reseller flag */
  partnerSetReseller?: Maybe<PartnerEntity>;
  /** Looks up weather for shipment */
  shipmentLookupWeatherByHash?: Maybe<Array<Maybe<Weather>>>;
  /** Clears the cart's pickup warehouse */
  assistedCartClearPickupWarehouse?: Maybe<CartEntity>;
  /** Sets a category's parent */
  categorySetParent?: Maybe<CategoryEntity>;
  /** Add a preparation to a product */
  preppedProductsAddPreparation?: Maybe<PreparationSummary>;
  /** Removes a zone from a simple product */
  simpleProductRemoveZone?: Maybe<SimpleProductEntity>;
  /** Triggers a re-sync of updated orders with Petco DS */
  maintenancePetcoDsSyncUpdatedOrders?: Maybe<Scalars['Boolean']>;
  /** Update's rate quotes for a shipment */
  shipmentUpdateRateQuotes?: Maybe<ShipmentEntity>;
  /** Sets a partner's name */
  partnerSetName?: Maybe<PartnerEntity>;
  /** Sets a simple product's country of origin */
  simpleProductSetCountryOfOrigin?: Maybe<SimpleProductEntity>;
  /** Sets a kit product's special price */
  kitProductSetSpecialPrice?: Maybe<KitProductEntity>;
  /** Sets a simple product's active flag */
  simpleProductSetActive?: Maybe<SimpleProductEntity>;
  /** Sets the shipping address */
  cartSetShippingAddress?: Maybe<CartEntity>;
  /** Sends Drop Shipper Reports */
  maintenanceDropshipperReport?: Maybe<Scalars['Boolean']>;
  /** Generates a shipping manifest */
  shipmentManifest?: Maybe<Scalars['String']>;
  /** Sets the supplier's lead time */
  supplierSetLeadTime?: Maybe<SupplierEntity>;
  /** Sets a warehouse's dropshipper */
  warehouseSetDropshipper?: Maybe<WarehouseEntity>;
  /** Creates a new gift card, with amount */
  giftCardCreate?: Maybe<GiftCardEntity>;
  /** Triggers a re-index of all items in the catalog */
  maintenanceSearchReindexCatalog?: Maybe<Scalars['Boolean']>;
  /** Sets a component item's quantity */
  simpleProductSetComponentItemQuantity?: Maybe<SimpleProductEntity>;
  /** Clears a brand from a simple product */
  simpleProductClearBrand?: Maybe<SimpleProductEntity>;
  /** Sets a kit product's price */
  kitProductSetPrice?: Maybe<KitProductEntity>;
  /** Add a product list for an affiliate */
  affiliateAddProductList?: Maybe<PartnerEntity>;
  /** Sets a configurable product's meta description */
  configurableProductSetMetaDescription?: Maybe<ConfigurableProductEntity>;
  /** Sets a warehouse's type */
  warehouseSetWarehouseType?: Maybe<WarehouseEntity>;
  /** Refreshes a sales order (with Amazon) by entity id */
  amazonSalesOrderRefresh?: Maybe<AmazonSalesOrderEntity>;
  /** Starts picking of the batch */
  shipmentBatchBeginPicking?: Maybe<ShipmentBatchEntity>;
  /** Triggers a re-sync of updated orders with Chewy */
  maintenanceChewySyncUpdatedOrders?: Maybe<Scalars['Boolean']>;
  /** Validates the address, updates it if necessary */
  shipmentValidateAddress?: Maybe<ShipmentEntity>;
  /** Clears the service level */
  assistedSubscriptionClearServiceLevel?: Maybe<SubscriptionEntity>;
  /** Creates a shipment batch */
  dropshipShipmentBatchCreate?: Maybe<ShipmentBatchEntity>;
  /** Generates a packing list PDF */
  dropshipShipmentBatchGeneratePackingList?: Maybe<Scalars['String']>;
  /** Sets a configurable product's visible flag */
  configurableProductSetVisible?: Maybe<ConfigurableProductEntity>;
  /** Sets the payment token */
  subscriptionSetPaymentToken?: Maybe<SubscriptionEntity>;
  /** Set the position of a cross sell item */
  listedProductSetCrossSellItemPosition?: Maybe<ListedProductEntity>;
  /** Add cross sell items to a listed product */
  listedProductAddCrossSellItems?: Maybe<ListedProductEntity>;
  /** Resends a cancel notification */
  shipmentResendCancelNotification?: Maybe<ShipmentEntity>;
  /** Gets inventory details for a simple product */
  inventoryGetDetails?: Maybe<InventoryDetails>;
  /** Add cross sell items to a kit product */
  kitProductAddCrossSellItems?: Maybe<KitProductEntity>;
  /** Lists batch print queues for this warehouse */
  batchPrintQueueList?: Maybe<Array<Maybe<BatchPrintQueueEntity>>>;
  /** Sets a simple product's slug */
  simpleProductSetSlug?: Maybe<SimpleProductEntity>;
  /** Sets active flag */
  blogSetActive?: Maybe<BlogEntity>;
  /** Returns possible delivery dates */
  assistedScheduledShipmentPossibleDeliveryDates?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Generates a packing list PDF */
  shipmentGeneratePackingList?: Maybe<Scalars['String']>;
  /** Adds inventory for a simple product */
  inventoryAddDetails?: Maybe<InventoryDetails>;
  /** Changes quantity of selected simple product in user's cart */
  assistedCartChangeQuantitySimpleProduct?: Maybe<CartEntity>;
  /** Removes brands from the promotion */
  promotionRemoveBrands?: Maybe<PromotionEntity>;
  /** Triggers a re-sync of updated orders with Walmart */
  maintenanceWalmartSyncUpdatedOrders?: Maybe<Scalars['Boolean']>;
  /** Create */
  amazonProfileCreate?: Maybe<AmazonProfileEntity>;
  /** Provides cart info for current user */
  assistedCartInfo?: Maybe<CartEntity>;
  /** Removes a vaulted payment method for the specified user. */
  assistedPaymentMethodDelete: Scalars['Boolean'];
  /** Updates an item's quantity */
  assistedSubscriptionSetItemQuantity?: Maybe<SubscriptionEntity>;
  /** Cancels a shipment within an order */
  shipmentCancel?: Maybe<ShipmentEntity>;
  /** Closes a purchase order */
  purchaseOrderClose?: Maybe<PurchaseOrderEntity>;
  /** Uploads an image */
  mediaUploadImage?: Maybe<MediaEntity>;
  /** Provides a new cart */
  cartNewCart?: Maybe<CartEntity>;
  /** Merges a payment terms account into another */
  assistedPaymentAccountMerge?: Maybe<PaymentTermsAccountEntity>;
  /** Create */
  chewyProfileCreate?: Maybe<ChewyProfileEntity>;
  /** Creates a new configurable product */
  configurableProductCreate?: Maybe<ConfigurableProductEntity>;
  /** Sets the cart's shipping address to the same as its billing address */
  assistedCartSetShippingAddressFromBillingAddress?: Maybe<CartEntity>;
  /** Adds categories to the promotion */
  promotionAddCategories?: Maybe<PromotionEntity>;
  warehouseWorkScheduleCreateHoliday?: Maybe<WarehouseEntity>;
  /** Update an existing discount */
  discountUpdate?: Maybe<DiscountEntity>;
  /** Tracks a shipment */
  shipmentTrackByHash?: Maybe<Array<Maybe<TrackingEvent>>>;
  /** Returns an authorization code */
  authorizationAuthCode?: Maybe<Scalars['String']>;
  /** Updates an existing block */
  blockUpdate?: Maybe<BlockEntity>;
  /** Reschedule a shipment */
  shipmentReschedule?: Maybe<ShipmentEntity>;
  /** Adds whatever portion of a kit product is actually available to user's cart */
  cartAddPartialKitProduct?: Maybe<CartEntity>;
  /** Updates a subscription's delivery day */
  subscriptionSetDeliveryDay?: Maybe<SubscriptionEntity>;
  /** Removes a vaulted payment method for the current user. */
  checkoutDeletePaymentMethod: Scalars['Boolean'];
  /** Credits user's rewards account */
  assistedRewardPointsCreditRewardsAccount?: Maybe<RewardsAccountEntity>;
  /** Schedule a shipment for today or a future date */
  assistedScheduledShipmentSchedule?: Maybe<ShipmentEntity>;
  /** Sets a partner's reseller shipping rate mode */
  resellerSetResellerShippingRateMode?: Maybe<PartnerEntity>;
  /** Reroutes and re-prints shipments */
  maintenanceRerouteAndReprint?: Maybe<Scalars['Int']>;
  /** Updates an existing tradeshow */
  tradeShowUpdateShow?: Maybe<TradeShowEntity>;
  /** Add products to a blog */
  blogAddProducts?: Maybe<BlogEntity>;
  /** Removes eligible products from the discount */
  discountRemoveEligibleProducts?: Maybe<DiscountEntity>;
  /** Generates a client token for specified user. */
  assistedPaymentMethodGetClientToken?: Maybe<ClientTokenDetail>;
  /** Set the position of a cross sell item */
  simpleProductSetCrossSellItemPosition?: Maybe<SimpleProductEntity>;
  /** Gets items eligible for refund. */
  salesOrderGetRefundableAmounts?: Maybe<RefundableAmounts>;
  /** Removes all quantity of selected simple product from user's cart */
  assistedCartRemoveAllSimpleProduct?: Maybe<CartEntity>;
  /** Removes all quantity of selected simple product from user's cart */
  cartRemoveAllSimpleProduct?: Maybe<CartEntity>;
  /** Sets a simple product's length */
  simpleProductSetLength?: Maybe<SimpleProductEntity>;
  /** Sets the sales order's email */
  salesOrderSetEmail?: Maybe<SalesOrderEntity>;
  /** Clears the carrier preference for a cart item */
  assistedCartClearCarrierPreference?: Maybe<CartEntity>;
  /** Creates a dropship order */
  shipmentCreateDropshipOrder?: Maybe<ShipmentEntity>;
  /** Sets a blog's meta title */
  blogSetMetaTitle?: Maybe<BlogEntity>;
  /** Adds departments to the promotion */
  promotionAddDepartments?: Maybe<PromotionEntity>;
  /** Clears the cart's email */
  assistedCartClearEmail?: Maybe<CartEntity>;
  /** Sanitize a care sheet */
  careSheetSanitize?: Maybe<CareSheetEntity>;
  /** Sets the service level */
  subscriptionSetServiceLevel?: Maybe<SubscriptionEntity>;
  /** Sets active flag */
  categorySetActive?: Maybe<CategoryEntity>;
  /** Creates a zone for a warehouse */
  warehouseCreateZone?: Maybe<WarehouseEntity>;
  /** Sets a delivery date preference for a cart item */
  cartClearDeliveryDatePreference?: Maybe<CartEntity>;
  /** Set the title of a media */
  mediaSetTitle?: Maybe<MediaEntity>;
  /** Creates a brand */
  brandCreate?: Maybe<BrandEntity>;
  /** Updates a scheduled shipment's address */
  scheduledShipmentUpdateAddressByHash?: Maybe<ShipmentEntity>;
  /** Clears the carrier preference */
  assistedSubscriptionClearCarrierPreference?: Maybe<SubscriptionEntity>;
  /** Remove a product from a care sheet */
  careSheetRemoveProduct?: Maybe<CareSheetEntity>;
  /** Updates a subscription's delivery day */
  assistedSubscriptionSetDeliveryDay?: Maybe<SubscriptionEntity>;
  /** Sets the supplier's email */
  supplierClearEmail?: Maybe<SupplierEntity>;
  /** Triggers a re-sync of updated orders with Amazon */
  maintenanceAmazonSyncUpdatedOrders?: Maybe<Scalars['Boolean']>;
  /** Sets a warehouse's address */
  warehouseSetAddress?: Maybe<WarehouseEntity>;
  /** Sends a password reset email */
  userSendPasswordReset?: Maybe<Scalars['Boolean']>;
  /** Pushes a simple product to Poynt */
  simpleProductPush: Scalars['Boolean'];
  /** Sets a care sheet's published revision */
  careSheetSetPublishedRevision?: Maybe<CareSheetEntity>;
  /** Changes quantity of selected simple product in user's cart */
  cartChangeQuantitySimpleProduct?: Maybe<CartEntity>;
  /** Sets the start date of the discount */
  discountSetStartDate?: Maybe<DiscountEntity>;
  /** Sets an listed product's price */
  listedProductSetPrice?: Maybe<ListedProductEntity>;
  /** Clears the carrier preference */
  subscriptionClearCarrierPreference?: Maybe<SubscriptionEntity>;
  /** Sets a listed product's meta description */
  listedProductSetMetaDescription?: Maybe<ListedProductEntity>;
  /** Provides a new cart */
  assistedCartNewCart?: Maybe<CartEntity>;
  /** Triggers a fix to assign the default payment method to subscriptions */
  maintenanceFixSubscriptionDefaultPayments?: Maybe<Scalars['Boolean']>;
  /** Sets a simple product's vendor ref */
  simpleProductSetVendorRef?: Maybe<SimpleProductEntity>;
  /** Sets the carrier preference */
  subscriptionSetCarrierPreference?: Maybe<SubscriptionEntity>;
  /** Sets a delivery date preference for a cart item */
  assistedCartClearDeliveryDatePreference?: Maybe<CartEntity>;
  /** Updates bin on a listed product */
  listedProductSetBin?: Maybe<ListedProductEntity>;
  /** Removes a content media from the care sheet */
  careSheetRemoveContentMedia?: Maybe<CareSheetEntity>;
  /** Sets a partner's email */
  partnerSetEmail?: Maybe<PartnerEntity>;
  /** Refreshes a sales order (with PSP) by entity id */
  pspFranchiseSalesOrderRefresh?: Maybe<PspFranchiseSalesOrderEntity>;
  /** Sets a care sheet's meta title */
  careSheetSetMetaTitle?: Maybe<CareSheetEntity>;
  /** Sets an listed product's SKU */
  listedProductSetSku?: Maybe<ListedProductEntity>;
  /** Calculates the implicit products a discount */
  discountEvaluateImplicitProducts?: Maybe<DiscountEntity>;
  /** Triggers a re-index of hibernate cache */
  maintenanceCacheFlushAll?: Maybe<Scalars['Boolean']>;
  /** Sets the sequence of a product media */
  configurableProductSetProductMediaSequence?: Maybe<ConfigurableProductEntity>;
  /** Adds explicit products to the discount */
  discountAddExplicitProducts?: Maybe<DiscountEntity>;
  /** Validates the address, updates it if necessary */
  dropshipShipmentValidateAddress?: Maybe<ShipmentEntity>;
  /** Refreshes a sales order (with Walmart) by purchase order id */
  walmartSalesOrderRefresh?: Maybe<WalmartSalesOrderEntity>;
  /** Updates a subscription's billing address */
  assistedSubscriptionSetBillingAddress?: Maybe<SubscriptionEntity>;
  /** Remove a price tier from a kit product */
  kitProductRemovePriceTier?: Maybe<KitProductEntity>;
  /** Generates an invoice PDF */
  salesOrderGenerateInvoice?: Maybe<Scalars['String']>;
  /** Removes a preparation from a product */
  preppedProductsRemovePreparation?: Maybe<PreparationSummary>;
  /** Sets an listed product's UPC */
  listedProductSetUpc?: Maybe<ListedProductEntity>;
  /** Sets an listed product's title */
  listedProductSetTitle?: Maybe<ListedProductEntity>;
  /** Mocks a partial refund of an order. */
  salesOrderPartialRefundSummarize?: Maybe<RefundEntity>;
  /** Removes a zone from a listed product */
  listedProductRemoveZone?: Maybe<ListedProductEntity>;
  /** Clears the cart's phone */
  assistedCartClearPhone?: Maybe<CartEntity>;
  /** Updates an item's frequency */
  assistedSubscriptionSetItemFrequency?: Maybe<SubscriptionEntity>;
  /** Sets a simple product's subscription flag */
  simpleProductSetSubscription?: Maybe<SimpleProductEntity>;
  /** Sets the sequence of a listed product media */
  listedProductSetProductMediaSequence?: Maybe<ListedProductEntity>;
  /** Assigns a pos terminal to a tradeshow */
  tradeShowAssignTerminal?: Maybe<TradeShowEntity>;
  /** Sets a blog's meta keywords */
  blogSetMetaKeywords?: Maybe<BlogEntity>;
  /** Sets the eligible item value of the promotion */
  promotionSetEligibleItemValue?: Maybe<PromotionEntity>;
  /** Custom refunds an order. */
  salesOrderCustomRefund?: Maybe<SalesOrderEntity>;
  /** Completes picking of the batch */
  shipmentBatchCompletePicking?: Maybe<ShipmentBatchEntity>;
  /** Adds a check payment to an order */
  salesOrderAddCheckPayment?: Maybe<SalesOrderEntity>;
  /** Sets a listing's weight unit */
  listedProductSetListingWeightUnit?: Maybe<ListingEntity>;
  /** Uploads an image */
  reviewUploadImage?: Maybe<MediaEntity>;
  /** Returns possible delivery dates */
  scheduledShipmentPossibleDeliveryDates?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Removes all quantity of selected listed product from user's cart */
  cartRemoveAllListedProduct?: Maybe<CartEntity>;
  /** Set the html content of a promotion */
  promotionSetHtmlContent?: Maybe<PromotionEntity>;
  /** Add an item to this kit */
  kitProductAddKitItem?: Maybe<KitProductEntity>;
  /** Removes a content media from the blog */
  blogRemoveContentMedia?: Maybe<BlogEntity>;
  /** Sets the eligible user type of the discount */
  discountSetEligibleUserType?: Maybe<DiscountEntity>;
  /** Clones a kit product */
  kitProductClone?: Maybe<KitProductEntity>;
  /** Tests a workstation for use with AutoPrint */
  autoprintTestWorkstation?: Maybe<WorkstationEntity>;
  /** Adds explicit products to the promotion */
  promotionAddExplicitProducts?: Maybe<PromotionEntity>;
  /** Sets the cart's tax exempt flag */
  cartSetTaxExempt?: Maybe<CartEntity>;
  /** Sets a carrier preference for a cart item */
  cartClearCarrierPreference?: Maybe<CartEntity>;
  /** Reindexes all products */
  maintenanceReindexProducts?: Maybe<Scalars['Boolean']>;
  /** Sets the cart's contact information */
  assistedCartSetContact?: Maybe<CartEntity>;
  /** Returns the tradeshow report */
  tradeShowReport?: Maybe<ShowReport>;
  /** Sets a kit product's meta description */
  kitProductSetMetaDescription?: Maybe<KitProductEntity>;
  /** Generates shipment packing lists */
  shipmentBatchGeneratePackingList?: Maybe<Array<Maybe<SignedZplLabel>>>;
  /** Refreshes a sales order (with Petco SD) by entity id */
  petcoSdSalesOrderRefresh?: Maybe<PetcoStoreDirectSalesOrderEntity>;
  /** Clears the shipping address */
  cartClearShippingAddress?: Maybe<CartEntity>;
  /** Assigns dimensions to an listed product */
  listedProductSetDimensions?: Maybe<ListedProductEntity>;
  /** Sets the category's name */
  categorySetName?: Maybe<CategoryEntity>;
  /** Generates a list of single-use coupon codes */
  discountGenerateCustomCouponCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sets an listed product's published revision */
  listedProductSetPublishedRevision?: Maybe<ListedProductEntity>;
  /** Sets a block's slug */
  blockSetSlug?: Maybe<BlockEntity>;
  /** Clears the cart's tradeshow */
  cartClearTradeshow?: Maybe<CartEntity>;
  /** Sets a listing's comments */
  listedProductSetListingComments?: Maybe<ListingEntity>;
  /** Sets the supplier's order cycle */
  supplierSetOrderCycle?: Maybe<SupplierEntity>;
  /** Adds queues to a warehouse */
  warehouseAddQueues?: Maybe<WarehouseEntity>;
  /** Sets the type of the discount */
  discountSetDiscountType?: Maybe<DiscountEntity>;
  /** Gets inventory history for a simple product */
  inventoryReportGenerate?: Maybe<Array<Maybe<InventoryConsistency>>>;
  /** Sets the cart's phone */
  assistedCartSetPhone?: Maybe<CartEntity>;
  /** Sets the cart's affiliate */
  cartSetAffiliate?: Maybe<CartEntity>;
  /** Sets a component item's position */
  simpleProductSetComponentItemPosition?: Maybe<SimpleProductEntity>;
  /** Updates a shipment's address */
  shipmentUpdateAddress?: Maybe<ShipmentEntity>;
  /** Sets an block's name */
  blockSetName?: Maybe<BlockEntity>;
  /** Creates a purchase request */
  purchaseRequestCreate?: Maybe<PurchaseRequestEntity>;
  /** Update an existing review */
  reviewUpdate?: Maybe<ReviewEntity>;
  /** Add an animal to a kit product */
  kitProductAddAnimal?: Maybe<KitProductEntity>;
  /** Summarizes the cart's totals */
  cartSummarize?: Maybe<SalesOrderEntity>;
  /** Remove a cross sell from a kit product */
  kitProductRemoveCrossSellProduct?: Maybe<KitProductEntity>;
  /** Gets inventory history for a simple product */
  inventoryGetHistory?: Maybe<Array<Maybe<InventoryHistory>>>;
  /** Sets an listed product's slug */
  listedProductSetSlug?: Maybe<ListedProductEntity>;
  /** Refreshes a sales order (with Chewy) by entity id */
  chewySalesOrderRefresh?: Maybe<ChewySalesOrderEntity>;
  /** Sets inventory for a simple product */
  inventorySetDetails?: Maybe<InventoryDetails>;
  /** Sets the cart's attribution token */
  cartSetAttributionToken?: Maybe<CartEntity>;
  warehouseWorkScheduleSetOpenCloseTime?: Maybe<WarehouseEntity>;
  /** Adds quantity of selected kit product to user's cart */
  cartAddKitProduct?: Maybe<CartEntity>;
  /** Requests a notification when a product is placed back into stock */
  productRequestBackInStockNotification?: Maybe<Scalars['Boolean']>;
  /** Records an off-line delivery for an order */
  shipmentDeliverOffline?: Maybe<ShipmentEntity>;
  /** Creates a queue for a warehouse */
  warehouseCreateQueue?: Maybe<WarehouseEntity>;
  /** Sets a kit product's cost */
  kitProductSetCost?: Maybe<KitProductEntity>;
  /** Sets the shipping rule set on a product */
  listedProductSetShippingRuleSet?: Maybe<ListedProductEntity>;
  /** Clears the billing address */
  assistedCartClearBillingAddress?: Maybe<CartEntity>;
  /** Creates a reseller profile */
  resellerCreateProfile?: Maybe<ResellerProfileEntity>;
  /** Clones a promotion */
  promotionClone?: Maybe<PromotionEntity>;
  /** Removes all quantity of selected kit product from user's cart */
  assistedCartRemoveAllKitProduct?: Maybe<CartEntity>;
  /** Clears the shipping rule set from an listed product */
  listedProductClearShippingRuleSet?: Maybe<ListedProductEntity>;
  /** Sets the cart's pickup warehouse */
  assistedCartSetPickupWarehouse?: Maybe<CartEntity>;
  /** Sets the cart's tax exempt flag */
  assistedCartSetTaxExempt?: Maybe<CartEntity>;
  /** Cancels a shipment */
  dropshipShipmentCancelAndRefund?: Maybe<ShipmentEntity>;
  /** Clones a discount */
  discountClone?: Maybe<DiscountEntity>;
  /** Reindexes a configurable product */
  configurableProductReindex?: Maybe<ConfigurableProductEntity>;
  /** Sets a simple product's minimum order quantity */
  simpleProductSetMinimumOrderQuantity?: Maybe<SimpleProductEntity>;
  /** Sets a category's meta description */
  categorySetMetaDescription?: Maybe<CategoryEntity>;
  /** Sets the cart's phone */
  cartSetPhone?: Maybe<CartEntity>;
  /** Partially reships an order. */
  salesOrderPartialReship?: Maybe<SalesOrderEntity>;
  /** Refreshes a simple product (with Magento) by entity id */
  simpleProductRefresh?: Maybe<SimpleProductEntity>;
  /** Adds an existing image to the care sheet */
  careSheetAddImage?: Maybe<CareSheetEntity>;
  /** Removes explicit products from the promotion */
  promotionRemoveExplicitProducts?: Maybe<PromotionEntity>;
  /** Sets active flag */
  careSheetSetActive?: Maybe<CareSheetEntity>;
  /** Sets a carrier preference for a cart item */
  assistedCartSetCarrierPreference?: Maybe<CartEntity>;
  /** Re-opens a purchase order */
  purchaseOrderReOpen?: Maybe<PurchaseOrderEntity>;
  /** Adds an existing image to the listed product */
  listedProductAddImage?: Maybe<ListedProductEntity>;
  /** Sets a care sheet's meta keywords */
  careSheetSetMetaKeywords?: Maybe<CareSheetEntity>;
  /** Creates a new blog */
  blogCreate?: Maybe<BlogEntity>;
  /** Recomputes shipping estimates for all products */
  maintenanceRecomputeShippingEstimates?: Maybe<Scalars['Boolean']>;
  /** Adds quantity of selected simple product to user's cart */
  cartAddSimpleProduct?: Maybe<CartEntity>;
  /** Sets the service level */
  assistedSubscriptionSetServiceLevel?: Maybe<SubscriptionEntity>;
  /** Sets the slug of the promotion */
  promotionSetSlug?: Maybe<PromotionEntity>;
  /** Deletes a promotion */
  promotionDelete?: Maybe<Scalars['Boolean']>;
  /** Sends a Petco DS inventory report */
  maintenanceSendPetcoDsInventory?: Maybe<Scalars['Boolean']>;
  /** Holds a shipment for weather reasons */
  assistedScheduledShipmentHoldForWeather?: Maybe<ShipmentEntity>;
  /** Sets a kit product's visible flag */
  kitProductSetVisible?: Maybe<KitProductEntity>;
  /** Updates a user's phone */
  userUpdatePhone?: Maybe<UserEntity>;
  /** Sets the shipping address to one of the user's addresses */
  cartSetShippingAddressById?: Maybe<CartEntity>;
  /** Cancels the order and removes the shipments */
  salesOrderCancel?: Maybe<SalesOrderEntity>;
  /** Adds eligible products to the discount */
  discountAddEligibleProducts?: Maybe<DiscountEntity>;
  /** Triggers a re-sync of products quantities available for sale */
  maintenanceInventoryUpdateQuantitiesAvailableForSale?: Maybe<Scalars['Boolean']>;
  /** Sets a simple product's case quantity */
  simpleProductSetCaseQuantity?: Maybe<SimpleProductEntity>;
  /** Sets a listed product's meta title */
  listedProductSetMetaTitle?: Maybe<ListedProductEntity>;
  /** Sets a service level for a cart item */
  assistedCartSetServiceTypePreference?: Maybe<CartEntity>;
  /** Add cross sells to a simple product */
  simpleProductAddCrossSellProducts?: Maybe<SimpleProductEntity>;
  /** Rejects a scheduled shipment for departure today */
  assistedScheduledShipmentClearApproval?: Maybe<ShipmentEntity>;
  /** Updates a partner's address */
  partnerUpdateAddress?: Maybe<PartnerEntity>;
  /** Uploads an image and adds it to the blog */
  blogUploadImage?: Maybe<BlogEntity>;
  /** Lists available ship dates */
  preppedProductsCalculateShipDates?: Maybe<PreparationShipDates>;
  /** Adds a check payment to an order */
  salesOrderAddGiftCardPayment?: Maybe<SalesOrderEntity>;
  /** Triggers a re-sync of FBA inventory with Amazon */
  maintenanceAmazonUpdateFbaInventory?: Maybe<Scalars['Boolean']>;
  /** Removes a purchase request item */
  purchaseRequestRemoveItem?: Maybe<PurchaseRequestEntity>;
  /** Sends Drop Shipper Reports */
  maintenanceDropshipperReports?: Maybe<Scalars['Boolean']>;
  /** Generates the assisted scheduled shipment dashboard */
  assistedScheduledShipmentDashboard?: Maybe<SchedulingDashboard>;
  /** Create */
  dropshipperProfileCreate?: Maybe<DropshipperProfileEntity>;
  /** Sets a simple product's width */
  simpleProductSetWidth?: Maybe<SimpleProductEntity>;
  /** Create a new promotion */
  promotionCreate?: Maybe<PromotionEntity>;
  /** Add an animal to a simple product */
  simpleProductAddAnimal?: Maybe<SimpleProductEntity>;
  /** Sets the teaser of the discount */
  discountSetTeaser?: Maybe<DiscountEntity>;
  /** Tracks a shipment */
  shipmentTrack?: Maybe<Array<Maybe<TrackingEvent>>>;
  /** Sets the billing address */
  cartSetBillingAddress?: Maybe<CartEntity>;
  /** Sets an listed product's wholesale price */
  listedProductSetWholesalePrice?: Maybe<ListedProductEntity>;
  /** Recomputes shipping estimates for an listed product */
  listedProductRecomputeShippingEstimatesAsync?: Maybe<ListedProductEntity>;
  /** Adds a warehouse to a simple product */
  simpleProductAddWarehouse?: Maybe<SimpleProductEntity>;
  /** Refreshes a configurable product (with Magento) by entity id */
  configurableProductRefresh?: Maybe<ConfigurableProductEntity>;
  /** Ships a multi-piece shipment */
  dropshipShipmentShipMultiPiece?: Maybe<ShipmentEntity>;
  /** Add a preparation to a product by shipment item */
  preppedProductsAddPreparationLineItem?: Maybe<PreparationSummary>;
  /** Adds a custom product to user's cart */
  assistedCartAddCustomProduct?: Maybe<CartEntity>;
  /** Generates a packing list label */
  shipmentGeneratePackingListZpl?: Maybe<Scalars['String']>;
  /** Creates a new shipment batch from a list of shipment ids */
  shipmentBatchCreateFromList?: Maybe<ShipmentBatchEntity>;
  /** Sets a listed product's meta keywords */
  listedProductSetMetaKeywords?: Maybe<ListedProductEntity>;
  /** Sets a simple product's meta description */
  simpleProductSetMetaDescription?: Maybe<SimpleProductEntity>;
  /** Returns shipping estimates for a simple product */
  simpleProductShippingEstimates?: Maybe<Array<Maybe<ShippingEstimateEntity>>>;
  /** Sets a listing's title */
  listedProductSetListingTitle?: Maybe<ListingEntity>;
  /** Updates a shipment's address */
  supplierUpdateAddress?: Maybe<SupplierEntity>;
  /** Sets a configurable product's published revision */
  configurableProductSetPublishedRevision?: Maybe<ConfigurableProductEntity>;
  /** Sets the sequence of a content media */
  blogSetContentMediaSequence?: Maybe<BlogEntity>;
  /** Update's a user's type */
  userUpdateType?: Maybe<UserEntity>;
  /** Changes quantity of selected kit product in user's cart */
  assistedCartChangeQuantityKitProduct?: Maybe<CartEntity>;
  /** Remove a blog topic from a blog */
  blogRemoveBlogTopic?: Maybe<BlogEntity>;
  /** Sets a warehouse's enabled flag */
  warehouseSetEnabled?: Maybe<WarehouseEntity>;
  /** Filter inventory history */
  dropshipInventoryFilter?: Maybe<GraphQlPage_DropshipInventoryDetails>;
  /** Remove an animal from a listed product */
  listedProductRemoveAnimal?: Maybe<ListedProductEntity>;
  /** Adds quantity of selected simple product to user's cart */
  assistedCartAddSimpleProduct?: Maybe<CartEntity>;
  warehouseWorkScheduleRemoveHoliday?: Maybe<WarehouseEntity>;
  /** Sets the cart's coupon code */
  assistedCartSetCouponCode?: Maybe<CartEntity>;
  /** Set the author of a blog */
  blogSetAuthor?: Maybe<BlogEntity>;
  /** Removes carriers from a warehouse */
  warehouseRemoveCarriers?: Maybe<WarehouseEntity>;
  /** Gets earliest delivery date option */
  cartGetEarliestDeliveryDate?: Maybe<Scalars['LocalDate']>;
  /** Triggers sync of transactions with Poynt */
  maintenancePoyntSyncTransactions?: Maybe<Scalars['Boolean']>;
  /** Schedule a shipment for today or a future date */
  scheduledShipmentScheduleByHash?: Maybe<ShipmentEntity>;
  /** Voids a shipment */
  dropshipShipmentVoid?: Maybe<ShipmentEntity>;
  /** Adds an existing image to the kit */
  kitProductAddImage?: Maybe<KitProductEntity>;
  /** Partially refunds an order. */
  salesOrderPartialRefund?: Maybe<SalesOrderEntity>;
  /** Reindexes an listed product */
  listedProductReindex?: Maybe<ListedProductEntity>;
  /** Creates an listed product */
  listedProductCreate?: Maybe<ListedProductEntity>;
  /** Sets the end date of the discount */
  discountSetEndDate?: Maybe<DiscountEntity>;
  /** Clears the shipping rule set from a simple product */
  simpleProductClearShippingRuleSet?: Maybe<SimpleProductEntity>;
  /** Uploads an image and adds it to the care sheet */
  careSheetUploadImage?: Maybe<CareSheetEntity>;
  /** Clones a simple product */
  simpleProductClone?: Maybe<SimpleProductEntity>;
  /** Removes queues from a warehouse */
  warehouseRemoveQueues?: Maybe<WarehouseEntity>;
  /** Refreshes a configurable product (with Magento) by sku */
  configurableProductRefreshBySku?: Maybe<ConfigurableProductEntity>;
  /** Updates an existing address */
  addressUpdate?: Maybe<AddressEntity>;
  /** Creates a new team */
  teamCreate?: Maybe<TeamEntity>;
  /** Sets a category's meta title */
  categorySetMetaTitle?: Maybe<CategoryEntity>;
  /** Creates a warehouse */
  warehouseCreate?: Maybe<WarehouseEntity>;
  /** Adds zone to a listed product */
  listedProductAddZone?: Maybe<ListedProductEntity>;
  /** Add a price tier to a simple product */
  simpleProductAddPriceTier?: Maybe<SimpleProductEntity>;
  /** Removes a product media from the listed product */
  listedProductRemoveListingMedia?: Maybe<ListingEntity>;
  /** Gets printers for an existing workstation */
  autoprintListPrinters?: Maybe<Array<Maybe<PrinterEntity>>>;
  /** Adds a comment to a sales order */
  salesOrderAddComment?: Maybe<SalesOrderEntity>;
  /** Remove an item from this kit */
  kitProductRemoveKitItem?: Maybe<KitProductEntity>;
  /** Generates a client token for current user. */
  checkoutGetClientToken?: Maybe<ClientTokenDetail>;
  /** Triggers a re-sync of updated orders with Petco SD */
  maintenancePetcoSdSyncUpdatedOrders?: Maybe<Scalars['Boolean']>;
  /** Process subscriptions */
  maintenanceProcessSubscriptions?: Maybe<Scalars['Boolean']>;
  /** Updates payments with processor */
  salesOrderUpdatePayments?: Maybe<SalesOrderEntity>;
  /** Edits an item quantity on a purchase order */
  purchaseOrderEditItemQty?: Maybe<PurchaseOrderEntity>;
  /** Sets a blog's published revision */
  blogSetPublishedRevision?: Maybe<BlogEntity>;
  /** Creates a new department */
  departmentCreate?: Maybe<DepartmentEntity>;
  /** Removes a listing from a listed product */
  listedProductRemoveListing?: Maybe<ListedProductEntity>;
  /** Sets the cart's pickup warehouse */
  cartSetPickupWarehouse?: Maybe<CartEntity>;
  /** Sets the supplier's name */
  supplierSetName?: Maybe<SupplierEntity>;
  /** Clears the cart's coupon code */
  cartClearCouponCode?: Maybe<CartEntity>;
  /** Create a new discount */
  discountCreate?: Maybe<DiscountEntity>;
  /** Completes checkout and processes payment. */
  cartCheckout?: Maybe<SalesOrderEntity>;
  /** Removes a user account from a partner */
  partnerRemoveUser?: Maybe<PartnerEntity>;
  /** Sets a simple product's wholesale price */
  simpleProductSetWholesalePrice?: Maybe<SimpleProductEntity>;
  /** Sets a partner's affiliate biography */
  affiliateSetAffiliateBiography?: Maybe<PartnerEntity>;
  /** Triggers an update to all inventory caches */
  maintenanceUpdateInventoryCaches?: Maybe<Scalars['Boolean']>;
  /** Processes a user's reward expirations */
  assistedRewardPointsProcessRewardsExpiration?: Maybe<RewardsAccountEntity>;
  /** Adds zones to a warehouse */
  warehouseAddZones?: Maybe<WarehouseEntity>;
  /** Sets a partner's reseller tax mode */
  resellerSetResellerTaxMode?: Maybe<PartnerEntity>;
  /** Sets a listing's dimensions */
  listedProductSetListingDimensions?: Maybe<ListingEntity>;
  /** Adds a user account to a partner */
  partnerAddUser?: Maybe<PartnerEntity>;
  /** Changes quantity of selected kit product in user's cart */
  cartChangeQuantityKitProduct?: Maybe<CartEntity>;
  /** Adds quantity of selected kit product to user's cart */
  assistedCartAddKitProduct?: Maybe<CartEntity>;
  /** Adds an existing image to the simple product */
  simpleProductAddImage?: Maybe<SimpleProductEntity>;
  /** Generates a shipment dashboard */
  dropshipShipmentDashboard?: Maybe<Dashboard>;
  /** Set the keywords of a media */
  mediaSetKeywords?: Maybe<MediaEntity>;
  /** Sets a configurable product's active flag */
  configurableProductSetActive?: Maybe<ConfigurableProductEntity>;
  /** Sets an alternate item on a kit */
  kitProductSetKitItemAlternate?: Maybe<KitProductEntity>;
  /** Removes a role from a user */
  userRemoveRole?: Maybe<UserEntity>;
  /** Generates a list of single-use coupon codes */
  promotionGenerateCustomCouponCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Remove an animal from a configurable product */
  configurableProductRemoveAnimal?: Maybe<ConfigurableProductEntity>;
  /** Triggers a fix to clean up inventory issues */
  maintenanceFixInventoryIssues?: Maybe<Scalars['Boolean']>;
  /** Remove an item from this configurable */
  configurableProductRemoveConfigurableItem?: Maybe<ConfigurableProductEntity>;
  /** Adds a component item to the simple product */
  simpleProductAddComponentItem?: Maybe<SimpleProductEntity>;
  /** Sets a simple product's published revision */
  simpleProductSetPublishedRevision?: Maybe<SimpleProductEntity>;
  /** Gets rates for a multi-piece shipment */
  shipmentRateMultiPiece?: Maybe<Array<Maybe<RateQuote>>>;
  /** Lists payment methods for the current user. */
  checkoutListPaymentMethods?: Maybe<Array<Maybe<PaymentMethodDetail>>>;
  /** Refreshes a kit product (with Magento) by entity id */
  kitProductRefresh?: Maybe<KitProductEntity>;
  /** Reindexes a kit product */
  kitProductReindex?: Maybe<KitProductEntity>;
  /** Approves a scheduled shipment for departure today */
  assistedScheduledShipmentSetApproval?: Maybe<ShipmentEntity>;
  /** Deletes a blog topic */
  blogTopicDelete: Scalars['Boolean'];
  /** Set the sequence for an affiliate's product list */
  affiliateSetProductListSequence?: Maybe<PartnerEntity>;
  /** Deletes an item on a purchase order */
  purchaseOrderDeleteItem?: Maybe<PurchaseOrderEntity>;
  /** Sets the sequence of a listed product media */
  listedProductSetListingMediaSequence?: Maybe<ListingEntity>;
  /** Triggers a re-sync of updated orders with Amazon */
  maintenanceAmazonReSyncOrders?: Maybe<Scalars['Boolean']>;
  /** Create */
  resellerProfileCreate?: Maybe<ResellerProfileEntity>;
  /** Uploads an image and adds it to the simple product */
  simpleProductUploadImage?: Maybe<SimpleProductEntity>;
  /** Returns unused single-use coupon codes */
  promotionListUnusedCustomCouponCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Unassigns a pos terminal to a tradeshow */
  tradeShowUnassignTerminal?: Maybe<TradeShowEntity>;
  /** Sets a warehouse's slug */
  warehouseSetSlug?: Maybe<WarehouseEntity>;
  /** Sets a listed product's prep type */
  listedProductSetPrepType?: Maybe<ListedProductEntity>;
  /** Sends an email confirmation email */
  userSendEmailConfirmation?: Maybe<Scalars['Boolean']>;
  /** Add an item to this configurable */
  configurableProductAddConfigurableItem?: Maybe<ConfigurableProductEntity>;
  /** Syncs purchase orders */
  maintenanceSyncPurchaseOrders?: Maybe<Scalars['Boolean']>;
  /** Adds an item to a sales order */
  salesOrderAddItem?: Maybe<SalesOrderEntity>;
  /** Clears the supplier's phone */
  supplierClearPhone?: Maybe<SupplierEntity>;
  /** Changes quantity of selected listed product in user's cart */
  cartChangeQuantityListedProduct?: Maybe<CartEntity>;
  /** Acknowledges a shipment's print job has printed */
  autoprintPrintAcknowledgeForPrinter: Scalars['Boolean'];
  /** Adds the products to user's cart */
  assistedCartAddProducts?: Maybe<CartEntity>;
  /** Sets a kit product's meta keywords */
  kitProductSetMetaKeywords?: Maybe<KitProductEntity>;
  /** Sets the eligible item count of the promotion */
  promotionSetEligibleItemCount?: Maybe<PromotionEntity>;
  /** Converts a simple product to a kit */
  simpleProductConvertToKit?: Maybe<KitProductEntity>;
  /** Sets the sales order's hold status */
  salesOrderSetHold?: Maybe<SalesOrderEntity>;
  /** Sets the supplier's phone */
  supplierSetPhone?: Maybe<SupplierEntity>;
  /** Reindexes a simple product */
  simpleProductReindex?: Maybe<SimpleProductEntity>;
  /** Removes a product media from the simple product */
  simpleProductRemoveProductMedia?: Maybe<SimpleProductEntity>;
  /** Sends a Petco SD inventory email */
  maintenanceSendPetcoSdInventory?: Maybe<Scalars['Boolean']>;
  /** Adds zone to a simple product */
  simpleProductAddZone?: Maybe<SimpleProductEntity>;
  /** Assigns dimensions to a simple product */
  simpleProductSetDimensions?: Maybe<SimpleProductEntity>;
  /** Sets a warehouse's name */
  warehouseSetName?: Maybe<WarehouseEntity>;
  /** Sets a product's discontinuation reason */
  simpleProductSetDiscontinuationReason?: Maybe<SimpleProductEntity>;
  /** Sets a kit product's active flag */
  kitProductSetActive?: Maybe<KitProductEntity>;
  /** Sets a care sheet's meta description */
  careSheetSetMetaDescription?: Maybe<CareSheetEntity>;
  /** Reject a pending review */
  assistedReviewReject?: Maybe<ReviewEntity>;
  /** Sets a simple product's meta keywords */
  simpleProductSetMetaKeywords?: Maybe<SimpleProductEntity>;
  /** Sets a listing's quantity */
  listedProductSetListingQuantity?: Maybe<ListingEntity>;
  /** Clears an alternate item on a kit */
  kitProductClearKitItemAlternate?: Maybe<KitProductEntity>;
  /** Sets a listing's sex */
  listedProductSetListingSex?: Maybe<ListingEntity>;
  /** Sets a listed product's active flag */
  listedProductSetActive?: Maybe<ListedProductEntity>;
  /** Update's a user's payment terms */
  userUpdateNetTerms?: Maybe<UserEntity>;
  /** Deletes an existing address */
  addressDelete: Scalars['Boolean'];
  /** Creates a new user, with password hash */
  userCreate?: Maybe<UserEntity>;
  /** Sets the cart's shipping address to the same as its billing address */
  cartSetShippingAddressFromBillingAddress?: Maybe<CartEntity>;
  /** Assigns case dimensions to a simple product */
  simpleProductSetCaseDimensions?: Maybe<SimpleProductEntity>;
  /** Sets the cart's email */
  assistedCartSetEmail?: Maybe<CartEntity>;
  /** Updates an existing tradeshow */
  tradeShowSetPreOrderDates?: Maybe<TradeShowEntity>;
  /** Provides making stock status */
  makingStockStatus?: Maybe<Array<Maybe<InventoryDetails>>>;
  /** Uploads an image and adds it to the listed product */
  listedProductUploadListingImage?: Maybe<ListingEntity>;
  /** Sends a Chewy inventory report */
  maintenanceSendChewyInventory?: Maybe<Scalars['Boolean']>;
  /** Removes a category from a product */
  productRemoveCategory?: Maybe<ProductEntity>;
  /** Lists payment methods for the specified user. */
  assistedPaymentMethodList?: Maybe<Array<Maybe<PaymentMethodDetail>>>;
  /** Creates a new application */
  applicationCreate?: Maybe<ApplicationEntity>;
  /** Triggers sync a transaction with Poynt */
  maintenancePoyntSyncTransaction?: Maybe<Scalars['Boolean']>;
  /** Adds a role to a user */
  userAddRole?: Maybe<UserEntity>;
  /** Deletes a brand */
  supplierDelete: Scalars['Boolean'];
  /** Adds brands to the promotion */
  promotionAddBrands?: Maybe<PromotionEntity>;
  /** Adds an existing image to the configurable product */
  configurableProductAddImage?: Maybe<ConfigurableProductEntity>;
  /** Sets whether stacking is permitted for the promotion */
  promotionSetPermitStacking?: Maybe<PromotionEntity>;
  /** Sets an listed product's featured flag */
  listedProductSetFeatured?: Maybe<ListedProductEntity>;
  /** Voids a shipment */
  shipmentVoid?: Maybe<ShipmentEntity>;
  /** Set the position of a configurable item */
  configurableProductSetConfigurableItemPosition?: Maybe<ConfigurableProductEntity>;
  /** Adds an existing image to the listed product */
  listedProductAddListingImage?: Maybe<ListingEntity>;
  /** Triggers a fix to clean up inventory issues */
  maintenanceFixInventoryIssue?: Maybe<Scalars['Boolean']>;
  /** Attempts to pre-label shipments */
  maintenanceAttemptPreLabels?: Maybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationListedProductRemoveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPartnerJoinArgs = {
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedCartClearTaxExemptArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderOnlineRefundArgs = {
  reason?: Maybe<RefundReason>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductAddCrossSellProductsArgs = {
  productIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationWarehouseSetAlternateSlugArgs = {
  alternateSlug?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentFilterUnshippedBySimpleProductArgs = {
  warehouseId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountSetPermitStackingArgs = {
  permitStacking: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetShippingNeedsTypeArgs = {
  shippingNeedsType: ShippingNeedsType;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationScheduledShipmentPossibleDeliveryDatesByHashArgs = {
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationDropshipWarehouseWorkScheduleClearOpenCloseTimeArgs = {
  dayOfWeek: DayOfWeek;
  partnerId: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductAddPriceTierArgs = {
  quantity: Scalars['BigDecimal'];
  price: Scalars['BigDecimal'];
  userType?: Maybe<UserType>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCategorySetMetaKeywordsArgs = {
  metaKeywords?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedPaymentMethodCreateArgs = {
  deviceData: Scalars['String'];
  nonce: Scalars['String'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogAddImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentLookupWeatherArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetTradeshowArgs = {
  tradeshowId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionSetNextDeliveryDateArgs = {
  subscriptionItemId?: Maybe<Scalars['UUID']>;
  nextDeliveryDate: Scalars['LocalDate'];
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAffiliateSetProductListItemPositionArgs = {
  listId: Scalars['UUID'];
  affiliateProductListItemId: Scalars['UUID'];
  position: Scalars['Int'];
};


/** Mutation root */
export type MutationReviewRemoveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentSplitArgs = {
  lineItems: Array<Maybe<ShipmentLineItemInput>>;
  id: Scalars['UUID'];
  reroute: Scalars['Boolean'];
};


/** Mutation root */
export type MutationDiscountRemoveDepartmentsArgs = {
  departmentIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountSetSlugArgs = {
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetShippingNeedsTypeArgs = {
  shippingNeedsType: ShippingNeedsType;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetFeaturedArgs = {
  featured: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationWarehouseAddCarriersArgs = {
  carriers: Array<Maybe<Carrier>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionSetPromotionTypeArgs = {
  promotionType: PromotionType;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCategorySetImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountSetNameArgs = {
  name: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetSpecialPriceArgs = {
  id: Scalars['UUID'];
  specialPrice?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationProductSummarizeArgs = {
  maxCharacters: Scalars['Int'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPurchaseRequestApproveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderCustomRefundSummarizeArgs = {
  reason?: Maybe<RefundReason>;
  shipping: Scalars['BigDecimal'];
  productDiscount?: Maybe<Scalars['BigDecimal']>;
  handling: Scalars['BigDecimal'];
  shippingDiscount: Scalars['BigDecimal'];
  subTotal?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductCloneArgs = {
  price: Scalars['BigDecimal'];
  id: Scalars['UUID'];
  title: Scalars['String'];
  sku: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationProductSetDepartmentArgs = {
  productId: Scalars['UUID'];
  departmentId: Scalars['UUID'];
};


/** Mutation root */
export type MutationWarehouseRemoveZonesArgs = {
  zoneIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductSetSlugArgs = {
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductRemoveWarehouseArgs = {
  productId: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationScheduledShipmentContactCustomerServiceArgs = {
  id: Scalars['UUID'];
  message: Scalars['String'];
};


/** Mutation root */
export type MutationTradeShowSendReportArgs = {
  emails: Array<Maybe<Scalars['String']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationTradeShowCreateShowArgs = {
  preOrderStartDate?: Maybe<Scalars['LocalDate']>;
  endDate: Scalars['LocalDate'];
  city: Scalars['String'];
  preOrderEndDate?: Maybe<Scalars['LocalDate']>;
  postalCode: Scalars['String'];
  name: Scalars['String'];
  posTerminalsRequested?: Maybe<Scalars['Long']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  startDate: Scalars['LocalDate'];
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartClearServiceTypePreferenceArgs = {
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationMagentoSalesOrderRefreshArgs = {
  magentoId: Scalars['String'];
};


/** Mutation root */
export type MutationPromotionSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartReorderArgs = {
  orderId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationMagentoSalesOrderRefreshAsyncArgs = {
  magentoId: Scalars['String'];
};


/** Mutation root */
export type MutationPromotionRemoveDepartmentsArgs = {
  departmentIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionCreateArgs = {
  shippingCity: Scalars['String'];
  shippingCompany?: Maybe<Scalars['String']>;
  deliveryDay?: Maybe<DayOfWeek>;
  shippingPostalCode: Scalars['String'];
  frequency: Scalars['Long'];
  paymentToken?: Maybe<Scalars['String']>;
  billingCompany?: Maybe<Scalars['String']>;
  shippingCountry: Scalars['String'];
  billingLine2?: Maybe<Scalars['String']>;
  billingLine1: Scalars['String'];
  billingFirstName: Scalars['String'];
  billingCountry: Scalars['String'];
  shippingResidential?: Maybe<Scalars['Boolean']>;
  shippingLine2?: Maybe<Scalars['String']>;
  quantity: Scalars['Long'];
  taxExempt?: Maybe<Scalars['Boolean']>;
  productId: Scalars['UUID'];
  shippingLastName: Scalars['String'];
  billingState: Scalars['String'];
  userId: Scalars['UUID'];
  billingLastName: Scalars['String'];
  phone: Scalars['String'];
  billingPostalCode: Scalars['String'];
  shippingFirstName: Scalars['String'];
  shippingState: Scalars['String'];
  shippingLine1: Scalars['String'];
  billingCity: Scalars['String'];
};


/** Mutation root */
export type MutationShipmentGenerateCommercialInvoiceArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartAuthorizeUrlArgs = {
  subscribeToNewsletter?: Maybe<Scalars['Boolean']>;
  cartId?: Maybe<Scalars['UUID']>;
  giftCardPin?: Maybe<Scalars['String']>;
  giftCardCode?: Maybe<Scalars['String']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationDiscountAddCategoriesArgs = {
  categoryIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationQueueUpdateArgs = {
  template?: Maybe<Scalars['String']>;
  singlesOnly?: Maybe<Scalars['Boolean']>;
  shippingNeedsType?: Maybe<ShippingNeedsType>;
  combosOnly?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  zoneId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  priority: Scalars['Long'];
  enabled: Scalars['Boolean'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetProductMediaSequenceArgs = {
  sequence: Scalars['Int'];
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationWarehouseSetLongitudeArgs = {
  id: Scalars['UUID'];
  longitude?: Maybe<Scalars['Float']>;
};


/** Mutation root */
export type MutationDropshipShipmentGenerateLaserLabelsArgs = {
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPartnerSetDropshipperArgs = {
  dropshipper: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionSetEligibleCouponCodeArgs = {
  eligibleCouponCode?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedReviewRemoveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionClearServiceLevelArgs = {
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPartnerCreateArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationDiscountAddDepartmentsArgs = {
  departmentIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBrandDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductRecomputeShippingEstimateArgs = {
  state: State;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountSetEligibleCartValueArgs = {
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetCrossSellItemPositionArgs = {
  crossSellItemId: Scalars['UUID'];
  position: Scalars['Int'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartClearShippingAddressArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDropshipShipmentGeneratePackingListArgs = {
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductCreateArgs = {
  cost?: Maybe<Scalars['BigDecimal']>;
  price: Scalars['BigDecimal'];
  weight: Scalars['BigDecimal'];
  upc?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  sku: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  vendorRef?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedSubscriptionSetCarrierPreferenceArgs = {
  carrierPreference: Carrier;
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRemovePriceTierArgs = {
  priceTierId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionUpdateArgs = {
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  permitStacking: Scalars['Boolean'];
  promotionType: PromotionType;
  endDate?: Maybe<Scalars['Instant']>;
  eligibleItemCount?: Maybe<Scalars['Long']>;
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  eligibleCouponCode?: Maybe<Scalars['String']>;
  departmentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  eligibleItemValue?: Maybe<Scalars['BigDecimal']>;
  htmlContent?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  eligibleUserType?: Maybe<UserType>;
  name: Scalars['String'];
  id: Scalars['UUID'];
  startDate?: Maybe<Scalars['Instant']>;
  slug: Scalars['String'];
  teaser?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAffiliateSetSlugArgs = {
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationDiscountSetDiscountAmountArgs = {
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartLookupArgs = {
  lookup?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationCartSetShippingZipArgs = {
  zip: Scalars['String'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationPetcoSdProfileCreateArgs = {
  productId: Scalars['UUID'];
  description: Scalars['String'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductRemoveComponentItemArgs = {
  id: Scalars['UUID'];
  componentItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductRemoveCrossSellProductArgs = {
  crossSellProductId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderAddPaymentTermsPaymentArgs = {
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationScheduledShipmentScheduleArgs = {
  date: Scalars['LocalDate'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetBillingAddressByIdArgs = {
  cartId: Scalars['UUID'];
  addressId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDropshipWarehouseWorkScheduleCreateHolidayArgs = {
  date: Scalars['LocalDate'];
  shipsScheduled: Scalars['Boolean'];
  closeTime: Scalars['LocalTime'];
  partnerId: Scalars['UUID'];
  openTime: Scalars['LocalTime'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetCostArgs = {
  cost?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductRemoveProductMediaArgs = {
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserUpdateEmailArgs = {
  id: Scalars['UUID'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductCreateListingArgs = {
  identifier: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  quantity: Scalars['Long'];
  sex?: Maybe<SexType>;
  length?: Maybe<Scalars['BigDecimal']>;
  weight?: Maybe<Scalars['BigDecimal']>;
  title: Scalars['String'];
  birthDate?: Maybe<Scalars['LocalDate']>;
  warehouseId: Scalars['UUID'];
  price: Scalars['BigDecimal'];
  width?: Maybe<Scalars['BigDecimal']>;
  listingWeightUnit?: Maybe<ListingWeightUnit>;
  id: Scalars['UUID'];
  weightDate?: Maybe<Scalars['LocalDate']>;
  height?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationAssistedCartSummarizeArgs = {
  customDiscount?: Maybe<Scalars['BigDecimal']>;
  cartId: Scalars['UUID'];
  useRewardPoints?: Maybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationCartClearPhoneArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationListedProductRemovePriceTierArgs = {
  priceTierId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetPublishedRevisionArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationShipmentConfirmArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionAddItemArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
  frequency: Scalars['Long'];
};


/** Mutation root */
export type MutationShipmentDashboardArgs = {
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationShipmentAttemptPreLabelArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPartnerSetAffiliateArgs = {
  id: Scalars['UUID'];
  affiliate: Scalars['Boolean'];
};


/** Mutation root */
export type MutationAssistedCartSetFulfillmentNetworkArgs = {
  id: Scalars['UUID'];
  fulfillmentNetwork: FulfillmentNetworkType;
};


/** Mutation root */
export type MutationPurchaseRequestDenyArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartClearFulfillmentNetworkArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductShippingEstimatesArgs = {
  productId: Scalars['UUID'];
  warehouseId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAutoprintEnrollWorkstationArgs = {
  machineKey: Scalars['String'];
  name: Scalars['String'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationShipmentShipMultiPieceArgs = {
  carrier: Carrier;
  service: Service;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  reseller: Reseller;
  packaging?: Maybe<Packaging>;
  id: Scalars['UUID'];
  packages: Array<Maybe<PackageSizeInput>>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationShipmentReprocessArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationRedirectFindByPathArgs = {
  path: Scalars['String'];
};


/** Mutation root */
export type MutationScheduledShipmentContactCustomerServiceByHashArgs = {
  message: Scalars['String'];
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationPromotionEvaluateImplicitProductsArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionSetNextDeliveryDateArgs = {
  subscriptionItemId?: Maybe<Scalars['UUID']>;
  nextDeliveryDate: Scalars['LocalDate'];
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlockSetHtmlContentArgs = {
  id: Scalars['UUID'];
  htmlContent: Scalars['String'];
};


/** Mutation root */
export type MutationSubscriptionCreateArgs = {
  shippingCity: Scalars['String'];
  shippingCompany?: Maybe<Scalars['String']>;
  deliveryDay?: Maybe<DayOfWeek>;
  shippingPostalCode: Scalars['String'];
  frequency: Scalars['Long'];
  paymentToken?: Maybe<Scalars['String']>;
  billingCompany?: Maybe<Scalars['String']>;
  shippingCountry: Scalars['String'];
  billingLine2?: Maybe<Scalars['String']>;
  billingLine1: Scalars['String'];
  billingFirstName: Scalars['String'];
  billingCountry: Scalars['String'];
  promoCode?: Maybe<Scalars['String']>;
  shippingResidential?: Maybe<Scalars['Boolean']>;
  shippingLine2?: Maybe<Scalars['String']>;
  quantity: Scalars['Long'];
  taxExempt?: Maybe<Scalars['Boolean']>;
  productId: Scalars['UUID'];
  shippingLastName: Scalars['String'];
  billingState: Scalars['String'];
  billingLastName: Scalars['String'];
  phone: Scalars['String'];
  billingPostalCode: Scalars['String'];
  shippingFirstName: Scalars['String'];
  shippingState: Scalars['String'];
  shippingLine1: Scalars['String'];
  billingCity: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetWeightArgs = {
  weight: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetMapPriceArgs = {
  mapPrice?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductClearSupplierArgs = {
  productId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetEmailArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductRemoveCrossSellItemArgs = {
  crossSellItemId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetCarrierPreferenceArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
  carrierPreference: Carrier;
};


/** Mutation root */
export type MutationPetcoDsProfileCreateArgs = {
  surcharge: Scalars['BigDecimal'];
  productId: Scalars['UUID'];
  description: Scalars['String'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetFeaturedArgs = {
  featured: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserConfirmEmailArgs = {
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationDropshipInventorySetDetailsArgs = {
  quantity: Scalars['Long'];
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationCareSheetDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserChangePasswordByHashArgs = {
  password: Scalars['String'];
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductSetMetaTitleArgs = {
  metaTitle?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetShippingZipArgs = {
  zip: Scalars['String'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSupplierSetEmailArgs = {
  id?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
};


/** Mutation root */
export type MutationPurchaseRequestAddItemArgs = {
  amount?: Maybe<Scalars['BigDecimal']>;
  quantity?: Maybe<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAssistedCartCheckoutArgs = {
  customDiscount?: Maybe<Scalars['BigDecimal']>;
  skipAvs?: Maybe<Scalars['Boolean']>;
  method: Scalars['String'];
  cartId: Scalars['UUID'];
  deviceData?: Maybe<Scalars['String']>;
  giftCardPin?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  giftCardCode?: Maybe<Scalars['String']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
  nonce?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSubscriptionSetShippingAddressFromBillingAddressArgs = {
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderCancelByHashArgs = {
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationDropshipWarehouseWorkScheduleRemoveHolidayArgs = {
  date: Scalars['LocalDate'];
  partnerId: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationCartSetContactArgs = {
  phone?: Maybe<Scalars['String']>;
  cartId?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationListedProductSetBrandArgs = {
  productId: Scalars['UUID'];
  brandId: Scalars['UUID'];
};


/** Mutation root */
export type MutationGiftCardReloadArgs = {
  amount: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetTradeshowArgs = {
  tradeshowId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationUserUpdateAdminArgs = {
  admin: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionSetDiscountAmountArgs = {
  discountAmount: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAddressVerifyRawArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetListingBirthDateArgs = {
  id: Scalars['UUID'];
  birthDate?: Maybe<Scalars['LocalDate']>;
};


/** Mutation root */
export type MutationKitProductSetProductMediaSequenceArgs = {
  sequence: Scalars['Int'];
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountSetHtmlContentArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationKitProductSetMetaTitleArgs = {
  metaTitle?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderAddBraintreeOfflinePaymentArgs = {
  orderId: Scalars['UUID'];
  transactionId: Scalars['String'];
};


/** Mutation root */
export type MutationSalesOrderGenerateInvoiceByHashArgs = {
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCartSetDeliveryDatePreferenceArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
  deliveryDatePreference: Scalars['LocalDate'];
};


/** Mutation root */
export type MutationPurchaseRequestReceiveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionSetEligibleCartValueArgs = {
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSupplierCreateArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  name: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetHeightArgs = {
  id: Scalars['UUID'];
  height?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationPromotionSetEligibleUserTypeArgs = {
  eligibleUserType?: Maybe<UserType>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedAddressCreateArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  userId: Scalars['UUID'];
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationAddressVerifyArgs = {
  commit: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAffiliateSetAttributionPeriodArgs = {
  period: Scalars['Long'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetPrepTypeArgs = {
  prepType?: Maybe<PrepType>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRefreshBySkuArgs = {
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationCategoryCreateArgs = {
  name: Scalars['String'];
  magentoIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug: Scalars['String'];
  parentId: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductRemoveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductRemoveWarehouseArgs = {
  productId: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationDiscountListUnusedCustomCouponCodesArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogSetTitleArgs = {
  id: Scalars['UUID'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductSetSubscriptionArgs = {
  subscription: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionAddItemArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  promoCode?: Maybe<Scalars['String']>;
  subscriptionId: Scalars['UUID'];
  frequency: Scalars['Long'];
};


/** Mutation root */
export type MutationAffiliateSetCommissionArgs = {
  commission: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionSetBillingAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  subscriptionId: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetSupplierArgs = {
  supplierId: Scalars['UUID'];
  productId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListedProductTypeArgs = {
  listedProductType: ListedProductType;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDepartmentSetParentArgs = {
  parent: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingWeightArgs = {
  weight?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogAddBlogTopicsArgs = {
  blogTopicIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBatchPrintQueueInfoArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductClearBrandArgs = {
  productId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionSetNameArgs = {
  name: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAffiliateSetProductListHtmlContentArgs = {
  listId: Scalars['UUID'];
  htmlContent: Scalars['String'];
};


/** Mutation root */
export type MutationBlogDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingPriceArgs = {
  price: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountRemoveExplicitProductsArgs = {
  explicitProductIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedScheduledShipmentSendSchedulingNeededNotificationArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAffiliateAddProductListItemsArgs = {
  listId: Scalars['UUID'];
  productIds: Array<Maybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationCartClearTaxExemptArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductClearBinArgs = {
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartSetDeliveryDatePreferenceArgs = {
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
  deliveryDatePreference: Scalars['LocalDate'];
};


/** Mutation root */
export type MutationPromotionSetStartDateArgs = {
  id: Scalars['UUID'];
  startDate?: Maybe<Scalars['Instant']>;
};


/** Mutation root */
export type MutationCheckoutSetDefaultPaymentMethodArgs = {
  token: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductSetMetaKeywordsArgs = {
  metaKeywords?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductRemoveCrossSellItemArgs = {
  crossSellItemId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDropshipShipmentShipOfflineArgs = {
  carrier?: Maybe<Carrier>;
  trackingNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  service?: Maybe<Service>;
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetVisibleArgs = {
  visible: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductRemoveProductMediaArgs = {
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPurchaseOrderGenerateCsvArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBrandSetNameArgs = {
  name: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationBlogRemoveProductArgs = {
  productId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRemoveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRemoveCrossSellItemArgs = {
  crossSellItemId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCheckoutCreatePaymentMethodArgs = {
  deviceData: Scalars['String'];
  nonce: Scalars['String'];
};


/** Mutation root */
export type MutationPspCorporateSalesOrderRefreshArgs = {
  alternateOrderNumber: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedSubscriptionSetShippingAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  subscriptionId: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductAddPriceTierArgs = {
  quantity: Scalars['BigDecimal'];
  price: Scalars['BigDecimal'];
  userType?: Maybe<UserType>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartChangeQuantityListedProductArgs = {
  quantity: Scalars['Long'];
  cartId: Scalars['UUID'];
  listingId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAutoprintGetNextForPrinterArgs = {
  machineKey: Scalars['String'];
  printerName: Scalars['String'];
};


/** Mutation root */
export type MutationCartSetBillingAddressByIdArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  addressId: Scalars['UUID'];
};


/** Mutation root */
export type MutationZoneUpdateArgs = {
  sequence?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedReviewApproveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionProcessArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedReviewCreateArgs = {
  comments: Scalars['String'];
  productId: Scalars['UUID'];
  rating: Scalars['Long'];
  mediaIds: Array<Maybe<Scalars['UUID']>>;
  reviewer?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationKitProductSetWholesalePriceArgs = {
  id: Scalars['UUID'];
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationKitProductCreateArgs = {
  price: Scalars['BigDecimal'];
  title: Scalars['String'];
  sku: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationDropshipWarehouseWorkScheduleSetOpenCloseTimeArgs = {
  dayOfWeek: DayOfWeek;
  shipsScheduled: Scalars['Boolean'];
  closeTime: Scalars['LocalTime'];
  partnerId: Scalars['UUID'];
  openTime: Scalars['LocalTime'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetMetaTitleArgs = {
  metaTitle?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionSetEndDateArgs = {
  endDate?: Maybe<Scalars['Instant']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionSetPaymentTokenArgs = {
  paymentToken: Scalars['String'];
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductExportArgs = {
  supplier?: Maybe<Scalars['String']>;
  rating?: Maybe<GraphQlSingleValueFilter_LongInput>;
  animal?: Maybe<Scalars['String']>;
  active?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  inStock?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  category?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  sku?: Maybe<GraphQlLikeQueryFilterInput>;
  title?: Maybe<GraphQlLikeQueryFilterInput>;
  brand?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedCartSetBillingAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  cartId: Scalars['UUID'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationUserUpdateNameArgs = {
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartClearTradeshowArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationScheduledShipmentUpdateAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  addressType?: Maybe<AddressType>;
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  id: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetSpecialPriceArgs = {
  id: Scalars['UUID'];
  specialPrice?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationCartClearEmailArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationListedProductAddAnimalArgs = {
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDropshipShipmentGeneratePackingListZplArgs = {
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAddressCreateArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedAddressDeleteArgs = {
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationProductIndexArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCategorySetPublishedRevisionArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSimpleProductSetLiveArgs = {
  id: Scalars['UUID'];
  live: Scalars['Boolean'];
};


/** Mutation root */
export type MutationSimpleProductRemoveAnimalArgs = {
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAutoprintReprintArgs = {
  shipment: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogCloneArgs = {
  id: Scalars['UUID'];
  title: Scalars['String'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationWarehouseWorkScheduleClearOpenCloseTimeArgs = {
  dayOfWeek: DayOfWeek;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetShippingAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  cartId: Scalars['UUID'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationAutoprintAddPrinterArgs = {
  machineKey: Scalars['String'];
  printerName: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductSetKitItemPositionArgs = {
  kitItemId: Scalars['UUID'];
  position: Scalars['Int'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAutoprintDownloadAcknowledgeForPrinterArgs = {
  shipment: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartClearCouponCodeArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAffiliateSetProductListSlugArgs = {
  listId: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetPriceArgs = {
  price: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductAddWarehouseArgs = {
  productId: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductSetSkuArgs = {
  id: Scalars['UUID'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationUserRegisterArgs = {
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductSetUpcArgs = {
  upc?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionSetShippingAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  subscriptionId: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationReviewCreateArgs = {
  comments: Scalars['String'];
  productId: Scalars['UUID'];
  rating: Scalars['Long'];
  mediaIds: Array<Maybe<Scalars['UUID']>>;
  title: Scalars['String'];
};


/** Mutation root */
export type MutationPreppedProductsAddPreparationListedLineItemArgs = {
  productId: Scalars['UUID'];
  shipmentListedItemId: Scalars['UUID'];
  shipDate?: Maybe<Scalars['LocalDate']>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedPaymentMethodSetDefaultArgs = {
  userId: Scalars['UUID'];
  token: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductAddAnimalArgs = {
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductAddCrossSellProductsArgs = {
  productIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlockCreateArgs = {
  name: Scalars['String'];
  slug: Scalars['String'];
  htmlContent: Scalars['String'];
};


/** Mutation root */
export type MutationAffiliateSetProductListNameArgs = {
  listId: Scalars['UUID'];
  name: Scalars['String'];
};


/** Mutation root */
export type MutationCartSetShippingPreferencesArgs = {
  serviceTypePreference?: Maybe<ServiceType>;
  cartId?: Maybe<Scalars['UUID']>;
  carrierPreference?: Maybe<Carrier>;
  deliveryDatePreference?: Maybe<Scalars['LocalDate']>;
  cartItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionSkipNextDeliveryArgs = {
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductRefreshBySkuArgs = {
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetBomArgs = {
  bomItems: Array<Maybe<BomItemInput>>;
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductRecomputeShippingEstimateArgs = {
  state: State;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductRemoveArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductClearDiscontinuationReasonArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetTitleArgs = {
  id: Scalars['UUID'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetListingWarehouseArgs = {
  warehouseId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserUpdatePasswordArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationSalesOrderOfflineRefundArgs = {
  reason?: Maybe<RefundReason>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedAddressVerifyArgs = {
  commit: Scalars['Boolean'];
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAffiliateDeleteProductListArgs = {
  listId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartClearBillingAddressArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationShipmentLookupWeatherByZipArgs = {
  zip: Scalars['String'];
};


/** Mutation root */
export type MutationBlogTopicCreateArgs = {
  name: Scalars['String'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationBlockDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetShippingAddressByIdArgs = {
  cartId: Scalars['UUID'];
  addressId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderValidateAddressArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductClearShipsAloneArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRecomputeShippingEstimatesAsyncArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCategoryUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationBlogSanitizeArgs = {
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAssistedSubscriptionSetShippingAddressFromBillingAddressArgs = {
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetShippingRuleSetArgs = {
  productId: Scalars['UUID'];
  shippingRuleSetId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentBatchCreateArgs = {
  queueId: Scalars['UUID'];
  count: Scalars['Long'];
  name?: Maybe<Scalars['String']>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationSalesOrderAddBraintreePaymentArgs = {
  orderId: Scalars['UUID'];
  transactionId: Scalars['String'];
};


/** Mutation root */
export type MutationCartRemoveAllKitProductArgs = {
  productId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSimpleProductSetWeightArgs = {
  weight: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDropshipShipmentUpdateAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential: Scalars['Boolean'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationSalesOrderGetRefundableItemsArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionRemoveItemArgs = {
  subscriptionItemId: Scalars['UUID'];
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPetcoDsSalesOrderRefreshArgs = {
  alternateOrderNumber: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetCostArgs = {
  cost?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartClearPickupWarehouseArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationPurchaseRequestOrderArgs = {
  amount: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentResendShipmentNotificationArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionSetItemNextDeliveryArgs = {
  subscriptionItemId: Scalars['UUID'];
  nextDeliveryDate: Scalars['LocalDate'];
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetSkuArgs = {
  id: Scalars['UUID'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationPartnerValidateAddressArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderAddCashPaymentArgs = {
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetBinArgs = {
  bin: Scalars['String'];
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationSalesTaxRateArgs = {
  postalCode: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSupplierValidateAddressArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetLiveArgs = {
  id: Scalars['UUID'];
  live: Scalars['Boolean'];
};


/** Mutation root */
export type MutationSimpleProductRemoveCrossSellProductArgs = {
  crossSellProductId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationProductAddCategoryArgs = {
  productId: Scalars['UUID'];
  categoryId: Scalars['UUID'];
};


/** Mutation root */
export type MutationTradeShowDeleteShowArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedReviewUpdateArgs = {
  comments: Scalars['String'];
  rating: Scalars['Long'];
  mediaIds: Array<Maybe<Scalars['UUID']>>;
  reviewer?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationCareSheetCreateArgs = {
  title: Scalars['String'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetVisibleArgs = {
  visible: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingIdentifierArgs = {
  identifier: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetUpcArgs = {
  upc?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetHsCodeArgs = {
  hsCode?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionSetTeaserArgs = {
  id: Scalars['UUID'];
  teaser?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationPromotionRemoveCategoriesArgs = {
  categoryIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartGetEarliestDeliveryDateArgs = {
  quantity: Scalars['Long'];
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductSetFeaturedArgs = {
  featured: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentCancelAndRefundArgs = {
  cancellationReason?: Maybe<CancellationReason>;
  sendNotification: Scalars['Boolean'];
  id: Scalars['UUID'];
  refund: Scalars['Boolean'];
};


/** Mutation root */
export type MutationPurchaseOrderGeneratePdfArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartAddListedProductArgs = {
  quantity: Scalars['Long'];
  cartId?: Maybe<Scalars['UUID']>;
  listingId: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductSetTitleArgs = {
  id: Scalars['UUID'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductSetSlugArgs = {
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductClearBinArgs = {
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductRemoveAnimalArgs = {
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAffiliateRemoveProductListItemArgs = {
  listId: Scalars['UUID'];
  affiliateProductListItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentShipOfflineArgs = {
  carrier?: Maybe<Carrier>;
  trackingNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  service?: Maybe<Service>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCategoryDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingWeightDateArgs = {
  id: Scalars['UUID'];
  weightDate?: Maybe<Scalars['LocalDate']>;
};


/** Mutation root */
export type MutationShipmentRerouteArgs = {
  shipment: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetShipsAloneArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAutoprintCancelAcknowledgeForPrinterArgs = {
  shipment: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetSkuArgs = {
  id: Scalars['UUID'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetBrandArgs = {
  productId: Scalars['UUID'];
  brandId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetCouponCodeArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  couponCode: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductRemoveProductMediaArgs = {
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderAddBraintreeOnlinePaymentArgs = {
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
  deviceData: Scalars['String'];
  nonce: Scalars['String'];
};


/** Mutation root */
export type MutationWarehouseSetLatitudeArgs = {
  latitude?: Maybe<Scalars['Float']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountSetEligibleCouponCodeArgs = {
  eligibleCouponCode?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetUpcArgs = {
  upc?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogSetMetaDescriptionArgs = {
  id: Scalars['UUID'];
  metaDescription?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSubscriptionRemoveItemArgs = {
  subscriptionItemId: Scalars['UUID'];
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductAddCrossSellItemsArgs = {
  productIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCareSheetSetContentMediaSequenceArgs = {
  sequence: Scalars['Int'];
  contentMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartCrossSellsArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentSplitAfterShipArgs = {
  lineItems: Array<Maybe<ShipmentLineItemInput>>;
  id: Scalars['UUID'];
  reroute: Scalars['Boolean'];
};


/** Mutation root */
export type MutationTradeShowCreateTerminalArgs = {
  urn: Scalars['String'];
  assetTag: Scalars['String'];
};


/** Mutation root */
export type MutationSalesOrderSalesTaxRefundArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetTitleArgs = {
  id: Scalars['UUID'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationCartInfoArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationCareSheetAddProductsArgs = {
  productIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionSetPauseReasonArgs = {
  pauseReason?: Maybe<PauseReason>;
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedAddressUpdateArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  id: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  userId: Scalars['UUID'];
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationDiscountRemoveCategoriesArgs = {
  categoryIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDropshipShipmentRateMultiPieceArgs = {
  applyRestrictions?: Maybe<Scalars['Boolean']>;
  packaging?: Maybe<Packaging>;
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
  packages: Array<Maybe<PackageSizeInput>>;
  shipDate?: Maybe<Scalars['String']>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationUserUpdateTaxExemptArgs = {
  taxExempt: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartCrossSellsArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionSalesOrderReleaseArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartRemoveCustomProductArgs = {
  cartId: Scalars['UUID'];
  cartCustomItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentSetShippingNeedsArgs = {
  shippingNeeds: ShippingNeedsType;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPartnerSetResellerArgs = {
  reseller: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentLookupWeatherByHashArgs = {
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartClearPickupWarehouseArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCategorySetParentArgs = {
  parent: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPreppedProductsAddPreparationArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  shipDate?: Maybe<Scalars['LocalDate']>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductRemoveZoneArgs = {
  productId: Scalars['UUID'];
  zoneId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentUpdateRateQuotesArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPartnerSetNameArgs = {
  name: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetCountryOfOriginArgs = {
  country?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetSpecialPriceArgs = {
  id: Scalars['UUID'];
  specialPrice?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationSimpleProductSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetShippingAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  cartId?: Maybe<Scalars['UUID']>;
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationMaintenanceDropshipperReportArgs = {
  start: Scalars['String'];
  end: Scalars['String'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationShipmentManifestArgs = {
  date: Scalars['String'];
  reseller: Reseller;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationSupplierSetLeadTimeArgs = {
  leadTime: Scalars['Int'];
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationWarehouseSetDropshipperArgs = {
  partnerId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationGiftCardCreateArgs = {
  amount: Scalars['BigDecimal'];
};


/** Mutation root */
export type MutationSimpleProductSetComponentItemQuantityArgs = {
  quantity: Scalars['BigDecimal'];
  id: Scalars['UUID'];
  componentItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductClearBrandArgs = {
  productId: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetPriceArgs = {
  price: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAffiliateAddProductListArgs = {
  name: Scalars['String'];
  id: Scalars['UUID'];
  slug: Scalars['String'];
  htmlContent: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductSetMetaDescriptionArgs = {
  id: Scalars['UUID'];
  metaDescription?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationWarehouseSetWarehouseTypeArgs = {
  warehouseType: WarehouseType;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAmazonSalesOrderRefreshArgs = {
  amazonId: Scalars['String'];
};


/** Mutation root */
export type MutationShipmentBatchBeginPickingArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentValidateAddressArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionClearServiceLevelArgs = {
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDropshipShipmentBatchCreateArgs = {
  count: Scalars['Long'];
  name?: Maybe<Scalars['String']>;
  partnerId: Scalars['UUID'];
  warehouse?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationDropshipShipmentBatchGeneratePackingListArgs = {
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductSetVisibleArgs = {
  visible: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionSetPaymentTokenArgs = {
  paymentToken: Scalars['String'];
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetCrossSellItemPositionArgs = {
  crossSellItemId: Scalars['UUID'];
  position: Scalars['Int'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductAddCrossSellItemsArgs = {
  productIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentResendCancelNotificationArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationInventoryGetDetailsArgs = {
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductAddCrossSellItemsArgs = {
  productIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBatchPrintQueueListArgs = {
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetSlugArgs = {
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationBlogSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedScheduledShipmentPossibleDeliveryDatesArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentGeneratePackingListArgs = {
  template?: Maybe<Scalars['String']>;
  htmlOnly?: Maybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationInventoryAddDetailsArgs = {
  quantity: Scalars['Long'];
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
  purchaseOrderItemId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAssistedCartChangeQuantitySimpleProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionRemoveBrandsArgs = {
  id: Scalars['UUID'];
  brandIds: Array<Maybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationAmazonProfileCreateArgs = {
  productId: Scalars['UUID'];
  fbaSellerSku?: Maybe<Scalars['String']>;
  asin: Scalars['String'];
  sellerSku?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedCartInfoArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  userId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAssistedPaymentMethodDeleteArgs = {
  id: Scalars['UUID'];
  token: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedSubscriptionSetItemQuantityArgs = {
  quantity: Scalars['Long'];
  subscriptionItemId: Scalars['UUID'];
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentCancelArgs = {
  cancellationReason?: Maybe<CancellationReason>;
  sendNotification?: Maybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPurchaseOrderCloseArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMediaUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedPaymentAccountMergeArgs = {
  sourceId: Scalars['UUID'];
  destId: Scalars['UUID'];
};


/** Mutation root */
export type MutationChewyProfileCreateArgs = {
  productId: Scalars['UUID'];
  description: Scalars['String'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductCreateArgs = {
  price: Scalars['BigDecimal'];
  title: Scalars['String'];
  sku: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedCartSetShippingAddressFromBillingAddressArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionAddCategoriesArgs = {
  categoryIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationWarehouseWorkScheduleCreateHolidayArgs = {
  date: Scalars['LocalDate'];
  shipsScheduled: Scalars['Boolean'];
  closeTime: Scalars['LocalTime'];
  id: Scalars['UUID'];
  openTime: Scalars['LocalTime'];
};


/** Mutation root */
export type MutationDiscountUpdateArgs = {
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  permitStacking: Scalars['Boolean'];
  endDate?: Maybe<Scalars['Instant']>;
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  eligibleCouponCode?: Maybe<Scalars['String']>;
  departmentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  htmlContent?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  eligibleUserType?: Maybe<UserType>;
  name: Scalars['String'];
  discountType: DiscountType;
  id: Scalars['UUID'];
  startDate?: Maybe<Scalars['Instant']>;
  slug: Scalars['String'];
  teaser?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationShipmentTrackByHashArgs = {
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationAuthorizationAuthCodeArgs = {
  redirectUri: Scalars['String'];
  clientId: Scalars['String'];
  scope: AuthorizationScopeType;
  codeChallengeMethod: Scalars['String'];
  codeChallenge: Scalars['String'];
};


/** Mutation root */
export type MutationBlockUpdateArgs = {
  name: Scalars['String'];
  id: Scalars['UUID'];
  slug: Scalars['String'];
  htmlContent: Scalars['String'];
};


/** Mutation root */
export type MutationShipmentRescheduleArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartAddPartialKitProductArgs = {
  productId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSubscriptionSetDeliveryDayArgs = {
  deliveryDay?: Maybe<DayOfWeek>;
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCheckoutDeletePaymentMethodArgs = {
  token: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedRewardPointsCreditRewardsAccountArgs = {
  comment?: Maybe<Scalars['String']>;
  userId: Scalars['UUID'];
  points: Scalars['Long'];
};


/** Mutation root */
export type MutationAssistedScheduledShipmentScheduleArgs = {
  date: Scalars['LocalDate'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationResellerSetResellerShippingRateModeArgs = {
  resellerShippingRateMode: ResellerShippingRateMode;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationTradeShowUpdateShowArgs = {
  preOrderStartDate?: Maybe<Scalars['LocalDate']>;
  endDate: Scalars['LocalDate'];
  city: Scalars['String'];
  preOrderEndDate?: Maybe<Scalars['LocalDate']>;
  postalCode: Scalars['String'];
  name: Scalars['String'];
  posTerminalsRequested?: Maybe<Scalars['Long']>;
  state: Scalars['String'];
  id: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  startDate: Scalars['LocalDate'];
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationBlogAddProductsArgs = {
  productIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountRemoveEligibleProductsArgs = {
  id: Scalars['UUID'];
  eligibleProductIds: Array<Maybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationAssistedPaymentMethodGetClientTokenArgs = {
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetCrossSellItemPositionArgs = {
  crossSellItemId: Scalars['UUID'];
  position: Scalars['Int'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderGetRefundableAmountsArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartRemoveAllSimpleProductArgs = {
  productId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartRemoveAllSimpleProductArgs = {
  productId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSimpleProductSetLengthArgs = {
  length?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderSetEmailArgs = {
  id: Scalars['UUID'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartClearCarrierPreferenceArgs = {
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentCreateDropshipOrderArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogSetMetaTitleArgs = {
  metaTitle?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionAddDepartmentsArgs = {
  departmentIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartClearEmailArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCareSheetSanitizeArgs = {
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSubscriptionSetServiceLevelArgs = {
  serviceLevel: ServiceType;
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCategorySetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationWarehouseCreateZoneArgs = {
  sequence?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationCartClearDeliveryDatePreferenceArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationMediaSetTitleArgs = {
  id: Scalars['UUID'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationBrandCreateArgs = {
  name: Scalars['String'];
};


/** Mutation root */
export type MutationScheduledShipmentUpdateAddressByHashArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  addressType?: Maybe<AddressType>;
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedSubscriptionClearCarrierPreferenceArgs = {
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCareSheetRemoveProductArgs = {
  productId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionSetDeliveryDayArgs = {
  deliveryDay?: Maybe<DayOfWeek>;
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSupplierClearEmailArgs = {
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationWarehouseSetAddressArgs = {
  country: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  id: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationUserSendPasswordResetArgs = {
  email: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductPushArgs = {
  price?: Maybe<Scalars['BigDecimal']>;
  name?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  shortCode?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCareSheetSetPublishedRevisionArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCartChangeQuantitySimpleProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationDiscountSetStartDateArgs = {
  id: Scalars['UUID'];
  startDate?: Maybe<Scalars['Instant']>;
};


/** Mutation root */
export type MutationListedProductSetPriceArgs = {
  price: Scalars['BigDecimal'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSubscriptionClearCarrierPreferenceArgs = {
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetMetaDescriptionArgs = {
  id: Scalars['UUID'];
  metaDescription?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedCartNewCartArgs = {
  userId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSimpleProductSetVendorRefArgs = {
  id: Scalars['UUID'];
  vendorRef?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSubscriptionSetCarrierPreferenceArgs = {
  carrierPreference: Carrier;
  subscriptionId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartClearDeliveryDatePreferenceArgs = {
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetBinArgs = {
  bin: Scalars['String'];
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationCareSheetRemoveContentMediaArgs = {
  contentMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPartnerSetEmailArgs = {
  id: Scalars['UUID'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationPspFranchiseSalesOrderRefreshArgs = {
  alternateOrderNumber: Scalars['String'];
};


/** Mutation root */
export type MutationCareSheetSetMetaTitleArgs = {
  metaTitle?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetSkuArgs = {
  id: Scalars['UUID'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationDiscountEvaluateImplicitProductsArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductSetProductMediaSequenceArgs = {
  sequence: Scalars['Int'];
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountAddExplicitProductsArgs = {
  explicitProductIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDropshipShipmentValidateAddressArgs = {
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationWalmartSalesOrderRefreshArgs = {
  purchaseOrderId: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedSubscriptionSetBillingAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  subscriptionId: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductRemovePriceTierArgs = {
  priceTierId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderGenerateInvoiceArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPreppedProductsRemovePreparationArgs = {
  productId: Scalars['UUID'];
  preparationId: Scalars['UUID'];
  shipDate?: Maybe<Scalars['LocalDate']>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetUpcArgs = {
  upc?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetTitleArgs = {
  id: Scalars['UUID'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationSalesOrderPartialRefundSummarizeArgs = {
  lineItems: Array<Maybe<SalesOrderLineItemInput>>;
  reason?: Maybe<RefundReason>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductRemoveZoneArgs = {
  productId: Scalars['UUID'];
  zoneId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartClearPhoneArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedSubscriptionSetItemFrequencyArgs = {
  subscriptionItemId: Scalars['UUID'];
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
  frequency: Scalars['Long'];
};


/** Mutation root */
export type MutationSimpleProductSetSubscriptionArgs = {
  subscription: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetProductMediaSequenceArgs = {
  sequence: Scalars['Int'];
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationTradeShowAssignTerminalArgs = {
  tradeshowId: Scalars['UUID'];
  terminalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogSetMetaKeywordsArgs = {
  metaKeywords?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionSetEligibleItemValueArgs = {
  id: Scalars['UUID'];
  eligibleItemValue?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationSalesOrderCustomRefundArgs = {
  shipping: Scalars['BigDecimal'];
  refundReason?: Maybe<RefundReason>;
  productDiscount?: Maybe<Scalars['BigDecimal']>;
  handling: Scalars['BigDecimal'];
  shippingDiscount: Scalars['BigDecimal'];
  subTotal?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentBatchCompletePickingArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderAddCheckPaymentArgs = {
  checkNumber?: Maybe<Scalars['String']>;
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingWeightUnitArgs = {
  listingWeightUnit?: Maybe<ListingWeightUnit>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationReviewUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationScheduledShipmentPossibleDeliveryDatesArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartRemoveAllListedProductArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  listingId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionSetHtmlContentArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationKitProductAddKitItemArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogRemoveContentMediaArgs = {
  contentMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountSetEligibleUserTypeArgs = {
  eligibleUserType?: Maybe<UserType>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductCloneArgs = {
  price: Scalars['BigDecimal'];
  id: Scalars['UUID'];
  title: Scalars['String'];
  sku: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAutoprintTestWorkstationArgs = {
  machineKey: Scalars['String'];
};


/** Mutation root */
export type MutationPromotionAddExplicitProductsArgs = {
  explicitProductIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetTaxExemptArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartClearCarrierPreferenceArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetContactArgs = {
  phone?: Maybe<Scalars['String']>;
  cartId: Scalars['UUID'];
  email?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationTradeShowReportArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetMetaDescriptionArgs = {
  id: Scalars['UUID'];
  metaDescription?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationShipmentBatchGeneratePackingListArgs = {
  labelType?: Maybe<LabelType>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPetcoSdSalesOrderRefreshArgs = {
  alternateOrderNumber: Scalars['String'];
};


/** Mutation root */
export type MutationCartClearShippingAddressArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationListedProductSetDimensionsArgs = {
  width: Scalars['BigDecimal'];
  length: Scalars['BigDecimal'];
  id: Scalars['UUID'];
  height: Scalars['BigDecimal'];
};


/** Mutation root */
export type MutationCategorySetNameArgs = {
  name: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountGenerateCustomCouponCodesArgs = {
  count: Scalars['Long'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetPublishedRevisionArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationBlockSetSlugArgs = {
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationCartClearTradeshowArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationListedProductSetListingCommentsArgs = {
  comments?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSupplierSetOrderCycleArgs = {
  orderCycle: Scalars['Int'];
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationWarehouseAddQueuesArgs = {
  queueIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountSetDiscountTypeArgs = {
  discountType: DiscountType;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationInventoryReportGenerateArgs = {
  departmentId: Scalars['UUID'];
  days: Scalars['Long'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartSetPhoneArgs = {
  phone: Scalars['String'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetAffiliateArgs = {
  affiliateSlug: Scalars['String'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSimpleProductSetComponentItemPositionArgs = {
  position: Scalars['Int'];
  id: Scalars['UUID'];
  componentItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentUpdateAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  addressType?: Maybe<AddressType>;
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  id: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationBlockSetNameArgs = {
  name: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPurchaseRequestCreateArgs = {
  comments?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  team: Scalars['String'];
  items?: Maybe<Array<Maybe<PurchaseRequestItemInput>>>;
};


/** Mutation root */
export type MutationReviewUpdateArgs = {
  comments: Scalars['String'];
  rating: Scalars['Long'];
  mediaIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductAddAnimalArgs = {
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSummarizeArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationKitProductRemoveCrossSellProductArgs = {
  crossSellProductId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationInventoryGetHistoryArgs = {
  days: Scalars['Long'];
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetSlugArgs = {
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationChewySalesOrderRefreshArgs = {
  alternateOrderNumber: Scalars['String'];
};


/** Mutation root */
export type MutationInventorySetDetailsArgs = {
  quantity: Scalars['Long'];
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationCartSetAttributionTokenArgs = {
  attributionToken: Scalars['String'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationWarehouseWorkScheduleSetOpenCloseTimeArgs = {
  dayOfWeek: DayOfWeek;
  shipsScheduled: Scalars['Boolean'];
  closeTime: Scalars['LocalTime'];
  id: Scalars['UUID'];
  openTime: Scalars['LocalTime'];
};


/** Mutation root */
export type MutationCartAddKitProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationProductRequestBackInStockNotificationArgs = {
  phone?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  email?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationShipmentDeliverOfflineArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationWarehouseCreateQueueArgs = {
  template?: Maybe<Scalars['String']>;
  singlesOnly?: Maybe<Scalars['Boolean']>;
  shippingNeedsType?: Maybe<ShippingNeedsType>;
  combosOnly?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  zoneId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  priority: Scalars['Long'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductSetCostArgs = {
  cost?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetShippingRuleSetArgs = {
  productId: Scalars['UUID'];
  shippingRuleSetId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartClearBillingAddressArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationResellerCreateProfileArgs = {
  productId: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionCloneArgs = {
  name: Scalars['String'];
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartRemoveAllKitProductArgs = {
  productId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductClearShippingRuleSetArgs = {
  productId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetPickupWarehouseArgs = {
  warehouseId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetTaxExemptArgs = {
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDropshipShipmentCancelAndRefundArgs = {
  cancellationReason?: Maybe<CancellationReason>;
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountCloneArgs = {
  name: Scalars['String'];
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductReindexArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetMinimumOrderQuantityArgs = {
  minimumOrderQuantity?: Maybe<Scalars['Long']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCategorySetMetaDescriptionArgs = {
  id: Scalars['UUID'];
  metaDescription?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCartSetPhoneArgs = {
  phone: Scalars['String'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSalesOrderPartialReshipArgs = {
  lineItems: Array<Maybe<SalesOrderLineItemInput>>;
  serviceType?: Maybe<ServiceType>;
  reason?: Maybe<RefundReason>;
  carrierPreference?: Maybe<Carrier>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRefreshArgs = {
  magentoId: Scalars['String'];
};


/** Mutation root */
export type MutationCareSheetAddImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionRemoveExplicitProductsArgs = {
  explicitProductIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCareSheetSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetCarrierPreferenceArgs = {
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
  carrierPreference: Carrier;
};


/** Mutation root */
export type MutationPurchaseOrderReOpenArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductAddImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCareSheetSetMetaKeywordsArgs = {
  metaKeywords?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogCreateArgs = {
  title: Scalars['String'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationMaintenanceRecomputeShippingEstimatesArgs = {
  state: State;
};


/** Mutation root */
export type MutationCartAddSimpleProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAssistedSubscriptionSetServiceLevelArgs = {
  serviceLevel: ServiceType;
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionSetSlugArgs = {
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationPromotionDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedScheduledShipmentHoldForWeatherArgs = {
  date: Scalars['LocalDate'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetVisibleArgs = {
  visible: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserUpdatePhoneArgs = {
  phone: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetShippingAddressByIdArgs = {
  cartId?: Maybe<Scalars['UUID']>;
  addressId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderCancelArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountAddEligibleProductsArgs = {
  id: Scalars['UUID'];
  eligibleProductIds: Array<Maybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationSimpleProductSetCaseQuantityArgs = {
  caseQuantity?: Maybe<Scalars['Long']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetMetaTitleArgs = {
  metaTitle?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetServiceTypePreferenceArgs = {
  serviceType: ServiceType;
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductAddCrossSellProductsArgs = {
  productIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedScheduledShipmentClearApprovalArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPartnerUpdateAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  addressType?: Maybe<AddressType>;
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  id: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationBlogUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationPreppedProductsCalculateShipDatesArgs = {
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationSalesOrderAddGiftCardPaymentArgs = {
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
  giftCardCode?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationPurchaseRequestRemoveItemArgs = {
  itemId?: Maybe<Scalars['UUID']>;
  requestId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAssistedScheduledShipmentDashboardArgs = {
  salesOrderCls?: Maybe<GraphQlSingleValueFilter_StringInput>;
  warehouse?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationDropshipperProfileCreateArgs = {
  cost: Scalars['BigDecimal'];
  simpleProductId: Scalars['UUID'];
  dropshipperId: Scalars['UUID'];
  title: Scalars['String'];
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationSimpleProductSetWidthArgs = {
  width?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionCreateArgs = {
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  permitStacking: Scalars['Boolean'];
  promotionType: PromotionType;
  endDate?: Maybe<Scalars['Instant']>;
  eligibleItemCount?: Maybe<Scalars['Long']>;
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  eligibleCouponCode?: Maybe<Scalars['String']>;
  departmentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  eligibleItemValue?: Maybe<Scalars['BigDecimal']>;
  htmlContent?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  eligibleUserType?: Maybe<UserType>;
  name: Scalars['String'];
  startDate?: Maybe<Scalars['Instant']>;
  slug: Scalars['String'];
  teaser?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSimpleProductAddAnimalArgs = {
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDiscountSetTeaserArgs = {
  id: Scalars['UUID'];
  teaser?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationShipmentTrackArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetBillingAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  cartId?: Maybe<Scalars['UUID']>;
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetWholesalePriceArgs = {
  id: Scalars['UUID'];
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationListedProductRecomputeShippingEstimatesAsyncArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductAddWarehouseArgs = {
  productId: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductRefreshArgs = {
  magentoId: Scalars['String'];
};


/** Mutation root */
export type MutationDropshipShipmentShipMultiPieceArgs = {
  carrier: Carrier;
  service: Service;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  reseller: Reseller;
  packaging?: Maybe<Packaging>;
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
  packages: Array<Maybe<PackageSizeInput>>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationPreppedProductsAddPreparationLineItemArgs = {
  productId: Scalars['UUID'];
  shipmentItemId: Scalars['UUID'];
  shipDate?: Maybe<Scalars['LocalDate']>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationAssistedCartAddCustomProductArgs = {
  unitPrice: Scalars['BigDecimal'];
  quantity: Scalars['Long'];
  cartId: Scalars['UUID'];
  weight: Scalars['BigDecimal'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationShipmentGeneratePackingListZplArgs = {
  template?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentBatchCreateFromListArgs = {
  queueId: Scalars['UUID'];
  shipmentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  name?: Maybe<Scalars['String']>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetMetaKeywordsArgs = {
  metaKeywords?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetMetaDescriptionArgs = {
  id: Scalars['UUID'];
  metaDescription?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSimpleProductShippingEstimatesArgs = {
  productId: Scalars['UUID'];
  warehouseId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationListedProductSetListingTitleArgs = {
  id: Scalars['UUID'];
  title: Scalars['String'];
};


/** Mutation root */
export type MutationSupplierUpdateAddressArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  addressType?: Maybe<AddressType>;
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  id: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationConfigurableProductSetPublishedRevisionArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationBlogSetContentMediaSequenceArgs = {
  sequence: Scalars['Int'];
  contentMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserUpdateTypeArgs = {
  id: Scalars['UUID'];
  type: UserType;
};


/** Mutation root */
export type MutationAssistedCartChangeQuantityKitProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogRemoveBlogTopicArgs = {
  blogTopicId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationWarehouseSetEnabledArgs = {
  id: Scalars['UUID'];
  enabled: Scalars['Boolean'];
};


/** Mutation root */
export type MutationDropshipInventoryFilterArgs = {
  zone?: Maybe<GraphQlSingleValueFilter_StringInput>;
  inStock?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  partnerId: Scalars['UUID'];
  title?: Maybe<GraphQlLikeQueryFilterInput>;
  sku?: Maybe<GraphQlLikeQueryFilterInput>;
  warehouse: GraphQlSingleValueFilter_StringInput;
};


/** Mutation root */
export type MutationListedProductRemoveAnimalArgs = {
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartAddSimpleProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationWarehouseWorkScheduleRemoveHolidayArgs = {
  date: Scalars['LocalDate'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartSetCouponCodeArgs = {
  cartId: Scalars['UUID'];
  couponCode: Scalars['String'];
};


/** Mutation root */
export type MutationBlogSetAuthorArgs = {
  author?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationWarehouseRemoveCarriersArgs = {
  carriers: Array<Maybe<Carrier>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartGetEarliestDeliveryDateArgs = {
  quantity: Scalars['Long'];
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationScheduledShipmentScheduleByHashArgs = {
  date: Scalars['LocalDate'];
  hash: Scalars['String'];
};


/** Mutation root */
export type MutationDropshipShipmentVoidArgs = {
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductAddImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderPartialRefundArgs = {
  lineItems: Array<Maybe<SalesOrderLineItemInput>>;
  reason?: Maybe<RefundReason>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductReindexArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductCreateArgs = {
  cost?: Maybe<Scalars['BigDecimal']>;
  weight: Scalars['BigDecimal'];
  upc?: Maybe<Scalars['String']>;
  type: ListedProductType;
  title: Scalars['String'];
  sku: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationDiscountSetEndDateArgs = {
  endDate?: Maybe<Scalars['Instant']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductClearShippingRuleSetArgs = {
  productId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCareSheetUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationSimpleProductCloneArgs = {
  cost?: Maybe<Scalars['BigDecimal']>;
  price: Scalars['BigDecimal'];
  upc?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  title: Scalars['String'];
  sku: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  vendorRef?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationWarehouseRemoveQueuesArgs = {
  queueIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductRefreshBySkuArgs = {
  sku: Scalars['String'];
};


/** Mutation root */
export type MutationAddressUpdateArgs = {
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  id: Scalars['UUID'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
};


/** Mutation root */
export type MutationTeamCreateArgs = {
  name: Scalars['String'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationCategorySetMetaTitleArgs = {
  metaTitle?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationWarehouseCreateArgs = {
  country: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  name: Scalars['String'];
  carriers: Array<Maybe<Carrier>>;
  state: Scalars['String'];
  warehouseType: WarehouseType;
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductAddZoneArgs = {
  productId: Scalars['UUID'];
  zoneId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductAddPriceTierArgs = {
  quantity: Scalars['BigDecimal'];
  price: Scalars['BigDecimal'];
  userType?: Maybe<UserType>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductRemoveListingMediaArgs = {
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAutoprintListPrintersArgs = {
  machineKey: Scalars['String'];
};


/** Mutation root */
export type MutationSalesOrderAddCommentArgs = {
  comment: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductRemoveKitItemArgs = {
  kitItemId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCheckoutGetClientTokenArgs = {
  anonymous?: Maybe<Scalars['Boolean']>;
};


/** Mutation root */
export type MutationSalesOrderUpdatePaymentsArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPurchaseOrderEditItemQtyArgs = {
  quantity: Scalars['Long'];
  purchaseOrderId: Scalars['UUID'];
  purchaseOrderItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogSetPublishedRevisionArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationDepartmentCreateArgs = {
  name: Scalars['String'];
  slug: Scalars['String'];
  parentId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductRemoveListingArgs = {
  productId: Scalars['UUID'];
  listingId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartSetPickupWarehouseArgs = {
  warehouseId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSupplierSetNameArgs = {
  name: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationCartClearCouponCodeArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationDiscountCreateArgs = {
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  permitStacking: Scalars['Boolean'];
  endDate?: Maybe<Scalars['Instant']>;
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  eligibleCouponCode?: Maybe<Scalars['String']>;
  departmentIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  htmlContent?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  eligibleUserType?: Maybe<UserType>;
  name: Scalars['String'];
  discountType: DiscountType;
  startDate?: Maybe<Scalars['Instant']>;
  slug: Scalars['String'];
  teaser?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationCartCheckoutArgs = {
  method: Scalars['String'];
  subscribeToNewsletter?: Maybe<Scalars['Boolean']>;
  cartId: Scalars['UUID'];
  deviceData?: Maybe<Scalars['String']>;
  giftCardPin?: Maybe<Scalars['String']>;
  gaClientId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  giftCardCode?: Maybe<Scalars['String']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
  nonce?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationPartnerRemoveUserArgs = {
  partnerId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetWholesalePriceArgs = {
  id: Scalars['UUID'];
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationAffiliateSetAffiliateBiographyArgs = {
  affiliateBiography: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedRewardPointsProcessRewardsExpirationArgs = {
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationWarehouseAddZonesArgs = {
  zoneIds: Array<Maybe<Scalars['UUID']>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationResellerSetResellerTaxModeArgs = {
  resellerTaxMode: ResellerTaxMode;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingDimensionsArgs = {
  width?: Maybe<Scalars['BigDecimal']>;
  length?: Maybe<Scalars['BigDecimal']>;
  id: Scalars['UUID'];
  height?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationPartnerAddUserArgs = {
  partnerId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Mutation root */
export type MutationCartChangeQuantityKitProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationAssistedCartAddKitProductArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  cartId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductAddImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationDropshipShipmentDashboardArgs = {
  partnerId: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationMediaSetKeywordsArgs = {
  keywords?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetKitItemAlternateArgs = {
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  kitItemId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserRemoveRoleArgs = {
  role: RoleType;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionGenerateCustomCouponCodesArgs = {
  count: Scalars['Long'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductRemoveAnimalArgs = {
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductRemoveConfigurableItemArgs = {
  configurableItemId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductAddComponentItemArgs = {
  quantity: Scalars['BigDecimal'];
  productId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetPublishedRevisionArgs = {
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationShipmentRateMultiPieceArgs = {
  applyRestrictions?: Maybe<Scalars['Boolean']>;
  packaging?: Maybe<Packaging>;
  id: Scalars['UUID'];
  packages: Array<Maybe<PackageSizeInput>>;
  shipDate?: Maybe<Scalars['String']>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductRefreshArgs = {
  magentoId: Scalars['String'];
};


/** Mutation root */
export type MutationKitProductReindexArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedScheduledShipmentSetApprovalArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationBlogTopicDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAffiliateSetProductListSequenceArgs = {
  listId: Scalars['UUID'];
  sequence: Scalars['Int'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPurchaseOrderDeleteItemArgs = {
  purchaseOrderId: Scalars['UUID'];
  purchaseOrderItemId: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingMediaSequenceArgs = {
  sequence: Scalars['Int'];
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationResellerProfileCreateArgs = {
  resellerId: Scalars['UUID'];
  productId: Scalars['UUID'];
  price: Scalars['BigDecimal'];
  title: Scalars['String'];
  sku: Scalars['String'];
  retail?: Maybe<Scalars['BigDecimal']>;
};


/** Mutation root */
export type MutationSimpleProductUploadImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationPromotionListUnusedCustomCouponCodesArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationTradeShowUnassignTerminalArgs = {
  tradeshowId: Scalars['UUID'];
  terminalId: Scalars['UUID'];
};


/** Mutation root */
export type MutationWarehouseSetSlugArgs = {
  id: Scalars['UUID'];
  slug: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductSetPrepTypeArgs = {
  prepType?: Maybe<PrepType>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductAddConfigurableItemArgs = {
  productId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderAddItemArgs = {
  salesOrderId: Scalars['UUID'];
  quantity: Scalars['Long'];
  simpleProductId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSupplierClearPhoneArgs = {
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationCartChangeQuantityListedProductArgs = {
  quantity: Scalars['Long'];
  cartId?: Maybe<Scalars['UUID']>;
  listingId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAutoprintPrintAcknowledgeForPrinterArgs = {
  shipment: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedCartAddProductsArgs = {
  kitProductIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  listingIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  cartId: Scalars['UUID'];
  simpleProductIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};


/** Mutation root */
export type MutationKitProductSetMetaKeywordsArgs = {
  metaKeywords?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionSetEligibleItemCountArgs = {
  eligibleItemCount?: Maybe<Scalars['Long']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductConvertToKitArgs = {
  kitItems: Array<Maybe<KitItemInput>>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSalesOrderSetHoldArgs = {
  id: Scalars['UUID'];
  hold: Scalars['Boolean'];
};


/** Mutation root */
export type MutationSupplierSetPhoneArgs = {
  phone: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSimpleProductReindexArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductRemoveProductMediaArgs = {
  productMediaId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductAddZoneArgs = {
  productId: Scalars['UUID'];
  zoneId: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetDimensionsArgs = {
  width: Scalars['BigDecimal'];
  length: Scalars['BigDecimal'];
  id: Scalars['UUID'];
  height: Scalars['BigDecimal'];
};


/** Mutation root */
export type MutationWarehouseSetNameArgs = {
  name: Scalars['String'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetDiscontinuationReasonArgs = {
  reason: DiscontinuationReasonType;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationCareSheetSetMetaDescriptionArgs = {
  id: Scalars['UUID'];
  metaDescription?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationAssistedReviewRejectArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSimpleProductSetMetaKeywordsArgs = {
  metaKeywords?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingQuantityArgs = {
  quantity: Scalars['Long'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationKitProductClearKitItemAlternateArgs = {
  kitItemId: Scalars['UUID'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetListingSexArgs = {
  sex?: Maybe<SexType>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetActiveArgs = {
  active: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserUpdateNetTermsArgs = {
  netTerms: Scalars['Int'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationAddressDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationUserCreateArgs = {
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationCartSetShippingAddressFromBillingAddressArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Mutation root */
export type MutationSimpleProductSetCaseDimensionsArgs = {
  caseLength: Scalars['BigDecimal'];
  productId: Scalars['UUID'];
  caseHeight: Scalars['BigDecimal'];
  caseWidth: Scalars['BigDecimal'];
};


/** Mutation root */
export type MutationAssistedCartSetEmailArgs = {
  cartId: Scalars['UUID'];
  email: Scalars['String'];
};


/** Mutation root */
export type MutationTradeShowSetPreOrderDatesArgs = {
  preOrderStartDate?: Maybe<Scalars['LocalDate']>;
  preOrderEndDate?: Maybe<Scalars['LocalDate']>;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationMakingStockStatusArgs = {
  requiresPrep?: Maybe<Scalars['Boolean']>;
  departmentId?: Maybe<Scalars['UUID']>;
  active?: Maybe<Scalars['Boolean']>;
  warehouse: Scalars['String'];
};


/** Mutation root */
export type MutationListedProductUploadListingImageArgs = {
  dataUrl: Scalars['String'];
  keyWords?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationProductRemoveCategoryArgs = {
  productId: Scalars['UUID'];
  categoryId: Scalars['UUID'];
};


/** Mutation root */
export type MutationAssistedPaymentMethodListArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationApplicationCreateArgs = {
  redirectUri: Scalars['String'];
  clientId: Scalars['String'];
  name: Scalars['String'];
};


/** Mutation root */
export type MutationMaintenancePoyntSyncTransactionArgs = {
  orderNumber?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
};


/** Mutation root */
export type MutationUserAddRoleArgs = {
  role: RoleType;
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationSupplierDeleteArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionAddBrandsArgs = {
  id: Scalars['UUID'];
  brandIds: Array<Maybe<Scalars['UUID']>>;
};


/** Mutation root */
export type MutationConfigurableProductAddImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationPromotionSetPermitStackingArgs = {
  permitStacking: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductSetFeaturedArgs = {
  featured: Scalars['Boolean'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationShipmentVoidArgs = {
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationConfigurableProductSetConfigurableItemPositionArgs = {
  configurableItemId: Scalars['UUID'];
  position: Scalars['Int'];
  id: Scalars['UUID'];
};


/** Mutation root */
export type MutationListedProductAddListingImageArgs = {
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
};


/** Mutation root */
export type MutationMaintenanceFixInventoryIssueArgs = {
  productId?: Maybe<Scalars['UUID']>;
};

export type OpenCloseTimeEntity = {
  __typename?: 'OpenCloseTimeEntity';
  closeTime?: Maybe<Scalars['LocalTime']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  dayOfWeek?: Maybe<DayOfWeek>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  openTime?: Maybe<Scalars['LocalTime']>;
  shipsScheduled?: Maybe<Scalars['Boolean']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type PartnerEntity = {
  __typename?: 'PartnerEntity';
  /** Partner's address type */
  addressType?: Maybe<AddressType>;
  /** Partner's validation source */
  addressValidationSource?: Maybe<AddressValidationSource>;
  /** Partner's affiliate flag */
  affiliate?: Maybe<Scalars['Boolean']>;
  /** Affiliate's attribution period */
  affiliateAttributionPeriod?: Maybe<Scalars['Long']>;
  /** Partner's attribution token */
  affiliateAttributionToken?: Maybe<Scalars['String']>;
  /** Affiliate's biography */
  affiliateBiography?: Maybe<Scalars['String']>;
  /** Affiliate's commission */
  affiliateCommissionRate?: Maybe<Scalars['BigDecimal']>;
  /** Affiliate's product list */
  affiliateProductLists?: Maybe<Array<Maybe<AffiliateProductListEntity>>>;
  /** Partner's city name */
  city?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Partner's company */
  company?: Maybe<Scalars['String']>;
  /** Partner's ISO country code */
  country?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Partner's dropshipper flag */
  dropshipper?: Maybe<Scalars['Boolean']>;
  /** Partner's email */
  email?: Maybe<Scalars['String']>;
  /** Partner's first name */
  firstName?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Partner's last name */
  lastName?: Maybe<Scalars['String']>;
  /** Partner's latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Partner's first line */
  line1?: Maybe<Scalars['String']>;
  /** Partner's last line */
  line2?: Maybe<Scalars['String']>;
  /** Partner's longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Partner's name */
  name?: Maybe<Scalars['String']>;
  /** Partner's postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Partner's report emails */
  reportEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Partner's reseller flag */
  reseller?: Maybe<Scalars['Boolean']>;
  /** Reseller's shipping rate mode */
  resellerShippingRateMode?: Maybe<ResellerShippingRateMode>;
  /** Reseller's tax mode */
  resellerTaxMode?: Maybe<ResellerTaxMode>;
  /** Partner's residential status */
  residential?: Maybe<Scalars['Boolean']>;
  /** Dropshipper's shipping software */
  shippingSoftware?: Maybe<ShippingSoftware>;
  /** Affiliate's slug */
  slug?: Maybe<Scalars['String']>;
  /** Partner's state (or province) abbreviation */
  state?: Maybe<Scalars['String']>;
  /** Dropshipper's survey frequency */
  surveyFrequency?: Maybe<Scalars['BigDecimal']>;
  /** Dropshipper's survey url */
  surveyUrl?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Partner's enrolled users */
  users?: Maybe<Array<Maybe<UserEntity>>>;
};

export type PaymentEntity = {
  __typename?: 'PaymentEntity';
  /** Payment's amount */
  amount?: Maybe<Scalars['BigDecimal']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Payment's description */
  description?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Payment's status */
  paymentStatus?: Maybe<PaymentStatus>;
  /** Payment's placed timestamp */
  placedAt?: Maybe<Scalars['Instant']>;
  /** Payment's sales order */
  salesOrder?: Maybe<SalesOrderEntity>;
  /** Payment's transaction id */
  transactionId?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type PaymentMethodDetail = {
  __typename?: 'PaymentMethodDetail';
  /** Payment method comment */
  comment?: Maybe<Scalars['String']>;
  /** Payment method's default method flag */
  defaultMethod?: Maybe<Scalars['Boolean']>;
  /** Payment method's image */
  image?: Maybe<Scalars['String']>;
  /** Payment method token */
  token?: Maybe<Scalars['String']>;
  /** Payment method type */
  type?: Maybe<Scalars['String']>;
};

export type PaymentTermsAccountEntity = {
  __typename?: 'PaymentTermsAccountEntity';
  /** Payment terms account's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Payment term account's address type */
  addressType?: Maybe<AddressType>;
  balance?: Maybe<Scalars['BigDecimal']>;
  /** Payment term account's city */
  city?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Payment term account's company */
  company?: Maybe<Scalars['String']>;
  /** Payment term account's country */
  country?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Payment terms account's email */
  email?: Maybe<Scalars['String']>;
  /** Payment term account's first name */
  firstName?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Payment term account's last name */
  lastName?: Maybe<Scalars['String']>;
  /** Payment term account's line 1 */
  line1?: Maybe<Scalars['String']>;
  /** Payment term account's line 2 */
  line2?: Maybe<Scalars['String']>;
  /** Payment terms account's NET terms */
  netTerms?: Maybe<Scalars['Int']>;
  pastDueBalance?: Maybe<Scalars['BigDecimal']>;
  /** Payment terms account's phone */
  phone?: Maybe<Scalars['String']>;
  /** Payment term account's postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Payment term account's residential flag */
  residential?: Maybe<Scalars['Boolean']>;
  /** Payment term account's state */
  state?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type PdfContents = {
  __typename?: 'PdfContents';
  /** PDF's data */
  data?: Maybe<Scalars['String']>;
  /** PDF's filename */
  fileName?: Maybe<Scalars['String']>;
  /** PDF's HTML content */
  htmlContent?: Maybe<Scalars['String']>;
};

export type PetSuppliesPlusProfileEntity = {
  __typename?: 'PetSuppliesPlusProfileEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** PSP store direct profile's description */
  description?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** PSP store direct profile's sku */
  sku?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type PetcoDropshipProfileEntity = {
  __typename?: 'PetcoDropshipProfileEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Petco dropship profile's description */
  description?: Maybe<Scalars['String']>;
  /** Petco dropship profile's enabled flag */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Petco dropship profile's sku */
  sku?: Maybe<Scalars['String']>;
  /** Petco dropship profile's surcharge */
  surcharge?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type PetcoDropshipSalesOrderEntity = {
  __typename?: 'PetcoDropshipSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' type */
  billingAddressType?: Maybe<AddressType>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Sales order's fulfillment network */
  fulfillmentNetwork?: Maybe<FulfillmentNetworkType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payment terms account */
  paymentTermsAccount?: Maybe<PaymentTermsAccountEntity>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Sales order's listed items */
  salesOrderListedItems?: Maybe<Array<Maybe<SalesOrderListedItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' type */
  shippingAddressType?: Maybe<AddressType>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type PetcoStoreDirectProfileEntity = {
  __typename?: 'PetcoStoreDirectProfileEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Petco store direct profile's description */
  description?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Petco store direct profile's sku */
  sku?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type PetcoStoreDirectSalesOrderEntity = {
  __typename?: 'PetcoStoreDirectSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' type */
  billingAddressType?: Maybe<AddressType>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Sales order's fulfillment network */
  fulfillmentNetwork?: Maybe<FulfillmentNetworkType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payment terms account */
  paymentTermsAccount?: Maybe<PaymentTermsAccountEntity>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Sales order's listed items */
  salesOrderListedItems?: Maybe<Array<Maybe<SalesOrderListedItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' type */
  shippingAddressType?: Maybe<AddressType>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type PosTerminalEntity = {
  __typename?: 'PosTerminalEntity';
  /** POS terminal's asset tag */
  assetTag?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  tradeShowEntities?: Maybe<Array<Maybe<TradeShowEntity>>>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** POS terminal's urn */
  urn?: Maybe<Scalars['String']>;
};

export type PreparationEntity = {
  __typename?: 'PreparationEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Preparation's preparer */
  preparer?: Maybe<Scalars['String']>;
  /** Preparation's product */
  product?: Maybe<ProductEntity>;
  /** Preparation's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Preparation's ship date */
  shipDate?: Maybe<Scalars['LocalDate']>;
  /** Preparation's shipment item */
  shipmentItem?: Maybe<ShipmentItemEntity>;
  /** Preparation's shipment listed item */
  shipmentListedItem?: Maybe<ShipmentListedItemEntity>;
  /** Preparation's simple product */
  simpleProduct?: Maybe<ProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Preparation's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
};

export type PreparationShipDates = {
  __typename?: 'PreparationShipDates';
  /** Preparation tool's current ship date */
  currentShipDate?: Maybe<Scalars['LocalDate']>;
  /** Preparation tool's possible ship dates */
  possibleShipDates?: Maybe<Array<Maybe<Scalars['LocalDate']>>>;
};

export type PreparationSummary = {
  __typename?: 'PreparationSummary';
  /** Preparation summary's listed product */
  listedProduct?: Maybe<ListedProductEntity>;
  /** Preparation summary's quantity needed */
  quantityNeeded?: Maybe<Scalars['Long']>;
  /** Preparation summary's quantity ordered */
  quantityOrdered?: Maybe<Scalars['Long']>;
  /** Preparation summary's quantity prepped */
  quantityPrepped?: Maybe<Scalars['Long']>;
  /** Preparation summary's quantity shipped */
  quantityShipped?: Maybe<Scalars['Long']>;
  /** Preparation summary's shipment items */
  shipmentItems?: Maybe<Array<Maybe<ShipmentItemEntity>>>;
  /** Preparation summary's shipment listed items */
  shipmentListedItems?: Maybe<Array<Maybe<ShipmentListedItemEntity>>>;
  /** Preparation summary's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Preparation summary's preparations */
  todaysPreparations?: Maybe<Array<Maybe<PreparationEntity>>>;
};

export type PriceTierEntity = {
  __typename?: 'PriceTierEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Tiered price's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Tiered price's quantity */
  quantity?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Tiered price's user type */
  userType?: Maybe<UserType>;
};

export type PrintJob = {
  __typename?: 'PrintJob';
  /** Print job's data */
  dataBase64?: Maybe<Scalars['String']>;
  /** Print job's name */
  name?: Maybe<Scalars['String']>;
  /** Print job's printer */
  printer?: Maybe<Scalars['String']>;
  /** Print job's rotation */
  rotate?: Maybe<Scalars['Boolean']>;
  /** Print job's tray */
  tray?: Maybe<Scalars['String']>;
};

export type PrinterEntity = {
  __typename?: 'PrinterEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Printer's name */
  name?: Maybe<Scalars['String']>;
  /** Printer's rotation */
  rotate?: Maybe<Scalars['Boolean']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ProductEntity = {
  __typename?: 'ProductEntity';
  /** Product's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Product's Amazon profile */
  amazonProfile?: Maybe<AmazonProfileEntity>;
  /** Product's average rating */
  averageRating?: Maybe<Scalars['Float']>;
  /** Product's blogs */
  blogs?: Maybe<Array<Maybe<BlogEntity>>>;
  /** Simple product's brand */
  brand?: Maybe<BrandEntity>;
  /** Product's care sheets */
  careSheets?: Maybe<Array<Maybe<CareSheetEntity>>>;
  /** Product's Chewy profile */
  chewyProfile?: Maybe<ChewyProfileEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Product's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Product's number of ratings */
  countRating?: Maybe<Scalars['Long']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Product's cross sell items */
  crossSellItems?: Maybe<Array<Maybe<CrossSellItemEntity>>>;
  crossSells?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Product's dropshipped flag */
  dropshipped?: Maybe<Scalars['Boolean']>;
  /** Product's dropship partner */
  dropshipper?: Maybe<PartnerEntity>;
  /** Product's explicit animals */
  explicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's explicit categories */
  explicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's explicit department */
  explicitDepartment?: Maybe<DepartmentEntity>;
  /** Product's explicit discounts */
  explicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's explicit promotions */
  explicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's featured status */
  featured?: Maybe<Scalars['Boolean']>;
  /** Product's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Product's implicit animals */
  implicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's implicit categories */
  implicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's implicit departments */
  implicitDepartments?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Product's implicit discounts */
  implicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's implicit promotions */
  implicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's important status */
  important?: Maybe<Scalars['Boolean']>;
  /** Product's live flag */
  live?: Maybe<Scalars['Boolean']>;
  /** Product's Magento Id */
  magentoId?: Maybe<Scalars['String']>;
  /** Product's manufacture time */
  manufactureTime?: Maybe<Scalars['BigDecimal']>;
  /** Product's medias */
  medias?: Maybe<Array<Maybe<ProductMediaEntity>>>;
  /** Product's meta description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Product's meta keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Product's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Product's Pet Supplies Plus profile */
  petSuppliesPlusProfile?: Maybe<PetSuppliesPlusProfileEntity>;
  /** Product's Petco dropship profile */
  petcoDropshipProfile?: Maybe<PetcoDropshipProfileEntity>;
  /** Product's Petco store direct profile */
  petcoStoreDirectProfile?: Maybe<PetcoStoreDirectProfileEntity>;
  /** Product's popularity */
  popularity?: Maybe<Scalars['BigDecimal']>;
  /** Product's prep type */
  prepType?: Maybe<PrepType>;
  /** Product's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Product's price tiers */
  priceTiers?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's published revision */
  publishedRevision?: Maybe<ProductRevisionEntity>;
  /** Product's quantity available for sale */
  quantityAvailableForSale?: Maybe<Scalars['Long']>;
  /** Product's quantity available for sale on speciality markets */
  quantityAvailableForSpeciality?: Maybe<Scalars['Long']>;
  /** Product's reseller profiles */
  resellerProfiles?: Maybe<Array<Maybe<ResellerProfileEntity>>>;
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's shipping estimates */
  shippingEstimates?: Maybe<Array<Maybe<ShippingEstimateEntity>>>;
  /** Product's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Product's shipping rule set */
  shippingRuleSet?: Maybe<ShippingRuleSetEntity>;
  /** Product's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Product's slug */
  slug?: Maybe<Scalars['String']>;
  /** Product's special price */
  specialPrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's subscription status */
  subscription?: Maybe<Scalars['Boolean']>;
  tieredPrices?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's title */
  title?: Maybe<Scalars['String']>;
  /** Product's UPC */
  upc?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Product's upsells */
  upsellProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  userReview?: Maybe<ReviewEntity>;
  /** Product's variation key */
  variationKey?: Maybe<Scalars['String']>;
  /** Product's variation set */
  variationSet?: Maybe<VariationSetEntity>;
  /** Product's visible flag */
  visible?: Maybe<Scalars['Boolean']>;
  /** Product's Walmart profile */
  walmartProfile?: Maybe<WalmartProfileEntity>;
  /** Product's wholesale price */
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
};

export type ProductMediaEntity = {
  __typename?: 'ProductMediaEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Product media's media */
  media?: Maybe<MediaEntity>;
  /** Product media's type */
  mediaType?: Maybe<MediaType>;
  /** Product media's product */
  product?: Maybe<ProductEntity>;
  /** Product media's sequence */
  sequence?: Maybe<Scalars['Int']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Product media's url */
  url?: Maybe<Scalars['String']>;
};

export type ProductRevisionEntity = {
  __typename?: 'ProductRevisionEntity';
  /** Product revision's html content */
  htmlContent?: Maybe<Scalars['String']>;
};

export type ProfitabilitySummary = {
  __typename?: 'ProfitabilitySummary';
  /** Summary's average cost */
  averageCost?: Maybe<Scalars['BigDecimal']>;
  /** Summary's average price */
  averagePrice?: Maybe<Scalars['BigDecimal']>;
  /** Summary's gross margin */
  grossMargin?: Maybe<Scalars['Float']>;
  /** Summary's product */
  product?: Maybe<SimpleProductEntity>;
  /** Summary's profit */
  profit?: Maybe<Scalars['BigDecimal']>;
  /** Summary's number sold */
  sold?: Maybe<Scalars['Long']>;
  /** Summary's stock out */
  stockOut?: Maybe<Scalars['Float']>;
  /** Summary's velocity */
  velocity?: Maybe<Scalars['Float']>;
};

export type PromotionEntity = {
  __typename?: 'PromotionEntity';
  /** Promotion's active status */
  active?: Maybe<Scalars['Boolean']>;
  /** Promotion's brands */
  brands?: Maybe<Array<Maybe<BrandEntity>>>;
  /** Promotion's categories */
  categories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Promotion's departments */
  departments?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Promotion's eligible discount amount */
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  /** Promotion's eligible cart value */
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  /** Promotion's eligible coupon code */
  eligibleCouponCode?: Maybe<Scalars['String']>;
  /** Promotion's eligible item count */
  eligibleItemCount?: Maybe<Scalars['Long']>;
  /** Promotion's eligible item value */
  eligibleItemValue?: Maybe<Scalars['BigDecimal']>;
  /** Promotion's eligible user type */
  eligibleUserType?: Maybe<UserType>;
  /** Promotion's end date */
  endDate?: Maybe<Scalars['Instant']>;
  /** Promotion's explicit products */
  explicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Promotion's html content */
  htmlContent?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Promotion's implicit products */
  implicitProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Promotion's name */
  name?: Maybe<Scalars['String']>;
  /** Promotion's permit stacking status */
  permitStacking?: Maybe<Scalars['Boolean']>;
  /** Promotion's type */
  promotionType?: Maybe<PromotionType>;
  /** Promotion's slug */
  slug?: Maybe<Scalars['String']>;
  /** Promotion's start date */
  startDate?: Maybe<Scalars['Instant']>;
  /** Promotion's teaser */
  teaser?: Maybe<Scalars['String']>;
  unusedCustomCouponCodeCount?: Maybe<Scalars['Int']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type PspCorporateSalesOrderEntity = {
  __typename?: 'PspCorporateSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' type */
  billingAddressType?: Maybe<AddressType>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Sales order's fulfillment network */
  fulfillmentNetwork?: Maybe<FulfillmentNetworkType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payment terms account */
  paymentTermsAccount?: Maybe<PaymentTermsAccountEntity>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Sales order's listed items */
  salesOrderListedItems?: Maybe<Array<Maybe<SalesOrderListedItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' type */
  shippingAddressType?: Maybe<AddressType>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type PspFranchiseSalesOrderEntity = {
  __typename?: 'PspFranchiseSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' type */
  billingAddressType?: Maybe<AddressType>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Sales order's fulfillment network */
  fulfillmentNetwork?: Maybe<FulfillmentNetworkType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payment terms account */
  paymentTermsAccount?: Maybe<PaymentTermsAccountEntity>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Sales order's listed items */
  salesOrderListedItems?: Maybe<Array<Maybe<SalesOrderListedItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' type */
  shippingAddressType?: Maybe<AddressType>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type PurchaseOrderEntity = {
  __typename?: 'PurchaseOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's expected time */
  expectedTime?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's items */
  purchaseOrderItems?: Maybe<Array<Maybe<PurchaseOrderItemEntity>>>;
  /** Order's status */
  status?: Maybe<PurchaseOrderStatus>;
  /** Order's supplier */
  supplier?: Maybe<SupplierEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
};

export type PurchaseOrderItemEntity = {
  __typename?: 'PurchaseOrderItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Order's item's name */
  name?: Maybe<Scalars['String']>;
  /** Order's item's order */
  purchaseOrder?: Maybe<PurchaseOrderEntity>;
  /** Order's quantity received */
  received?: Maybe<Scalars['Long']>;
  /** Order's items simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Order's item's sku */
  sku?: Maybe<Scalars['String']>;
  /** Order's quantity total */
  total?: Maybe<Scalars['Long']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's item's vendor reference */
  vendorRef?: Maybe<Scalars['String']>;
};

export type PurchaseRequestEntity = {
  __typename?: 'PurchaseRequestEntity';
  /** Request's approver */
  approver?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Request's comments */
  comments?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Request's estimated amount */
  estimatedAmount?: Maybe<Scalars['BigDecimal']>;
  /** Request's final amount */
  finalAmount?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Request's ordered date */
  orderedDate?: Maybe<Scalars['LocalDate']>;
  /** Request's orderer */
  orderer?: Maybe<Scalars['String']>;
  /** Request's items */
  purchaseRequestItems?: Maybe<Array<Maybe<PurchaseRequestItemEntity>>>;
  /** Request's status */
  purchaseRequestStatus?: Maybe<PurchaseRequestStatus>;
  /** Request's received date */
  receivedDate?: Maybe<Scalars['LocalDate']>;
  /** Request's receiver */
  receiver?: Maybe<Scalars['String']>;
  /** Request's number */
  requestNumber?: Maybe<Scalars['String']>;
  /** Request's requested date */
  requestedDate?: Maybe<Scalars['LocalDate']>;
  /** Request's requester */
  requester?: Maybe<Scalars['String']>;
  /** Request's supplier */
  supplier?: Maybe<Scalars['String']>;
  /** Request's team */
  team?: Maybe<TeamEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type PurchaseRequestItemEntity = {
  __typename?: 'PurchaseRequestItemEntity';
  /** Requests item's amount */
  amount?: Maybe<Scalars['BigDecimal']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Requests item's name */
  name?: Maybe<Scalars['String']>;
  /** Requests item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

/** Query root */
export type Query = {
  __typename?: 'Query';
  /** Filter dropship profiles */
  dropshipDropshipperProfileFilter?: Maybe<GraphQlPage_DropshipperProfileEntity>;
  /** Provides user info a user */
  userInfo?: Maybe<UserEntity>;
  /** Locates a discount by slug */
  discountBySlug?: Maybe<DiscountEntity>;
  /** Provides queue info */
  queueList?: Maybe<Array<Maybe<QueueEntity>>>;
  /** Creates transient shipments for sales order items, then rolls back */
  salesOrderComputeBestShipping?: Maybe<SalesOrderEntity>;
  /** Filters care sheets */
  careSheetFilter?: Maybe<GraphQlPage_CareSheetEntity>;
  /** Filters payment terms accounts */
  assistedPaymentAccountFilter?: Maybe<GraphQlPage_PaymentTermsAccountEntity>;
  /** Deletes and recomputes shipments for a sales order */
  salesOrderNukeAndComputeBestShipping?: Maybe<SalesOrderEntity>;
  /** Generate a PDF for the sales order */
  salesOrderGeneratePdf?: Maybe<PdfContents>;
  /** Returns the user's reward points balance */
  rewardPointsBalance?: Maybe<Scalars['Long']>;
  /** Locates the root department */
  departmentRoot?: Maybe<DepartmentEntity>;
  /** Retrieves product reviews */
  reviewListForProduct?: Maybe<ReviewResults>;
  /** Filters implicit products for a discount */
  discountFilterImplicitProducts?: Maybe<GraphQlPage_ProductEntity>;
  /** Fetches brand info */
  supplierInfo?: Maybe<SupplierEntity>;
  /** Lists listed products */
  listedProductList?: Maybe<GraphQlPage_ListedProductEntity>;
  /** Filters blogs */
  blogFilter?: Maybe<GraphQlPage_BlogEntity>;
  /** Filters implicit products for a promotion */
  promotionFilterImplicitProducts?: Maybe<GraphQlPage_ProductEntity>;
  /** Locates a product by slug */
  productBySlug?: Maybe<ProductEntity>;
  /** Lists kit products */
  kitProductList?: Maybe<GraphQlPage_KitProductEntity>;
  /** Locates a product list by id */
  affiliateGetProductListById?: Maybe<AffiliateProductListEntity>;
  /** Lists teams */
  teamList?: Maybe<GraphQlPage_TeamEntity>;
  /** Lists suppliers */
  supplierList?: Maybe<GraphQlPage_SupplierEntity>;
  /** Gets a journal entry for PSP franchises */
  financialPspFranchiseRevenueJournal?: Maybe<JournalEntry>;
  /** Locates a block by id */
  blockInfo?: Maybe<BlockEntity>;
  /** Search products */
  productSearch?: Maybe<SearchResults>;
  /** Calculates a secure hash */
  salesOrderSecureHash?: Maybe<Scalars['String']>;
  /** Filters products */
  productFilter?: Maybe<GraphQlPage_ProductEntity>;
  /** Filters discounts */
  discountFilter?: Maybe<GraphQlPage_DiscountEntity>;
  /** Locates a partner by slug */
  affiliateBySlug?: Maybe<PartnerEntity>;
  /** Provides geolocation info for an IP address */
  geolocationByIpAddress?: Maybe<GeolocationType>;
  /** Locates a configurable product by id */
  configurableProductInfo?: Maybe<ConfigurableProductEntity>;
  /** Locates a department by slug */
  departmentBySlug?: Maybe<DepartmentEntity>;
  /** Calculates a secure hash */
  userSecureHash?: Maybe<Scalars['String']>;
  /** Filters simple products */
  simpleProductFilter?: Maybe<GraphQlPage_SimpleProductEntity>;
  shelfLabelsGeneratePdf?: Maybe<Scalars['String']>;
  /** Locates a product by id */
  productInfo?: Maybe<ProductEntity>;
  /** Locates a discount by id */
  discountInfo?: Maybe<DiscountEntity>;
  /** Filters users */
  userFilter?: Maybe<GraphQlPage_UserEntity>;
  /** Filter shipments */
  shipmentFilter?: Maybe<GraphQlPage_ShipmentEntity>;
  /** Locates a media by id */
  mediaInfo?: Maybe<MediaEntity>;
  /** Filters sales orders */
  salesOrderFilter?: Maybe<GraphQlPage_SalesOrderEntity>;
  /** Lists products */
  productList?: Maybe<GraphQlPage_ProductEntity>;
  /** Locates a kit product by slug */
  kitProductBySlug?: Maybe<KitProductEntity>;
  /** Locate a review by id */
  assistedReviewInfo?: Maybe<ReviewEntity>;
  /** Lists upcoming tradeshows */
  tradeShowListUpcoming?: Maybe<Array<Maybe<TradeShowEntity>>>;
  /** Locates a simple product by slug */
  simpleProductBySlug?: Maybe<SimpleProductEntity>;
  /** Locates a simple product by bin */
  simpleProductFindByBin?: Maybe<SimpleProductEntity>;
  /** Filters subscriptions */
  assistedSubscriptionFilter?: Maybe<GraphQlPage_SubscriptionEntity>;
  /** Returns a purchase request by id */
  purchaseRequestInfo?: Maybe<PurchaseRequestEntity>;
  /** Retrieves a reship sales order */
  reshipSalesOrderInfo?: Maybe<ReshipSalesOrderEntity>;
  /** Lists warehouses */
  dropshipWarehouseList?: Maybe<Array<Maybe<WarehouseEntity>>>;
  /** Lists departments */
  departmentList?: Maybe<GraphQlPage_DepartmentEntity>;
  /** Returns the gift card's balance */
  giftCardBalance?: Maybe<Scalars['BigDecimal']>;
  /** Filters promotions */
  promotionFilter?: Maybe<GraphQlPage_PromotionEntity>;
  /** Locates a team by slug */
  teamBySlug?: Maybe<TeamEntity>;
  /** Provides user info by email */
  userFindByEmail?: Maybe<UserEntity>;
  /** Test labels as ZPL */
  dropshipTestLabels?: Maybe<Array<Maybe<SignedZplLabel>>>;
  /** Locates a blog by slug */
  blogBySlug?: Maybe<BlogEntity>;
  /** Lists prepped products */
  preppedProductsList?: Maybe<Array<Maybe<PreparationSummary>>>;
  /** Lists dropshipper partners */
  partnerListDropshippers?: Maybe<Array<Maybe<PartnerEntity>>>;
  /** Locates a configurable product by slug */
  configurableProductBySlug?: Maybe<ConfigurableProductEntity>;
  /** Lists address for current user */
  addressList?: Maybe<Array<Maybe<AddressEntity>>>;
  /** Retrieves a subscription */
  subscriptionInfo?: Maybe<SubscriptionEntity>;
  /** Searches for a shipment */
  dropshipShipmentSearch?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Locates a category by path */
  categoryByPath?: Maybe<CategoryResults>;
  /** Queries a preparation summary for a product */
  preppedProductsInfo?: Maybe<PreparationSummary>;
  /** Lists configurable products */
  configurableProductList?: Maybe<GraphQlPage_ConfigurableProductEntity>;
  /** Locates a simple product by id */
  simpleProductInfo?: Maybe<SimpleProductEntity>;
  /** Filter shipments */
  assistedScheduledShipmentFilter?: Maybe<GraphQlPage_ShipmentEntity>;
  /** Locates an listed product by id */
  listedProductInfo?: Maybe<ListedProductEntity>;
  /** Filters configurable products */
  configurableProductFilter?: Maybe<GraphQlPage_ConfigurableProductEntity>;
  /** Lists shipment batches by warehouse */
  shipmentBatchList?: Maybe<GraphQlPage_ShipmentBatchEntity>;
  /** Lists subscriptions */
  subscriptionList?: Maybe<GraphQlPage_SubscriptionEntity>;
  /** Filters media */
  mediaFilter?: Maybe<GraphQlPage_MediaEntity>;
  /** Locates a simple product by UPC */
  simpleProductFindByUpc?: Maybe<SimpleProductEntity>;
  /** Locates the root category */
  categoryRoot?: Maybe<CategoryEntity>;
  /** Filters kit products */
  kitProductFilter?: Maybe<GraphQlPage_KitProductEntity>;
  /** Lists simple products */
  simpleProductList?: Maybe<GraphQlPage_SimpleProductEntity>;
  /** Locates a promotion by id */
  promotionInfo?: Maybe<PromotionEntity>;
  /** Lists warehouse zones */
  dropshipZoneList?: Maybe<Array<Maybe<ZoneEntity>>>;
  /** Provides geolocation info for current user */
  geolocationSelf?: Maybe<GeolocationType>;
  /** Locates a simple product by SKU */
  simpleProductFindBySku?: Maybe<SimpleProductEntity>;
  /** Lists simples product by sku list */
  productListBySkus?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Returns the user's rewards account */
  assistedRewardPointsGetRewardsAccount?: Maybe<RewardsAccountEntity>;
  /** Search blogs */
  blogSearch?: Maybe<GraphQlPage_BlogEntity>;
  /** Gets a journal entry for the reseller */
  financialResellerRevenueJournal?: Maybe<JournalEntry>;
  /** Lists warehouses */
  dropshipWarehouseInfo?: Maybe<WarehouseEntity>;
  /** Lists promotions */
  promotionList?: Maybe<GraphQlPage_PromotionEntity>;
  /** Filter shipments */
  dropshipShipmentFilter?: Maybe<GraphQlPage_ShipmentEntity>;
  /** Filters tradeshows */
  tradeShowFilter?: Maybe<GraphQlPage_TradeShowEntity>;
  simpleProductProfitabilityReport?: Maybe<GraphQlPage_ProfitabilitySummary>;
  /** Lists animals */
  animalList?: Maybe<GraphQlPage_AnimalEntity>;
  /** Locates a partner by id */
  partnerInfo?: Maybe<PartnerEntity>;
  /** Gets health of Rabbit MQ */
  healthRabbit?: Maybe<Scalars['Int']>;
  /** Lists all blog topics */
  blogTopicList?: Maybe<Array<Maybe<BlogTopicEntity>>>;
  /** Lists shipment batches */
  dropshipShipmentBatchList?: Maybe<Array<Maybe<ShipmentBatchEntity>>>;
  /** Lists partners which user has access to */
  partnerList?: Maybe<Array<Maybe<PartnerEntity>>>;
  /** Filters partners */
  partnerFilter?: Maybe<GraphQlPage_PartnerEntity>;
  /** Filters blocks */
  blockFilter?: Maybe<GraphQlPage_BlockEntity>;
  /** Filters purchase orders */
  purchaseOrderFilter?: Maybe<GraphQlPage_PurchaseOrderEntity>;
  /** Calculates a secure hash */
  shipmentSecureHash?: Maybe<Scalars['String']>;
  /** Search categories */
  categorySearch?: Maybe<GraphQlPage_CategoryEntity>;
  /** Get a shipment batch by ID */
  shipmentBatchInfo?: Maybe<ShipmentBatchEntity>;
  /** Filters purchase requests */
  purchaseRequestFilter?: Maybe<GraphQlPage_PurchaseRequestEntity>;
  scheduledShipmentHoldLocations?: Maybe<Array<Maybe<HoldLocation>>>;
  /** Provides warehouse info */
  warehouseFindByName?: Maybe<WarehouseEntity>;
  /** Lists reseller partners */
  partnerListResellers?: Maybe<Array<Maybe<PartnerEntity>>>;
  /** Locates a promotion by slug */
  promotionBySlug?: Maybe<PromotionEntity>;
  /** Searches for a shipment */
  shipmentSearch?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Gets a journal entry for PSP corporate */
  financialPspCorporateRevenueJournal?: Maybe<JournalEntry>;
  /** Lists care sheets */
  careSheetList?: Maybe<GraphQlPage_CareSheetEntity>;
  /** Locates a configurable product by SKU */
  configurableProductFindBySku?: Maybe<ConfigurableProductEntity>;
  /** Pulls information for a shipment */
  dropshipShipmentFind?: Maybe<ShipmentEntity>;
  /** Provides warehouse info */
  warehouseList?: Maybe<Array<Maybe<WarehouseEntity>>>;
  /** Locates a category by slug */
  categoryBySlug?: Maybe<CategoryResults>;
  /** Locates a product list by slug */
  affiliateGetProductListBySlug?: Maybe<AffiliateProductListEntity>;
  /** Provides user info for current user */
  userSelf?: Maybe<UserEntity>;
  /** Pulls information for a shipment */
  dropshipShipmentInfo?: Maybe<ShipmentEntity>;
  /** Locates a category by id */
  categoryInfo?: Maybe<CategoryEntity>;
  /** Provides cart info for current user */
  cartInfo?: Maybe<CartEntity>;
  /** Provides warehouse info */
  warehouseInfo?: Maybe<WarehouseEntity>;
  /** Looks up a shipment by secure hash */
  shipmentByHash?: Maybe<ShipmentEntity>;
  /** Test labels as a PDF */
  dropshipTestLaserLabels?: Maybe<Scalars['String']>;
  /** Locates a care sheet by id */
  careSheetInfo?: Maybe<CareSheetEntity>;
  /** Retrieves a refund */
  refundInfo?: Maybe<RefundEntity>;
  /** Pulls information for a shipment */
  dropshipShipmentBatchInfo?: Maybe<ShipmentBatchEntity>;
  /** Locates content by slug */
  contentBySlug?: Maybe<ContentEntity>;
  /** Locates an listed product by SKU */
  listedProductFindBySku?: Maybe<ListedProductEntity>;
  /** Returns a tradeshow */
  tradeShowInfo?: Maybe<TradeShowEntity>;
  /** Product Info */
  productViewBySlug?: Maybe<IGenericProductEntity>;
  scheduledShipmentHoldLocationsByHash?: Maybe<Array<Maybe<HoldLocation>>>;
  /** Lists discounts */
  discountList?: Maybe<GraphQlPage_DiscountEntity>;
  /** Lists subscriptions */
  assistedSubscriptionList?: Maybe<GraphQlPage_SubscriptionEntity>;
  /** Looks up an order by secure hash */
  salesOrderByHash?: Maybe<SalesOrderEntity>;
  /** Locates an listed product by UPC */
  listedProductFindByUpc?: Maybe<ListedProductEntity>;
  /** Retrieves a shipment */
  shipmentFind?: Maybe<ShipmentEntity>;
  /** Lists brands */
  brandList?: Maybe<GraphQlPage_BrandEntity>;
  /** Provides user info a user */
  giftCardInfo?: Maybe<GiftCardEntity>;
  /** Locates a kit product by UPC */
  kitProductFindByUpc?: Maybe<KitProductEntity>;
  /** Locate a review by id */
  reviewInfo?: Maybe<ReviewEntity>;
  /** Filters reviews */
  assistedReviewFilter?: Maybe<GraphQlPage_ReviewEntity>;
  /** Retrieves a subscription */
  assistedSubscriptionInfo?: Maybe<SubscriptionEntity>;
  /** Filters gift cards */
  giftCardFilter?: Maybe<GraphQlPage_GiftCardEntity>;
  /** Lists warehouse queues */
  dropshipQueueList?: Maybe<Array<Maybe<QueueEntity>>>;
  /** Returns open purchase order lines for a simple product */
  purchaseOrderOpenItems?: Maybe<Array<Maybe<PurchaseOrderItemEntity>>>;
  /** Returns a purchase order by id */
  purchaseOrderInfo?: Maybe<PurchaseOrderEntity>;
  /** Lists categories */
  categoryList?: Maybe<GraphQlPage_CategoryEntity>;
  /** Locates a kit product by SKU */
  kitProductFindBySku?: Maybe<KitProductEntity>;
  /** Fetches brand info */
  brandInfo?: Maybe<BrandEntity>;
  /** Retrieves a shipment */
  shipmentInfo?: Maybe<ShipmentEntity>;
  /** Filters listed products */
  listedProductFilter?: Maybe<GraphQlPage_ListedProductEntity>;
  /** Lists a user's reviews */
  reviewList?: Maybe<GraphQlPage_ReviewEntity>;
  /** Provides zone info */
  zoneList?: Maybe<Array<Maybe<ZoneEntity>>>;
  /** Lists shipping rules */
  shippingRuleSetList?: Maybe<GraphQlPage_ShippingRuleSetEntity>;
  /** Locates an listed product by slug */
  listedProductBySlug?: Maybe<ListedProductEntity>;
  /** Lists sales orders */
  salesOrderList?: Maybe<GraphQlPage_SalesOrderEntity>;
  /** Locates a kit product by id */
  kitProductInfo?: Maybe<KitProductEntity>;
  /** Returns the user's rewards account */
  rewardPointsGetRewardsAccount?: Maybe<RewardsAccountEntity>;
  /** Lists blogs */
  blogList?: Maybe<GraphQlPage_BlogEntity>;
  /** Lists tradeshows in pre-order window */
  tradeShowListInPreOrder?: Maybe<Array<Maybe<TradeShowEntity>>>;
  /** Returns the user's reward points balance */
  assistedRewardPointsBalance?: Maybe<Scalars['Long']>;
  /** Filters warehouse info */
  warehouseFilter?: Maybe<GraphQlPage_WarehouseEntity>;
  /** Lists categories */
  categoryFilter?: Maybe<GraphQlPage_CategoryEntity>;
  /** Retrieves a sales order */
  salesOrderInfo?: Maybe<SalesOrderEntity>;
  /** Locates a care sheet by slug */
  careSheetBySlug?: Maybe<CareSheetEntity>;
  /** Locates a blog by id */
  blogInfo?: Maybe<BlogEntity>;
  /** Gets a journal entry for the website */
  financialWebsiteRevenueJournal?: Maybe<JournalEntry>;
  /** Provides payment terms account info */
  assistedPaymentAccountInfo?: Maybe<PaymentTermsAccountEntity>;
};


/** Query root */
export type QueryDropshipDropshipperProfileFilterArgs = {
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  partnerId: Scalars['UUID'];
  title?: Maybe<GraphQlLikeQueryFilterInput>;
  sku?: Maybe<GraphQlLikeQueryFilterInput>;
};


/** Query root */
export type QueryUserInfoArgs = {
  id?: Maybe<Scalars['UUID']>;
};


/** Query root */
export type QueryDiscountBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QuerySalesOrderComputeBestShippingArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryCareSheetFilterArgs = {
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  title?: Maybe<GraphQlLikeQueryFilterInput>;
};


/** Query root */
export type QueryAssistedPaymentAccountFilterArgs = {
  lastName?: Maybe<GraphQlLikeQueryFilterInput>;
  firstName?: Maybe<GraphQlLikeQueryFilterInput>;
  active?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  company?: Maybe<GraphQlLikeQueryFilterInput>;
  page: GraphQlPageableInput;
  email?: Maybe<GraphQlLikeQueryFilterInput>;
};


/** Query root */
export type QuerySalesOrderNukeAndComputeBestShippingArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QuerySalesOrderGeneratePdfArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryReviewListForProductArgs = {
  productId: Scalars['UUID'];
  rating?: Maybe<Scalars['Long']>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryDiscountFilterImplicitProductsArgs = {
  id: Scalars['UUID'];
  page: GraphQlPageableInput;
  title?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
};


/** Query root */
export type QuerySupplierInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryListedProductListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryBlogFilterArgs = {
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  title?: Maybe<GraphQlLikeQueryFilterInput>;
};


/** Query root */
export type QueryPromotionFilterImplicitProductsArgs = {
  id: Scalars['UUID'];
  page: GraphQlPageableInput;
  title?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
};


/** Query root */
export type QueryProductBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryKitProductListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryAffiliateGetProductListByIdArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryTeamListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QuerySupplierListArgs = {
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryFinancialPspFranchiseRevenueJournalArgs = {
  endDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
};


/** Query root */
export type QueryBlockInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryProductSearchArgs = {
  featured?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  price?: Maybe<GraphQlValueRangeQueryFilter_BigDecimalInput>;
  query?: Maybe<Scalars['String']>;
  rating?: Maybe<GraphQlValueRangeQueryFilter_DoubleInput>;
  animal?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['String']>;
  inStock?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  subscription?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  category?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  promotion?: Maybe<Scalars['String']>;
};


/** Query root */
export type QuerySalesOrderSecureHashArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryProductFilterArgs = {
  visible?: Maybe<Scalars['Boolean']>;
  upc?: Maybe<Scalars['String']>;
  minRating?: Maybe<Scalars['Long']>;
  animal?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  subscription?: Maybe<Scalars['Boolean']>;
  page: GraphQlPageableInput;
  title?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  minStock?: Maybe<Scalars['Long']>;
};


/** Query root */
export type QueryDiscountFilterArgs = {
  permitStacking?: Maybe<Scalars['Boolean']>;
  eligibleUserType?: Maybe<UserType>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  discountType?: Maybe<DiscountType>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryAffiliateBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryGeolocationByIpAddressArgs = {
  ipAddress?: Maybe<Scalars['String']>;
};


/** Query root */
export type QueryConfigurableProductInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryDepartmentBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryUserSecureHashArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QuerySimpleProductFilterArgs = {
  rating?: Maybe<GraphQlSingleValueFilter_LongInput>;
  upc?: Maybe<Scalars['String']>;
  active?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  subscription?: Maybe<Scalars['Boolean']>;
  title?: Maybe<GraphQlLikeQueryFilterInput>;
  supplier?: Maybe<Scalars['String']>;
  animal?: Maybe<Scalars['String']>;
  inStock?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  page: GraphQlPageableInput;
  category?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  sku?: Maybe<GraphQlLikeQueryFilterInput>;
  brand?: Maybe<Scalars['String']>;
};


/** Query root */
export type QueryShelfLabelsGeneratePdfArgs = {
  collate: Scalars['Boolean'];
  zonePrefix: Scalars['String'];
  start: Scalars['Long'];
  format: Scalars['String'];
  end: Scalars['Long'];
};


/** Query root */
export type QueryProductInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryDiscountInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryUserFilterArgs = {
  lastName?: Maybe<GraphQlLikeQueryFilterInput>;
  firstName?: Maybe<GraphQlLikeQueryFilterInput>;
  admin?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  userType?: Maybe<UserType>;
  email?: Maybe<GraphQlLikeQueryFilterInput>;
};


/** Query root */
export type QueryShipmentFilterArgs = {
  lastName?: Maybe<GraphQlLikeQueryFilterInput>;
  firstName?: Maybe<GraphQlLikeQueryFilterInput>;
  salesOrderCls?: Maybe<GraphQlSingleValueFilter_StringInput>;
  late?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  warehouse?: Maybe<GraphQlSingleValueFilter_StringInput>;
  shipmentNumber?: Maybe<GraphQlLikeQueryFilterInput>;
  email?: Maybe<GraphQlLikeQueryFilterInput>;
  shipmentStatus?: Maybe<GraphQlSingleValueFilter_ShipmentStatusInput>;
};


/** Query root */
export type QueryMediaInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QuerySalesOrderFilterArgs = {
  orderType?: Maybe<GraphQlSingleValueFilter_StringInput>;
  billingLastName?: Maybe<GraphQlLikeQueryFilterInput>;
  orderNumber?: Maybe<GraphQlLikeQueryFilterInput>;
  alternateOrderNumber?: Maybe<GraphQlLikeQueryFilterInput>;
  billingFirstName?: Maybe<GraphQlLikeQueryFilterInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  email?: Maybe<GraphQlLikeQueryFilterInput>;
  hold?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  status?: Maybe<GraphQlSingleValueFilter_SalesOrderStatusInput>;
};


/** Query root */
export type QueryProductListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryKitProductBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryAssistedReviewInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QuerySimpleProductBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QuerySimpleProductFindByBinArgs = {
  binId: Scalars['String'];
  warehouse: Scalars['String'];
};


/** Query root */
export type QueryAssistedSubscriptionFilterArgs = {
  pauseReason?: Maybe<PauseReason>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryPurchaseRequestInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryReshipSalesOrderInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryDropshipWarehouseListArgs = {
  partnerId: Scalars['UUID'];
};


/** Query root */
export type QueryDepartmentListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryGiftCardBalanceArgs = {
  code: Scalars['String'];
  pin?: Maybe<Scalars['String']>;
};


/** Query root */
export type QueryPromotionFilterArgs = {
  promotionType?: Maybe<PromotionType>;
  permitStacking?: Maybe<Scalars['Boolean']>;
  eligibleUserType?: Maybe<UserType>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryTeamBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryUserFindByEmailArgs = {
  email: Scalars['String'];
};


/** Query root */
export type QueryBlogBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryPreppedProductsListArgs = {
  shipDate?: Maybe<Scalars['LocalDate']>;
  department: Scalars['String'];
  warehouse: Scalars['String'];
};


/** Query root */
export type QueryConfigurableProductBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QuerySubscriptionInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryDropshipShipmentSearchArgs = {
  query: Scalars['String'];
  partnerId: Scalars['UUID'];
};


/** Query root */
export type QueryCategoryByPathArgs = {
  path: Scalars['String'];
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryPreppedProductsInfoArgs = {
  productId: Scalars['UUID'];
  shipDate?: Maybe<Scalars['LocalDate']>;
  warehouse: Scalars['String'];
};


/** Query root */
export type QueryConfigurableProductListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QuerySimpleProductInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryAssistedScheduledShipmentFilterArgs = {
  lastName?: Maybe<GraphQlLikeQueryFilterInput>;
  firstName?: Maybe<GraphQlLikeQueryFilterInput>;
  salesOrderCls?: Maybe<GraphQlSingleValueFilter_StringInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  warehouse?: Maybe<Scalars['String']>;
  shipmentNumber?: Maybe<GraphQlLikeQueryFilterInput>;
  email?: Maybe<GraphQlLikeQueryFilterInput>;
  schedulingStatus?: Maybe<GraphQlSingleValueFilter_SchedulingStatusTypeInput>;
};


/** Query root */
export type QueryListedProductInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryConfigurableProductFilterArgs = {
  animal?: Maybe<Scalars['String']>;
  active?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  department?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  sku?: Maybe<GraphQlLikeQueryFilterInput>;
  title?: Maybe<GraphQlLikeQueryFilterInput>;
};


/** Query root */
export type QueryShipmentBatchListArgs = {
  page: GraphQlPageableInput;
  warehouse: Scalars['String'];
};


/** Query root */
export type QuerySubscriptionListArgs = {
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryMediaFilterArgs = {
  keywords?: Maybe<Scalars['String']>;
  page: GraphQlPageableInput;
  title?: Maybe<Scalars['String']>;
};


/** Query root */
export type QuerySimpleProductFindByUpcArgs = {
  upc: Scalars['String'];
};


/** Query root */
export type QueryKitProductFilterArgs = {
  upc?: Maybe<Scalars['String']>;
  animal?: Maybe<Scalars['String']>;
  active?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  department?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  sku?: Maybe<GraphQlLikeQueryFilterInput>;
  title?: Maybe<GraphQlLikeQueryFilterInput>;
};


/** Query root */
export type QuerySimpleProductListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryPromotionInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryDropshipZoneListArgs = {
  partnerId: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Query root */
export type QuerySimpleProductFindBySkuArgs = {
  sku: Scalars['String'];
};


/** Query root */
export type QueryProductListBySkusArgs = {
  skus: Array<Maybe<Scalars['String']>>;
};


/** Query root */
export type QueryAssistedRewardPointsGetRewardsAccountArgs = {
  userId: Scalars['UUID'];
};


/** Query root */
export type QueryBlogSearchArgs = {
  query: Scalars['String'];
  animal?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryFinancialResellerRevenueJournalArgs = {
  endDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
};


/** Query root */
export type QueryDropshipWarehouseInfoArgs = {
  partnerId: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Query root */
export type QueryPromotionListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryDropshipShipmentFilterArgs = {
  lastName?: Maybe<GraphQlLikeQueryFilterInput>;
  firstName?: Maybe<GraphQlLikeQueryFilterInput>;
  late?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  partnerId: Scalars['UUID'];
  warehouse?: Maybe<GraphQlSingleValueFilter_StringInput>;
  shipmentNumber?: Maybe<GraphQlLikeQueryFilterInput>;
  shipmentStatus?: Maybe<GraphQlSingleValueFilter_ShipmentStatusInput>;
};


/** Query root */
export type QueryTradeShowFilterArgs = {
  name?: Maybe<GraphQlLikeQueryFilterInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QuerySimpleProductProfitabilityReportArgs = {
  endDate: Scalars['LocalDate'];
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  category?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  startDate: Scalars['LocalDate'];
};


/** Query root */
export type QueryAnimalListArgs = {
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryPartnerInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryDropshipShipmentBatchListArgs = {
  partnerId: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Query root */
export type QueryPartnerFilterArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryBlockFilterArgs = {
  name?: Maybe<GraphQlLikeQueryFilterInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryPurchaseOrderFilterArgs = {
  orderNumber?: Maybe<GraphQlLikeQueryFilterInput>;
  supplier?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  status?: Maybe<PurchaseOrderStatus>;
};


/** Query root */
export type QueryShipmentSecureHashArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryCategorySearchArgs = {
  query: Scalars['String'];
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryShipmentBatchInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryPurchaseRequestFilterArgs = {
  requestNumber?: Maybe<GraphQlLikeQueryFilterInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  team?: Maybe<Scalars['String']>;
  status?: Maybe<PurchaseRequestStatus>;
};


/** Query root */
export type QueryScheduledShipmentHoldLocationsArgs = {
  postalCode: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
  miles?: Maybe<Scalars['BigDecimal']>;
};


/** Query root */
export type QueryWarehouseFindByNameArgs = {
  name: Scalars['String'];
};


/** Query root */
export type QueryPromotionBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryShipmentSearchArgs = {
  query: Scalars['String'];
};


/** Query root */
export type QueryFinancialPspCorporateRevenueJournalArgs = {
  endDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
};


/** Query root */
export type QueryCareSheetListArgs = {
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryConfigurableProductFindBySkuArgs = {
  sku: Scalars['String'];
};


/** Query root */
export type QueryDropshipShipmentFindArgs = {
  partnerId: Scalars['UUID'];
  shipmentNumber: Scalars['String'];
};


/** Query root */
export type QueryCategoryBySlugArgs = {
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  slug: Scalars['String'];
};


/** Query root */
export type QueryAffiliateGetProductListBySlugArgs = {
  affiliateSlug: Scalars['String'];
  slug: Scalars['String'];
};


/** Query root */
export type QueryDropshipShipmentInfoArgs = {
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Query root */
export type QueryCategoryInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryCartInfoArgs = {
  cartId?: Maybe<Scalars['UUID']>;
};


/** Query root */
export type QueryWarehouseInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryShipmentByHashArgs = {
  hash: Scalars['String'];
};


/** Query root */
export type QueryCareSheetInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryRefundInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryDropshipShipmentBatchInfoArgs = {
  id: Scalars['UUID'];
  partnerId: Scalars['UUID'];
};


/** Query root */
export type QueryContentBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryListedProductFindBySkuArgs = {
  sku: Scalars['String'];
};


/** Query root */
export type QueryTradeShowInfoArgs = {
  id?: Maybe<Scalars['UUID']>;
};


/** Query root */
export type QueryProductViewBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryScheduledShipmentHoldLocationsByHashArgs = {
  postalCode: Scalars['String'];
  hash: Scalars['String'];
  miles?: Maybe<Scalars['BigDecimal']>;
};


/** Query root */
export type QueryDiscountListArgs = {
  page?: Maybe<GraphQlPageableInput>;
};


/** Query root */
export type QueryAssistedSubscriptionListArgs = {
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  userId: Scalars['UUID'];
};


/** Query root */
export type QuerySalesOrderByHashArgs = {
  hash: Scalars['String'];
};


/** Query root */
export type QueryListedProductFindByUpcArgs = {
  upc: Scalars['String'];
};


/** Query root */
export type QueryShipmentFindArgs = {
  shipmentNumber: Scalars['String'];
};


/** Query root */
export type QueryBrandListArgs = {
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryGiftCardInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryKitProductFindByUpcArgs = {
  upc: Scalars['String'];
};


/** Query root */
export type QueryReviewInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryAssistedReviewFilterArgs = {
  productId?: Maybe<Scalars['UUID']>;
  rating?: Maybe<Scalars['Long']>;
  minRating?: Maybe<Scalars['Long']>;
  page: GraphQlPageableInput;
  userId?: Maybe<Scalars['UUID']>;
  status?: Maybe<ReviewStatus>;
};


/** Query root */
export type QueryAssistedSubscriptionInfoArgs = {
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** Query root */
export type QueryGiftCardFilterArgs = {
  code?: Maybe<GraphQlLikeQueryFilterInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryDropshipQueueListArgs = {
  partnerId: Scalars['UUID'];
  warehouse: Scalars['String'];
};


/** Query root */
export type QueryPurchaseOrderOpenItemsArgs = {
  simpleProductId: Scalars['UUID'];
};


/** Query root */
export type QueryPurchaseOrderInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryCategoryListArgs = {
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryKitProductFindBySkuArgs = {
  sku: Scalars['String'];
};


/** Query root */
export type QueryBrandInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryShipmentInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryListedProductFilterArgs = {
  supplier?: Maybe<Scalars['String']>;
  rating?: Maybe<GraphQlSingleValueFilter_LongInput>;
  animal?: Maybe<Scalars['String']>;
  active?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  inStock?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  category?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  sku?: Maybe<GraphQlLikeQueryFilterInput>;
  title?: Maybe<GraphQlLikeQueryFilterInput>;
  brand?: Maybe<Scalars['String']>;
};


/** Query root */
export type QueryReviewListArgs = {
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryShippingRuleSetListArgs = {
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryListedProductBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QuerySalesOrderListArgs = {
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryKitProductInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryBlogListArgs = {
  topic?: Maybe<Scalars['String']>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QueryAssistedRewardPointsBalanceArgs = {
  userId: Scalars['UUID'];
};


/** Query root */
export type QueryWarehouseFilterArgs = {
  name?: Maybe<GraphQlLikeQueryFilterInput>;
  sort?: Maybe<GraphQlSortInput>;
  page: GraphQlPageableInput;
  warehouseType?: Maybe<GraphQlSingleValueFilter_WarehouseTypeInput>;
  enabled?: Maybe<GraphQlSingleValueFilter_BooleanInput>;
};


/** Query root */
export type QueryCategoryFilterArgs = {
  active?: Maybe<Scalars['Boolean']>;
  page: GraphQlPageableInput;
};


/** Query root */
export type QuerySalesOrderInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryCareSheetBySlugArgs = {
  slug: Scalars['String'];
};


/** Query root */
export type QueryBlogInfoArgs = {
  id: Scalars['UUID'];
};


/** Query root */
export type QueryFinancialWebsiteRevenueJournalArgs = {
  endDate: Scalars['LocalDate'];
  startDate: Scalars['LocalDate'];
};


/** Query root */
export type QueryAssistedPaymentAccountInfoArgs = {
  id: Scalars['UUID'];
};

export type QueueEntity = {
  __typename?: 'QueueEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Queue's combos only */
  combosOnly?: Maybe<Scalars['Boolean']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Queue's enabled */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Queue's name */
  name?: Maybe<Scalars['String']>;
  /** Queue's priority */
  priority?: Maybe<Scalars['Long']>;
  /** Queue's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Queue's singles only */
  singlesOnly?: Maybe<Scalars['Boolean']>;
  /** Queue's slug */
  slug?: Maybe<Scalars['String']>;
  /** Queue's templates */
  template?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Queue's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
  /** Queue's zone */
  zone?: Maybe<ZoneEntity>;
};

export type RateQuote = {
  __typename?: 'RateQuote';
  /** Rate quote's carrier */
  carrier?: Maybe<Carrier>;
  /** Rate quote's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Rate quote's delivery date */
  deliveryDate?: Maybe<Scalars['String']>;
  /** Rate quote's delivery date end */
  deliveryDateEnd: Scalars['String'];
  /** Rate quote's delivery date start */
  deliveryDateStart: Scalars['String'];
  /** Rate quote's domestic service type */
  domesticServiceType?: Maybe<ServiceType>;
  /** Rate quote's evaluation */
  evaluation?: Maybe<RateQuoteEvaluation>;
  /** Rate quote's fitness */
  fitness?: Maybe<RateQuoteFitness>;
  /** Rate quote's options */
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Rate quote's packaging */
  packaging?: Maybe<Packaging>;
  /** Rate quote's postage reseller */
  reseller?: Maybe<Reseller>;
  /** Rate quote's service */
  service?: Maybe<Service>;
  /** Rate quote's ship date */
  shipDate?: Maybe<Scalars['String']>;
};

export type RateQuoteEvaluation = {
  __typename?: 'RateQuoteEvaluation';
  /** Rate quote evaluation's beats delivery date by day count */
  beatsDeliveryDateBy?: Maybe<Scalars['Long']>;
  /** Rate quote evaluation's matches service level flag */
  exceedsServiceLevel?: Maybe<Scalars['Boolean']>;
  /** Rate quote evaluation's generic method flag */
  genericMethod?: Maybe<Scalars['Boolean']>;
  /** Rate quote evaluation's missed carrier preference flag */
  missedCarrierPreference?: Maybe<Scalars['Boolean']>;
  /** Rate quote evaluation's misses delivery date by day count */
  missedDeliveryDateBy?: Maybe<Scalars['Long']>;
  /** Rate quote evaluation's missed service level flag */
  missedServiceLevel?: Maybe<Scalars['Boolean']>;
  /** Rate quote evaluation's misses ship date by day count */
  missedShipDateBy?: Maybe<Scalars['Long']>;
  /** Rate quote evaluation's misses transit days by day count */
  missedTransitDayBy?: Maybe<Scalars['Long']>;
  /** Rate quote evaluation's overpriced flag */
  overpriced?: Maybe<Scalars['Boolean']>;
  /** Rate quote evaluation's violates LAG flag */
  violatesLiveArrivalGuarantee?: Maybe<Scalars['Boolean']>;
};

export type RefundEntity = {
  __typename?: 'RefundEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Refund's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Refund's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Refund's placed at time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Refund's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Refund's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Refund's items */
  refundItems?: Maybe<Array<Maybe<RefundItemEntity>>>;
  /** Refund's listed items */
  refundListedItems?: Maybe<Array<Maybe<RefundListedItemEntity>>>;
  /** Refund's refund number */
  refundNumber?: Maybe<Scalars['String']>;
  /** Refund's refund reason */
  refundReason?: Maybe<RefundReason>;
  /** Refund's representative first name */
  repFirstName?: Maybe<Scalars['String']>;
  /** Refund's representative last name */
  repLastName?: Maybe<Scalars['String']>;
  /** Refund's sales order */
  salesOrder?: Maybe<SalesOrderEntity>;
  /** Refund's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Refund's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Refund's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Refund's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Refund's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type RefundItemEntity = {
  __typename?: 'RefundItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Refund item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Refund item's refund */
  refund?: Maybe<RefundEntity>;
  /** Refund item's sales order item */
  salesOrderItem?: Maybe<SalesOrderItemEntity>;
  /** Refund item's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type RefundListedItemEntity = {
  __typename?: 'RefundListedItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Refund listed item's simple product */
  listedProduct?: Maybe<ListedProductEntity>;
  /** Refund listed item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Refund listed item's refund */
  refund?: Maybe<RefundEntity>;
  /** Refund listed item's sales order item */
  salesOrderListedItem?: Maybe<SalesOrderListedItemEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type RefundableAmounts = {
  __typename?: 'RefundableAmounts';
  /** Handling amount eligible for refund */
  handling?: Maybe<Scalars['BigDecimal']>;
  /** Product discount amount eligible for refund */
  productDiscount?: Maybe<Scalars['BigDecimal']>;
  /** Shipping amount eligible for refund */
  shipping?: Maybe<Scalars['BigDecimal']>;
  /** Shipping discount amount eligible for refund */
  shippingDiscount?: Maybe<Scalars['BigDecimal']>;
  /** Product sub total eligible for refund */
  subTotal?: Maybe<Scalars['BigDecimal']>;
};

export type ResellerProfileEntity = {
  __typename?: 'ResellerProfileEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Reseller's content */
  content?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Reseller's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Profile's reseller */
  reseller?: Maybe<PartnerEntity>;
  /** Reseller's SKU */
  resellerSku?: Maybe<Scalars['String']>;
  /** Reseller's retail */
  retail?: Maybe<Scalars['BigDecimal']>;
  /** Reseller's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ReshipSalesOrderEntity = {
  __typename?: 'ReshipSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' type */
  billingAddressType?: Maybe<AddressType>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Sales order's fulfillment network */
  fulfillmentNetwork?: Maybe<FulfillmentNetworkType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Reship sales order's parent order */
  parentOrder?: Maybe<SalesOrderEntity>;
  /** Sales order's payment terms account */
  paymentTermsAccount?: Maybe<PaymentTermsAccountEntity>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Reship sales order's refund reason */
  refundReason?: Maybe<RefundReason>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Reship sales order's representative first name */
  repFirstName?: Maybe<Scalars['String']>;
  /** Reship sales order's representative last name */
  repLastName?: Maybe<Scalars['String']>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Sales order's listed items */
  salesOrderListedItems?: Maybe<Array<Maybe<SalesOrderListedItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' type */
  shippingAddressType?: Maybe<AddressType>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type ReviewEntity = {
  __typename?: 'ReviewEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Review's comments */
  comments?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Review's created time */
  createdTime?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Review's medias */
  medias?: Maybe<Array<Maybe<MediaEntity>>>;
  /** Review's product */
  product?: Maybe<ProductEntity>;
  /** Review's rating */
  rating?: Maybe<Scalars['Long']>;
  /** Review's reviewer */
  reviewer?: Maybe<Scalars['String']>;
  /** Review's reward points */
  rewardPoints?: Maybe<Scalars['Long']>;
  /** Review's status */
  status?: Maybe<ReviewStatus>;
  /** Review's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Review's updated time */
  updatedTime?: Maybe<Scalars['Instant']>;
  /** Review's user */
  user?: Maybe<UserEntity>;
};

export type ReviewResults = {
  __typename?: 'ReviewResults';
  /** Result's average rating */
  averageRating?: Maybe<Scalars['Float']>;
  /** Result's count 0 to 20 */
  count0To20?: Maybe<Scalars['Long']>;
  /** Result's count 21 to 40 */
  count21To40?: Maybe<Scalars['Long']>;
  /** Result's count 41 to 60 */
  count41To60?: Maybe<Scalars['Long']>;
  /** Result's count 61 to 80 */
  count61To80?: Maybe<Scalars['Long']>;
  /** Result's count 81 to 100 */
  count81To100?: Maybe<Scalars['Long']>;
  /** Result's reviews */
  reviews?: Maybe<GraphQlPage_ReviewEntity>;
};

export type RewardsAccountEntity = {
  __typename?: 'RewardsAccountEntity';
  /** Rewards account's balance */
  balance?: Maybe<Scalars['Long']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Rewards account's transactions */
  transactions?: Maybe<Array<Maybe<RewardsTransactionEntity>>>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type RewardsTransactionEntity = {
  __typename?: 'RewardsTransactionEntity';
  /** Rewards transaction's amount */
  amount?: Maybe<Scalars['Long']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Rewards transaction's comment */
  comment?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Rewards transaction's expiration */
  expiration?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Rewards transaction's timestamp */
  timestamp?: Maybe<Scalars['Instant']>;
  /** Rewards transaction's type */
  transactionType?: Maybe<RewardsTransactionType>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SalesOrderCommentsEntity = {
  __typename?: 'SalesOrderCommentsEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Comment's comment */
  comment?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Comment's name */
  name?: Maybe<Scalars['String']>;
  /** Comment's sales order */
  salesOrder?: Maybe<SalesOrderEntity>;
  /** Comment's time */
  time?: Maybe<Scalars['Instant']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SalesOrderDiscountEntity = {
  __typename?: 'SalesOrderDiscountEntity';
  /** Sales order discount's amount */
  amount?: Maybe<Scalars['BigDecimal']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order discount's name */
  name?: Maybe<Scalars['String']>;
  /** Sales order discount's reward points */
  rewardPoints: Scalars['Long'];
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SalesOrderEntity = {
  __typename?: 'SalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' type */
  billingAddressType?: Maybe<AddressType>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Sales order's fulfillment network */
  fulfillmentNetwork?: Maybe<FulfillmentNetworkType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Order type */
  orderType?: Maybe<OrderType>;
  /** Sales order's payment terms account */
  paymentTermsAccount?: Maybe<PaymentTermsAccountEntity>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Sales order's listed items */
  salesOrderListedItems?: Maybe<Array<Maybe<SalesOrderListedItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' type */
  shippingAddressType?: Maybe<AddressType>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type SalesOrderItemEntity = {
  __typename?: 'SalesOrderItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order item's discount */
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order item's expected delivery */
  expectedDelivery?: Maybe<Scalars['LocalDate']>;
  /** Order item's expected ship */
  expectedShip?: Maybe<Scalars['LocalDate']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Order item's line cost */
  lineAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order item's live arrival guarantee */
  liveArrivalGuaranteeState?: Maybe<LiveArrivalGuaranteeState>;
  /** Order item's name */
  name?: Maybe<Scalars['String']>;
  /** Order item's overridden carrier preference */
  overriddenCarrierPreference?: Maybe<Carrier>;
  /** Order item's overridden service type */
  overriddenDomesticServiceType?: Maybe<ServiceType>;
  /** Order item's overridden shipping needs */
  overriddenShippingNeedsType?: Maybe<ShippingNeedsType>;
  /** Order item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Order item's group */
  salesOrderItemGroup?: Maybe<SalesOrderItemGroupEntity>;
  /** Order item's shipping amount */
  shippingAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order item's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Order item's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Order item's tax amount */
  taxAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order item's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order item's unit cost */
  unitAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order item's unit weight */
  unitWeight?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SalesOrderItemGroupEntity = {
  __typename?: 'SalesOrderItemGroupEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order item group's discount */
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Order item group's kit product */
  kitProduct?: Maybe<KitProductEntity>;
  /** Order item group's line cost */
  lineAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order item group's name */
  name?: Maybe<Scalars['String']>;
  /** Order item group's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Order item group's order items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Order item group's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Order item group's unit cost */
  unitAmount?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SalesOrderLineItem = {
  __typename?: 'SalesOrderLineItem';
  id?: Maybe<Scalars['UUID']>;
  quantity?: Maybe<Scalars['Long']>;
};

export type SalesOrderListedItemEntity = {
  __typename?: 'SalesOrderListedItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order listed item's discount */
  discountAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order listed item's expected delivery */
  expectedDelivery?: Maybe<Scalars['LocalDate']>;
  /** Order listed item's expected ship */
  expectedShip?: Maybe<Scalars['LocalDate']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Order listed item's line cost */
  lineAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order listed item's listed product */
  listedProduct?: Maybe<ListedProductEntity>;
  /** Order listed item's listing */
  listing?: Maybe<ListingEntity>;
  /** Order listed item's live arrival guarantee */
  liveArrivalGuaranteeState?: Maybe<LiveArrivalGuaranteeState>;
  /** Order listed item's name */
  name?: Maybe<Scalars['String']>;
  /** Order listed item's overridden carrier preference */
  overriddenCarrierPreference?: Maybe<Carrier>;
  /** Order listed item's overridden service type */
  overriddenDomesticServiceType?: Maybe<ServiceType>;
  /** Order listed item's overridden shipping needs */
  overriddenShippingNeedsType?: Maybe<ShippingNeedsType>;
  /** Order listed item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Order listed item's shipping amount */
  shippingAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order listed item's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Order listed item's tax amount */
  taxAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order listed item's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order listed item's unit cost */
  unitAmount?: Maybe<Scalars['BigDecimal']>;
  /** Order listed item's unit weight */
  unitWeight?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SchedulingDashboard = {
  __typename?: 'SchedulingDashboard';
  /** Dashboard's needs approval count */
  needsApprovalCount?: Maybe<Scalars['Long']>;
  /** Dashboard's needs picked count */
  needsPickedCount?: Maybe<Scalars['Long']>;
  /** Dashboard's needs shipped count */
  needsShippedCount?: Maybe<Scalars['Long']>;
  /** Dashboard's next open date */
  nextOpenDate?: Maybe<Scalars['String']>;
  /** Dashboard's scheduled in future count */
  scheduledInFutureCount?: Maybe<Scalars['Long']>;
  /** Dashboard's timezone */
  timezone?: Maybe<Scalars['String']>;
  /** Dashboard's to reschedule count */
  toRescheduleCount?: Maybe<Scalars['Long']>;
  /** Dashboard's today */
  today?: Maybe<Scalars['String']>;
  /** Dashboard's unscheduled count */
  unscheduledCount?: Maybe<Scalars['Long']>;
  /** Dashboard's warehouse */
  warehouse?: Maybe<Scalars['String']>;
  /** Dashboard's to weather hold count */
  weatherHoldCount?: Maybe<Scalars['Long']>;
};

export type SearchResults = {
  __typename?: 'SearchResults';
  /** Result's animal */
  animal?: Maybe<AnimalEntity>;
  /** Result's brand */
  brand?: Maybe<BrandEntity>;
  /** Result's category */
  category?: Maybe<CategoryEntity>;
  /** Result's products */
  products?: Maybe<GraphQlPage_ProductEntity>;
};

export type ShipmentAddonEntity = {
  __typename?: 'ShipmentAddonEntity';
  /** Shipment addon's addon */
  addon?: Maybe<AddonEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipment addon's name */
  name?: Maybe<Scalars['String']>;
  /** Shipment addon's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Shipment addon's shipment */
  shipment?: Maybe<ShipmentEntity>;
  /** Shipment addon's sku */
  sku?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ShipmentBatchEntity = {
  __typename?: 'ShipmentBatchEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Batch's count */
  count?: Maybe<Scalars['Long']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Batch's name */
  name?: Maybe<Scalars['String']>;
  /** Batch's picker */
  picker?: Maybe<Scalars['String']>;
  /** Batch's end time */
  pickingEnd?: Maybe<Scalars['Instant']>;
  /** Batch's start time */
  pickingStart?: Maybe<Scalars['Instant']>;
  /** Batch's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Batch's timestamp */
  timestamp?: Maybe<Scalars['Instant']>;
  /** Batch's unshipped count */
  unshippedCount?: Maybe<Scalars['Long']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Batch's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
};

export type ShipmentEntity = {
  __typename?: 'ShipmentEntity';
  /** Shipment's actual carrier */
  actualCarrier?: Maybe<Carrier>;
  /** Shipment's actual height */
  actualHeight?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's actual length */
  actualLength?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's actual options */
  actualOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Shipment's actual packaging */
  actualPackaging?: Maybe<Packaging>;
  /** Shipment's actual postage reseller */
  actualReseller?: Maybe<Reseller>;
  /** Shipment's actual service */
  actualService?: Maybe<Service>;
  /** Shipment's actual shipping cost */
  actualShippingCost?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's actual weight */
  actualWeight?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's actual width */
  actualWidth?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's address type */
  addressType?: Maybe<AddressType>;
  /** Shipment's address validation source */
  addressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipment's backorder */
  backorder?: Maybe<Scalars['Boolean']>;
  /** Shipment's backorder expiry */
  backorderExpiry?: Maybe<Scalars['LocalDate']>;
  /** Shipment's cancellation reason */
  cancellationReason?: Maybe<CancellationReason>;
  /** Shipment's cancelled at */
  cancelledAt?: Maybe<Scalars['Instant']>;
  /** Shipment's cancelled by */
  cancelledBy?: Maybe<Scalars['String']>;
  /** Shipment's carrier */
  carrier?: Maybe<Carrier>;
  /** Shipment's carrier preference */
  carrierPreference?: Maybe<Carrier>;
  /** Shipment's city */
  city?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Shipment's company */
  company?: Maybe<Scalars['String']>;
  /** Shipment's content hash */
  contentsMd5?: Maybe<Scalars['String']>;
  /** Shipment's country */
  country?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Shipment's departing warehouse */
  departingWarehouse?: Maybe<WarehouseEntity>;
  /** Shipment's download start */
  downloadStart?: Maybe<Scalars['Instant']>;
  /** Shipment's dropshipper */
  dropshipper?: Maybe<PartnerEntity>;
  /** Shipment's email */
  email?: Maybe<Scalars['String']>;
  /** Shipment's estimated carrier */
  estimatedCarrier?: Maybe<Carrier>;
  /** Shipment's estimated delivery date */
  estimatedDeliveryDate?: Maybe<Scalars['LocalDate']>;
  /** Shipment's estimated delivery date end */
  estimatedDeliveryDateEnd?: Maybe<Scalars['LocalDate']>;
  /** Shipment's estimated delivery date start */
  estimatedDeliveryDateStart?: Maybe<Scalars['LocalDate']>;
  /** Shipment's estimated height */
  estimatedHeight?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's estimated length */
  estimatedLength?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's estimated options */
  estimatedOptions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Shipment's estimated packaging */
  estimatedPackaging?: Maybe<Packaging>;
  /** Shipment's estimated postage reseller */
  estimatedReseller?: Maybe<Reseller>;
  /** Shipment's estimated service */
  estimatedService?: Maybe<Service>;
  /** Shipment's estimated ship date */
  estimatedShipDate?: Maybe<Scalars['LocalDate']>;
  /** Shipment's estimated shipping cost */
  estimatedShippingCost?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's estimated weight */
  estimatedWeight?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's estimated width */
  estimatedWidth?: Maybe<Scalars['BigDecimal']>;
  /** Shipment's final approval */
  finalApproval?: Maybe<Scalars['Boolean']>;
  /** Shipment's final approver */
  finalApprovalBy?: Maybe<Scalars['String']>;
  /** Shipment's first name */
  firstName?: Maybe<Scalars['String']>;
  /** Shipment's handling cost */
  handlingCost?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipment's last name */
  lastName?: Maybe<Scalars['String']>;
  /** Shipment's line 1 */
  line1?: Maybe<Scalars['String']>;
  /** Shipment's line 2 */
  line2?: Maybe<Scalars['String']>;
  /** Shipment's options */
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Shipment's origin warehouse */
  originWarehouse?: Maybe<WarehouseEntity>;
  /** Shipment's packaging */
  packaging?: Maybe<Packaging>;
  packingListZpl?: Maybe<Array<Maybe<SignedZplLabel>>>;
  /** Shipment's placed at time */
  placedAt?: Maybe<Scalars['Instant']>;
  /** Shipment's postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Shipment's printed end */
  printedEnd?: Maybe<Scalars['Instant']>;
  /** Shipment's printed start */
  printedStart?: Maybe<Scalars['Instant']>;
  /** Shipment's queue */
  queue?: Maybe<QueueEntity>;
  /** Shipment's reprint status */
  reprint?: Maybe<Scalars['Boolean']>;
  /** Shipment's postage reseller */
  reseller?: Maybe<Reseller>;
  /** Shipment's residential flag */
  residential?: Maybe<Scalars['Boolean']>;
  /** Shipment's sales order */
  salesOrder?: Maybe<SalesOrderEntity>;
  /** Shipment's scheduled at time */
  scheduledAt?: Maybe<Scalars['Instant']>;
  /** Shipment's scheduler */
  scheduledBy?: Maybe<Scalars['String']>;
  schedulingStatus?: Maybe<SchedulingStatusType>;
  /** Shipment's service */
  service?: Maybe<Service>;
  /** Shipment's addons */
  shipmentAddons?: Maybe<Array<Maybe<ShipmentAddonEntity>>>;
  /** Shipment's batch */
  shipmentBatch?: Maybe<ShipmentBatchEntity>;
  /** Shipment's items */
  shipmentItems?: Maybe<Array<Maybe<ShipmentItemEntity>>>;
  /** Shipment's listed items */
  shipmentListedItems?: Maybe<Array<Maybe<ShipmentListedItemEntity>>>;
  /** Shipment's shipment number */
  shipmentNumber?: Maybe<Scalars['String']>;
  /** Shipment's pallets */
  shipmentPallets?: Maybe<Array<Maybe<ShipmentPalletEntity>>>;
  shipmentSsccLabelsZpl?: Maybe<Array<Maybe<SignedZplLabel>>>;
  /** Shipment's status */
  shipmentStatus?: Maybe<ShipmentStatus>;
  /** Shipment's shipped at time */
  shippedAt?: Maybe<Scalars['Instant']>;
  /** Shipment's shipper */
  shippedBy?: Maybe<Scalars['String']>;
  shippingLabels?: Maybe<Array<Maybe<SignedZplLabel>>>;
  /** Shipment's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Shipment's state */
  state?: Maybe<Scalars['String']>;
  /** Shipment's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Shipment's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Shipment's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Shipment's tracking number (deprecated) */
  trackingNo?: Maybe<Scalars['String']>;
  /** Shipment's tracking number */
  trackingNos?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Shipment's tracking number (deprecated) */
  trackingUrl?: Maybe<Scalars['String']>;
  /** Shipment's tracking urls */
  trackingUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};


export type ShipmentEntityShippingLabelsArgs = {
  labelType?: Maybe<LabelType>;
};

export type ShipmentItemEntity = {
  __typename?: 'ShipmentItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipment item's live arrival guarantee */
  liveArrivalGuaranteeState?: Maybe<LiveArrivalGuaranteeState>;
  /** Shipment item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Shipment item's sales order item */
  salesOrderItem?: Maybe<SalesOrderItemEntity>;
  /** Shipment item's shipment */
  shipment?: Maybe<ShipmentEntity>;
  /** Shipment item's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Shipment item's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
};

export type ShipmentListedItemEntity = {
  __typename?: 'ShipmentListedItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipment listed item's listed product */
  listedProduct?: Maybe<ListedProductEntity>;
  /** Shipment listed item's live arrival guarantee */
  liveArrivalGuaranteeState?: Maybe<LiveArrivalGuaranteeState>;
  /** Shipment listed item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Shipment listed item's sales order item */
  salesOrderListedItem?: Maybe<SalesOrderListedItemEntity>;
  /** Shipment listed item's shipment */
  shipment?: Maybe<ShipmentEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Shipment listed item's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
};

export type ShipmentPalletEntity = {
  __typename?: 'ShipmentPalletEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipment pallet's SSCC number */
  palletSsccNumber: Scalars['String'];
  /** Shipment pallet's shipment */
  shipment: ShipmentEntity;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ShippingEstimateEntity = {
  __typename?: 'ShippingEstimateEntity';
  /** Shipping estimates carrier */
  carrier?: Maybe<Carrier>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Shipping estimates cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Shipping estimates country */
  country?: Maybe<Country>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Shipping estimates height */
  height?: Maybe<Scalars['BigDecimal']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipping estimates length */
  length?: Maybe<Scalars['BigDecimal']>;
  /** Shipping estimates service */
  service?: Maybe<Service>;
  /** Shipping estimates service type */
  serviceType?: Maybe<ServiceType>;
  /** Shipping estimates state */
  state?: Maybe<State>;
  /** Shipping estimates transit days */
  transitDays?: Maybe<Scalars['Long']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Shipping estimates weight */
  weight?: Maybe<Scalars['BigDecimal']>;
  /** Shipping estimates width */
  width?: Maybe<Scalars['BigDecimal']>;
};

export type ShippingRuleSetEntity = {
  __typename?: 'ShippingRuleSetEntity';
  /** Shipping rule set's addon destination requirements */
  addonSetDestinationRequirements?: Maybe<Array<Maybe<AddonSetDestinationRequirementEntity>>>;
  /** Shipping rule set's addon source requirements */
  addonSetSourceRequirements?: Maybe<Array<Maybe<AddonSetSourceRequirementEntity>>>;
  /** Shipping rule set's carrier restriction */
  carrierRestriction?: Maybe<CarrierRestrictionEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Shipping rule set's live destination restriction */
  destinationRestriction?: Maybe<DestinationRestrictionEntity>;
  /** Shipping rule set's hazmat flag */
  hazmat?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipping rule set's live arrival guarantees */
  liveArrivalGuarantees?: Maybe<Array<Maybe<LiveArrivalGuaranteeEntity>>>;
  /** Shipping rule set's name */
  name?: Maybe<Scalars['String']>;
  /** Shipping rule set's ships alone restriction */
  shipsAloneRestriction?: Maybe<ShipsAloneRestrictionEntity>;
  /** Shipping rule set's slug */
  slug?: Maybe<Scalars['String']>;
  /** Shipping rule set's transit time restriction */
  transitTimeRestriction?: Maybe<TransitTimeRestrictionEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ShipsAloneRestrictionEntity = {
  __typename?: 'ShipsAloneRestrictionEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipping rule's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type ShowReport = {
  __typename?: 'ShowReport';
  /** Show report's city */
  city?: Maybe<Scalars['String']>;
  /** Show report's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Show report's end date */
  endDate?: Maybe<Scalars['LocalDate']>;
  /** Show report's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Show report's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Show report's line items */
  lineItems?: Maybe<Array<Maybe<LineItem>>>;
  /** Show report's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Show report's show name */
  showName?: Maybe<Scalars['String']>;
  /** Show report's start date */
  startDate?: Maybe<Scalars['LocalDate']>;
  /** Show report's state */
  state?: Maybe<Scalars['String']>;
  /** Show report's subtotal */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Show report's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
};

export type SignedZplLabel = {
  __typename?: 'SignedZplLabel';
  /** Label's contents */
  zplContents?: Maybe<Scalars['String']>;
  /** Label's signature */
  zplSignature?: Maybe<Scalars['String']>;
};

export type SimpleProductEntity = {
  __typename?: 'SimpleProductEntity';
  /** Product's active flag */
  active?: Maybe<Scalars['Boolean']>;
  /** Product's Amazon profile */
  amazonProfile?: Maybe<AmazonProfileEntity>;
  /** Product's average rating */
  averageRating?: Maybe<Scalars['Float']>;
  /** Simple product's bins */
  bins?: Maybe<Array<Maybe<BinEntity>>>;
  /** Product's blogs */
  blogs?: Maybe<Array<Maybe<BlogEntity>>>;
  /** Simple product's brand */
  brand?: Maybe<BrandEntity>;
  /** Product's care sheets */
  careSheets?: Maybe<Array<Maybe<CareSheetEntity>>>;
  /** Simple product's case height */
  caseHeight?: Maybe<Scalars['BigDecimal']>;
  /** Simple product's case length */
  caseLength?: Maybe<Scalars['BigDecimal']>;
  /** Simple product's case quantity */
  caseQuantity?: Maybe<Scalars['Long']>;
  /** Simple product's case width */
  caseWidth?: Maybe<Scalars['BigDecimal']>;
  /** Product's Chewy profile */
  chewyProfile?: Maybe<ChewyProfileEntity>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Simple product's component items */
  componentItems?: Maybe<Array<Maybe<BomItemEntity>>>;
  /** Product's cost */
  cost?: Maybe<Scalars['BigDecimal']>;
  /** Product's number of ratings */
  countRating?: Maybe<Scalars['Long']>;
  /** Simple product's country of origin */
  countryOfOrigin?: Maybe<Country>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Product's cross sell items */
  crossSellItems?: Maybe<Array<Maybe<CrossSellItemEntity>>>;
  crossSells?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Simple product's discontinuation reason */
  discontinuationReason?: Maybe<DiscontinuationReasonType>;
  /** Product's dropshipped flag */
  dropshipped?: Maybe<Scalars['Boolean']>;
  /** Product's dropship partner */
  dropshipper?: Maybe<PartnerEntity>;
  /** Simple product's dropshipper profiles */
  dropshipperProfiles?: Maybe<Array<Maybe<DropshipperProfileEntity>>>;
  /** Product's explicit animals */
  explicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's explicit categories */
  explicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's explicit department */
  explicitDepartment?: Maybe<DepartmentEntity>;
  /** Product's explicit discounts */
  explicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's explicit promotions */
  explicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's featured status */
  featured?: Maybe<Scalars['Boolean']>;
  /** Product's free shipping */
  freeShipping?: Maybe<Scalars['Boolean']>;
  /** Simple product's height */
  height?: Maybe<Scalars['BigDecimal']>;
  /** Simple product's hs code */
  hsCode?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Product's implicit animals */
  implicitAnimals?: Maybe<Array<Maybe<AnimalEntity>>>;
  /** Product's implicit categories */
  implicitCategories?: Maybe<Array<Maybe<CategoryEntity>>>;
  /** Product's implicit departments */
  implicitDepartments?: Maybe<Array<Maybe<DepartmentEntity>>>;
  /** Product's implicit discounts */
  implicitDiscounts?: Maybe<Array<Maybe<DiscountEntity>>>;
  /** Product's implicit promotions */
  implicitPromotions?: Maybe<Array<Maybe<PromotionEntity>>>;
  /** Product's important status */
  important?: Maybe<Scalars['Boolean']>;
  /** Simple product's inventory quantity caches */
  inventoryQuantityCaches?: Maybe<Array<Maybe<InventoryQuantityCacheEntity>>>;
  /** Simple product's inventory stats caches */
  inventoryStatsCaches?: Maybe<Array<Maybe<InventoryStatsCacheEntity>>>;
  /** Simple product's length */
  length?: Maybe<Scalars['BigDecimal']>;
  /** Product's live flag */
  live?: Maybe<Scalars['Boolean']>;
  /** Product's Magento Id */
  magentoId?: Maybe<Scalars['String']>;
  /** Simple product's Magento vendor */
  magentoVendor?: Maybe<Vendor>;
  /** Product's manufacture time */
  manufactureTime?: Maybe<Scalars['BigDecimal']>;
  /** Simple product's MAP price */
  mapPrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's medias */
  medias?: Maybe<Array<Maybe<ProductMediaEntity>>>;
  /** Product's meta description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Product's meta keywords */
  metaKeywords?: Maybe<Scalars['String']>;
  /** Product's meta title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Simple product's minimum order quantity */
  minimumOrderQuantity?: Maybe<Scalars['Long']>;
  /** Simple product's not ships alone */
  notShipsAlone?: Maybe<Scalars['Boolean']>;
  /** Product's Pet Supplies Plus profile */
  petSuppliesPlusProfile?: Maybe<PetSuppliesPlusProfileEntity>;
  /** Product's Petco dropship profile */
  petcoDropshipProfile?: Maybe<PetcoDropshipProfileEntity>;
  /** Product's Petco store direct profile */
  petcoStoreDirectProfile?: Maybe<PetcoStoreDirectProfileEntity>;
  /** Product's popularity */
  popularity?: Maybe<Scalars['BigDecimal']>;
  /** Product's prep type */
  prepType?: Maybe<PrepType>;
  /** Product's price */
  price?: Maybe<Scalars['BigDecimal']>;
  /** Product's price tiers */
  priceTiers?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's published revision */
  publishedRevision?: Maybe<ProductRevisionEntity>;
  /** Product's quantity available for sale */
  quantityAvailableForSale?: Maybe<Scalars['Long']>;
  /** Product's quantity available for sale on speciality markets */
  quantityAvailableForSpeciality?: Maybe<Scalars['Long']>;
  /** Product's reseller profiles */
  resellerProfiles?: Maybe<Array<Maybe<ResellerProfileEntity>>>;
  salePrice?: Maybe<Scalars['BigDecimal']>;
  /** Simple product's sales tax rules */
  salesTaxRules?: Maybe<SalesTaxRules>;
  /** Product's shipping estimates */
  shippingEstimates?: Maybe<Array<Maybe<ShippingEstimateEntity>>>;
  /** Simple product's shipping needs */
  shippingNeeds?: Maybe<ShippingNeedsType>;
  /** Simple product's shipping restrictions */
  shippingRestrictions?: Maybe<Scalars['String']>;
  /** Simple product's shipping rule set */
  shippingRuleSet?: Maybe<ShippingRuleSetEntity>;
  /** Simple product's ships alone */
  shipsAlone?: Maybe<Scalars['Boolean']>;
  /** Product's SKU */
  sku?: Maybe<Scalars['String']>;
  /** Product's slug */
  slug?: Maybe<Scalars['String']>;
  /** Product's special price */
  specialPrice?: Maybe<Scalars['BigDecimal']>;
  /** Product's subscription status */
  subscription?: Maybe<Scalars['Boolean']>;
  /** Simple product's supplier */
  supplier?: Maybe<SupplierEntity>;
  tieredPrices?: Maybe<Array<Maybe<PriceTierEntity>>>;
  /** Product's title */
  title?: Maybe<Scalars['String']>;
  /** Product's UPC */
  upc?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Product's upsells */
  upsellProducts?: Maybe<Array<Maybe<ProductEntity>>>;
  userReview?: Maybe<ReviewEntity>;
  /** Product's variation key */
  variationKey?: Maybe<Scalars['String']>;
  /** Product's variation set */
  variationSet?: Maybe<VariationSetEntity>;
  /** Simple product's vendor reference */
  vendorRef?: Maybe<Scalars['String']>;
  /** Product's visible flag */
  visible?: Maybe<Scalars['Boolean']>;
  /** Product's Walmart profile */
  walmartProfile?: Maybe<WalmartProfileEntity>;
  /** Simple product's warehouses */
  warehouses?: Maybe<Array<Maybe<WarehouseEntity>>>;
  /** Simple product's weight */
  weight?: Maybe<Scalars['BigDecimal']>;
  /** Product's wholesale price */
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
  /** Simple product's width */
  width?: Maybe<Scalars['BigDecimal']>;
  /** Simple product's zones */
  zones?: Maybe<Array<Maybe<ZoneEntity>>>;
};

export type Stats = {
  __typename?: 'Stats';
  /** Stat's end date */
  endDate?: Maybe<Scalars['String']>;
  /** Stat's on-hold count */
  onHold?: Maybe<Scalars['Int']>;
  /** Stat's shipped late */
  shippedLate?: Maybe<Scalars['Int']>;
  /** Stat's shipped on-time */
  shippedOnTime?: Maybe<Scalars['Int']>;
  /** Stat's start date */
  startDate?: Maybe<Scalars['String']>;
  /** Stat's unshipped count */
  unshipped?: Maybe<Scalars['Int']>;
};

export type SubscriptionEntity = {
  __typename?: 'SubscriptionEntity';
  /** Billing address' type */
  billingAddressType?: Maybe<AddressType>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Subscription's carrier preference */
  carrierPreference?: Maybe<Carrier>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Subscription's delivery day of week */
  deliveryDay?: Maybe<DayOfWeek>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Subscription's pause reason */
  pauseReason?: Maybe<PauseReason>;
  paymentMethod?: Maybe<PaymentMethodDetail>;
  /** Subscription's phone */
  phone?: Maybe<Scalars['String']>;
  /** Subscription's promo code */
  promoCode?: Maybe<Scalars['String']>;
  /** Subscription's sales orders */
  salesOrders?: Maybe<Array<Maybe<SubscriptionSalesOrderEntity>>>;
  /** Subscription's service type */
  serviceType?: Maybe<ServiceType>;
  /** Shipping address' type */
  shippingAddressType?: Maybe<AddressType>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Subscription's items */
  subscriptionItems?: Maybe<Array<Maybe<SubscriptionItemEntity>>>;
  /** Subscription's tax-exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SubscriptionItemEntity = {
  __typename?: 'SubscriptionItemEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Subscriptions item's frequency */
  frequency?: Maybe<Scalars['Long']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Subscription item's last delivery date */
  lastDeliveryDate?: Maybe<Scalars['LocalDate']>;
  /** Subscriptions item's next delivery date */
  nextDeliveryDate?: Maybe<Scalars['LocalDate']>;
  /** Subscriptions item's product */
  product?: Maybe<ProductEntity>;
  /** Subscriptions item's quantity */
  quantity?: Maybe<Scalars['Long']>;
  /** Subscriptions item's simple product */
  simpleProduct?: Maybe<SimpleProductEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type SubscriptionSalesOrderEntity = {
  __typename?: 'SubscriptionSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' type */
  billingAddressType?: Maybe<AddressType>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Sales order's fulfillment network */
  fulfillmentNetwork?: Maybe<FulfillmentNetworkType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payment terms account */
  paymentTermsAccount?: Maybe<PaymentTermsAccountEntity>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Sales order's listed items */
  salesOrderListedItems?: Maybe<Array<Maybe<SalesOrderListedItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' type */
  shippingAddressType?: Maybe<AddressType>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type Summary = {
  __typename?: 'Summary';
  /** Summary's date */
  date?: Maybe<Scalars['String']>;
  /** Summary's on-hold count */
  onHold?: Maybe<Scalars['Long']>;
  /** Summary's shipped count */
  shipped?: Maybe<Scalars['Long']>;
  /** Summary's unshipped count */
  unshipped?: Maybe<Scalars['Long']>;
};

export type SupplierEntity = {
  __typename?: 'SupplierEntity';
  /** Supplier's address type */
  addressType?: Maybe<AddressType>;
  /** Supplier's validation source */
  addressValidationSource?: Maybe<AddressValidationSource>;
  /** Supplier's city name */
  city?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Supplier's company */
  company?: Maybe<Scalars['String']>;
  /** Supplier's ISO country code */
  country?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Supplier's email address */
  email?: Maybe<Scalars['String']>;
  /** Supplier's first name */
  firstName?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Supplier's last name */
  lastName?: Maybe<Scalars['String']>;
  /** Supplier's lead time */
  leadTime?: Maybe<Scalars['Int']>;
  /** Supplier's first line */
  line1?: Maybe<Scalars['String']>;
  /** Supplier's last line */
  line2?: Maybe<Scalars['String']>;
  /** Supplier's name */
  name?: Maybe<Scalars['String']>;
  /** Supplier's order cycle */
  orderCycle?: Maybe<Scalars['Int']>;
  /** Supplier's payment terms */
  paymentTerms?: Maybe<Scalars['Int']>;
  /** Supplier's phone */
  phone?: Maybe<Scalars['String']>;
  /** Supplier's postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Supplier's residential status */
  residential?: Maybe<Scalars['Boolean']>;
  /** Supplier's simple products */
  simpleProducts?: Maybe<Array<Maybe<SimpleProductEntity>>>;
  /** Supplier's slug */
  slug?: Maybe<Scalars['String']>;
  /** Supplier's state (or province) abbreviation */
  state?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type TaxDetail = {
  __typename?: 'TaxDetail';
  /** Tax detail's rate */
  rate?: Maybe<Scalars['BigDecimal']>;
  /** Tax detail's shipping taxed */
  shippingTaxed?: Maybe<Scalars['Boolean']>;
  /** Tax detail's state */
  state?: Maybe<State>;
};

export type TeamEntity = {
  __typename?: 'TeamEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Team's name */
  name?: Maybe<Scalars['String']>;
  /** Team's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type TrackingEvent = {
  __typename?: 'TrackingEvent';
  /** Tracking event's comment */
  comment?: Maybe<Scalars['String']>;
  /** Tracking event's location */
  location?: Maybe<Scalars['String']>;
  /** Tracking event's timestamp */
  timestamp?: Maybe<Scalars['Instant']>;
  /** Tracking event's type */
  trackingEventType?: Maybe<TrackingEventType>;
};

export type TradeShowEntity = {
  __typename?: 'TradeShowEntity';
  carts?: Maybe<Array<Maybe<CartEntity>>>;
  /** Trade show's city name */
  city?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Trade show's ISO country code */
  country?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Trade show's end date */
  endDate?: Maybe<Scalars['LocalDate']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Trade show's first line */
  line1?: Maybe<Scalars['String']>;
  /** Trade show's last line */
  line2?: Maybe<Scalars['String']>;
  /** Trade show's name */
  name?: Maybe<Scalars['String']>;
  posTerminals?: Maybe<Array<Maybe<PosTerminalEntity>>>;
  /** Trade show's number of POS units requested */
  posTerminalsRequested?: Maybe<Scalars['Long']>;
  /** Trade show's postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Trade show's pre-order end date */
  preOrderEndDate?: Maybe<Scalars['LocalDate']>;
  /** Trade show's pre-order start date */
  preOrderStartDate?: Maybe<Scalars['LocalDate']>;
  salesOrders?: Maybe<Array<Maybe<SalesOrderEntity>>>;
  /** Trade show's start date */
  startDate?: Maybe<Scalars['LocalDate']>;
  /** Trade show's state (or province) abbreviation */
  state?: Maybe<Scalars['String']>;
  /** Trade show's sales tax rate */
  taxRate?: Maybe<Scalars['BigDecimal']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Trade show's url */
  url?: Maybe<Scalars['String']>;
};

export type TransitTimeRestrictionEntity = {
  __typename?: 'TransitTimeRestrictionEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Shipping rule's slug */
  slug?: Maybe<Scalars['String']>;
  /** Transit time rule's transit time type */
  transitDays?: Maybe<Scalars['Long']>;
  /** Transit time rule's transit time type */
  transitTimeType?: Maybe<TransitTimeType>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type UserEntity = {
  __typename?: 'UserEntity';
  /** User's addresses */
  addresses?: Maybe<Array<Maybe<AddressEntity>>>;
  /** User is an admin */
  admin?: Maybe<Scalars['Boolean']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** User's email address */
  email?: Maybe<Scalars['String']>;
  /** User's email is confirmed */
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  /** User's first name */
  firstName?: Maybe<Scalars['String']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** User's last name */
  lastName?: Maybe<Scalars['String']>;
  /** User's Magento Id */
  magentoId?: Maybe<Scalars['String']>;
  /** User's net terms */
  netTerms?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** User's roles */
  roles?: Maybe<Array<Maybe<RoleType>>>;
  /** User's sales agent */
  salesAgent?: Maybe<Scalars['String']>;
  /** User's tax exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** User's type */
  userType?: Maybe<UserType>;
};

export type VariationSetEntity = {
  __typename?: 'VariationSetEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Variation set's products */
  products?: Maybe<Array<Maybe<ProductEntity>>>;
  /** Variation set's title */
  title?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type WalmartProfileEntity = {
  __typename?: 'WalmartProfileEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Walmart profile's MFN Seller SKU */
  sellerSku?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Walmart profile's WFS Seller SKU */
  wfsSellerSku?: Maybe<Scalars['String']>;
};

export type WalmartSalesOrderEntity = {
  __typename?: 'WalmartSalesOrderEntity';
  /** Order's alternate number */
  alternateOrderNumber?: Maybe<Scalars['String']>;
  /** Billing address' type */
  billingAddressType?: Maybe<AddressType>;
  /** Billing address' validation source */
  billingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Billing address' city name */
  billingCity?: Maybe<Scalars['String']>;
  /** Billing address' company */
  billingCompany?: Maybe<Scalars['String']>;
  /** Billing address' ISO country code */
  billingCountry?: Maybe<Scalars['String']>;
  /** Billing address' first name */
  billingFirstName?: Maybe<Scalars['String']>;
  /** Billing address' last name */
  billingLastName?: Maybe<Scalars['String']>;
  /** Billing address' first line */
  billingLine1?: Maybe<Scalars['String']>;
  /** Billing address' last line */
  billingLine2?: Maybe<Scalars['String']>;
  /** Billing address' postal code */
  billingPostalCode?: Maybe<Scalars['String']>;
  /** Billing address' residential status */
  billingResidential?: Maybe<Scalars['Boolean']>;
  /** Billing address' state (or province) abbreviation */
  billingState?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Sales order's comments */
  comments?: Maybe<Array<Maybe<SalesOrderCommentsEntity>>>;
  /** Order's coupon code */
  couponCode?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Walmart customer number */
  customerOrderId?: Maybe<Scalars['String']>;
  /** Order's discount total */
  discountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's email */
  email?: Maybe<Scalars['String']>;
  /** Order's fulfillment channel */
  fulfillmentChannelType?: Maybe<FulfillmentChannelType>;
  /** Sales order's fulfillment network */
  fulfillmentNetwork?: Maybe<FulfillmentNetworkType>;
  /** Order's grand total */
  grandTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's handling total */
  handlingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's hold status */
  hold?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Sales order's IP address */
  ipAddress?: Maybe<Scalars['String']>;
  /** Order's number */
  orderNumber?: Maybe<Scalars['String']>;
  /** Sales order's payment terms account */
  paymentTermsAccount?: Maybe<PaymentTermsAccountEntity>;
  /** Sales order's payments */
  payments?: Maybe<Array<Maybe<PaymentEntity>>>;
  /** Order's phone */
  phone?: Maybe<Scalars['String']>;
  /** Sales order's pickup warehouse */
  pickupWarehouse?: Maybe<WarehouseEntity>;
  /** Order's placed time */
  placedTime?: Maybe<Scalars['Instant']>;
  /** Order's product discount total */
  productDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's product tax total */
  productTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's refunds */
  refunds?: Maybe<Array<Maybe<RefundEntity>>>;
  /** Sales order's reships */
  reships?: Maybe<Array<Maybe<ReshipSalesOrderEntity>>>;
  /** Sales order's discounts */
  salesOrderDiscounts?: Maybe<Array<Maybe<SalesOrderDiscountEntity>>>;
  /** Sales order's item groups */
  salesOrderItemGroups?: Maybe<Array<Maybe<SalesOrderItemGroupEntity>>>;
  /** Sales order's items */
  salesOrderItems?: Maybe<Array<Maybe<SalesOrderItemEntity>>>;
  /** Sales order's listed items */
  salesOrderListedItems?: Maybe<Array<Maybe<SalesOrderListedItemEntity>>>;
  /** Order's type */
  salesOrderType?: Maybe<SalesOrderType>;
  /** Order's secondary alternate number */
  secondaryAlternateOrderNumber?: Maybe<Scalars['String']>;
  /** Sales order's shipments */
  shipments?: Maybe<Array<Maybe<ShipmentEntity>>>;
  /** Shipping address' type */
  shippingAddressType?: Maybe<AddressType>;
  /** Shipping address' validation source */
  shippingAddressValidationSource?: Maybe<AddressValidationSource>;
  /** Shipping address' city name */
  shippingCity?: Maybe<Scalars['String']>;
  /** Shipping address' company */
  shippingCompany?: Maybe<Scalars['String']>;
  /** Shipping address' ISO country code */
  shippingCountry?: Maybe<Scalars['String']>;
  /** Order's shipping discount total */
  shippingDiscountTotal?: Maybe<Scalars['BigDecimal']>;
  /** Shipping address' first name */
  shippingFirstName?: Maybe<Scalars['String']>;
  /** Shipping address' last name */
  shippingLastName?: Maybe<Scalars['String']>;
  /** Shipping address' first line */
  shippingLine1?: Maybe<Scalars['String']>;
  /** Shipping address' last line */
  shippingLine2?: Maybe<Scalars['String']>;
  /** Sales order's shipping method */
  shippingMethod?: Maybe<Scalars['String']>;
  /** Shipping address' postal code */
  shippingPostalCode?: Maybe<Scalars['String']>;
  /** Shipping address' residential status */
  shippingResidential?: Maybe<Scalars['Boolean']>;
  /** Shipping address' state (or province) abbreviation */
  shippingState?: Maybe<Scalars['String']>;
  /** Order's shipping tax total */
  shippingTaxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's shipping total */
  shippingTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's status */
  status?: Maybe<SalesOrderStatus>;
  /** Order's sub total */
  subTotal?: Maybe<Scalars['BigDecimal']>;
  /** Order's tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Order's tax exempt status */
  taxExempt?: Maybe<Scalars['Boolean']>;
  /** Order's tax total */
  taxTotal?: Maybe<Scalars['BigDecimal']>;
  /** Sales order's third-party account */
  thirdPartyAccount?: Maybe<Scalars['String']>;
  /** Sales order's third-party postal code */
  thirdPartyPostalCode?: Maybe<Scalars['String']>;
  /** Sales order's third-party reseller */
  thirdPartyReseller?: Maybe<Reseller>;
  /** Sales order's tradeshow */
  tradeShow?: Maybe<TradeShowEntity>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Order's user */
  user?: Maybe<UserEntity>;
};

export type WarehouseEntity = {
  __typename?: 'WarehouseEntity';
  /** Warehouse's alternate slug */
  alternateSlug?: Maybe<Scalars['String']>;
  /** Warehouse's carriers */
  carriers?: Maybe<Array<Maybe<Carrier>>>;
  /** Warehouse's city name */
  city?: Maybe<Scalars['String']>;
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Warehouse' ISO country code */
  country?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Warehouse's dropshipper */
  dropshipper?: Maybe<PartnerEntity>;
  /** Warehouse's enabled flag */
  enabled?: Maybe<Scalars['Boolean']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Warehouse's latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Warehouse's first line */
  line1?: Maybe<Scalars['String']>;
  /** Warehouse's last line */
  line2?: Maybe<Scalars['String']>;
  /** Warehouse's longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Warehouse's name */
  name?: Maybe<Scalars['String']>;
  /** Warehouse's postal code */
  postalCode?: Maybe<Scalars['String']>;
  /** Warehouse's queues */
  queues?: Maybe<Array<Maybe<QueueEntity>>>;
  /** Warehouse's slug */
  slug?: Maybe<Scalars['String']>;
  /** Warehouse's state (or province) abbreviation */
  state?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Warehouse's type */
  warehouseType?: Maybe<WarehouseType>;
  workSchedule?: Maybe<WorkScheduleEntity>;
  /** Warehouse's zones */
  zones?: Maybe<Array<Maybe<ZoneEntity>>>;
};

export type Weather = {
  __typename?: 'Weather';
  /** Weather's date */
  date?: Maybe<Scalars['LocalDate']>;
  /** Weather's high */
  high?: Maybe<Scalars['Float']>;
  /** Weather's low */
  low?: Maybe<Scalars['Float']>;
};

export type WorkScheduleEntity = {
  __typename?: 'WorkScheduleEntity';
  closedOnCoreHolidays: Scalars['Boolean'];
  closedOnUSPSHolidays: Scalars['Boolean'];
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  holidayTimes?: Maybe<Array<Maybe<HolidayTimeEntity>>>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  openCloseTimes?: Maybe<Array<Maybe<OpenCloseTimeEntity>>>;
  timezone?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
};

export type WorkstationEntity = {
  __typename?: 'WorkstationEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Workstation's key */
  machineKey?: Maybe<Scalars['String']>;
  /** Workstation's name */
  name?: Maybe<Scalars['String']>;
  /** Workstation's printers */
  printers?: Maybe<Array<Maybe<PrinterEntity>>>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Workstation's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
};

export type ZoneEntity = {
  __typename?: 'ZoneEntity';
  /** Entity's class */
  cls?: Maybe<Scalars['String']>;
  /** Entity's creation timestamp */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Entity's UUID */
  id?: Maybe<Scalars['UUID']>;
  /** Zone's name */
  name?: Maybe<Scalars['String']>;
  /** Zone's sequence */
  sequence?: Maybe<Scalars['Long']>;
  /** Zone's slug */
  slug?: Maybe<Scalars['String']>;
  /** Entity's modification timestamp */
  updatedAt?: Maybe<Scalars['Instant']>;
  /** Zone's warehouse */
  warehouse?: Maybe<WarehouseEntity>;
};

export type IGenericProductEntity = ConfigurableProductEntity | KitProductEntity | ListedProductEntity | SimpleProductEntity;

export enum AddressType {
  Commercial = 'COMMERCIAL',
  FedexShipCenter = 'FEDEX_SHIP_CENTER',
  Residential = 'RESIDENTIAL',
  Tradeshow = 'TRADESHOW',
  UpsAccessPoint = 'UPS_ACCESS_POINT',
  UpsCustomerCenter = 'UPS_CUSTOMER_CENTER',
  Warehouse = 'WAREHOUSE'
}

export enum AddressValidationSource {
  FedEx = 'FedEx',
  SmartyStreets = 'SmartyStreets',
  Stamps = 'Stamps',
  Ups = 'UPS',
  Unverified = 'Unverified'
}

export enum AuthorizationScopeType {
  Admin = 'ADMIN',
  Partner = 'PARTNER',
  User = 'USER'
}

export enum CancellationReason {
  CustomerRequested = 'CUSTOMER_REQUESTED',
  InsufficientInventory = 'INSUFFICIENT_INVENTORY',
  ShippingRestriction = 'SHIPPING_RESTRICTION',
  TooExpensive = 'TOO_EXPENSIVE',
  UndeliverableAddress = 'UNDELIVERABLE_ADDRESS',
  Unpaid = 'UNPAID',
  Weather = 'WEATHER'
}

export enum Carrier {
  Amazon = 'AMAZON',
  DavisCartage = 'DAVIS_CARTAGE',
  Dhl = 'DHL',
  Fedex = 'FEDEX',
  Generic = 'GENERIC',
  Globaltranz = 'GLOBALTRANZ',
  Lasership = 'LASERSHIP',
  Ontrac = 'ONTRAC',
  Otd = 'OTD',
  Promed = 'PROMED',
  Roadie = 'ROADIE',
  Ups = 'UPS',
  Usps = 'USPS'
}

export enum CartNotificationType {
  InsufficientInventory = 'INSUFFICIENT_INVENTORY',
  NotAvailableForStoreShowPickup = 'NOT_AVAILABLE_FOR_STORE_SHOW_PICKUP',
  RestrictedDestination = 'RESTRICTED_DESTINATION'
}

export enum Comparison {
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN'
}

export enum Country {
  Abw = 'ABW',
  Afg = 'AFG',
  Ago = 'AGO',
  Aia = 'AIA',
  Ala = 'ALA',
  Alb = 'ALB',
  And = 'AND',
  Are = 'ARE',
  Arg = 'ARG',
  Arm = 'ARM',
  Asm = 'ASM',
  Ata = 'ATA',
  Atf = 'ATF',
  Atg = 'ATG',
  Aus = 'AUS',
  Aut = 'AUT',
  Aze = 'AZE',
  Bdi = 'BDI',
  Bel = 'BEL',
  Ben = 'BEN',
  Bes = 'BES',
  Bfa = 'BFA',
  Bgd = 'BGD',
  Bgr = 'BGR',
  Bhr = 'BHR',
  Bhs = 'BHS',
  Bih = 'BIH',
  Blm = 'BLM',
  Blr = 'BLR',
  Blz = 'BLZ',
  Bmu = 'BMU',
  Bol = 'BOL',
  Bra = 'BRA',
  Brb = 'BRB',
  Brn = 'BRN',
  Btn = 'BTN',
  Bvt = 'BVT',
  Bwa = 'BWA',
  Caf = 'CAF',
  Can = 'CAN',
  Cck = 'CCK',
  Che = 'CHE',
  Chl = 'CHL',
  Chn = 'CHN',
  Civ = 'CIV',
  Cmr = 'CMR',
  Cod = 'COD',
  Cog = 'COG',
  Cok = 'COK',
  Col = 'COL',
  Com = 'COM',
  Cpv = 'CPV',
  Cri = 'CRI',
  Cub = 'CUB',
  Cuw = 'CUW',
  Cxr = 'CXR',
  Cym = 'CYM',
  Cyp = 'CYP',
  Cze = 'CZE',
  Deu = 'DEU',
  Dji = 'DJI',
  Dma = 'DMA',
  Dnk = 'DNK',
  Dom = 'DOM',
  Dza = 'DZA',
  Ecu = 'ECU',
  Egy = 'EGY',
  Eri = 'ERI',
  Esh = 'ESH',
  Esp = 'ESP',
  Est = 'EST',
  Eth = 'ETH',
  Fin = 'FIN',
  Fji = 'FJI',
  Flk = 'FLK',
  Fra = 'FRA',
  Fro = 'FRO',
  Fsm = 'FSM',
  Gab = 'GAB',
  Gbr = 'GBR',
  Geo = 'GEO',
  Ggy = 'GGY',
  Gha = 'GHA',
  Gib = 'GIB',
  Gin = 'GIN',
  Glp = 'GLP',
  Gmb = 'GMB',
  Gnb = 'GNB',
  Gnq = 'GNQ',
  Grc = 'GRC',
  Grd = 'GRD',
  Grl = 'GRL',
  Gtm = 'GTM',
  Guf = 'GUF',
  Gum = 'GUM',
  Guy = 'GUY',
  Hkg = 'HKG',
  Hmd = 'HMD',
  Hnd = 'HND',
  Hrv = 'HRV',
  Hti = 'HTI',
  Hun = 'HUN',
  Idn = 'IDN',
  Imn = 'IMN',
  Ind = 'IND',
  Iot = 'IOT',
  Irl = 'IRL',
  Irn = 'IRN',
  Irq = 'IRQ',
  Isl = 'ISL',
  Isr = 'ISR',
  Ita = 'ITA',
  Jam = 'JAM',
  Jey = 'JEY',
  Jor = 'JOR',
  Jpn = 'JPN',
  Kaz = 'KAZ',
  Ken = 'KEN',
  Kgz = 'KGZ',
  Khm = 'KHM',
  Kir = 'KIR',
  Kna = 'KNA',
  Kor = 'KOR',
  Kwt = 'KWT',
  Lao = 'LAO',
  Lbn = 'LBN',
  Lbr = 'LBR',
  Lby = 'LBY',
  Lca = 'LCA',
  Lie = 'LIE',
  Lka = 'LKA',
  Lso = 'LSO',
  Ltu = 'LTU',
  Lux = 'LUX',
  Lva = 'LVA',
  Mac = 'MAC',
  Maf = 'MAF',
  Mar = 'MAR',
  Mco = 'MCO',
  Mda = 'MDA',
  Mdg = 'MDG',
  Mdv = 'MDV',
  Mex = 'MEX',
  Mhl = 'MHL',
  Mkd = 'MKD',
  Mli = 'MLI',
  Mlt = 'MLT',
  Mmr = 'MMR',
  Mne = 'MNE',
  Mng = 'MNG',
  Mnp = 'MNP',
  Moz = 'MOZ',
  Mrt = 'MRT',
  Msr = 'MSR',
  Mtq = 'MTQ',
  Mus = 'MUS',
  Mwi = 'MWI',
  Mys = 'MYS',
  Myt = 'MYT',
  Nam = 'NAM',
  Ncl = 'NCL',
  Ner = 'NER',
  Nfk = 'NFK',
  Nga = 'NGA',
  Nic = 'NIC',
  Niu = 'NIU',
  Nld = 'NLD',
  Nor = 'NOR',
  Npl = 'NPL',
  Nru = 'NRU',
  Nzl = 'NZL',
  Omn = 'OMN',
  Pak = 'PAK',
  Pan = 'PAN',
  Pcn = 'PCN',
  Per = 'PER',
  Phl = 'PHL',
  Plw = 'PLW',
  Png = 'PNG',
  Pol = 'POL',
  Pri = 'PRI',
  Prk = 'PRK',
  Prt = 'PRT',
  Pry = 'PRY',
  Pse = 'PSE',
  Pyf = 'PYF',
  Qat = 'QAT',
  Reu = 'REU',
  Rou = 'ROU',
  Rus = 'RUS',
  Rwa = 'RWA',
  Sau = 'SAU',
  Sdn = 'SDN',
  Sen = 'SEN',
  Sgp = 'SGP',
  Sgs = 'SGS',
  Shn = 'SHN',
  Sjm = 'SJM',
  Slb = 'SLB',
  Sle = 'SLE',
  Slv = 'SLV',
  Smr = 'SMR',
  Som = 'SOM',
  Spm = 'SPM',
  Srb = 'SRB',
  Ssd = 'SSD',
  Stp = 'STP',
  Sur = 'SUR',
  Svk = 'SVK',
  Svn = 'SVN',
  Swe = 'SWE',
  Swz = 'SWZ',
  Sxm = 'SXM',
  Syc = 'SYC',
  Syr = 'SYR',
  Tca = 'TCA',
  Tcd = 'TCD',
  Tgo = 'TGO',
  Tha = 'THA',
  Tjk = 'TJK',
  Tkl = 'TKL',
  Tkm = 'TKM',
  Tls = 'TLS',
  Ton = 'TON',
  Tto = 'TTO',
  Tun = 'TUN',
  Tur = 'TUR',
  Tuv = 'TUV',
  Twn = 'TWN',
  Tza = 'TZA',
  Uga = 'UGA',
  Ukr = 'UKR',
  Umi = 'UMI',
  Ury = 'URY',
  Usa = 'USA',
  Uzb = 'UZB',
  Vat = 'VAT',
  Vct = 'VCT',
  Ven = 'VEN',
  Vgb = 'VGB',
  Vir = 'VIR',
  Vnm = 'VNM',
  Vut = 'VUT',
  Wlf = 'WLF',
  Wsm = 'WSM',
  Yem = 'YEM',
  Zaf = 'ZAF',
  Zmb = 'ZMB',
  Zwe = 'ZWE'
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export enum DiscontinuationReasonType {
  ManufacturerDiscontinued = 'MANUFACTURER_DISCONTINUED',
  PoorQuality = 'POOR_QUALITY',
  RetiredSpecies = 'RETIRED_SPECIES',
  SlowMovement = 'SLOW_MOVEMENT'
}

export enum DiscountType {
  FixedDiscountOffCart = 'FIXED_DISCOUNT_OFF_CART',
  FixedRewardPointsOffCart = 'FIXED_REWARD_POINTS_OFF_CART',
  FreeShipping = 'FREE_SHIPPING',
  PercentageOff = 'PERCENTAGE_OFF'
}

export enum FulfillmentChannelType {
  DirectFulfillment = 'DirectFulfillment',
  ThirdPartyFulfillment = 'ThirdPartyFulfillment'
}

export enum FulfillmentNetworkType {
  Partner = 'PARTNER',
  Primary = 'PRIMARY'
}

export enum GiftCardTransactionType {
  Create = 'CREATE',
  Purchase = 'PURCHASE',
  Refund = 'REFUND',
  Reload = 'RELOAD'
}

export enum InventoryChangeType {
  Final = 'Final',
  Initial = 'Initial',
  OrderPlaced = 'OrderPlaced',
  ReceivedAddition = 'ReceivedAddition',
  ShelfCount = 'ShelfCount',
  ShipmentShipped = 'ShipmentShipped'
}

export enum LabelType {
  LongWithPackingList4x8 = 'LongWithPackingList4x8',
  Standard4x6 = 'Standard4x6'
}

export enum ListedProductType {
  DryGood = 'DRY_GOOD',
  LiveAnimal = 'LIVE_ANIMAL'
}

export enum ListingWeightUnit {
  G = 'G',
  Lb = 'LB',
  Oz = 'OZ'
}

export enum LiveArrivalGuaranteeState {
  Guaranteed = 'Guaranteed',
  NotApplicable = 'NotApplicable',
  NotGuaranteed = 'NotGuaranteed',
  UnableToDetermine = 'UnableToDetermine'
}

export enum MediaType {
  Image = 'Image',
  Panorama = 'Panorama',
  VimeoVideo = 'VimeoVideo',
  YouTubeVideo = 'YouTubeVideo'
}

export enum OrderType {
  ChewyMarketplace = 'CHEWY_MARKETPLACE',
  GeneralMarketplace = 'GENERAL_MARKETPLACE',
  PetcoMarketplace = 'PETCO_MARKETPLACE',
  PointOfSale = 'POINT_OF_SALE',
  PspMarketplace = 'PSP_MARKETPLACE',
  Subscription = 'SUBSCRIPTION',
  Website = 'WEBSITE'
}

export enum Packaging {
  CardboardBox = 'CARDBOARD_BOX',
  FedexEnvelope = 'FEDEX_ENVELOPE',
  FlatRateEnvelope = 'FLAT_RATE_ENVELOPE',
  Pallet = 'PALLET',
  PolyBag_12X15 = 'POLY_BAG_12X15',
  RegionalBoxA = 'REGIONAL_BOX_A',
  RegionalBoxB = 'REGIONAL_BOX_B'
}

export enum PauseReason {
  Manual = 'MANUAL',
  PaymentIssue = 'PAYMENT_ISSUE'
}

export enum PaymentStatus {
  AuthorizationExpired = 'AUTHORIZATION_EXPIRED',
  Authorized = 'AUTHORIZED',
  Authorizing = 'AUTHORIZING',
  Failed = 'FAILED',
  GatewayRejected = 'GATEWAY_REJECTED',
  ProcessorDeclined = 'PROCESSOR_DECLINED',
  Settled = 'SETTLED',
  SettlementDeclined = 'SETTLEMENT_DECLINED',
  SettlementPending = 'SETTLEMENT_PENDING',
  Settling = 'SETTLING',
  SubmittedForSettlement = 'SUBMITTED_FOR_SETTLEMENT',
  Voided = 'VOIDED'
}

export enum PrepType {
  LineItem = 'LINE_ITEM',
  Simple = 'SIMPLE'
}

export enum PromotionType {
  FixedDiscountOffEach = 'FIXED_DISCOUNT_OFF_EACH',
  PercentageDiscount = 'PERCENTAGE_DISCOUNT'
}

export enum PurchaseOrderStatus {
  Closed = 'Closed',
  Open = 'Open',
  PartiallyReceived = 'PartiallyReceived'
}

export enum PurchaseRequestStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  NeedsClarification = 'NEEDS_CLARIFICATION',
  Pending = 'PENDING',
  Purchased = 'PURCHASED',
  Received = 'RECEIVED'
}

export enum QueryCondition {
  Eq = 'eq',
  Ge = 'ge',
  Gt = 'gt',
  IsNull = 'isNull',
  Le = 'le',
  Lt = 'lt',
  Ne = 'ne',
  NotNull = 'notNull'
}

export enum RateQuoteFitness {
  Bad = 'BAD',
  Best = 'BEST',
  Good = 'GOOD',
  Neutral = 'NEUTRAL'
}

export enum RefundReason {
  CancelledPriorToShipment = 'CANCELLED_PRIOR_TO_SHIPMENT',
  DamagedInTransit = 'DAMAGED_IN_TRANSIT',
  DeadOnArrival = 'DEAD_ON_ARRIVAL',
  DefectiveItem = 'DEFECTIVE_ITEM',
  HealthIssue = 'HEALTH_ISSUE',
  ItemMissing = 'ITEM_MISSING',
  ItemWrongQuantity = 'ITEM_WRONG_QUANTITY',
  LostInTransit = 'LOST_IN_TRANSIT',
  Other = 'OTHER',
  PestsPresent = 'PESTS_PRESENT',
  WrongItem = 'WRONG_ITEM'
}

export enum Reseller {
  Amazon = 'AMAZON',
  Chewy = 'CHEWY',
  DavisCartage = 'DAVIS_CARTAGE',
  Dhl = 'DHL',
  Easypost = 'EASYPOST',
  Fedex = 'FEDEX',
  Generic = 'GENERIC',
  Globaltranz = 'GLOBALTRANZ',
  Lasership = 'LASERSHIP',
  Ontrac = 'ONTRAC',
  Otd = 'OTD',
  PitneyExp = 'PITNEY_EXP',
  PitneyStd = 'PITNEY_STD',
  Promed = 'PROMED',
  Sendle = 'SENDLE',
  Stamps = 'STAMPS',
  Ups = 'UPS',
  UpsAlt = 'UPS_ALT',
  UpsFr = 'UPS_FR',
  UpsGs = 'UPS_GS',
  Usps = 'USPS'
}

export enum ResellerShippingRateMode {
  FixedDomesticRate = 'FixedDomesticRate',
  FreeShipping = 'FreeShipping',
  RegionalRates = 'RegionalRates'
}

export enum ResellerTaxMode {
  MarketplaceFacilitator = 'MarketplaceFacilitator',
  Wholesale = 'Wholesale'
}

export enum ReviewStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Removed = 'REMOVED'
}

export enum RewardsTransactionType {
  Award = 'AWARD',
  Create = 'CREATE',
  Expiration = 'EXPIRATION',
  Purchase = 'PURCHASE',
  Refund = 'REFUND'
}

export enum RoleType {
  RoleApplicationManage = 'ROLE_APPLICATION_MANAGE',
  RoleGiftCardManage = 'ROLE_GIFT_CARD_MANAGE',
  RoleProductSetDepartment = 'ROLE_PRODUCT_SET_DEPARTMENT',
  RoleProductSetThirdPartyProfiles = 'ROLE_PRODUCT_SET_THIRD_PARTY_PROFILES',
  RolePurchaseRequestApprove = 'ROLE_PURCHASE_REQUEST_APPROVE',
  RolePurchaseRequestOrder = 'ROLE_PURCHASE_REQUEST_ORDER',
  RolePurchaseRequestReceive = 'ROLE_PURCHASE_REQUEST_RECEIVE',
  RoleSalesOrderRefund = 'ROLE_SALES_ORDER_REFUND',
  RoleSalesOrderReleaseHold = 'ROLE_SALES_ORDER_RELEASE_HOLD',
  RoleShipmentCancel = 'ROLE_SHIPMENT_CANCEL',
  RoleShipmentSetShippingNeeds = 'ROLE_SHIPMENT_SET_SHIPPING_NEEDS',
  RoleUserRoleManage = 'ROLE_USER_ROLE_MANAGE'
}

export enum SalesOrderStatus {
  Cancelled = 'Cancelled',
  PartiallyShipped = 'PartiallyShipped',
  PendingPayment = 'PendingPayment',
  PendingReleaseOfHold = 'PendingReleaseOfHold',
  Shipped = 'Shipped',
  Unshipped = 'Unshipped'
}

export enum SalesOrderType {
  Employee = 'Employee',
  Retail = 'Retail',
  TaxExempt = 'TaxExempt',
  Wholesale = 'Wholesale'
}

export enum SalesTaxRules {
  NotTaxable = 'NOT_TAXABLE',
  Taxable = 'TAXABLE'
}

export enum SchedulingStatusType {
  NeedsApproval = 'NeedsApproval',
  NeedsPicked = 'NeedsPicked',
  NeedsShipped = 'NeedsShipped',
  ScheduledInFuture = 'ScheduledInFuture',
  ToReschedule = 'ToReschedule',
  Unscheduled = 'Unscheduled',
  WeatherHold = 'WeatherHold'
}

export enum Service {
  AmazonGround = 'AMAZON_GROUND',
  DavisCartageLtl = 'DAVIS_CARTAGE_LTL',
  FedexExpressSaver = 'FEDEX_EXPRESS_SAVER',
  FedexFirstOvernight = 'FEDEX_FIRST_OVERNIGHT',
  FedexGround = 'FEDEX_GROUND',
  FedexHomeDelivery = 'FEDEX_HOME_DELIVERY',
  FedexInternationalEconomy = 'FEDEX_INTERNATIONAL_ECONOMY',
  FedexInternationalGround = 'FEDEX_INTERNATIONAL_GROUND',
  FedexPriorityOvernight = 'FEDEX_PRIORITY_OVERNIGHT',
  FedexSmartPost = 'FEDEX_SMART_POST',
  FedexStandardOvernight = 'FEDEX_STANDARD_OVERNIGHT',
  FedexTwoDay = 'FEDEX_TWO_DAY',
  GenericGround = 'GENERIC_GROUND',
  GenericInternational = 'GENERIC_INTERNATIONAL',
  GenericOvernight = 'GENERIC_OVERNIGHT',
  GenericTwoDay = 'GENERIC_TWO_DAY',
  GlobaltranzLtl = 'GLOBALTRANZ_LTL',
  GlobaltranzLtlLiftgate = 'GLOBALTRANZ_LTL_LIFTGATE',
  GlobaltranzLtlTradeshow = 'GLOBALTRANZ_LTL_TRADESHOW',
  LasershipGround = 'LASERSHIP_GROUND',
  OntracGround = 'ONTRAC_GROUND',
  OtdFloridaOvernight = 'OTD_FLORIDA_OVERNIGHT',
  PromedLtl = 'PROMED_LTL',
  PromedOvernight = 'PROMED_OVERNIGHT',
  RoadieNextDay = 'ROADIE_NEXT_DAY',
  RoadieSameDay = 'ROADIE_SAME_DAY',
  RoadieTwoDay = 'ROADIE_TWO_DAY',
  UpsGround = 'UPS_GROUND',
  UpsNextDayAir = 'UPS_NEXT_DAY_AIR',
  UpsNextDayAirSaver = 'UPS_NEXT_DAY_AIR_SAVER',
  UpsSecondDayAir = 'UPS_SECOND_DAY_AIR',
  UpsSurePost = 'UPS_SURE_POST',
  UpsThreeDaySelect = 'UPS_THREE_DAY_SELECT',
  UpsWorldwideExpedited = 'UPS_WORLDWIDE_EXPEDITED',
  UpsWorldwideExpress = 'UPS_WORLDWIDE_EXPRESS',
  UpsWorldwideExpressPlus = 'UPS_WORLDWIDE_EXPRESS_PLUS',
  UspsFirstClassMail = 'USPS_FIRST_CLASS_MAIL',
  UspsGroundAdvantage = 'USPS_GROUND_ADVANTAGE',
  UspsParcelSelect = 'USPS_PARCEL_SELECT',
  UspsParcelSelectLightweight = 'USPS_PARCEL_SELECT_LIGHTWEIGHT',
  UspsPriorityMail = 'USPS_PRIORITY_MAIL',
  UspsPriorityMailExpress = 'USPS_PRIORITY_MAIL_EXPRESS',
  UspsPriorityMailInternational = 'USPS_PRIORITY_MAIL_INTERNATIONAL'
}

export enum ServiceType {
  FreightLtl = 'FreightLTL',
  Ground = 'Ground',
  InternationalExpress = 'InternationalExpress',
  InternationalPriority = 'InternationalPriority',
  InternationalStandard = 'InternationalStandard',
  OvernightEarly = 'OvernightEarly',
  OvernightEvening = 'OvernightEvening',
  OvernightMorning = 'OvernightMorning',
  PostOfficeLastMile = 'PostOfficeLastMile',
  SameDay = 'SameDay',
  ThreeDay = 'ThreeDay',
  TwoDay = 'TwoDay'
}

export enum SexType {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER',
  Unknown = 'UNKNOWN'
}

export enum ShipmentStatus {
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  External = 'External',
  Labelled = 'Labelled',
  NeedsScheduling = 'NeedsScheduling',
  Pending = 'Pending',
  Shipped = 'Shipped',
  Unshipped = 'Unshipped'
}

export enum ShippingNeedsType {
  CourierDelivery = 'CourierDelivery',
  ItemNotShipped = 'ItemNotShipped',
  ScheduleWithCustomer = 'ScheduleWithCustomer',
  StoreShowPickupOnly = 'StoreShowPickupOnly'
}

export enum ShippingSoftware {
  Edi = 'EDI',
  External = 'EXTERNAL',
  PartnerPortal = 'PARTNER_PORTAL',
  Shipstation = 'SHIPSTATION'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum State {
  Aa = 'AA',
  Ae = 'AE',
  Ak = 'AK',
  Al = 'AL',
  Ap = 'AP',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Um = 'UM',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

export enum TrackingEventType {
  Delivered = 'DELIVERED',
  InTransit = 'IN_TRANSIT',
  Other = 'OTHER',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  ReceivedByCarrier = 'RECEIVED_BY_CARRIER'
}

export enum TransitTimeType {
  GroundService = 'GroundService',
  OneDayService = 'OneDayService',
  ThreeDayService = 'ThreeDayService',
  TwoDayService = 'TwoDayService'
}

export enum UserType {
  Employee = 'EMPLOYEE',
  Franchise = 'FRANCHISE',
  Regular = 'REGULAR',
  Wholesale = 'WHOLESALE'
}

export enum Vendor {
  Arachnid = 'Arachnid',
  CricketFarm = 'CricketFarm',
  DryGoods = 'DryGoods',
  FreeShipping = 'FreeShipping',
  Frogs = 'Frogs',
  GiftCards = 'GiftCards',
  LiveInsects = 'LiveInsects',
  MiceDirect = 'MiceDirect'
}

export enum WarehouseType {
  DropShipper = 'DropShipper',
  OverstockStorage = 'OverstockStorage',
  Production = 'Production',
  ThirdPartyFulfillment = 'ThirdPartyFulfillment',
  TradeshowFulfillment = 'TradeshowFulfillment',
  WebFulfillment = 'WebFulfillment'
}

export type BomItemInput = {
  quantity: Scalars['BigDecimal'];
  sku: Scalars['String'];
};

export type GraphQlLikeQueryFilterInput = {
  /** Abbreviation for 'pattern'. */
  p?: Maybe<Scalars['String']>;
  /** Field pattern. */
  pattern?: Maybe<Scalars['String']>;
};

export type GraphQlPageableInput = {
  /** The number of the page to display. */
  page?: Maybe<Scalars['Int']>;
  /** Size of each page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** Sort conditions for the current view. */
  sort?: Maybe<Array<Maybe<GraphQlSortInput>>>;
};

export type GraphQlSingleValueFilter_BooleanInput = {
  /** Abbreviation for 'condition'. */
  c?: Maybe<QueryCondition>;
  /** Filter condition (default: eq). */
  condition?: Maybe<QueryCondition>;
  /** Abbreviation for 'value'. */
  v?: Maybe<Scalars['Boolean']>;
  /** Field value. */
  value?: Maybe<Scalars['Boolean']>;
};

export type GraphQlSingleValueFilter_LongInput = {
  /** Abbreviation for 'condition'. */
  c?: Maybe<QueryCondition>;
  /** Filter condition (default: eq). */
  condition?: Maybe<QueryCondition>;
  /** Abbreviation for 'value'. */
  v?: Maybe<Scalars['Long']>;
  /** Field value. */
  value?: Maybe<Scalars['Long']>;
};

export type GraphQlSingleValueFilter_SalesOrderStatusInput = {
  /** Abbreviation for 'condition'. */
  c?: Maybe<QueryCondition>;
  /** Filter condition (default: eq). */
  condition?: Maybe<QueryCondition>;
  /** Abbreviation for 'value'. */
  v?: Maybe<SalesOrderStatus>;
  /** Field value. */
  value?: Maybe<SalesOrderStatus>;
};

export type GraphQlSingleValueFilter_SchedulingStatusTypeInput = {
  /** Abbreviation for 'condition'. */
  c?: Maybe<QueryCondition>;
  /** Filter condition (default: eq). */
  condition?: Maybe<QueryCondition>;
  /** Abbreviation for 'value'. */
  v?: Maybe<SchedulingStatusType>;
  /** Field value. */
  value?: Maybe<SchedulingStatusType>;
};

export type GraphQlSingleValueFilter_ShipmentStatusInput = {
  /** Abbreviation for 'condition'. */
  c?: Maybe<QueryCondition>;
  /** Filter condition (default: eq). */
  condition?: Maybe<QueryCondition>;
  /** Abbreviation for 'value'. */
  v?: Maybe<ShipmentStatus>;
  /** Field value. */
  value?: Maybe<ShipmentStatus>;
};

export type GraphQlSingleValueFilter_StringInput = {
  /** Abbreviation for 'condition'. */
  c?: Maybe<QueryCondition>;
  /** Filter condition (default: eq). */
  condition?: Maybe<QueryCondition>;
  /** Abbreviation for 'value'. */
  v?: Maybe<Scalars['String']>;
  /** Field value. */
  value?: Maybe<Scalars['String']>;
};

export type GraphQlSingleValueFilter_WarehouseTypeInput = {
  /** Abbreviation for 'condition'. */
  c?: Maybe<QueryCondition>;
  /** Filter condition (default: eq). */
  condition?: Maybe<QueryCondition>;
  /** Abbreviation for 'value'. */
  v?: Maybe<WarehouseType>;
  /** Field value. */
  value?: Maybe<WarehouseType>;
};

export type GraphQlSortInput = {
  /** Abbreviation for 'direction'. */
  d?: Maybe<SortDirection>;
  /** Direction of sorting (default: asc). */
  direction?: Maybe<SortDirection>;
  /** Abbreviation for 'field'. */
  f?: Maybe<Scalars['String']>;
  /** Name of the field to sort by. */
  field?: Maybe<Scalars['String']>;
};

export type GraphQlValueRangeQueryFilter_BigDecimalInput = {
  /** Abbreviation for 'from'. */
  f?: Maybe<Scalars['BigDecimal']>;
  /** Value from (inclusive). */
  from?: Maybe<Scalars['BigDecimal']>;
  /** Abbreviation for 'to'. */
  t?: Maybe<Scalars['BigDecimal']>;
  /** Value to (inclusive). */
  to?: Maybe<Scalars['BigDecimal']>;
};

export type GraphQlValueRangeQueryFilter_DoubleInput = {
  /** Abbreviation for 'from'. */
  f?: Maybe<Scalars['Float']>;
  /** Value from (inclusive). */
  from?: Maybe<Scalars['Float']>;
  /** Abbreviation for 'to'. */
  t?: Maybe<Scalars['Float']>;
  /** Value to (inclusive). */
  to?: Maybe<Scalars['Float']>;
};

export type KitItemInput = {
  id: Scalars['UUID'];
  quantity: Scalars['Long'];
};

export type PackageSizeInput = {
  height?: Maybe<Scalars['BigDecimal']>;
  length?: Maybe<Scalars['BigDecimal']>;
  weight: Scalars['BigDecimal'];
  width?: Maybe<Scalars['BigDecimal']>;
};

export type PurchaseRequestItemInput = {
  amount?: Maybe<Scalars['BigDecimal']>;
  name: Scalars['String'];
  quantity: Scalars['Long'];
};

export type SalesOrderLineItemInput = {
  id: Scalars['UUID'];
  quantity: Scalars['Long'];
};

export type ShipmentLineItemInput = {
  id: Scalars['UUID'];
  quantity: Scalars['Long'];
};

export type Cart_CheckoutFragment = (
  { __typename?: 'CartEntity' }
  & Pick<CartEntity, 'id' | 'email' | 'phone' | 'billingAddressValidationSource' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'shippingAddressValidationSource' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingResidential' | 'couponCode' | 'taxExempt' | 'subtotal' | 'cartItemsCount' | 'cartBatchItemsCount'>
  & { tradeShow?: Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'name' | 'url' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'startDate' | 'endDate'>
  )>, pickupWarehouse?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country'>
  )>, cartItemGroups?: Maybe<Array<Maybe<(
    { __typename?: 'CartItemGroupEntity' }
    & Pick<CartItemGroupEntity, 'id' | 'quantity' | 'price' | 'salePrice'>
    & { kitProduct?: Maybe<(
      { __typename?: 'KitProductEntity' }
      & Pick<KitProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'price' | 'salePrice' | 'quantityAvailableForSale'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>>, cartItems?: Maybe<Array<Maybe<(
    { __typename?: 'CartItemEntity' }
    & Pick<CartItemEntity, 'id' | 'quantity' | 'price' | 'salePrice' | 'carrierPreference' | 'deliveryDatePreference' | 'serviceTypePreference'>
    & { cartItemGroup?: Maybe<(
      { __typename?: 'CartItemGroupEntity' }
      & Pick<CartItemGroupEntity, 'id'>
    )>, simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'price' | 'salePrice' | 'quantityAvailableForSale' | 'shippingNeeds'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>>, explicitCategories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug'>
      )>>> }
    )> }
  )>>>, cartListedItems?: Maybe<Array<Maybe<(
    { __typename?: 'CartListedItemEntity' }
    & Pick<CartListedItemEntity, 'id' | 'quantity' | 'price' | 'salePrice' | 'carrierPreference' | 'deliveryDatePreference' | 'serviceTypePreference'>
    & { listing?: Maybe<(
      { __typename?: 'ListingEntity' }
      & Pick<ListingEntity, 'id' | 'identifier' | 'title' | 'comments' | 'quantity'>
      & { listingMedias?: Maybe<Array<Maybe<(
        { __typename?: 'ListingMediaEntity' }
        & Pick<ListingMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )>, listedProduct?: Maybe<(
      { __typename?: 'ListedProductEntity' }
      & Pick<ListedProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'price' | 'salePrice' | 'shippingNeeds'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>>, explicitCategories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug'>
      )>>> }
    )> }
  )>>>, cartCustomItems?: Maybe<Array<Maybe<(
    { __typename?: 'CartCustomItemEntity' }
    & Pick<CartCustomItemEntity, 'id' | 'quantity' | 'productPrice' | 'title'>
  )>>>, notifications?: Maybe<Array<Maybe<(
    { __typename?: 'CartNotification' }
    & Pick<CartNotification, 'type' | 'message'>
    & { cartItem?: Maybe<(
      { __typename?: 'CartItemEntity' }
      & Pick<CartItemEntity, 'id'>
    )>, cartItemGroup?: Maybe<(
      { __typename?: 'CartItemGroupEntity' }
      & Pick<CartItemGroupEntity, 'id'>
    )>, cartListedItem?: Maybe<(
      { __typename?: 'CartListedItemEntity' }
      & Pick<CartListedItemEntity, 'id'>
    )> }
  )>>> }
);

export type Admin_Kit_Product_EntityFragment = (
  { __typename?: 'KitProductEntity' }
  & Pick<KitProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'magentoId' | 'upc' | 'title' | 'active' | 'quantityAvailableForSale' | 'cost' | 'metaDescription' | 'metaKeywords' | 'metaTitle' | 'price' | 'specialPrice' | 'subscription' | 'wholesalePrice' | 'featured' | 'visible'>
  & { explicitAnimals?: Maybe<Array<Maybe<(
    { __typename?: 'AnimalEntity' }
    & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'AnimalEntity' }
      & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'AnimalEntity' }
        & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
      )> }
    )> }
  )>>>, explicitCategories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      )> }
    )> }
  )>>>, explicitDepartment?: Maybe<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
    )> }
  )>, kitItems?: Maybe<Array<Maybe<(
    { __typename?: 'KitItemEntity' }
    & Pick<KitItemEntity, 'id' | 'quantity' | 'altQuantity' | 'position'>
    & { altSimpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'sku' | 'title' | 'price'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )>, simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'sku' | 'title' | 'price'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>>, medias?: Maybe<Array<Maybe<(
    { __typename?: 'ProductMediaEntity' }
    & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'sequence' | 'url'>
    & { media?: Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'title'>
    )> }
  )>>>, publishedRevision?: Maybe<(
    { __typename?: 'ProductRevisionEntity' }
    & Pick<ProductRevisionEntity, 'htmlContent'>
  )>, implicitPromotions?: Maybe<Array<Maybe<(
    { __typename?: 'PromotionEntity' }
    & Pick<PromotionEntity, 'id' | 'name' | 'slug' | 'promotionType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleItemValue' | 'eligibleItemCount' | 'eligibleCartValue' | 'discountAmount'>
  )>>>, implicitDiscounts?: Maybe<Array<Maybe<(
    { __typename?: 'DiscountEntity' }
    & Pick<DiscountEntity, 'id' | 'name' | 'slug' | 'discountType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleCartValue' | 'discountAmount'>
  )>>>, amazonProfile?: Maybe<(
    { __typename?: 'AmazonProfileEntity' }
    & Pick<AmazonProfileEntity, 'id' | 'asin' | 'fbaSellerSku' | 'sellerSku'>
  )>, petcoDropshipProfile?: Maybe<(
    { __typename?: 'PetcoDropshipProfileEntity' }
    & Pick<PetcoDropshipProfileEntity, 'id' | 'description' | 'sku' | 'surcharge'>
  )>, petcoStoreDirectProfile?: Maybe<(
    { __typename?: 'PetcoStoreDirectProfileEntity' }
    & Pick<PetcoStoreDirectProfileEntity, 'id' | 'description' | 'sku'>
  )>, chewyProfile?: Maybe<(
    { __typename?: 'ChewyProfileEntity' }
    & Pick<ChewyProfileEntity, 'id' | 'description' | 'sku' | 'cost'>
  )>, walmartProfile?: Maybe<(
    { __typename?: 'WalmartProfileEntity' }
    & Pick<WalmartProfileEntity, 'id' | 'sellerSku' | 'wfsSellerSku'>
  )>, resellerProfiles?: Maybe<Array<Maybe<(
    { __typename?: 'ResellerProfileEntity' }
    & Pick<ResellerProfileEntity, 'id' | 'resellerSku' | 'price' | 'retail'>
    & { reseller?: Maybe<(
      { __typename?: 'PartnerEntity' }
      & Pick<PartnerEntity, 'id' | 'name'>
    )> }
  )>>>, crossSellItems?: Maybe<Array<Maybe<(
    { __typename?: 'CrossSellItemEntity' }
    & Pick<CrossSellItemEntity, 'id' | 'position'>
    & { crossSellProduct?: Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'title' | 'sku' | 'price' | 'quantityAvailableForSale' | 'cls'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>>, priceTiers?: Maybe<Array<Maybe<(
    { __typename?: 'PriceTierEntity' }
    & Pick<PriceTierEntity, 'id' | 'price' | 'quantity' | 'userType'>
  )>>> }
);

export type Admin_Configurable_Product_EntityFragment = (
  { __typename?: 'ConfigurableProductEntity' }
  & Pick<ConfigurableProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'magentoId' | 'metaDescription' | 'metaKeywords' | 'metaTitle' | 'upc' | 'title' | 'active' | 'quantityAvailableForSale' | 'featured' | 'visible'>
  & { configurableItems?: Maybe<Array<Maybe<(
    { __typename?: 'ConfigurableItemEntity' }
    & Pick<ConfigurableItemEntity, 'id' | 'position'>
    & { simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'sku' | 'title' | 'price' | 'quantityAvailableForSale'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>>, explicitAnimals?: Maybe<Array<Maybe<(
    { __typename?: 'AnimalEntity' }
    & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'AnimalEntity' }
      & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'AnimalEntity' }
        & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
      )> }
    )> }
  )>>>, explicitCategories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      )> }
    )> }
  )>>>, explicitDepartment?: Maybe<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
    )> }
  )>, medias?: Maybe<Array<Maybe<(
    { __typename?: 'ProductMediaEntity' }
    & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'sequence' | 'url'>
    & { media?: Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'title'>
    )> }
  )>>>, publishedRevision?: Maybe<(
    { __typename?: 'ProductRevisionEntity' }
    & Pick<ProductRevisionEntity, 'htmlContent'>
  )>, implicitPromotions?: Maybe<Array<Maybe<(
    { __typename?: 'PromotionEntity' }
    & Pick<PromotionEntity, 'id' | 'name' | 'slug' | 'promotionType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleItemValue' | 'eligibleItemCount' | 'eligibleCartValue' | 'discountAmount'>
  )>>>, implicitDiscounts?: Maybe<Array<Maybe<(
    { __typename?: 'DiscountEntity' }
    & Pick<DiscountEntity, 'id' | 'name' | 'slug' | 'discountType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleCartValue' | 'discountAmount'>
  )>>> }
);

export type Customer_Sales_OrderFragment = (
  { __typename?: 'SalesOrderEntity' }
  & Pick<SalesOrderEntity, 'id' | 'orderNumber' | 'placedTime' | 'email' | 'phone' | 'status' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'subTotal' | 'discountTotal' | 'taxTotal' | 'shippingTotal' | 'handlingTotal' | 'grandTotal'>
  & { payments?: Maybe<Array<Maybe<(
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'id' | 'amount' | 'description' | 'placedAt'>
  )>>>, refunds?: Maybe<Array<Maybe<(
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'id' | 'grandTotal' | 'placedTime' | 'refundNumber'>
    & { refundItems?: Maybe<Array<Maybe<(
      { __typename?: 'RefundItemEntity' }
      & Pick<RefundItemEntity, 'id' | 'quantity'>
      & { salesOrderItem?: Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'id' | 'name'>
      )> }
    )>>> }
  )>>>, salesOrderListedItems?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderListedItemEntity' }
    & Pick<SalesOrderListedItemEntity, 'id' | 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
    & { listedProduct?: Maybe<(
      { __typename?: 'ListedProductEntity' }
      & Pick<ListedProductEntity, 'id' | 'cls' | 'slug' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'url'>
      )>>>, userReview?: Maybe<(
        { __typename?: 'ReviewEntity' }
        & User_ReviewFragment
      )> }
    )>, listing?: Maybe<(
      { __typename?: 'ListingEntity' }
      & Pick<ListingEntity, 'identifier' | 'title'>
      & { listingMedias?: Maybe<Array<Maybe<(
        { __typename?: 'ListingMediaEntity' }
        & Pick<ListingMediaEntity, 'url'>
      )>>> }
    )> }
  )>>>, salesOrderItems?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderItemEntity' }
    & Pick<SalesOrderItemEntity, 'id' | 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
    & { simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'cls' | 'slug' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'url'>
      )>>>, userReview?: Maybe<(
        { __typename?: 'ReviewEntity' }
        & User_ReviewFragment
      )> }
    )>, salesOrderItemGroup?: Maybe<(
      { __typename?: 'SalesOrderItemGroupEntity' }
      & Pick<SalesOrderItemGroupEntity, 'id'>
    )> }
  )>>>, salesOrderItemGroups?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderItemGroupEntity' }
    & Pick<SalesOrderItemGroupEntity, 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
    & { salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'name' | 'quantity'>
    )>>>, kitProduct?: Maybe<(
      { __typename?: 'KitProductEntity' }
      & Pick<KitProductEntity, 'id' | 'cls' | 'slug' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'url'>
      )>>>, userReview?: Maybe<(
        { __typename?: 'ReviewEntity' }
        & User_ReviewFragment
      )> }
    )> }
  )>>>, shipments?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Pick<ShipmentEntity, 'id' | 'shipmentNumber' | 'shipmentStatus' | 'carrier' | 'service' | 'trackingNos' | 'trackingUrls'>
    & { shipmentListedItems?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentListedItemEntity' }
      & Pick<ShipmentListedItemEntity, 'quantity'>
      & { salesOrderListedItem?: Maybe<(
        { __typename?: 'SalesOrderListedItemEntity' }
        & Pick<SalesOrderListedItemEntity, 'name'>
      )> }
    )>>>, shipmentItems?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentItemEntity' }
      & Pick<ShipmentItemEntity, 'quantity'>
      & { salesOrderItem?: Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'name'>
      )> }
    )>>> }
  )>>> }
);

export type Supplier_EntityFragment = (
  { __typename?: 'SupplierEntity' }
  & Pick<SupplierEntity, 'id' | 'firstName' | 'lastName' | 'company' | 'name' | 'phone' | 'email' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'addressType' | 'addressValidationSource' | 'createdAt' | 'leadTime' | 'orderCycle' | 'residential' | 'slug'>
  & { simpleProducts?: Maybe<Array<Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Pick<SimpleProductEntity, 'id' | 'sku' | 'title' | 'active' | 'price' | 'quantityAvailableForSale'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'url'>
    )>>> }
  )>>> }
);

export type Brand_EntityFragment = (
  { __typename?: 'BrandEntity' }
  & Pick<BrandEntity, 'id' | 'name' | 'slug'>
  & { products?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'cls' | 'sku' | 'title' | 'active' | 'price' | 'quantityAvailableForSale'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'url'>
    )>>> }
  )>>> }
);

export type Admin_Sales_OrderFragment = (
  { __typename?: 'SalesOrderEntity' }
  & Pick<SalesOrderEntity, 'id' | 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'placedTime' | 'email' | 'phone' | 'ipAddress' | 'status' | 'hold' | 'couponCode' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingAddressValidationSource' | 'salesOrderType' | 'subTotal' | 'discountTotal' | 'taxCode' | 'taxTotal' | 'shippingTotal' | 'handlingTotal' | 'grandTotal'>
  & { user?: Maybe<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'id' | 'email' | 'firstName' | 'lastName'>
  )>, paymentTermsAccount?: Maybe<(
    { __typename?: 'PaymentTermsAccountEntity' }
    & Pick<PaymentTermsAccountEntity, 'id'>
  )>, salesOrderListedItems?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderListedItemEntity' }
    & Pick<SalesOrderListedItemEntity, 'id' | 'sku' | 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
    & { listedProduct?: Maybe<(
      { __typename?: 'ListedProductEntity' }
      & Pick<ListedProductEntity, 'slug' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'url'>
      )>>> }
    )>, listing?: Maybe<(
      { __typename?: 'ListingEntity' }
      & Pick<ListingEntity, 'identifier' | 'title'>
      & { listingMedias?: Maybe<Array<Maybe<(
        { __typename?: 'ListingMediaEntity' }
        & Pick<ListingMediaEntity, 'url'>
      )>>> }
    )> }
  )>>>, salesOrderItemGroups?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderItemGroupEntity' }
    & Pick<SalesOrderItemGroupEntity, 'id' | 'lineAmount' | 'quantity' | 'sku' | 'name' | 'unitAmount'>
    & { salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'sku' | 'name' | 'quantity'>
    )>>>, kitProduct?: Maybe<(
      { __typename?: 'KitProductEntity' }
      & Pick<KitProductEntity, 'sku' | 'title'>
    )> }
  )>>>, salesOrderItems?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderItemEntity' }
    & Pick<SalesOrderItemEntity, 'id' | 'sku' | 'name' | 'quantity' | 'unitAmount' | 'discountAmount' | 'lineAmount'>
    & { simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'quantityAvailableForSale'>
    )>, salesOrderItemGroup?: Maybe<(
      { __typename?: 'SalesOrderItemGroupEntity' }
      & Pick<SalesOrderItemGroupEntity, 'id'>
    )> }
  )>>>, shipments?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Pick<ShipmentEntity, 'id' | 'shipmentNumber' | 'shipmentStatus' | 'estimatedShipDate' | 'estimatedDeliveryDateStart' | 'estimatedDeliveryDateEnd' | 'estimatedWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualWeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'placedAt' | 'printedStart' | 'printedEnd' | 'shippedAt' | 'carrier' | 'service' | 'shippingNeeds'>
    & { departingWarehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'id' | 'name'>
    )>, originWarehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'id' | 'name'>
    )>, shipmentListedItems?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentListedItemEntity' }
      & Pick<ShipmentListedItemEntity, 'id' | 'quantity'>
      & { salesOrderListedItem?: Maybe<(
        { __typename?: 'SalesOrderListedItemEntity' }
        & Pick<SalesOrderListedItemEntity, 'id' | 'sku' | 'name'>
      )> }
    )>>>, shipmentItems?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentItemEntity' }
      & Pick<ShipmentItemEntity, 'id' | 'quantity'>
      & { salesOrderItem?: Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'id' | 'sku' | 'name'>
      )> }
    )>>> }
  )>>>, refunds?: Maybe<Array<Maybe<(
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'id' | 'refundNumber' | 'repFirstName' | 'subTotal' | 'productDiscountTotal' | 'productTaxTotal' | 'shippingTotal' | 'handlingTotal' | 'shippingDiscountTotal' | 'shippingTaxTotal' | 'grandTotal'>
  )>>>, reships?: Maybe<Array<Maybe<(
    { __typename?: 'ReshipSalesOrderEntity' }
    & Pick<ReshipSalesOrderEntity, 'id' | 'status' | 'orderNumber'>
  )>>>, payments?: Maybe<Array<Maybe<(
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'id' | 'transactionId' | 'paymentStatus' | 'placedAt' | 'amount' | 'description'>
  )>>>, comments?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderCommentsEntity' }
    & Pick<SalesOrderCommentsEntity, 'id' | 'time' | 'name' | 'comment'>
  )>>> }
);

export type Admin_Shipment_EntityFragment = (
  { __typename?: 'ShipmentEntity' }
  & Pick<ShipmentEntity, 'id' | 'cls' | 'shipmentNumber' | 'shipmentStatus' | 'shippingNeeds' | 'actualShippingCost' | 'estimatedShippingCost' | 'actualReseller' | 'estimatedReseller' | 'actualCarrier' | 'estimatedCarrier' | 'carrier' | 'carrierPreference' | 'actualService' | 'estimatedService' | 'estimatedOptions' | 'actualOptions' | 'service' | 'estimatedWeight' | 'actualWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'placedAt' | 'estimatedShipDate' | 'estimatedDeliveryDateStart' | 'estimatedDeliveryDateEnd' | 'shippedAt' | 'shippedBy' | 'cancelledAt' | 'cancelledBy' | 'cancellationReason' | 'trackingNos' | 'trackingUrls' | 'email' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'residential' | 'addressType' | 'addressValidationSource' | 'downloadStart' | 'printedStart' | 'printedEnd' | 'reprint'>
  & { shipmentBatch?: Maybe<(
    { __typename?: 'ShipmentBatchEntity' }
    & Pick<ShipmentBatchEntity, 'id' | 'name'>
  )>, queue?: Maybe<(
    { __typename?: 'QueueEntity' }
    & Pick<QueueEntity, 'id' | 'name'>
  )>, originWarehouse?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'name'>
  )>, departingWarehouse?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'name'>
  )>, salesOrder?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'id' | 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'placedTime' | 'status'>
  )>, shipmentListedItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentListedItemEntity' }
    & Pick<ShipmentListedItemEntity, 'id' | 'cls' | 'quantity'>
    & { salesOrderListedItem?: Maybe<(
      { __typename?: 'SalesOrderListedItemEntity' }
      & Pick<SalesOrderListedItemEntity, 'id' | 'cls' | 'sku' | 'name'>
    )> }
  )>>>, shipmentItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentItemEntity' }
    & Pick<ShipmentItemEntity, 'id' | 'cls' | 'quantity'>
    & { salesOrderItem?: Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'cls' | 'sku' | 'name'>
    )> }
  )>>>, shipmentAddons?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentAddonEntity' }
    & Pick<ShipmentAddonEntity, 'id' | 'quantity' | 'sku' | 'name'>
  )>>> }
);

export type Customer_ShipmentFragment = (
  { __typename?: 'ShipmentEntity' }
  & Pick<ShipmentEntity, 'id' | 'carrier' | 'email' | 'scheduledAt' | 'estimatedDeliveryDateStart' | 'estimatedDeliveryDateEnd' | 'service' | 'shipmentNumber' | 'shipmentStatus' | 'shippedAt' | 'scheduledBy' | 'trackingNos' | 'trackingUrls' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'residential' | 'addressType' | 'addressValidationSource' | 'shippingNeeds' | 'backorder' | 'backorderExpiry'>
  & { salesOrder?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'id' | 'orderNumber' | 'placedTime'>
  )>, shipmentListedItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentListedItemEntity' }
    & Pick<ShipmentListedItemEntity, 'id' | 'quantity'>
    & { salesOrderListedItem?: Maybe<(
      { __typename?: 'SalesOrderListedItemEntity' }
      & Pick<SalesOrderListedItemEntity, 'id' | 'name'>
    )>, listedProduct?: Maybe<(
      { __typename?: 'ListedProductEntity' }
      & Pick<ListedProductEntity, 'id' | 'slug' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>>, shipmentItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentItemEntity' }
    & Pick<ShipmentItemEntity, 'id' | 'quantity'>
    & { salesOrderItem?: Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'name'>
    )>, simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'slug' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>> }
);

export type Customer_Shipment_PublicFragment = (
  { __typename?: 'ShipmentEntity' }
  & Pick<ShipmentEntity, 'id' | 'carrier' | 'email' | 'scheduledAt' | 'estimatedDeliveryDateStart' | 'estimatedDeliveryDateEnd' | 'service' | 'shipmentNumber' | 'shipmentStatus' | 'shippedAt' | 'scheduledBy' | 'trackingNos' | 'trackingUrls' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'residential' | 'addressType' | 'addressValidationSource' | 'shippingNeeds' | 'backorder' | 'backorderExpiry'>
  & { salesOrder?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'id' | 'orderNumber' | 'placedTime'>
  )>, shipmentListedItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentListedItemEntity' }
    & Pick<ShipmentListedItemEntity, 'id' | 'quantity'>
    & { salesOrderListedItem?: Maybe<(
      { __typename?: 'SalesOrderListedItemEntity' }
      & Pick<SalesOrderListedItemEntity, 'id' | 'name'>
    )>, listedProduct?: Maybe<(
      { __typename?: 'ListedProductEntity' }
      & Pick<ListedProductEntity, 'id' | 'slug' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>>, shipmentItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentItemEntity' }
    & Pick<ShipmentItemEntity, 'id' | 'quantity'>
    & { salesOrderItem?: Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'name'>
    )>, simpleProduct?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'slug' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>> }
);

export type Admin_User_EntityFragment = (
  { __typename?: 'UserEntity' }
  & Pick<UserEntity, 'id' | 'cls' | 'magentoId' | 'email' | 'firstName' | 'lastName' | 'admin' | 'roles' | 'emailConfirmed' | 'userType' | 'taxExempt' | 'netTerms' | 'createdAt' | 'updatedAt'>
  & { addresses?: Maybe<Array<Maybe<(
    { __typename?: 'AddressEntity' }
    & Pick<AddressEntity, 'id' | 'firstName' | 'lastName' | 'cls' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'createdAt' | 'latitude' | 'longitude' | 'magentoId' | 'residential' | 'addressType' | 'addressValidationSource' | 'updatedAt'>
  )>>> }
);

export type Admin_Partner_EntityFragment = (
  { __typename?: 'PartnerEntity' }
  & Pick<PartnerEntity, 'id' | 'affiliate' | 'affiliateAttributionPeriod' | 'affiliateBiography' | 'affiliateCommissionRate' | 'dropshipper' | 'email' | 'name' | 'reseller' | 'resellerShippingRateMode' | 'resellerTaxMode' | 'slug' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'residential' | 'addressType' | 'addressValidationSource'>
  & { affiliateProductLists?: Maybe<Array<Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Pick<AffiliateProductListEntity, 'id' | 'name' | 'sequence'>
  )>>> }
);

export type Admin_Affiliate_Product_ListFragment = (
  { __typename?: 'AffiliateProductListEntity' }
  & Pick<AffiliateProductListEntity, 'id' | 'htmlContent' | 'name' | 'sequence' | 'slug'>
  & { affiliateProductListItems?: Maybe<Array<Maybe<(
    { __typename?: 'AffiliateProductListItemEntity' }
    & Pick<AffiliateProductListItemEntity, 'id' | 'position'>
    & { product?: Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'price' | 'title' | 'sku' | 'quantityAvailableForSale'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'url'>
      )>>> }
    )> }
  )>>> }
);

export type Shipment_EntityFragment = (
  { __typename?: 'ShipmentEntity' }
  & Pick<ShipmentEntity, 'id' | 'placedAt' | 'cls' | 'email' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'addressValidationSource' | 'shipmentNumber' | 'shipmentStatus' | 'shippingNeeds' | 'carrier' | 'service' | 'estimatedWeight' | 'actualWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'estimatedShipDate' | 'estimatedDeliveryDateStart' | 'estimatedDeliveryDateEnd' | 'shippedAt' | 'trackingNo' | 'schedulingStatus' | 'scheduledAt' | 'scheduledBy' | 'finalApproval' | 'finalApprovalBy'>
  & { originWarehouse?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'name'>
  )>, departingWarehouse?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'name'>
  )>, salesOrder?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'salesOrderType'>
  )>, shipmentListedItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentListedItemEntity' }
    & Pick<ShipmentListedItemEntity, 'id' | 'cls' | 'quantity'>
    & { salesOrderListedItem?: Maybe<(
      { __typename?: 'SalesOrderListedItemEntity' }
      & Pick<SalesOrderListedItemEntity, 'id' | 'cls' | 'sku' | 'name'>
    )> }
  )>>>, shipmentItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentItemEntity' }
    & Pick<ShipmentItemEntity, 'id' | 'cls' | 'quantity'>
    & { salesOrderItem?: Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'cls' | 'sku' | 'name'>
    )> }
  )>>> }
);

export type Scheduled_Shipment_EntityFragment = (
  { __typename?: 'ShipmentEntity' }
  & Pick<ShipmentEntity, 'id' | 'placedAt' | 'cls' | 'email' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'addressValidationSource' | 'shipmentNumber' | 'shipmentStatus' | 'shippingNeeds' | 'carrier' | 'service' | 'estimatedWeight' | 'actualWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'estimatedShipDate' | 'estimatedDeliveryDateStart' | 'estimatedDeliveryDateEnd' | 'shippedAt' | 'trackingNo' | 'schedulingStatus' | 'scheduledAt' | 'scheduledBy' | 'finalApproval' | 'finalApprovalBy'>
  & { originWarehouse?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'name'>
  )>, departingWarehouse?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'name'>
  )>, shipmentListedItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentListedItemEntity' }
    & Pick<ShipmentListedItemEntity, 'id' | 'cls' | 'quantity'>
    & { salesOrderListedItem?: Maybe<(
      { __typename?: 'SalesOrderListedItemEntity' }
      & Pick<SalesOrderListedItemEntity, 'id' | 'cls' | 'sku' | 'name'>
    )> }
  )>>>, shipmentItems?: Maybe<Array<Maybe<(
    { __typename?: 'ShipmentItemEntity' }
    & Pick<ShipmentItemEntity, 'id' | 'cls' | 'quantity'>
    & { salesOrderItem?: Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'cls' | 'sku' | 'name'>
    )> }
  )>>>, salesOrder?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'salesOrderType'>
    & { shipments?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentEntity' }
      & Pick<ShipmentEntity, 'id' | 'shipmentNumber' | 'shipmentStatus' | 'shippingNeeds' | 'carrier' | 'service' | 'estimatedWeight' | 'actualWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'estimatedShipDate' | 'estimatedDeliveryDateStart' | 'estimatedDeliveryDateEnd' | 'shippedAt' | 'trackingNo' | 'schedulingStatus' | 'scheduledAt' | 'scheduledBy' | 'finalApproval' | 'finalApprovalBy'>
      & { departingWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, shipmentListedItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentListedItemEntity' }
        & Pick<ShipmentListedItemEntity, 'id' | 'cls' | 'quantity'>
        & { salesOrderListedItem?: Maybe<(
          { __typename?: 'SalesOrderListedItemEntity' }
          & Pick<SalesOrderListedItemEntity, 'id' | 'cls' | 'sku' | 'name'>
        )> }
      )>>>, shipmentItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentItemEntity' }
        & Pick<ShipmentItemEntity, 'id' | 'cls' | 'quantity'>
        & { salesOrderItem?: Maybe<(
          { __typename?: 'SalesOrderItemEntity' }
          & Pick<SalesOrderItemEntity, 'id' | 'cls' | 'sku' | 'name'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type AddressCreateMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type AddressCreateMutation = (
  { __typename?: 'Mutation' }
  & { addressCreate?: Maybe<(
    { __typename?: 'AddressEntity' }
    & Pick<AddressEntity, 'id' | 'cls' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country'>
  )> }
);

export type AddressDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AddressDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addressDelete'>
);

export type AddressListQueryVariables = Exact<{ [key: string]: never; }>;


export type AddressListQuery = (
  { __typename?: 'Query' }
  & { addressList?: Maybe<Array<Maybe<(
    { __typename?: 'AddressEntity' }
    & Pick<AddressEntity, 'id' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'addressType' | 'addressValidationSource' | 'residential'>
  )>>> }
);

export type AddressUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type AddressUpdateMutation = (
  { __typename?: 'Mutation' }
  & { addressUpdate?: Maybe<(
    { __typename?: 'AddressEntity' }
    & Pick<AddressEntity, 'id' | 'cls' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country'>
  )> }
);

export type AddressVerifyMutationVariables = Exact<{
  id: Scalars['UUID'];
  commit: Scalars['Boolean'];
}>;


export type AddressVerifyMutation = (
  { __typename?: 'Mutation' }
  & { addressVerify?: Maybe<(
    { __typename?: 'AddressEntity' }
    & Pick<AddressEntity, 'id' | 'cls' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'addressValidationSource'>
  )> }
);

export type AmazonSalesOrderRefreshMutationVariables = Exact<{
  amazonId: Scalars['String'];
}>;


export type AmazonSalesOrderRefreshMutation = (
  { __typename?: 'Mutation' }
  & { amazonSalesOrderRefresh?: Maybe<(
    { __typename?: 'AmazonSalesOrderEntity' }
    & Pick<AmazonSalesOrderEntity, 'id' | 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'placedTime' | 'email' | 'phone' | 'ipAddress' | 'status' | 'hold' | 'couponCode' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingAddressValidationSource' | 'salesOrderType' | 'subTotal' | 'discountTotal' | 'taxCode' | 'taxTotal' | 'shippingTotal' | 'handlingTotal' | 'grandTotal'>
    & { salesOrderListedItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderListedItemEntity' }
      & Pick<SalesOrderListedItemEntity, 'id' | 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
      & { listedProduct?: Maybe<(
        { __typename?: 'ListedProductEntity' }
        & Pick<ListedProductEntity, 'slug' | 'title'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'url'>
        )>>> }
      )>, listing?: Maybe<(
        { __typename?: 'ListingEntity' }
        & Pick<ListingEntity, 'identifier' | 'title'>
        & { listingMedias?: Maybe<Array<Maybe<(
          { __typename?: 'ListingMediaEntity' }
          & Pick<ListingMediaEntity, 'url'>
        )>>> }
      )> }
    )>>>, salesOrderItemGroups?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemGroupEntity' }
      & Pick<SalesOrderItemGroupEntity, 'id' | 'lineAmount' | 'quantity' | 'unitAmount'>
      & { salesOrderItems?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'sku' | 'name' | 'quantity'>
      )>>>, kitProduct?: Maybe<(
        { __typename?: 'KitProductEntity' }
        & Pick<KitProductEntity, 'sku' | 'title'>
      )> }
    )>>>, salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'sku' | 'name' | 'quantity' | 'unitAmount' | 'discountAmount' | 'lineAmount'>
      & { salesOrderItemGroup?: Maybe<(
        { __typename?: 'SalesOrderItemGroupEntity' }
        & Pick<SalesOrderItemGroupEntity, 'id'>
      )> }
    )>>>, shipments?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentEntity' }
      & Pick<ShipmentEntity, 'id' | 'shipmentNumber' | 'shipmentStatus' | 'estimatedShipDate' | 'estimatedDeliveryDateStart' | 'estimatedDeliveryDateEnd' | 'estimatedWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualWeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'placedAt' | 'printedStart' | 'printedEnd' | 'shippedAt' | 'carrier' | 'service' | 'shippingNeeds'>
      & { departingWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, originWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, shipmentListedItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentListedItemEntity' }
        & Pick<ShipmentListedItemEntity, 'id' | 'quantity'>
        & { salesOrderListedItem?: Maybe<(
          { __typename?: 'SalesOrderListedItemEntity' }
          & Pick<SalesOrderListedItemEntity, 'id' | 'sku' | 'name'>
        )> }
      )>>>, shipmentItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentItemEntity' }
        & Pick<ShipmentItemEntity, 'id' | 'quantity'>
        & { salesOrderItem?: Maybe<(
          { __typename?: 'SalesOrderItemEntity' }
          & Pick<SalesOrderItemEntity, 'id' | 'sku' | 'name'>
        )> }
      )>>> }
    )>>>, payments?: Maybe<Array<Maybe<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'id' | 'transactionId' | 'paymentStatus' | 'placedAt' | 'amount' | 'description'>
    )>>>, comments?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderCommentsEntity' }
      & Pick<SalesOrderCommentsEntity, 'id' | 'time' | 'name' | 'comment'>
    )>>> }
  )> }
);

export type AnimalListQueryVariables = Exact<{ [key: string]: never; }>;


export type AnimalListQuery = (
  { __typename?: 'Query' }
  & { animalList?: Maybe<(
    { __typename?: 'GraphQLPage_AnimalEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'AnimalEntity' }
      & Pick<AnimalEntity, 'id' | 'slug' | 'name' | 'routePath'>
      & { parent?: Maybe<(
        { __typename?: 'AnimalEntity' }
        & Pick<AnimalEntity, 'id'>
      )> }
    )>>> }
  )> }
);

export type AssistedAddressCreateMutationVariables = Exact<{
  userId: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type AssistedAddressCreateMutation = (
  { __typename?: 'Mutation' }
  & { assistedAddressCreate?: Maybe<(
    { __typename?: 'AddressEntity' }
    & Pick<AddressEntity, 'id' | 'cls' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'residential'>
  )> }
);

export type AssistedAddressDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;


export type AssistedAddressDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assistedAddressDelete'>
);

export type AssistedAddressUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type AssistedAddressUpdateMutation = (
  { __typename?: 'Mutation' }
  & { assistedAddressUpdate?: Maybe<(
    { __typename?: 'AddressEntity' }
    & Pick<AddressEntity, 'id' | 'cls' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'residential'>
  )> }
);

export type AssistedCartAddCustomProductMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  unitPrice: Scalars['BigDecimal'];
  quantity: Scalars['Long'];
  weight: Scalars['BigDecimal'];
  title: Scalars['String'];
}>;


export type AssistedCartAddCustomProductMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartAddCustomProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartAddKitProductMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type AssistedCartAddKitProductMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartAddKitProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartAddProductsMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
  kitProductIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
  listingIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
}>;


export type AssistedCartAddProductsMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartAddProducts?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartAddSimpleProductMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type AssistedCartAddSimpleProductMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartAddSimpleProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartChangeQuantityKitProductMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type AssistedCartChangeQuantityKitProductMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartChangeQuantityKitProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartChangeQuantityListedProductMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  listingId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type AssistedCartChangeQuantityListedProductMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartChangeQuantityListedProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartChangeQuantitySimpleProductMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type AssistedCartChangeQuantitySimpleProductMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartChangeQuantitySimpleProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartCheckoutMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  method: Scalars['String'];
  deviceData?: Maybe<Scalars['String']>;
  nonce?: Maybe<Scalars['String']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
  customDiscount?: Maybe<Scalars['BigDecimal']>;
  skipAvs?: Maybe<Scalars['Boolean']>;
  giftCardCode?: Maybe<Scalars['String']>;
}>;


export type AssistedCartCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartCheckout?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type AssistedCartClearBillingAddressMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type AssistedCartClearBillingAddressMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartClearBillingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartClearCarrierPreferenceMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
}>;


export type AssistedCartClearCarrierPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartClearCarrierPreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartClearCouponCodeMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type AssistedCartClearCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartClearCouponCode?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartClearDeliveryDatePreferenceMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
}>;


export type AssistedCartClearDeliveryDatePreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartClearDeliveryDatePreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartClearServiceTypePreferenceMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
}>;


export type AssistedCartClearServiceTypePreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartClearServiceTypePreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartClearShippingAddressMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type AssistedCartClearShippingAddressMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartClearShippingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartClearTaxExemptMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type AssistedCartClearTaxExemptMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartClearTaxExempt?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartGetEarliestDeliveryDateMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type AssistedCartGetEarliestDeliveryDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assistedCartGetEarliestDeliveryDate'>
);

export type AssistedCartInfoMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type AssistedCartInfoMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartInfo?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartLookupMutationVariables = Exact<{
  userId?: Maybe<Scalars['UUID']>;
}>;


export type AssistedCartLookupMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartLookup?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartNewCartMutationVariables = Exact<{
  userId?: Maybe<Scalars['UUID']>;
}>;


export type AssistedCartNewCartMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartNewCart?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartRemoveCustomProductMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  cartCustomItemId: Scalars['UUID'];
}>;


export type AssistedCartRemoveCustomProductMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartRemoveCustomProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetBillingAddressMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type AssistedCartSetBillingAddressMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetBillingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetBillingAddressByIdMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  addressId: Scalars['UUID'];
}>;


export type AssistedCartSetBillingAddressByIdMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetBillingAddressById?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetCarrierPreferenceMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
  carrier: Carrier;
}>;


export type AssistedCartSetCarrierPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetCarrierPreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetContactMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}>;


export type AssistedCartSetContactMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetContact?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetCouponCodeMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  couponCode: Scalars['String'];
}>;


export type AssistedCartSetCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetCouponCode?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetDeliveryDatePreferenceMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
  deliveryDatePreference: Scalars['LocalDate'];
}>;


export type AssistedCartSetDeliveryDatePreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetDeliveryDatePreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetPickupWarehouseMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  warehouseId: Scalars['UUID'];
}>;


export type AssistedCartSetPickupWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetPickupWarehouse?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetServiceTypePreferenceMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  simpleProductId: Scalars['UUID'];
  serviceType: ServiceType;
}>;


export type AssistedCartSetServiceTypePreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetServiceTypePreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetShippingAddressMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type AssistedCartSetShippingAddressMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetShippingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetShippingAddressByIdMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  addressId: Scalars['UUID'];
}>;


export type AssistedCartSetShippingAddressByIdMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetShippingAddressById?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetShippingAddressFromBillingAddressMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type AssistedCartSetShippingAddressFromBillingAddressMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetShippingAddressFromBillingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetShippingZipMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  zip: Scalars['String'];
}>;


export type AssistedCartSetShippingZipMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetShippingZip?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetTaxExemptMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type AssistedCartSetTaxExemptMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetTaxExempt?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSetTradeshowMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  tradeshowId: Scalars['UUID'];
}>;


export type AssistedCartSetTradeshowMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSetTradeshow?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type AssistedCartSummarizeMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  useRewardPoints?: Maybe<Scalars['Boolean']>;
  customDiscount?: Maybe<Scalars['BigDecimal']>;
}>;


export type AssistedCartSummarizeMutation = (
  { __typename?: 'Mutation' }
  & { assistedCartSummarize?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'id' | 'billingAddressValidationSource' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'shippingAddressValidationSource' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingResidential' | 'subTotal' | 'discountTotal' | 'taxCode' | 'taxTotal' | 'shippingTotal' | 'handlingTotal' | 'grandTotal' | 'couponCode'>
    & { salesOrderDiscounts?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderDiscountEntity' }
      & Pick<SalesOrderDiscountEntity, 'id' | 'name' | 'amount' | 'rewardPoints'>
    )>>>, salesOrderListedItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderListedItemEntity' }
      & Pick<SalesOrderListedItemEntity, 'id' | 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
      & { listedProduct?: Maybe<(
        { __typename?: 'ListedProductEntity' }
        & Pick<ListedProductEntity, 'slug' | 'title'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'url'>
        )>>> }
      )>, listing?: Maybe<(
        { __typename?: 'ListingEntity' }
        & Pick<ListingEntity, 'identifier' | 'title'>
        & { listingMedias?: Maybe<Array<Maybe<(
          { __typename?: 'ListingMediaEntity' }
          & Pick<ListingMediaEntity, 'url'>
        )>>> }
      )> }
    )>>>, salesOrderItemGroups?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemGroupEntity' }
      & Pick<SalesOrderItemGroupEntity, 'id' | 'quantity' | 'unitAmount' | 'discountAmount'>
      & { kitProduct?: Maybe<(
        { __typename?: 'KitProductEntity' }
        & Pick<KitProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'price'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )> }
    )>>>, salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'quantity' | 'unitAmount' | 'discountAmount'>
      & { salesOrderItemGroup?: Maybe<(
        { __typename?: 'SalesOrderItemGroupEntity' }
        & Pick<SalesOrderItemGroupEntity, 'id'>
      )>, simpleProduct?: Maybe<(
        { __typename?: 'SimpleProductEntity' }
        & Pick<SimpleProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'price'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )> }
    )>>>, shipments?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentEntity' }
      & Pick<ShipmentEntity, 'shippingNeeds' | 'carrier' | 'service' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'estimatedWeight' | 'estimatedShipDate' | 'estimatedDeliveryDateStart' | 'estimatedDeliveryDateEnd'>
      & { departingWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, originWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, shipmentListedItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentListedItemEntity' }
        & Pick<ShipmentListedItemEntity, 'quantity' | 'liveArrivalGuaranteeState'>
        & { listedProduct?: Maybe<(
          { __typename?: 'ListedProductEntity' }
          & Pick<ListedProductEntity, 'id'>
        )>, salesOrderListedItem?: Maybe<(
          { __typename?: 'SalesOrderListedItemEntity' }
          & { listing?: Maybe<(
            { __typename?: 'ListingEntity' }
            & Pick<ListingEntity, 'id'>
          )> }
        )> }
      )>>>, shipmentItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentItemEntity' }
        & Pick<ShipmentItemEntity, 'quantity' | 'liveArrivalGuaranteeState'>
        & { simpleProduct?: Maybe<(
          { __typename?: 'SimpleProductEntity' }
          & Pick<SimpleProductEntity, 'id'>
        )>, salesOrderItem?: Maybe<(
          { __typename?: 'SalesOrderItemEntity' }
          & Pick<SalesOrderItemEntity, 'name'>
          & { salesOrderItemGroup?: Maybe<(
            { __typename?: 'SalesOrderItemGroupEntity' }
            & Pick<SalesOrderItemGroupEntity, 'id'>
            & { kitProduct?: Maybe<(
              { __typename?: 'KitProductEntity' }
              & Pick<KitProductEntity, 'id'>
            )> }
          )> }
        )> }
      )>>> }
    )>>> }
  )> }
);

export type AssistedPaymentAccountFilterQueryVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  pageable: GraphQlPageableInput;
}>;


export type AssistedPaymentAccountFilterQuery = (
  { __typename?: 'Query' }
  & { assistedPaymentAccountFilter?: Maybe<(
    { __typename?: 'GraphQLPage_PaymentTermsAccountEntity' }
    & Pick<GraphQlPage_PaymentTermsAccountEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'PaymentTermsAccountEntity' }
      & Pick<PaymentTermsAccountEntity, 'id' | 'firstName' | 'lastName' | 'company' | 'email' | 'active' | 'netTerms' | 'balance' | 'pastDueBalance'>
    )>>> }
  )> }
);

export type AssistedPaymentAccountInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AssistedPaymentAccountInfoQuery = (
  { __typename?: 'Query' }
  & { assistedPaymentAccountInfo?: Maybe<(
    { __typename?: 'PaymentTermsAccountEntity' }
    & Pick<PaymentTermsAccountEntity, 'id' | 'firstName' | 'lastName' | 'company' | 'email' | 'active' | 'netTerms' | 'balance' | 'pastDueBalance'>
  )> }
);

export type AssistedPaymentMethodGetClientTokenMutationVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type AssistedPaymentMethodGetClientTokenMutation = (
  { __typename?: 'Mutation' }
  & { assistedPaymentMethodGetClientToken?: Maybe<(
    { __typename?: 'ClientTokenDetail' }
    & Pick<ClientTokenDetail, 'clientToken'>
  )> }
);

export type AssistedPaymentMethodListMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AssistedPaymentMethodListMutation = (
  { __typename?: 'Mutation' }
  & { assistedPaymentMethodList?: Maybe<Array<Maybe<(
    { __typename?: 'PaymentMethodDetail' }
    & Pick<PaymentMethodDetail, 'type' | 'token' | 'comment' | 'image' | 'defaultMethod'>
  )>>> }
);

export type Assisted_ReviewFragment = (
  { __typename?: 'ReviewEntity' }
  & Pick<ReviewEntity, 'id' | 'comments' | 'createdTime' | 'rating' | 'reviewer' | 'rewardPoints' | 'status' | 'title' | 'updatedTime'>
  & { medias?: Maybe<Array<Maybe<(
    { __typename?: 'MediaEntity' }
    & Pick<MediaEntity, 'id' | 'keywords' | 'title' | 'url'>
  )>>>, user?: Maybe<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'id' | 'firstName' | 'lastName'>
  )>, product?: Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'slug' | 'title' | 'cls'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'url'>
    )>>> }
  )> }
);

export type AssistedReviewApproveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AssistedReviewApproveMutation = (
  { __typename?: 'Mutation' }
  & { assistedReviewApprove?: Maybe<(
    { __typename?: 'ReviewEntity' }
    & Assisted_ReviewFragment
  )> }
);

export type AssistedReviewCreateMutationVariables = Exact<{
  userId?: Maybe<Scalars['UUID']>;
  reviewer?: Maybe<Scalars['String']>;
  productId: Scalars['UUID'];
  rating: Scalars['Long'];
  title: Scalars['String'];
  comments: Scalars['String'];
  mediaIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type AssistedReviewCreateMutation = (
  { __typename?: 'Mutation' }
  & { assistedReviewCreate?: Maybe<(
    { __typename?: 'ReviewEntity' }
    & Assisted_ReviewFragment
  )> }
);

export type AssistedReviewFilterQueryVariables = Exact<{
  status?: Maybe<ReviewStatus>;
  minRating?: Maybe<Scalars['Long']>;
  rating?: Maybe<Scalars['Long']>;
  page: GraphQlPageableInput;
  userId?: Maybe<Scalars['UUID']>;
}>;


export type AssistedReviewFilterQuery = (
  { __typename?: 'Query' }
  & { assistedReviewFilter?: Maybe<(
    { __typename?: 'GraphQLPage_ReviewEntity' }
    & Pick<GraphQlPage_ReviewEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ReviewEntity' }
      & Pick<ReviewEntity, 'id' | 'title' | 'status' | 'rating' | 'reviewer' | 'createdTime' | 'updatedTime'>
      & { product?: Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'id' | 'sku' | 'title'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'url'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type AssistedReviewInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AssistedReviewInfoQuery = (
  { __typename?: 'Query' }
  & { assistedReviewInfo?: Maybe<(
    { __typename?: 'ReviewEntity' }
    & Assisted_ReviewFragment
  )> }
);

export type AssistedReviewRejectMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AssistedReviewRejectMutation = (
  { __typename?: 'Mutation' }
  & { assistedReviewReject?: Maybe<(
    { __typename?: 'ReviewEntity' }
    & Assisted_ReviewFragment
  )> }
);

export type AssistedReviewRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AssistedReviewRemoveMutation = (
  { __typename?: 'Mutation' }
  & { assistedReviewRemove?: Maybe<(
    { __typename?: 'ReviewEntity' }
    & Assisted_ReviewFragment
  )> }
);

export type AssistedReviewUpdateMutationVariables = Exact<{
  reviewer?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  rating: Scalars['Long'];
  title: Scalars['String'];
  comments: Scalars['String'];
  mediaIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type AssistedReviewUpdateMutation = (
  { __typename?: 'Mutation' }
  & { assistedReviewUpdate?: Maybe<(
    { __typename?: 'ReviewEntity' }
    & Assisted_ReviewFragment
  )> }
);

export type AssistedRewardPointsBalanceQueryVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type AssistedRewardPointsBalanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'assistedRewardPointsBalance'>
);

export type AssistedRewardPointsCreditRewardsAccountMutationVariables = Exact<{
  userId: Scalars['UUID'];
  points: Scalars['Long'];
  comment?: Maybe<Scalars['String']>;
}>;


export type AssistedRewardPointsCreditRewardsAccountMutation = (
  { __typename?: 'Mutation' }
  & { assistedRewardPointsCreditRewardsAccount?: Maybe<(
    { __typename?: 'RewardsAccountEntity' }
    & Pick<RewardsAccountEntity, 'balance' | 'createdAt' | 'id' | 'updatedAt'>
    & { transactions?: Maybe<Array<Maybe<(
      { __typename?: 'RewardsTransactionEntity' }
      & Pick<RewardsTransactionEntity, 'amount' | 'createdAt' | 'comment' | 'id' | 'timestamp' | 'transactionType' | 'updatedAt'>
    )>>> }
  )> }
);

export type AssistedRewardPointsGetRewardsAccountQueryVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type AssistedRewardPointsGetRewardsAccountQuery = (
  { __typename?: 'Query' }
  & { assistedRewardPointsGetRewardsAccount?: Maybe<(
    { __typename?: 'RewardsAccountEntity' }
    & Pick<RewardsAccountEntity, 'balance' | 'createdAt' | 'id' | 'updatedAt'>
    & { transactions?: Maybe<Array<Maybe<(
      { __typename?: 'RewardsTransactionEntity' }
      & Pick<RewardsTransactionEntity, 'amount' | 'createdAt' | 'comment' | 'id' | 'timestamp' | 'transactionType' | 'updatedAt'>
    )>>> }
  )> }
);

export type AssistedScheduledShipmentClearApprovalMutationVariables = Exact<{
  shipmentId: Scalars['UUID'];
}>;


export type AssistedScheduledShipmentClearApprovalMutation = (
  { __typename?: 'Mutation' }
  & { assistedScheduledShipmentClearApproval?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Scheduled_Shipment_EntityFragment
  )> }
);

export type AssistedScheduledShipmentDashboardMutationVariables = Exact<{
  warehouse?: Maybe<Scalars['String']>;
}>;


export type AssistedScheduledShipmentDashboardMutation = (
  { __typename?: 'Mutation' }
  & { assistedScheduledShipmentDashboard?: Maybe<(
    { __typename?: 'SchedulingDashboard' }
    & Pick<SchedulingDashboard, 'warehouse' | 'timezone' | 'today' | 'nextOpenDate' | 'weatherHoldCount' | 'toRescheduleCount' | 'unscheduledCount' | 'scheduledInFutureCount' | 'needsApprovalCount' | 'needsPickedCount' | 'needsShippedCount'>
  )> }
);

export type AssistedScheduledShipmentFilterQueryVariables = Exact<{
  warehouse?: Maybe<Scalars['String']>;
  salesOrderCls?: Maybe<Scalars['String']>;
  schedulingStatus?: Maybe<SchedulingStatusType>;
  shipmentNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  pageable: GraphQlPageableInput;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type AssistedScheduledShipmentFilterQuery = (
  { __typename?: 'Query' }
  & { assistedScheduledShipmentFilter?: Maybe<(
    { __typename?: 'GraphQLPage_ShipmentEntity' }
    & Pick<GraphQlPage_ShipmentEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentEntity' }
      & Scheduled_Shipment_EntityFragment
    )>>> }
  )> }
);

export type AssistedScheduledShipmentHoldForWeatherMutationVariables = Exact<{
  date: Scalars['LocalDate'];
  shipmentId: Scalars['UUID'];
}>;


export type AssistedScheduledShipmentHoldForWeatherMutation = (
  { __typename?: 'Mutation' }
  & { assistedScheduledShipmentHoldForWeather?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Scheduled_Shipment_EntityFragment
  )> }
);

export type AssistedScheduledShipmentPossibleDeliveryDatesMutationVariables = Exact<{
  shipmentId: Scalars['UUID'];
}>;


export type AssistedScheduledShipmentPossibleDeliveryDatesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assistedScheduledShipmentPossibleDeliveryDates'>
);

export type AssistedScheduledShipmentScheduleMutationVariables = Exact<{
  date: Scalars['LocalDate'];
  shipmentId: Scalars['UUID'];
}>;


export type AssistedScheduledShipmentScheduleMutation = (
  { __typename?: 'Mutation' }
  & { assistedScheduledShipmentSchedule?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Scheduled_Shipment_EntityFragment
  )> }
);

export type AssistedScheduledShipmentSetApprovalMutationVariables = Exact<{
  shipmentId: Scalars['UUID'];
}>;


export type AssistedScheduledShipmentSetApprovalMutation = (
  { __typename?: 'Mutation' }
  & { assistedScheduledShipmentSetApproval?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Scheduled_Shipment_EntityFragment
  )> }
);

export type Admin_SubscriptionFragment = (
  { __typename?: 'SubscriptionEntity' }
  & Pick<SubscriptionEntity, 'id' | 'deliveryDay' | 'phone' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'billingAddressValidationSource' | 'billingAddressType' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingAddressValidationSource' | 'shippingAddressType' | 'carrierPreference' | 'serviceType' | 'pauseReason'>
  & { paymentMethod?: Maybe<(
    { __typename?: 'PaymentMethodDetail' }
    & Pick<PaymentMethodDetail, 'token' | 'type' | 'defaultMethod' | 'comment' | 'image'>
  )>, salesOrders?: Maybe<Array<Maybe<(
    { __typename?: 'SubscriptionSalesOrderEntity' }
    & Pick<SubscriptionSalesOrderEntity, 'id' | 'cls' | 'grandTotal' | 'orderNumber' | 'status'>
    & { salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'name' | 'quantity' | 'sku'>
    )>>> }
  )>>>, subscriptionItems?: Maybe<Array<Maybe<(
    { __typename?: 'SubscriptionItemEntity' }
    & Pick<SubscriptionItemEntity, 'id' | 'frequency' | 'quantity' | 'lastDeliveryDate' | 'nextDeliveryDate'>
    & { product?: Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'price' | 'sku' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>>, shippingRuleSet?: Maybe<(
        { __typename?: 'ShippingRuleSetEntity' }
        & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
          { __typename?: 'LiveArrivalGuaranteeEntity' }
          & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
        )>>>, destinationRestriction?: Maybe<(
          { __typename?: 'DestinationRestrictionEntity' }
          & Pick<DestinationRestrictionEntity, 'domesticOnly' | 'prohibitedStates'>
        )> }
      )> }
    )> }
  )>>> }
);

export type AssistedSubscriptionAddItemMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
  frequency: Scalars['Long'];
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionAddItemMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionAddItem?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionClearCarrierPreferenceMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionClearCarrierPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionClearCarrierPreference?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionClearServiceLevelMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionClearServiceLevelMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionClearServiceLevel?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionCreateMutationVariables = Exact<{
  productId: Scalars['UUID'];
  userId: Scalars['UUID'];
  quantity: Scalars['Long'];
  frequency: Scalars['Long'];
  deliveryDay?: Maybe<DayOfWeek>;
  paymentToken?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  billingFirstName: Scalars['String'];
  billingLastName: Scalars['String'];
  billingCompany?: Maybe<Scalars['String']>;
  billingLine1: Scalars['String'];
  billingLine2?: Maybe<Scalars['String']>;
  billingCity: Scalars['String'];
  billingState: Scalars['String'];
  billingPostalCode: Scalars['String'];
  billingCountry: Scalars['String'];
  shippingFirstName: Scalars['String'];
  shippingLastName: Scalars['String'];
  shippingCompany?: Maybe<Scalars['String']>;
  shippingLine1: Scalars['String'];
  shippingLine2?: Maybe<Scalars['String']>;
  shippingCity: Scalars['String'];
  shippingState: Scalars['String'];
  shippingPostalCode: Scalars['String'];
  shippingCountry: Scalars['String'];
  shippingResidential?: Maybe<Scalars['Boolean']>;
}>;


export type AssistedSubscriptionCreateMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionCreate?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionInfoQuery = (
  { __typename?: 'Query' }
  & { assistedSubscriptionInfo?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionListQueryVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionListQuery = (
  { __typename?: 'Query' }
  & { assistedSubscriptionList?: Maybe<(
    { __typename?: 'GraphQLPage_SubscriptionEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'SubscriptionEntity' }
      & Pick<SubscriptionEntity, 'id' | 'deliveryDay' | 'phone'>
      & { subscriptionItems?: Maybe<Array<Maybe<(
        { __typename?: 'SubscriptionItemEntity' }
        & Pick<SubscriptionItemEntity, 'frequency' | 'quantity' | 'lastDeliveryDate' | 'nextDeliveryDate'>
        & { product?: Maybe<(
          { __typename?: 'ProductEntity' }
          & Pick<ProductEntity, 'id' | 'title'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type AssistedSubscriptionProcessMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AssistedSubscriptionProcessMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionProcess?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionRemoveItemMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  subscriptionItemId: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionRemoveItemMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionRemoveItem?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetBillingAddressMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type AssistedSubscriptionSetBillingAddressMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetBillingAddress?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetCarrierPreferenceMutationVariables = Exact<{
  carrierPreference: Carrier;
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionSetCarrierPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetCarrierPreference?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetDeliveryDayMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  deliveryDay?: Maybe<DayOfWeek>;
}>;


export type AssistedSubscriptionSetDeliveryDayMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetDeliveryDay?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetItemFrequencyMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  subscriptionItemId: Scalars['UUID'];
  userId: Scalars['UUID'];
  frequency: Scalars['Long'];
}>;


export type AssistedSubscriptionSetItemFrequencyMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetItemFrequency?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetItemQuantityMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  subscriptionItemId: Scalars['UUID'];
  userId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type AssistedSubscriptionSetItemQuantityMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetItemQuantity?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetItemNextDeliveryMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  subscriptionItemId: Scalars['UUID'];
  userId: Scalars['UUID'];
  nextDeliveryDate: Scalars['LocalDate'];
}>;


export type AssistedSubscriptionSetItemNextDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetItemNextDelivery?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetPauseReasonMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  pauseReason?: Maybe<PauseReason>;
}>;


export type AssistedSubscriptionSetPauseReasonMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetPauseReason?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetPaymentTokenMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
  paymentToken: Scalars['String'];
}>;


export type AssistedSubscriptionSetPaymentTokenMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetPaymentToken?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetServiceLevelMutationVariables = Exact<{
  serviceLevel: ServiceType;
  subscriptionId: Scalars['UUID'];
  userId: Scalars['UUID'];
}>;


export type AssistedSubscriptionSetServiceLevelMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetServiceLevel?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AssistedSubscriptionSetShippingAddressMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type AssistedSubscriptionSetShippingAddressMutation = (
  { __typename?: 'Mutation' }
  & { assistedSubscriptionSetShippingAddress?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & Admin_SubscriptionFragment
  )> }
);

export type AuthorizationAuthCodeMutationVariables = Exact<{
  scope: AuthorizationScopeType;
  clientId: Scalars['String'];
  redirectUri: Scalars['String'];
  codeChallenge: Scalars['String'];
  codeChallengeMethod: Scalars['String'];
}>;


export type AuthorizationAuthCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'authorizationAuthCode'>
);

export type AutoprintReprintMutationVariables = Exact<{
  shipment: Scalars['UUID'];
}>;


export type AutoprintReprintMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'autoprintReprint'>
);

export type BlockCreateMutationVariables = Exact<{
  htmlContent: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
}>;


export type BlockCreateMutation = (
  { __typename?: 'Mutation' }
  & { blockCreate?: Maybe<(
    { __typename?: 'BlockEntity' }
    & Pick<BlockEntity, 'id' | 'htmlContent' | 'name' | 'slug'>
  )> }
);

export type BlockDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type BlockDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'blockDelete'>
);

export type BlockFilterQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
  pageable: GraphQlPageableInput;
}>;


export type BlockFilterQuery = (
  { __typename?: 'Query' }
  & { blockFilter?: Maybe<(
    { __typename?: 'GraphQLPage_BlockEntity' }
    & Pick<GraphQlPage_BlockEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'BlockEntity' }
      & Pick<BlockEntity, 'id' | 'name' | 'slug'>
    )>>> }
  )> }
);

export type BlockInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type BlockInfoQuery = (
  { __typename?: 'Query' }
  & { blockInfo?: Maybe<(
    { __typename?: 'BlockEntity' }
    & Pick<BlockEntity, 'id' | 'htmlContent' | 'name' | 'slug'>
  )> }
);

export type BlockUpdateMutationVariables = Exact<{
  htmlContent: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  slug: Scalars['String'];
}>;


export type BlockUpdateMutation = (
  { __typename?: 'Mutation' }
  & { blockUpdate?: Maybe<(
    { __typename?: 'BlockEntity' }
    & Pick<BlockEntity, 'id' | 'htmlContent' | 'name' | 'slug'>
  )> }
);

export type Admin_Blog_EntityFragment = (
  { __typename?: 'BlogEntity' }
  & Pick<BlogEntity, 'id' | 'cls' | 'active' | 'author' | 'createdAt' | 'metaDescription' | 'metaKeywords' | 'metaTitle' | 'slug' | 'title'>
  & { blogTopics?: Maybe<Array<Maybe<(
    { __typename?: 'BlogTopicEntity' }
    & Pick<BlogTopicEntity, 'id' | 'name' | 'slug'>
  )>>>, medias?: Maybe<Array<Maybe<(
    { __typename?: 'ContentMediaEntity' }
    & Pick<ContentMediaEntity, 'id' | 'mediaType' | 'sequence' | 'url'>
  )>>>, products?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'cls' | 'price' | 'quantityAvailableForSale' | 'sku' | 'title'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>> }
  )>>>, publishedRevision?: Maybe<(
    { __typename?: 'ContentRevisionEntity' }
    & Pick<ContentRevisionEntity, 'htmlContent'>
  )> }
);

export type BlogAddBlogTopicsMutationVariables = Exact<{
  id: Scalars['UUID'];
  blogTopicIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type BlogAddBlogTopicsMutation = (
  { __typename?: 'Mutation' }
  & { blogAddBlogTopics?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogAddImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
}>;


export type BlogAddImageMutation = (
  { __typename?: 'Mutation' }
  & { blogAddImage?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogAddProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  productIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type BlogAddProductsMutation = (
  { __typename?: 'Mutation' }
  & { blogAddProducts?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type BlogBySlugQuery = (
  { __typename?: 'Query' }
  & { blogBySlug?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Pick<BlogEntity, 'id' | 'cls' | 'createdAt' | 'slug' | 'title' | 'author' | 'metaTitle' | 'metaDescription' | 'metaKeywords'>
    & { products?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'sku' | 'slug' | 'title' | 'price' | 'salePrice' | 'countRating' | 'averageRating' | 'quantityAvailableForSale'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'url'>
      )>>> }
    )>>>, blogTopics?: Maybe<Array<Maybe<(
      { __typename?: 'BlogTopicEntity' }
      & Pick<BlogTopicEntity, 'id' | 'cls' | 'slug' | 'name'>
    )>>>, medias?: Maybe<Array<Maybe<(
      { __typename?: 'ContentMediaEntity' }
      & Pick<ContentMediaEntity, 'id' | 'sequence' | 'mediaType' | 'url'>
    )>>>, publishedRevision?: Maybe<(
      { __typename?: 'ContentRevisionEntity' }
      & Pick<ContentRevisionEntity, 'htmlContent'>
    )> }
  )> }
);

export type BlogCloneMutationVariables = Exact<{
  id: Scalars['UUID'];
  title: Scalars['String'];
  slug: Scalars['String'];
}>;


export type BlogCloneMutation = (
  { __typename?: 'Mutation' }
  & { blogClone?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogCreateMutationVariables = Exact<{
  title: Scalars['String'];
  slug: Scalars['String'];
}>;


export type BlogCreateMutation = (
  { __typename?: 'Mutation' }
  & { blogCreate?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type BlogDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'blogDelete'>
);

export type BlogFilterQueryVariables = Exact<{
  title?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
  pageable: GraphQlPageableInput;
}>;


export type BlogFilterQuery = (
  { __typename?: 'Query' }
  & { blogFilter?: Maybe<(
    { __typename?: 'GraphQLPage_BlogEntity' }
    & Pick<GraphQlPage_BlogEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'BlogEntity' }
      & Pick<BlogEntity, 'id' | 'title' | 'slug' | 'active'>
    )>>> }
  )> }
);

export type BlogInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type BlogInfoQuery = (
  { __typename?: 'Query' }
  & { blogInfo?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogListQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
  topic?: Maybe<Scalars['String']>;
}>;


export type BlogListQuery = (
  { __typename?: 'Query' }
  & { blogList?: Maybe<(
    { __typename?: 'GraphQLPage_BlogEntity' }
    & Pick<GraphQlPage_BlogEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'BlogEntity' }
      & Pick<BlogEntity, 'id' | 'cls' | 'createdAt' | 'readTime' | 'active' | 'title' | 'author' | 'slug' | 'metaDescription'>
      & { blogTopics?: Maybe<Array<Maybe<(
        { __typename?: 'BlogTopicEntity' }
        & Pick<BlogTopicEntity, 'id' | 'cls' | 'slug' | 'name'>
      )>>>, medias?: Maybe<Array<Maybe<(
        { __typename?: 'ContentMediaEntity' }
        & Pick<ContentMediaEntity, 'id' | 'cls' | 'sequence' | 'mediaType' | 'url'>
      )>>> }
    )>>> }
  )> }
);

export type BlogRemoveBlogTopicMutationVariables = Exact<{
  id: Scalars['UUID'];
  blogTopicId: Scalars['UUID'];
}>;


export type BlogRemoveBlogTopicMutation = (
  { __typename?: 'Mutation' }
  & { blogRemoveBlogTopic?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogRemoveContentMediaMutationVariables = Exact<{
  id: Scalars['UUID'];
  contentMediaId: Scalars['UUID'];
}>;


export type BlogRemoveContentMediaMutation = (
  { __typename?: 'Mutation' }
  & { blogRemoveContentMedia?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogRemoveProductMutationVariables = Exact<{
  id: Scalars['UUID'];
  productId: Scalars['UUID'];
}>;


export type BlogRemoveProductMutation = (
  { __typename?: 'Mutation' }
  & { blogRemoveProduct?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogSearchQueryVariables = Exact<{
  query: Scalars['String'];
  pageable?: Maybe<GraphQlPageableInput>;
  topic?: Maybe<Scalars['String']>;
}>;


export type BlogSearchQuery = (
  { __typename?: 'Query' }
  & { blogSearch?: Maybe<(
    { __typename?: 'GraphQLPage_BlogEntity' }
    & Pick<GraphQlPage_BlogEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'BlogEntity' }
      & Pick<BlogEntity, 'id' | 'cls' | 'createdAt' | 'readTime' | 'active' | 'title' | 'author' | 'slug' | 'metaDescription'>
      & { blogTopics?: Maybe<Array<Maybe<(
        { __typename?: 'BlogTopicEntity' }
        & Pick<BlogTopicEntity, 'id' | 'cls' | 'slug' | 'name'>
      )>>>, medias?: Maybe<Array<Maybe<(
        { __typename?: 'ContentMediaEntity' }
        & Pick<ContentMediaEntity, 'id' | 'cls' | 'sequence' | 'mediaType' | 'url'>
      )>>> }
    )>>> }
  )> }
);

export type BlogSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type BlogSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { blogSetActive?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogSetAuthorMutationVariables = Exact<{
  id: Scalars['UUID'];
  author?: Maybe<Scalars['String']>;
}>;


export type BlogSetAuthorMutation = (
  { __typename?: 'Mutation' }
  & { blogSetAuthor?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogSetContentMediaSequenceMutationVariables = Exact<{
  id: Scalars['UUID'];
  contentMediaId: Scalars['UUID'];
  sequence: Scalars['Int'];
}>;


export type BlogSetContentMediaSequenceMutation = (
  { __typename?: 'Mutation' }
  & { blogSetContentMediaSequence?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogSetMetaDescriptionMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaDescription?: Maybe<Scalars['String']>;
}>;


export type BlogSetMetaDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { blogSetMetaDescription?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogSetMetaKeywordsMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaKeywords?: Maybe<Scalars['String']>;
}>;


export type BlogSetMetaKeywordsMutation = (
  { __typename?: 'Mutation' }
  & { blogSetMetaKeywords?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogSetMetaTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaTitle?: Maybe<Scalars['String']>;
}>;


export type BlogSetMetaTitleMutation = (
  { __typename?: 'Mutation' }
  & { blogSetMetaTitle?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogSetPublishedRevisionMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type BlogSetPublishedRevisionMutation = (
  { __typename?: 'Mutation' }
  & { blogSetPublishedRevision?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogSetTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  title: Scalars['String'];
}>;


export type BlogSetTitleMutation = (
  { __typename?: 'Mutation' }
  & { blogSetTitle?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogUploadImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type BlogUploadImageMutation = (
  { __typename?: 'Mutation' }
  & { blogUploadImage?: Maybe<(
    { __typename?: 'BlogEntity' }
    & Admin_Blog_EntityFragment
  )> }
);

export type BlogTopicListQueryVariables = Exact<{ [key: string]: never; }>;


export type BlogTopicListQuery = (
  { __typename?: 'Query' }
  & { blogTopicList?: Maybe<Array<Maybe<(
    { __typename?: 'BlogTopicEntity' }
    & Pick<BlogTopicEntity, 'id' | 'name' | 'slug'>
  )>>> }
);

export type BrandCreateMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type BrandCreateMutation = (
  { __typename?: 'Mutation' }
  & { brandCreate?: Maybe<(
    { __typename?: 'BrandEntity' }
    & Brand_EntityFragment
  )> }
);

export type BrandDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type BrandDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'brandDelete'>
);

export type BrandInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type BrandInfoQuery = (
  { __typename?: 'Query' }
  & { brandInfo?: Maybe<(
    { __typename?: 'BrandEntity' }
    & Brand_EntityFragment
  )> }
);

export type BrandListQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandListQuery = (
  { __typename?: 'Query' }
  & { brandList?: Maybe<(
    { __typename?: 'GraphQLPage_BrandEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'BrandEntity' }
      & Pick<BrandEntity, 'id' | 'slug' | 'name'>
    )>>> }
  )> }
);

export type BrandSetNameMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type BrandSetNameMutation = (
  { __typename?: 'Mutation' }
  & { brandSetName?: Maybe<(
    { __typename?: 'BrandEntity' }
    & Brand_EntityFragment
  )> }
);

export type Admin_Care_Sheet_EntityFragment = (
  { __typename?: 'CareSheetEntity' }
  & Pick<CareSheetEntity, 'id' | 'cls' | 'active' | 'author' | 'createdAt' | 'metaDescription' | 'metaKeywords' | 'metaTitle' | 'slug' | 'title'>
  & { medias?: Maybe<Array<Maybe<(
    { __typename?: 'ContentMediaEntity' }
    & Pick<ContentMediaEntity, 'id' | 'mediaType' | 'sequence' | 'url'>
  )>>>, products?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'cls' | 'price' | 'quantityAvailableForSale' | 'sku' | 'title'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>> }
  )>>>, publishedRevision?: Maybe<(
    { __typename?: 'ContentRevisionEntity' }
    & Pick<ContentRevisionEntity, 'htmlContent'>
  )> }
);

export type CareSheetAddImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
}>;


export type CareSheetAddImageMutation = (
  { __typename?: 'Mutation' }
  & { careSheetAddImage?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CareSheetAddProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  productIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type CareSheetAddProductsMutation = (
  { __typename?: 'Mutation' }
  & { careSheetAddProducts?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CareSheetBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type CareSheetBySlugQuery = (
  { __typename?: 'Query' }
  & { careSheetBySlug?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Pick<CareSheetEntity, 'id' | 'cls' | 'createdAt' | 'slug' | 'title' | 'author' | 'metaTitle' | 'metaDescription' | 'metaKeywords'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ContentMediaEntity' }
      & Pick<ContentMediaEntity, 'id' | 'sequence' | 'mediaType' | 'url'>
    )>>>, products?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'sku' | 'slug' | 'title' | 'price' | 'salePrice' | 'countRating' | 'averageRating' | 'quantityAvailableForSale'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'url'>
      )>>> }
    )>>>, publishedRevision?: Maybe<(
      { __typename?: 'ContentRevisionEntity' }
      & Pick<ContentRevisionEntity, 'htmlContent'>
    )> }
  )> }
);

export type CareSheetCreateMutationVariables = Exact<{
  title: Scalars['String'];
  slug: Scalars['String'];
}>;


export type CareSheetCreateMutation = (
  { __typename?: 'Mutation' }
  & { careSheetCreate?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CareSheetDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CareSheetDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'careSheetDelete'>
);

export type CareSheetFilterQueryVariables = Exact<{
  title?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
  pageable: GraphQlPageableInput;
}>;


export type CareSheetFilterQuery = (
  { __typename?: 'Query' }
  & { careSheetFilter?: Maybe<(
    { __typename?: 'GraphQLPage_CareSheetEntity' }
    & Pick<GraphQlPage_CareSheetEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'CareSheetEntity' }
      & Pick<CareSheetEntity, 'id' | 'active' | 'title' | 'slug'>
    )>>> }
  )> }
);

export type CareSheetInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CareSheetInfoQuery = (
  { __typename?: 'Query' }
  & { careSheetInfo?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CareSheetRemoveContentMediaMutationVariables = Exact<{
  id: Scalars['UUID'];
  contentMediaId: Scalars['UUID'];
}>;


export type CareSheetRemoveContentMediaMutation = (
  { __typename?: 'Mutation' }
  & { careSheetRemoveContentMedia?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CareSheetRemoveProductMutationVariables = Exact<{
  id: Scalars['UUID'];
  productId: Scalars['UUID'];
}>;


export type CareSheetRemoveProductMutation = (
  { __typename?: 'Mutation' }
  & { careSheetRemoveProduct?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CareSheetSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type CareSheetSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { careSheetSetActive?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CareSheetSetContentMediaSequenceMutationVariables = Exact<{
  id: Scalars['UUID'];
  contentMediaId: Scalars['UUID'];
  sequence: Scalars['Int'];
}>;


export type CareSheetSetContentMediaSequenceMutation = (
  { __typename?: 'Mutation' }
  & { careSheetSetContentMediaSequence?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CareSheetSetMetaDescriptionMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaDescription?: Maybe<Scalars['String']>;
}>;


export type CareSheetSetMetaDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { careSheetSetMetaDescription?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CareSheetSetMetaKeywordsMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaKeywords?: Maybe<Scalars['String']>;
}>;


export type CareSheetSetMetaKeywordsMutation = (
  { __typename?: 'Mutation' }
  & { careSheetSetMetaKeywords?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CareSheetSetMetaTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaTitle?: Maybe<Scalars['String']>;
}>;


export type CareSheetSetMetaTitleMutation = (
  { __typename?: 'Mutation' }
  & { careSheetSetMetaTitle?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CareSheetSetPublishedRevisionMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type CareSheetSetPublishedRevisionMutation = (
  { __typename?: 'Mutation' }
  & { careSheetSetPublishedRevision?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CareSheetUploadImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type CareSheetUploadImageMutation = (
  { __typename?: 'Mutation' }
  & { careSheetUploadImage?: Maybe<(
    { __typename?: 'CareSheetEntity' }
    & Admin_Care_Sheet_EntityFragment
  )> }
);

export type CartAddListedProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  listingId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type CartAddListedProductMutation = (
  { __typename?: 'Mutation' }
  & { cartAddListedProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartAddPartialKitProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
}>;


export type CartAddPartialKitProductMutation = (
  { __typename?: 'Mutation' }
  & { cartAddPartialKitProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartAuthorizeUrlMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
  giftCardCode?: Maybe<Scalars['String']>;
  giftCardPin?: Maybe<Scalars['String']>;
  subscribeToNewsletter?: Maybe<Scalars['Boolean']>;
}>;


export type CartAuthorizeUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cartAuthorizeUrl'>
);

export type CartChangeQuantityListedProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  listingId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type CartChangeQuantityListedProductMutation = (
  { __typename?: 'Mutation' }
  & { cartChangeQuantityListedProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartNewCartMutationVariables = Exact<{ [key: string]: never; }>;


export type CartNewCartMutation = (
  { __typename?: 'Mutation' }
  & { cartNewCart?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartRemoveAllListedProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  listingId: Scalars['UUID'];
}>;


export type CartRemoveAllListedProductMutation = (
  { __typename?: 'Mutation' }
  & { cartRemoveAllListedProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartReorderMutationVariables = Exact<{
  orderId: Scalars['UUID'];
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartReorderMutation = (
  { __typename?: 'Mutation' }
  & { cartReorder?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetAffiliateMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  affiliateSlug: Scalars['String'];
}>;


export type CartSetAffiliateMutation = (
  { __typename?: 'Mutation' }
  & { cartSetAffiliate?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetShippingAddressFromBillingAddressMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartSetShippingAddressFromBillingAddressMutation = (
  { __typename?: 'Mutation' }
  & { cartSetShippingAddressFromBillingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartAddKitProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type CartAddKitProductMutation = (
  { __typename?: 'Mutation' }
  & { cartAddKitProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartAddSimpleProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type CartAddSimpleProductMutation = (
  { __typename?: 'Mutation' }
  & { cartAddSimpleProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartChangeQuantityKitProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type CartChangeQuantityKitProductMutation = (
  { __typename?: 'Mutation' }
  & { cartChangeQuantityKitProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartChangeQuantitySimpleProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type CartChangeQuantitySimpleProductMutation = (
  { __typename?: 'Mutation' }
  & { cartChangeQuantitySimpleProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartCheckoutMutationVariables = Exact<{
  cartId: Scalars['UUID'];
  method: Scalars['String'];
  deviceData?: Maybe<Scalars['String']>;
  nonce?: Maybe<Scalars['String']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
  giftCardCode?: Maybe<Scalars['String']>;
  giftCardPin?: Maybe<Scalars['String']>;
  gaClientId?: Maybe<Scalars['String']>;
  subscribeToNewsletter?: Maybe<Scalars['Boolean']>;
}>;


export type CartCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { cartCheckout?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'couponCode'>
    & { salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'discountAmount'>
      & { simpleProduct?: Maybe<(
        { __typename?: 'SimpleProductEntity' }
        & Pick<SimpleProductEntity, 'sku'>
      )> }
    )>>> }
    & Customer_Sales_OrderFragment
  )> }
);

export type CartClearBillingAddressMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartClearBillingAddressMutation = (
  { __typename?: 'Mutation' }
  & { cartClearBillingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearCarrierPreferenceMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
}>;


export type CartClearCarrierPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { cartClearCarrierPreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearCouponCodeMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartClearCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { cartClearCouponCode?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearDeliveryDatePreferenceMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
}>;


export type CartClearDeliveryDatePreferenceMutation = (
  { __typename?: 'Mutation' }
  & { cartClearDeliveryDatePreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearEmailMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartClearEmailMutation = (
  { __typename?: 'Mutation' }
  & { cartClearEmail?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearPhoneMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartClearPhoneMutation = (
  { __typename?: 'Mutation' }
  & { cartClearPhone?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearPickupWarehouseMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartClearPickupWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { cartClearPickupWarehouse?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearShippingAddressMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartClearShippingAddressMutation = (
  { __typename?: 'Mutation' }
  & { cartClearShippingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearTaxExemptMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type CartClearTaxExemptMutation = (
  { __typename?: 'Mutation' }
  & { cartClearTaxExempt?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartClearTradeshowMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartClearTradeshowMutation = (
  { __typename?: 'Mutation' }
  & { cartClearTradeshow?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartCrossSellsMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type CartCrossSellsMutation = (
  { __typename?: 'Mutation' }
  & { cartCrossSells?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'cls' | 'active' | 'title' | 'sku' | 'slug' | 'countRating' | 'averageRating' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'variationKey' | 'featured'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>> }
  )>>> }
);

export type CartGetEarliestDeliveryDateMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type CartGetEarliestDeliveryDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cartGetEarliestDeliveryDate'>
);

export type CartInfoMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
}>;


export type CartInfoMutation = (
  { __typename?: 'Mutation' }
  & { cartInfo?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartRemoveAllKitProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
}>;


export type CartRemoveAllKitProductMutation = (
  { __typename?: 'Mutation' }
  & { cartRemoveAllKitProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartRemoveAllSimpleProductMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  productId: Scalars['UUID'];
}>;


export type CartRemoveAllSimpleProductMutation = (
  { __typename?: 'Mutation' }
  & { cartRemoveAllSimpleProduct?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetAttributionTokenMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  attributionToken: Scalars['String'];
}>;


export type CartSetAttributionTokenMutation = (
  { __typename?: 'Mutation' }
  & { cartSetAttributionToken?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetBillingAddressMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type CartSetBillingAddressMutation = (
  { __typename?: 'Mutation' }
  & { cartSetBillingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetBillingAddressByIdMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  addressId: Scalars['UUID'];
}>;


export type CartSetBillingAddressByIdMutation = (
  { __typename?: 'Mutation' }
  & { cartSetBillingAddressById?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetCarrierPreferenceMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
  carrier: Carrier;
}>;


export type CartSetCarrierPreferenceMutation = (
  { __typename?: 'Mutation' }
  & { cartSetCarrierPreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetContactMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}>;


export type CartSetContactMutation = (
  { __typename?: 'Mutation' }
  & { cartSetContact?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetCouponCodeMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  couponCode: Scalars['String'];
}>;


export type CartSetCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { cartSetCouponCode?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetDeliveryDatePreferenceMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  simpleProductId: Scalars['UUID'];
  deliveryDatePreference: Scalars['LocalDate'];
}>;


export type CartSetDeliveryDatePreferenceMutation = (
  { __typename?: 'Mutation' }
  & { cartSetDeliveryDatePreference?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetEmailMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  email: Scalars['String'];
}>;


export type CartSetEmailMutation = (
  { __typename?: 'Mutation' }
  & { cartSetEmail?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetPhoneMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  phone: Scalars['String'];
}>;


export type CartSetPhoneMutation = (
  { __typename?: 'Mutation' }
  & { cartSetPhone?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetPickupWarehouseMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  warehouseId: Scalars['UUID'];
}>;


export type CartSetPickupWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { cartSetPickupWarehouse?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetShippingAddressMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type CartSetShippingAddressMutation = (
  { __typename?: 'Mutation' }
  & { cartSetShippingAddress?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetShippingAddressByIdMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  addressId: Scalars['UUID'];
}>;


export type CartSetShippingAddressByIdMutation = (
  { __typename?: 'Mutation' }
  & { cartSetShippingAddressById?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetShippingZipMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  zip: Scalars['String'];
}>;


export type CartSetShippingZipMutation = (
  { __typename?: 'Mutation' }
  & { cartSetShippingZip?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetTaxExemptMutationVariables = Exact<{
  cartId: Scalars['UUID'];
}>;


export type CartSetTaxExemptMutation = (
  { __typename?: 'Mutation' }
  & { cartSetTaxExempt?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSetTradeshowMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  tradeshowId: Scalars['UUID'];
}>;


export type CartSetTradeshowMutation = (
  { __typename?: 'Mutation' }
  & { cartSetTradeshow?: Maybe<(
    { __typename?: 'CartEntity' }
    & Cart_CheckoutFragment
  )> }
);

export type CartSummarizeMutationVariables = Exact<{
  cartId?: Maybe<Scalars['UUID']>;
  useRewardPoints?: Maybe<Scalars['Boolean']>;
}>;


export type CartSummarizeMutation = (
  { __typename?: 'Mutation' }
  & { cartSummarize?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Pick<SalesOrderEntity, 'id' | 'billingAddressValidationSource' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'shippingAddressValidationSource' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingResidential' | 'subTotal' | 'discountTotal' | 'taxCode' | 'taxTotal' | 'shippingTotal' | 'handlingTotal' | 'grandTotal' | 'couponCode'>
    & { salesOrderDiscounts?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderDiscountEntity' }
      & Pick<SalesOrderDiscountEntity, 'id' | 'name' | 'amount' | 'rewardPoints'>
    )>>>, salesOrderListedItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderListedItemEntity' }
      & Pick<SalesOrderListedItemEntity, 'id' | 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
      & { listedProduct?: Maybe<(
        { __typename?: 'ListedProductEntity' }
        & Pick<ListedProductEntity, 'slug' | 'title'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'url'>
        )>>> }
      )>, listing?: Maybe<(
        { __typename?: 'ListingEntity' }
        & Pick<ListingEntity, 'identifier' | 'title'>
        & { listingMedias?: Maybe<Array<Maybe<(
          { __typename?: 'ListingMediaEntity' }
          & Pick<ListingMediaEntity, 'url'>
        )>>> }
      )> }
    )>>>, salesOrderItemGroups?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemGroupEntity' }
      & Pick<SalesOrderItemGroupEntity, 'id' | 'quantity' | 'unitAmount' | 'discountAmount'>
      & { kitProduct?: Maybe<(
        { __typename?: 'KitProductEntity' }
        & Pick<KitProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'price'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )> }
    )>>>, salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'quantity' | 'unitAmount' | 'discountAmount'>
      & { salesOrderItemGroup?: Maybe<(
        { __typename?: 'SalesOrderItemGroupEntity' }
        & Pick<SalesOrderItemGroupEntity, 'id'>
      )>, simpleProduct?: Maybe<(
        { __typename?: 'SimpleProductEntity' }
        & Pick<SimpleProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'price'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )> }
    )>>>, shipments?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentEntity' }
      & Pick<ShipmentEntity, 'shippingNeeds' | 'carrier' | 'service' | 'estimatedShipDate' | 'estimatedDeliveryDateStart' | 'estimatedDeliveryDateEnd'>
      & { shipmentListedItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentListedItemEntity' }
        & Pick<ShipmentListedItemEntity, 'quantity' | 'liveArrivalGuaranteeState'>
        & { listedProduct?: Maybe<(
          { __typename?: 'ListedProductEntity' }
          & Pick<ListedProductEntity, 'id'>
        )>, salesOrderListedItem?: Maybe<(
          { __typename?: 'SalesOrderListedItemEntity' }
          & { listing?: Maybe<(
            { __typename?: 'ListingEntity' }
            & Pick<ListingEntity, 'id'>
          )> }
        )> }
      )>>>, shipmentItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentItemEntity' }
        & Pick<ShipmentItemEntity, 'quantity' | 'liveArrivalGuaranteeState'>
        & { simpleProduct?: Maybe<(
          { __typename?: 'SimpleProductEntity' }
          & Pick<SimpleProductEntity, 'id'>
        )>, salesOrderItem?: Maybe<(
          { __typename?: 'SalesOrderItemEntity' }
          & { salesOrderItemGroup?: Maybe<(
            { __typename?: 'SalesOrderItemGroupEntity' }
            & Pick<SalesOrderItemGroupEntity, 'id'>
            & { kitProduct?: Maybe<(
              { __typename?: 'KitProductEntity' }
              & Pick<KitProductEntity, 'id'>
            )> }
          )> }
        )> }
      )>>> }
    )>>> }
  )> }
);

export type CategoryByPathQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
  path: Scalars['String'];
}>;


export type CategoryByPathQuery = (
  { __typename?: 'Query' }
  & { categoryByPath?: Maybe<(
    { __typename?: 'CategoryResults' }
    & { category?: Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'wholesale'>
      & { publishedRevision?: Maybe<(
        { __typename?: 'CategoryRevisionEntity' }
        & Pick<CategoryRevisionEntity, 'htmlContent'>
      )>, children?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'active' | 'slug' | 'path' | 'name'>
        & { thumbnailMedia?: Maybe<(
          { __typename?: 'MediaEntity' }
          & Pick<MediaEntity, 'id' | 'url'>
        )> }
      )>>>, ancestors?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'name' | 'path'>
      )>>> }
    )>, products?: Maybe<(
      { __typename?: 'GraphQLPage_ProductEntity' }
      & Pick<GraphQlPage_ProductEntity, 'count' | 'page' | 'pageSize'>
      & { data?: Maybe<Array<Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'averageRating' | 'countRating'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type CategoryCreateMutationVariables = Exact<{
  name: Scalars['String'];
  slug: Scalars['String'];
  parentId: Scalars['UUID'];
  magentoIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type CategoryCreateMutation = (
  { __typename?: 'Mutation' }
  & { categoryCreate?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name' | 'active' | 'routePath'>
    & { parent?: Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id'>
    )> }
  )> }
);

export type CategoryDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CategoryDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'categoryDelete'>
);

export type CategoryFilterQueryVariables = Exact<{
  active?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Array<Maybe<GraphQlSortInput>> | Maybe<GraphQlSortInput>>;
}>;


export type CategoryFilterQuery = (
  { __typename?: 'Query' }
  & { categoryFilter?: Maybe<(
    { __typename?: 'GraphQLPage_CategoryEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name' | 'active' | 'routePath'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id'>
      )> }
    )>>> }
  )> }
);

export type CategoryInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CategoryInfoQuery = (
  { __typename?: 'Query' }
  & { categoryInfo?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'active' | 'slug' | 'path' | 'name' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'magentoIds'>
    & { publishedRevision?: Maybe<(
      { __typename?: 'CategoryRevisionEntity' }
      & Pick<CategoryRevisionEntity, 'htmlContent'>
    )>, thumbnailMedia?: Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'id' | 'title' | 'url'>
    )>, children?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
    )>>> }
  )> }
);

export type CategoryListQueryVariables = Exact<{
  sort?: Maybe<Array<Maybe<GraphQlSortInput>> | Maybe<GraphQlSortInput>>;
}>;


export type CategoryListQuery = (
  { __typename?: 'Query' }
  & { categoryList?: Maybe<(
    { __typename?: 'GraphQLPage_CategoryEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name' | 'active' | 'routePath'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id'>
      )> }
    )>>> }
  )> }
);

export type CategorySetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type CategorySetActiveMutation = (
  { __typename?: 'Mutation' }
  & { categorySetActive?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'active'>
  )> }
);

export type CategorySetImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
}>;


export type CategorySetImageMutation = (
  { __typename?: 'Mutation' }
  & { categorySetImage?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id'>
    & { thumbnailMedia?: Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'id' | 'title' | 'url'>
    )> }
  )> }
);

export type CategorySetMetaDescriptionMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaDescription?: Maybe<Scalars['String']>;
}>;


export type CategorySetMetaDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { categorySetMetaDescription?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'metaDescription'>
  )> }
);

export type CategorySetMetaKeywordsMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaKeywords?: Maybe<Scalars['String']>;
}>;


export type CategorySetMetaKeywordsMutation = (
  { __typename?: 'Mutation' }
  & { categorySetMetaKeywords?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'metaKeywords'>
  )> }
);

export type CategorySetMetaTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaTitle?: Maybe<Scalars['String']>;
}>;


export type CategorySetMetaTitleMutation = (
  { __typename?: 'Mutation' }
  & { categorySetMetaTitle?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'metaTitle'>
  )> }
);

export type CategorySetNameMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type CategorySetNameMutation = (
  { __typename?: 'Mutation' }
  & { categorySetName?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'name'>
  )> }
);

export type CategorySetPublishedRevisionMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type CategorySetPublishedRevisionMutation = (
  { __typename?: 'Mutation' }
  & { categorySetPublishedRevision?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id'>
    & { publishedRevision?: Maybe<(
      { __typename?: 'CategoryRevisionEntity' }
      & Pick<CategoryRevisionEntity, 'htmlContent'>
    )> }
  )> }
);

export type CategoryUploadImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type CategoryUploadImageMutation = (
  { __typename?: 'Mutation' }
  & { categoryUploadImage?: Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id'>
    & { thumbnailMedia?: Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'id' | 'title' | 'url'>
    )> }
  )> }
);

export type CheckoutCreatePaymentMethodMutationVariables = Exact<{
  deviceData: Scalars['String'];
  nonce: Scalars['String'];
}>;


export type CheckoutCreatePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { checkoutCreatePaymentMethod?: Maybe<(
    { __typename?: 'PaymentMethodDetail' }
    & Pick<PaymentMethodDetail, 'type' | 'token' | 'comment' | 'image' | 'defaultMethod'>
  )> }
);

export type CheckoutDeletePaymentMethodMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type CheckoutDeletePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkoutDeletePaymentMethod'>
);

export type CheckoutGetClientTokenMutationVariables = Exact<{
  anonymous?: Maybe<Scalars['Boolean']>;
}>;


export type CheckoutGetClientTokenMutation = (
  { __typename?: 'Mutation' }
  & { checkoutGetClientToken?: Maybe<(
    { __typename?: 'ClientTokenDetail' }
    & Pick<ClientTokenDetail, 'clientToken'>
  )> }
);

export type CheckoutListPaymentMethodsMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckoutListPaymentMethodsMutation = (
  { __typename?: 'Mutation' }
  & { checkoutListPaymentMethods?: Maybe<Array<Maybe<(
    { __typename?: 'PaymentMethodDetail' }
    & Pick<PaymentMethodDetail, 'type' | 'token' | 'comment' | 'image' | 'defaultMethod'>
  )>>> }
);

export type CheckoutSetDefaultPaymentMethodMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type CheckoutSetDefaultPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkoutSetDefaultPaymentMethod'>
);

export type ConfigurableProductAddAnimalMutationVariables = Exact<{
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
}>;


export type ConfigurableProductAddAnimalMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductAddAnimal?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductAddConfigurableItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  productId: Scalars['UUID'];
}>;


export type ConfigurableProductAddConfigurableItemMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductAddConfigurableItem?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductAddImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
}>;


export type ConfigurableProductAddImageMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductAddImage?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ConfigurableProductBySlugQuery = (
  { __typename?: 'Query' }
  & { configurableProductBySlug?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Pick<ConfigurableProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'dropshipped' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'averageRating' | 'countRating'>
    & { publishedRevision?: Maybe<(
      { __typename?: 'ProductRevisionEntity' }
      & Pick<ProductRevisionEntity, 'htmlContent'>
    )>, medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>>, explicitCategories?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'active' | 'id' | 'slug' | 'path' | 'name'>
      & { ancestors?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'active' | 'name' | 'path'>
      )>>> }
    )>>>, variationSet?: Maybe<(
      { __typename?: 'VariationSetEntity' }
      & { products?: Maybe<Array<Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'active' | 'cls' | 'id' | 'title' | 'slug' | 'price' | 'salePrice' | 'variationKey'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )>>> }
    )>, upsellProducts?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'active' | 'title' | 'sku' | 'slug' | 'countRating' | 'averageRating' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'variationKey' | 'featured'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )>>>, configurableItems?: Maybe<Array<Maybe<(
      { __typename?: 'ConfigurableItemEntity' }
      & { simpleProduct?: Maybe<(
        { __typename?: 'SimpleProductEntity' }
        & Pick<SimpleProductEntity, 'id' | 'cls' | 'sku' | 'slug' | 'title' | 'price' | 'salePrice' | 'featured' | 'subscription' | 'quantityAvailableForSale' | 'averageRating' | 'countRating'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>>, shippingRuleSet?: Maybe<(
          { __typename?: 'ShippingRuleSetEntity' }
          & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
            { __typename?: 'LiveArrivalGuaranteeEntity' }
            & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
          )>>>, destinationRestriction?: Maybe<(
            { __typename?: 'DestinationRestrictionEntity' }
            & Pick<DestinationRestrictionEntity, 'domesticOnly' | 'prohibitedStates'>
          )> }
        )> }
      )> }
    )>>>, careSheets?: Maybe<Array<Maybe<(
      { __typename?: 'CareSheetEntity' }
      & Pick<CareSheetEntity, 'id' | 'title' | 'slug' | 'createdAt' | 'metaDescription' | 'active' | 'readTime'>
    )>>> }
  )> }
);

export type ConfigurableProductCloneMutationVariables = Exact<{
  id: Scalars['UUID'];
  sku: Scalars['String'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  price: Scalars['BigDecimal'];
}>;


export type ConfigurableProductCloneMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductClone?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductCreateMutationVariables = Exact<{
  sku: Scalars['String'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  price: Scalars['BigDecimal'];
}>;


export type ConfigurableProductCreateMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductCreate?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductFilterQueryVariables = Exact<{
  active?: Maybe<Scalars['Boolean']>;
  pageable: GraphQlPageableInput;
  category?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type ConfigurableProductFilterQuery = (
  { __typename?: 'Query' }
  & { configurableProductFilter?: Maybe<(
    { __typename?: 'GraphQLPage_ConfigurableProductEntity' }
    & Pick<GraphQlPage_ConfigurableProductEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ConfigurableProductEntity' }
      & Pick<ConfigurableProductEntity, 'id' | 'slug' | 'sku' | 'upc' | 'title' | 'active' | 'featured' | 'visible' | 'quantityAvailableForSale' | 'price' | 'specialPrice' | 'wholesalePrice' | 'cost'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>>, explicitCategories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
          & { parent?: Maybe<(
            { __typename?: 'CategoryEntity' }
            & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
          )> }
        )> }
      )>>>, explicitDepartment?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'DepartmentEntity' }
          & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type ConfigurableProductInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ConfigurableProductInfoQuery = (
  { __typename?: 'Query' }
  & { configurableProductInfo?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductReindexMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ConfigurableProductReindexMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductReindex?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ConfigurableProductRemoveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'configurableProductRemove'>
);

export type ConfigurableProductRemoveAnimalMutationVariables = Exact<{
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
}>;


export type ConfigurableProductRemoveAnimalMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductRemoveAnimal?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductRemoveConfigurableItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  configurableItemId: Scalars['UUID'];
}>;


export type ConfigurableProductRemoveConfigurableItemMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductRemoveConfigurableItem?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductRemoveProductMediaMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
}>;


export type ConfigurableProductRemoveProductMediaMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductRemoveProductMedia?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type ConfigurableProductSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetActive?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetConfigurableItemPositionMutationVariables = Exact<{
  id: Scalars['UUID'];
  configurableItemId: Scalars['UUID'];
  position: Scalars['Int'];
}>;


export type ConfigurableProductSetConfigurableItemPositionMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetConfigurableItemPosition?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetFeaturedMutationVariables = Exact<{
  id: Scalars['UUID'];
  featured: Scalars['Boolean'];
}>;


export type ConfigurableProductSetFeaturedMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetFeatured?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetMetaDescriptionMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaDescription?: Maybe<Scalars['String']>;
}>;


export type ConfigurableProductSetMetaDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetMetaDescription?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetMetaKeywordsMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaKeywords?: Maybe<Scalars['String']>;
}>;


export type ConfigurableProductSetMetaKeywordsMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetMetaKeywords?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetMetaTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaTitle?: Maybe<Scalars['String']>;
}>;


export type ConfigurableProductSetMetaTitleMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetMetaTitle?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetProductMediaSequenceMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
  sequence: Scalars['Int'];
}>;


export type ConfigurableProductSetProductMediaSequenceMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetProductMediaSequence?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetPublishedRevisionMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type ConfigurableProductSetPublishedRevisionMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetPublishedRevision?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetSkuMutationVariables = Exact<{
  id: Scalars['UUID'];
  sku: Scalars['String'];
}>;


export type ConfigurableProductSetSkuMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetSku?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetSlugMutationVariables = Exact<{
  id: Scalars['UUID'];
  slug: Scalars['String'];
}>;


export type ConfigurableProductSetSlugMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetSlug?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  title: Scalars['String'];
}>;


export type ConfigurableProductSetTitleMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetTitle?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetUpcMutationVariables = Exact<{
  id: Scalars['UUID'];
  upc?: Maybe<Scalars['String']>;
}>;


export type ConfigurableProductSetUpcMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetUpc?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductSetVisibleMutationVariables = Exact<{
  id: Scalars['UUID'];
  visible: Scalars['Boolean'];
}>;


export type ConfigurableProductSetVisibleMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductSetVisible?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ConfigurableProductUploadImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type ConfigurableProductUploadImageMutation = (
  { __typename?: 'Mutation' }
  & { configurableProductUploadImage?: Maybe<(
    { __typename?: 'ConfigurableProductEntity' }
    & Admin_Configurable_Product_EntityFragment
  )> }
);

export type ContentBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ContentBySlugQuery = (
  { __typename?: 'Query' }
  & { contentBySlug?: Maybe<(
    { __typename?: 'ContentEntity' }
    & Pick<ContentEntity, 'id' | 'cls' | 'slug' | 'title' | 'author' | 'metaTitle' | 'metaDescription' | 'metaKeywords'>
  )> }
);

export type DepartmentListQueryVariables = Exact<{ [key: string]: never; }>;


export type DepartmentListQuery = (
  { __typename?: 'Query' }
  & { departmentList?: Maybe<(
    { __typename?: 'GraphQLPage_DepartmentEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'id' | 'slug' | 'name' | 'routePath'>
      & { parent?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'id'>
      )> }
    )>>> }
  )> }
);

export type Customer_DiscountFragment = (
  { __typename?: 'DiscountEntity' }
  & Pick<DiscountEntity, 'id' | 'discountAmount' | 'discountType' | 'eligibleCartValue' | 'eligibleCouponCode' | 'eligibleUserType' | 'endDate' | 'name' | 'permitStacking' | 'slug' | 'startDate' | 'htmlContent' | 'teaser'>
);

export type Admin_DiscountFragment = (
  { __typename?: 'DiscountEntity' }
  & Pick<DiscountEntity, 'id' | 'active' | 'discountAmount' | 'discountType' | 'eligibleCartValue' | 'eligibleCouponCode' | 'eligibleUserType' | 'endDate' | 'htmlContent' | 'name' | 'permitStacking' | 'slug' | 'startDate' | 'teaser'>
);

export type Admin_Discount_ListsFragment = (
  { __typename?: 'DiscountEntity' }
  & { eligibleProducts?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'price' | 'quantityAvailableForSale' | 'sku' | 'title'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'url'>
    )>>> }
  )>>>, explicitProducts?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'sku' | 'title'>
  )>>>, categories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
  )>>>, departments?: Maybe<Array<Maybe<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'id' | 'name'>
  )>>> }
);

export type DiscountAddCategoriesMutationVariables = Exact<{
  id: Scalars['UUID'];
  categoryIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type DiscountAddCategoriesMutation = (
  { __typename?: 'Mutation' }
  & { discountAddCategories?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
    & Admin_Discount_ListsFragment
  )> }
);

export type DiscountAddDepartmentsMutationVariables = Exact<{
  id: Scalars['UUID'];
  departmentIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type DiscountAddDepartmentsMutation = (
  { __typename?: 'Mutation' }
  & { discountAddDepartments?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
    & Admin_Discount_ListsFragment
  )> }
);

export type DiscountAddEligibleProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  eligibleProductIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type DiscountAddEligibleProductsMutation = (
  { __typename?: 'Mutation' }
  & { discountAddEligibleProducts?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
    & Admin_Discount_ListsFragment
  )> }
);

export type DiscountAddExplicitProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  explicitProductIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type DiscountAddExplicitProductsMutation = (
  { __typename?: 'Mutation' }
  & { discountAddExplicitProducts?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
    & Admin_Discount_ListsFragment
  )> }
);

export type DiscountBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type DiscountBySlugQuery = (
  { __typename?: 'Query' }
  & { discountBySlug?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Customer_DiscountFragment
  )> }
);

export type DiscountCloneMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  slug: Scalars['String'];
}>;


export type DiscountCloneMutation = (
  { __typename?: 'Mutation' }
  & { discountClone?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
    & Admin_Discount_ListsFragment
  )> }
);

export type DiscountCreateMutationVariables = Exact<{
  name: Scalars['String'];
  slug: Scalars['String'];
  discountType: DiscountType;
  permitStacking: Scalars['Boolean'];
  startDate?: Maybe<Scalars['Instant']>;
  endDate?: Maybe<Scalars['Instant']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
  departmentIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
  eligibleUserType?: Maybe<UserType>;
  eligibleCouponCode?: Maybe<Scalars['String']>;
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  discountAmount?: Maybe<Scalars['BigDecimal']>;
}>;


export type DiscountCreateMutation = (
  { __typename?: 'Mutation' }
  & { discountCreate?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
    & Admin_Discount_ListsFragment
  )> }
);

export type DiscountDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DiscountDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'discountDelete'>
);

export type DiscountEvaluateImplicitProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DiscountEvaluateImplicitProductsMutation = (
  { __typename?: 'Mutation' }
  & { discountEvaluateImplicitProducts?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
    & Admin_Discount_ListsFragment
  )> }
);

export type DiscountFilterImplicitProductsQueryVariables = Exact<{
  id: Scalars['UUID'];
  page: GraphQlPageableInput;
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type DiscountFilterImplicitProductsQuery = (
  { __typename?: 'Query' }
  & { discountFilterImplicitProducts?: Maybe<(
    { __typename?: 'GraphQLPage_ProductEntity' }
    & Pick<GraphQlPage_ProductEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'sku' | 'title'>
    )>>> }
  )> }
);

export type DiscountInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DiscountInfoQuery = (
  { __typename?: 'Query' }
  & { discountInfo?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
    & Admin_Discount_ListsFragment
  )> }
);

export type DiscountListQueryVariables = Exact<{
  pageable?: Maybe<GraphQlPageableInput>;
}>;


export type DiscountListQuery = (
  { __typename?: 'Query' }
  & { discountList?: Maybe<(
    { __typename?: 'GraphQLPage_DiscountEntity' }
    & Pick<GraphQlPage_DiscountEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'DiscountEntity' }
      & Pick<DiscountEntity, 'id' | 'name' | 'slug' | 'discountType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleCartValue' | 'discountAmount'>
    )>>> }
  )> }
);

export type DiscountRemoveCategoriesMutationVariables = Exact<{
  id: Scalars['UUID'];
  categoryIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type DiscountRemoveCategoriesMutation = (
  { __typename?: 'Mutation' }
  & { discountRemoveCategories?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
    & Admin_Discount_ListsFragment
  )> }
);

export type DiscountRemoveDepartmentsMutationVariables = Exact<{
  id: Scalars['UUID'];
  departmentIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type DiscountRemoveDepartmentsMutation = (
  { __typename?: 'Mutation' }
  & { discountRemoveDepartments?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
    & Admin_Discount_ListsFragment
  )> }
);

export type DiscountRemoveEligibleProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  eligibleProductIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type DiscountRemoveEligibleProductsMutation = (
  { __typename?: 'Mutation' }
  & { discountRemoveEligibleProducts?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
    & Admin_Discount_ListsFragment
  )> }
);

export type DiscountRemoveExplicitProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  explicitProductIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type DiscountRemoveExplicitProductsMutation = (
  { __typename?: 'Mutation' }
  & { discountRemoveExplicitProducts?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
    & Admin_Discount_ListsFragment
  )> }
);

export type DiscountSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type DiscountSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { discountSetActive?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountSetDiscountAmountMutationVariables = Exact<{
  id: Scalars['UUID'];
  discountAmount?: Maybe<Scalars['BigDecimal']>;
}>;


export type DiscountSetDiscountAmountMutation = (
  { __typename?: 'Mutation' }
  & { discountSetDiscountAmount?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountSetDiscountTypeMutationVariables = Exact<{
  id: Scalars['UUID'];
  discountType: DiscountType;
}>;


export type DiscountSetDiscountTypeMutation = (
  { __typename?: 'Mutation' }
  & { discountSetDiscountType?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountSetEligibleCartValueMutationVariables = Exact<{
  id: Scalars['UUID'];
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
}>;


export type DiscountSetEligibleCartValueMutation = (
  { __typename?: 'Mutation' }
  & { discountSetEligibleCartValue?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountSetEligibleCouponCodeMutationVariables = Exact<{
  id: Scalars['UUID'];
  eligibleCouponCode?: Maybe<Scalars['String']>;
}>;


export type DiscountSetEligibleCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { discountSetEligibleCouponCode?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountSetEligibleUserTypeMutationVariables = Exact<{
  id: Scalars['UUID'];
  eligibleUserType?: Maybe<UserType>;
}>;


export type DiscountSetEligibleUserTypeMutation = (
  { __typename?: 'Mutation' }
  & { discountSetEligibleUserType?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountSetEndDateMutationVariables = Exact<{
  id: Scalars['UUID'];
  endDate?: Maybe<Scalars['Instant']>;
}>;


export type DiscountSetEndDateMutation = (
  { __typename?: 'Mutation' }
  & { discountSetEndDate?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountSetHtmlContentMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type DiscountSetHtmlContentMutation = (
  { __typename?: 'Mutation' }
  & { discountSetHtmlContent?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountSetNameMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type DiscountSetNameMutation = (
  { __typename?: 'Mutation' }
  & { discountSetName?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountSetPermitStackingMutationVariables = Exact<{
  id: Scalars['UUID'];
  permitStacking: Scalars['Boolean'];
}>;


export type DiscountSetPermitStackingMutation = (
  { __typename?: 'Mutation' }
  & { discountSetPermitStacking?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountSetSlugMutationVariables = Exact<{
  id: Scalars['UUID'];
  slug: Scalars['String'];
}>;


export type DiscountSetSlugMutation = (
  { __typename?: 'Mutation' }
  & { discountSetSlug?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountSetStartDateMutationVariables = Exact<{
  id: Scalars['UUID'];
  startDate?: Maybe<Scalars['Instant']>;
}>;


export type DiscountSetStartDateMutation = (
  { __typename?: 'Mutation' }
  & { discountSetStartDate?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type DiscountSetTeaserMutationVariables = Exact<{
  id: Scalars['UUID'];
  teaser?: Maybe<Scalars['String']>;
}>;


export type DiscountSetTeaserMutation = (
  { __typename?: 'Mutation' }
  & { discountSetTeaser?: Maybe<(
    { __typename?: 'DiscountEntity' }
    & Admin_DiscountFragment
  )> }
);

export type FinancialPspCorporateRevenueJournalQueryVariables = Exact<{
  startDate: Scalars['LocalDate'];
  endDate: Scalars['LocalDate'];
}>;


export type FinancialPspCorporateRevenueJournalQuery = (
  { __typename?: 'Query' }
  & { financialPspCorporateRevenueJournal?: Maybe<(
    { __typename?: 'JournalEntry' }
    & Pick<JournalEntry, 'startDate' | 'endDate' | 'accountsReceivable' | 'productSales' | 'shippingSales' | 'productRefunds' | 'shippingRefunds' | 'salesTaxExpense' | 'salesTaxLiability'>
  )> }
);

export type FinancialPspFranchiseRevenueJournalQueryVariables = Exact<{
  startDate: Scalars['LocalDate'];
  endDate: Scalars['LocalDate'];
}>;


export type FinancialPspFranchiseRevenueJournalQuery = (
  { __typename?: 'Query' }
  & { financialPspFranchiseRevenueJournal?: Maybe<(
    { __typename?: 'JournalEntry' }
    & Pick<JournalEntry, 'startDate' | 'endDate' | 'accountsReceivable' | 'productSales' | 'shippingSales' | 'productRefunds' | 'shippingRefunds' | 'salesTaxExpense' | 'salesTaxLiability'>
  )> }
);

export type FinancialResellerRevenueJournalQueryVariables = Exact<{
  startDate: Scalars['LocalDate'];
  endDate: Scalars['LocalDate'];
}>;


export type FinancialResellerRevenueJournalQuery = (
  { __typename?: 'Query' }
  & { financialResellerRevenueJournal?: Maybe<(
    { __typename?: 'JournalEntry' }
    & Pick<JournalEntry, 'startDate' | 'endDate' | 'accountsReceivable' | 'productSales' | 'shippingSales' | 'productRefunds' | 'shippingRefunds' | 'salesTaxExpense' | 'salesTaxLiability'>
  )> }
);

export type FinancialWebsiteRevenueJournalQueryVariables = Exact<{
  startDate: Scalars['LocalDate'];
  endDate: Scalars['LocalDate'];
}>;


export type FinancialWebsiteRevenueJournalQuery = (
  { __typename?: 'Query' }
  & { financialWebsiteRevenueJournal?: Maybe<(
    { __typename?: 'JournalEntry' }
    & Pick<JournalEntry, 'startDate' | 'endDate' | 'accountsReceivable' | 'productSales' | 'shippingSales' | 'productRefunds' | 'shippingRefunds' | 'salesTaxExpense' | 'salesTaxLiability'>
  )> }
);

export type GiftCardCreateMutationVariables = Exact<{
  amount: Scalars['BigDecimal'];
}>;


export type GiftCardCreateMutation = (
  { __typename?: 'Mutation' }
  & { giftCardCreate?: Maybe<(
    { __typename?: 'GiftCardEntity' }
    & Pick<GiftCardEntity, 'id' | 'createdAt' | 'activatedBy' | 'code' | 'balance'>
  )> }
);

export type GiftCardFilterQueryVariables = Exact<{
  code?: Maybe<Scalars['String']>;
  pageable: GraphQlPageableInput;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type GiftCardFilterQuery = (
  { __typename?: 'Query' }
  & { giftCardFilter?: Maybe<(
    { __typename?: 'GraphQLPage_GiftCardEntity' }
    & Pick<GraphQlPage_GiftCardEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'GiftCardEntity' }
      & Pick<GiftCardEntity, 'id' | 'createdAt' | 'activatedBy' | 'code' | 'balance'>
    )>>> }
  )> }
);

export type GiftCardInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GiftCardInfoQuery = (
  { __typename?: 'Query' }
  & { giftCardInfo?: Maybe<(
    { __typename?: 'GiftCardEntity' }
    & Pick<GiftCardEntity, 'id' | 'createdAt' | 'activatedBy' | 'code' | 'balance'>
    & { transactions?: Maybe<Array<Maybe<(
      { __typename?: 'GiftCardTransactionEntity' }
      & Pick<GiftCardTransactionEntity, 'id' | 'createdAt' | 'updatedAt' | 'transactionType' | 'comment' | 'amount'>
    )>>> }
  )> }
);

export type GiftCardReloadMutationVariables = Exact<{
  amount: Scalars['BigDecimal'];
  id: Scalars['UUID'];
}>;


export type GiftCardReloadMutation = (
  { __typename?: 'Mutation' }
  & { giftCardReload?: Maybe<(
    { __typename?: 'GiftCardEntity' }
    & Pick<GiftCardEntity, 'id' | 'createdAt' | 'activatedBy' | 'code' | 'balance'>
    & { transactions?: Maybe<Array<Maybe<(
      { __typename?: 'GiftCardTransactionEntity' }
      & Pick<GiftCardTransactionEntity, 'id' | 'createdAt' | 'updatedAt' | 'transactionType' | 'comment' | 'amount'>
    )>>> }
  )> }
);

export type GiftCardBalanceQueryVariables = Exact<{
  code: Scalars['String'];
  pin?: Maybe<Scalars['String']>;
}>;


export type GiftCardBalanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'giftCardBalance'>
);

export type InventoryGetHistoryMutationVariables = Exact<{
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
  days: Scalars['Long'];
}>;


export type InventoryGetHistoryMutation = (
  { __typename?: 'Mutation' }
  & { inventoryGetHistory?: Maybe<Array<Maybe<(
    { __typename?: 'InventoryHistory' }
    & Pick<InventoryHistory, 'timestamp' | 'user' | 'quantityOnShelf' | 'quantityAvailable' | 'changeQuantity' | 'changeType'>
  )>>> }
);

export type InventorySetDetailsMutationVariables = Exact<{
  warehouse: Scalars['String'];
  id: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type InventorySetDetailsMutation = (
  { __typename?: 'Mutation' }
  & { inventorySetDetails?: Maybe<(
    { __typename?: 'InventoryDetails' }
    & { product?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Admin_Simple_Product_EntityFragment
    )> }
  )> }
);

export type KitProductAddAnimalMutationVariables = Exact<{
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
}>;


export type KitProductAddAnimalMutation = (
  { __typename?: 'Mutation' }
  & { kitProductAddAnimal?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductAddCrossSellItemsMutationVariables = Exact<{
  id: Scalars['UUID'];
  productIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type KitProductAddCrossSellItemsMutation = (
  { __typename?: 'Mutation' }
  & { kitProductAddCrossSellItems?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductAddImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
}>;


export type KitProductAddImageMutation = (
  { __typename?: 'Mutation' }
  & { kitProductAddImage?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductAddKitItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type KitProductAddKitItemMutation = (
  { __typename?: 'Mutation' }
  & { kitProductAddKitItem?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductAddPriceTierMutationVariables = Exact<{
  id: Scalars['UUID'];
  price: Scalars['BigDecimal'];
  quantity: Scalars['BigDecimal'];
  userType?: Maybe<UserType>;
}>;


export type KitProductAddPriceTierMutation = (
  { __typename?: 'Mutation' }
  & { kitProductAddPriceTier?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type KitProductBySlugQuery = (
  { __typename?: 'Query' }
  & { kitProductBySlug?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Pick<KitProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'subscription' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'averageRating' | 'countRating' | 'shippingNeeds'>
    & { kitItems?: Maybe<Array<Maybe<(
      { __typename?: 'KitItemEntity' }
      & Pick<KitItemEntity, 'id' | 'quantity'>
      & { simpleProduct?: Maybe<(
        { __typename?: 'SimpleProductEntity' }
        & Pick<SimpleProductEntity, 'id' | 'cls' | 'sku' | 'slug' | 'title' | 'price' | 'salePrice' | 'averageRating' | 'countRating' | 'quantityAvailableForSale'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'url'>
        )>>>, shippingRuleSet?: Maybe<(
          { __typename?: 'ShippingRuleSetEntity' }
          & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
            { __typename?: 'LiveArrivalGuaranteeEntity' }
            & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
          )>>>, destinationRestriction?: Maybe<(
            { __typename?: 'DestinationRestrictionEntity' }
            & Pick<DestinationRestrictionEntity, 'domesticOnly' | 'prohibitedStates'>
          )> }
        )> }
      )> }
    )>>>, tieredPrices?: Maybe<Array<Maybe<(
      { __typename?: 'PriceTierEntity' }
      & Pick<PriceTierEntity, 'id' | 'quantity' | 'price'>
    )>>>, publishedRevision?: Maybe<(
      { __typename?: 'ProductRevisionEntity' }
      & Pick<ProductRevisionEntity, 'htmlContent'>
    )>, medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>>, explicitCategories?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'active' | 'id' | 'slug' | 'path' | 'name'>
      & { ancestors?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'active' | 'name' | 'path'>
      )>>> }
    )>>>, variationSet?: Maybe<(
      { __typename?: 'VariationSetEntity' }
      & { products?: Maybe<Array<Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'active' | 'cls' | 'id' | 'title' | 'slug' | 'price' | 'salePrice' | 'variationKey'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )>>> }
    )>, upsellProducts?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'active' | 'title' | 'sku' | 'slug' | 'countRating' | 'averageRating' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'variationKey' | 'featured'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )>>>, shippingRuleSet?: Maybe<(
      { __typename?: 'ShippingRuleSetEntity' }
      & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
        { __typename?: 'LiveArrivalGuaranteeEntity' }
        & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
      )>>>, destinationRestriction?: Maybe<(
        { __typename?: 'DestinationRestrictionEntity' }
        & Pick<DestinationRestrictionEntity, 'domesticOnly' | 'prohibitedStates'>
      )> }
    )>, careSheets?: Maybe<Array<Maybe<(
      { __typename?: 'CareSheetEntity' }
      & Pick<CareSheetEntity, 'id' | 'title' | 'slug' | 'createdAt' | 'metaDescription' | 'active' | 'readTime'>
    )>>> }
  )> }
);

export type KitProductClearKitItemAlternateMutationVariables = Exact<{
  kitItemId: Scalars['UUID'];
  id: Scalars['UUID'];
}>;


export type KitProductClearKitItemAlternateMutation = (
  { __typename?: 'Mutation' }
  & { kitProductClearKitItemAlternate?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductCloneMutationVariables = Exact<{
  id: Scalars['UUID'];
  sku: Scalars['String'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  price: Scalars['BigDecimal'];
}>;


export type KitProductCloneMutation = (
  { __typename?: 'Mutation' }
  & { kitProductClone?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductCreateMutationVariables = Exact<{
  sku: Scalars['String'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  price: Scalars['BigDecimal'];
}>;


export type KitProductCreateMutation = (
  { __typename?: 'Mutation' }
  & { kitProductCreate?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductFilterQueryVariables = Exact<{
  active?: Maybe<Scalars['Boolean']>;
  pageable: GraphQlPageableInput;
  category?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
  upc?: Maybe<Scalars['String']>;
}>;


export type KitProductFilterQuery = (
  { __typename?: 'Query' }
  & { kitProductFilter?: Maybe<(
    { __typename?: 'GraphQLPage_KitProductEntity' }
    & Pick<GraphQlPage_KitProductEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'KitProductEntity' }
      & Pick<KitProductEntity, 'id' | 'slug' | 'sku' | 'upc' | 'title' | 'active' | 'featured' | 'visible' | 'quantityAvailableForSale' | 'price' | 'specialPrice' | 'wholesalePrice' | 'cost'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>>, explicitCategories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
          & { parent?: Maybe<(
            { __typename?: 'CategoryEntity' }
            & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
          )> }
        )> }
      )>>>, explicitDepartment?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'DepartmentEntity' }
          & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type KitProductInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type KitProductInfoQuery = (
  { __typename?: 'Query' }
  & { kitProductInfo?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductReindexMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type KitProductReindexMutation = (
  { __typename?: 'Mutation' }
  & { kitProductReindex?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type KitProductRemoveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'kitProductRemove'>
);

export type KitProductRemoveAnimalMutationVariables = Exact<{
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
}>;


export type KitProductRemoveAnimalMutation = (
  { __typename?: 'Mutation' }
  & { kitProductRemoveAnimal?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductRemoveCrossSellItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  crossSellItemId: Scalars['UUID'];
}>;


export type KitProductRemoveCrossSellItemMutation = (
  { __typename?: 'Mutation' }
  & { kitProductRemoveCrossSellItem?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductRemoveKitItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  kitItemId: Scalars['UUID'];
}>;


export type KitProductRemoveKitItemMutation = (
  { __typename?: 'Mutation' }
  & { kitProductRemoveKitItem?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductRemovePriceTierMutationVariables = Exact<{
  id: Scalars['UUID'];
  priceTierId: Scalars['UUID'];
}>;


export type KitProductRemovePriceTierMutation = (
  { __typename?: 'Mutation' }
  & { kitProductRemovePriceTier?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductRemoveProductMediaMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
}>;


export type KitProductRemoveProductMediaMutation = (
  { __typename?: 'Mutation' }
  & { kitProductRemoveProductMedia?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type KitProductSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetActive?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetCostMutationVariables = Exact<{
  id: Scalars['UUID'];
  cost?: Maybe<Scalars['BigDecimal']>;
}>;


export type KitProductSetCostMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetCost?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetCrossSellItemPositionMutationVariables = Exact<{
  id: Scalars['UUID'];
  crossSellItemId: Scalars['UUID'];
  position: Scalars['Int'];
}>;


export type KitProductSetCrossSellItemPositionMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetCrossSellItemPosition?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetFeaturedMutationVariables = Exact<{
  id: Scalars['UUID'];
  featured: Scalars['Boolean'];
}>;


export type KitProductSetFeaturedMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetFeatured?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetKitItemAlternateMutationVariables = Exact<{
  quantity: Scalars['Long'];
  productId: Scalars['UUID'];
  kitItemId: Scalars['UUID'];
  id: Scalars['UUID'];
}>;


export type KitProductSetKitItemAlternateMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetKitItemAlternate?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetKitItemPositionMutationVariables = Exact<{
  id: Scalars['UUID'];
  kitItemId: Scalars['UUID'];
  position: Scalars['Int'];
}>;


export type KitProductSetKitItemPositionMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetKitItemPosition?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetMetaDescriptionMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaDescription?: Maybe<Scalars['String']>;
}>;


export type KitProductSetMetaDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetMetaDescription?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetMetaKeywordsMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaKeywords?: Maybe<Scalars['String']>;
}>;


export type KitProductSetMetaKeywordsMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetMetaKeywords?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetMetaTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaTitle?: Maybe<Scalars['String']>;
}>;


export type KitProductSetMetaTitleMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetMetaTitle?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetPriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  price: Scalars['BigDecimal'];
}>;


export type KitProductSetPriceMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetPrice?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetProductMediaSequenceMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
  sequence: Scalars['Int'];
}>;


export type KitProductSetProductMediaSequenceMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetProductMediaSequence?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetPublishedRevisionMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type KitProductSetPublishedRevisionMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetPublishedRevision?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetSkuMutationVariables = Exact<{
  id: Scalars['UUID'];
  sku: Scalars['String'];
}>;


export type KitProductSetSkuMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetSku?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetSlugMutationVariables = Exact<{
  id: Scalars['UUID'];
  slug: Scalars['String'];
}>;


export type KitProductSetSlugMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetSlug?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetSpecialPriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  specialPrice?: Maybe<Scalars['BigDecimal']>;
}>;


export type KitProductSetSpecialPriceMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetSpecialPrice?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetSubscriptionMutationVariables = Exact<{
  id: Scalars['UUID'];
  subscription: Scalars['Boolean'];
}>;


export type KitProductSetSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetSubscription?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  title: Scalars['String'];
}>;


export type KitProductSetTitleMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetTitle?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetUpcMutationVariables = Exact<{
  id: Scalars['UUID'];
  upc?: Maybe<Scalars['String']>;
}>;


export type KitProductSetUpcMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetUpc?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetVisibleMutationVariables = Exact<{
  id: Scalars['UUID'];
  visible: Scalars['Boolean'];
}>;


export type KitProductSetVisibleMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetVisible?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductSetWholesalePriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
}>;


export type KitProductSetWholesalePriceMutation = (
  { __typename?: 'Mutation' }
  & { kitProductSetWholesalePrice?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductUploadImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type KitProductUploadImageMutation = (
  { __typename?: 'Mutation' }
  & { kitProductUploadImage?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Admin_Kit_Product_EntityFragment
  )> }
);

export type KitProductWithCrossSellsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type KitProductWithCrossSellsQuery = (
  { __typename?: 'Query' }
  & { kitProductBySlug?: Maybe<(
    { __typename?: 'KitProductEntity' }
    & Pick<KitProductEntity, 'id' | 'cls' | 'sku' | 'slug' | 'title' | 'quantityAvailableForSale' | 'price' | 'salePrice'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>>, shippingRuleSet?: Maybe<(
      { __typename?: 'ShippingRuleSetEntity' }
      & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
        { __typename?: 'LiveArrivalGuaranteeEntity' }
        & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
      )>>> }
    )>, crossSellItems?: Maybe<Array<Maybe<(
      { __typename?: 'CrossSellItemEntity' }
      & Pick<CrossSellItemEntity, 'id' | 'position'>
      & { crossSellProduct?: Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'id' | 'cls' | 'active' | 'title' | 'sku' | 'slug' | 'countRating' | 'averageRating' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'variationKey' | 'featured'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type Admin_Listed_Product_EntityFragment = (
  { __typename?: 'ListedProductEntity' }
  & Pick<ListedProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'upc' | 'title' | 'active' | 'visible' | 'featured' | 'listedProductType' | 'live' | 'metaDescription' | 'metaKeywords' | 'metaTitle' | 'quantityAvailableForSale' | 'cost' | 'height' | 'length' | 'price' | 'weight' | 'width' | 'shippingNeeds' | 'prepType'>
  & { listings?: Maybe<Array<Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )>>>, publishedRevision?: Maybe<(
    { __typename?: 'ProductRevisionEntity' }
    & Pick<ProductRevisionEntity, 'htmlContent'>
  )>, warehouses?: Maybe<Array<Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'name'>
  )>>>, zones?: Maybe<Array<Maybe<(
    { __typename?: 'ZoneEntity' }
    & Pick<ZoneEntity, 'id' | 'name'>
    & { warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'id' | 'name'>
    )> }
  )>>>, bins?: Maybe<Array<Maybe<(
    { __typename?: 'BinEntity' }
    & Pick<BinEntity, 'id' | 'binId'>
    & { zone?: Maybe<(
      { __typename?: 'ZoneEntity' }
      & Pick<ZoneEntity, 'id' | 'name'>
      & { warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )> }
    )> }
  )>>>, explicitAnimals?: Maybe<Array<Maybe<(
    { __typename?: 'AnimalEntity' }
    & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'AnimalEntity' }
      & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'AnimalEntity' }
        & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
      )> }
    )> }
  )>>>, explicitDepartment?: Maybe<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
    )> }
  )>, brand?: Maybe<(
    { __typename?: 'BrandEntity' }
    & Pick<BrandEntity, 'id' | 'slug' | 'name'>
  )>, medias?: Maybe<Array<Maybe<(
    { __typename?: 'ProductMediaEntity' }
    & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'sequence' | 'url'>
    & { media?: Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'title'>
    )> }
  )>>>, explicitCategories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      )> }
    )> }
  )>>>, implicitPromotions?: Maybe<Array<Maybe<(
    { __typename?: 'PromotionEntity' }
    & Pick<PromotionEntity, 'id' | 'name' | 'slug' | 'promotionType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleItemValue' | 'eligibleItemCount' | 'eligibleCartValue' | 'discountAmount'>
  )>>>, implicitDiscounts?: Maybe<Array<Maybe<(
    { __typename?: 'DiscountEntity' }
    & Pick<DiscountEntity, 'id' | 'name' | 'slug' | 'discountType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleCartValue' | 'discountAmount'>
  )>>>, amazonProfile?: Maybe<(
    { __typename?: 'AmazonProfileEntity' }
    & Pick<AmazonProfileEntity, 'id' | 'asin' | 'fbaSellerSku' | 'sellerSku'>
  )>, petcoDropshipProfile?: Maybe<(
    { __typename?: 'PetcoDropshipProfileEntity' }
    & Pick<PetcoDropshipProfileEntity, 'id' | 'description' | 'sku' | 'surcharge'>
  )>, petcoStoreDirectProfile?: Maybe<(
    { __typename?: 'PetcoStoreDirectProfileEntity' }
    & Pick<PetcoStoreDirectProfileEntity, 'id' | 'description' | 'sku'>
  )>, chewyProfile?: Maybe<(
    { __typename?: 'ChewyProfileEntity' }
    & Pick<ChewyProfileEntity, 'id' | 'description' | 'sku' | 'cost'>
  )>, walmartProfile?: Maybe<(
    { __typename?: 'WalmartProfileEntity' }
    & Pick<WalmartProfileEntity, 'id' | 'sellerSku' | 'wfsSellerSku'>
  )>, resellerProfiles?: Maybe<Array<Maybe<(
    { __typename?: 'ResellerProfileEntity' }
    & Pick<ResellerProfileEntity, 'id' | 'resellerSku' | 'price' | 'retail'>
    & { reseller?: Maybe<(
      { __typename?: 'PartnerEntity' }
      & Pick<PartnerEntity, 'id' | 'name'>
    )> }
  )>>>, crossSellItems?: Maybe<Array<Maybe<(
    { __typename?: 'CrossSellItemEntity' }
    & Pick<CrossSellItemEntity, 'id' | 'position'>
    & { crossSellProduct?: Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'title' | 'sku' | 'price' | 'quantityAvailableForSale' | 'cls'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>>, shippingRuleSet?: Maybe<(
    { __typename?: 'ShippingRuleSetEntity' }
    & Pick<ShippingRuleSetEntity, 'id' | 'slug' | 'name'>
    & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
      { __typename?: 'LiveArrivalGuaranteeEntity' }
      & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
    )>>>, destinationRestriction?: Maybe<(
      { __typename?: 'DestinationRestrictionEntity' }
      & Pick<DestinationRestrictionEntity, 'domesticOnly' | 'prohibitedStates'>
    )> }
  )> }
);

export type Admin_Listing_EntityFragment = (
  { __typename?: 'ListingEntity' }
  & Pick<ListingEntity, 'id' | 'title' | 'identifier' | 'comments' | 'weightDate' | 'birthDate' | 'sex' | 'quantity' | 'height' | 'length' | 'listingWeightUnit' | 'width' | 'price' | 'weight'>
  & { warehouse?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'name'>
  )>, listingMedias?: Maybe<Array<Maybe<(
    { __typename?: 'ListingMediaEntity' }
    & Pick<ListingMediaEntity, 'id' | 'mediaType' | 'sequence' | 'url'>
    & { media?: Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'title'>
    )> }
  )>>> }
);

export type ListedProductAddCrossSellItemsMutationVariables = Exact<{
  id: Scalars['UUID'];
  productIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type ListedProductAddCrossSellItemsMutation = (
  { __typename?: 'Mutation' }
  & { listedProductAddCrossSellItems?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductAddImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
}>;


export type ListedProductAddImageMutation = (
  { __typename?: 'Mutation' }
  & { listedProductAddImage?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductAddListingImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
}>;


export type ListedProductAddListingImageMutation = (
  { __typename?: 'Mutation' }
  & { listedProductAddListingImage?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductAddWarehouseMutationVariables = Exact<{
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
}>;


export type ListedProductAddWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { listedProductAddWarehouse?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductAddZoneMutationVariables = Exact<{
  id: Scalars['UUID'];
  zone: Scalars['UUID'];
}>;


export type ListedProductAddZoneMutation = (
  { __typename?: 'Mutation' }
  & { listedProductAddZone?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ListedProductBySlugQuery = (
  { __typename?: 'Query' }
  & { listedProductBySlug?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Pick<ListedProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'featured' | 'listedProductType' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'averageRating' | 'countRating' | 'shippingNeeds'>
    & { listings?: Maybe<Array<Maybe<(
      { __typename?: 'ListingEntity' }
      & Pick<ListingEntity, 'id' | 'price' | 'salePrice' | 'birthDate' | 'sex' | 'listingWeightUnit' | 'weight' | 'weightDate' | 'quantity' | 'identifier' | 'title' | 'comments' | 'height' | 'length' | 'width'>
      & { listingMedias?: Maybe<Array<Maybe<(
        { __typename?: 'ListingMediaEntity' }
        & Pick<ListingMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )>>>, publishedRevision?: Maybe<(
      { __typename?: 'ProductRevisionEntity' }
      & Pick<ProductRevisionEntity, 'htmlContent'>
    )>, medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>>, explicitCategories?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'active' | 'id' | 'slug' | 'path' | 'name'>
      & { ancestors?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'active' | 'name' | 'path'>
      )>>> }
    )>>>, variationSet?: Maybe<(
      { __typename?: 'VariationSetEntity' }
      & { products?: Maybe<Array<Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'active' | 'cls' | 'id' | 'title' | 'slug' | 'price' | 'salePrice' | 'variationKey'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )>>> }
    )>, upsellProducts?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'active' | 'title' | 'sku' | 'slug' | 'countRating' | 'averageRating' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'variationKey' | 'featured'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )>>>, shippingRuleSet?: Maybe<(
      { __typename?: 'ShippingRuleSetEntity' }
      & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
        { __typename?: 'LiveArrivalGuaranteeEntity' }
        & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
      )>>>, destinationRestriction?: Maybe<(
        { __typename?: 'DestinationRestrictionEntity' }
        & Pick<DestinationRestrictionEntity, 'domesticOnly' | 'prohibitedStates'>
      )> }
    )>, careSheets?: Maybe<Array<Maybe<(
      { __typename?: 'CareSheetEntity' }
      & Pick<CareSheetEntity, 'id' | 'title' | 'slug' | 'createdAt' | 'metaDescription' | 'active' | 'readTime'>
    )>>> }
  )> }
);

export type ListedProductClearBinMutationVariables = Exact<{
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
}>;


export type ListedProductClearBinMutation = (
  { __typename?: 'Mutation' }
  & { listedProductClearBin?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductClearBrandMutationVariables = Exact<{
  productId: Scalars['UUID'];
}>;


export type ListedProductClearBrandMutation = (
  { __typename?: 'Mutation' }
  & { listedProductClearBrand?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductCreateMutationVariables = Exact<{
  sku: Scalars['String'];
  upc?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['BigDecimal']>;
  listedProductType: ListedProductType;
  weight: Scalars['BigDecimal'];
}>;


export type ListedProductCreateMutation = (
  { __typename?: 'Mutation' }
  & { listedProductCreate?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductCreateListingMutationVariables = Exact<{
  id: Scalars['UUID'];
  title: Scalars['String'];
  identifier: Scalars['String'];
  quantity: Scalars['Long'];
  price: Scalars['BigDecimal'];
  birthDate?: Maybe<Scalars['LocalDate']>;
  comments?: Maybe<Scalars['String']>;
  sex?: Maybe<SexType>;
  length?: Maybe<Scalars['BigDecimal']>;
  width?: Maybe<Scalars['BigDecimal']>;
  height?: Maybe<Scalars['BigDecimal']>;
  weight?: Maybe<Scalars['BigDecimal']>;
  listingWeightUnit?: Maybe<ListingWeightUnit>;
  weightDate?: Maybe<Scalars['LocalDate']>;
  warehouseId: Scalars['UUID'];
}>;


export type ListedProductCreateListingMutation = (
  { __typename?: 'Mutation' }
  & { listedProductCreateListing?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductFilterQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
  title?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
  inStock?: Maybe<Scalars['Boolean']>;
}>;


export type ListedProductFilterQuery = (
  { __typename?: 'Query' }
  & { listedProductFilter?: Maybe<(
    { __typename?: 'GraphQLPage_ListedProductEntity' }
    & Pick<GraphQlPage_ListedProductEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ListedProductEntity' }
      & Pick<ListedProductEntity, 'id' | 'slug' | 'sku' | 'upc' | 'title' | 'active' | 'quantityAvailableForSale' | 'averageRating' | 'countRating' | 'price' | 'salePrice' | 'shippingNeeds'>
      & { explicitDepartment?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'DepartmentEntity' }
          & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'BrandEntity' }
        & Pick<BrandEntity, 'id' | 'slug' | 'name'>
      )>, medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>>, explicitCategories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
          & { parent?: Maybe<(
            { __typename?: 'CategoryEntity' }
            & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
          )> }
        )> }
      )>>>, shippingRuleSet?: Maybe<(
        { __typename?: 'ShippingRuleSetEntity' }
        & Pick<ShippingRuleSetEntity, 'id' | 'slug' | 'name'>
      )> }
    )>>> }
  )> }
);

export type ListedProductInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ListedProductInfoQuery = (
  { __typename?: 'Query' }
  & { listedProductInfo?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductReindexMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ListedProductReindexMutation = (
  { __typename?: 'Mutation' }
  & { listedProductReindex?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ListedProductRemoveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'listedProductRemove'>
);

export type ListedProductRemoveCrossSellItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  crossSellItemId: Scalars['UUID'];
}>;


export type ListedProductRemoveCrossSellItemMutation = (
  { __typename?: 'Mutation' }
  & { listedProductRemoveCrossSellItem?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductRemoveListingMutationVariables = Exact<{
  productId: Scalars['UUID'];
  listingId: Scalars['UUID'];
}>;


export type ListedProductRemoveListingMutation = (
  { __typename?: 'Mutation' }
  & { listedProductRemoveListing?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductRemoveListingMediaMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
}>;


export type ListedProductRemoveListingMediaMutation = (
  { __typename?: 'Mutation' }
  & { listedProductRemoveListingMedia?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductRemoveProductMediaMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
}>;


export type ListedProductRemoveProductMediaMutation = (
  { __typename?: 'Mutation' }
  & { listedProductRemoveProductMedia?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductRemoveWarehouseMutationVariables = Exact<{
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
}>;


export type ListedProductRemoveWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { listedProductRemoveWarehouse?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductRemoveZoneMutationVariables = Exact<{
  id: Scalars['UUID'];
  zone: Scalars['UUID'];
}>;


export type ListedProductRemoveZoneMutation = (
  { __typename?: 'Mutation' }
  & { listedProductRemoveZone?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type ListedProductSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetActive?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetBinMutationVariables = Exact<{
  id: Scalars['UUID'];
  bin: Scalars['String'];
  warehouse: Scalars['String'];
}>;


export type ListedProductSetBinMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetBin?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetBrandMutationVariables = Exact<{
  productId: Scalars['UUID'];
  brandId: Scalars['UUID'];
}>;


export type ListedProductSetBrandMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetBrand?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetCostMutationVariables = Exact<{
  id: Scalars['UUID'];
  cost?: Maybe<Scalars['BigDecimal']>;
}>;


export type ListedProductSetCostMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetCost?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetCrossSellItemPositionMutationVariables = Exact<{
  id: Scalars['UUID'];
  crossSellItemId: Scalars['UUID'];
  position: Scalars['Int'];
}>;


export type ListedProductSetCrossSellItemPositionMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetCrossSellItemPosition?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetDimensionsMutationVariables = Exact<{
  id: Scalars['UUID'];
  length: Scalars['BigDecimal'];
  width: Scalars['BigDecimal'];
  height: Scalars['BigDecimal'];
}>;


export type ListedProductSetDimensionsMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetDimensions?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetFeaturedMutationVariables = Exact<{
  id: Scalars['UUID'];
  featured: Scalars['Boolean'];
}>;


export type ListedProductSetFeaturedMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetFeatured?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetListedProductTypeMutationVariables = Exact<{
  id: Scalars['UUID'];
  listedProductType: ListedProductType;
}>;


export type ListedProductSetListedProductTypeMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetListedProductType?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetListingBirthDateMutationVariables = Exact<{
  id: Scalars['UUID'];
  birthDate?: Maybe<Scalars['LocalDate']>;
}>;


export type ListedProductSetListingBirthDateMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetListingBirthDate?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductSetListingCommentsMutationVariables = Exact<{
  id: Scalars['UUID'];
  comments?: Maybe<Scalars['String']>;
}>;


export type ListedProductSetListingCommentsMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetListingComments?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductSetListingDimensionsMutationVariables = Exact<{
  id: Scalars['UUID'];
  length?: Maybe<Scalars['BigDecimal']>;
  width?: Maybe<Scalars['BigDecimal']>;
  height?: Maybe<Scalars['BigDecimal']>;
}>;


export type ListedProductSetListingDimensionsMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetListingDimensions?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductSetListingIdentifierMutationVariables = Exact<{
  id: Scalars['UUID'];
  identifier: Scalars['String'];
}>;


export type ListedProductSetListingIdentifierMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetListingIdentifier?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductSetListingMediaSequenceMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
  sequence: Scalars['Int'];
}>;


export type ListedProductSetListingMediaSequenceMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetListingMediaSequence?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductSetListingPriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  price: Scalars['BigDecimal'];
}>;


export type ListedProductSetListingPriceMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetListingPrice?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductSetListingQuantityMutationVariables = Exact<{
  id: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type ListedProductSetListingQuantityMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetListingQuantity?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductSetListingSexMutationVariables = Exact<{
  id: Scalars['UUID'];
  sex?: Maybe<SexType>;
}>;


export type ListedProductSetListingSexMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetListingSex?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductSetListingTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  title: Scalars['String'];
}>;


export type ListedProductSetListingTitleMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetListingTitle?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductSetListingWarehouseMutationVariables = Exact<{
  id: Scalars['UUID'];
  warehouseId: Scalars['UUID'];
}>;


export type ListedProductSetListingWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetListingWarehouse?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductSetListingWeightMutationVariables = Exact<{
  id: Scalars['UUID'];
  weight?: Maybe<Scalars['BigDecimal']>;
}>;


export type ListedProductSetListingWeightMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetListingWeight?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductSetListingWeightDateMutationVariables = Exact<{
  id: Scalars['UUID'];
  weightDate?: Maybe<Scalars['LocalDate']>;
}>;


export type ListedProductSetListingWeightDateMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetListingWeightDate?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductSetListingWeightUnitMutationVariables = Exact<{
  id: Scalars['UUID'];
  listingWeightUnit?: Maybe<ListingWeightUnit>;
}>;


export type ListedProductSetListingWeightUnitMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetListingWeightUnit?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductSetLiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  live: Scalars['Boolean'];
}>;


export type ListedProductSetLiveMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetLive?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetMetaDescriptionMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaDescription?: Maybe<Scalars['String']>;
}>;


export type ListedProductSetMetaDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetMetaDescription?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetMetaKeywordsMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaKeywords?: Maybe<Scalars['String']>;
}>;


export type ListedProductSetMetaKeywordsMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetMetaKeywords?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetMetaTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaTitle?: Maybe<Scalars['String']>;
}>;


export type ListedProductSetMetaTitleMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetMetaTitle?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetPrepTypeMutationVariables = Exact<{
  id: Scalars['UUID'];
  prepType?: Maybe<PrepType>;
}>;


export type ListedProductSetPrepTypeMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetPrepType?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetPriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  price: Scalars['BigDecimal'];
}>;


export type ListedProductSetPriceMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetPrice?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetProductMediaSequenceMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
  sequence: Scalars['Int'];
}>;


export type ListedProductSetProductMediaSequenceMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetProductMediaSequence?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetPublishedRevisionMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type ListedProductSetPublishedRevisionMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetPublishedRevision?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetShippingNeedsTypeMutationVariables = Exact<{
  id: Scalars['UUID'];
  shippingNeedsType: ShippingNeedsType;
}>;


export type ListedProductSetShippingNeedsTypeMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetShippingNeedsType?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetShippingRuleSetMutationVariables = Exact<{
  productId: Scalars['UUID'];
  shippingRuleSetId: Scalars['UUID'];
}>;


export type ListedProductSetShippingRuleSetMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetShippingRuleSet?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetSkuMutationVariables = Exact<{
  id: Scalars['UUID'];
  sku: Scalars['String'];
}>;


export type ListedProductSetSkuMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetSku?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetSlugMutationVariables = Exact<{
  id: Scalars['UUID'];
  slug: Scalars['String'];
}>;


export type ListedProductSetSlugMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetSlug?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  title: Scalars['String'];
}>;


export type ListedProductSetTitleMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetTitle?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetUpcMutationVariables = Exact<{
  id: Scalars['UUID'];
  upc?: Maybe<Scalars['String']>;
}>;


export type ListedProductSetUpcMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetUpc?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetVisibleMutationVariables = Exact<{
  id: Scalars['UUID'];
  visible: Scalars['Boolean'];
}>;


export type ListedProductSetVisibleMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetVisible?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductSetWeightMutationVariables = Exact<{
  id: Scalars['UUID'];
  weight: Scalars['BigDecimal'];
}>;


export type ListedProductSetWeightMutation = (
  { __typename?: 'Mutation' }
  & { listedProductSetWeight?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductUploadImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type ListedProductUploadImageMutation = (
  { __typename?: 'Mutation' }
  & { listedProductUploadImage?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Admin_Listed_Product_EntityFragment
  )> }
);

export type ListedProductUploadListingImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type ListedProductUploadListingImageMutation = (
  { __typename?: 'Mutation' }
  & { listedProductUploadListingImage?: Maybe<(
    { __typename?: 'ListingEntity' }
    & Admin_Listing_EntityFragment
  )> }
);

export type ListedProductWithCrossSellsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ListedProductWithCrossSellsQuery = (
  { __typename?: 'Query' }
  & { listedProductBySlug?: Maybe<(
    { __typename?: 'ListedProductEntity' }
    & Pick<ListedProductEntity, 'id' | 'cls' | 'sku' | 'slug' | 'title' | 'quantityAvailableForSale' | 'price' | 'salePrice'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>>, shippingRuleSet?: Maybe<(
      { __typename?: 'ShippingRuleSetEntity' }
      & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
        { __typename?: 'LiveArrivalGuaranteeEntity' }
        & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
      )>>> }
    )>, crossSellItems?: Maybe<Array<Maybe<(
      { __typename?: 'CrossSellItemEntity' }
      & Pick<CrossSellItemEntity, 'id' | 'position'>
      & { crossSellProduct?: Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'id' | 'cls' | 'active' | 'title' | 'sku' | 'slug' | 'countRating' | 'averageRating' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'variationKey' | 'featured'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type MakingStockStatusMutationVariables = Exact<{
  warehouse: Scalars['String'];
  departmentId?: Maybe<Scalars['UUID']>;
  requiresPrep?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
}>;


export type MakingStockStatusMutation = (
  { __typename?: 'Mutation' }
  & { makingStockStatus?: Maybe<Array<Maybe<(
    { __typename?: 'InventoryDetails' }
    & Pick<InventoryDetails, 'warehouseQuantityAvailable' | 'warehouseWeeklyConsumptionRate' | 'warehouseWeeklyConsumptionVariance' | 'warehouseRunOutDays50' | 'warehouseRunOutDays85' | 'warehouseRunOutDays97'>
    & { product?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'title' | 'sku'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>> }
);

export type Admin_Media_EntityFragment = (
  { __typename?: 'MediaEntity' }
  & Pick<MediaEntity, 'id' | 'keywords' | 'title' | 'url'>
  & { categories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'name'>
    & { ancestors?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'name'>
    )>>> }
  )>>>, productMedias?: Maybe<Array<Maybe<(
    { __typename?: 'ProductMediaEntity' }
    & Pick<ProductMediaEntity, 'id'>
    & { product?: Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'sku' | 'title'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'url'>
      )>>> }
    )> }
  )>>> }
);

export type MediaFilterQueryVariables = Exact<{
  title?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  pageable: GraphQlPageableInput;
}>;


export type MediaFilterQuery = (
  { __typename?: 'Query' }
  & { mediaFilter?: Maybe<(
    { __typename?: 'GraphQLPage_MediaEntity' }
    & Pick<GraphQlPage_MediaEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'id' | 'keywords' | 'title' | 'url'>
    )>>> }
  )> }
);

export type MediaInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type MediaInfoQuery = (
  { __typename?: 'Query' }
  & { mediaInfo?: Maybe<(
    { __typename?: 'MediaEntity' }
    & Admin_Media_EntityFragment
  )> }
);

export type MediaSetKeywordsMutationVariables = Exact<{
  id: Scalars['UUID'];
  keywords?: Maybe<Scalars['String']>;
}>;


export type MediaSetKeywordsMutation = (
  { __typename?: 'Mutation' }
  & { mediaSetKeywords?: Maybe<(
    { __typename?: 'MediaEntity' }
    & Admin_Media_EntityFragment
  )> }
);

export type MediaSetTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  title: Scalars['String'];
}>;


export type MediaSetTitleMutation = (
  { __typename?: 'Mutation' }
  & { mediaSetTitle?: Maybe<(
    { __typename?: 'MediaEntity' }
    & Admin_Media_EntityFragment
  )> }
);

export type MediaUploadImageMutationVariables = Exact<{
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type MediaUploadImageMutation = (
  { __typename?: 'Mutation' }
  & { mediaUploadImage?: Maybe<(
    { __typename?: 'MediaEntity' }
    & Pick<MediaEntity, 'id' | 'keywords' | 'title' | 'url'>
  )> }
);

export type AffiliateAddProductListMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  htmlContent: Scalars['String'];
}>;


export type AffiliateAddProductListMutation = (
  { __typename?: 'Mutation' }
  & { affiliateAddProductList?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type AffiliateAddProductListItemsMutationVariables = Exact<{
  listId: Scalars['UUID'];
  productIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type AffiliateAddProductListItemsMutation = (
  { __typename?: 'Mutation' }
  & { affiliateAddProductListItems?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Admin_Affiliate_Product_ListFragment
  )> }
);

export type AffiliateBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type AffiliateBySlugQuery = (
  { __typename?: 'Query' }
  & { affiliateBySlug?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Pick<PartnerEntity, 'affiliateAttributionToken' | 'affiliateBiography' | 'name' | 'slug'>
    & { affiliateProductLists?: Maybe<Array<Maybe<(
      { __typename?: 'AffiliateProductListEntity' }
      & Pick<AffiliateProductListEntity, 'name' | 'slug'>
      & { affiliateProductListItems?: Maybe<Array<Maybe<(
        { __typename?: 'AffiliateProductListItemEntity' }
        & { product?: Maybe<(
          { __typename?: 'ProductEntity' }
          & { medias?: Maybe<Array<Maybe<(
            { __typename?: 'ProductMediaEntity' }
            & Pick<ProductMediaEntity, 'url'>
          )>>> }
        )> }
      )>>> }
    )>>> }
  )> }
);

export type AffiliateDeleteProductListMutationVariables = Exact<{
  id: Scalars['UUID'];
  listId: Scalars['UUID'];
}>;


export type AffiliateDeleteProductListMutation = (
  { __typename?: 'Mutation' }
  & { affiliateDeleteProductList?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type AffiliateGetProductListByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AffiliateGetProductListByIdQuery = (
  { __typename?: 'Query' }
  & { affiliateGetProductListById?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Admin_Affiliate_Product_ListFragment
  )> }
);

export type AffiliateGetProductListBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
  affiliateSlug: Scalars['String'];
}>;


export type AffiliateGetProductListBySlugQuery = (
  { __typename?: 'Query' }
  & { affiliateGetProductListBySlug?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Pick<AffiliateProductListEntity, 'htmlContent' | 'name' | 'slug'>
    & { affiliateProductListItems?: Maybe<Array<Maybe<(
      { __typename?: 'AffiliateProductListItemEntity' }
      & { product?: Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'cls' | 'price' | 'salePrice' | 'sku' | 'slug' | 'title' | 'quantityAvailableForSale'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'url'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type AffiliateRemoveProductListItemMutationVariables = Exact<{
  listId: Scalars['UUID'];
  affiliateProductListItemId: Scalars['UUID'];
}>;


export type AffiliateRemoveProductListItemMutation = (
  { __typename?: 'Mutation' }
  & { affiliateRemoveProductListItem?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Admin_Affiliate_Product_ListFragment
  )> }
);

export type AffiliateSetAffiliateBiographyMutationVariables = Exact<{
  id: Scalars['UUID'];
  affiliateBiography: Scalars['String'];
}>;


export type AffiliateSetAffiliateBiographyMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetAffiliateBiography?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type AffiliateSetAttributionPeriodMutationVariables = Exact<{
  id: Scalars['UUID'];
  period: Scalars['Long'];
}>;


export type AffiliateSetAttributionPeriodMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetAttributionPeriod?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type AffiliateSetCommissionMutationVariables = Exact<{
  id: Scalars['UUID'];
  commission: Scalars['BigDecimal'];
}>;


export type AffiliateSetCommissionMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetCommission?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type AffiliateSetProductListHtmlContentMutationVariables = Exact<{
  listId: Scalars['UUID'];
  htmlContent: Scalars['String'];
}>;


export type AffiliateSetProductListHtmlContentMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetProductListHtmlContent?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Admin_Affiliate_Product_ListFragment
  )> }
);

export type AffiliateSetProductListItemPositionMutationVariables = Exact<{
  listId: Scalars['UUID'];
  affiliateProductListItemId: Scalars['UUID'];
  position: Scalars['Int'];
}>;


export type AffiliateSetProductListItemPositionMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetProductListItemPosition?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Admin_Affiliate_Product_ListFragment
  )> }
);

export type AffiliateSetProductListNameMutationVariables = Exact<{
  listId: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type AffiliateSetProductListNameMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetProductListName?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Admin_Affiliate_Product_ListFragment
  )> }
);

export type AffiliateSetProductListSequenceMutationVariables = Exact<{
  id: Scalars['UUID'];
  listId: Scalars['UUID'];
  sequence: Scalars['Int'];
}>;


export type AffiliateSetProductListSequenceMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetProductListSequence?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type AffiliateSetProductListSlugMutationVariables = Exact<{
  listId: Scalars['UUID'];
  slug: Scalars['String'];
}>;


export type AffiliateSetProductListSlugMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetProductListSlug?: Maybe<(
    { __typename?: 'AffiliateProductListEntity' }
    & Admin_Affiliate_Product_ListFragment
  )> }
);

export type AffiliateSetSlugMutationVariables = Exact<{
  id: Scalars['UUID'];
  slug: Scalars['String'];
}>;


export type AffiliateSetSlugMutation = (
  { __typename?: 'Mutation' }
  & { affiliateSetSlug?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type PartnerJoinMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
}>;


export type PartnerJoinMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'partnerJoin'>
);

export type PartnerCreateMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
}>;


export type PartnerCreateMutation = (
  { __typename?: 'Mutation' }
  & { partnerCreate?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Pick<PartnerEntity, 'id' | 'affiliate' | 'dropshipper' | 'name' | 'reseller'>
  )> }
);

export type PartnerFilterQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
}>;


export type PartnerFilterQuery = (
  { __typename?: 'Query' }
  & { partnerFilter?: Maybe<(
    { __typename?: 'GraphQLPage_PartnerEntity' }
    & Pick<GraphQlPage_PartnerEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'PartnerEntity' }
      & Pick<PartnerEntity, 'id' | 'affiliate' | 'dropshipper' | 'email' | 'name' | 'reseller'>
    )>>> }
  )> }
);

export type PartnerInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PartnerInfoQuery = (
  { __typename?: 'Query' }
  & { partnerInfo?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type PartnerListDropshippersQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerListDropshippersQuery = (
  { __typename?: 'Query' }
  & { partnerListDropshippers?: Maybe<Array<Maybe<(
    { __typename?: 'PartnerEntity' }
    & Pick<PartnerEntity, 'id' | 'affiliate' | 'dropshipper' | 'email' | 'name' | 'reseller'>
  )>>> }
);

export type PartnerListResellersQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerListResellersQuery = (
  { __typename?: 'Query' }
  & { partnerListResellers?: Maybe<Array<Maybe<(
    { __typename?: 'PartnerEntity' }
    & Pick<PartnerEntity, 'id' | 'affiliate' | 'dropshipper' | 'email' | 'name' | 'reseller'>
  )>>> }
);

export type PartnerSetAffiliateMutationVariables = Exact<{
  id: Scalars['UUID'];
  affiliate: Scalars['Boolean'];
}>;


export type PartnerSetAffiliateMutation = (
  { __typename?: 'Mutation' }
  & { partnerSetAffiliate?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type PartnerSetDropshipperMutationVariables = Exact<{
  id: Scalars['UUID'];
  dropshipper: Scalars['Boolean'];
}>;


export type PartnerSetDropshipperMutation = (
  { __typename?: 'Mutation' }
  & { partnerSetDropshipper?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type PartnerSetEmailMutationVariables = Exact<{
  id: Scalars['UUID'];
  email: Scalars['String'];
}>;


export type PartnerSetEmailMutation = (
  { __typename?: 'Mutation' }
  & { partnerSetEmail?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type PartnerSetNameMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type PartnerSetNameMutation = (
  { __typename?: 'Mutation' }
  & { partnerSetName?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type PartnerSetResellerMutationVariables = Exact<{
  id: Scalars['UUID'];
  reseller: Scalars['Boolean'];
}>;


export type PartnerSetResellerMutation = (
  { __typename?: 'Mutation' }
  & { partnerSetReseller?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type PartnerUpdateAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<AddressType>;
}>;


export type PartnerUpdateAddressMutation = (
  { __typename?: 'Mutation' }
  & { partnerUpdateAddress?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type ResellerSetResellerShippingRateModeMutationVariables = Exact<{
  id: Scalars['UUID'];
  resellerShippingRateMode: ResellerShippingRateMode;
}>;


export type ResellerSetResellerShippingRateModeMutation = (
  { __typename?: 'Mutation' }
  & { resellerSetResellerShippingRateMode?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type ResellerSetResellerTaxModeMutationVariables = Exact<{
  id: Scalars['UUID'];
  resellerTaxMode: ResellerTaxMode;
}>;


export type ResellerSetResellerTaxModeMutation = (
  { __typename?: 'Mutation' }
  & { resellerSetResellerTaxMode?: Maybe<(
    { __typename?: 'PartnerEntity' }
    & Admin_Partner_EntityFragment
  )> }
);

export type DropshipperProfileCreateMutationVariables = Exact<{
  simpleProductId: Scalars['UUID'];
  dropshipperId: Scalars['UUID'];
  sku: Scalars['String'];
  title: Scalars['String'];
  cost: Scalars['BigDecimal'];
}>;


export type DropshipperProfileCreateMutation = (
  { __typename?: 'Mutation' }
  & { dropshipperProfileCreate?: Maybe<(
    { __typename?: 'DropshipperProfileEntity' }
    & Pick<DropshipperProfileEntity, 'id' | 'dropshipperSku' | 'title' | 'cost'>
  )> }
);

export type PetcoDsProfileCreateMutationVariables = Exact<{
  productId: Scalars['UUID'];
  sku: Scalars['String'];
  description: Scalars['String'];
  surcharge: Scalars['BigDecimal'];
}>;


export type PetcoDsProfileCreateMutation = (
  { __typename?: 'Mutation' }
  & { petcoDsProfileCreate?: Maybe<(
    { __typename?: 'PetcoDropshipProfileEntity' }
    & Pick<PetcoDropshipProfileEntity, 'id' | 'sku' | 'description' | 'surcharge'>
  )> }
);

export type PetcoSdProfileCreateMutationVariables = Exact<{
  productId: Scalars['UUID'];
  sku: Scalars['String'];
  description: Scalars['String'];
}>;


export type PetcoSdProfileCreateMutation = (
  { __typename?: 'Mutation' }
  & { petcoSdProfileCreate?: Maybe<(
    { __typename?: 'PetcoStoreDirectProfileEntity' }
    & Pick<PetcoStoreDirectProfileEntity, 'id' | 'sku' | 'description'>
  )> }
);

export type ResellerProfileCreateMutationVariables = Exact<{
  productId: Scalars['UUID'];
  resellerId: Scalars['UUID'];
  sku: Scalars['String'];
  title: Scalars['String'];
  price: Scalars['BigDecimal'];
  retail?: Maybe<Scalars['BigDecimal']>;
}>;


export type ResellerProfileCreateMutation = (
  { __typename?: 'Mutation' }
  & { resellerProfileCreate?: Maybe<(
    { __typename?: 'ResellerProfileEntity' }
    & Pick<ResellerProfileEntity, 'id' | 'resellerSku' | 'title' | 'price' | 'retail'>
  )> }
);

export type ProductAddCategoryMutationVariables = Exact<{
  productId: Scalars['UUID'];
  categoryId: Scalars['UUID'];
}>;


export type ProductAddCategoryMutation = (
  { __typename?: 'Mutation' }
  & { productAddCategory?: Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id'>
    & { explicitCategories?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type ProductBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ProductBySlugQuery = (
  { __typename?: 'Query' }
  & { productBySlug?: Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'cls' | 'slug'>
  )> }
);

export type ProductFilterQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
  active?: Maybe<Scalars['Boolean']>;
  animal?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  minRating?: Maybe<Scalars['Long']>;
  minStock?: Maybe<Scalars['Long']>;
  sku?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
}>;


export type ProductFilterQuery = (
  { __typename?: 'Query' }
  & { productFilter?: Maybe<(
    { __typename?: 'GraphQLPage_ProductEntity' }
    & Pick<GraphQlPage_ProductEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'slug' | 'title' | 'sku' | 'upc' | 'price' | 'quantityAvailableForSale' | 'subscription'>
      & { shippingRuleSet?: Maybe<(
        { __typename?: 'ShippingRuleSetEntity' }
        & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
          { __typename?: 'LiveArrivalGuaranteeEntity' }
          & Pick<LiveArrivalGuaranteeEntity, 'id'>
        )>>>, destinationRestriction?: Maybe<(
          { __typename?: 'DestinationRestrictionEntity' }
          & Pick<DestinationRestrictionEntity, 'domesticOnly' | 'prohibitedStates'>
        )> }
      )>, medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )>>> }
  )> }
);

export type ProductListBySkusQueryVariables = Exact<{
  skus: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type ProductListBySkusQuery = (
  { __typename?: 'Query' }
  & { productListBySkus?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'cls' | 'sku' | 'title' | 'price'>
  )>>> }
);

export type ProductListBySkusForPedestalQueryVariables = Exact<{
  skus: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type ProductListBySkusForPedestalQuery = (
  { __typename?: 'Query' }
  & { productListBySkus?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'cls' | 'active' | 'sku' | 'slug' | 'title' | 'price' | 'quantityAvailableForSale'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'url'>
    )>>> }
  )>>> }
);

export type ProductListBySkusForSpotlightQueryVariables = Exact<{
  skus: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type ProductListBySkusForSpotlightQuery = (
  { __typename?: 'Query' }
  & { productListBySkus?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'cls' | 'active' | 'sku' | 'slug' | 'title' | 'price' | 'quantityAvailableForSale'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'url'>
    )>>>, explicitCategories?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'active' | 'wholesale' | 'name' | 'path' | 'slug'>
      & { ancestors?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'active' | 'wholesale' | 'name' | 'path' | 'slug'>
      )>>> }
    )>>> }
  )>>> }
);

export type ProductRemoveCategoryMutationVariables = Exact<{
  productId: Scalars['UUID'];
  categoryId: Scalars['UUID'];
}>;


export type ProductRemoveCategoryMutation = (
  { __typename?: 'Mutation' }
  & { productRemoveCategory?: Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id'>
    & { explicitCategories?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type ProductRequestBackInStockNotificationMutationVariables = Exact<{
  id: Scalars['UUID'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}>;


export type ProductRequestBackInStockNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'productRequestBackInStockNotification'>
);

export type ProductSearchQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
  query?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['String']>;
  promotion?: Maybe<Scalars['String']>;
  animal?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  inStock?: Maybe<Scalars['Boolean']>;
  subscription?: Maybe<Scalars['Boolean']>;
  minPrice?: Maybe<Scalars['BigDecimal']>;
  maxPrice?: Maybe<Scalars['BigDecimal']>;
  minRating?: Maybe<Scalars['Float']>;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type ProductSearchQuery = (
  { __typename?: 'Query' }
  & { productSearch?: Maybe<(
    { __typename?: 'SearchResults' }
    & { category?: Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        & { children?: Maybe<Array<Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        )>>> }
      )>, children?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'active' | 'slug' | 'path' | 'name'>
      )>>> }
    )>, animal?: Maybe<(
      { __typename?: 'AnimalEntity' }
      & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'AnimalEntity' }
        & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
        & { children?: Maybe<Array<Maybe<(
          { __typename?: 'AnimalEntity' }
          & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
        )>>> }
      )>, children?: Maybe<Array<Maybe<(
        { __typename?: 'AnimalEntity' }
        & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
      )>>> }
    )>, brand?: Maybe<(
      { __typename?: 'BrandEntity' }
      & Pick<BrandEntity, 'id' | 'slug' | 'name'>
    )>, products?: Maybe<(
      { __typename?: 'GraphQLPage_ProductEntity' }
      & Pick<GraphQlPage_ProductEntity, 'count' | 'page' | 'pageSize'>
      & { data?: Maybe<Array<Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'averageRating' | 'countRating' | 'featured'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type ProductSetDepartmentMutationVariables = Exact<{
  productId: Scalars['UUID'];
  departmentId: Scalars['UUID'];
}>;


export type ProductSetDepartmentMutation = (
  { __typename?: 'Mutation' }
  & { productSetDepartment?: Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id'>
    & { explicitDepartment?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
      )> }
    )> }
  )> }
);

export type Customer_PromotionFragment = (
  { __typename?: 'PromotionEntity' }
  & Pick<PromotionEntity, 'id' | 'discountAmount' | 'promotionType' | 'eligibleCartValue' | 'eligibleCouponCode' | 'eligibleUserType' | 'endDate' | 'name' | 'permitStacking' | 'slug' | 'startDate' | 'htmlContent' | 'teaser'>
);

export type Admin_PromotionFragment = (
  { __typename?: 'PromotionEntity' }
  & Pick<PromotionEntity, 'id' | 'active' | 'discountAmount' | 'promotionType' | 'eligibleCartValue' | 'eligibleCouponCode' | 'eligibleItemCount' | 'eligibleItemValue' | 'eligibleUserType' | 'endDate' | 'htmlContent' | 'name' | 'permitStacking' | 'slug' | 'startDate' | 'teaser'>
);

export type Admin_Promotion_ListsFragment = (
  { __typename?: 'PromotionEntity' }
  & { explicitProducts?: Maybe<Array<Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'sku' | 'title'>
  )>>>, categories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
  )>>>, departments?: Maybe<Array<Maybe<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'id' | 'name'>
  )>>>, brands?: Maybe<Array<Maybe<(
    { __typename?: 'BrandEntity' }
    & Pick<BrandEntity, 'id' | 'name'>
  )>>> }
);

export type PromotionAddBrandsMutationVariables = Exact<{
  id: Scalars['UUID'];
  brandIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type PromotionAddBrandsMutation = (
  { __typename?: 'Mutation' }
  & { promotionAddBrands?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
    & Admin_Promotion_ListsFragment
  )> }
);

export type PromotionAddCategoriesMutationVariables = Exact<{
  id: Scalars['UUID'];
  categoryIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type PromotionAddCategoriesMutation = (
  { __typename?: 'Mutation' }
  & { promotionAddCategories?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
    & Admin_Promotion_ListsFragment
  )> }
);

export type PromotionAddDepartmentsMutationVariables = Exact<{
  id: Scalars['UUID'];
  departmentIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type PromotionAddDepartmentsMutation = (
  { __typename?: 'Mutation' }
  & { promotionAddDepartments?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
    & Admin_Promotion_ListsFragment
  )> }
);

export type PromotionAddExplicitProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  explicitProductIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type PromotionAddExplicitProductsMutation = (
  { __typename?: 'Mutation' }
  & { promotionAddExplicitProducts?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
    & Admin_Promotion_ListsFragment
  )> }
);

export type PromotionBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type PromotionBySlugQuery = (
  { __typename?: 'Query' }
  & { promotionBySlug?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Customer_PromotionFragment
  )> }
);

export type PromotionCloneMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  slug: Scalars['String'];
}>;


export type PromotionCloneMutation = (
  { __typename?: 'Mutation' }
  & { promotionClone?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
    & Admin_Promotion_ListsFragment
  )> }
);

export type PromotionCreateMutationVariables = Exact<{
  name: Scalars['String'];
  slug: Scalars['String'];
  promotionType: PromotionType;
  permitStacking: Scalars['Boolean'];
  startDate?: Maybe<Scalars['Instant']>;
  endDate?: Maybe<Scalars['Instant']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
  departmentIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
  eligibleUserType?: Maybe<UserType>;
  eligibleCouponCode?: Maybe<Scalars['String']>;
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
  eligibleItemValue?: Maybe<Scalars['BigDecimal']>;
  eligibleItemCount?: Maybe<Scalars['Long']>;
  discountAmount?: Maybe<Scalars['BigDecimal']>;
}>;


export type PromotionCreateMutation = (
  { __typename?: 'Mutation' }
  & { promotionCreate?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
    & Admin_Promotion_ListsFragment
  )> }
);

export type PromotionDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PromotionDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'promotionDelete'>
);

export type PromotionEvaluateImplicitProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PromotionEvaluateImplicitProductsMutation = (
  { __typename?: 'Mutation' }
  & { promotionEvaluateImplicitProducts?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
    & Admin_Promotion_ListsFragment
  )> }
);

export type PromotionFilterImplicitProductsQueryVariables = Exact<{
  id: Scalars['UUID'];
  page: GraphQlPageableInput;
  sku?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type PromotionFilterImplicitProductsQuery = (
  { __typename?: 'Query' }
  & { promotionFilterImplicitProducts?: Maybe<(
    { __typename?: 'GraphQLPage_ProductEntity' }
    & Pick<GraphQlPage_ProductEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'sku' | 'title'>
    )>>> }
  )> }
);

export type PromotionInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PromotionInfoQuery = (
  { __typename?: 'Query' }
  & { promotionInfo?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
    & Admin_Promotion_ListsFragment
  )> }
);

export type PromotionListQueryVariables = Exact<{
  pageable?: Maybe<GraphQlPageableInput>;
}>;


export type PromotionListQuery = (
  { __typename?: 'Query' }
  & { promotionList?: Maybe<(
    { __typename?: 'GraphQLPage_PromotionEntity' }
    & Pick<GraphQlPage_PromotionEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'PromotionEntity' }
      & Pick<PromotionEntity, 'id' | 'active' | 'discountAmount' | 'promotionType' | 'eligibleCartValue' | 'eligibleCouponCode' | 'eligibleItemCount' | 'eligibleItemValue' | 'eligibleUserType' | 'endDate' | 'name' | 'permitStacking' | 'slug' | 'startDate'>
    )>>> }
  )> }
);

export type PromotionRemoveBrandsMutationVariables = Exact<{
  id: Scalars['UUID'];
  brandIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type PromotionRemoveBrandsMutation = (
  { __typename?: 'Mutation' }
  & { promotionRemoveBrands?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
    & Admin_Promotion_ListsFragment
  )> }
);

export type PromotionRemoveCategoriesMutationVariables = Exact<{
  id: Scalars['UUID'];
  categoryIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type PromotionRemoveCategoriesMutation = (
  { __typename?: 'Mutation' }
  & { promotionRemoveCategories?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
    & Admin_Promotion_ListsFragment
  )> }
);

export type PromotionRemoveDepartmentsMutationVariables = Exact<{
  id: Scalars['UUID'];
  departmentIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type PromotionRemoveDepartmentsMutation = (
  { __typename?: 'Mutation' }
  & { promotionRemoveDepartments?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
    & Admin_Promotion_ListsFragment
  )> }
);

export type PromotionRemoveExplicitProductsMutationVariables = Exact<{
  id: Scalars['UUID'];
  explicitProductIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type PromotionRemoveExplicitProductsMutation = (
  { __typename?: 'Mutation' }
  & { promotionRemoveExplicitProducts?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
    & Admin_Promotion_ListsFragment
  )> }
);

export type PromotionSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type PromotionSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetActive?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetDiscountAmountMutationVariables = Exact<{
  id: Scalars['UUID'];
  discountAmount: Scalars['BigDecimal'];
}>;


export type PromotionSetDiscountAmountMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetDiscountAmount?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetEligibleCartValueMutationVariables = Exact<{
  id: Scalars['UUID'];
  eligibleCartValue?: Maybe<Scalars['BigDecimal']>;
}>;


export type PromotionSetEligibleCartValueMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetEligibleCartValue?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetEligibleCouponCodeMutationVariables = Exact<{
  id: Scalars['UUID'];
  eligibleCouponCode?: Maybe<Scalars['String']>;
}>;


export type PromotionSetEligibleCouponCodeMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetEligibleCouponCode?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetEligibleItemCountMutationVariables = Exact<{
  id: Scalars['UUID'];
  eligibleItemCount?: Maybe<Scalars['Long']>;
}>;


export type PromotionSetEligibleItemCountMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetEligibleItemCount?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetEligibleItemValueMutationVariables = Exact<{
  id: Scalars['UUID'];
  eligibleItemValue?: Maybe<Scalars['BigDecimal']>;
}>;


export type PromotionSetEligibleItemValueMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetEligibleItemValue?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetEligibleUserTypeMutationVariables = Exact<{
  id: Scalars['UUID'];
  eligibleUserType?: Maybe<UserType>;
}>;


export type PromotionSetEligibleUserTypeMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetEligibleUserType?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetEndDateMutationVariables = Exact<{
  id: Scalars['UUID'];
  endDate?: Maybe<Scalars['Instant']>;
}>;


export type PromotionSetEndDateMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetEndDate?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetHtmlContentMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type PromotionSetHtmlContentMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetHtmlContent?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetNameMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type PromotionSetNameMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetName?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetPermitStackingMutationVariables = Exact<{
  id: Scalars['UUID'];
  permitStacking: Scalars['Boolean'];
}>;


export type PromotionSetPermitStackingMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetPermitStacking?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetPromotionTypeMutationVariables = Exact<{
  id: Scalars['UUID'];
  promotionType: PromotionType;
}>;


export type PromotionSetPromotionTypeMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetPromotionType?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetSlugMutationVariables = Exact<{
  id: Scalars['UUID'];
  slug: Scalars['String'];
}>;


export type PromotionSetSlugMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetSlug?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetStartDateMutationVariables = Exact<{
  id: Scalars['UUID'];
  startDate?: Maybe<Scalars['Instant']>;
}>;


export type PromotionSetStartDateMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetStartDate?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PromotionSetTeaserMutationVariables = Exact<{
  id: Scalars['UUID'];
  teaser?: Maybe<Scalars['String']>;
}>;


export type PromotionSetTeaserMutation = (
  { __typename?: 'Mutation' }
  & { promotionSetTeaser?: Maybe<(
    { __typename?: 'PromotionEntity' }
    & Admin_PromotionFragment
  )> }
);

export type PurchaseOrderCloseMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseOrderCloseMutation = (
  { __typename?: 'Mutation' }
  & { purchaseOrderClose?: Maybe<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'id' | 'status'>
  )> }
);

export type PurchaseOrderDeleteItemMutationVariables = Exact<{
  purchaseOrderId: Scalars['UUID'];
  purchaseOrderItemId: Scalars['UUID'];
}>;


export type PurchaseOrderDeleteItemMutation = (
  { __typename?: 'Mutation' }
  & { purchaseOrderDeleteItem?: Maybe<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'id'>
    & { purchaseOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseOrderItemEntity' }
      & Pick<PurchaseOrderItemEntity, 'id'>
    )>>> }
  )> }
);

export type PurchaseOrderEditItemQtyMutationVariables = Exact<{
  purchaseOrderId: Scalars['UUID'];
  purchaseOrderItemId: Scalars['UUID'];
  quantity: Scalars['Long'];
}>;


export type PurchaseOrderEditItemQtyMutation = (
  { __typename?: 'Mutation' }
  & { purchaseOrderEditItemQty?: Maybe<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'id'>
    & { purchaseOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseOrderItemEntity' }
      & Pick<PurchaseOrderItemEntity, 'id' | 'total'>
    )>>> }
  )> }
);

export type PurchaseOrderFilterQueryVariables = Exact<{
  orderNumber?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  status?: Maybe<PurchaseOrderStatus>;
  sort?: Maybe<GraphQlSortInput>;
  pageable: GraphQlPageableInput;
}>;


export type PurchaseOrderFilterQuery = (
  { __typename?: 'Query' }
  & { purchaseOrderFilter?: Maybe<(
    { __typename?: 'GraphQLPage_PurchaseOrderEntity' }
    & Pick<GraphQlPage_PurchaseOrderEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseOrderEntity' }
      & Pick<PurchaseOrderEntity, 'id' | 'cls' | 'placedTime' | 'orderNumber' | 'status'>
      & { supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'name'>
      )>, warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'name'>
      )> }
    )>>> }
  )> }
);

export type PurchaseOrderGenerateCsvMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseOrderGenerateCsvMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'purchaseOrderGenerateCSV'>
);

export type PurchaseOrderGeneratePdfMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseOrderGeneratePdfMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'purchaseOrderGeneratePDF'>
);

export type PurchaseOrderInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseOrderInfoQuery = (
  { __typename?: 'Query' }
  & { purchaseOrderInfo?: Maybe<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'cls' | 'id' | 'orderNumber' | 'alternateOrderNumber' | 'placedTime' | 'expectedTime' | 'status'>
    & { purchaseOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseOrderItemEntity' }
      & Pick<PurchaseOrderItemEntity, 'cls' | 'id' | 'name' | 'sku' | 'received' | 'total'>
      & { simpleProduct?: Maybe<(
        { __typename?: 'SimpleProductEntity' }
        & Pick<SimpleProductEntity, 'id'>
      )> }
    )>>>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'name' | 'phone' | 'email' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'state' | 'postalCode' | 'country'>
    )>, warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'name' | 'line1' | 'line2' | 'state' | 'postalCode' | 'country'>
    )> }
  )> }
);

export type PurchaseOrderReOpenMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseOrderReOpenMutation = (
  { __typename?: 'Mutation' }
  & { purchaseOrderReOpen?: Maybe<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'id' | 'status'>
  )> }
);

export type PurchaseRequestApproveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseRequestApproveMutation = (
  { __typename?: 'Mutation' }
  & { purchaseRequestApprove?: Maybe<(
    { __typename?: 'PurchaseRequestEntity' }
    & Pick<PurchaseRequestEntity, 'id' | 'requestNumber' | 'purchaseRequestStatus' | 'requester' | 'approver' | 'orderer' | 'receiver' | 'supplier' | 'requestedDate' | 'orderedDate' | 'receivedDate' | 'estimatedAmount' | 'finalAmount' | 'comments'>
    & { team?: Maybe<(
      { __typename?: 'TeamEntity' }
      & Pick<TeamEntity, 'id' | 'name' | 'slug'>
    )>, purchaseRequestItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseRequestItemEntity' }
      & Pick<PurchaseRequestItemEntity, 'id' | 'quantity' | 'name' | 'amount'>
    )>>> }
  )> }
);

export type PurchaseRequestCreateMutationVariables = Exact<{
  team: Scalars['String'];
  supplier?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<PurchaseRequestItemInput>> | Maybe<PurchaseRequestItemInput>>;
}>;


export type PurchaseRequestCreateMutation = (
  { __typename?: 'Mutation' }
  & { purchaseRequestCreate?: Maybe<(
    { __typename?: 'PurchaseRequestEntity' }
    & Pick<PurchaseRequestEntity, 'id' | 'requestNumber' | 'purchaseRequestStatus' | 'requester' | 'approver' | 'orderer' | 'receiver' | 'supplier' | 'requestedDate' | 'orderedDate' | 'receivedDate' | 'estimatedAmount' | 'finalAmount' | 'comments'>
    & { team?: Maybe<(
      { __typename?: 'TeamEntity' }
      & Pick<TeamEntity, 'id' | 'name' | 'slug'>
    )>, purchaseRequestItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseRequestItemEntity' }
      & Pick<PurchaseRequestItemEntity, 'id' | 'quantity' | 'name' | 'amount'>
    )>>> }
  )> }
);

export type PurchaseRequestDenyMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseRequestDenyMutation = (
  { __typename?: 'Mutation' }
  & { purchaseRequestDeny?: Maybe<(
    { __typename?: 'PurchaseRequestEntity' }
    & Pick<PurchaseRequestEntity, 'id' | 'requestNumber' | 'purchaseRequestStatus' | 'requester' | 'approver' | 'orderer' | 'receiver' | 'supplier' | 'requestedDate' | 'orderedDate' | 'receivedDate' | 'estimatedAmount' | 'finalAmount' | 'comments'>
    & { team?: Maybe<(
      { __typename?: 'TeamEntity' }
      & Pick<TeamEntity, 'id' | 'name' | 'slug'>
    )>, purchaseRequestItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseRequestItemEntity' }
      & Pick<PurchaseRequestItemEntity, 'id' | 'quantity' | 'name' | 'amount'>
    )>>> }
  )> }
);

export type PurchaseRequestFilterQueryVariables = Exact<{
  requestNumber?: Maybe<Scalars['String']>;
  pageable: GraphQlPageableInput;
  team?: Maybe<Scalars['String']>;
  status?: Maybe<PurchaseRequestStatus>;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type PurchaseRequestFilterQuery = (
  { __typename?: 'Query' }
  & { purchaseRequestFilter?: Maybe<(
    { __typename?: 'GraphQLPage_PurchaseRequestEntity' }
    & Pick<GraphQlPage_PurchaseRequestEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseRequestEntity' }
      & Pick<PurchaseRequestEntity, 'id' | 'requestNumber' | 'purchaseRequestStatus' | 'requester' | 'approver' | 'orderer' | 'receiver' | 'supplier' | 'requestedDate' | 'orderedDate' | 'receivedDate' | 'estimatedAmount' | 'finalAmount' | 'comments'>
      & { team?: Maybe<(
        { __typename?: 'TeamEntity' }
        & Pick<TeamEntity, 'id' | 'name' | 'slug'>
      )>, purchaseRequestItems?: Maybe<Array<Maybe<(
        { __typename?: 'PurchaseRequestItemEntity' }
        & Pick<PurchaseRequestItemEntity, 'id' | 'quantity' | 'name' | 'amount'>
      )>>> }
    )>>> }
  )> }
);

export type PurchaseRequestInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseRequestInfoQuery = (
  { __typename?: 'Query' }
  & { purchaseRequestInfo?: Maybe<(
    { __typename?: 'PurchaseRequestEntity' }
    & Pick<PurchaseRequestEntity, 'id' | 'requestNumber' | 'purchaseRequestStatus' | 'requester' | 'approver' | 'orderer' | 'receiver' | 'supplier' | 'requestedDate' | 'orderedDate' | 'receivedDate' | 'estimatedAmount' | 'finalAmount' | 'comments'>
    & { team?: Maybe<(
      { __typename?: 'TeamEntity' }
      & Pick<TeamEntity, 'id' | 'name' | 'slug'>
    )>, purchaseRequestItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseRequestItemEntity' }
      & Pick<PurchaseRequestItemEntity, 'id' | 'quantity' | 'name' | 'amount'>
    )>>> }
  )> }
);

export type PurchaseRequestOrderMutationVariables = Exact<{
  id: Scalars['UUID'];
  amount: Scalars['BigDecimal'];
}>;


export type PurchaseRequestOrderMutation = (
  { __typename?: 'Mutation' }
  & { purchaseRequestOrder?: Maybe<(
    { __typename?: 'PurchaseRequestEntity' }
    & Pick<PurchaseRequestEntity, 'id' | 'requestNumber' | 'purchaseRequestStatus' | 'requester' | 'approver' | 'orderer' | 'receiver' | 'supplier' | 'requestedDate' | 'orderedDate' | 'receivedDate' | 'estimatedAmount' | 'finalAmount' | 'comments'>
    & { team?: Maybe<(
      { __typename?: 'TeamEntity' }
      & Pick<TeamEntity, 'id' | 'name' | 'slug'>
    )>, purchaseRequestItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseRequestItemEntity' }
      & Pick<PurchaseRequestItemEntity, 'id' | 'quantity' | 'name' | 'amount'>
    )>>> }
  )> }
);

export type PurchaseRequestReceiveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PurchaseRequestReceiveMutation = (
  { __typename?: 'Mutation' }
  & { purchaseRequestReceive?: Maybe<(
    { __typename?: 'PurchaseRequestEntity' }
    & Pick<PurchaseRequestEntity, 'id' | 'requestNumber' | 'purchaseRequestStatus' | 'requester' | 'approver' | 'orderer' | 'receiver' | 'supplier' | 'requestedDate' | 'orderedDate' | 'receivedDate' | 'estimatedAmount' | 'finalAmount' | 'comments'>
    & { team?: Maybe<(
      { __typename?: 'TeamEntity' }
      & Pick<TeamEntity, 'id' | 'name' | 'slug'>
    )>, purchaseRequestItems?: Maybe<Array<Maybe<(
      { __typename?: 'PurchaseRequestItemEntity' }
      & Pick<PurchaseRequestItemEntity, 'id' | 'quantity' | 'name' | 'amount'>
    )>>> }
  )> }
);

export type QueueUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  zoneId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  priority: Scalars['Long'];
  template?: Maybe<Scalars['String']>;
  shippingNeedsType?: Maybe<ShippingNeedsType>;
  singlesOnly?: Maybe<Scalars['Boolean']>;
  combosOnly?: Maybe<Scalars['Boolean']>;
  enabled: Scalars['Boolean'];
}>;


export type QueueUpdateMutation = (
  { __typename?: 'Mutation' }
  & { queueUpdate?: Maybe<(
    { __typename?: 'QueueEntity' }
    & Pick<QueueEntity, 'id' | 'enabled' | 'name' | 'slug' | 'priority' | 'template' | 'shippingNeeds' | 'singlesOnly' | 'combosOnly'>
    & { zone?: Maybe<(
      { __typename?: 'ZoneEntity' }
      & Pick<ZoneEntity, 'id' | 'name'>
    )> }
  )> }
);

export type RedirectFindByPathMutationVariables = Exact<{
  path: Scalars['String'];
}>;


export type RedirectFindByPathMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'redirectFindByPath'>
);

export type RefundInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RefundInfoQuery = (
  { __typename?: 'Query' }
  & { refundInfo?: Maybe<(
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'id' | 'createdAt' | 'refundNumber' | 'repFirstName' | 'subTotal' | 'productDiscountTotal' | 'taxCode' | 'productTaxTotal' | 'shippingTotal' | 'handlingTotal' | 'shippingDiscountTotal' | 'shippingTaxTotal' | 'grandTotal'>
    & { refundItems?: Maybe<Array<Maybe<(
      { __typename?: 'RefundItemEntity' }
      & Pick<RefundItemEntity, 'id' | 'quantity'>
      & { salesOrderItem?: Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'sku' | 'name' | 'unitAmount' | 'lineAmount'>
      )> }
    )>>> }
  )> }
);

export type User_ReviewFragment = (
  { __typename?: 'ReviewEntity' }
  & Pick<ReviewEntity, 'id' | 'comments' | 'createdTime' | 'rating' | 'reviewer' | 'status' | 'title' | 'updatedTime'>
  & { medias?: Maybe<Array<Maybe<(
    { __typename?: 'MediaEntity' }
    & Pick<MediaEntity, 'id' | 'keywords' | 'title' | 'url'>
  )>>>, product?: Maybe<(
    { __typename?: 'ProductEntity' }
    & Pick<ProductEntity, 'id' | 'slug' | 'title' | 'cls'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'url'>
    )>>> }
  )> }
);

export type ReviewCreateMutationVariables = Exact<{
  productId: Scalars['UUID'];
  rating: Scalars['Long'];
  title: Scalars['String'];
  comments: Scalars['String'];
  mediaIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type ReviewCreateMutation = (
  { __typename?: 'Mutation' }
  & { reviewCreate?: Maybe<(
    { __typename?: 'ReviewEntity' }
    & User_ReviewFragment
  )> }
);

export type ReviewListQueryVariables = Exact<{
  page: GraphQlPageableInput;
}>;


export type ReviewListQuery = (
  { __typename?: 'Query' }
  & { reviewList?: Maybe<(
    { __typename?: 'GraphQLPage_ReviewEntity' }
    & Pick<GraphQlPage_ReviewEntity, 'count'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ReviewEntity' }
      & User_ReviewFragment
    )>>> }
  )> }
);

export type ReviewListForProductQueryVariables = Exact<{
  productId: Scalars['UUID'];
  rating?: Maybe<Scalars['Long']>;
  pageable: GraphQlPageableInput;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type ReviewListForProductQuery = (
  { __typename?: 'Query' }
  & { reviewListForProduct?: Maybe<(
    { __typename?: 'ReviewResults' }
    & Pick<ReviewResults, 'count81To100' | 'count61To80' | 'count41To60' | 'count21To40' | 'count0To20' | 'averageRating'>
    & { reviews?: Maybe<(
      { __typename?: 'GraphQLPage_ReviewEntity' }
      & Pick<GraphQlPage_ReviewEntity, 'count' | 'page' | 'pageSize'>
      & { data?: Maybe<Array<Maybe<(
        { __typename?: 'ReviewEntity' }
        & Pick<ReviewEntity, 'id' | 'comments' | 'createdTime' | 'rating' | 'reviewer' | 'title' | 'updatedTime'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'MediaEntity' }
          & Pick<MediaEntity, 'id' | 'keywords' | 'title' | 'url'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type ReviewRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ReviewRemoveMutation = (
  { __typename?: 'Mutation' }
  & { reviewRemove?: Maybe<(
    { __typename?: 'ReviewEntity' }
    & Pick<ReviewEntity, 'id'>
  )> }
);

export type ReviewUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  rating: Scalars['Long'];
  title: Scalars['String'];
  comments: Scalars['String'];
  mediaIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type ReviewUpdateMutation = (
  { __typename?: 'Mutation' }
  & { reviewUpdate?: Maybe<(
    { __typename?: 'ReviewEntity' }
    & User_ReviewFragment
  )> }
);

export type ReviewUploadImageMutationVariables = Exact<{
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
}>;


export type ReviewUploadImageMutation = (
  { __typename?: 'Mutation' }
  & { reviewUploadImage?: Maybe<(
    { __typename?: 'MediaEntity' }
    & Pick<MediaEntity, 'id' | 'keywords' | 'title' | 'url'>
  )> }
);

export type RewardPointsBalanceQueryVariables = Exact<{ [key: string]: never; }>;


export type RewardPointsBalanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'rewardPointsBalance'>
);

export type RewardPointsGetRewardsAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type RewardPointsGetRewardsAccountQuery = (
  { __typename?: 'Query' }
  & { rewardPointsGetRewardsAccount?: Maybe<(
    { __typename?: 'RewardsAccountEntity' }
    & Pick<RewardsAccountEntity, 'balance'>
    & { transactions?: Maybe<Array<Maybe<(
      { __typename?: 'RewardsTransactionEntity' }
      & Pick<RewardsTransactionEntity, 'amount' | 'comment' | 'timestamp' | 'transactionType'>
    )>>> }
  )> }
);

export type ReshipSalesOrderInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ReshipSalesOrderInfoQuery = (
  { __typename?: 'Query' }
  & { reshipSalesOrderInfo?: Maybe<(
    { __typename?: 'ReshipSalesOrderEntity' }
    & { parentOrder?: Maybe<(
      { __typename?: 'SalesOrderEntity' }
      & Pick<SalesOrderEntity, 'id' | 'cls' | 'orderNumber' | 'alternateOrderNumber'>
    )> }
  )> }
);

export type SalesOrderAddBraintreeOfflinePaymentMutationVariables = Exact<{
  orderId: Scalars['UUID'];
  transactionId: Scalars['String'];
}>;


export type SalesOrderAddBraintreeOfflinePaymentMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderAddBraintreeOfflinePayment?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderAddBraintreeOnlinePaymentMutationVariables = Exact<{
  orderId: Scalars['UUID'];
  amount: Scalars['BigDecimal'];
  deviceData: Scalars['String'];
  nonce: Scalars['String'];
}>;


export type SalesOrderAddBraintreeOnlinePaymentMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderAddBraintreeOnlinePayment?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderAddCashPaymentMutationVariables = Exact<{
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
}>;


export type SalesOrderAddCashPaymentMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderAddCashPayment?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderAddCheckPaymentMutationVariables = Exact<{
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
  checkNumber?: Maybe<Scalars['String']>;
}>;


export type SalesOrderAddCheckPaymentMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderAddCheckPayment?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderAddCommentMutationVariables = Exact<{
  comment: Scalars['String'];
  id: Scalars['UUID'];
}>;


export type SalesOrderAddCommentMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderAddComment?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderAddGiftCardPaymentMutationVariables = Exact<{
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
  giftCardCode?: Maybe<Scalars['String']>;
}>;


export type SalesOrderAddGiftCardPaymentMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderAddGiftCardPayment?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderAddPaymentTermsPaymentMutationVariables = Exact<{
  amount: Scalars['BigDecimal'];
  orderId: Scalars['UUID'];
}>;


export type SalesOrderAddPaymentTermsPaymentMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderAddPaymentTermsPayment?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderCancelMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderCancelMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderCancel?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Customer_Sales_OrderFragment
  )> }
);

export type SalesOrderCancelByHashMutationVariables = Exact<{
  hash: Scalars['String'];
}>;


export type SalesOrderCancelByHashMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderCancelByHash?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Customer_Sales_OrderFragment
  )> }
);

export type SalesOrderCustomRefundMutationVariables = Exact<{
  id: Scalars['UUID'];
  subTotal?: Maybe<Scalars['BigDecimal']>;
  productDiscount?: Maybe<Scalars['BigDecimal']>;
  shipping: Scalars['BigDecimal'];
  handling: Scalars['BigDecimal'];
  shippingDiscount: Scalars['BigDecimal'];
  reason?: Maybe<RefundReason>;
}>;


export type SalesOrderCustomRefundMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderCustomRefund?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderCustomRefundSummarizeMutationVariables = Exact<{
  id: Scalars['UUID'];
  subTotal?: Maybe<Scalars['BigDecimal']>;
  productDiscount?: Maybe<Scalars['BigDecimal']>;
  shipping: Scalars['BigDecimal'];
  handling: Scalars['BigDecimal'];
  shippingDiscount: Scalars['BigDecimal'];
  reason?: Maybe<RefundReason>;
}>;


export type SalesOrderCustomRefundSummarizeMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderCustomRefundSummarize?: Maybe<(
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'id' | 'createdAt' | 'refundNumber' | 'repFirstName' | 'subTotal' | 'productDiscountTotal' | 'taxCode' | 'productTaxTotal' | 'shippingTotal' | 'handlingTotal' | 'shippingDiscountTotal' | 'shippingTaxTotal' | 'grandTotal'>
  )> }
);

export type SalesOrderGetRefundableAmountsMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderGetRefundableAmountsMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderGetRefundableAmounts?: Maybe<(
    { __typename?: 'RefundableAmounts' }
    & Pick<RefundableAmounts, 'shipping' | 'handling' | 'shippingDiscount'>
  )> }
);

export type SalesOrderPartialRefundSummarizeMutationVariables = Exact<{
  id: Scalars['UUID'];
  lineItems: Array<Maybe<SalesOrderLineItemInput>> | Maybe<SalesOrderLineItemInput>;
  reason?: Maybe<RefundReason>;
}>;


export type SalesOrderPartialRefundSummarizeMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderPartialRefundSummarize?: Maybe<(
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'id' | 'createdAt' | 'refundNumber' | 'repFirstName' | 'subTotal' | 'productDiscountTotal' | 'taxCode' | 'productTaxTotal' | 'shippingTotal' | 'handlingTotal' | 'shippingDiscountTotal' | 'shippingTaxTotal' | 'grandTotal'>
    & { refundListedItems?: Maybe<Array<Maybe<(
      { __typename?: 'RefundListedItemEntity' }
      & Pick<RefundListedItemEntity, 'id' | 'quantity'>
      & { salesOrderListedItem?: Maybe<(
        { __typename?: 'SalesOrderListedItemEntity' }
        & Pick<SalesOrderListedItemEntity, 'sku' | 'name' | 'unitAmount' | 'lineAmount'>
      )> }
    )>>>, refundItems?: Maybe<Array<Maybe<(
      { __typename?: 'RefundItemEntity' }
      & Pick<RefundItemEntity, 'id' | 'quantity'>
      & { salesOrderItem?: Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'sku' | 'name' | 'unitAmount' | 'lineAmount'>
      )> }
    )>>> }
  )> }
);

export type SalesOrderSalesTaxRefundMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderSalesTaxRefundMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderSalesTaxRefund?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderByHashQueryVariables = Exact<{
  hash: Scalars['String'];
}>;


export type SalesOrderByHashQuery = (
  { __typename?: 'Query' }
  & { salesOrderByHash?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Customer_Sales_OrderFragment
  )> }
);

export type SalesOrderFilterQueryVariables = Exact<{
  orderNumber?: Maybe<Scalars['String']>;
  alternateOrderNumber?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['String']>;
  billingFirstName?: Maybe<Scalars['String']>;
  billingLastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<SalesOrderStatus>;
  hold?: Maybe<Scalars['Boolean']>;
  pageable: GraphQlPageableInput;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type SalesOrderFilterQuery = (
  { __typename?: 'Query' }
  & { salesOrderFilter?: Maybe<(
    { __typename?: 'GraphQLPage_SalesOrderEntity' }
    & Pick<GraphQlPage_SalesOrderEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderEntity' }
      & Pick<SalesOrderEntity, 'id' | 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'status' | 'billingFirstName' | 'billingLastName' | 'email' | 'placedTime' | 'subTotal' | 'discountTotal' | 'taxTotal' | 'shippingTotal' | 'handlingTotal' | 'grandTotal'>
      & { salesOrderListedItems?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderListedItemEntity' }
        & Pick<SalesOrderListedItemEntity, 'id' | 'sku' | 'name' | 'quantity' | 'unitAmount' | 'discountAmount' | 'lineAmount'>
      )>>>, salesOrderItemGroups?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderItemGroupEntity' }
        & Pick<SalesOrderItemGroupEntity, 'id' | 'quantity' | 'unitAmount' | 'lineAmount' | 'name' | 'sku'>
        & { kitProduct?: Maybe<(
          { __typename?: 'KitProductEntity' }
          & Pick<KitProductEntity, 'sku' | 'title'>
        )> }
      )>>>, salesOrderItems?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'id' | 'sku' | 'name' | 'quantity' | 'unitAmount' | 'discountAmount' | 'lineAmount'>
        & { salesOrderItemGroup?: Maybe<(
          { __typename?: 'SalesOrderItemGroupEntity' }
          & Pick<SalesOrderItemGroupEntity, 'id'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type SalesOrderGenerateInvoiceMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderGenerateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'salesOrderGenerateInvoice'>
);

export type SalesOrderGenerateInvoiceByHashMutationVariables = Exact<{
  hash: Scalars['String'];
}>;


export type SalesOrderGenerateInvoiceByHashMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'salesOrderGenerateInvoiceByHash'>
);

export type SalesOrderGetRefundableItemsMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderGetRefundableItemsMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderGetRefundableItems?: Maybe<Array<Maybe<(
    { __typename?: 'SalesOrderLineItem' }
    & Pick<SalesOrderLineItem, 'id' | 'quantity'>
  )>>> }
);

export type SalesOrderInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderInfoQuery = (
  { __typename?: 'Query' }
  & { salesOrderInfo?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Customer_Sales_OrderFragment
  )> }
);

export type SalesOrderInfoAdminQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderInfoAdminQuery = (
  { __typename?: 'Query' }
  & { salesOrderInfo?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderListQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
}>;


export type SalesOrderListQuery = (
  { __typename?: 'Query' }
  & { salesOrderList?: Maybe<(
    { __typename?: 'GraphQLPage_SalesOrderEntity' }
    & Pick<GraphQlPage_SalesOrderEntity, 'count'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderEntity' }
      & Pick<SalesOrderEntity, 'id' | 'orderNumber' | 'status' | 'placedTime' | 'grandTotal'>
      & { salesOrderListedItems?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderListedItemEntity' }
        & Pick<SalesOrderListedItemEntity, 'id' | 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
        & { listedProduct?: Maybe<(
          { __typename?: 'ListedProductEntity' }
          & Pick<ListedProductEntity, 'slug' | 'title'>
          & { medias?: Maybe<Array<Maybe<(
            { __typename?: 'ProductMediaEntity' }
            & Pick<ProductMediaEntity, 'url'>
          )>>> }
        )>, listing?: Maybe<(
          { __typename?: 'ListingEntity' }
          & Pick<ListingEntity, 'identifier' | 'title'>
          & { listingMedias?: Maybe<Array<Maybe<(
            { __typename?: 'ListingMediaEntity' }
            & Pick<ListingMediaEntity, 'url'>
          )>>> }
        )> }
      )>>>, salesOrderItemGroups?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderItemGroupEntity' }
        & Pick<SalesOrderItemGroupEntity, 'quantity' | 'unitAmount' | 'lineAmount' | 'name'>
        & { kitProduct?: Maybe<(
          { __typename?: 'KitProductEntity' }
          & Pick<KitProductEntity, 'slug' | 'title'>
          & { medias?: Maybe<Array<Maybe<(
            { __typename?: 'ProductMediaEntity' }
            & Pick<ProductMediaEntity, 'url'>
          )>>> }
        )>, salesOrderItems?: Maybe<Array<Maybe<(
          { __typename?: 'SalesOrderItemEntity' }
          & Pick<SalesOrderItemEntity, 'quantity' | 'name'>
        )>>> }
      )>>>, salesOrderItems?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
        & { salesOrderItemGroup?: Maybe<(
          { __typename?: 'SalesOrderItemGroupEntity' }
          & Pick<SalesOrderItemGroupEntity, 'id'>
        )>, simpleProduct?: Maybe<(
          { __typename?: 'SimpleProductEntity' }
          & Pick<SimpleProductEntity, 'slug' | 'title'>
          & { medias?: Maybe<Array<Maybe<(
            { __typename?: 'ProductMediaEntity' }
            & Pick<ProductMediaEntity, 'url'>
          )>>> }
        )> }
      )>>> }
    )>>> }
  )> }
);

export type SalesOrderOfflineRefundMutationVariables = Exact<{
  id: Scalars['UUID'];
  reason?: Maybe<RefundReason>;
}>;


export type SalesOrderOfflineRefundMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderOfflineRefund?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderOnlineRefundMutationVariables = Exact<{
  id: Scalars['UUID'];
  reason?: Maybe<RefundReason>;
}>;


export type SalesOrderOnlineRefundMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderOnlineRefund?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderPartialRefundMutationVariables = Exact<{
  id: Scalars['UUID'];
  lineItems: Array<Maybe<SalesOrderLineItemInput>> | Maybe<SalesOrderLineItemInput>;
  reason?: Maybe<RefundReason>;
}>;


export type SalesOrderPartialRefundMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderPartialRefund?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderPartialReshipMutationVariables = Exact<{
  id: Scalars['UUID'];
  lineItems: Array<Maybe<SalesOrderLineItemInput>> | Maybe<SalesOrderLineItemInput>;
  reason?: Maybe<RefundReason>;
  carrierPreference?: Maybe<Carrier>;
  serviceType?: Maybe<ServiceType>;
}>;


export type SalesOrderPartialReshipMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderPartialReship?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderSetEmailMutationVariables = Exact<{
  id: Scalars['UUID'];
  email: Scalars['String'];
}>;


export type SalesOrderSetEmailMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderSetEmail?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderSetHoldMutationVariables = Exact<{
  id: Scalars['UUID'];
  hold: Scalars['Boolean'];
}>;


export type SalesOrderSetHoldMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderSetHold?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderUpdatePaymentsMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderUpdatePaymentsMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderUpdatePayments?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type SalesOrderValidateAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SalesOrderValidateAddressMutation = (
  { __typename?: 'Mutation' }
  & { salesOrderValidateAddress?: Maybe<(
    { __typename?: 'SalesOrderEntity' }
    & Admin_Sales_OrderFragment
  )> }
);

export type ScheduledShipmentContactCustomerServiceMutationVariables = Exact<{
  shipmentId: Scalars['UUID'];
  message: Scalars['String'];
}>;


export type ScheduledShipmentContactCustomerServiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'scheduledShipmentContactCustomerService'>
);

export type ScheduledShipmentContactCustomerServiceByHashMutationVariables = Exact<{
  hash: Scalars['String'];
  message: Scalars['String'];
}>;


export type ScheduledShipmentContactCustomerServiceByHashMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'scheduledShipmentContactCustomerServiceByHash'>
);

export type ScheduledShipmentHoldLocationsQueryVariables = Exact<{
  id: Scalars['UUID'];
  miles?: Maybe<Scalars['BigDecimal']>;
  postalCode: Scalars['String'];
}>;


export type ScheduledShipmentHoldLocationsQuery = (
  { __typename?: 'Query' }
  & { scheduledShipmentHoldLocations?: Maybe<Array<Maybe<(
    { __typename?: 'HoldLocation' }
    & Pick<HoldLocation, 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'addressType'>
  )>>> }
);

export type ScheduledShipmentHoldLocationsByHashQueryVariables = Exact<{
  hash: Scalars['String'];
  miles?: Maybe<Scalars['BigDecimal']>;
  postalCode: Scalars['String'];
}>;


export type ScheduledShipmentHoldLocationsByHashQuery = (
  { __typename?: 'Query' }
  & { scheduledShipmentHoldLocationsByHash?: Maybe<Array<Maybe<(
    { __typename?: 'HoldLocation' }
    & Pick<HoldLocation, 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'addressType'>
  )>>> }
);

export type ScheduledShipmentPossibleDeliveryDatesMutationVariables = Exact<{
  shipmentId: Scalars['UUID'];
}>;


export type ScheduledShipmentPossibleDeliveryDatesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'scheduledShipmentPossibleDeliveryDates'>
);

export type ScheduledShipmentPossibleDeliveryDatesByHashMutationVariables = Exact<{
  hash: Scalars['String'];
}>;


export type ScheduledShipmentPossibleDeliveryDatesByHashMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'scheduledShipmentPossibleDeliveryDatesByHash'>
);

export type ScheduledShipmentScheduleMutationVariables = Exact<{
  date: Scalars['LocalDate'];
  id: Scalars['UUID'];
}>;


export type ScheduledShipmentScheduleMutation = (
  { __typename?: 'Mutation' }
  & { scheduledShipmentSchedule?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Customer_ShipmentFragment
  )> }
);

export type ScheduledShipmentScheduleByHashMutationVariables = Exact<{
  date: Scalars['LocalDate'];
  hash: Scalars['String'];
}>;


export type ScheduledShipmentScheduleByHashMutation = (
  { __typename?: 'Mutation' }
  & { scheduledShipmentScheduleByHash?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Customer_Shipment_PublicFragment
  )> }
);

export type ScheduledShipmentUpdateAddressByHashFullMutationVariables = Exact<{
  hash: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<AddressType>;
}>;


export type ScheduledShipmentUpdateAddressByHashFullMutation = (
  { __typename?: 'Mutation' }
  & { scheduledShipmentUpdateAddressByHash?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Customer_ShipmentFragment
  )> }
);

export type ShelfLabelsGeneratePdfQueryVariables = Exact<{
  format: Scalars['String'];
  zonePrefix: Scalars['String'];
  start: Scalars['Long'];
  end: Scalars['Long'];
  collate: Scalars['Boolean'];
}>;


export type ShelfLabelsGeneratePdfQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'shelfLabelsGeneratePdf'>
);

export type ShipmentByHashQueryVariables = Exact<{
  hash: Scalars['String'];
}>;


export type ShipmentByHashQuery = (
  { __typename?: 'Query' }
  & { shipmentByHash?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Customer_Shipment_PublicFragment
  )> }
);

export type ShipmentFilterQueryVariables = Exact<{
  warehouse?: Maybe<Scalars['String']>;
  salesOrderCls?: Maybe<Scalars['String']>;
  shipmentNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  late?: Maybe<Scalars['Boolean']>;
  shipmentStatus?: Maybe<ShipmentStatus>;
  pageable: GraphQlPageableInput;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type ShipmentFilterQuery = (
  { __typename?: 'Query' }
  & { shipmentFilter?: Maybe<(
    { __typename?: 'GraphQLPage_ShipmentEntity' }
    & Pick<GraphQlPage_ShipmentEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentEntity' }
      & Pick<ShipmentEntity, 'id' | 'cls' | 'email' | 'firstName' | 'lastName' | 'shipmentNumber' | 'shipmentStatus' | 'shippingNeeds' | 'carrier' | 'service' | 'estimatedWeight' | 'actualWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'estimatedShipDate' | 'estimatedDeliveryDateStart' | 'estimatedDeliveryDateEnd' | 'shippedAt' | 'trackingNo'>
      & { originWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, departingWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, salesOrder?: Maybe<(
        { __typename?: 'SalesOrderEntity' }
        & Pick<SalesOrderEntity, 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'salesOrderType'>
      )>, shipmentListedItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentListedItemEntity' }
        & Pick<ShipmentListedItemEntity, 'id' | 'cls' | 'quantity'>
        & { salesOrderListedItem?: Maybe<(
          { __typename?: 'SalesOrderListedItemEntity' }
          & Pick<SalesOrderListedItemEntity, 'id' | 'cls' | 'sku' | 'name'>
        )> }
      )>>>, shipmentItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentItemEntity' }
        & Pick<ShipmentItemEntity, 'id' | 'cls' | 'quantity'>
        & { salesOrderItem?: Maybe<(
          { __typename?: 'SalesOrderItemEntity' }
          & Pick<SalesOrderItemEntity, 'id' | 'cls' | 'sku' | 'name'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type ShipmentInfoAdminQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentInfoAdminQuery = (
  { __typename?: 'Query' }
  & { shipmentInfo?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Admin_Shipment_EntityFragment
  )> }
);

export type ShipmentInfoSchedulingQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentInfoSchedulingQuery = (
  { __typename?: 'Query' }
  & { shipmentInfo?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Scheduled_Shipment_EntityFragment
  )> }
);

export type ShipmentTrackMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentTrackMutation = (
  { __typename?: 'Mutation' }
  & { shipmentTrack?: Maybe<Array<Maybe<(
    { __typename?: 'TrackingEvent' }
    & Pick<TrackingEvent, 'comment' | 'location' | 'timestamp' | 'trackingEventType'>
  )>>> }
);

export type ShipmentTrackByHashMutationVariables = Exact<{
  hash: Scalars['String'];
}>;


export type ShipmentTrackByHashMutation = (
  { __typename?: 'Mutation' }
  & { shipmentTrackByHash?: Maybe<Array<Maybe<(
    { __typename?: 'TrackingEvent' }
    & Pick<TrackingEvent, 'comment' | 'location' | 'timestamp' | 'trackingEventType'>
  )>>> }
);

export type ShipmentCancelAndRefundMutationVariables = Exact<{
  id: Scalars['UUID'];
  reason?: Maybe<CancellationReason>;
  sendNotification: Scalars['Boolean'];
  refund: Scalars['Boolean'];
}>;


export type ShipmentCancelAndRefundMutation = (
  { __typename?: 'Mutation' }
  & { shipmentCancelAndRefund?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Admin_Shipment_EntityFragment
  )> }
);

export type ShipmentDashboardMutationVariables = Exact<{
  warehouse: Scalars['String'];
}>;


export type ShipmentDashboardMutation = (
  { __typename?: 'Mutation' }
  & { shipmentDashboard?: Maybe<(
    { __typename?: 'Dashboard' }
    & Pick<Dashboard, 'warehouse' | 'timezone' | 'today' | 'nextOpenDate' | 'lateCount' | 'onHoldCount' | 'pendingCount' | 'needsSchedulingCount'>
    & { summaries?: Maybe<Array<Maybe<(
      { __typename?: 'Summary' }
      & Pick<Summary, 'date' | 'onHold' | 'unshipped' | 'shipped'>
    )>>> }
  )> }
);

export type ShipmentDeliverOfflineMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentDeliverOfflineMutation = (
  { __typename?: 'Mutation' }
  & { shipmentDeliverOffline?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Pick<ShipmentEntity, 'id' | 'shipmentStatus'>
  )> }
);

export type ShipmentGenerateCommercialInvoiceMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentGenerateCommercialInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'shipmentGenerateCommercialInvoice'>
);

export type ShipmentGeneratePackingListMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentGeneratePackingListMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'shipmentGeneratePackingList'>
);

export type ShipmentInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentInfoQuery = (
  { __typename?: 'Query' }
  & { shipmentInfo?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Customer_ShipmentFragment
  )> }
);

export type ShipmentLookupWeatherMutationVariables = Exact<{
  shipmentId: Scalars['UUID'];
}>;


export type ShipmentLookupWeatherMutation = (
  { __typename?: 'Mutation' }
  & { shipmentLookupWeather?: Maybe<Array<Maybe<(
    { __typename?: 'Weather' }
    & Pick<Weather, 'date' | 'high' | 'low'>
  )>>> }
);

export type ShipmentLookupWeatherByHashMutationVariables = Exact<{
  shipmentIdHash: Scalars['String'];
}>;


export type ShipmentLookupWeatherByHashMutation = (
  { __typename?: 'Mutation' }
  & { shipmentLookupWeatherByHash?: Maybe<Array<Maybe<(
    { __typename?: 'Weather' }
    & Pick<Weather, 'date' | 'high' | 'low'>
  )>>> }
);

export type ShipmentRerouteMutationVariables = Exact<{
  shipment: Scalars['UUID'];
  warehouse: Scalars['String'];
}>;


export type ShipmentRerouteMutation = (
  { __typename?: 'Mutation' }
  & { shipmentReroute?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Admin_Shipment_EntityFragment
  )> }
);

export type ShipmentSetShippingNeedsMutationVariables = Exact<{
  id: Scalars['UUID'];
  shippingNeeds: ShippingNeedsType;
}>;


export type ShipmentSetShippingNeedsMutation = (
  { __typename?: 'Mutation' }
  & { shipmentSetShippingNeeds?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Admin_Shipment_EntityFragment
  )> }
);

export type ShipmentShipOfflineMutationVariables = Exact<{
  id: Scalars['UUID'];
  carrier?: Maybe<Carrier>;
  trackingNos?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  service?: Maybe<Service>;
}>;


export type ShipmentShipOfflineMutation = (
  { __typename?: 'Mutation' }
  & { shipmentShipOffline?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Admin_Shipment_EntityFragment
  )> }
);

export type ShipmentSplitMutationVariables = Exact<{
  id: Scalars['UUID'];
  lineItems: Array<Maybe<ShipmentLineItemInput>> | Maybe<ShipmentLineItemInput>;
  reroute: Scalars['Boolean'];
}>;


export type ShipmentSplitMutation = (
  { __typename?: 'Mutation' }
  & { shipmentSplit?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Pick<ShipmentEntity, 'id' | 'cls' | 'shipmentNumber' | 'shipmentStatus' | 'shippingNeeds' | 'carrier' | 'service' | 'estimatedWeight' | 'actualWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'placedAt' | 'estimatedShipDate' | 'estimatedDeliveryDateStart' | 'estimatedDeliveryDateEnd' | 'shippedAt' | 'email' | 'firstName' | 'lastName' | 'company' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'residential' | 'addressType' | 'addressValidationSource' | 'downloadStart' | 'printedStart' | 'printedEnd' | 'reprint'>
    & { salesOrder?: Maybe<(
      { __typename?: 'SalesOrderEntity' }
      & Pick<SalesOrderEntity, 'id' | 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'placedTime' | 'status'>
    )>, shipmentListedItems?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentListedItemEntity' }
      & Pick<ShipmentListedItemEntity, 'id' | 'cls' | 'quantity'>
      & { salesOrderListedItem?: Maybe<(
        { __typename?: 'SalesOrderListedItemEntity' }
        & Pick<SalesOrderListedItemEntity, 'id' | 'cls' | 'sku' | 'name'>
      )> }
    )>>>, shipmentItems?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentItemEntity' }
      & Pick<ShipmentItemEntity, 'id' | 'cls' | 'quantity'>
      & { salesOrderItem?: Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'id' | 'cls' | 'sku' | 'name'>
      )> }
    )>>>, shipmentAddons?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentAddonEntity' }
      & Pick<ShipmentAddonEntity, 'id' | 'cls' | 'quantity' | 'sku' | 'name'>
    )>>> }
  )> }
);

export type ShipmentUpdateAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<AddressType>;
}>;


export type ShipmentUpdateAddressMutation = (
  { __typename?: 'Mutation' }
  & { shipmentUpdateAddress?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Admin_Shipment_EntityFragment
  )> }
);

export type ShipmentUpdateAddressFullMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<AddressType>;
}>;


export type ShipmentUpdateAddressFullMutation = (
  { __typename?: 'Mutation' }
  & { shipmentUpdateAddress?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Customer_ShipmentFragment
  )> }
);

export type ShipmentValidateAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ShipmentValidateAddressMutation = (
  { __typename?: 'Mutation' }
  & { shipmentValidateAddress?: Maybe<(
    { __typename?: 'ShipmentEntity' }
    & Admin_Shipment_EntityFragment
  )> }
);

export type ShippingRuleSetListQueryVariables = Exact<{ [key: string]: never; }>;


export type ShippingRuleSetListQuery = (
  { __typename?: 'Query' }
  & { shippingRuleSetList?: Maybe<(
    { __typename?: 'GraphQLPage_ShippingRuleSetEntity' }
    & Pick<GraphQlPage_ShippingRuleSetEntity, 'count'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ShippingRuleSetEntity' }
      & Pick<ShippingRuleSetEntity, 'id' | 'name' | 'slug'>
    )>>> }
  )> }
);

export type Admin_Simple_Product_EntityFragment = (
  { __typename?: 'SimpleProductEntity' }
  & Pick<SimpleProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'magentoId' | 'upc' | 'title' | 'active' | 'visible' | 'featured' | 'quantityAvailableForSale' | 'caseQuantity' | 'cost' | 'height' | 'length' | 'live' | 'price' | 'mapPrice' | 'specialPrice' | 'wholesalePrice' | 'weight' | 'width' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'minimumOrderQuantity' | 'averageRating' | 'countRating' | 'shippingNeeds' | 'shipsAlone' | 'subscription' | 'prepType' | 'discontinuationReason' | 'vendorRef' | 'countryOfOrigin' | 'hsCode' | 'shippingRestrictions'>
  & { publishedRevision?: Maybe<(
    { __typename?: 'ProductRevisionEntity' }
    & Pick<ProductRevisionEntity, 'htmlContent'>
  )>, warehouses?: Maybe<Array<Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'name'>
  )>>>, zones?: Maybe<Array<Maybe<(
    { __typename?: 'ZoneEntity' }
    & Pick<ZoneEntity, 'id' | 'name'>
    & { warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'id' | 'name'>
    )> }
  )>>>, bins?: Maybe<Array<Maybe<(
    { __typename?: 'BinEntity' }
    & Pick<BinEntity, 'id' | 'binId'>
    & { zone?: Maybe<(
      { __typename?: 'ZoneEntity' }
      & Pick<ZoneEntity, 'id' | 'name'>
      & { warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )> }
    )> }
  )>>>, explicitDepartment?: Maybe<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
    )> }
  )>, brand?: Maybe<(
    { __typename?: 'BrandEntity' }
    & Pick<BrandEntity, 'id' | 'slug' | 'name'>
  )>, supplier?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'id' | 'slug' | 'name'>
  )>, medias?: Maybe<Array<Maybe<(
    { __typename?: 'ProductMediaEntity' }
    & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'sequence' | 'url'>
    & { media?: Maybe<(
      { __typename?: 'MediaEntity' }
      & Pick<MediaEntity, 'title'>
    )> }
  )>>>, explicitAnimals?: Maybe<Array<Maybe<(
    { __typename?: 'AnimalEntity' }
    & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'AnimalEntity' }
      & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'AnimalEntity' }
        & Pick<AnimalEntity, 'id' | 'slug' | 'name'>
      )> }
    )> }
  )>>>, explicitCategories?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryEntity' }
    & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
      )> }
    )> }
  )>>>, inventoryQuantityCaches?: Maybe<Array<Maybe<(
    { __typename?: 'InventoryQuantityCacheEntity' }
    & Pick<InventoryQuantityCacheEntity, 'id' | 'quantityAvailableForSale' | 'quantityOnShelf' | 'quantityReserved'>
    & { warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'id' | 'name' | 'slug'>
    )> }
  )>>>, shippingRuleSet?: Maybe<(
    { __typename?: 'ShippingRuleSetEntity' }
    & Pick<ShippingRuleSetEntity, 'id' | 'slug' | 'name'>
    & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
      { __typename?: 'LiveArrivalGuaranteeEntity' }
      & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
    )>>>, destinationRestriction?: Maybe<(
      { __typename?: 'DestinationRestrictionEntity' }
      & Pick<DestinationRestrictionEntity, 'domesticOnly' | 'prohibitedStates'>
    )> }
  )>, implicitPromotions?: Maybe<Array<Maybe<(
    { __typename?: 'PromotionEntity' }
    & Pick<PromotionEntity, 'id' | 'name' | 'slug' | 'promotionType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleItemValue' | 'eligibleItemCount' | 'eligibleCartValue' | 'discountAmount'>
  )>>>, implicitDiscounts?: Maybe<Array<Maybe<(
    { __typename?: 'DiscountEntity' }
    & Pick<DiscountEntity, 'id' | 'name' | 'slug' | 'discountType' | 'active' | 'startDate' | 'endDate' | 'permitStacking' | 'eligibleUserType' | 'eligibleCouponCode' | 'eligibleCartValue' | 'discountAmount'>
  )>>>, amazonProfile?: Maybe<(
    { __typename?: 'AmazonProfileEntity' }
    & Pick<AmazonProfileEntity, 'id' | 'asin' | 'fbaSellerSku' | 'sellerSku'>
  )>, petcoDropshipProfile?: Maybe<(
    { __typename?: 'PetcoDropshipProfileEntity' }
    & Pick<PetcoDropshipProfileEntity, 'id' | 'description' | 'sku' | 'surcharge'>
  )>, petcoStoreDirectProfile?: Maybe<(
    { __typename?: 'PetcoStoreDirectProfileEntity' }
    & Pick<PetcoStoreDirectProfileEntity, 'id' | 'description' | 'sku'>
  )>, chewyProfile?: Maybe<(
    { __typename?: 'ChewyProfileEntity' }
    & Pick<ChewyProfileEntity, 'id' | 'description' | 'sku' | 'cost'>
  )>, walmartProfile?: Maybe<(
    { __typename?: 'WalmartProfileEntity' }
    & Pick<WalmartProfileEntity, 'id' | 'sellerSku' | 'wfsSellerSku'>
  )>, resellerProfiles?: Maybe<Array<Maybe<(
    { __typename?: 'ResellerProfileEntity' }
    & Pick<ResellerProfileEntity, 'id' | 'resellerSku' | 'price' | 'retail'>
    & { reseller?: Maybe<(
      { __typename?: 'PartnerEntity' }
      & Pick<PartnerEntity, 'id' | 'name'>
    )> }
  )>>>, dropshipperProfiles?: Maybe<Array<Maybe<(
    { __typename?: 'DropshipperProfileEntity' }
    & Pick<DropshipperProfileEntity, 'id' | 'dropshipperSku' | 'cost' | 'enabled'>
    & { dropshipper?: Maybe<(
      { __typename?: 'PartnerEntity' }
      & Pick<PartnerEntity, 'id' | 'name'>
    )> }
  )>>>, crossSellItems?: Maybe<Array<Maybe<(
    { __typename?: 'CrossSellItemEntity' }
    & Pick<CrossSellItemEntity, 'id' | 'position'>
    & { crossSellProduct?: Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'title' | 'sku' | 'price' | 'quantityAvailableForSale' | 'cls'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )> }
  )>>>, priceTiers?: Maybe<Array<Maybe<(
    { __typename?: 'PriceTierEntity' }
    & Pick<PriceTierEntity, 'id' | 'price' | 'quantity' | 'userType'>
  )>>>, componentItems?: Maybe<Array<Maybe<(
    { __typename?: 'BomItemEntity' }
    & Pick<BomItemEntity, 'id' | 'quantity' | 'position'>
    & { component?: Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'cost' | 'title' | 'sku'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'url' | 'mediaType'>
      )>>> }
    )> }
  )>>> }
);

export type SimpleProductAddAnimalMutationVariables = Exact<{
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
}>;


export type SimpleProductAddAnimalMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductAddAnimal?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductAddComponentItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['BigDecimal'];
}>;


export type SimpleProductAddComponentItemMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductAddComponentItem?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductAddCrossSellItemsMutationVariables = Exact<{
  id: Scalars['UUID'];
  productIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type SimpleProductAddCrossSellItemsMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductAddCrossSellItems?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductAddImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  mediaId: Scalars['UUID'];
}>;


export type SimpleProductAddImageMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductAddImage?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductAddPriceTierMutationVariables = Exact<{
  id: Scalars['UUID'];
  price: Scalars['BigDecimal'];
  quantity: Scalars['BigDecimal'];
  userType?: Maybe<UserType>;
}>;


export type SimpleProductAddPriceTierMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductAddPriceTier?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductAddWarehouseMutationVariables = Exact<{
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
}>;


export type SimpleProductAddWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductAddWarehouse?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductAddZoneMutationVariables = Exact<{
  id: Scalars['UUID'];
  zone: Scalars['UUID'];
}>;


export type SimpleProductAddZoneMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductAddZone?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type SimpleProductBySlugQuery = (
  { __typename?: 'Query' }
  & { simpleProductBySlug?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Pick<SimpleProductEntity, 'id' | 'cls' | 'slug' | 'sku' | 'title' | 'featured' | 'subscription' | 'dropshipped' | 'quantityAvailableForSale' | 'discontinuationReason' | 'price' | 'salePrice' | 'metaTitle' | 'metaDescription' | 'metaKeywords' | 'averageRating' | 'countRating' | 'shippingNeeds'>
    & { tieredPrices?: Maybe<Array<Maybe<(
      { __typename?: 'PriceTierEntity' }
      & Pick<PriceTierEntity, 'id' | 'quantity' | 'price'>
    )>>>, publishedRevision?: Maybe<(
      { __typename?: 'ProductRevisionEntity' }
      & Pick<ProductRevisionEntity, 'htmlContent'>
    )>, medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>>, explicitCategories?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryEntity' }
      & Pick<CategoryEntity, 'active' | 'id' | 'slug' | 'path' | 'name'>
      & { ancestors?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'active' | 'name' | 'path'>
      )>>> }
    )>>>, variationSet?: Maybe<(
      { __typename?: 'VariationSetEntity' }
      & { products?: Maybe<Array<Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'active' | 'cls' | 'id' | 'title' | 'slug' | 'price' | 'salePrice' | 'variationKey'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )>>> }
    )>, upsellProducts?: Maybe<Array<Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'active' | 'title' | 'sku' | 'slug' | 'countRating' | 'averageRating' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'variationKey' | 'featured'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>> }
    )>>>, shippingRuleSet?: Maybe<(
      { __typename?: 'ShippingRuleSetEntity' }
      & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
        { __typename?: 'LiveArrivalGuaranteeEntity' }
        & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
      )>>>, destinationRestriction?: Maybe<(
        { __typename?: 'DestinationRestrictionEntity' }
        & Pick<DestinationRestrictionEntity, 'domesticOnly' | 'prohibitedStates'>
      )> }
    )>, careSheets?: Maybe<Array<Maybe<(
      { __typename?: 'CareSheetEntity' }
      & Pick<CareSheetEntity, 'id' | 'title' | 'slug' | 'createdAt' | 'metaDescription' | 'active' | 'readTime'>
    )>>> }
  )> }
);

export type SimpleProductClearBinMutationVariables = Exact<{
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
}>;


export type SimpleProductClearBinMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductClearBin?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductClearBrandMutationVariables = Exact<{
  productId: Scalars['UUID'];
}>;


export type SimpleProductClearBrandMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductClearBrand?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductClearDiscontinuationReasonMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SimpleProductClearDiscontinuationReasonMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductClearDiscontinuationReason?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductClearShipsAloneMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SimpleProductClearShipsAloneMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductClearShipsAlone?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductClearSupplierMutationVariables = Exact<{
  productId: Scalars['UUID'];
}>;


export type SimpleProductClearSupplierMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductClearSupplier?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductCloneMutationVariables = Exact<{
  id: Scalars['UUID'];
  sku: Scalars['String'];
  upc?: Maybe<Scalars['String']>;
  vendorRef?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  price: Scalars['BigDecimal'];
  cost?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductCloneMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductClone?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductCreateMutationVariables = Exact<{
  sku: Scalars['String'];
  upc?: Maybe<Scalars['String']>;
  vendorRef?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  price: Scalars['BigDecimal'];
  cost?: Maybe<Scalars['BigDecimal']>;
  weight: Scalars['BigDecimal'];
}>;


export type SimpleProductCreateMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductCreate?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemoveMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SimpleProductRemoveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'simpleProductRemove'>
);

export type SimpleProductFilterQueryVariables = Exact<{
  pageable: GraphQlPageableInput;
  title?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  animal?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  sort?: Maybe<GraphQlSortInput>;
  inStock?: Maybe<Scalars['Boolean']>;
  upc?: Maybe<Scalars['String']>;
  subscription?: Maybe<Scalars['Boolean']>;
}>;


export type SimpleProductFilterQuery = (
  { __typename?: 'Query' }
  & { simpleProductFilter?: Maybe<(
    { __typename?: 'GraphQLPage_SimpleProductEntity' }
    & Pick<GraphQlPage_SimpleProductEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'SimpleProductEntity' }
      & Pick<SimpleProductEntity, 'id' | 'slug' | 'sku' | 'upc' | 'title' | 'active' | 'quantityAvailableForSale' | 'averageRating' | 'countRating' | 'price' | 'salePrice' | 'shippingNeeds'>
      & { explicitDepartment?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'DepartmentEntity' }
          & Pick<DepartmentEntity, 'id' | 'slug' | 'name'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'BrandEntity' }
        & Pick<BrandEntity, 'id' | 'slug' | 'name'>
      )>, supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'id' | 'slug' | 'name'>
      )>, medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>>, explicitCategories?: Maybe<Array<Maybe<(
        { __typename?: 'CategoryEntity' }
        & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'CategoryEntity' }
          & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
          & { parent?: Maybe<(
            { __typename?: 'CategoryEntity' }
            & Pick<CategoryEntity, 'id' | 'slug' | 'path' | 'name'>
          )> }
        )> }
      )>>>, shippingRuleSet?: Maybe<(
        { __typename?: 'ShippingRuleSetEntity' }
        & Pick<ShippingRuleSetEntity, 'id' | 'slug' | 'name'>
      )> }
    )>>> }
  )> }
);

export type SimpleProductInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SimpleProductInfoQuery = (
  { __typename?: 'Query' }
  & { simpleProductInfo?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductReindexMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SimpleProductReindexMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductReindex?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemoveAnimalMutationVariables = Exact<{
  id: Scalars['UUID'];
  animalId: Scalars['UUID'];
}>;


export type SimpleProductRemoveAnimalMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductRemoveAnimal?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemoveComponentItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  componentItemId: Scalars['UUID'];
}>;


export type SimpleProductRemoveComponentItemMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductRemoveComponentItem?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemoveCrossSellItemMutationVariables = Exact<{
  id: Scalars['UUID'];
  crossSellItemId: Scalars['UUID'];
}>;


export type SimpleProductRemoveCrossSellItemMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductRemoveCrossSellItem?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemovePriceTierMutationVariables = Exact<{
  id: Scalars['UUID'];
  priceTierId: Scalars['UUID'];
}>;


export type SimpleProductRemovePriceTierMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductRemovePriceTier?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemoveProductMediaMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
}>;


export type SimpleProductRemoveProductMediaMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductRemoveProductMedia?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemoveWarehouseMutationVariables = Exact<{
  id: Scalars['UUID'];
  warehouse: Scalars['String'];
}>;


export type SimpleProductRemoveWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductRemoveWarehouse?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductRemoveZoneMutationVariables = Exact<{
  id: Scalars['UUID'];
  zone: Scalars['UUID'];
}>;


export type SimpleProductRemoveZoneMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductRemoveZone?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetActiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type SimpleProductSetActiveMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetActive?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetBinMutationVariables = Exact<{
  id: Scalars['UUID'];
  bin: Scalars['String'];
  warehouse: Scalars['String'];
}>;


export type SimpleProductSetBinMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetBin?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetBrandMutationVariables = Exact<{
  productId: Scalars['UUID'];
  brandId: Scalars['UUID'];
}>;


export type SimpleProductSetBrandMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetBrand?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetCaseQuantityMutationVariables = Exact<{
  id: Scalars['UUID'];
  caseQuantity?: Maybe<Scalars['Long']>;
}>;


export type SimpleProductSetCaseQuantityMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetCaseQuantity?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetComponentItemPositionMutationVariables = Exact<{
  id: Scalars['UUID'];
  componentItemId: Scalars['UUID'];
  position: Scalars['Int'];
}>;


export type SimpleProductSetComponentItemPositionMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetComponentItemPosition?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetComponentItemQuantityMutationVariables = Exact<{
  id: Scalars['UUID'];
  componentItemId: Scalars['UUID'];
  quantity: Scalars['BigDecimal'];
}>;


export type SimpleProductSetComponentItemQuantityMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetComponentItemQuantity?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetCostMutationVariables = Exact<{
  id: Scalars['UUID'];
  cost?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetCostMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetCost?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetCountryOfOriginMutationVariables = Exact<{
  id: Scalars['UUID'];
  country?: Maybe<Scalars['String']>;
}>;


export type SimpleProductSetCountryOfOriginMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetCountryOfOrigin?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetCrossSellItemPositionMutationVariables = Exact<{
  id: Scalars['UUID'];
  crossSellItemId: Scalars['UUID'];
  position: Scalars['Int'];
}>;


export type SimpleProductSetCrossSellItemPositionMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetCrossSellItemPosition?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetDimensionsMutationVariables = Exact<{
  id: Scalars['UUID'];
  length: Scalars['BigDecimal'];
  width: Scalars['BigDecimal'];
  height: Scalars['BigDecimal'];
}>;


export type SimpleProductSetDimensionsMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetDimensions?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetDiscontinuationReasonMutationVariables = Exact<{
  reason: DiscontinuationReasonType;
  id: Scalars['UUID'];
}>;


export type SimpleProductSetDiscontinuationReasonMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetDiscontinuationReason?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetFeaturedMutationVariables = Exact<{
  id: Scalars['UUID'];
  featured: Scalars['Boolean'];
}>;


export type SimpleProductSetFeaturedMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetFeatured?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetHeightMutationVariables = Exact<{
  id: Scalars['UUID'];
  height?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetHeightMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetHeight?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetHsCodeMutationVariables = Exact<{
  id: Scalars['UUID'];
  hsCode?: Maybe<Scalars['String']>;
}>;


export type SimpleProductSetHsCodeMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetHsCode?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetLengthMutationVariables = Exact<{
  id: Scalars['UUID'];
  length?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetLengthMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetLength?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetLiveMutationVariables = Exact<{
  id: Scalars['UUID'];
  live: Scalars['Boolean'];
}>;


export type SimpleProductSetLiveMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetLive?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetMapPriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  mapPrice?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetMapPriceMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetMapPrice?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetMetaDescriptionMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaDescription?: Maybe<Scalars['String']>;
}>;


export type SimpleProductSetMetaDescriptionMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetMetaDescription?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetMetaKeywordsMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaKeywords?: Maybe<Scalars['String']>;
}>;


export type SimpleProductSetMetaKeywordsMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetMetaKeywords?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetMetaTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  metaTitle?: Maybe<Scalars['String']>;
}>;


export type SimpleProductSetMetaTitleMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetMetaTitle?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetMinimumOrderQuantityMutationVariables = Exact<{
  id: Scalars['UUID'];
  minimumOrderQuantity?: Maybe<Scalars['Long']>;
}>;


export type SimpleProductSetMinimumOrderQuantityMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetMinimumOrderQuantity?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetPrepTypeMutationVariables = Exact<{
  id: Scalars['UUID'];
  prepType?: Maybe<PrepType>;
}>;


export type SimpleProductSetPrepTypeMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetPrepType?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetPriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  price: Scalars['BigDecimal'];
}>;


export type SimpleProductSetPriceMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetPrice?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetProductMediaSequenceMutationVariables = Exact<{
  id: Scalars['UUID'];
  productMediaId: Scalars['UUID'];
  sequence: Scalars['Int'];
}>;


export type SimpleProductSetProductMediaSequenceMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetProductMediaSequence?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetPublishedRevisionMutationVariables = Exact<{
  id: Scalars['UUID'];
  htmlContent?: Maybe<Scalars['String']>;
}>;


export type SimpleProductSetPublishedRevisionMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetPublishedRevision?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetShippingNeedsTypeMutationVariables = Exact<{
  id: Scalars['UUID'];
  shippingNeedsType: ShippingNeedsType;
}>;


export type SimpleProductSetShippingNeedsTypeMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetShippingNeedsType?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetShippingRuleSetMutationVariables = Exact<{
  productId: Scalars['UUID'];
  shippingRuleSetId: Scalars['UUID'];
}>;


export type SimpleProductSetShippingRuleSetMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetShippingRuleSet?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetShipsAloneMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SimpleProductSetShipsAloneMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetShipsAlone?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetSkuMutationVariables = Exact<{
  id: Scalars['UUID'];
  sku: Scalars['String'];
}>;


export type SimpleProductSetSkuMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetSku?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetSlugMutationVariables = Exact<{
  id: Scalars['UUID'];
  slug: Scalars['String'];
}>;


export type SimpleProductSetSlugMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetSlug?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetSpecialPriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  specialPrice?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetSpecialPriceMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetSpecialPrice?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetSubscriptionMutationVariables = Exact<{
  id: Scalars['UUID'];
  subscription: Scalars['Boolean'];
}>;


export type SimpleProductSetSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetSubscription?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetSupplierMutationVariables = Exact<{
  productId: Scalars['UUID'];
  supplierId: Scalars['UUID'];
}>;


export type SimpleProductSetSupplierMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetSupplier?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetTitleMutationVariables = Exact<{
  id: Scalars['UUID'];
  title: Scalars['String'];
}>;


export type SimpleProductSetTitleMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetTitle?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetUpcMutationVariables = Exact<{
  id: Scalars['UUID'];
  upc?: Maybe<Scalars['String']>;
}>;


export type SimpleProductSetUpcMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetUpc?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetVendorRefMutationVariables = Exact<{
  id: Scalars['UUID'];
  vendorRef?: Maybe<Scalars['String']>;
}>;


export type SimpleProductSetVendorRefMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetVendorRef?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetVisibleMutationVariables = Exact<{
  id: Scalars['UUID'];
  visible: Scalars['Boolean'];
}>;


export type SimpleProductSetVisibleMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetVisible?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetWeightMutationVariables = Exact<{
  id: Scalars['UUID'];
  weight: Scalars['BigDecimal'];
}>;


export type SimpleProductSetWeightMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetWeight?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetWholesalePriceMutationVariables = Exact<{
  id: Scalars['UUID'];
  wholesalePrice?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetWholesalePriceMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetWholesalePrice?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductSetWidthMutationVariables = Exact<{
  id: Scalars['UUID'];
  width?: Maybe<Scalars['BigDecimal']>;
}>;


export type SimpleProductSetWidthMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductSetWidth?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductUploadImageMutationVariables = Exact<{
  id: Scalars['UUID'];
  dataUrl: Scalars['String'];
  filename: Scalars['String'];
  keywords?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}>;


export type SimpleProductUploadImageMutation = (
  { __typename?: 'Mutation' }
  & { simpleProductUploadImage?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Admin_Simple_Product_EntityFragment
  )> }
);

export type SimpleProductWithCrossSellsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type SimpleProductWithCrossSellsQuery = (
  { __typename?: 'Query' }
  & { simpleProductBySlug?: Maybe<(
    { __typename?: 'SimpleProductEntity' }
    & Pick<SimpleProductEntity, 'id' | 'cls' | 'sku' | 'slug' | 'title' | 'quantityAvailableForSale' | 'price' | 'salePrice'>
    & { medias?: Maybe<Array<Maybe<(
      { __typename?: 'ProductMediaEntity' }
      & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
    )>>>, shippingRuleSet?: Maybe<(
      { __typename?: 'ShippingRuleSetEntity' }
      & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
        { __typename?: 'LiveArrivalGuaranteeEntity' }
        & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
      )>>> }
    )>, crossSellItems?: Maybe<Array<Maybe<(
      { __typename?: 'CrossSellItemEntity' }
      & Pick<CrossSellItemEntity, 'id' | 'position'>
      & { crossSellProduct?: Maybe<(
        { __typename?: 'ProductEntity' }
        & Pick<ProductEntity, 'id' | 'cls' | 'active' | 'title' | 'sku' | 'slug' | 'countRating' | 'averageRating' | 'quantityAvailableForSale' | 'price' | 'salePrice' | 'variationKey' | 'featured'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type SubscriptionFragment = (
  { __typename?: 'SubscriptionEntity' }
  & Pick<SubscriptionEntity, 'id' | 'phone' | 'deliveryDay' | 'pauseReason' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'billingAddressValidationSource' | 'billingAddressType' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingAddressValidationSource' | 'shippingAddressType' | 'taxExempt'>
  & { paymentMethod?: Maybe<(
    { __typename?: 'PaymentMethodDetail' }
    & Pick<PaymentMethodDetail, 'token' | 'type' | 'defaultMethod' | 'comment' | 'image'>
  )>, salesOrders?: Maybe<Array<Maybe<(
    { __typename?: 'SubscriptionSalesOrderEntity' }
    & Pick<SubscriptionSalesOrderEntity, 'id' | 'cls' | 'grandTotal' | 'orderNumber' | 'status'>
    & { salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'name' | 'quantity'>
    )>>> }
  )>>>, subscriptionItems?: Maybe<Array<Maybe<(
    { __typename?: 'SubscriptionItemEntity' }
    & Pick<SubscriptionItemEntity, 'id' | 'frequency' | 'quantity' | 'lastDeliveryDate' | 'nextDeliveryDate'>
    & { product?: Maybe<(
      { __typename?: 'ProductEntity' }
      & Pick<ProductEntity, 'id' | 'cls' | 'title' | 'price' | 'salePrice' | 'slug'>
      & { medias?: Maybe<Array<Maybe<(
        { __typename?: 'ProductMediaEntity' }
        & Pick<ProductMediaEntity, 'id' | 'mediaType' | 'url'>
      )>>>, shippingRuleSet?: Maybe<(
        { __typename?: 'ShippingRuleSetEntity' }
        & { liveArrivalGuarantees?: Maybe<Array<Maybe<(
          { __typename?: 'LiveArrivalGuaranteeEntity' }
          & Pick<LiveArrivalGuaranteeEntity, 'minTemp' | 'maxTemp' | 'transitTimeType'>
        )>>>, destinationRestriction?: Maybe<(
          { __typename?: 'DestinationRestrictionEntity' }
          & Pick<DestinationRestrictionEntity, 'domesticOnly' | 'prohibitedStates'>
        )> }
      )> }
    )> }
  )>>> }
);

export type SubscriptionAddItemMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
  frequency: Scalars['Long'];
  promoCode?: Maybe<Scalars['String']>;
}>;


export type SubscriptionAddItemMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionAddItem?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SubscriptionCreateMutationVariables = Exact<{
  productId: Scalars['UUID'];
  quantity: Scalars['Long'];
  frequency: Scalars['Long'];
  deliveryDay?: Maybe<DayOfWeek>;
  phone: Scalars['String'];
  billingFirstName: Scalars['String'];
  billingLastName: Scalars['String'];
  billingCompany?: Maybe<Scalars['String']>;
  billingLine1: Scalars['String'];
  billingLine2?: Maybe<Scalars['String']>;
  billingCity: Scalars['String'];
  billingState: Scalars['String'];
  billingPostalCode: Scalars['String'];
  billingCountry: Scalars['String'];
  shippingFirstName: Scalars['String'];
  shippingLastName: Scalars['String'];
  shippingCompany?: Maybe<Scalars['String']>;
  shippingLine1: Scalars['String'];
  shippingLine2?: Maybe<Scalars['String']>;
  shippingCity: Scalars['String'];
  shippingState: Scalars['String'];
  shippingPostalCode: Scalars['String'];
  shippingCountry: Scalars['String'];
  shippingResidential?: Maybe<Scalars['Boolean']>;
  paymentToken?: Maybe<Scalars['String']>;
  taxExempt?: Maybe<Scalars['Boolean']>;
  promoCode?: Maybe<Scalars['String']>;
}>;


export type SubscriptionCreateMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionCreate?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SubscriptionInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SubscriptionInfoQuery = (
  { __typename?: 'Query' }
  & { subscriptionInfo?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SubscriptionListQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionListQuery = (
  { __typename?: 'Query' }
  & { subscriptionList?: Maybe<(
    { __typename?: 'GraphQLPage_SubscriptionEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'SubscriptionEntity' }
      & Pick<SubscriptionEntity, 'id' | 'deliveryDay' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingAddressValidationSource' | 'shippingAddressType'>
      & { subscriptionItems?: Maybe<Array<Maybe<(
        { __typename?: 'SubscriptionItemEntity' }
        & Pick<SubscriptionItemEntity, 'frequency' | 'quantity'>
        & { product?: Maybe<(
          { __typename?: 'ProductEntity' }
          & Pick<ProductEntity, 'id' | 'title' | 'price' | 'salePrice'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type SubscriptionRemoveItemMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  subscriptionItemId: Scalars['UUID'];
}>;


export type SubscriptionRemoveItemMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionRemoveItem?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SubscriptionSetBillingAddressMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type SubscriptionSetBillingAddressMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionSetBillingAddress?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SubscriptionSetDeliveryDayMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  deliveryDay?: Maybe<DayOfWeek>;
}>;


export type SubscriptionSetDeliveryDayMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionSetDeliveryDay?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SubscriptionSetNextDeliveryDateMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  subscriptionItemId?: Maybe<Scalars['UUID']>;
  nextDeliveryDate: Scalars['LocalDate'];
}>;


export type SubscriptionSetNextDeliveryDateMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionSetNextDeliveryDate?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SubscriptionSetPaymentTokenMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  paymentToken: Scalars['String'];
}>;


export type SubscriptionSetPaymentTokenMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionSetPaymentToken?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SubscriptionSetShippingAddressMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type SubscriptionSetShippingAddressMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionSetShippingAddress?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SubscriptionSkipNextDeliveryMutationVariables = Exact<{
  subscriptionId: Scalars['UUID'];
}>;


export type SubscriptionSkipNextDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { subscriptionSkipNextDelivery?: Maybe<(
    { __typename?: 'SubscriptionEntity' }
    & SubscriptionFragment
  )> }
);

export type SupplierClearEmailMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SupplierClearEmailMutation = (
  { __typename?: 'Mutation' }
  & { supplierClearEmail?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierClearPhoneMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SupplierClearPhoneMutation = (
  { __typename?: 'Mutation' }
  & { supplierClearPhone?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierCreateMutationVariables = Exact<{
  country: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  city: Scalars['String'];
  postalCode: Scalars['String'];
  name: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
}>;


export type SupplierCreateMutation = (
  { __typename?: 'Mutation' }
  & { supplierCreate?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SupplierDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'supplierDelete'>
);

export type SupplierInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type SupplierInfoQuery = (
  { __typename?: 'Query' }
  & { supplierInfo?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierListQueryVariables = Exact<{ [key: string]: never; }>;


export type SupplierListQuery = (
  { __typename?: 'Query' }
  & { supplierList?: Maybe<(
    { __typename?: 'GraphQLPage_SupplierEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'id' | 'slug' | 'name'>
    )>>> }
  )> }
);

export type SupplierSetEmailMutationVariables = Exact<{
  id: Scalars['UUID'];
  email: Scalars['String'];
}>;


export type SupplierSetEmailMutation = (
  { __typename?: 'Mutation' }
  & { supplierSetEmail?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierSetLeadTimeMutationVariables = Exact<{
  id: Scalars['UUID'];
  leadTime: Scalars['Int'];
}>;


export type SupplierSetLeadTimeMutation = (
  { __typename?: 'Mutation' }
  & { supplierSetLeadTime?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierSetNameMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type SupplierSetNameMutation = (
  { __typename?: 'Mutation' }
  & { supplierSetName?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierSetOrderCycleMutationVariables = Exact<{
  id: Scalars['UUID'];
  orderCycle: Scalars['Int'];
}>;


export type SupplierSetOrderCycleMutation = (
  { __typename?: 'Mutation' }
  & { supplierSetOrderCycle?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierSetPhoneMutationVariables = Exact<{
  id: Scalars['UUID'];
  phone: Scalars['String'];
}>;


export type SupplierSetPhoneMutation = (
  { __typename?: 'Mutation' }
  & { supplierSetPhone?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type SupplierUpdateAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  residential?: Maybe<Scalars['Boolean']>;
  addressType?: Maybe<AddressType>;
}>;


export type SupplierUpdateAddressMutation = (
  { __typename?: 'Mutation' }
  & { supplierUpdateAddress?: Maybe<(
    { __typename?: 'SupplierEntity' }
    & Supplier_EntityFragment
  )> }
);

export type TeamListQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamListQuery = (
  { __typename?: 'Query' }
  & { teamList?: Maybe<(
    { __typename?: 'GraphQLPage_TeamEntity' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'TeamEntity' }
      & Pick<TeamEntity, 'id' | 'slug' | 'name'>
    )>>> }
  )> }
);

export type TradeShowAssignTerminalMutationVariables = Exact<{
  tradeshowId: Scalars['UUID'];
  terminalId: Scalars['UUID'];
}>;


export type TradeShowAssignTerminalMutation = (
  { __typename?: 'Mutation' }
  & { tradeShowAssignTerminal?: Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'country' | 'city' | 'endDate' | 'startDate' | 'postalCode' | 'cls' | 'taxRate' | 'name' | 'state' | 'line2' | 'line1'>
  )> }
);

export type TradeShowCreateShowMutationVariables = Exact<{
  name: Scalars['String'];
  preOrderStartDate?: Maybe<Scalars['LocalDate']>;
  preOrderEndDate?: Maybe<Scalars['LocalDate']>;
  startDate: Scalars['LocalDate'];
  endDate: Scalars['LocalDate'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  posTerminalsRequested?: Maybe<Scalars['Long']>;
}>;


export type TradeShowCreateShowMutation = (
  { __typename?: 'Mutation' }
  & { tradeShowCreateShow?: Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'country' | 'city' | 'endDate' | 'startDate' | 'postalCode' | 'preOrderStartDate' | 'preOrderEndDate' | 'cls' | 'taxRate' | 'name' | 'state' | 'line2' | 'line1' | 'url' | 'posTerminalsRequested'>
  )> }
);

export type TradeShowDeleteShowMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TradeShowDeleteShowMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'tradeShowDeleteShow'>
);

export type TradeShowFilterQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  pageable: GraphQlPageableInput;
  sort?: Maybe<GraphQlSortInput>;
}>;


export type TradeShowFilterQuery = (
  { __typename?: 'Query' }
  & { tradeShowFilter?: Maybe<(
    { __typename?: 'GraphQLPage_TradeShowEntity' }
    & Pick<GraphQlPage_TradeShowEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'TradeShowEntity' }
      & Pick<TradeShowEntity, 'id' | 'country' | 'city' | 'endDate' | 'startDate' | 'postalCode' | 'preOrderEndDate' | 'preOrderStartDate' | 'cls' | 'name' | 'taxRate' | 'line1' | 'line2' | 'state'>
    )>>> }
  )> }
);

export type TradeShowInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TradeShowInfoQuery = (
  { __typename?: 'Query' }
  & { tradeShowInfo?: Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'cls' | 'name' | 'taxRate' | 'startDate' | 'endDate' | 'preOrderEndDate' | 'preOrderStartDate' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'url' | 'posTerminalsRequested'>
    & { posTerminals?: Maybe<Array<Maybe<(
      { __typename?: 'PosTerminalEntity' }
      & Pick<PosTerminalEntity, 'id' | 'cls' | 'assetTag'>
    )>>>, salesOrders?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderEntity' }
      & Pick<SalesOrderEntity, 'id' | 'alternateOrderNumber' | 'billingAddressValidationSource' | 'billingAddressType' | 'billingCity' | 'billingCompany' | 'billingCountry' | 'billingFirstName' | 'billingLastName' | 'billingLine1' | 'billingLine2' | 'billingPostalCode' | 'billingState' | 'cls' | 'couponCode' | 'createdAt' | 'discountTotal' | 'email' | 'fulfillmentChannelType' | 'grandTotal' | 'handlingTotal' | 'hold' | 'ipAddress' | 'orderNumber' | 'phone' | 'placedTime' | 'salesOrderType' | 'shippingAddressValidationSource' | 'shippingAddressType' | 'shippingCity' | 'shippingCompany' | 'shippingCountry' | 'shippingFirstName' | 'shippingLastName' | 'shippingLine1' | 'shippingLine2' | 'shippingMethod' | 'shippingPostalCode' | 'shippingResidential' | 'shippingState' | 'shippingTotal' | 'status' | 'subTotal' | 'taxCode' | 'taxTotal' | 'updatedAt'>
      & { comments?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderCommentsEntity' }
        & Pick<SalesOrderCommentsEntity, 'id'>
      )>>>, payments?: Maybe<Array<Maybe<(
        { __typename?: 'PaymentEntity' }
        & Pick<PaymentEntity, 'id'>
      )>>>, shipments?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentEntity' }
        & Pick<ShipmentEntity, 'id' | 'actualHeight' | 'actualLength' | 'actualShippingCost' | 'actualWeight' | 'actualWidth' | 'addressValidationSource' | 'carrier' | 'carrierPreference' | 'city' | 'cls' | 'company' | 'contentsMd5' | 'country' | 'createdAt' | 'downloadStart' | 'email' | 'estimatedDeliveryDateStart' | 'estimatedDeliveryDateEnd' | 'estimatedHeight' | 'estimatedLength' | 'estimatedShipDate' | 'estimatedWeight' | 'estimatedWidth' | 'finalApproval' | 'firstName' | 'lastName' | 'line1' | 'line2' | 'options' | 'packaging' | 'placedAt' | 'postalCode' | 'printedEnd' | 'printedStart' | 'reprint' | 'reseller' | 'residential' | 'service' | 'shipmentNumber' | 'shipmentStatus' | 'shippedAt' | 'shippedBy' | 'shippingNeeds' | 'state' | 'trackingNo' | 'trackingNos' | 'updatedAt'>
        & { shipmentListedItems?: Maybe<Array<Maybe<(
          { __typename?: 'ShipmentListedItemEntity' }
          & Pick<ShipmentListedItemEntity, 'id'>
        )>>>, shipmentItems?: Maybe<Array<Maybe<(
          { __typename?: 'ShipmentItemEntity' }
          & Pick<ShipmentItemEntity, 'id'>
        )>>> }
      )>>> }
    )>>> }
  )> }
);

export type TradeShowListInPreOrderQueryVariables = Exact<{ [key: string]: never; }>;


export type TradeShowListInPreOrderQuery = (
  { __typename?: 'Query' }
  & { tradeShowListInPreOrder?: Maybe<Array<Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'name' | 'url' | 'city' | 'state' | 'startDate' | 'endDate'>
  )>>> }
);

export type TradeShowListTerminalsMutationVariables = Exact<{ [key: string]: never; }>;


export type TradeShowListTerminalsMutation = (
  { __typename?: 'Mutation' }
  & { tradeShowListTerminals?: Maybe<Array<Maybe<(
    { __typename?: 'PosTerminalEntity' }
    & Pick<PosTerminalEntity, 'urn' | 'cls' | 'assetTag' | 'id'>
  )>>> }
);

export type TradeShowListUpcomingQueryVariables = Exact<{ [key: string]: never; }>;


export type TradeShowListUpcomingQuery = (
  { __typename?: 'Query' }
  & { tradeShowListUpcoming?: Maybe<Array<Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'name' | 'city' | 'state' | 'startDate' | 'endDate' | 'preOrderEndDate' | 'preOrderStartDate' | 'url'>
  )>>> }
);

export type TradeShowReportMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TradeShowReportMutation = (
  { __typename?: 'Mutation' }
  & { tradeShowReport?: Maybe<(
    { __typename?: 'ShowReport' }
    & Pick<ShowReport, 'showName' | 'startDate' | 'endDate' | 'city' | 'state' | 'subTotal' | 'discountTotal' | 'handlingTotal' | 'shippingTotal' | 'taxTotal' | 'grandTotal'>
    & { lineItems?: Maybe<Array<Maybe<(
      { __typename?: 'LineItem' }
      & Pick<LineItem, 'orderNumber' | 'sku' | 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
    )>>> }
  )> }
);

export type TradeShowSendReportMutationVariables = Exact<{
  id: Scalars['UUID'];
  emails: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type TradeShowSendReportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'tradeShowSendReport'>
);

export type TradeShowUnassignTerminalMutationVariables = Exact<{
  tradeshowId: Scalars['UUID'];
  terminalId: Scalars['UUID'];
}>;


export type TradeShowUnassignTerminalMutation = (
  { __typename?: 'Mutation' }
  & { tradeShowUnassignTerminal?: Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'country' | 'city' | 'endDate' | 'startDate' | 'postalCode' | 'cls' | 'taxRate' | 'name' | 'state' | 'line2' | 'line1'>
  )> }
);

export type TradeShowUpdateShowMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  preOrderStartDate?: Maybe<Scalars['LocalDate']>;
  preOrderEndDate?: Maybe<Scalars['LocalDate']>;
  startDate: Scalars['LocalDate'];
  endDate: Scalars['LocalDate'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  posTerminalsRequested?: Maybe<Scalars['Long']>;
}>;


export type TradeShowUpdateShowMutation = (
  { __typename?: 'Mutation' }
  & { tradeShowUpdateShow?: Maybe<(
    { __typename?: 'TradeShowEntity' }
    & Pick<TradeShowEntity, 'id' | 'country' | 'city' | 'endDate' | 'startDate' | 'postalCode' | 'preOrderStartDate' | 'preOrderEndDate' | 'cls' | 'taxRate' | 'name' | 'state' | 'line2' | 'line1' | 'url' | 'posTerminalsRequested'>
  )> }
);

export type UserChangePasswordByHashMutationVariables = Exact<{
  hash: Scalars['String'];
  password: Scalars['String'];
}>;


export type UserChangePasswordByHashMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userChangePasswordByHash'>
);

export type UserConfirmEmailByHashMutationVariables = Exact<{
  hash: Scalars['String'];
}>;


export type UserConfirmEmailByHashMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userConfirmEmail'>
);

export type UserFilterQueryVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['Boolean']>;
  pageable: GraphQlPageableInput;
  sort?: Maybe<GraphQlSortInput>;
  userType?: Maybe<UserType>;
}>;


export type UserFilterQuery = (
  { __typename?: 'Query' }
  & { userFilter?: Maybe<(
    { __typename?: 'GraphQLPage_UserEntity' }
    & Pick<GraphQlPage_UserEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'UserEntity' }
      & Pick<UserEntity, 'id' | 'firstName' | 'lastName' | 'email' | 'admin' | 'userType' | 'taxExempt' | 'netTerms'>
    )>>> }
  )> }
);

export type UserInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type UserInfoQuery = (
  { __typename?: 'Query' }
  & { userInfo?: Maybe<(
    { __typename?: 'UserEntity' }
    & Admin_User_EntityFragment
  )> }
);

export type UserSelfQueryVariables = Exact<{ [key: string]: never; }>;


export type UserSelfQuery = (
  { __typename?: 'Query' }
  & { userSelf?: Maybe<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'id' | 'email' | 'firstName' | 'lastName' | 'phoneNumber' | 'admin' | 'roles' | 'emailConfirmed' | 'taxExempt' | 'netTerms' | 'userType'>
  )> }
);

export type UserSendPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserSendPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userSendPasswordReset'>
);

export type UserUpdateAdminMutationVariables = Exact<{
  id: Scalars['UUID'];
  admin: Scalars['Boolean'];
}>;


export type UserUpdateAdminMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateAdmin?: Maybe<(
    { __typename?: 'UserEntity' }
    & Admin_User_EntityFragment
  )> }
);

export type UserUpdateEmailMutationVariables = Exact<{
  id: Scalars['UUID'];
  email: Scalars['String'];
}>;


export type UserUpdateEmailMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateEmail?: Maybe<(
    { __typename?: 'UserEntity' }
    & Admin_User_EntityFragment
  )> }
);

export type UserUpdateNameMutationVariables = Exact<{
  id: Scalars['UUID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


export type UserUpdateNameMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateName?: Maybe<(
    { __typename?: 'UserEntity' }
    & Admin_User_EntityFragment
  )> }
);

export type UserUpdateNetTermsMutationVariables = Exact<{
  id: Scalars['UUID'];
  netTerms: Scalars['Int'];
}>;


export type UserUpdateNetTermsMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateNetTerms?: Maybe<(
    { __typename?: 'UserEntity' }
    & Admin_User_EntityFragment
  )> }
);

export type UserUpdatePasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type UserUpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { userUpdatePassword?: Maybe<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'id' | 'email' | 'firstName' | 'lastName' | 'admin' | 'emailConfirmed'>
  )> }
);

export type UserUpdateTaxExemptMutationVariables = Exact<{
  taxExempt: Scalars['Boolean'];
  id: Scalars['UUID'];
}>;


export type UserUpdateTaxExemptMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateTaxExempt?: Maybe<(
    { __typename?: 'UserEntity' }
    & Admin_User_EntityFragment
  )> }
);

export type UserUpdateTypeMutationVariables = Exact<{
  id: Scalars['UUID'];
  type: UserType;
}>;


export type UserUpdateTypeMutation = (
  { __typename?: 'Mutation' }
  & { userUpdateType?: Maybe<(
    { __typename?: 'UserEntity' }
    & Admin_User_EntityFragment
  )> }
);

export type WalmartSalesOrderRefreshMutationVariables = Exact<{
  purchaseOrderId: Scalars['String'];
}>;


export type WalmartSalesOrderRefreshMutation = (
  { __typename?: 'Mutation' }
  & { walmartSalesOrderRefresh?: Maybe<(
    { __typename?: 'WalmartSalesOrderEntity' }
    & Pick<WalmartSalesOrderEntity, 'id' | 'cls' | 'orderNumber' | 'alternateOrderNumber' | 'placedTime' | 'email' | 'phone' | 'ipAddress' | 'status' | 'hold' | 'couponCode' | 'billingFirstName' | 'billingLastName' | 'billingCompany' | 'billingLine1' | 'billingLine2' | 'billingCity' | 'billingState' | 'billingPostalCode' | 'billingCountry' | 'shippingFirstName' | 'shippingLastName' | 'shippingCompany' | 'shippingLine1' | 'shippingLine2' | 'shippingCity' | 'shippingState' | 'shippingPostalCode' | 'shippingCountry' | 'shippingAddressValidationSource' | 'salesOrderType' | 'subTotal' | 'discountTotal' | 'taxCode' | 'taxTotal' | 'shippingTotal' | 'handlingTotal' | 'grandTotal'>
    & { salesOrderListedItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderListedItemEntity' }
      & Pick<SalesOrderListedItemEntity, 'id' | 'name' | 'quantity' | 'unitAmount' | 'lineAmount'>
      & { listedProduct?: Maybe<(
        { __typename?: 'ListedProductEntity' }
        & Pick<ListedProductEntity, 'slug' | 'title'>
        & { medias?: Maybe<Array<Maybe<(
          { __typename?: 'ProductMediaEntity' }
          & Pick<ProductMediaEntity, 'url'>
        )>>> }
      )>, listing?: Maybe<(
        { __typename?: 'ListingEntity' }
        & Pick<ListingEntity, 'identifier' | 'title'>
        & { listingMedias?: Maybe<Array<Maybe<(
          { __typename?: 'ListingMediaEntity' }
          & Pick<ListingMediaEntity, 'url'>
        )>>> }
      )> }
    )>>>, salesOrderItemGroups?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemGroupEntity' }
      & Pick<SalesOrderItemGroupEntity, 'id' | 'lineAmount' | 'quantity' | 'unitAmount'>
      & { salesOrderItems?: Maybe<Array<Maybe<(
        { __typename?: 'SalesOrderItemEntity' }
        & Pick<SalesOrderItemEntity, 'sku' | 'name' | 'quantity'>
      )>>>, kitProduct?: Maybe<(
        { __typename?: 'KitProductEntity' }
        & Pick<KitProductEntity, 'sku' | 'title'>
      )> }
    )>>>, salesOrderItems?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderItemEntity' }
      & Pick<SalesOrderItemEntity, 'id' | 'sku' | 'name' | 'quantity' | 'unitAmount' | 'discountAmount' | 'lineAmount'>
      & { salesOrderItemGroup?: Maybe<(
        { __typename?: 'SalesOrderItemGroupEntity' }
        & Pick<SalesOrderItemGroupEntity, 'id'>
      )> }
    )>>>, shipments?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentEntity' }
      & Pick<ShipmentEntity, 'id' | 'shipmentNumber' | 'shipmentStatus' | 'estimatedShipDate' | 'estimatedDeliveryDateStart' | 'estimatedDeliveryDateEnd' | 'estimatedWeight' | 'estimatedLength' | 'estimatedWidth' | 'estimatedHeight' | 'actualWeight' | 'actualLength' | 'actualWidth' | 'actualHeight' | 'placedAt' | 'printedStart' | 'printedEnd' | 'shippedAt' | 'carrier' | 'service' | 'shippingNeeds'>
      & { departingWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, originWarehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'id' | 'name'>
      )>, shipmentListedItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentListedItemEntity' }
        & Pick<ShipmentListedItemEntity, 'id' | 'quantity'>
        & { salesOrderListedItem?: Maybe<(
          { __typename?: 'SalesOrderListedItemEntity' }
          & Pick<SalesOrderListedItemEntity, 'id' | 'sku' | 'name'>
        )> }
      )>>>, shipmentItems?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentItemEntity' }
        & Pick<ShipmentItemEntity, 'id' | 'quantity'>
        & { salesOrderItem?: Maybe<(
          { __typename?: 'SalesOrderItemEntity' }
          & Pick<SalesOrderItemEntity, 'id' | 'sku' | 'name'>
        )> }
      )>>> }
    )>>>, payments?: Maybe<Array<Maybe<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'id' | 'transactionId' | 'paymentStatus' | 'placedAt' | 'amount' | 'description'>
    )>>>, comments?: Maybe<Array<Maybe<(
      { __typename?: 'SalesOrderCommentsEntity' }
      & Pick<SalesOrderCommentsEntity, 'id' | 'time' | 'name' | 'comment'>
    )>>> }
  )> }
);

export type WarehouseFragment = (
  { __typename?: 'WarehouseEntity' }
  & Pick<WarehouseEntity, 'id' | 'enabled' | 'latitude' | 'longitude' | 'name' | 'slug' | 'alternateSlug' | 'warehouseType' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'carriers'>
  & { workSchedule?: Maybe<(
    { __typename?: 'WorkScheduleEntity' }
    & Pick<WorkScheduleEntity, 'timezone' | 'closedOnCoreHolidays' | 'closedOnUSPSHolidays'>
    & { openCloseTimes?: Maybe<Array<Maybe<(
      { __typename?: 'OpenCloseTimeEntity' }
      & Pick<OpenCloseTimeEntity, 'dayOfWeek' | 'openTime' | 'closeTime' | 'shipsScheduled'>
    )>>>, holidayTimes?: Maybe<Array<Maybe<(
      { __typename?: 'HolidayTimeEntity' }
      & Pick<HolidayTimeEntity, 'date' | 'openTime' | 'closeTime' | 'shipsScheduled'>
    )>>> }
  )>, queues?: Maybe<Array<Maybe<(
    { __typename?: 'QueueEntity' }
    & Pick<QueueEntity, 'id' | 'enabled' | 'name' | 'slug' | 'priority' | 'template' | 'shippingNeeds' | 'singlesOnly' | 'combosOnly'>
    & { zone?: Maybe<(
      { __typename?: 'ZoneEntity' }
      & Pick<ZoneEntity, 'id' | 'name'>
    )> }
  )>>>, zones?: Maybe<Array<Maybe<(
    { __typename?: 'ZoneEntity' }
    & Pick<ZoneEntity, 'id' | 'name' | 'slug' | 'sequence'>
  )>>> }
);

export type WarehouseAddCarriersMutationVariables = Exact<{
  id: Scalars['UUID'];
  carriers: Array<Maybe<Carrier>> | Maybe<Carrier>;
}>;


export type WarehouseAddCarriersMutation = (
  { __typename?: 'Mutation' }
  & { warehouseAddCarriers?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseCreateMutationVariables = Exact<{
  name: Scalars['String'];
  slug: Scalars['String'];
  warehouseType: WarehouseType;
  carriers: Array<Maybe<Carrier>> | Maybe<Carrier>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type WarehouseCreateMutation = (
  { __typename?: 'Mutation' }
  & { warehouseCreate?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseCreateQueueMutationVariables = Exact<{
  id: Scalars['UUID'];
  zoneId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  priority: Scalars['Long'];
  template?: Maybe<Scalars['String']>;
  shippingNeedsType?: Maybe<ShippingNeedsType>;
  singlesOnly?: Maybe<Scalars['Boolean']>;
  combosOnly?: Maybe<Scalars['Boolean']>;
}>;


export type WarehouseCreateQueueMutation = (
  { __typename?: 'Mutation' }
  & { warehouseCreateQueue?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseCreateZoneMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sequence?: Maybe<Scalars['Long']>;
}>;


export type WarehouseCreateZoneMutation = (
  { __typename?: 'Mutation' }
  & { warehouseCreateZone?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseFilterQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  warehouseType?: Maybe<WarehouseType>;
  enabled?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<GraphQlSortInput>;
  pageable: GraphQlPageableInput;
}>;


export type WarehouseFilterQuery = (
  { __typename?: 'Query' }
  & { warehouseFilter?: Maybe<(
    { __typename?: 'GraphQLPage_WarehouseEntity' }
    & Pick<GraphQlPage_WarehouseEntity, 'count' | 'page' | 'pageSize'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'id' | 'cls' | 'warehouseType' | 'name' | 'slug' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'latitude' | 'longitude' | 'enabled'>
    )>>> }
  )> }
);

export type WarehouseInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type WarehouseInfoQuery = (
  { __typename?: 'Query' }
  & { warehouseInfo?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseListQueryVariables = Exact<{ [key: string]: never; }>;


export type WarehouseListQuery = (
  { __typename?: 'Query' }
  & { warehouseList?: Maybe<Array<Maybe<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'id' | 'cls' | 'warehouseType' | 'name' | 'slug' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'latitude' | 'longitude' | 'enabled'>
  )>>> }
);

export type WarehouseRemoveCarriersMutationVariables = Exact<{
  id: Scalars['UUID'];
  carriers: Array<Maybe<Carrier>> | Maybe<Carrier>;
}>;


export type WarehouseRemoveCarriersMutation = (
  { __typename?: 'Mutation' }
  & { warehouseRemoveCarriers?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseRemoveQueuesMutationVariables = Exact<{
  id: Scalars['UUID'];
  queueIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type WarehouseRemoveQueuesMutation = (
  { __typename?: 'Mutation' }
  & { warehouseRemoveQueues?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseRemoveZonesMutationVariables = Exact<{
  id: Scalars['UUID'];
  zoneIds: Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>;
}>;


export type WarehouseRemoveZonesMutation = (
  { __typename?: 'Mutation' }
  & { warehouseRemoveZones?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseSetAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
}>;


export type WarehouseSetAddressMutation = (
  { __typename?: 'Mutation' }
  & { warehouseSetAddress?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseSetAlternateSlugMutationVariables = Exact<{
  id: Scalars['UUID'];
  alternateSlug?: Maybe<Scalars['String']>;
}>;


export type WarehouseSetAlternateSlugMutation = (
  { __typename?: 'Mutation' }
  & { warehouseSetAlternateSlug?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseSetDropshipperMutationVariables = Exact<{
  id: Scalars['UUID'];
  partnerId?: Maybe<Scalars['UUID']>;
}>;


export type WarehouseSetDropshipperMutation = (
  { __typename?: 'Mutation' }
  & { warehouseSetDropshipper?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseSetEnabledMutationVariables = Exact<{
  id: Scalars['UUID'];
  enabled: Scalars['Boolean'];
}>;


export type WarehouseSetEnabledMutation = (
  { __typename?: 'Mutation' }
  & { warehouseSetEnabled?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseSetLatitudeMutationVariables = Exact<{
  id: Scalars['UUID'];
  latitude?: Maybe<Scalars['Float']>;
}>;


export type WarehouseSetLatitudeMutation = (
  { __typename?: 'Mutation' }
  & { warehouseSetLatitude?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseSetLongitudeMutationVariables = Exact<{
  id: Scalars['UUID'];
  longitude?: Maybe<Scalars['Float']>;
}>;


export type WarehouseSetLongitudeMutation = (
  { __typename?: 'Mutation' }
  & { warehouseSetLongitude?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseSetNameMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
}>;


export type WarehouseSetNameMutation = (
  { __typename?: 'Mutation' }
  & { warehouseSetName?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseSetSlugMutationVariables = Exact<{
  id: Scalars['UUID'];
  slug: Scalars['String'];
}>;


export type WarehouseSetSlugMutation = (
  { __typename?: 'Mutation' }
  & { warehouseSetSlug?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type WarehouseSetWarehouseTypeMutationVariables = Exact<{
  id: Scalars['UUID'];
  warehouseType: WarehouseType;
}>;


export type WarehouseSetWarehouseTypeMutation = (
  { __typename?: 'Mutation' }
  & { warehouseSetWarehouseType?: Maybe<(
    { __typename?: 'WarehouseEntity' }
    & WarehouseFragment
  )> }
);

export type ZoneListQueryVariables = Exact<{ [key: string]: never; }>;


export type ZoneListQuery = (
  { __typename?: 'Query' }
  & { zoneList?: Maybe<Array<Maybe<(
    { __typename?: 'ZoneEntity' }
    & Pick<ZoneEntity, 'id' | 'name'>
    & { warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'id' | 'name' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country' | 'latitude' | 'longitude'>
    )> }
  )>>> }
);

export type ZoneUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sequence?: Maybe<Scalars['Long']>;
}>;


export type ZoneUpdateMutation = (
  { __typename?: 'Mutation' }
  & { zoneUpdate?: Maybe<(
    { __typename?: 'ZoneEntity' }
    & Pick<ZoneEntity, 'id' | 'name' | 'slug' | 'sequence'>
  )> }
);

export const Cart_CheckoutFragmentDoc = gql`
    fragment CART_CHECKOUT on CartEntity {
  id
  email
  phone
  billingAddressValidationSource
  billingFirstName
  billingLastName
  billingCompany
  billingLine1
  billingLine2
  billingCity
  billingState
  billingPostalCode
  billingCountry
  shippingAddressValidationSource
  shippingFirstName
  shippingLastName
  shippingCompany
  shippingLine1
  shippingLine2
  shippingCity
  shippingState
  shippingPostalCode
  shippingCountry
  shippingResidential
  couponCode
  taxExempt
  subtotal
  cartItemsCount
  cartBatchItemsCount
  tradeShow {
    id
    name
    url
    line1
    line2
    city
    state
    postalCode
    country
    startDate
    endDate
  }
  pickupWarehouse {
    id
    line1
    line2
    city
    state
    postalCode
    country
  }
  cartItemGroups {
    id
    quantity
    kitProduct {
      id
      cls
      slug
      sku
      title
      price
      salePrice
      quantityAvailableForSale
      medias {
        id
        mediaType
        url
      }
    }
    price
    salePrice
  }
  cartItems {
    id
    cartItemGroup {
      id
    }
    simpleProduct {
      id
      cls
      slug
      sku
      title
      price
      salePrice
      quantityAvailableForSale
      shippingNeeds
      medias {
        id
        mediaType
        url
      }
      explicitCategories {
        id
        slug
      }
    }
    quantity
    price
    salePrice
    carrierPreference
    deliveryDatePreference
    serviceTypePreference
  }
  cartListedItems {
    id
    listing {
      id
      identifier
      title
      comments
      quantity
      title
      listingMedias {
        id
        mediaType
        url
      }
    }
    listedProduct {
      id
      cls
      slug
      sku
      title
      price
      salePrice
      shippingNeeds
      medias {
        id
        mediaType
        url
      }
      explicitCategories {
        id
        slug
      }
    }
    quantity
    price
    salePrice
    carrierPreference
    deliveryDatePreference
    serviceTypePreference
  }
  cartCustomItems {
    id
    quantity
    productPrice
    title
  }
  notifications {
    type
    message
    cartItem {
      id
    }
    cartItemGroup {
      id
    }
    cartListedItem {
      id
    }
  }
}
    `;
export const Admin_Kit_Product_EntityFragmentDoc = gql`
    fragment ADMIN_KIT_PRODUCT_ENTITY on KitProductEntity {
  id
  cls
  slug
  sku
  magentoId
  upc
  title
  active
  quantityAvailableForSale
  cost
  metaDescription
  metaKeywords
  metaTitle
  price
  specialPrice
  subscription
  wholesalePrice
  featured
  visible
  explicitAnimals {
    id
    slug
    name
    parent {
      id
      slug
      name
      parent {
        id
        slug
        name
      }
    }
  }
  explicitCategories {
    id
    slug
    path
    name
    parent {
      id
      slug
      name
      parent {
        id
        slug
        path
        name
      }
    }
  }
  explicitDepartment {
    id
    slug
    name
    parent {
      id
      slug
      name
    }
  }
  kitItems {
    id
    quantity
    altQuantity
    position
    altSimpleProduct {
      id
      sku
      title
      price
      medias {
        id
        mediaType
        url
      }
    }
    simpleProduct {
      id
      sku
      title
      price
      medias {
        id
        mediaType
        url
      }
    }
  }
  medias {
    id
    mediaType
    sequence
    url
    media {
      title
    }
  }
  publishedRevision {
    htmlContent
  }
  implicitPromotions {
    id
    name
    slug
    promotionType
    active
    startDate
    endDate
    permitStacking
    eligibleUserType
    eligibleCouponCode
    eligibleItemValue
    eligibleItemCount
    eligibleCartValue
    discountAmount
  }
  implicitDiscounts {
    id
    name
    slug
    discountType
    active
    startDate
    endDate
    permitStacking
    eligibleUserType
    eligibleCouponCode
    eligibleCartValue
    discountAmount
  }
  amazonProfile {
    id
    asin
    fbaSellerSku
    sellerSku
  }
  petcoDropshipProfile {
    id
    description
    sku
    surcharge
  }
  petcoStoreDirectProfile {
    id
    description
    sku
  }
  chewyProfile {
    id
    description
    sku
    cost
  }
  walmartProfile {
    id
    sellerSku
    wfsSellerSku
  }
  resellerProfiles {
    id
    resellerSku
    price
    retail
    reseller {
      id
      name
    }
  }
  crossSellItems {
    id
    position
    crossSellProduct {
      id
      title
      sku
      price
      quantityAvailableForSale
      cls
      medias {
        id
        mediaType
        url
      }
    }
  }
  priceTiers {
    id
    price
    quantity
    userType
  }
}
    `;
export const Admin_Configurable_Product_EntityFragmentDoc = gql`
    fragment ADMIN_CONFIGURABLE_PRODUCT_ENTITY on ConfigurableProductEntity {
  id
  cls
  slug
  sku
  magentoId
  metaDescription
  metaKeywords
  metaTitle
  upc
  title
  active
  quantityAvailableForSale
  featured
  visible
  configurableItems {
    id
    position
    simpleProduct {
      id
      sku
      title
      price
      quantityAvailableForSale
      medias {
        id
        mediaType
        url
      }
    }
  }
  explicitAnimals {
    id
    slug
    name
    parent {
      id
      slug
      name
      parent {
        id
        slug
        name
      }
    }
  }
  explicitCategories {
    id
    slug
    path
    name
    parent {
      id
      slug
      path
      name
      parent {
        id
        slug
        path
        name
      }
    }
  }
  explicitDepartment {
    id
    slug
    name
    parent {
      id
      slug
      name
    }
  }
  medias {
    id
    mediaType
    sequence
    url
    media {
      title
    }
  }
  publishedRevision {
    htmlContent
  }
  implicitPromotions {
    id
    name
    slug
    promotionType
    active
    startDate
    endDate
    permitStacking
    eligibleUserType
    eligibleCouponCode
    eligibleItemValue
    eligibleItemCount
    eligibleCartValue
    discountAmount
  }
  implicitDiscounts {
    id
    name
    slug
    discountType
    active
    startDate
    endDate
    permitStacking
    eligibleUserType
    eligibleCouponCode
    eligibleCartValue
    discountAmount
  }
}
    `;
export const User_ReviewFragmentDoc = gql`
    fragment USER_REVIEW on ReviewEntity {
  id
  comments
  createdTime
  rating
  reviewer
  status
  title
  updatedTime
  medias {
    id
    keywords
    title
    url
  }
  product {
    id
    slug
    title
    cls
    medias {
      url
    }
  }
}
    `;
export const Customer_Sales_OrderFragmentDoc = gql`
    fragment CUSTOMER_SALES_ORDER on SalesOrderEntity {
  id
  orderNumber
  placedTime
  email
  phone
  status
  billingFirstName
  billingLastName
  billingCompany
  billingLine1
  billingLine2
  billingCity
  billingState
  billingPostalCode
  billingCountry
  shippingFirstName
  shippingLastName
  shippingCompany
  shippingLine1
  shippingLine2
  shippingCity
  shippingState
  shippingPostalCode
  shippingCountry
  subTotal
  discountTotal
  taxTotal
  shippingTotal
  handlingTotal
  grandTotal
  payments {
    id
    amount
    description
    placedAt
  }
  refunds {
    id
    grandTotal
    placedTime
    refundNumber
    refundItems {
      id
      quantity
      salesOrderItem {
        id
        name
      }
    }
  }
  salesOrderListedItems {
    id
    name
    quantity
    unitAmount
    lineAmount
    listedProduct {
      id
      cls
      slug
      title
      medias {
        url
      }
      userReview {
        ...USER_REVIEW
      }
    }
    listing {
      identifier
      title
      listingMedias {
        url
      }
    }
  }
  salesOrderItems {
    id
    name
    quantity
    unitAmount
    lineAmount
    simpleProduct {
      id
      cls
      slug
      title
      medias {
        url
      }
      userReview {
        ...USER_REVIEW
      }
    }
    salesOrderItemGroup {
      id
    }
  }
  salesOrderItemGroups {
    name
    quantity
    unitAmount
    lineAmount
    salesOrderItems {
      name
      quantity
    }
    kitProduct {
      id
      cls
      slug
      title
      medias {
        url
      }
      userReview {
        ...USER_REVIEW
      }
    }
  }
  shipments {
    id
    shipmentNumber
    shipmentStatus
    carrier
    service
    trackingNos
    trackingUrls
    shipmentListedItems {
      quantity
      salesOrderListedItem {
        name
      }
    }
    shipmentItems {
      quantity
      salesOrderItem {
        name
      }
    }
  }
}
    ${User_ReviewFragmentDoc}`;
export const Supplier_EntityFragmentDoc = gql`
    fragment SUPPLIER_ENTITY on SupplierEntity {
  id
  firstName
  lastName
  company
  name
  phone
  email
  line1
  line2
  city
  state
  postalCode
  country
  addressType
  addressValidationSource
  createdAt
  leadTime
  orderCycle
  residential
  slug
  simpleProducts {
    id
    sku
    title
    medias {
      id
      url
    }
    active
    price
    quantityAvailableForSale
  }
}
    `;
export const Brand_EntityFragmentDoc = gql`
    fragment BRAND_ENTITY on BrandEntity {
  id
  name
  products {
    id
    cls
    sku
    title
    medias {
      id
      url
    }
    active
    price
    quantityAvailableForSale
  }
  slug
}
    `;
export const Admin_Sales_OrderFragmentDoc = gql`
    fragment ADMIN_SALES_ORDER on SalesOrderEntity {
  id
  cls
  orderNumber
  alternateOrderNumber
  placedTime
  email
  phone
  ipAddress
  status
  hold
  couponCode
  billingFirstName
  billingLastName
  billingCompany
  billingLine1
  billingLine2
  billingCity
  billingState
  billingPostalCode
  billingCountry
  shippingFirstName
  shippingLastName
  shippingCompany
  shippingLine1
  shippingLine2
  shippingCity
  shippingState
  shippingPostalCode
  shippingCountry
  shippingAddressValidationSource
  salesOrderType
  subTotal
  discountTotal
  taxCode
  taxTotal
  shippingTotal
  handlingTotal
  grandTotal
  user {
    id
    email
    firstName
    lastName
  }
  paymentTermsAccount {
    id
  }
  salesOrderListedItems {
    id
    sku
    name
    quantity
    unitAmount
    lineAmount
    listedProduct {
      slug
      title
      medias {
        url
      }
    }
    listing {
      identifier
      title
      listingMedias {
        url
      }
    }
  }
  salesOrderItemGroups {
    id
    lineAmount
    quantity
    sku
    name
    unitAmount
    salesOrderItems {
      sku
      name
      quantity
    }
    kitProduct {
      sku
      title
    }
  }
  salesOrderItems {
    id
    sku
    name
    quantity
    unitAmount
    discountAmount
    lineAmount
    simpleProduct {
      id
      quantityAvailableForSale
    }
    salesOrderItemGroup {
      id
    }
  }
  shipments {
    id
    shipmentNumber
    shipmentStatus
    estimatedShipDate
    estimatedDeliveryDateStart
    estimatedDeliveryDateEnd
    estimatedWeight
    estimatedLength
    estimatedWidth
    estimatedHeight
    actualWeight
    actualLength
    actualWidth
    actualHeight
    placedAt
    printedStart
    printedEnd
    shippedAt
    carrier
    service
    departingWarehouse {
      id
      name
    }
    originWarehouse {
      id
      name
    }
    shippingNeeds
    shipmentListedItems {
      id
      quantity
      salesOrderListedItem {
        id
        sku
        name
      }
    }
    shipmentItems {
      id
      quantity
      salesOrderItem {
        id
        sku
        name
      }
    }
  }
  refunds {
    id
    refundNumber
    repFirstName
    subTotal
    productDiscountTotal
    productTaxTotal
    shippingTotal
    handlingTotal
    shippingDiscountTotal
    shippingTaxTotal
    grandTotal
  }
  reships {
    id
    status
    orderNumber
  }
  payments {
    id
    transactionId
    paymentStatus
    placedAt
    amount
    description
  }
  comments {
    id
    time
    name
    comment
  }
}
    `;
export const Admin_Shipment_EntityFragmentDoc = gql`
    fragment ADMIN_SHIPMENT_ENTITY on ShipmentEntity {
  id
  cls
  shipmentNumber
  shipmentStatus
  shippingNeeds
  actualShippingCost
  estimatedShippingCost
  actualReseller
  estimatedReseller
  actualCarrier
  estimatedCarrier
  carrier
  carrierPreference
  actualService
  estimatedService
  estimatedOptions
  actualOptions
  service
  estimatedWeight
  actualWeight
  estimatedLength
  estimatedWidth
  estimatedHeight
  actualLength
  actualWidth
  actualHeight
  placedAt
  estimatedShipDate
  estimatedDeliveryDateStart
  estimatedDeliveryDateEnd
  shippedAt
  shippedBy
  cancelledAt
  cancelledBy
  cancellationReason
  trackingNos
  trackingUrls
  email
  firstName
  lastName
  company
  line1
  line2
  city
  state
  postalCode
  country
  residential
  addressType
  addressValidationSource
  downloadStart
  printedStart
  printedEnd
  reprint
  shipmentBatch {
    id
    name
  }
  queue {
    id
    name
  }
  originWarehouse {
    id
    name
  }
  departingWarehouse {
    id
    name
  }
  salesOrder {
    id
    cls
    orderNumber
    alternateOrderNumber
    placedTime
    status
  }
  shipmentListedItems {
    id
    cls
    quantity
    salesOrderListedItem {
      id
      cls
      sku
      name
    }
  }
  shipmentItems {
    id
    cls
    quantity
    salesOrderItem {
      id
      cls
      sku
      name
    }
  }
  shipmentAddons {
    id
    quantity
    sku
    name
  }
}
    `;
export const Customer_ShipmentFragmentDoc = gql`
    fragment CUSTOMER_SHIPMENT on ShipmentEntity {
  id
  carrier
  email
  scheduledAt
  estimatedDeliveryDateStart
  estimatedDeliveryDateEnd
  service
  shipmentNumber
  shipmentStatus
  shippedAt
  scheduledAt
  scheduledBy
  trackingNos
  trackingUrls
  firstName
  lastName
  company
  line1
  line2
  city
  state
  postalCode
  country
  residential
  addressType
  addressValidationSource
  shippingNeeds
  backorder
  backorderExpiry
  salesOrder {
    id
    orderNumber
    placedTime
  }
  shipmentListedItems {
    id
    quantity
    salesOrderListedItem {
      id
      name
    }
    listedProduct {
      id
      slug
      title
      medias {
        mediaType
        url
      }
    }
  }
  shipmentItems {
    id
    quantity
    salesOrderItem {
      id
      name
    }
    simpleProduct {
      id
      slug
      title
      medias {
        mediaType
        url
      }
    }
  }
}
    `;
export const Customer_Shipment_PublicFragmentDoc = gql`
    fragment CUSTOMER_SHIPMENT_PUBLIC on ShipmentEntity {
  id
  carrier
  email
  scheduledAt
  estimatedDeliveryDateStart
  estimatedDeliveryDateEnd
  service
  shipmentNumber
  shipmentStatus
  shippedAt
  scheduledAt
  scheduledBy
  trackingNos
  trackingUrls
  firstName
  lastName
  company
  line1
  line2
  city
  state
  postalCode
  country
  residential
  addressType
  addressValidationSource
  shippingNeeds
  backorder
  backorderExpiry
  salesOrder {
    id
    orderNumber
    placedTime
  }
  shipmentListedItems {
    id
    quantity
    salesOrderListedItem {
      id
      name
    }
    listedProduct {
      id
      slug
      title
      medias {
        mediaType
        url
      }
    }
  }
  shipmentItems {
    id
    quantity
    salesOrderItem {
      id
      name
    }
    simpleProduct {
      id
      slug
      title
      medias {
        mediaType
        url
      }
    }
  }
}
    `;
export const Admin_User_EntityFragmentDoc = gql`
    fragment ADMIN_USER_ENTITY on UserEntity {
  id
  cls
  magentoId
  email
  firstName
  lastName
  admin
  roles
  emailConfirmed
  userType
  taxExempt
  netTerms
  createdAt
  updatedAt
  addresses {
    id
    firstName
    lastName
    cls
    company
    line1
    line2
    city
    state
    postalCode
    country
    createdAt
    latitude
    longitude
    magentoId
    residential
    addressType
    addressValidationSource
    updatedAt
  }
}
    `;
export const Admin_Partner_EntityFragmentDoc = gql`
    fragment ADMIN_PARTNER_ENTITY on PartnerEntity {
  id
  affiliate
  affiliateAttributionPeriod
  affiliateBiography
  affiliateCommissionRate
  dropshipper
  email
  name
  reseller
  resellerShippingRateMode
  resellerTaxMode
  slug
  firstName
  lastName
  company
  line1
  line2
  city
  state
  postalCode
  residential
  addressType
  addressValidationSource
  affiliateProductLists {
    id
    name
    sequence
  }
}
    `;
export const Admin_Affiliate_Product_ListFragmentDoc = gql`
    fragment ADMIN_AFFILIATE_PRODUCT_LIST on AffiliateProductListEntity {
  id
  htmlContent
  name
  sequence
  slug
  affiliateProductListItems {
    id
    position
    product {
      id
      cls
      price
      title
      sku
      quantityAvailableForSale
      medias {
        url
      }
    }
  }
}
    `;
export const Shipment_EntityFragmentDoc = gql`
    fragment SHIPMENT_ENTITY on ShipmentEntity {
  id
  placedAt
  cls
  email
  firstName
  lastName
  company
  line1
  line2
  city
  state
  postalCode
  country
  addressValidationSource
  shipmentNumber
  shipmentStatus
  shippingNeeds
  carrier
  service
  estimatedWeight
  actualWeight
  estimatedLength
  estimatedWidth
  estimatedHeight
  actualLength
  actualWidth
  actualHeight
  estimatedShipDate
  estimatedDeliveryDateStart
  estimatedDeliveryDateEnd
  shippedAt
  trackingNo
  schedulingStatus
  scheduledAt
  scheduledBy
  finalApproval
  finalApprovalBy
  originWarehouse {
    id
    name
  }
  departingWarehouse {
    id
    name
  }
  salesOrder {
    cls
    orderNumber
    alternateOrderNumber
    salesOrderType
  }
  shipmentListedItems {
    id
    cls
    quantity
    salesOrderListedItem {
      id
      cls
      sku
      name
    }
  }
  shipmentItems {
    id
    cls
    quantity
    salesOrderItem {
      id
      cls
      sku
      name
    }
  }
}
    `;
export const Scheduled_Shipment_EntityFragmentDoc = gql`
    fragment SCHEDULED_SHIPMENT_ENTITY on ShipmentEntity {
  id
  placedAt
  cls
  email
  firstName
  lastName
  company
  line1
  line2
  city
  state
  postalCode
  country
  addressValidationSource
  shipmentNumber
  shipmentStatus
  shippingNeeds
  carrier
  service
  estimatedWeight
  actualWeight
  estimatedLength
  estimatedWidth
  estimatedHeight
  actualLength
  actualWidth
  actualHeight
  estimatedShipDate
  estimatedDeliveryDateStart
  estimatedDeliveryDateEnd
  shippedAt
  trackingNo
  schedulingStatus
  scheduledAt
  scheduledBy
  finalApproval
  finalApprovalBy
  originWarehouse {
    id
    name
  }
  departingWarehouse {
    id
    name
  }
  shipmentListedItems {
    id
    cls
    quantity
    salesOrderListedItem {
      id
      cls
      sku
      name
    }
  }
  shipmentItems {
    id
    cls
    quantity
    salesOrderItem {
      id
      cls
      sku
      name
    }
  }
  salesOrder {
    cls
    orderNumber
    alternateOrderNumber
    salesOrderType
    shipments {
      id
      shipmentNumber
      shipmentStatus
      shippingNeeds
      carrier
      service
      estimatedWeight
      actualWeight
      estimatedLength
      estimatedWidth
      estimatedHeight
      actualLength
      actualWidth
      actualHeight
      estimatedShipDate
      estimatedDeliveryDateStart
      estimatedDeliveryDateEnd
      shippedAt
      trackingNo
      schedulingStatus
      scheduledAt
      scheduledBy
      finalApproval
      finalApprovalBy
      departingWarehouse {
        id
        name
      }
      shipmentListedItems {
        id
        cls
        quantity
        salesOrderListedItem {
          id
          cls
          sku
          name
        }
      }
      shipmentItems {
        id
        cls
        quantity
        salesOrderItem {
          id
          cls
          sku
          name
        }
      }
    }
  }
}
    `;
export const Assisted_ReviewFragmentDoc = gql`
    fragment ASSISTED_REVIEW on ReviewEntity {
  id
  comments
  createdTime
  rating
  reviewer
  rewardPoints
  status
  title
  updatedTime
  medias {
    id
    keywords
    title
    url
  }
  user {
    id
    firstName
    lastName
  }
  product {
    id
    slug
    title
    cls
    medias {
      url
    }
  }
}
    `;
export const Admin_SubscriptionFragmentDoc = gql`
    fragment ADMIN_SUBSCRIPTION on SubscriptionEntity {
  id
  deliveryDay
  phone
  billingFirstName
  billingLastName
  billingCompany
  billingLine1
  billingLine2
  billingCity
  billingState
  billingPostalCode
  billingCountry
  billingAddressValidationSource
  billingAddressType
  shippingFirstName
  shippingLastName
  shippingCompany
  shippingLine1
  shippingLine2
  shippingCity
  shippingState
  shippingPostalCode
  shippingCountry
  shippingAddressValidationSource
  shippingAddressType
  carrierPreference
  serviceType
  pauseReason
  paymentMethod {
    token
    type
    defaultMethod
    comment
    image
  }
  salesOrders {
    id
    cls
    grandTotal
    orderNumber
    status
    salesOrderItems {
      id
      name
      quantity
      sku
    }
  }
  subscriptionItems {
    id
    frequency
    quantity
    lastDeliveryDate
    nextDeliveryDate
    product {
      id
      cls
      price
      sku
      title
      medias {
        id
        mediaType
        url
      }
      shippingRuleSet {
        liveArrivalGuarantees {
          minTemp
          maxTemp
          transitTimeType
        }
        destinationRestriction {
          domesticOnly
          prohibitedStates
        }
      }
    }
  }
}
    `;
export const Admin_Blog_EntityFragmentDoc = gql`
    fragment ADMIN_BLOG_ENTITY on BlogEntity {
  id
  cls
  active
  author
  createdAt
  metaDescription
  metaKeywords
  metaTitle
  slug
  title
  blogTopics {
    id
    name
    slug
  }
  medias {
    id
    mediaType
    sequence
    url
  }
  products {
    id
    cls
    price
    quantityAvailableForSale
    sku
    title
    medias {
      id
      mediaType
      url
    }
  }
  publishedRevision {
    htmlContent
  }
}
    `;
export const Admin_Care_Sheet_EntityFragmentDoc = gql`
    fragment ADMIN_CARE_SHEET_ENTITY on CareSheetEntity {
  id
  cls
  active
  author
  createdAt
  metaDescription
  metaKeywords
  metaTitle
  slug
  title
  medias {
    id
    mediaType
    sequence
    url
  }
  products {
    id
    cls
    price
    quantityAvailableForSale
    sku
    title
    medias {
      id
      mediaType
      url
    }
  }
  publishedRevision {
    htmlContent
  }
}
    `;
export const Customer_DiscountFragmentDoc = gql`
    fragment CUSTOMER_DISCOUNT on DiscountEntity {
  id
  discountAmount
  discountType
  eligibleCartValue
  eligibleCouponCode
  eligibleUserType
  endDate
  name
  permitStacking
  slug
  startDate
  htmlContent
  teaser
}
    `;
export const Admin_DiscountFragmentDoc = gql`
    fragment ADMIN_DISCOUNT on DiscountEntity {
  id
  active
  discountAmount
  discountType
  eligibleCartValue
  eligibleCouponCode
  eligibleUserType
  endDate
  htmlContent
  name
  permitStacking
  slug
  startDate
  teaser
}
    `;
export const Admin_Discount_ListsFragmentDoc = gql`
    fragment ADMIN_DISCOUNT_LISTS on DiscountEntity {
  eligibleProducts {
    id
    price
    quantityAvailableForSale
    sku
    title
    medias {
      url
    }
  }
  explicitProducts {
    id
    sku
    title
  }
  categories {
    id
    slug
    path
    name
  }
  departments {
    id
    name
  }
}
    `;
export const Admin_Listing_EntityFragmentDoc = gql`
    fragment ADMIN_LISTING_ENTITY on ListingEntity {
  id
  title
  identifier
  comments
  weightDate
  birthDate
  sex
  quantity
  height
  length
  listingWeightUnit
  width
  price
  weight
  listingWeightUnit
  warehouse {
    id
    name
  }
  listingMedias {
    id
    mediaType
    sequence
    url
    media {
      title
    }
  }
}
    `;
export const Admin_Listed_Product_EntityFragmentDoc = gql`
    fragment ADMIN_LISTED_PRODUCT_ENTITY on ListedProductEntity {
  id
  cls
  slug
  sku
  upc
  title
  active
  visible
  featured
  listedProductType
  live
  metaDescription
  metaKeywords
  metaTitle
  quantityAvailableForSale
  cost
  height
  length
  price
  weight
  width
  shippingNeeds
  prepType
  listings {
    ...ADMIN_LISTING_ENTITY
  }
  publishedRevision {
    htmlContent
  }
  warehouses {
    id
    name
  }
  zones {
    id
    name
    warehouse {
      id
      name
    }
  }
  bins {
    id
    binId
    zone {
      id
      name
      warehouse {
        id
        name
      }
    }
  }
  explicitAnimals {
    id
    slug
    name
    parent {
      id
      slug
      name
      parent {
        id
        slug
        name
      }
    }
  }
  explicitDepartment {
    id
    slug
    name
    parent {
      id
      slug
      name
    }
  }
  brand {
    id
    slug
    name
  }
  medias {
    id
    mediaType
    sequence
    url
    media {
      title
    }
  }
  explicitCategories {
    id
    slug
    path
    name
    parent {
      id
      slug
      path
      name
      parent {
        id
        slug
        path
        name
      }
    }
  }
  implicitPromotions {
    id
    name
    slug
    promotionType
    active
    startDate
    endDate
    permitStacking
    eligibleUserType
    eligibleCouponCode
    eligibleItemValue
    eligibleItemCount
    eligibleCartValue
    discountAmount
  }
  implicitDiscounts {
    id
    name
    slug
    discountType
    active
    startDate
    endDate
    permitStacking
    eligibleUserType
    eligibleCouponCode
    eligibleCartValue
    discountAmount
  }
  amazonProfile {
    id
    asin
    fbaSellerSku
    sellerSku
  }
  petcoDropshipProfile {
    id
    description
    sku
    surcharge
  }
  petcoStoreDirectProfile {
    id
    description
    sku
  }
  chewyProfile {
    id
    description
    sku
    cost
  }
  walmartProfile {
    id
    sellerSku
    wfsSellerSku
  }
  resellerProfiles {
    id
    resellerSku
    price
    retail
    reseller {
      id
      name
    }
  }
  crossSellItems {
    id
    position
    crossSellProduct {
      id
      title
      sku
      price
      quantityAvailableForSale
      cls
      medias {
        id
        mediaType
        url
      }
    }
  }
  shippingRuleSet {
    id
    slug
    name
    liveArrivalGuarantees {
      minTemp
      maxTemp
      transitTimeType
    }
    destinationRestriction {
      domesticOnly
      prohibitedStates
    }
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;
export const Admin_Media_EntityFragmentDoc = gql`
    fragment ADMIN_MEDIA_ENTITY on MediaEntity {
  id
  keywords
  title
  url
  categories {
    id
    name
    ancestors {
      id
      name
    }
  }
  productMedias {
    id
    product {
      id
      cls
      sku
      title
      medias {
        url
      }
    }
  }
}
    `;
export const Customer_PromotionFragmentDoc = gql`
    fragment CUSTOMER_PROMOTION on PromotionEntity {
  id
  discountAmount
  promotionType
  eligibleCartValue
  eligibleCouponCode
  eligibleUserType
  endDate
  name
  permitStacking
  slug
  startDate
  htmlContent
  teaser
}
    `;
export const Admin_PromotionFragmentDoc = gql`
    fragment ADMIN_PROMOTION on PromotionEntity {
  id
  active
  discountAmount
  promotionType
  eligibleCartValue
  eligibleCouponCode
  eligibleItemCount
  eligibleItemValue
  eligibleUserType
  endDate
  htmlContent
  name
  permitStacking
  slug
  startDate
  teaser
}
    `;
export const Admin_Promotion_ListsFragmentDoc = gql`
    fragment ADMIN_PROMOTION_LISTS on PromotionEntity {
  explicitProducts {
    id
    sku
    title
  }
  categories {
    id
    slug
    path
    name
  }
  departments {
    id
    name
  }
  brands {
    id
    name
  }
}
    `;
export const Admin_Simple_Product_EntityFragmentDoc = gql`
    fragment ADMIN_SIMPLE_PRODUCT_ENTITY on SimpleProductEntity {
  id
  cls
  slug
  sku
  magentoId
  upc
  title
  active
  visible
  featured
  quantityAvailableForSale
  caseQuantity
  cost
  height
  length
  live
  price
  mapPrice
  specialPrice
  wholesalePrice
  weight
  width
  metaTitle
  metaDescription
  metaKeywords
  minimumOrderQuantity
  averageRating
  countRating
  shippingNeeds
  shipsAlone
  subscription
  prepType
  discontinuationReason
  vendorRef
  countryOfOrigin
  hsCode
  publishedRevision {
    htmlContent
  }
  shippingRestrictions
  warehouses {
    id
    name
  }
  zones {
    id
    name
    warehouse {
      id
      name
    }
  }
  bins {
    id
    binId
    zone {
      id
      name
      warehouse {
        id
        name
      }
    }
  }
  explicitDepartment {
    id
    slug
    name
    parent {
      id
      slug
      name
    }
  }
  brand {
    id
    slug
    name
  }
  supplier {
    id
    slug
    name
  }
  medias {
    id
    mediaType
    sequence
    url
    media {
      title
    }
  }
  explicitAnimals {
    id
    slug
    name
    parent {
      id
      slug
      name
      parent {
        id
        slug
        name
      }
    }
  }
  explicitCategories {
    id
    slug
    path
    name
    parent {
      id
      slug
      path
      name
      parent {
        id
        slug
        path
        name
      }
    }
  }
  inventoryQuantityCaches {
    id
    warehouse {
      id
      name
      slug
    }
    quantityAvailableForSale
    quantityOnShelf
    quantityReserved
  }
  shippingRuleSet {
    id
    slug
    name
    liveArrivalGuarantees {
      minTemp
      maxTemp
      transitTimeType
    }
    destinationRestriction {
      domesticOnly
      prohibitedStates
    }
  }
  implicitPromotions {
    id
    name
    slug
    promotionType
    active
    startDate
    endDate
    permitStacking
    eligibleUserType
    eligibleCouponCode
    eligibleItemValue
    eligibleItemCount
    eligibleCartValue
    discountAmount
  }
  implicitDiscounts {
    id
    name
    slug
    discountType
    active
    startDate
    endDate
    permitStacking
    eligibleUserType
    eligibleCouponCode
    eligibleCartValue
    discountAmount
  }
  amazonProfile {
    id
    asin
    fbaSellerSku
    sellerSku
  }
  petcoDropshipProfile {
    id
    description
    sku
    surcharge
  }
  petcoStoreDirectProfile {
    id
    description
    sku
  }
  chewyProfile {
    id
    description
    sku
    cost
  }
  walmartProfile {
    id
    sellerSku
    wfsSellerSku
  }
  resellerProfiles {
    id
    resellerSku
    price
    retail
    reseller {
      id
      name
    }
  }
  dropshipperProfiles {
    id
    dropshipperSku
    cost
    enabled
    dropshipper {
      id
      name
    }
  }
  crossSellItems {
    id
    position
    crossSellProduct {
      id
      title
      sku
      price
      quantityAvailableForSale
      cls
      medias {
        id
        mediaType
        url
      }
    }
  }
  priceTiers {
    id
    price
    quantity
    userType
  }
  componentItems {
    id
    quantity
    position
    component {
      id
      cost
      title
      sku
      medias {
        url
        mediaType
      }
    }
  }
}
    `;
export const SubscriptionFragmentDoc = gql`
    fragment SUBSCRIPTION on SubscriptionEntity {
  id
  phone
  deliveryDay
  pauseReason
  billingFirstName
  billingLastName
  billingCompany
  billingLine1
  billingLine2
  billingCity
  billingState
  billingPostalCode
  billingCountry
  billingAddressValidationSource
  billingAddressType
  shippingFirstName
  shippingLastName
  shippingCompany
  shippingLine1
  shippingLine2
  shippingCity
  shippingState
  shippingPostalCode
  shippingCountry
  shippingAddressValidationSource
  shippingAddressType
  taxExempt
  paymentMethod {
    token
    type
    defaultMethod
    comment
    image
  }
  salesOrders {
    id
    cls
    grandTotal
    orderNumber
    status
    salesOrderItems {
      id
      name
      quantity
    }
  }
  subscriptionItems {
    id
    frequency
    quantity
    lastDeliveryDate
    nextDeliveryDate
    product {
      id
      cls
      title
      price
      salePrice
      slug
      medias {
        id
        mediaType
        url
      }
      shippingRuleSet {
        liveArrivalGuarantees {
          minTemp
          maxTemp
          transitTimeType
        }
        destinationRestriction {
          domesticOnly
          prohibitedStates
        }
      }
    }
  }
}
    `;
export const WarehouseFragmentDoc = gql`
    fragment WAREHOUSE on WarehouseEntity {
  id
  enabled
  latitude
  longitude
  name
  slug
  alternateSlug
  warehouseType
  line1
  line2
  city
  state
  postalCode
  country
  carriers
  workSchedule {
    timezone
    closedOnCoreHolidays
    closedOnUSPSHolidays
    openCloseTimes {
      dayOfWeek
      openTime
      closeTime
      shipsScheduled
    }
    holidayTimes {
      date
      openTime
      closeTime
      shipsScheduled
    }
  }
  queues {
    id
    enabled
    name
    slug
    priority
    template
    shippingNeeds
    singlesOnly
    combosOnly
    zone {
      id
      name
    }
  }
  zones {
    id
    name
    slug
    sequence
  }
}
    `;
export const AddressCreateDocument = gql`
    mutation addressCreate($firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  addressCreate(
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    id
    cls
    firstName
    lastName
    company
    line1
    line2
    city
    state
    postalCode
    country
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddressCreateGQL extends Apollo.Mutation<AddressCreateMutation, AddressCreateMutationVariables> {
    document = AddressCreateDocument;
    
  }
export const AddressDeleteDocument = gql`
    mutation addressDelete($id: UUID!) {
  addressDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddressDeleteGQL extends Apollo.Mutation<AddressDeleteMutation, AddressDeleteMutationVariables> {
    document = AddressDeleteDocument;
    
  }
export const AddressListDocument = gql`
    query addressList {
  addressList {
    id
    firstName
    lastName
    company
    line1
    line2
    city
    state
    postalCode
    country
    addressType
    addressValidationSource
    residential
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddressListGQL extends Apollo.Query<AddressListQuery, AddressListQueryVariables> {
    document = AddressListDocument;
    
  }
export const AddressUpdateDocument = gql`
    mutation addressUpdate($id: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  addressUpdate(
    id: $id
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    id
    cls
    firstName
    lastName
    company
    line1
    line2
    city
    state
    postalCode
    country
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddressUpdateGQL extends Apollo.Mutation<AddressUpdateMutation, AddressUpdateMutationVariables> {
    document = AddressUpdateDocument;
    
  }
export const AddressVerifyDocument = gql`
    mutation addressVerify($id: UUID!, $commit: Boolean!) {
  addressVerify(id: $id, commit: $commit) {
    id
    cls
    firstName
    lastName
    company
    line1
    line2
    city
    state
    postalCode
    country
    addressValidationSource
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddressVerifyGQL extends Apollo.Mutation<AddressVerifyMutation, AddressVerifyMutationVariables> {
    document = AddressVerifyDocument;
    
  }
export const AmazonSalesOrderRefreshDocument = gql`
    mutation amazonSalesOrderRefresh($amazonId: String!) {
  amazonSalesOrderRefresh(amazonId: $amazonId) {
    id
    cls
    orderNumber
    alternateOrderNumber
    placedTime
    email
    phone
    ipAddress
    status
    hold
    couponCode
    billingFirstName
    billingLastName
    billingCompany
    billingLine1
    billingLine2
    billingCity
    billingState
    billingPostalCode
    billingCountry
    shippingFirstName
    shippingLastName
    shippingCompany
    shippingLine1
    shippingLine2
    shippingCity
    shippingState
    shippingPostalCode
    shippingCountry
    shippingAddressValidationSource
    salesOrderType
    subTotal
    discountTotal
    taxCode
    taxTotal
    shippingTotal
    handlingTotal
    grandTotal
    salesOrderListedItems {
      id
      name
      quantity
      unitAmount
      lineAmount
      listedProduct {
        slug
        title
        medias {
          url
        }
      }
      listing {
        identifier
        title
        listingMedias {
          url
        }
      }
    }
    salesOrderItemGroups {
      id
      lineAmount
      quantity
      unitAmount
      salesOrderItems {
        sku
        name
        quantity
      }
      kitProduct {
        sku
        title
      }
    }
    salesOrderItems {
      id
      sku
      name
      quantity
      unitAmount
      discountAmount
      lineAmount
      salesOrderItemGroup {
        id
      }
    }
    shipments {
      id
      shipmentNumber
      shipmentStatus
      estimatedShipDate
      estimatedDeliveryDateStart
      estimatedDeliveryDateEnd
      estimatedWeight
      estimatedLength
      estimatedWidth
      estimatedHeight
      actualWeight
      actualLength
      actualWidth
      actualHeight
      placedAt
      printedStart
      printedEnd
      shippedAt
      carrier
      service
      departingWarehouse {
        id
        name
      }
      originWarehouse {
        id
        name
      }
      shippingNeeds
      shipmentListedItems {
        id
        quantity
        salesOrderListedItem {
          id
          sku
          name
        }
      }
      shipmentItems {
        id
        quantity
        salesOrderItem {
          id
          sku
          name
        }
      }
    }
    payments {
      id
      transactionId
      paymentStatus
      placedAt
      amount
      description
    }
    comments {
      id
      time
      name
      comment
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AmazonSalesOrderRefreshGQL extends Apollo.Mutation<AmazonSalesOrderRefreshMutation, AmazonSalesOrderRefreshMutationVariables> {
    document = AmazonSalesOrderRefreshDocument;
    
  }
export const AnimalListDocument = gql`
    query animalList {
  animalList(
    page: {page: null, pageSize: null, sort: [{field: "routePath", direction: ASC}]}
  ) {
    data {
      id
      slug
      name
      routePath
      parent {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AnimalListGQL extends Apollo.Query<AnimalListQuery, AnimalListQueryVariables> {
    document = AnimalListDocument;
    
  }
export const AssistedAddressCreateDocument = gql`
    mutation assistedAddressCreate($userId: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  assistedAddressCreate(
    userId: $userId
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    id
    cls
    firstName
    lastName
    company
    line1
    line2
    city
    state
    postalCode
    country
    residential
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedAddressCreateGQL extends Apollo.Mutation<AssistedAddressCreateMutation, AssistedAddressCreateMutationVariables> {
    document = AssistedAddressCreateDocument;
    
  }
export const AssistedAddressDeleteDocument = gql`
    mutation assistedAddressDelete($id: UUID!, $userId: UUID!) {
  assistedAddressDelete(id: $id, userId: $userId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedAddressDeleteGQL extends Apollo.Mutation<AssistedAddressDeleteMutation, AssistedAddressDeleteMutationVariables> {
    document = AssistedAddressDeleteDocument;
    
  }
export const AssistedAddressUpdateDocument = gql`
    mutation assistedAddressUpdate($id: UUID!, $userId: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  assistedAddressUpdate(
    id: $id
    userId: $userId
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    id
    cls
    firstName
    lastName
    company
    line1
    line2
    city
    state
    postalCode
    country
    residential
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedAddressUpdateGQL extends Apollo.Mutation<AssistedAddressUpdateMutation, AssistedAddressUpdateMutationVariables> {
    document = AssistedAddressUpdateDocument;
    
  }
export const AssistedCartAddCustomProductDocument = gql`
    mutation assistedCartAddCustomProduct($cartId: UUID!, $unitPrice: BigDecimal!, $quantity: Long!, $weight: BigDecimal!, $title: String!) {
  assistedCartAddCustomProduct(
    cartId: $cartId
    unitPrice: $unitPrice
    quantity: $quantity
    weight: $weight
    title: $title
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartAddCustomProductGQL extends Apollo.Mutation<AssistedCartAddCustomProductMutation, AssistedCartAddCustomProductMutationVariables> {
    document = AssistedCartAddCustomProductDocument;
    
  }
export const AssistedCartAddKitProductDocument = gql`
    mutation assistedCartAddKitProduct($cartId: UUID!, $productId: UUID!, $quantity: Long!) {
  assistedCartAddKitProduct(
    cartId: $cartId
    productId: $productId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartAddKitProductGQL extends Apollo.Mutation<AssistedCartAddKitProductMutation, AssistedCartAddKitProductMutationVariables> {
    document = AssistedCartAddKitProductDocument;
    
  }
export const AssistedCartAddProductsDocument = gql`
    mutation assistedCartAddProducts($cartId: UUID!, $simpleProductIds: [UUID], $kitProductIds: [UUID], $listingIds: [UUID]) {
  assistedCartAddProducts(
    cartId: $cartId
    simpleProductIds: $simpleProductIds
    kitProductIds: $kitProductIds
    listingIds: $listingIds
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartAddProductsGQL extends Apollo.Mutation<AssistedCartAddProductsMutation, AssistedCartAddProductsMutationVariables> {
    document = AssistedCartAddProductsDocument;
    
  }
export const AssistedCartAddSimpleProductDocument = gql`
    mutation assistedCartAddSimpleProduct($cartId: UUID!, $productId: UUID!, $quantity: Long!) {
  assistedCartAddSimpleProduct(
    cartId: $cartId
    productId: $productId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartAddSimpleProductGQL extends Apollo.Mutation<AssistedCartAddSimpleProductMutation, AssistedCartAddSimpleProductMutationVariables> {
    document = AssistedCartAddSimpleProductDocument;
    
  }
export const AssistedCartChangeQuantityKitProductDocument = gql`
    mutation assistedCartChangeQuantityKitProduct($cartId: UUID!, $productId: UUID!, $quantity: Long!) {
  assistedCartChangeQuantityKitProduct(
    cartId: $cartId
    productId: $productId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartChangeQuantityKitProductGQL extends Apollo.Mutation<AssistedCartChangeQuantityKitProductMutation, AssistedCartChangeQuantityKitProductMutationVariables> {
    document = AssistedCartChangeQuantityKitProductDocument;
    
  }
export const AssistedCartChangeQuantityListedProductDocument = gql`
    mutation assistedCartChangeQuantityListedProduct($cartId: UUID!, $listingId: UUID!, $quantity: Long!) {
  assistedCartChangeQuantityListedProduct(
    cartId: $cartId
    listingId: $listingId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartChangeQuantityListedProductGQL extends Apollo.Mutation<AssistedCartChangeQuantityListedProductMutation, AssistedCartChangeQuantityListedProductMutationVariables> {
    document = AssistedCartChangeQuantityListedProductDocument;
    
  }
export const AssistedCartChangeQuantitySimpleProductDocument = gql`
    mutation assistedCartChangeQuantitySimpleProduct($cartId: UUID!, $productId: UUID!, $quantity: Long!) {
  assistedCartChangeQuantitySimpleProduct(
    cartId: $cartId
    productId: $productId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartChangeQuantitySimpleProductGQL extends Apollo.Mutation<AssistedCartChangeQuantitySimpleProductMutation, AssistedCartChangeQuantitySimpleProductMutationVariables> {
    document = AssistedCartChangeQuantitySimpleProductDocument;
    
  }
export const AssistedCartCheckoutDocument = gql`
    mutation assistedCartCheckout($cartId: UUID!, $method: String!, $deviceData: String, $nonce: String, $useRewardPoints: Boolean, $customDiscount: BigDecimal, $skipAvs: Boolean, $giftCardCode: String) {
  assistedCartCheckout(
    cartId: $cartId
    method: $method
    deviceData: $deviceData
    nonce: $nonce
    useRewardPoints: $useRewardPoints
    customDiscount: $customDiscount
    skipAvs: $skipAvs
    giftCardCode: $giftCardCode
  ) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartCheckoutGQL extends Apollo.Mutation<AssistedCartCheckoutMutation, AssistedCartCheckoutMutationVariables> {
    document = AssistedCartCheckoutDocument;
    
  }
export const AssistedCartClearBillingAddressDocument = gql`
    mutation assistedCartClearBillingAddress($cartId: UUID!) {
  assistedCartClearBillingAddress(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartClearBillingAddressGQL extends Apollo.Mutation<AssistedCartClearBillingAddressMutation, AssistedCartClearBillingAddressMutationVariables> {
    document = AssistedCartClearBillingAddressDocument;
    
  }
export const AssistedCartClearCarrierPreferenceDocument = gql`
    mutation assistedCartClearCarrierPreference($cartId: UUID!, $simpleProductId: UUID!) {
  assistedCartClearCarrierPreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartClearCarrierPreferenceGQL extends Apollo.Mutation<AssistedCartClearCarrierPreferenceMutation, AssistedCartClearCarrierPreferenceMutationVariables> {
    document = AssistedCartClearCarrierPreferenceDocument;
    
  }
export const AssistedCartClearCouponCodeDocument = gql`
    mutation assistedCartClearCouponCode($cartId: UUID!) {
  assistedCartClearCouponCode(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartClearCouponCodeGQL extends Apollo.Mutation<AssistedCartClearCouponCodeMutation, AssistedCartClearCouponCodeMutationVariables> {
    document = AssistedCartClearCouponCodeDocument;
    
  }
export const AssistedCartClearDeliveryDatePreferenceDocument = gql`
    mutation assistedCartClearDeliveryDatePreference($cartId: UUID!, $simpleProductId: UUID!) {
  assistedCartClearDeliveryDatePreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartClearDeliveryDatePreferenceGQL extends Apollo.Mutation<AssistedCartClearDeliveryDatePreferenceMutation, AssistedCartClearDeliveryDatePreferenceMutationVariables> {
    document = AssistedCartClearDeliveryDatePreferenceDocument;
    
  }
export const AssistedCartClearServiceTypePreferenceDocument = gql`
    mutation assistedCartClearServiceTypePreference($cartId: UUID!, $simpleProductId: UUID!) {
  assistedCartClearServiceTypePreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartClearServiceTypePreferenceGQL extends Apollo.Mutation<AssistedCartClearServiceTypePreferenceMutation, AssistedCartClearServiceTypePreferenceMutationVariables> {
    document = AssistedCartClearServiceTypePreferenceDocument;
    
  }
export const AssistedCartClearShippingAddressDocument = gql`
    mutation assistedCartClearShippingAddress($cartId: UUID!) {
  assistedCartClearShippingAddress(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartClearShippingAddressGQL extends Apollo.Mutation<AssistedCartClearShippingAddressMutation, AssistedCartClearShippingAddressMutationVariables> {
    document = AssistedCartClearShippingAddressDocument;
    
  }
export const AssistedCartClearTaxExemptDocument = gql`
    mutation assistedCartClearTaxExempt($cartId: UUID!) {
  assistedCartClearTaxExempt(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartClearTaxExemptGQL extends Apollo.Mutation<AssistedCartClearTaxExemptMutation, AssistedCartClearTaxExemptMutationVariables> {
    document = AssistedCartClearTaxExemptDocument;
    
  }
export const AssistedCartGetEarliestDeliveryDateDocument = gql`
    mutation assistedCartGetEarliestDeliveryDate($cartId: UUID!, $simpleProductId: UUID!, $quantity: Long!) {
  assistedCartGetEarliestDeliveryDate(
    cartId: $cartId
    simpleProductId: $simpleProductId
    quantity: $quantity
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartGetEarliestDeliveryDateGQL extends Apollo.Mutation<AssistedCartGetEarliestDeliveryDateMutation, AssistedCartGetEarliestDeliveryDateMutationVariables> {
    document = AssistedCartGetEarliestDeliveryDateDocument;
    
  }
export const AssistedCartInfoDocument = gql`
    mutation assistedCartInfo($cartId: UUID) {
  assistedCartInfo(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartInfoGQL extends Apollo.Mutation<AssistedCartInfoMutation, AssistedCartInfoMutationVariables> {
    document = AssistedCartInfoDocument;
    
  }
export const AssistedCartLookupDocument = gql`
    mutation assistedCartLookup($userId: UUID) {
  assistedCartLookup(lookup: $userId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartLookupGQL extends Apollo.Mutation<AssistedCartLookupMutation, AssistedCartLookupMutationVariables> {
    document = AssistedCartLookupDocument;
    
  }
export const AssistedCartNewCartDocument = gql`
    mutation assistedCartNewCart($userId: UUID) {
  assistedCartNewCart(userId: $userId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartNewCartGQL extends Apollo.Mutation<AssistedCartNewCartMutation, AssistedCartNewCartMutationVariables> {
    document = AssistedCartNewCartDocument;
    
  }
export const AssistedCartRemoveCustomProductDocument = gql`
    mutation assistedCartRemoveCustomProduct($cartId: UUID!, $cartCustomItemId: UUID!) {
  assistedCartRemoveCustomProduct(
    cartId: $cartId
    cartCustomItemId: $cartCustomItemId
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartRemoveCustomProductGQL extends Apollo.Mutation<AssistedCartRemoveCustomProductMutation, AssistedCartRemoveCustomProductMutationVariables> {
    document = AssistedCartRemoveCustomProductDocument;
    
  }
export const AssistedCartSetBillingAddressDocument = gql`
    mutation assistedCartSetBillingAddress($cartId: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  assistedCartSetBillingAddress(
    cartId: $cartId
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetBillingAddressGQL extends Apollo.Mutation<AssistedCartSetBillingAddressMutation, AssistedCartSetBillingAddressMutationVariables> {
    document = AssistedCartSetBillingAddressDocument;
    
  }
export const AssistedCartSetBillingAddressByIdDocument = gql`
    mutation assistedCartSetBillingAddressById($cartId: UUID!, $addressId: UUID!) {
  assistedCartSetBillingAddressById(cartId: $cartId, addressId: $addressId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetBillingAddressByIdGQL extends Apollo.Mutation<AssistedCartSetBillingAddressByIdMutation, AssistedCartSetBillingAddressByIdMutationVariables> {
    document = AssistedCartSetBillingAddressByIdDocument;
    
  }
export const AssistedCartSetCarrierPreferenceDocument = gql`
    mutation assistedCartSetCarrierPreference($cartId: UUID!, $simpleProductId: UUID!, $carrier: Carrier!) {
  assistedCartSetCarrierPreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
    carrierPreference: $carrier
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetCarrierPreferenceGQL extends Apollo.Mutation<AssistedCartSetCarrierPreferenceMutation, AssistedCartSetCarrierPreferenceMutationVariables> {
    document = AssistedCartSetCarrierPreferenceDocument;
    
  }
export const AssistedCartSetContactDocument = gql`
    mutation assistedCartSetContact($cartId: UUID!, $email: String, $phone: String) {
  assistedCartSetContact(cartId: $cartId, email: $email, phone: $phone) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetContactGQL extends Apollo.Mutation<AssistedCartSetContactMutation, AssistedCartSetContactMutationVariables> {
    document = AssistedCartSetContactDocument;
    
  }
export const AssistedCartSetCouponCodeDocument = gql`
    mutation assistedCartSetCouponCode($cartId: UUID!, $couponCode: String!) {
  assistedCartSetCouponCode(cartId: $cartId, couponCode: $couponCode) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetCouponCodeGQL extends Apollo.Mutation<AssistedCartSetCouponCodeMutation, AssistedCartSetCouponCodeMutationVariables> {
    document = AssistedCartSetCouponCodeDocument;
    
  }
export const AssistedCartSetDeliveryDatePreferenceDocument = gql`
    mutation assistedCartSetDeliveryDatePreference($cartId: UUID!, $simpleProductId: UUID!, $deliveryDatePreference: LocalDate!) {
  assistedCartSetDeliveryDatePreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
    deliveryDatePreference: $deliveryDatePreference
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetDeliveryDatePreferenceGQL extends Apollo.Mutation<AssistedCartSetDeliveryDatePreferenceMutation, AssistedCartSetDeliveryDatePreferenceMutationVariables> {
    document = AssistedCartSetDeliveryDatePreferenceDocument;
    
  }
export const AssistedCartSetPickupWarehouseDocument = gql`
    mutation assistedCartSetPickupWarehouse($cartId: UUID!, $warehouseId: UUID!) {
  assistedCartSetPickupWarehouse(cartId: $cartId, warehouseId: $warehouseId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetPickupWarehouseGQL extends Apollo.Mutation<AssistedCartSetPickupWarehouseMutation, AssistedCartSetPickupWarehouseMutationVariables> {
    document = AssistedCartSetPickupWarehouseDocument;
    
  }
export const AssistedCartSetServiceTypePreferenceDocument = gql`
    mutation assistedCartSetServiceTypePreference($cartId: UUID!, $simpleProductId: UUID!, $serviceType: ServiceType!) {
  assistedCartSetServiceTypePreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
    serviceType: $serviceType
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetServiceTypePreferenceGQL extends Apollo.Mutation<AssistedCartSetServiceTypePreferenceMutation, AssistedCartSetServiceTypePreferenceMutationVariables> {
    document = AssistedCartSetServiceTypePreferenceDocument;
    
  }
export const AssistedCartSetShippingAddressDocument = gql`
    mutation assistedCartSetShippingAddress($cartId: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  assistedCartSetShippingAddress(
    cartId: $cartId
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetShippingAddressGQL extends Apollo.Mutation<AssistedCartSetShippingAddressMutation, AssistedCartSetShippingAddressMutationVariables> {
    document = AssistedCartSetShippingAddressDocument;
    
  }
export const AssistedCartSetShippingAddressByIdDocument = gql`
    mutation assistedCartSetShippingAddressById($cartId: UUID!, $addressId: UUID!) {
  assistedCartSetShippingAddressById(cartId: $cartId, addressId: $addressId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetShippingAddressByIdGQL extends Apollo.Mutation<AssistedCartSetShippingAddressByIdMutation, AssistedCartSetShippingAddressByIdMutationVariables> {
    document = AssistedCartSetShippingAddressByIdDocument;
    
  }
export const AssistedCartSetShippingAddressFromBillingAddressDocument = gql`
    mutation assistedCartSetShippingAddressFromBillingAddress($cartId: UUID!) {
  assistedCartSetShippingAddressFromBillingAddress(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetShippingAddressFromBillingAddressGQL extends Apollo.Mutation<AssistedCartSetShippingAddressFromBillingAddressMutation, AssistedCartSetShippingAddressFromBillingAddressMutationVariables> {
    document = AssistedCartSetShippingAddressFromBillingAddressDocument;
    
  }
export const AssistedCartSetShippingZipDocument = gql`
    mutation assistedCartSetShippingZip($cartId: UUID!, $zip: String!) {
  assistedCartSetShippingZip(cartId: $cartId, zip: $zip) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetShippingZipGQL extends Apollo.Mutation<AssistedCartSetShippingZipMutation, AssistedCartSetShippingZipMutationVariables> {
    document = AssistedCartSetShippingZipDocument;
    
  }
export const AssistedCartSetTaxExemptDocument = gql`
    mutation assistedCartSetTaxExempt($cartId: UUID!) {
  assistedCartSetTaxExempt(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetTaxExemptGQL extends Apollo.Mutation<AssistedCartSetTaxExemptMutation, AssistedCartSetTaxExemptMutationVariables> {
    document = AssistedCartSetTaxExemptDocument;
    
  }
export const AssistedCartSetTradeshowDocument = gql`
    mutation assistedCartSetTradeshow($cartId: UUID!, $tradeshowId: UUID!) {
  assistedCartSetTradeshow(cartId: $cartId, tradeshowId: $tradeshowId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSetTradeshowGQL extends Apollo.Mutation<AssistedCartSetTradeshowMutation, AssistedCartSetTradeshowMutationVariables> {
    document = AssistedCartSetTradeshowDocument;
    
  }
export const AssistedCartSummarizeDocument = gql`
    mutation assistedCartSummarize($cartId: UUID!, $useRewardPoints: Boolean, $customDiscount: BigDecimal) {
  assistedCartSummarize(
    cartId: $cartId
    useRewardPoints: $useRewardPoints
    customDiscount: $customDiscount
  ) {
    id
    billingAddressValidationSource
    billingFirstName
    billingLastName
    billingCompany
    billingLine1
    billingLine2
    billingCity
    billingState
    billingPostalCode
    billingCountry
    shippingAddressValidationSource
    shippingFirstName
    shippingLastName
    shippingCompany
    shippingLine1
    shippingLine2
    shippingCity
    shippingState
    shippingPostalCode
    shippingCountry
    shippingResidential
    subTotal
    discountTotal
    taxCode
    taxTotal
    shippingTotal
    handlingTotal
    grandTotal
    couponCode
    salesOrderDiscounts {
      id
      name
      amount
      rewardPoints
    }
    salesOrderListedItems {
      id
      name
      quantity
      unitAmount
      lineAmount
      listedProduct {
        slug
        title
        medias {
          url
        }
      }
      listing {
        identifier
        title
        listingMedias {
          url
        }
      }
    }
    salesOrderItemGroups {
      id
      quantity
      unitAmount
      discountAmount
      kitProduct {
        id
        cls
        slug
        sku
        title
        price
        medias {
          id
          mediaType
          url
        }
      }
    }
    salesOrderItems {
      id
      quantity
      unitAmount
      discountAmount
      salesOrderItemGroup {
        id
      }
      simpleProduct {
        id
        cls
        slug
        sku
        title
        price
        medias {
          id
          mediaType
          url
        }
      }
    }
    shipments {
      departingWarehouse {
        id
        name
      }
      originWarehouse {
        id
        name
      }
      shipmentListedItems {
        listedProduct {
          id
        }
        salesOrderListedItem {
          listing {
            id
          }
        }
        quantity
        liveArrivalGuaranteeState
      }
      shipmentItems {
        simpleProduct {
          id
        }
        salesOrderItem {
          name
          salesOrderItemGroup {
            id
            kitProduct {
              id
            }
          }
        }
        quantity
        liveArrivalGuaranteeState
      }
      shippingNeeds
      carrier
      service
      estimatedLength
      estimatedWidth
      estimatedHeight
      estimatedWeight
      estimatedShipDate
      estimatedDeliveryDateStart
      estimatedDeliveryDateEnd
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedCartSummarizeGQL extends Apollo.Mutation<AssistedCartSummarizeMutation, AssistedCartSummarizeMutationVariables> {
    document = AssistedCartSummarizeDocument;
    
  }
export const AssistedPaymentAccountFilterDocument = gql`
    query assistedPaymentAccountFilter($firstName: String, $lastName: String, $company: String, $email: String, $active: Boolean, $pageable: GraphQLPageableInput!) {
  assistedPaymentAccountFilter(
    firstName: {pattern: $firstName}
    lastName: {pattern: $lastName}
    company: {pattern: $company}
    email: {pattern: $email}
    active: {value: $active}
    page: $pageable
  ) {
    data {
      id
      firstName
      lastName
      company
      email
      active
      netTerms
      balance
      pastDueBalance
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedPaymentAccountFilterGQL extends Apollo.Query<AssistedPaymentAccountFilterQuery, AssistedPaymentAccountFilterQueryVariables> {
    document = AssistedPaymentAccountFilterDocument;
    
  }
export const AssistedPaymentAccountInfoDocument = gql`
    query assistedPaymentAccountInfo($id: UUID!) {
  assistedPaymentAccountInfo(id: $id) {
    id
    firstName
    lastName
    company
    email
    active
    netTerms
    balance
    pastDueBalance
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedPaymentAccountInfoGQL extends Apollo.Query<AssistedPaymentAccountInfoQuery, AssistedPaymentAccountInfoQueryVariables> {
    document = AssistedPaymentAccountInfoDocument;
    
  }
export const AssistedPaymentMethodGetClientTokenDocument = gql`
    mutation assistedPaymentMethodGetClientToken($userId: UUID!) {
  assistedPaymentMethodGetClientToken(userId: $userId) {
    clientToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedPaymentMethodGetClientTokenGQL extends Apollo.Mutation<AssistedPaymentMethodGetClientTokenMutation, AssistedPaymentMethodGetClientTokenMutationVariables> {
    document = AssistedPaymentMethodGetClientTokenDocument;
    
  }
export const AssistedPaymentMethodListDocument = gql`
    mutation assistedPaymentMethodList($id: UUID!) {
  assistedPaymentMethodList(id: $id) {
    type
    token
    comment
    image
    defaultMethod
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedPaymentMethodListGQL extends Apollo.Mutation<AssistedPaymentMethodListMutation, AssistedPaymentMethodListMutationVariables> {
    document = AssistedPaymentMethodListDocument;
    
  }
export const AssistedReviewApproveDocument = gql`
    mutation assistedReviewApprove($id: UUID!) {
  assistedReviewApprove(id: $id) {
    ...ASSISTED_REVIEW
  }
}
    ${Assisted_ReviewFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedReviewApproveGQL extends Apollo.Mutation<AssistedReviewApproveMutation, AssistedReviewApproveMutationVariables> {
    document = AssistedReviewApproveDocument;
    
  }
export const AssistedReviewCreateDocument = gql`
    mutation assistedReviewCreate($userId: UUID, $reviewer: String, $productId: UUID!, $rating: Long!, $title: String!, $comments: String!, $mediaIds: [UUID]!) {
  assistedReviewCreate(
    userId: $userId
    reviewer: $reviewer
    productId: $productId
    rating: $rating
    title: $title
    comments: $comments
    mediaIds: $mediaIds
  ) {
    ...ASSISTED_REVIEW
  }
}
    ${Assisted_ReviewFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedReviewCreateGQL extends Apollo.Mutation<AssistedReviewCreateMutation, AssistedReviewCreateMutationVariables> {
    document = AssistedReviewCreateDocument;
    
  }
export const AssistedReviewFilterDocument = gql`
    query assistedReviewFilter($status: ReviewStatus, $minRating: Long, $rating: Long, $page: GraphQLPageableInput!, $userId: UUID) {
  assistedReviewFilter(
    status: $status
    minRating: $minRating
    rating: $rating
    page: $page
    userId: $userId
  ) {
    data {
      id
      title
      status
      rating
      reviewer
      createdTime
      updatedTime
      product {
        id
        sku
        title
        medias {
          url
        }
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedReviewFilterGQL extends Apollo.Query<AssistedReviewFilterQuery, AssistedReviewFilterQueryVariables> {
    document = AssistedReviewFilterDocument;
    
  }
export const AssistedReviewInfoDocument = gql`
    query assistedReviewInfo($id: UUID!) {
  assistedReviewInfo(id: $id) {
    ...ASSISTED_REVIEW
  }
}
    ${Assisted_ReviewFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedReviewInfoGQL extends Apollo.Query<AssistedReviewInfoQuery, AssistedReviewInfoQueryVariables> {
    document = AssistedReviewInfoDocument;
    
  }
export const AssistedReviewRejectDocument = gql`
    mutation assistedReviewReject($id: UUID!) {
  assistedReviewReject(id: $id) {
    ...ASSISTED_REVIEW
  }
}
    ${Assisted_ReviewFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedReviewRejectGQL extends Apollo.Mutation<AssistedReviewRejectMutation, AssistedReviewRejectMutationVariables> {
    document = AssistedReviewRejectDocument;
    
  }
export const AssistedReviewRemoveDocument = gql`
    mutation assistedReviewRemove($id: UUID!) {
  assistedReviewRemove(id: $id) {
    ...ASSISTED_REVIEW
  }
}
    ${Assisted_ReviewFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedReviewRemoveGQL extends Apollo.Mutation<AssistedReviewRemoveMutation, AssistedReviewRemoveMutationVariables> {
    document = AssistedReviewRemoveDocument;
    
  }
export const AssistedReviewUpdateDocument = gql`
    mutation assistedReviewUpdate($reviewer: String, $id: UUID!, $rating: Long!, $title: String!, $comments: String!, $mediaIds: [UUID]!) {
  assistedReviewUpdate(
    reviewer: $reviewer
    id: $id
    rating: $rating
    title: $title
    comments: $comments
    mediaIds: $mediaIds
  ) {
    ...ASSISTED_REVIEW
  }
}
    ${Assisted_ReviewFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedReviewUpdateGQL extends Apollo.Mutation<AssistedReviewUpdateMutation, AssistedReviewUpdateMutationVariables> {
    document = AssistedReviewUpdateDocument;
    
  }
export const AssistedRewardPointsBalanceDocument = gql`
    query assistedRewardPointsBalance($userId: UUID!) {
  assistedRewardPointsBalance(userId: $userId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedRewardPointsBalanceGQL extends Apollo.Query<AssistedRewardPointsBalanceQuery, AssistedRewardPointsBalanceQueryVariables> {
    document = AssistedRewardPointsBalanceDocument;
    
  }
export const AssistedRewardPointsCreditRewardsAccountDocument = gql`
    mutation assistedRewardPointsCreditRewardsAccount($userId: UUID!, $points: Long!, $comment: String) {
  assistedRewardPointsCreditRewardsAccount(
    userId: $userId
    points: $points
    comment: $comment
  ) {
    balance
    createdAt
    id
    updatedAt
    transactions {
      amount
      createdAt
      comment
      id
      timestamp
      transactionType
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedRewardPointsCreditRewardsAccountGQL extends Apollo.Mutation<AssistedRewardPointsCreditRewardsAccountMutation, AssistedRewardPointsCreditRewardsAccountMutationVariables> {
    document = AssistedRewardPointsCreditRewardsAccountDocument;
    
  }
export const AssistedRewardPointsGetRewardsAccountDocument = gql`
    query assistedRewardPointsGetRewardsAccount($userId: UUID!) {
  assistedRewardPointsGetRewardsAccount(userId: $userId) {
    balance
    createdAt
    id
    updatedAt
    transactions {
      amount
      createdAt
      comment
      id
      timestamp
      transactionType
      updatedAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedRewardPointsGetRewardsAccountGQL extends Apollo.Query<AssistedRewardPointsGetRewardsAccountQuery, AssistedRewardPointsGetRewardsAccountQueryVariables> {
    document = AssistedRewardPointsGetRewardsAccountDocument;
    
  }
export const AssistedScheduledShipmentClearApprovalDocument = gql`
    mutation assistedScheduledShipmentClearApproval($shipmentId: UUID!) {
  assistedScheduledShipmentClearApproval(id: $shipmentId) {
    ...SCHEDULED_SHIPMENT_ENTITY
  }
}
    ${Scheduled_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedScheduledShipmentClearApprovalGQL extends Apollo.Mutation<AssistedScheduledShipmentClearApprovalMutation, AssistedScheduledShipmentClearApprovalMutationVariables> {
    document = AssistedScheduledShipmentClearApprovalDocument;
    
  }
export const AssistedScheduledShipmentDashboardDocument = gql`
    mutation assistedScheduledShipmentDashboard($warehouse: String) {
  assistedScheduledShipmentDashboard(warehouse: $warehouse) {
    warehouse
    timezone
    today
    nextOpenDate
    weatherHoldCount
    toRescheduleCount
    unscheduledCount
    scheduledInFutureCount
    needsApprovalCount
    needsPickedCount
    needsShippedCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedScheduledShipmentDashboardGQL extends Apollo.Mutation<AssistedScheduledShipmentDashboardMutation, AssistedScheduledShipmentDashboardMutationVariables> {
    document = AssistedScheduledShipmentDashboardDocument;
    
  }
export const AssistedScheduledShipmentFilterDocument = gql`
    query assistedScheduledShipmentFilter($warehouse: String, $salesOrderCls: String, $schedulingStatus: SchedulingStatusType, $shipmentNumber: String, $firstName: String, $lastName: String, $email: String, $pageable: GraphQLPageableInput!, $sort: GraphQLSortInput) {
  assistedScheduledShipmentFilter(
    warehouse: $warehouse
    salesOrderCls: {value: $salesOrderCls}
    schedulingStatus: {value: $schedulingStatus}
    shipmentNumber: {pattern: $shipmentNumber}
    firstName: {pattern: $firstName}
    lastName: {pattern: $lastName}
    email: {pattern: $email}
    page: $pageable
    sort: $sort
  ) {
    count
    page
    pageSize
    data {
      ...SCHEDULED_SHIPMENT_ENTITY
    }
  }
}
    ${Scheduled_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedScheduledShipmentFilterGQL extends Apollo.Query<AssistedScheduledShipmentFilterQuery, AssistedScheduledShipmentFilterQueryVariables> {
    document = AssistedScheduledShipmentFilterDocument;
    
  }
export const AssistedScheduledShipmentHoldForWeatherDocument = gql`
    mutation assistedScheduledShipmentHoldForWeather($date: LocalDate!, $shipmentId: UUID!) {
  assistedScheduledShipmentHoldForWeather(date: $date, id: $shipmentId) {
    ...SCHEDULED_SHIPMENT_ENTITY
  }
}
    ${Scheduled_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedScheduledShipmentHoldForWeatherGQL extends Apollo.Mutation<AssistedScheduledShipmentHoldForWeatherMutation, AssistedScheduledShipmentHoldForWeatherMutationVariables> {
    document = AssistedScheduledShipmentHoldForWeatherDocument;
    
  }
export const AssistedScheduledShipmentPossibleDeliveryDatesDocument = gql`
    mutation assistedScheduledShipmentPossibleDeliveryDates($shipmentId: UUID!) {
  assistedScheduledShipmentPossibleDeliveryDates(id: $shipmentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedScheduledShipmentPossibleDeliveryDatesGQL extends Apollo.Mutation<AssistedScheduledShipmentPossibleDeliveryDatesMutation, AssistedScheduledShipmentPossibleDeliveryDatesMutationVariables> {
    document = AssistedScheduledShipmentPossibleDeliveryDatesDocument;
    
  }
export const AssistedScheduledShipmentScheduleDocument = gql`
    mutation assistedScheduledShipmentSchedule($date: LocalDate!, $shipmentId: UUID!) {
  assistedScheduledShipmentSchedule(date: $date, id: $shipmentId) {
    ...SCHEDULED_SHIPMENT_ENTITY
  }
}
    ${Scheduled_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedScheduledShipmentScheduleGQL extends Apollo.Mutation<AssistedScheduledShipmentScheduleMutation, AssistedScheduledShipmentScheduleMutationVariables> {
    document = AssistedScheduledShipmentScheduleDocument;
    
  }
export const AssistedScheduledShipmentSetApprovalDocument = gql`
    mutation assistedScheduledShipmentSetApproval($shipmentId: UUID!) {
  assistedScheduledShipmentSetApproval(id: $shipmentId) {
    ...SCHEDULED_SHIPMENT_ENTITY
  }
}
    ${Scheduled_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedScheduledShipmentSetApprovalGQL extends Apollo.Mutation<AssistedScheduledShipmentSetApprovalMutation, AssistedScheduledShipmentSetApprovalMutationVariables> {
    document = AssistedScheduledShipmentSetApprovalDocument;
    
  }
export const AssistedSubscriptionAddItemDocument = gql`
    mutation assistedSubscriptionAddItem($subscriptionId: UUID!, $productId: UUID!, $quantity: Long!, $frequency: Long!, $userId: UUID!) {
  assistedSubscriptionAddItem(
    subscriptionId: $subscriptionId
    productId: $productId
    quantity: $quantity
    frequency: $frequency
    userId: $userId
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionAddItemGQL extends Apollo.Mutation<AssistedSubscriptionAddItemMutation, AssistedSubscriptionAddItemMutationVariables> {
    document = AssistedSubscriptionAddItemDocument;
    
  }
export const AssistedSubscriptionClearCarrierPreferenceDocument = gql`
    mutation assistedSubscriptionClearCarrierPreference($subscriptionId: UUID!, $userId: UUID!) {
  assistedSubscriptionClearCarrierPreference(
    subscriptionId: $subscriptionId
    userId: $userId
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionClearCarrierPreferenceGQL extends Apollo.Mutation<AssistedSubscriptionClearCarrierPreferenceMutation, AssistedSubscriptionClearCarrierPreferenceMutationVariables> {
    document = AssistedSubscriptionClearCarrierPreferenceDocument;
    
  }
export const AssistedSubscriptionClearServiceLevelDocument = gql`
    mutation assistedSubscriptionClearServiceLevel($subscriptionId: UUID!, $userId: UUID!) {
  assistedSubscriptionClearServiceLevel(
    subscriptionId: $subscriptionId
    userId: $userId
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionClearServiceLevelGQL extends Apollo.Mutation<AssistedSubscriptionClearServiceLevelMutation, AssistedSubscriptionClearServiceLevelMutationVariables> {
    document = AssistedSubscriptionClearServiceLevelDocument;
    
  }
export const AssistedSubscriptionCreateDocument = gql`
    mutation assistedSubscriptionCreate($productId: UUID!, $userId: UUID!, $quantity: Long!, $frequency: Long!, $deliveryDay: DayOfWeek, $paymentToken: String, $phone: String!, $billingFirstName: String!, $billingLastName: String!, $billingCompany: String, $billingLine1: String!, $billingLine2: String, $billingCity: String!, $billingState: String!, $billingPostalCode: String!, $billingCountry: String!, $shippingFirstName: String!, $shippingLastName: String!, $shippingCompany: String, $shippingLine1: String!, $shippingLine2: String, $shippingCity: String!, $shippingState: String!, $shippingPostalCode: String!, $shippingCountry: String!, $shippingResidential: Boolean) {
  assistedSubscriptionCreate(
    productId: $productId
    userId: $userId
    quantity: $quantity
    frequency: $frequency
    deliveryDay: $deliveryDay
    paymentToken: $paymentToken
    phone: $phone
    billingFirstName: $billingFirstName
    billingLastName: $billingLastName
    billingCompany: $billingCompany
    billingLine1: $billingLine1
    billingLine2: $billingLine2
    billingCity: $billingCity
    billingState: $billingState
    billingPostalCode: $billingPostalCode
    billingCountry: $billingCountry
    shippingFirstName: $shippingFirstName
    shippingLastName: $shippingLastName
    shippingCompany: $shippingCompany
    shippingLine1: $shippingLine1
    shippingLine2: $shippingLine2
    shippingCity: $shippingCity
    shippingState: $shippingState
    shippingPostalCode: $shippingPostalCode
    shippingCountry: $shippingCountry
    shippingResidential: $shippingResidential
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionCreateGQL extends Apollo.Mutation<AssistedSubscriptionCreateMutation, AssistedSubscriptionCreateMutationVariables> {
    document = AssistedSubscriptionCreateDocument;
    
  }
export const AssistedSubscriptionInfoDocument = gql`
    query assistedSubscriptionInfo($id: UUID!, $userId: UUID!) {
  assistedSubscriptionInfo(id: $id, userId: $userId) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionInfoGQL extends Apollo.Query<AssistedSubscriptionInfoQuery, AssistedSubscriptionInfoQueryVariables> {
    document = AssistedSubscriptionInfoDocument;
    
  }
export const AssistedSubscriptionListDocument = gql`
    query assistedSubscriptionList($userId: UUID!) {
  assistedSubscriptionList(userId: $userId, page: {page: null, pageSize: null}) {
    data {
      id
      deliveryDay
      phone
      subscriptionItems {
        frequency
        quantity
        lastDeliveryDate
        nextDeliveryDate
        product {
          id
          title
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionListGQL extends Apollo.Query<AssistedSubscriptionListQuery, AssistedSubscriptionListQueryVariables> {
    document = AssistedSubscriptionListDocument;
    
  }
export const AssistedSubscriptionProcessDocument = gql`
    mutation assistedSubscriptionProcess($id: UUID!) {
  assistedSubscriptionProcess(id: $id) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionProcessGQL extends Apollo.Mutation<AssistedSubscriptionProcessMutation, AssistedSubscriptionProcessMutationVariables> {
    document = AssistedSubscriptionProcessDocument;
    
  }
export const AssistedSubscriptionRemoveItemDocument = gql`
    mutation assistedSubscriptionRemoveItem($subscriptionId: UUID!, $subscriptionItemId: UUID!, $userId: UUID!) {
  assistedSubscriptionRemoveItem(
    subscriptionId: $subscriptionId
    subscriptionItemId: $subscriptionItemId
    userId: $userId
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionRemoveItemGQL extends Apollo.Mutation<AssistedSubscriptionRemoveItemMutation, AssistedSubscriptionRemoveItemMutationVariables> {
    document = AssistedSubscriptionRemoveItemDocument;
    
  }
export const AssistedSubscriptionSetBillingAddressDocument = gql`
    mutation assistedSubscriptionSetBillingAddress($subscriptionId: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  assistedSubscriptionSetBillingAddress(
    subscriptionId: $subscriptionId
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetBillingAddressGQL extends Apollo.Mutation<AssistedSubscriptionSetBillingAddressMutation, AssistedSubscriptionSetBillingAddressMutationVariables> {
    document = AssistedSubscriptionSetBillingAddressDocument;
    
  }
export const AssistedSubscriptionSetCarrierPreferenceDocument = gql`
    mutation assistedSubscriptionSetCarrierPreference($carrierPreference: Carrier!, $subscriptionId: UUID!, $userId: UUID!) {
  assistedSubscriptionSetCarrierPreference(
    carrierPreference: $carrierPreference
    subscriptionId: $subscriptionId
    userId: $userId
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetCarrierPreferenceGQL extends Apollo.Mutation<AssistedSubscriptionSetCarrierPreferenceMutation, AssistedSubscriptionSetCarrierPreferenceMutationVariables> {
    document = AssistedSubscriptionSetCarrierPreferenceDocument;
    
  }
export const AssistedSubscriptionSetDeliveryDayDocument = gql`
    mutation assistedSubscriptionSetDeliveryDay($subscriptionId: UUID!, $deliveryDay: DayOfWeek) {
  assistedSubscriptionSetDeliveryDay(
    subscriptionId: $subscriptionId
    deliveryDay: $deliveryDay
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetDeliveryDayGQL extends Apollo.Mutation<AssistedSubscriptionSetDeliveryDayMutation, AssistedSubscriptionSetDeliveryDayMutationVariables> {
    document = AssistedSubscriptionSetDeliveryDayDocument;
    
  }
export const AssistedSubscriptionSetItemFrequencyDocument = gql`
    mutation assistedSubscriptionSetItemFrequency($subscriptionId: UUID!, $subscriptionItemId: UUID!, $userId: UUID!, $frequency: Long!) {
  assistedSubscriptionSetItemFrequency(
    subscriptionId: $subscriptionId
    subscriptionItemId: $subscriptionItemId
    userId: $userId
    frequency: $frequency
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetItemFrequencyGQL extends Apollo.Mutation<AssistedSubscriptionSetItemFrequencyMutation, AssistedSubscriptionSetItemFrequencyMutationVariables> {
    document = AssistedSubscriptionSetItemFrequencyDocument;
    
  }
export const AssistedSubscriptionSetItemQuantityDocument = gql`
    mutation assistedSubscriptionSetItemQuantity($subscriptionId: UUID!, $subscriptionItemId: UUID!, $userId: UUID!, $quantity: Long!) {
  assistedSubscriptionSetItemQuantity(
    subscriptionId: $subscriptionId
    subscriptionItemId: $subscriptionItemId
    userId: $userId
    quantity: $quantity
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetItemQuantityGQL extends Apollo.Mutation<AssistedSubscriptionSetItemQuantityMutation, AssistedSubscriptionSetItemQuantityMutationVariables> {
    document = AssistedSubscriptionSetItemQuantityDocument;
    
  }
export const AssistedSubscriptionSetItemNextDeliveryDocument = gql`
    mutation assistedSubscriptionSetItemNextDelivery($subscriptionId: UUID!, $subscriptionItemId: UUID!, $userId: UUID!, $nextDeliveryDate: LocalDate!) {
  assistedSubscriptionSetItemNextDelivery(
    subscriptionId: $subscriptionId
    subscriptionItemId: $subscriptionItemId
    userId: $userId
    nextDeliveryDate: $nextDeliveryDate
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetItemNextDeliveryGQL extends Apollo.Mutation<AssistedSubscriptionSetItemNextDeliveryMutation, AssistedSubscriptionSetItemNextDeliveryMutationVariables> {
    document = AssistedSubscriptionSetItemNextDeliveryDocument;
    
  }
export const AssistedSubscriptionSetPauseReasonDocument = gql`
    mutation assistedSubscriptionSetPauseReason($subscriptionId: UUID!, $pauseReason: PauseReason) {
  assistedSubscriptionSetPauseReason(
    subscriptionId: $subscriptionId
    pauseReason: $pauseReason
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetPauseReasonGQL extends Apollo.Mutation<AssistedSubscriptionSetPauseReasonMutation, AssistedSubscriptionSetPauseReasonMutationVariables> {
    document = AssistedSubscriptionSetPauseReasonDocument;
    
  }
export const AssistedSubscriptionSetPaymentTokenDocument = gql`
    mutation assistedSubscriptionSetPaymentToken($subscriptionId: UUID!, $userId: UUID!, $paymentToken: String!) {
  assistedSubscriptionSetPaymentToken(
    subscriptionId: $subscriptionId
    userId: $userId
    paymentToken: $paymentToken
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetPaymentTokenGQL extends Apollo.Mutation<AssistedSubscriptionSetPaymentTokenMutation, AssistedSubscriptionSetPaymentTokenMutationVariables> {
    document = AssistedSubscriptionSetPaymentTokenDocument;
    
  }
export const AssistedSubscriptionSetServiceLevelDocument = gql`
    mutation assistedSubscriptionSetServiceLevel($serviceLevel: ServiceType!, $subscriptionId: UUID!, $userId: UUID!) {
  assistedSubscriptionSetServiceLevel(
    serviceLevel: $serviceLevel
    subscriptionId: $subscriptionId
    userId: $userId
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetServiceLevelGQL extends Apollo.Mutation<AssistedSubscriptionSetServiceLevelMutation, AssistedSubscriptionSetServiceLevelMutationVariables> {
    document = AssistedSubscriptionSetServiceLevelDocument;
    
  }
export const AssistedSubscriptionSetShippingAddressDocument = gql`
    mutation assistedSubscriptionSetShippingAddress($subscriptionId: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  assistedSubscriptionSetShippingAddress(
    subscriptionId: $subscriptionId
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    ...ADMIN_SUBSCRIPTION
  }
}
    ${Admin_SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AssistedSubscriptionSetShippingAddressGQL extends Apollo.Mutation<AssistedSubscriptionSetShippingAddressMutation, AssistedSubscriptionSetShippingAddressMutationVariables> {
    document = AssistedSubscriptionSetShippingAddressDocument;
    
  }
export const AuthorizationAuthCodeDocument = gql`
    mutation authorizationAuthCode($scope: AuthorizationScopeType!, $clientId: String!, $redirectUri: String!, $codeChallenge: String!, $codeChallengeMethod: String!) {
  authorizationAuthCode(
    scope: $scope
    clientId: $clientId
    redirectUri: $redirectUri
    codeChallenge: $codeChallenge
    codeChallengeMethod: $codeChallengeMethod
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthorizationAuthCodeGQL extends Apollo.Mutation<AuthorizationAuthCodeMutation, AuthorizationAuthCodeMutationVariables> {
    document = AuthorizationAuthCodeDocument;
    
  }
export const AutoprintReprintDocument = gql`
    mutation autoprintReprint($shipment: UUID!) {
  autoprintReprint(shipment: $shipment)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoprintReprintGQL extends Apollo.Mutation<AutoprintReprintMutation, AutoprintReprintMutationVariables> {
    document = AutoprintReprintDocument;
    
  }
export const BlockCreateDocument = gql`
    mutation blockCreate($htmlContent: String!, $name: String!, $slug: String!) {
  blockCreate(htmlContent: $htmlContent, name: $name, slug: $slug) {
    id
    htmlContent
    name
    slug
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockCreateGQL extends Apollo.Mutation<BlockCreateMutation, BlockCreateMutationVariables> {
    document = BlockCreateDocument;
    
  }
export const BlockDeleteDocument = gql`
    mutation blockDelete($id: UUID!) {
  blockDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockDeleteGQL extends Apollo.Mutation<BlockDeleteMutation, BlockDeleteMutationVariables> {
    document = BlockDeleteDocument;
    
  }
export const BlockFilterDocument = gql`
    query blockFilter($name: String, $sort: GraphQLSortInput, $pageable: GraphQLPageableInput!) {
  blockFilter(name: {pattern: $name}, page: $pageable, sort: $sort) {
    data {
      id
      name
      slug
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockFilterGQL extends Apollo.Query<BlockFilterQuery, BlockFilterQueryVariables> {
    document = BlockFilterDocument;
    
  }
export const BlockInfoDocument = gql`
    query blockInfo($id: UUID!) {
  blockInfo(id: $id) {
    id
    htmlContent
    name
    slug
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockInfoGQL extends Apollo.Query<BlockInfoQuery, BlockInfoQueryVariables> {
    document = BlockInfoDocument;
    
  }
export const BlockUpdateDocument = gql`
    mutation blockUpdate($htmlContent: String!, $id: UUID!, $name: String!, $slug: String!) {
  blockUpdate(htmlContent: $htmlContent, id: $id, name: $name, slug: $slug) {
    id
    htmlContent
    name
    slug
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlockUpdateGQL extends Apollo.Mutation<BlockUpdateMutation, BlockUpdateMutationVariables> {
    document = BlockUpdateDocument;
    
  }
export const BlogAddBlogTopicsDocument = gql`
    mutation blogAddBlogTopics($id: UUID!, $blogTopicIds: [UUID]!) {
  blogAddBlogTopics(id: $id, blogTopicIds: $blogTopicIds) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogAddBlogTopicsGQL extends Apollo.Mutation<BlogAddBlogTopicsMutation, BlogAddBlogTopicsMutationVariables> {
    document = BlogAddBlogTopicsDocument;
    
  }
export const BlogAddImageDocument = gql`
    mutation blogAddImage($id: UUID!, $mediaId: UUID!) {
  blogAddImage(id: $id, mediaId: $mediaId) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogAddImageGQL extends Apollo.Mutation<BlogAddImageMutation, BlogAddImageMutationVariables> {
    document = BlogAddImageDocument;
    
  }
export const BlogAddProductsDocument = gql`
    mutation blogAddProducts($id: UUID!, $productIds: [UUID]!) {
  blogAddProducts(id: $id, productIds: $productIds) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogAddProductsGQL extends Apollo.Mutation<BlogAddProductsMutation, BlogAddProductsMutationVariables> {
    document = BlogAddProductsDocument;
    
  }
export const BlogBySlugDocument = gql`
    query blogBySlug($slug: String!) {
  blogBySlug(slug: $slug) {
    id
    cls
    createdAt
    slug
    title
    author
    metaTitle
    metaDescription
    metaKeywords
    products {
      id
      cls
      sku
      slug
      title
      price
      salePrice
      countRating
      averageRating
      quantityAvailableForSale
      medias {
        url
      }
    }
    blogTopics {
      id
      cls
      slug
      name
    }
    medias {
      id
      sequence
      mediaType
      url
    }
    publishedRevision {
      htmlContent
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogBySlugGQL extends Apollo.Query<BlogBySlugQuery, BlogBySlugQueryVariables> {
    document = BlogBySlugDocument;
    
  }
export const BlogCloneDocument = gql`
    mutation blogClone($id: UUID!, $title: String!, $slug: String!) {
  blogClone(id: $id, title: $title, slug: $slug) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogCloneGQL extends Apollo.Mutation<BlogCloneMutation, BlogCloneMutationVariables> {
    document = BlogCloneDocument;
    
  }
export const BlogCreateDocument = gql`
    mutation blogCreate($title: String!, $slug: String!) {
  blogCreate(title: $title, slug: $slug) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogCreateGQL extends Apollo.Mutation<BlogCreateMutation, BlogCreateMutationVariables> {
    document = BlogCreateDocument;
    
  }
export const BlogDeleteDocument = gql`
    mutation blogDelete($id: UUID!) {
  blogDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogDeleteGQL extends Apollo.Mutation<BlogDeleteMutation, BlogDeleteMutationVariables> {
    document = BlogDeleteDocument;
    
  }
export const BlogFilterDocument = gql`
    query blogFilter($title: String, $sort: GraphQLSortInput, $pageable: GraphQLPageableInput!) {
  blogFilter(title: {pattern: $title}, page: $pageable, sort: $sort) {
    data {
      id
      title
      slug
      active
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogFilterGQL extends Apollo.Query<BlogFilterQuery, BlogFilterQueryVariables> {
    document = BlogFilterDocument;
    
  }
export const BlogInfoDocument = gql`
    query blogInfo($id: UUID!) {
  blogInfo(id: $id) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogInfoGQL extends Apollo.Query<BlogInfoQuery, BlogInfoQueryVariables> {
    document = BlogInfoDocument;
    
  }
export const BlogListDocument = gql`
    query blogList($pageable: GraphQLPageableInput!, $topic: String) {
  blogList(page: $pageable, topic: $topic) {
    count
    page
    pageSize
    data {
      id
      cls
      createdAt
      readTime
      active
      title
      author
      slug
      metaDescription
      blogTopics {
        id
        cls
        slug
        name
      }
      medias {
        id
        cls
        sequence
        mediaType
        url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogListGQL extends Apollo.Query<BlogListQuery, BlogListQueryVariables> {
    document = BlogListDocument;
    
  }
export const BlogRemoveBlogTopicDocument = gql`
    mutation blogRemoveBlogTopic($id: UUID!, $blogTopicId: UUID!) {
  blogRemoveBlogTopic(id: $id, blogTopicId: $blogTopicId) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogRemoveBlogTopicGQL extends Apollo.Mutation<BlogRemoveBlogTopicMutation, BlogRemoveBlogTopicMutationVariables> {
    document = BlogRemoveBlogTopicDocument;
    
  }
export const BlogRemoveContentMediaDocument = gql`
    mutation blogRemoveContentMedia($id: UUID!, $contentMediaId: UUID!) {
  blogRemoveContentMedia(id: $id, contentMediaId: $contentMediaId) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogRemoveContentMediaGQL extends Apollo.Mutation<BlogRemoveContentMediaMutation, BlogRemoveContentMediaMutationVariables> {
    document = BlogRemoveContentMediaDocument;
    
  }
export const BlogRemoveProductDocument = gql`
    mutation blogRemoveProduct($id: UUID!, $productId: UUID!) {
  blogRemoveProduct(id: $id, productId: $productId) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogRemoveProductGQL extends Apollo.Mutation<BlogRemoveProductMutation, BlogRemoveProductMutationVariables> {
    document = BlogRemoveProductDocument;
    
  }
export const BlogSearchDocument = gql`
    query blogSearch($query: String!, $pageable: GraphQLPageableInput, $topic: String) {
  blogSearch(query: $query, page: $pageable, topic: $topic) {
    count
    page
    pageSize
    data {
      id
      cls
      createdAt
      readTime
      active
      title
      author
      slug
      metaDescription
      blogTopics {
        id
        cls
        slug
        name
      }
      medias {
        id
        cls
        sequence
        mediaType
        url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogSearchGQL extends Apollo.Query<BlogSearchQuery, BlogSearchQueryVariables> {
    document = BlogSearchDocument;
    
  }
export const BlogSetActiveDocument = gql`
    mutation blogSetActive($id: UUID!, $active: Boolean!) {
  blogSetActive(id: $id, active: $active) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogSetActiveGQL extends Apollo.Mutation<BlogSetActiveMutation, BlogSetActiveMutationVariables> {
    document = BlogSetActiveDocument;
    
  }
export const BlogSetAuthorDocument = gql`
    mutation blogSetAuthor($id: UUID!, $author: String) {
  blogSetAuthor(id: $id, author: $author) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogSetAuthorGQL extends Apollo.Mutation<BlogSetAuthorMutation, BlogSetAuthorMutationVariables> {
    document = BlogSetAuthorDocument;
    
  }
export const BlogSetContentMediaSequenceDocument = gql`
    mutation blogSetContentMediaSequence($id: UUID!, $contentMediaId: UUID!, $sequence: Int!) {
  blogSetContentMediaSequence(
    id: $id
    contentMediaId: $contentMediaId
    sequence: $sequence
  ) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogSetContentMediaSequenceGQL extends Apollo.Mutation<BlogSetContentMediaSequenceMutation, BlogSetContentMediaSequenceMutationVariables> {
    document = BlogSetContentMediaSequenceDocument;
    
  }
export const BlogSetMetaDescriptionDocument = gql`
    mutation blogSetMetaDescription($id: UUID!, $metaDescription: String) {
  blogSetMetaDescription(id: $id, metaDescription: $metaDescription) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogSetMetaDescriptionGQL extends Apollo.Mutation<BlogSetMetaDescriptionMutation, BlogSetMetaDescriptionMutationVariables> {
    document = BlogSetMetaDescriptionDocument;
    
  }
export const BlogSetMetaKeywordsDocument = gql`
    mutation blogSetMetaKeywords($id: UUID!, $metaKeywords: String) {
  blogSetMetaKeywords(id: $id, metaKeywords: $metaKeywords) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogSetMetaKeywordsGQL extends Apollo.Mutation<BlogSetMetaKeywordsMutation, BlogSetMetaKeywordsMutationVariables> {
    document = BlogSetMetaKeywordsDocument;
    
  }
export const BlogSetMetaTitleDocument = gql`
    mutation blogSetMetaTitle($id: UUID!, $metaTitle: String) {
  blogSetMetaTitle(id: $id, metaTitle: $metaTitle) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogSetMetaTitleGQL extends Apollo.Mutation<BlogSetMetaTitleMutation, BlogSetMetaTitleMutationVariables> {
    document = BlogSetMetaTitleDocument;
    
  }
export const BlogSetPublishedRevisionDocument = gql`
    mutation blogSetPublishedRevision($id: UUID!, $htmlContent: String) {
  blogSetPublishedRevision(id: $id, htmlContent: $htmlContent) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogSetPublishedRevisionGQL extends Apollo.Mutation<BlogSetPublishedRevisionMutation, BlogSetPublishedRevisionMutationVariables> {
    document = BlogSetPublishedRevisionDocument;
    
  }
export const BlogSetTitleDocument = gql`
    mutation blogSetTitle($id: UUID!, $title: String!) {
  blogSetTitle(id: $id, title: $title) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogSetTitleGQL extends Apollo.Mutation<BlogSetTitleMutation, BlogSetTitleMutationVariables> {
    document = BlogSetTitleDocument;
    
  }
export const BlogUploadImageDocument = gql`
    mutation blogUploadImage($id: UUID!, $dataUrl: String!, $filename: String!, $keywords: String, $title: String) {
  blogUploadImage(
    id: $id
    dataUrl: $dataUrl
    filename: $filename
    keyWords: $keywords
    title: $title
  ) {
    ...ADMIN_BLOG_ENTITY
  }
}
    ${Admin_Blog_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogUploadImageGQL extends Apollo.Mutation<BlogUploadImageMutation, BlogUploadImageMutationVariables> {
    document = BlogUploadImageDocument;
    
  }
export const BlogTopicListDocument = gql`
    query blogTopicList {
  blogTopicList {
    id
    name
    slug
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BlogTopicListGQL extends Apollo.Query<BlogTopicListQuery, BlogTopicListQueryVariables> {
    document = BlogTopicListDocument;
    
  }
export const BrandCreateDocument = gql`
    mutation brandCreate($name: String!) {
  brandCreate(name: $name) {
    ...BRAND_ENTITY
  }
}
    ${Brand_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BrandCreateGQL extends Apollo.Mutation<BrandCreateMutation, BrandCreateMutationVariables> {
    document = BrandCreateDocument;
    
  }
export const BrandDeleteDocument = gql`
    mutation brandDelete($id: UUID!) {
  brandDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BrandDeleteGQL extends Apollo.Mutation<BrandDeleteMutation, BrandDeleteMutationVariables> {
    document = BrandDeleteDocument;
    
  }
export const BrandInfoDocument = gql`
    query brandInfo($id: UUID!) {
  brandInfo(id: $id) {
    ...BRAND_ENTITY
  }
}
    ${Brand_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BrandInfoGQL extends Apollo.Query<BrandInfoQuery, BrandInfoQueryVariables> {
    document = BrandInfoDocument;
    
  }
export const BrandListDocument = gql`
    query brandList {
  brandList(page: {page: null, pageSize: null}) {
    data {
      id
      slug
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BrandListGQL extends Apollo.Query<BrandListQuery, BrandListQueryVariables> {
    document = BrandListDocument;
    
  }
export const BrandSetNameDocument = gql`
    mutation brandSetName($id: UUID!, $name: String!) {
  brandSetName(id: $id, name: $name) {
    ...BRAND_ENTITY
  }
}
    ${Brand_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BrandSetNameGQL extends Apollo.Mutation<BrandSetNameMutation, BrandSetNameMutationVariables> {
    document = BrandSetNameDocument;
    
  }
export const CareSheetAddImageDocument = gql`
    mutation careSheetAddImage($id: UUID!, $mediaId: UUID!) {
  careSheetAddImage(id: $id, mediaId: $mediaId) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetAddImageGQL extends Apollo.Mutation<CareSheetAddImageMutation, CareSheetAddImageMutationVariables> {
    document = CareSheetAddImageDocument;
    
  }
export const CareSheetAddProductsDocument = gql`
    mutation careSheetAddProducts($id: UUID!, $productIds: [UUID]!) {
  careSheetAddProducts(id: $id, productIds: $productIds) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetAddProductsGQL extends Apollo.Mutation<CareSheetAddProductsMutation, CareSheetAddProductsMutationVariables> {
    document = CareSheetAddProductsDocument;
    
  }
export const CareSheetBySlugDocument = gql`
    query careSheetBySlug($slug: String!) {
  careSheetBySlug(slug: $slug) {
    id
    cls
    createdAt
    slug
    title
    author
    metaTitle
    metaDescription
    metaKeywords
    medias {
      id
      sequence
      mediaType
      url
    }
    products {
      id
      cls
      sku
      slug
      title
      price
      salePrice
      countRating
      averageRating
      quantityAvailableForSale
      medias {
        url
      }
    }
    publishedRevision {
      htmlContent
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetBySlugGQL extends Apollo.Query<CareSheetBySlugQuery, CareSheetBySlugQueryVariables> {
    document = CareSheetBySlugDocument;
    
  }
export const CareSheetCreateDocument = gql`
    mutation careSheetCreate($title: String!, $slug: String!) {
  careSheetCreate(title: $title, slug: $slug) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetCreateGQL extends Apollo.Mutation<CareSheetCreateMutation, CareSheetCreateMutationVariables> {
    document = CareSheetCreateDocument;
    
  }
export const CareSheetDeleteDocument = gql`
    mutation careSheetDelete($id: UUID!) {
  careSheetDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetDeleteGQL extends Apollo.Mutation<CareSheetDeleteMutation, CareSheetDeleteMutationVariables> {
    document = CareSheetDeleteDocument;
    
  }
export const CareSheetFilterDocument = gql`
    query careSheetFilter($title: String, $sort: GraphQLSortInput, $pageable: GraphQLPageableInput!) {
  careSheetFilter(title: {pattern: $title}, page: $pageable, sort: $sort) {
    data {
      id
      active
      title
      slug
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetFilterGQL extends Apollo.Query<CareSheetFilterQuery, CareSheetFilterQueryVariables> {
    document = CareSheetFilterDocument;
    
  }
export const CareSheetInfoDocument = gql`
    query careSheetInfo($id: UUID!) {
  careSheetInfo(id: $id) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetInfoGQL extends Apollo.Query<CareSheetInfoQuery, CareSheetInfoQueryVariables> {
    document = CareSheetInfoDocument;
    
  }
export const CareSheetRemoveContentMediaDocument = gql`
    mutation careSheetRemoveContentMedia($id: UUID!, $contentMediaId: UUID!) {
  careSheetRemoveContentMedia(id: $id, contentMediaId: $contentMediaId) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetRemoveContentMediaGQL extends Apollo.Mutation<CareSheetRemoveContentMediaMutation, CareSheetRemoveContentMediaMutationVariables> {
    document = CareSheetRemoveContentMediaDocument;
    
  }
export const CareSheetRemoveProductDocument = gql`
    mutation careSheetRemoveProduct($id: UUID!, $productId: UUID!) {
  careSheetRemoveProduct(id: $id, productId: $productId) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetRemoveProductGQL extends Apollo.Mutation<CareSheetRemoveProductMutation, CareSheetRemoveProductMutationVariables> {
    document = CareSheetRemoveProductDocument;
    
  }
export const CareSheetSetActiveDocument = gql`
    mutation careSheetSetActive($id: UUID!, $active: Boolean!) {
  careSheetSetActive(id: $id, active: $active) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetSetActiveGQL extends Apollo.Mutation<CareSheetSetActiveMutation, CareSheetSetActiveMutationVariables> {
    document = CareSheetSetActiveDocument;
    
  }
export const CareSheetSetContentMediaSequenceDocument = gql`
    mutation careSheetSetContentMediaSequence($id: UUID!, $contentMediaId: UUID!, $sequence: Int!) {
  careSheetSetContentMediaSequence(
    id: $id
    contentMediaId: $contentMediaId
    sequence: $sequence
  ) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetSetContentMediaSequenceGQL extends Apollo.Mutation<CareSheetSetContentMediaSequenceMutation, CareSheetSetContentMediaSequenceMutationVariables> {
    document = CareSheetSetContentMediaSequenceDocument;
    
  }
export const CareSheetSetMetaDescriptionDocument = gql`
    mutation careSheetSetMetaDescription($id: UUID!, $metaDescription: String) {
  careSheetSetMetaDescription(id: $id, metaDescription: $metaDescription) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetSetMetaDescriptionGQL extends Apollo.Mutation<CareSheetSetMetaDescriptionMutation, CareSheetSetMetaDescriptionMutationVariables> {
    document = CareSheetSetMetaDescriptionDocument;
    
  }
export const CareSheetSetMetaKeywordsDocument = gql`
    mutation careSheetSetMetaKeywords($id: UUID!, $metaKeywords: String) {
  careSheetSetMetaKeywords(id: $id, metaKeywords: $metaKeywords) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetSetMetaKeywordsGQL extends Apollo.Mutation<CareSheetSetMetaKeywordsMutation, CareSheetSetMetaKeywordsMutationVariables> {
    document = CareSheetSetMetaKeywordsDocument;
    
  }
export const CareSheetSetMetaTitleDocument = gql`
    mutation careSheetSetMetaTitle($id: UUID!, $metaTitle: String) {
  careSheetSetMetaTitle(id: $id, metaTitle: $metaTitle) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetSetMetaTitleGQL extends Apollo.Mutation<CareSheetSetMetaTitleMutation, CareSheetSetMetaTitleMutationVariables> {
    document = CareSheetSetMetaTitleDocument;
    
  }
export const CareSheetSetPublishedRevisionDocument = gql`
    mutation careSheetSetPublishedRevision($id: UUID!, $htmlContent: String) {
  careSheetSetPublishedRevision(id: $id, htmlContent: $htmlContent) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetSetPublishedRevisionGQL extends Apollo.Mutation<CareSheetSetPublishedRevisionMutation, CareSheetSetPublishedRevisionMutationVariables> {
    document = CareSheetSetPublishedRevisionDocument;
    
  }
export const CareSheetUploadImageDocument = gql`
    mutation careSheetUploadImage($id: UUID!, $dataUrl: String!, $filename: String!, $keywords: String, $title: String) {
  careSheetUploadImage(
    id: $id
    dataUrl: $dataUrl
    filename: $filename
    keyWords: $keywords
    title: $title
  ) {
    ...ADMIN_CARE_SHEET_ENTITY
  }
}
    ${Admin_Care_Sheet_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CareSheetUploadImageGQL extends Apollo.Mutation<CareSheetUploadImageMutation, CareSheetUploadImageMutationVariables> {
    document = CareSheetUploadImageDocument;
    
  }
export const CartAddListedProductDocument = gql`
    mutation cartAddListedProduct($cartId: UUID, $listingId: UUID!, $quantity: Long!) {
  cartAddListedProduct(
    cartId: $cartId
    listingId: $listingId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartAddListedProductGQL extends Apollo.Mutation<CartAddListedProductMutation, CartAddListedProductMutationVariables> {
    document = CartAddListedProductDocument;
    
  }
export const CartAddPartialKitProductDocument = gql`
    mutation cartAddPartialKitProduct($cartId: UUID, $productId: UUID!) {
  cartAddPartialKitProduct(cartId: $cartId, productId: $productId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartAddPartialKitProductGQL extends Apollo.Mutation<CartAddPartialKitProductMutation, CartAddPartialKitProductMutationVariables> {
    document = CartAddPartialKitProductDocument;
    
  }
export const CartAuthorizeUrlDocument = gql`
    mutation cartAuthorizeUrl($cartId: UUID, $useRewardPoints: Boolean, $giftCardCode: String, $giftCardPin: String, $subscribeToNewsletter: Boolean) {
  cartAuthorizeUrl(
    cartId: $cartId
    useRewardPoints: $useRewardPoints
    giftCardCode: $giftCardCode
    giftCardPin: $giftCardPin
    subscribeToNewsletter: $subscribeToNewsletter
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartAuthorizeUrlGQL extends Apollo.Mutation<CartAuthorizeUrlMutation, CartAuthorizeUrlMutationVariables> {
    document = CartAuthorizeUrlDocument;
    
  }
export const CartChangeQuantityListedProductDocument = gql`
    mutation cartChangeQuantityListedProduct($cartId: UUID, $listingId: UUID!, $quantity: Long!) {
  cartChangeQuantityListedProduct(
    cartId: $cartId
    listingId: $listingId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartChangeQuantityListedProductGQL extends Apollo.Mutation<CartChangeQuantityListedProductMutation, CartChangeQuantityListedProductMutationVariables> {
    document = CartChangeQuantityListedProductDocument;
    
  }
export const CartNewCartDocument = gql`
    mutation cartNewCart {
  cartNewCart {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartNewCartGQL extends Apollo.Mutation<CartNewCartMutation, CartNewCartMutationVariables> {
    document = CartNewCartDocument;
    
  }
export const CartRemoveAllListedProductDocument = gql`
    mutation cartRemoveAllListedProduct($cartId: UUID, $listingId: UUID!) {
  cartRemoveAllListedProduct(cartId: $cartId, listingId: $listingId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartRemoveAllListedProductGQL extends Apollo.Mutation<CartRemoveAllListedProductMutation, CartRemoveAllListedProductMutationVariables> {
    document = CartRemoveAllListedProductDocument;
    
  }
export const CartReorderDocument = gql`
    mutation cartReorder($orderId: UUID!, $cartId: UUID) {
  cartReorder(orderId: $orderId, cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartReorderGQL extends Apollo.Mutation<CartReorderMutation, CartReorderMutationVariables> {
    document = CartReorderDocument;
    
  }
export const CartSetAffiliateDocument = gql`
    mutation cartSetAffiliate($cartId: UUID, $affiliateSlug: String!) {
  cartSetAffiliate(cartId: $cartId, affiliateSlug: $affiliateSlug) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetAffiliateGQL extends Apollo.Mutation<CartSetAffiliateMutation, CartSetAffiliateMutationVariables> {
    document = CartSetAffiliateDocument;
    
  }
export const CartSetShippingAddressFromBillingAddressDocument = gql`
    mutation cartSetShippingAddressFromBillingAddress($cartId: UUID) {
  cartSetShippingAddressFromBillingAddress(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetShippingAddressFromBillingAddressGQL extends Apollo.Mutation<CartSetShippingAddressFromBillingAddressMutation, CartSetShippingAddressFromBillingAddressMutationVariables> {
    document = CartSetShippingAddressFromBillingAddressDocument;
    
  }
export const CartAddKitProductDocument = gql`
    mutation cartAddKitProduct($cartId: UUID, $productId: UUID!, $quantity: Long!) {
  cartAddKitProduct(cartId: $cartId, productId: $productId, quantity: $quantity) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartAddKitProductGQL extends Apollo.Mutation<CartAddKitProductMutation, CartAddKitProductMutationVariables> {
    document = CartAddKitProductDocument;
    
  }
export const CartAddSimpleProductDocument = gql`
    mutation cartAddSimpleProduct($cartId: UUID, $productId: UUID!, $quantity: Long!) {
  cartAddSimpleProduct(
    cartId: $cartId
    productId: $productId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartAddSimpleProductGQL extends Apollo.Mutation<CartAddSimpleProductMutation, CartAddSimpleProductMutationVariables> {
    document = CartAddSimpleProductDocument;
    
  }
export const CartChangeQuantityKitProductDocument = gql`
    mutation cartChangeQuantityKitProduct($cartId: UUID, $productId: UUID!, $quantity: Long!) {
  cartChangeQuantityKitProduct(
    cartId: $cartId
    productId: $productId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartChangeQuantityKitProductGQL extends Apollo.Mutation<CartChangeQuantityKitProductMutation, CartChangeQuantityKitProductMutationVariables> {
    document = CartChangeQuantityKitProductDocument;
    
  }
export const CartChangeQuantitySimpleProductDocument = gql`
    mutation cartChangeQuantitySimpleProduct($cartId: UUID, $productId: UUID!, $quantity: Long!) {
  cartChangeQuantitySimpleProduct(
    cartId: $cartId
    productId: $productId
    quantity: $quantity
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartChangeQuantitySimpleProductGQL extends Apollo.Mutation<CartChangeQuantitySimpleProductMutation, CartChangeQuantitySimpleProductMutationVariables> {
    document = CartChangeQuantitySimpleProductDocument;
    
  }
export const CartCheckoutDocument = gql`
    mutation cartCheckout($cartId: UUID!, $method: String!, $deviceData: String, $nonce: String, $useRewardPoints: Boolean, $giftCardCode: String, $giftCardPin: String, $gaClientId: String, $subscribeToNewsletter: Boolean) {
  cartCheckout(
    cartId: $cartId
    method: $method
    deviceData: $deviceData
    nonce: $nonce
    useRewardPoints: $useRewardPoints
    giftCardCode: $giftCardCode
    giftCardPin: $giftCardPin
    gaClientId: $gaClientId
    subscribeToNewsletter: $subscribeToNewsletter
  ) {
    ...CUSTOMER_SALES_ORDER
    couponCode
    salesOrderItems {
      discountAmount
      simpleProduct {
        sku
      }
    }
  }
}
    ${Customer_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartCheckoutGQL extends Apollo.Mutation<CartCheckoutMutation, CartCheckoutMutationVariables> {
    document = CartCheckoutDocument;
    
  }
export const CartClearBillingAddressDocument = gql`
    mutation cartClearBillingAddress($cartId: UUID) {
  cartClearBillingAddress(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearBillingAddressGQL extends Apollo.Mutation<CartClearBillingAddressMutation, CartClearBillingAddressMutationVariables> {
    document = CartClearBillingAddressDocument;
    
  }
export const CartClearCarrierPreferenceDocument = gql`
    mutation cartClearCarrierPreference($cartId: UUID, $simpleProductId: UUID!) {
  cartClearCarrierPreference(cartId: $cartId, simpleProductId: $simpleProductId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearCarrierPreferenceGQL extends Apollo.Mutation<CartClearCarrierPreferenceMutation, CartClearCarrierPreferenceMutationVariables> {
    document = CartClearCarrierPreferenceDocument;
    
  }
export const CartClearCouponCodeDocument = gql`
    mutation cartClearCouponCode($cartId: UUID) {
  cartClearCouponCode(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearCouponCodeGQL extends Apollo.Mutation<CartClearCouponCodeMutation, CartClearCouponCodeMutationVariables> {
    document = CartClearCouponCodeDocument;
    
  }
export const CartClearDeliveryDatePreferenceDocument = gql`
    mutation cartClearDeliveryDatePreference($cartId: UUID, $simpleProductId: UUID!) {
  cartClearDeliveryDatePreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearDeliveryDatePreferenceGQL extends Apollo.Mutation<CartClearDeliveryDatePreferenceMutation, CartClearDeliveryDatePreferenceMutationVariables> {
    document = CartClearDeliveryDatePreferenceDocument;
    
  }
export const CartClearEmailDocument = gql`
    mutation cartClearEmail($cartId: UUID) {
  cartClearEmail(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearEmailGQL extends Apollo.Mutation<CartClearEmailMutation, CartClearEmailMutationVariables> {
    document = CartClearEmailDocument;
    
  }
export const CartClearPhoneDocument = gql`
    mutation cartClearPhone($cartId: UUID) {
  cartClearPhone(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearPhoneGQL extends Apollo.Mutation<CartClearPhoneMutation, CartClearPhoneMutationVariables> {
    document = CartClearPhoneDocument;
    
  }
export const CartClearPickupWarehouseDocument = gql`
    mutation cartClearPickupWarehouse($cartId: UUID) {
  cartClearPickupWarehouse(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearPickupWarehouseGQL extends Apollo.Mutation<CartClearPickupWarehouseMutation, CartClearPickupWarehouseMutationVariables> {
    document = CartClearPickupWarehouseDocument;
    
  }
export const CartClearShippingAddressDocument = gql`
    mutation cartClearShippingAddress($cartId: UUID) {
  cartClearShippingAddress(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearShippingAddressGQL extends Apollo.Mutation<CartClearShippingAddressMutation, CartClearShippingAddressMutationVariables> {
    document = CartClearShippingAddressDocument;
    
  }
export const CartClearTaxExemptDocument = gql`
    mutation cartClearTaxExempt($cartId: UUID!) {
  cartClearTaxExempt(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearTaxExemptGQL extends Apollo.Mutation<CartClearTaxExemptMutation, CartClearTaxExemptMutationVariables> {
    document = CartClearTaxExemptDocument;
    
  }
export const CartClearTradeshowDocument = gql`
    mutation cartClearTradeshow($cartId: UUID) {
  cartClearTradeshow(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartClearTradeshowGQL extends Apollo.Mutation<CartClearTradeshowMutation, CartClearTradeshowMutationVariables> {
    document = CartClearTradeshowDocument;
    
  }
export const CartCrossSellsDocument = gql`
    mutation cartCrossSells($cartId: UUID!) {
  cartCrossSells(cartId: $cartId) {
    id
    cls
    active
    title
    sku
    slug
    countRating
    averageRating
    quantityAvailableForSale
    price
    salePrice
    variationKey
    featured
    medias {
      id
      mediaType
      url
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartCrossSellsGQL extends Apollo.Mutation<CartCrossSellsMutation, CartCrossSellsMutationVariables> {
    document = CartCrossSellsDocument;
    
  }
export const CartGetEarliestDeliveryDateDocument = gql`
    mutation cartGetEarliestDeliveryDate($cartId: UUID, $simpleProductId: UUID!, $quantity: Long!) {
  cartGetEarliestDeliveryDate(
    cartId: $cartId
    simpleProductId: $simpleProductId
    quantity: $quantity
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartGetEarliestDeliveryDateGQL extends Apollo.Mutation<CartGetEarliestDeliveryDateMutation, CartGetEarliestDeliveryDateMutationVariables> {
    document = CartGetEarliestDeliveryDateDocument;
    
  }
export const CartInfoDocument = gql`
    mutation cartInfo($cartId: UUID) {
  cartInfo(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartInfoGQL extends Apollo.Mutation<CartInfoMutation, CartInfoMutationVariables> {
    document = CartInfoDocument;
    
  }
export const CartRemoveAllKitProductDocument = gql`
    mutation cartRemoveAllKitProduct($cartId: UUID, $productId: UUID!) {
  cartRemoveAllKitProduct(cartId: $cartId, productId: $productId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartRemoveAllKitProductGQL extends Apollo.Mutation<CartRemoveAllKitProductMutation, CartRemoveAllKitProductMutationVariables> {
    document = CartRemoveAllKitProductDocument;
    
  }
export const CartRemoveAllSimpleProductDocument = gql`
    mutation cartRemoveAllSimpleProduct($cartId: UUID, $productId: UUID!) {
  cartRemoveAllSimpleProduct(cartId: $cartId, productId: $productId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartRemoveAllSimpleProductGQL extends Apollo.Mutation<CartRemoveAllSimpleProductMutation, CartRemoveAllSimpleProductMutationVariables> {
    document = CartRemoveAllSimpleProductDocument;
    
  }
export const CartSetAttributionTokenDocument = gql`
    mutation cartSetAttributionToken($cartId: UUID, $attributionToken: String!) {
  cartSetAttributionToken(cartId: $cartId, attributionToken: $attributionToken) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetAttributionTokenGQL extends Apollo.Mutation<CartSetAttributionTokenMutation, CartSetAttributionTokenMutationVariables> {
    document = CartSetAttributionTokenDocument;
    
  }
export const CartSetBillingAddressDocument = gql`
    mutation cartSetBillingAddress($cartId: UUID, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  cartSetBillingAddress(
    cartId: $cartId
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetBillingAddressGQL extends Apollo.Mutation<CartSetBillingAddressMutation, CartSetBillingAddressMutationVariables> {
    document = CartSetBillingAddressDocument;
    
  }
export const CartSetBillingAddressByIdDocument = gql`
    mutation cartSetBillingAddressById($cartId: UUID, $addressId: UUID!) {
  cartSetBillingAddressById(cartId: $cartId, addressId: $addressId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetBillingAddressByIdGQL extends Apollo.Mutation<CartSetBillingAddressByIdMutation, CartSetBillingAddressByIdMutationVariables> {
    document = CartSetBillingAddressByIdDocument;
    
  }
export const CartSetCarrierPreferenceDocument = gql`
    mutation cartSetCarrierPreference($cartId: UUID, $simpleProductId: UUID!, $carrier: Carrier!) {
  cartSetCarrierPreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
    carrierPreference: $carrier
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetCarrierPreferenceGQL extends Apollo.Mutation<CartSetCarrierPreferenceMutation, CartSetCarrierPreferenceMutationVariables> {
    document = CartSetCarrierPreferenceDocument;
    
  }
export const CartSetContactDocument = gql`
    mutation cartSetContact($cartId: UUID, $email: String, $phone: String) {
  cartSetContact(cartId: $cartId, email: $email, phone: $phone) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetContactGQL extends Apollo.Mutation<CartSetContactMutation, CartSetContactMutationVariables> {
    document = CartSetContactDocument;
    
  }
export const CartSetCouponCodeDocument = gql`
    mutation cartSetCouponCode($cartId: UUID, $couponCode: String!) {
  cartSetCouponCode(cartId: $cartId, couponCode: $couponCode) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetCouponCodeGQL extends Apollo.Mutation<CartSetCouponCodeMutation, CartSetCouponCodeMutationVariables> {
    document = CartSetCouponCodeDocument;
    
  }
export const CartSetDeliveryDatePreferenceDocument = gql`
    mutation cartSetDeliveryDatePreference($cartId: UUID, $simpleProductId: UUID!, $deliveryDatePreference: LocalDate!) {
  cartSetDeliveryDatePreference(
    cartId: $cartId
    simpleProductId: $simpleProductId
    deliveryDatePreference: $deliveryDatePreference
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetDeliveryDatePreferenceGQL extends Apollo.Mutation<CartSetDeliveryDatePreferenceMutation, CartSetDeliveryDatePreferenceMutationVariables> {
    document = CartSetDeliveryDatePreferenceDocument;
    
  }
export const CartSetEmailDocument = gql`
    mutation cartSetEmail($cartId: UUID, $email: String!) {
  cartSetEmail(cartId: $cartId, email: $email) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetEmailGQL extends Apollo.Mutation<CartSetEmailMutation, CartSetEmailMutationVariables> {
    document = CartSetEmailDocument;
    
  }
export const CartSetPhoneDocument = gql`
    mutation cartSetPhone($cartId: UUID, $phone: String!) {
  cartSetPhone(cartId: $cartId, phone: $phone) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetPhoneGQL extends Apollo.Mutation<CartSetPhoneMutation, CartSetPhoneMutationVariables> {
    document = CartSetPhoneDocument;
    
  }
export const CartSetPickupWarehouseDocument = gql`
    mutation cartSetPickupWarehouse($cartId: UUID, $warehouseId: UUID!) {
  cartSetPickupWarehouse(cartId: $cartId, warehouseId: $warehouseId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetPickupWarehouseGQL extends Apollo.Mutation<CartSetPickupWarehouseMutation, CartSetPickupWarehouseMutationVariables> {
    document = CartSetPickupWarehouseDocument;
    
  }
export const CartSetShippingAddressDocument = gql`
    mutation cartSetShippingAddress($cartId: UUID, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  cartSetShippingAddress(
    cartId: $cartId
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetShippingAddressGQL extends Apollo.Mutation<CartSetShippingAddressMutation, CartSetShippingAddressMutationVariables> {
    document = CartSetShippingAddressDocument;
    
  }
export const CartSetShippingAddressByIdDocument = gql`
    mutation cartSetShippingAddressById($cartId: UUID, $addressId: UUID!) {
  cartSetShippingAddressById(cartId: $cartId, addressId: $addressId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetShippingAddressByIdGQL extends Apollo.Mutation<CartSetShippingAddressByIdMutation, CartSetShippingAddressByIdMutationVariables> {
    document = CartSetShippingAddressByIdDocument;
    
  }
export const CartSetShippingZipDocument = gql`
    mutation cartSetShippingZip($cartId: UUID, $zip: String!) {
  cartSetShippingZip(cartId: $cartId, zip: $zip) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetShippingZipGQL extends Apollo.Mutation<CartSetShippingZipMutation, CartSetShippingZipMutationVariables> {
    document = CartSetShippingZipDocument;
    
  }
export const CartSetTaxExemptDocument = gql`
    mutation cartSetTaxExempt($cartId: UUID!) {
  cartSetTaxExempt(cartId: $cartId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetTaxExemptGQL extends Apollo.Mutation<CartSetTaxExemptMutation, CartSetTaxExemptMutationVariables> {
    document = CartSetTaxExemptDocument;
    
  }
export const CartSetTradeshowDocument = gql`
    mutation cartSetTradeshow($cartId: UUID, $tradeshowId: UUID!) {
  cartSetTradeshow(cartId: $cartId, tradeshowId: $tradeshowId) {
    ...CART_CHECKOUT
  }
}
    ${Cart_CheckoutFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSetTradeshowGQL extends Apollo.Mutation<CartSetTradeshowMutation, CartSetTradeshowMutationVariables> {
    document = CartSetTradeshowDocument;
    
  }
export const CartSummarizeDocument = gql`
    mutation cartSummarize($cartId: UUID, $useRewardPoints: Boolean) {
  cartSummarize(cartId: $cartId, useRewardPoints: $useRewardPoints) {
    id
    billingAddressValidationSource
    billingFirstName
    billingLastName
    billingCompany
    billingLine1
    billingLine2
    billingCity
    billingState
    billingPostalCode
    billingCountry
    shippingAddressValidationSource
    shippingFirstName
    shippingLastName
    shippingCompany
    shippingLine1
    shippingLine2
    shippingCity
    shippingState
    shippingPostalCode
    shippingCountry
    shippingResidential
    subTotal
    discountTotal
    taxCode
    taxTotal
    shippingTotal
    handlingTotal
    grandTotal
    couponCode
    salesOrderDiscounts {
      id
      name
      amount
      rewardPoints
    }
    salesOrderListedItems {
      id
      name
      quantity
      unitAmount
      lineAmount
      listedProduct {
        slug
        title
        medias {
          url
        }
      }
      listing {
        identifier
        title
        listingMedias {
          url
        }
      }
    }
    salesOrderItemGroups {
      id
      quantity
      unitAmount
      discountAmount
      kitProduct {
        id
        cls
        slug
        sku
        title
        price
        medias {
          id
          mediaType
          url
        }
      }
    }
    salesOrderItems {
      id
      quantity
      unitAmount
      discountAmount
      salesOrderItemGroup {
        id
      }
      simpleProduct {
        id
        cls
        slug
        sku
        title
        price
        medias {
          id
          mediaType
          url
        }
      }
    }
    shipments {
      shipmentListedItems {
        listedProduct {
          id
        }
        salesOrderListedItem {
          listing {
            id
          }
        }
        quantity
        liveArrivalGuaranteeState
      }
      shipmentItems {
        simpleProduct {
          id
        }
        salesOrderItem {
          salesOrderItemGroup {
            id
            kitProduct {
              id
            }
          }
        }
        quantity
        liveArrivalGuaranteeState
      }
      shippingNeeds
      carrier
      service
      estimatedShipDate
      estimatedDeliveryDateStart
      estimatedDeliveryDateEnd
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartSummarizeGQL extends Apollo.Mutation<CartSummarizeMutation, CartSummarizeMutationVariables> {
    document = CartSummarizeDocument;
    
  }
export const CategoryByPathDocument = gql`
    query categoryByPath($pageable: GraphQLPageableInput!, $path: String!) {
  categoryByPath(page: $pageable, path: $path) {
    category {
      id
      slug
      path
      name
      metaTitle
      metaDescription
      metaKeywords
      wholesale
      publishedRevision {
        htmlContent
      }
      children {
        id
        active
        slug
        path
        name
        thumbnailMedia {
          id
          url
        }
      }
      ancestors {
        name
        path
      }
    }
    products {
      data {
        id
        cls
        slug
        sku
        title
        quantityAvailableForSale
        price
        salePrice
        averageRating
        countRating
        medias {
          id
          mediaType
          url
        }
      }
      count
      page
      pageSize
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryByPathGQL extends Apollo.Query<CategoryByPathQuery, CategoryByPathQueryVariables> {
    document = CategoryByPathDocument;
    
  }
export const CategoryCreateDocument = gql`
    mutation categoryCreate($name: String!, $slug: String!, $parentId: UUID!, $magentoIds: [String]!) {
  categoryCreate(
    name: $name
    slug: $slug
    parentId: $parentId
    magentoIds: $magentoIds
  ) {
    id
    slug
    path
    name
    active
    routePath
    parent {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryCreateGQL extends Apollo.Mutation<CategoryCreateMutation, CategoryCreateMutationVariables> {
    document = CategoryCreateDocument;
    
  }
export const CategoryDeleteDocument = gql`
    mutation categoryDelete($id: UUID!) {
  categoryDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryDeleteGQL extends Apollo.Mutation<CategoryDeleteMutation, CategoryDeleteMutationVariables> {
    document = CategoryDeleteDocument;
    
  }
export const CategoryFilterDocument = gql`
    query categoryFilter($active: Boolean, $sort: [GraphQLSortInput]) {
  categoryFilter(active: $active, page: {page: null, pageSize: null, sort: $sort}) {
    data {
      id
      slug
      path
      name
      active
      routePath
      parent {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryFilterGQL extends Apollo.Query<CategoryFilterQuery, CategoryFilterQueryVariables> {
    document = CategoryFilterDocument;
    
  }
export const CategoryInfoDocument = gql`
    query categoryInfo($id: UUID!) {
  categoryInfo(id: $id) {
    id
    active
    slug
    path
    name
    metaTitle
    metaDescription
    metaKeywords
    magentoIds
    publishedRevision {
      htmlContent
    }
    thumbnailMedia {
      id
      title
      url
    }
    children {
      id
      slug
      path
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryInfoGQL extends Apollo.Query<CategoryInfoQuery, CategoryInfoQueryVariables> {
    document = CategoryInfoDocument;
    
  }
export const CategoryListDocument = gql`
    query categoryList($sort: [GraphQLSortInput]) {
  categoryList(page: {page: null, pageSize: null, sort: $sort}) {
    data {
      id
      slug
      path
      name
      active
      routePath
      parent {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryListGQL extends Apollo.Query<CategoryListQuery, CategoryListQueryVariables> {
    document = CategoryListDocument;
    
  }
export const CategorySetActiveDocument = gql`
    mutation categorySetActive($id: UUID!, $active: Boolean!) {
  categorySetActive(id: $id, active: $active) {
    id
    active
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategorySetActiveGQL extends Apollo.Mutation<CategorySetActiveMutation, CategorySetActiveMutationVariables> {
    document = CategorySetActiveDocument;
    
  }
export const CategorySetImageDocument = gql`
    mutation categorySetImage($id: UUID!, $mediaId: UUID!) {
  categorySetImage(id: $id, mediaId: $mediaId) {
    id
    thumbnailMedia {
      id
      title
      url
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategorySetImageGQL extends Apollo.Mutation<CategorySetImageMutation, CategorySetImageMutationVariables> {
    document = CategorySetImageDocument;
    
  }
export const CategorySetMetaDescriptionDocument = gql`
    mutation categorySetMetaDescription($id: UUID!, $metaDescription: String) {
  categorySetMetaDescription(id: $id, metaDescription: $metaDescription) {
    id
    metaDescription
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategorySetMetaDescriptionGQL extends Apollo.Mutation<CategorySetMetaDescriptionMutation, CategorySetMetaDescriptionMutationVariables> {
    document = CategorySetMetaDescriptionDocument;
    
  }
export const CategorySetMetaKeywordsDocument = gql`
    mutation categorySetMetaKeywords($id: UUID!, $metaKeywords: String) {
  categorySetMetaKeywords(id: $id, metaKeywords: $metaKeywords) {
    id
    metaKeywords
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategorySetMetaKeywordsGQL extends Apollo.Mutation<CategorySetMetaKeywordsMutation, CategorySetMetaKeywordsMutationVariables> {
    document = CategorySetMetaKeywordsDocument;
    
  }
export const CategorySetMetaTitleDocument = gql`
    mutation categorySetMetaTitle($id: UUID!, $metaTitle: String) {
  categorySetMetaTitle(id: $id, metaTitle: $metaTitle) {
    id
    metaTitle
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategorySetMetaTitleGQL extends Apollo.Mutation<CategorySetMetaTitleMutation, CategorySetMetaTitleMutationVariables> {
    document = CategorySetMetaTitleDocument;
    
  }
export const CategorySetNameDocument = gql`
    mutation categorySetName($id: UUID!, $name: String!) {
  categorySetName(id: $id, name: $name) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategorySetNameGQL extends Apollo.Mutation<CategorySetNameMutation, CategorySetNameMutationVariables> {
    document = CategorySetNameDocument;
    
  }
export const CategorySetPublishedRevisionDocument = gql`
    mutation categorySetPublishedRevision($id: UUID!, $htmlContent: String) {
  categorySetPublishedRevision(id: $id, htmlContent: $htmlContent) {
    id
    publishedRevision {
      htmlContent
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategorySetPublishedRevisionGQL extends Apollo.Mutation<CategorySetPublishedRevisionMutation, CategorySetPublishedRevisionMutationVariables> {
    document = CategorySetPublishedRevisionDocument;
    
  }
export const CategoryUploadImageDocument = gql`
    mutation categoryUploadImage($id: UUID!, $dataUrl: String!, $filename: String!, $keywords: String, $title: String) {
  categoryUploadImage(
    id: $id
    dataUrl: $dataUrl
    filename: $filename
    keyWords: $keywords
    title: $title
  ) {
    id
    thumbnailMedia {
      id
      title
      url
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryUploadImageGQL extends Apollo.Mutation<CategoryUploadImageMutation, CategoryUploadImageMutationVariables> {
    document = CategoryUploadImageDocument;
    
  }
export const CheckoutCreatePaymentMethodDocument = gql`
    mutation checkoutCreatePaymentMethod($deviceData: String!, $nonce: String!) {
  checkoutCreatePaymentMethod(deviceData: $deviceData, nonce: $nonce) {
    type
    token
    comment
    image
    defaultMethod
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckoutCreatePaymentMethodGQL extends Apollo.Mutation<CheckoutCreatePaymentMethodMutation, CheckoutCreatePaymentMethodMutationVariables> {
    document = CheckoutCreatePaymentMethodDocument;
    
  }
export const CheckoutDeletePaymentMethodDocument = gql`
    mutation checkoutDeletePaymentMethod($token: String!) {
  checkoutDeletePaymentMethod(token: $token)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckoutDeletePaymentMethodGQL extends Apollo.Mutation<CheckoutDeletePaymentMethodMutation, CheckoutDeletePaymentMethodMutationVariables> {
    document = CheckoutDeletePaymentMethodDocument;
    
  }
export const CheckoutGetClientTokenDocument = gql`
    mutation checkoutGetClientToken($anonymous: Boolean) {
  checkoutGetClientToken(anonymous: $anonymous) {
    clientToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckoutGetClientTokenGQL extends Apollo.Mutation<CheckoutGetClientTokenMutation, CheckoutGetClientTokenMutationVariables> {
    document = CheckoutGetClientTokenDocument;
    
  }
export const CheckoutListPaymentMethodsDocument = gql`
    mutation checkoutListPaymentMethods {
  checkoutListPaymentMethods {
    type
    token
    comment
    image
    defaultMethod
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckoutListPaymentMethodsGQL extends Apollo.Mutation<CheckoutListPaymentMethodsMutation, CheckoutListPaymentMethodsMutationVariables> {
    document = CheckoutListPaymentMethodsDocument;
    
  }
export const CheckoutSetDefaultPaymentMethodDocument = gql`
    mutation checkoutSetDefaultPaymentMethod($token: String!) {
  checkoutSetDefaultPaymentMethod(token: $token)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckoutSetDefaultPaymentMethodGQL extends Apollo.Mutation<CheckoutSetDefaultPaymentMethodMutation, CheckoutSetDefaultPaymentMethodMutationVariables> {
    document = CheckoutSetDefaultPaymentMethodDocument;
    
  }
export const ConfigurableProductAddAnimalDocument = gql`
    mutation configurableProductAddAnimal($id: UUID!, $animalId: UUID!) {
  configurableProductAddAnimal(id: $id, animalId: $animalId) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductAddAnimalGQL extends Apollo.Mutation<ConfigurableProductAddAnimalMutation, ConfigurableProductAddAnimalMutationVariables> {
    document = ConfigurableProductAddAnimalDocument;
    
  }
export const ConfigurableProductAddConfigurableItemDocument = gql`
    mutation configurableProductAddConfigurableItem($id: UUID!, $productId: UUID!) {
  configurableProductAddConfigurableItem(id: $id, productId: $productId) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductAddConfigurableItemGQL extends Apollo.Mutation<ConfigurableProductAddConfigurableItemMutation, ConfigurableProductAddConfigurableItemMutationVariables> {
    document = ConfigurableProductAddConfigurableItemDocument;
    
  }
export const ConfigurableProductAddImageDocument = gql`
    mutation configurableProductAddImage($id: UUID!, $mediaId: UUID!) {
  configurableProductAddImage(id: $id, mediaId: $mediaId) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductAddImageGQL extends Apollo.Mutation<ConfigurableProductAddImageMutation, ConfigurableProductAddImageMutationVariables> {
    document = ConfigurableProductAddImageDocument;
    
  }
export const ConfigurableProductBySlugDocument = gql`
    query configurableProductBySlug($slug: String!) {
  configurableProductBySlug(slug: $slug) {
    id
    cls
    slug
    sku
    title
    dropshipped
    quantityAvailableForSale
    price
    salePrice
    metaTitle
    metaDescription
    metaKeywords
    averageRating
    countRating
    publishedRevision {
      htmlContent
    }
    medias {
      id
      mediaType
      url
    }
    explicitCategories {
      active
      ancestors {
        active
        name
        path
      }
      id
      slug
      path
      name
    }
    variationSet {
      products {
        active
        cls
        id
        title
        slug
        price
        salePrice
        variationKey
        medias {
          id
          mediaType
          url
        }
      }
    }
    upsellProducts {
      id
      cls
      active
      title
      sku
      slug
      countRating
      averageRating
      quantityAvailableForSale
      price
      salePrice
      variationKey
      featured
      medias {
        id
        mediaType
        url
      }
    }
    configurableItems {
      simpleProduct {
        id
        cls
        sku
        slug
        title
        price
        salePrice
        featured
        subscription
        quantityAvailableForSale
        averageRating
        countRating
        medias {
          id
          mediaType
          url
        }
        shippingRuleSet {
          liveArrivalGuarantees {
            minTemp
            maxTemp
            transitTimeType
          }
          destinationRestriction {
            domesticOnly
            prohibitedStates
          }
        }
      }
    }
    careSheets {
      id
      title
      slug
      createdAt
      metaDescription
      active
      readTime
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductBySlugGQL extends Apollo.Query<ConfigurableProductBySlugQuery, ConfigurableProductBySlugQueryVariables> {
    document = ConfigurableProductBySlugDocument;
    
  }
export const ConfigurableProductCloneDocument = gql`
    mutation configurableProductClone($id: UUID!, $sku: String!, $title: String!, $slug: String, $price: BigDecimal!) {
  configurableProductClone(
    id: $id
    sku: $sku
    title: $title
    slug: $slug
    price: $price
  ) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductCloneGQL extends Apollo.Mutation<ConfigurableProductCloneMutation, ConfigurableProductCloneMutationVariables> {
    document = ConfigurableProductCloneDocument;
    
  }
export const ConfigurableProductCreateDocument = gql`
    mutation configurableProductCreate($sku: String!, $title: String!, $slug: String, $price: BigDecimal!) {
  configurableProductCreate(sku: $sku, title: $title, slug: $slug, price: $price) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductCreateGQL extends Apollo.Mutation<ConfigurableProductCreateMutation, ConfigurableProductCreateMutationVariables> {
    document = ConfigurableProductCreateDocument;
    
  }
export const ConfigurableProductFilterDocument = gql`
    query configurableProductFilter($active: Boolean, $pageable: GraphQLPageableInput!, $category: String, $sku: String, $title: String, $sort: GraphQLSortInput) {
  configurableProductFilter(
    title: {pattern: $title}
    sku: {pattern: $sku}
    active: {value: $active}
    category: $category
    page: $pageable
    sort: $sort
  ) {
    data {
      id
      slug
      sku
      upc
      title
      active
      featured
      visible
      quantityAvailableForSale
      price
      specialPrice
      wholesalePrice
      cost
      medias {
        id
        mediaType
        url
      }
      explicitCategories {
        id
        slug
        path
        name
        parent {
          id
          slug
          path
          name
          parent {
            id
            slug
            path
            name
          }
        }
      }
      explicitDepartment {
        id
        slug
        name
        parent {
          id
          slug
          name
        }
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductFilterGQL extends Apollo.Query<ConfigurableProductFilterQuery, ConfigurableProductFilterQueryVariables> {
    document = ConfigurableProductFilterDocument;
    
  }
export const ConfigurableProductInfoDocument = gql`
    query configurableProductInfo($id: UUID!) {
  configurableProductInfo(id: $id) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductInfoGQL extends Apollo.Query<ConfigurableProductInfoQuery, ConfigurableProductInfoQueryVariables> {
    document = ConfigurableProductInfoDocument;
    
  }
export const ConfigurableProductReindexDocument = gql`
    mutation configurableProductReindex($id: UUID!) {
  configurableProductReindex(id: $id) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductReindexGQL extends Apollo.Mutation<ConfigurableProductReindexMutation, ConfigurableProductReindexMutationVariables> {
    document = ConfigurableProductReindexDocument;
    
  }
export const ConfigurableProductRemoveDocument = gql`
    mutation configurableProductRemove($id: UUID!) {
  configurableProductRemove(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductRemoveGQL extends Apollo.Mutation<ConfigurableProductRemoveMutation, ConfigurableProductRemoveMutationVariables> {
    document = ConfigurableProductRemoveDocument;
    
  }
export const ConfigurableProductRemoveAnimalDocument = gql`
    mutation configurableProductRemoveAnimal($id: UUID!, $animalId: UUID!) {
  configurableProductRemoveAnimal(id: $id, animalId: $animalId) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductRemoveAnimalGQL extends Apollo.Mutation<ConfigurableProductRemoveAnimalMutation, ConfigurableProductRemoveAnimalMutationVariables> {
    document = ConfigurableProductRemoveAnimalDocument;
    
  }
export const ConfigurableProductRemoveConfigurableItemDocument = gql`
    mutation configurableProductRemoveConfigurableItem($id: UUID!, $configurableItemId: UUID!) {
  configurableProductRemoveConfigurableItem(
    id: $id
    configurableItemId: $configurableItemId
  ) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductRemoveConfigurableItemGQL extends Apollo.Mutation<ConfigurableProductRemoveConfigurableItemMutation, ConfigurableProductRemoveConfigurableItemMutationVariables> {
    document = ConfigurableProductRemoveConfigurableItemDocument;
    
  }
export const ConfigurableProductRemoveProductMediaDocument = gql`
    mutation configurableProductRemoveProductMedia($id: UUID!, $productMediaId: UUID!) {
  configurableProductRemoveProductMedia(id: $id, productMediaId: $productMediaId) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductRemoveProductMediaGQL extends Apollo.Mutation<ConfigurableProductRemoveProductMediaMutation, ConfigurableProductRemoveProductMediaMutationVariables> {
    document = ConfigurableProductRemoveProductMediaDocument;
    
  }
export const ConfigurableProductSetActiveDocument = gql`
    mutation configurableProductSetActive($id: UUID!, $active: Boolean!) {
  configurableProductSetActive(id: $id, active: $active) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetActiveGQL extends Apollo.Mutation<ConfigurableProductSetActiveMutation, ConfigurableProductSetActiveMutationVariables> {
    document = ConfigurableProductSetActiveDocument;
    
  }
export const ConfigurableProductSetConfigurableItemPositionDocument = gql`
    mutation configurableProductSetConfigurableItemPosition($id: UUID!, $configurableItemId: UUID!, $position: Int!) {
  configurableProductSetConfigurableItemPosition(
    id: $id
    configurableItemId: $configurableItemId
    position: $position
  ) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetConfigurableItemPositionGQL extends Apollo.Mutation<ConfigurableProductSetConfigurableItemPositionMutation, ConfigurableProductSetConfigurableItemPositionMutationVariables> {
    document = ConfigurableProductSetConfigurableItemPositionDocument;
    
  }
export const ConfigurableProductSetFeaturedDocument = gql`
    mutation configurableProductSetFeatured($id: UUID!, $featured: Boolean!) {
  configurableProductSetFeatured(id: $id, featured: $featured) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetFeaturedGQL extends Apollo.Mutation<ConfigurableProductSetFeaturedMutation, ConfigurableProductSetFeaturedMutationVariables> {
    document = ConfigurableProductSetFeaturedDocument;
    
  }
export const ConfigurableProductSetMetaDescriptionDocument = gql`
    mutation configurableProductSetMetaDescription($id: UUID!, $metaDescription: String) {
  configurableProductSetMetaDescription(
    id: $id
    metaDescription: $metaDescription
  ) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetMetaDescriptionGQL extends Apollo.Mutation<ConfigurableProductSetMetaDescriptionMutation, ConfigurableProductSetMetaDescriptionMutationVariables> {
    document = ConfigurableProductSetMetaDescriptionDocument;
    
  }
export const ConfigurableProductSetMetaKeywordsDocument = gql`
    mutation configurableProductSetMetaKeywords($id: UUID!, $metaKeywords: String) {
  configurableProductSetMetaKeywords(id: $id, metaKeywords: $metaKeywords) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetMetaKeywordsGQL extends Apollo.Mutation<ConfigurableProductSetMetaKeywordsMutation, ConfigurableProductSetMetaKeywordsMutationVariables> {
    document = ConfigurableProductSetMetaKeywordsDocument;
    
  }
export const ConfigurableProductSetMetaTitleDocument = gql`
    mutation configurableProductSetMetaTitle($id: UUID!, $metaTitle: String) {
  configurableProductSetMetaTitle(id: $id, metaTitle: $metaTitle) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetMetaTitleGQL extends Apollo.Mutation<ConfigurableProductSetMetaTitleMutation, ConfigurableProductSetMetaTitleMutationVariables> {
    document = ConfigurableProductSetMetaTitleDocument;
    
  }
export const ConfigurableProductSetProductMediaSequenceDocument = gql`
    mutation configurableProductSetProductMediaSequence($id: UUID!, $productMediaId: UUID!, $sequence: Int!) {
  configurableProductSetProductMediaSequence(
    id: $id
    productMediaId: $productMediaId
    sequence: $sequence
  ) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetProductMediaSequenceGQL extends Apollo.Mutation<ConfigurableProductSetProductMediaSequenceMutation, ConfigurableProductSetProductMediaSequenceMutationVariables> {
    document = ConfigurableProductSetProductMediaSequenceDocument;
    
  }
export const ConfigurableProductSetPublishedRevisionDocument = gql`
    mutation configurableProductSetPublishedRevision($id: UUID!, $htmlContent: String) {
  configurableProductSetPublishedRevision(id: $id, htmlContent: $htmlContent) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetPublishedRevisionGQL extends Apollo.Mutation<ConfigurableProductSetPublishedRevisionMutation, ConfigurableProductSetPublishedRevisionMutationVariables> {
    document = ConfigurableProductSetPublishedRevisionDocument;
    
  }
export const ConfigurableProductSetSkuDocument = gql`
    mutation configurableProductSetSku($id: UUID!, $sku: String!) {
  configurableProductSetSku(id: $id, sku: $sku) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetSkuGQL extends Apollo.Mutation<ConfigurableProductSetSkuMutation, ConfigurableProductSetSkuMutationVariables> {
    document = ConfigurableProductSetSkuDocument;
    
  }
export const ConfigurableProductSetSlugDocument = gql`
    mutation configurableProductSetSlug($id: UUID!, $slug: String!) {
  configurableProductSetSlug(id: $id, slug: $slug) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetSlugGQL extends Apollo.Mutation<ConfigurableProductSetSlugMutation, ConfigurableProductSetSlugMutationVariables> {
    document = ConfigurableProductSetSlugDocument;
    
  }
export const ConfigurableProductSetTitleDocument = gql`
    mutation configurableProductSetTitle($id: UUID!, $title: String!) {
  configurableProductSetTitle(id: $id, title: $title) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetTitleGQL extends Apollo.Mutation<ConfigurableProductSetTitleMutation, ConfigurableProductSetTitleMutationVariables> {
    document = ConfigurableProductSetTitleDocument;
    
  }
export const ConfigurableProductSetUpcDocument = gql`
    mutation configurableProductSetUpc($id: UUID!, $upc: String) {
  configurableProductSetUpc(id: $id, upc: $upc) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetUpcGQL extends Apollo.Mutation<ConfigurableProductSetUpcMutation, ConfigurableProductSetUpcMutationVariables> {
    document = ConfigurableProductSetUpcDocument;
    
  }
export const ConfigurableProductSetVisibleDocument = gql`
    mutation configurableProductSetVisible($id: UUID!, $visible: Boolean!) {
  configurableProductSetVisible(id: $id, visible: $visible) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductSetVisibleGQL extends Apollo.Mutation<ConfigurableProductSetVisibleMutation, ConfigurableProductSetVisibleMutationVariables> {
    document = ConfigurableProductSetVisibleDocument;
    
  }
export const ConfigurableProductUploadImageDocument = gql`
    mutation configurableProductUploadImage($id: UUID!, $dataUrl: String!, $filename: String!, $keywords: String, $title: String) {
  configurableProductUploadImage(
    id: $id
    dataUrl: $dataUrl
    filename: $filename
    keyWords: $keywords
    title: $title
  ) {
    ...ADMIN_CONFIGURABLE_PRODUCT_ENTITY
  }
}
    ${Admin_Configurable_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfigurableProductUploadImageGQL extends Apollo.Mutation<ConfigurableProductUploadImageMutation, ConfigurableProductUploadImageMutationVariables> {
    document = ConfigurableProductUploadImageDocument;
    
  }
export const ContentBySlugDocument = gql`
    query contentBySlug($slug: String!) {
  contentBySlug(slug: $slug) {
    id
    cls
    slug
    title
    author
    metaTitle
    metaDescription
    metaKeywords
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContentBySlugGQL extends Apollo.Query<ContentBySlugQuery, ContentBySlugQueryVariables> {
    document = ContentBySlugDocument;
    
  }
export const DepartmentListDocument = gql`
    query departmentList {
  departmentList(
    page: {page: null, pageSize: null, sort: [{field: "slug", direction: ASC}]}
  ) {
    data {
      id
      slug
      name
      routePath
      parent {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DepartmentListGQL extends Apollo.Query<DepartmentListQuery, DepartmentListQueryVariables> {
    document = DepartmentListDocument;
    
  }
export const DiscountAddCategoriesDocument = gql`
    mutation discountAddCategories($id: UUID!, $categoryIds: [UUID]!) {
  discountAddCategories(id: $id, categoryIds: $categoryIds) {
    ...ADMIN_DISCOUNT
    ...ADMIN_DISCOUNT_LISTS
  }
}
    ${Admin_DiscountFragmentDoc}
${Admin_Discount_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountAddCategoriesGQL extends Apollo.Mutation<DiscountAddCategoriesMutation, DiscountAddCategoriesMutationVariables> {
    document = DiscountAddCategoriesDocument;
    
  }
export const DiscountAddDepartmentsDocument = gql`
    mutation discountAddDepartments($id: UUID!, $departmentIds: [UUID]!) {
  discountAddDepartments(id: $id, departmentIds: $departmentIds) {
    ...ADMIN_DISCOUNT
    ...ADMIN_DISCOUNT_LISTS
  }
}
    ${Admin_DiscountFragmentDoc}
${Admin_Discount_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountAddDepartmentsGQL extends Apollo.Mutation<DiscountAddDepartmentsMutation, DiscountAddDepartmentsMutationVariables> {
    document = DiscountAddDepartmentsDocument;
    
  }
export const DiscountAddEligibleProductsDocument = gql`
    mutation discountAddEligibleProducts($id: UUID!, $eligibleProductIds: [UUID]!) {
  discountAddEligibleProducts(id: $id, eligibleProductIds: $eligibleProductIds) {
    ...ADMIN_DISCOUNT
    ...ADMIN_DISCOUNT_LISTS
  }
}
    ${Admin_DiscountFragmentDoc}
${Admin_Discount_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountAddEligibleProductsGQL extends Apollo.Mutation<DiscountAddEligibleProductsMutation, DiscountAddEligibleProductsMutationVariables> {
    document = DiscountAddEligibleProductsDocument;
    
  }
export const DiscountAddExplicitProductsDocument = gql`
    mutation discountAddExplicitProducts($id: UUID!, $explicitProductIds: [UUID]!) {
  discountAddExplicitProducts(id: $id, explicitProductIds: $explicitProductIds) {
    ...ADMIN_DISCOUNT
    ...ADMIN_DISCOUNT_LISTS
  }
}
    ${Admin_DiscountFragmentDoc}
${Admin_Discount_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountAddExplicitProductsGQL extends Apollo.Mutation<DiscountAddExplicitProductsMutation, DiscountAddExplicitProductsMutationVariables> {
    document = DiscountAddExplicitProductsDocument;
    
  }
export const DiscountBySlugDocument = gql`
    query discountBySlug($slug: String!) {
  discountBySlug(slug: $slug) {
    ...CUSTOMER_DISCOUNT
  }
}
    ${Customer_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountBySlugGQL extends Apollo.Query<DiscountBySlugQuery, DiscountBySlugQueryVariables> {
    document = DiscountBySlugDocument;
    
  }
export const DiscountCloneDocument = gql`
    mutation discountClone($id: UUID!, $name: String!, $slug: String!) {
  discountClone(id: $id, name: $name, slug: $slug) {
    ...ADMIN_DISCOUNT
    ...ADMIN_DISCOUNT_LISTS
  }
}
    ${Admin_DiscountFragmentDoc}
${Admin_Discount_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountCloneGQL extends Apollo.Mutation<DiscountCloneMutation, DiscountCloneMutationVariables> {
    document = DiscountCloneDocument;
    
  }
export const DiscountCreateDocument = gql`
    mutation discountCreate($name: String!, $slug: String!, $discountType: DiscountType!, $permitStacking: Boolean!, $startDate: Instant, $endDate: Instant, $categoryIds: [UUID], $departmentIds: [UUID], $eligibleUserType: UserType, $eligibleCouponCode: String, $eligibleCartValue: BigDecimal, $discountAmount: BigDecimal) {
  discountCreate(
    name: $name
    slug: $slug
    discountType: $discountType
    permitStacking: $permitStacking
    startDate: $startDate
    endDate: $endDate
    categoryIds: $categoryIds
    departmentIds: $departmentIds
    eligibleUserType: $eligibleUserType
    eligibleCouponCode: $eligibleCouponCode
    eligibleCartValue: $eligibleCartValue
    discountAmount: $discountAmount
  ) {
    ...ADMIN_DISCOUNT
    ...ADMIN_DISCOUNT_LISTS
  }
}
    ${Admin_DiscountFragmentDoc}
${Admin_Discount_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountCreateGQL extends Apollo.Mutation<DiscountCreateMutation, DiscountCreateMutationVariables> {
    document = DiscountCreateDocument;
    
  }
export const DiscountDeleteDocument = gql`
    mutation discountDelete($id: UUID!) {
  discountDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountDeleteGQL extends Apollo.Mutation<DiscountDeleteMutation, DiscountDeleteMutationVariables> {
    document = DiscountDeleteDocument;
    
  }
export const DiscountEvaluateImplicitProductsDocument = gql`
    mutation discountEvaluateImplicitProducts($id: UUID!) {
  discountEvaluateImplicitProducts(id: $id) {
    ...ADMIN_DISCOUNT
    ...ADMIN_DISCOUNT_LISTS
  }
}
    ${Admin_DiscountFragmentDoc}
${Admin_Discount_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountEvaluateImplicitProductsGQL extends Apollo.Mutation<DiscountEvaluateImplicitProductsMutation, DiscountEvaluateImplicitProductsMutationVariables> {
    document = DiscountEvaluateImplicitProductsDocument;
    
  }
export const DiscountFilterImplicitProductsDocument = gql`
    query discountFilterImplicitProducts($id: UUID!, $page: GraphQLPageableInput!, $sku: String, $title: String) {
  discountFilterImplicitProducts(id: $id, page: $page, sku: $sku, title: $title) {
    count
    page
    pageSize
    data {
      id
      sku
      title
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountFilterImplicitProductsGQL extends Apollo.Query<DiscountFilterImplicitProductsQuery, DiscountFilterImplicitProductsQueryVariables> {
    document = DiscountFilterImplicitProductsDocument;
    
  }
export const DiscountInfoDocument = gql`
    query discountInfo($id: UUID!) {
  discountInfo(id: $id) {
    ...ADMIN_DISCOUNT
    ...ADMIN_DISCOUNT_LISTS
  }
}
    ${Admin_DiscountFragmentDoc}
${Admin_Discount_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountInfoGQL extends Apollo.Query<DiscountInfoQuery, DiscountInfoQueryVariables> {
    document = DiscountInfoDocument;
    
  }
export const DiscountListDocument = gql`
    query discountList($pageable: GraphQLPageableInput) {
  discountList(page: $pageable) {
    count
    page
    pageSize
    data {
      id
      name
      slug
      discountType
      active
      startDate
      endDate
      permitStacking
      eligibleUserType
      eligibleCouponCode
      eligibleCartValue
      discountAmount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountListGQL extends Apollo.Query<DiscountListQuery, DiscountListQueryVariables> {
    document = DiscountListDocument;
    
  }
export const DiscountRemoveCategoriesDocument = gql`
    mutation discountRemoveCategories($id: UUID!, $categoryIds: [UUID]!) {
  discountRemoveCategories(id: $id, categoryIds: $categoryIds) {
    ...ADMIN_DISCOUNT
    ...ADMIN_DISCOUNT_LISTS
  }
}
    ${Admin_DiscountFragmentDoc}
${Admin_Discount_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountRemoveCategoriesGQL extends Apollo.Mutation<DiscountRemoveCategoriesMutation, DiscountRemoveCategoriesMutationVariables> {
    document = DiscountRemoveCategoriesDocument;
    
  }
export const DiscountRemoveDepartmentsDocument = gql`
    mutation discountRemoveDepartments($id: UUID!, $departmentIds: [UUID]!) {
  discountRemoveDepartments(id: $id, departmentIds: $departmentIds) {
    ...ADMIN_DISCOUNT
    ...ADMIN_DISCOUNT_LISTS
  }
}
    ${Admin_DiscountFragmentDoc}
${Admin_Discount_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountRemoveDepartmentsGQL extends Apollo.Mutation<DiscountRemoveDepartmentsMutation, DiscountRemoveDepartmentsMutationVariables> {
    document = DiscountRemoveDepartmentsDocument;
    
  }
export const DiscountRemoveEligibleProductsDocument = gql`
    mutation discountRemoveEligibleProducts($id: UUID!, $eligibleProductIds: [UUID]!) {
  discountRemoveEligibleProducts(id: $id, eligibleProductIds: $eligibleProductIds) {
    ...ADMIN_DISCOUNT
    ...ADMIN_DISCOUNT_LISTS
  }
}
    ${Admin_DiscountFragmentDoc}
${Admin_Discount_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountRemoveEligibleProductsGQL extends Apollo.Mutation<DiscountRemoveEligibleProductsMutation, DiscountRemoveEligibleProductsMutationVariables> {
    document = DiscountRemoveEligibleProductsDocument;
    
  }
export const DiscountRemoveExplicitProductsDocument = gql`
    mutation discountRemoveExplicitProducts($id: UUID!, $explicitProductIds: [UUID]!) {
  discountRemoveExplicitProducts(id: $id, explicitProductIds: $explicitProductIds) {
    ...ADMIN_DISCOUNT
    ...ADMIN_DISCOUNT_LISTS
  }
}
    ${Admin_DiscountFragmentDoc}
${Admin_Discount_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountRemoveExplicitProductsGQL extends Apollo.Mutation<DiscountRemoveExplicitProductsMutation, DiscountRemoveExplicitProductsMutationVariables> {
    document = DiscountRemoveExplicitProductsDocument;
    
  }
export const DiscountSetActiveDocument = gql`
    mutation discountSetActive($id: UUID!, $active: Boolean!) {
  discountSetActive(id: $id, active: $active) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountSetActiveGQL extends Apollo.Mutation<DiscountSetActiveMutation, DiscountSetActiveMutationVariables> {
    document = DiscountSetActiveDocument;
    
  }
export const DiscountSetDiscountAmountDocument = gql`
    mutation discountSetDiscountAmount($id: UUID!, $discountAmount: BigDecimal) {
  discountSetDiscountAmount(id: $id, discountAmount: $discountAmount) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountSetDiscountAmountGQL extends Apollo.Mutation<DiscountSetDiscountAmountMutation, DiscountSetDiscountAmountMutationVariables> {
    document = DiscountSetDiscountAmountDocument;
    
  }
export const DiscountSetDiscountTypeDocument = gql`
    mutation discountSetDiscountType($id: UUID!, $discountType: DiscountType!) {
  discountSetDiscountType(id: $id, discountType: $discountType) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountSetDiscountTypeGQL extends Apollo.Mutation<DiscountSetDiscountTypeMutation, DiscountSetDiscountTypeMutationVariables> {
    document = DiscountSetDiscountTypeDocument;
    
  }
export const DiscountSetEligibleCartValueDocument = gql`
    mutation discountSetEligibleCartValue($id: UUID!, $eligibleCartValue: BigDecimal) {
  discountSetEligibleCartValue(id: $id, eligibleCartValue: $eligibleCartValue) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountSetEligibleCartValueGQL extends Apollo.Mutation<DiscountSetEligibleCartValueMutation, DiscountSetEligibleCartValueMutationVariables> {
    document = DiscountSetEligibleCartValueDocument;
    
  }
export const DiscountSetEligibleCouponCodeDocument = gql`
    mutation discountSetEligibleCouponCode($id: UUID!, $eligibleCouponCode: String) {
  discountSetEligibleCouponCode(id: $id, eligibleCouponCode: $eligibleCouponCode) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountSetEligibleCouponCodeGQL extends Apollo.Mutation<DiscountSetEligibleCouponCodeMutation, DiscountSetEligibleCouponCodeMutationVariables> {
    document = DiscountSetEligibleCouponCodeDocument;
    
  }
export const DiscountSetEligibleUserTypeDocument = gql`
    mutation discountSetEligibleUserType($id: UUID!, $eligibleUserType: UserType) {
  discountSetEligibleUserType(id: $id, eligibleUserType: $eligibleUserType) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountSetEligibleUserTypeGQL extends Apollo.Mutation<DiscountSetEligibleUserTypeMutation, DiscountSetEligibleUserTypeMutationVariables> {
    document = DiscountSetEligibleUserTypeDocument;
    
  }
export const DiscountSetEndDateDocument = gql`
    mutation discountSetEndDate($id: UUID!, $endDate: Instant) {
  discountSetEndDate(id: $id, endDate: $endDate) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountSetEndDateGQL extends Apollo.Mutation<DiscountSetEndDateMutation, DiscountSetEndDateMutationVariables> {
    document = DiscountSetEndDateDocument;
    
  }
export const DiscountSetHtmlContentDocument = gql`
    mutation discountSetHtmlContent($id: UUID!, $htmlContent: String) {
  discountSetHtmlContent(id: $id, htmlContent: $htmlContent) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountSetHtmlContentGQL extends Apollo.Mutation<DiscountSetHtmlContentMutation, DiscountSetHtmlContentMutationVariables> {
    document = DiscountSetHtmlContentDocument;
    
  }
export const DiscountSetNameDocument = gql`
    mutation discountSetName($id: UUID!, $name: String!) {
  discountSetName(id: $id, name: $name) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountSetNameGQL extends Apollo.Mutation<DiscountSetNameMutation, DiscountSetNameMutationVariables> {
    document = DiscountSetNameDocument;
    
  }
export const DiscountSetPermitStackingDocument = gql`
    mutation discountSetPermitStacking($id: UUID!, $permitStacking: Boolean!) {
  discountSetPermitStacking(id: $id, permitStacking: $permitStacking) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountSetPermitStackingGQL extends Apollo.Mutation<DiscountSetPermitStackingMutation, DiscountSetPermitStackingMutationVariables> {
    document = DiscountSetPermitStackingDocument;
    
  }
export const DiscountSetSlugDocument = gql`
    mutation discountSetSlug($id: UUID!, $slug: String!) {
  discountSetSlug(id: $id, slug: $slug) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountSetSlugGQL extends Apollo.Mutation<DiscountSetSlugMutation, DiscountSetSlugMutationVariables> {
    document = DiscountSetSlugDocument;
    
  }
export const DiscountSetStartDateDocument = gql`
    mutation discountSetStartDate($id: UUID!, $startDate: Instant) {
  discountSetStartDate(id: $id, startDate: $startDate) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountSetStartDateGQL extends Apollo.Mutation<DiscountSetStartDateMutation, DiscountSetStartDateMutationVariables> {
    document = DiscountSetStartDateDocument;
    
  }
export const DiscountSetTeaserDocument = gql`
    mutation discountSetTeaser($id: UUID!, $teaser: String) {
  discountSetTeaser(id: $id, teaser: $teaser) {
    ...ADMIN_DISCOUNT
  }
}
    ${Admin_DiscountFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DiscountSetTeaserGQL extends Apollo.Mutation<DiscountSetTeaserMutation, DiscountSetTeaserMutationVariables> {
    document = DiscountSetTeaserDocument;
    
  }
export const FinancialPspCorporateRevenueJournalDocument = gql`
    query financialPspCorporateRevenueJournal($startDate: LocalDate!, $endDate: LocalDate!) {
  financialPspCorporateRevenueJournal(startDate: $startDate, endDate: $endDate) {
    startDate
    endDate
    accountsReceivable
    productSales
    shippingSales
    productRefunds
    shippingRefunds
    salesTaxExpense
    salesTaxLiability
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinancialPspCorporateRevenueJournalGQL extends Apollo.Query<FinancialPspCorporateRevenueJournalQuery, FinancialPspCorporateRevenueJournalQueryVariables> {
    document = FinancialPspCorporateRevenueJournalDocument;
    
  }
export const FinancialPspFranchiseRevenueJournalDocument = gql`
    query financialPspFranchiseRevenueJournal($startDate: LocalDate!, $endDate: LocalDate!) {
  financialPspFranchiseRevenueJournal(startDate: $startDate, endDate: $endDate) {
    startDate
    endDate
    accountsReceivable
    productSales
    shippingSales
    productRefunds
    shippingRefunds
    salesTaxExpense
    salesTaxLiability
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinancialPspFranchiseRevenueJournalGQL extends Apollo.Query<FinancialPspFranchiseRevenueJournalQuery, FinancialPspFranchiseRevenueJournalQueryVariables> {
    document = FinancialPspFranchiseRevenueJournalDocument;
    
  }
export const FinancialResellerRevenueJournalDocument = gql`
    query financialResellerRevenueJournal($startDate: LocalDate!, $endDate: LocalDate!) {
  financialResellerRevenueJournal(startDate: $startDate, endDate: $endDate) {
    startDate
    endDate
    accountsReceivable
    productSales
    shippingSales
    productRefunds
    shippingRefunds
    salesTaxExpense
    salesTaxLiability
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinancialResellerRevenueJournalGQL extends Apollo.Query<FinancialResellerRevenueJournalQuery, FinancialResellerRevenueJournalQueryVariables> {
    document = FinancialResellerRevenueJournalDocument;
    
  }
export const FinancialWebsiteRevenueJournalDocument = gql`
    query financialWebsiteRevenueJournal($startDate: LocalDate!, $endDate: LocalDate!) {
  financialWebsiteRevenueJournal(startDate: $startDate, endDate: $endDate) {
    startDate
    endDate
    accountsReceivable
    productSales
    shippingSales
    productRefunds
    shippingRefunds
    salesTaxExpense
    salesTaxLiability
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinancialWebsiteRevenueJournalGQL extends Apollo.Query<FinancialWebsiteRevenueJournalQuery, FinancialWebsiteRevenueJournalQueryVariables> {
    document = FinancialWebsiteRevenueJournalDocument;
    
  }
export const GiftCardCreateDocument = gql`
    mutation giftCardCreate($amount: BigDecimal!) {
  giftCardCreate(amount: $amount) {
    id
    createdAt
    activatedBy
    code
    balance
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GiftCardCreateGQL extends Apollo.Mutation<GiftCardCreateMutation, GiftCardCreateMutationVariables> {
    document = GiftCardCreateDocument;
    
  }
export const GiftCardFilterDocument = gql`
    query giftCardFilter($code: String, $pageable: GraphQLPageableInput!, $sort: GraphQLSortInput) {
  giftCardFilter(code: {pattern: $code}, page: $pageable, sort: $sort) {
    data {
      id
      createdAt
      activatedBy
      code
      balance
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GiftCardFilterGQL extends Apollo.Query<GiftCardFilterQuery, GiftCardFilterQueryVariables> {
    document = GiftCardFilterDocument;
    
  }
export const GiftCardInfoDocument = gql`
    query giftCardInfo($id: UUID!) {
  giftCardInfo(id: $id) {
    id
    createdAt
    activatedBy
    code
    balance
    transactions {
      id
      createdAt
      updatedAt
      transactionType
      comment
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GiftCardInfoGQL extends Apollo.Query<GiftCardInfoQuery, GiftCardInfoQueryVariables> {
    document = GiftCardInfoDocument;
    
  }
export const GiftCardReloadDocument = gql`
    mutation GiftCardReload($amount: BigDecimal!, $id: UUID!) {
  giftCardReload(amount: $amount, id: $id) {
    id
    createdAt
    activatedBy
    code
    balance
    transactions {
      id
      createdAt
      updatedAt
      transactionType
      comment
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GiftCardReloadGQL extends Apollo.Mutation<GiftCardReloadMutation, GiftCardReloadMutationVariables> {
    document = GiftCardReloadDocument;
    
  }
export const GiftCardBalanceDocument = gql`
    query giftCardBalance($code: String!, $pin: String) {
  giftCardBalance(code: $code, pin: $pin)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GiftCardBalanceGQL extends Apollo.Query<GiftCardBalanceQuery, GiftCardBalanceQueryVariables> {
    document = GiftCardBalanceDocument;
    
  }
export const InventoryGetHistoryDocument = gql`
    mutation inventoryGetHistory($id: UUID!, $warehouse: String!, $days: Long!) {
  inventoryGetHistory(warehouse: $warehouse, id: $id, days: $days) {
    timestamp
    user
    quantityOnShelf
    quantityAvailable
    changeQuantity
    changeType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InventoryGetHistoryGQL extends Apollo.Mutation<InventoryGetHistoryMutation, InventoryGetHistoryMutationVariables> {
    document = InventoryGetHistoryDocument;
    
  }
export const InventorySetDetailsDocument = gql`
    mutation inventorySetDetails($warehouse: String!, $id: UUID!, $quantity: Long!) {
  inventorySetDetails(warehouse: $warehouse, id: $id, quantity: $quantity) {
    product {
      ...ADMIN_SIMPLE_PRODUCT_ENTITY
    }
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InventorySetDetailsGQL extends Apollo.Mutation<InventorySetDetailsMutation, InventorySetDetailsMutationVariables> {
    document = InventorySetDetailsDocument;
    
  }
export const KitProductAddAnimalDocument = gql`
    mutation kitProductAddAnimal($id: UUID!, $animalId: UUID!) {
  kitProductAddAnimal(id: $id, animalId: $animalId) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductAddAnimalGQL extends Apollo.Mutation<KitProductAddAnimalMutation, KitProductAddAnimalMutationVariables> {
    document = KitProductAddAnimalDocument;
    
  }
export const KitProductAddCrossSellItemsDocument = gql`
    mutation kitProductAddCrossSellItems($id: UUID!, $productIds: [UUID]!) {
  kitProductAddCrossSellItems(id: $id, productIds: $productIds) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductAddCrossSellItemsGQL extends Apollo.Mutation<KitProductAddCrossSellItemsMutation, KitProductAddCrossSellItemsMutationVariables> {
    document = KitProductAddCrossSellItemsDocument;
    
  }
export const KitProductAddImageDocument = gql`
    mutation kitProductAddImage($id: UUID!, $mediaId: UUID!) {
  kitProductAddImage(id: $id, mediaId: $mediaId) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductAddImageGQL extends Apollo.Mutation<KitProductAddImageMutation, KitProductAddImageMutationVariables> {
    document = KitProductAddImageDocument;
    
  }
export const KitProductAddKitItemDocument = gql`
    mutation kitProductAddKitItem($id: UUID!, $productId: UUID!, $quantity: Long!) {
  kitProductAddKitItem(id: $id, productId: $productId, quantity: $quantity) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductAddKitItemGQL extends Apollo.Mutation<KitProductAddKitItemMutation, KitProductAddKitItemMutationVariables> {
    document = KitProductAddKitItemDocument;
    
  }
export const KitProductAddPriceTierDocument = gql`
    mutation kitProductAddPriceTier($id: UUID!, $price: BigDecimal!, $quantity: BigDecimal!, $userType: UserType) {
  kitProductAddPriceTier(
    id: $id
    price: $price
    quantity: $quantity
    userType: $userType
  ) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductAddPriceTierGQL extends Apollo.Mutation<KitProductAddPriceTierMutation, KitProductAddPriceTierMutationVariables> {
    document = KitProductAddPriceTierDocument;
    
  }
export const KitProductBySlugDocument = gql`
    query kitProductBySlug($slug: String!) {
  kitProductBySlug(slug: $slug) {
    id
    cls
    slug
    sku
    title
    quantityAvailableForSale
    price
    salePrice
    subscription
    metaTitle
    metaDescription
    metaKeywords
    averageRating
    countRating
    shippingNeeds
    kitItems {
      id
      quantity
      simpleProduct {
        id
        cls
        sku
        slug
        title
        price
        salePrice
        averageRating
        countRating
        quantityAvailableForSale
        medias {
          url
        }
        shippingRuleSet {
          liveArrivalGuarantees {
            minTemp
            maxTemp
            transitTimeType
          }
          destinationRestriction {
            domesticOnly
            prohibitedStates
          }
        }
      }
    }
    tieredPrices {
      id
      quantity
      price
    }
    publishedRevision {
      htmlContent
    }
    medias {
      id
      mediaType
      url
    }
    explicitCategories {
      active
      ancestors {
        active
        name
        path
      }
      id
      slug
      path
      name
    }
    variationSet {
      products {
        active
        cls
        id
        title
        slug
        price
        salePrice
        variationKey
        medias {
          id
          mediaType
          url
        }
      }
    }
    upsellProducts {
      id
      cls
      active
      title
      sku
      slug
      countRating
      averageRating
      quantityAvailableForSale
      price
      salePrice
      variationKey
      featured
      medias {
        id
        mediaType
        url
      }
    }
    shippingRuleSet {
      liveArrivalGuarantees {
        minTemp
        maxTemp
        transitTimeType
      }
      destinationRestriction {
        domesticOnly
        prohibitedStates
      }
    }
    careSheets {
      id
      title
      slug
      createdAt
      metaDescription
      active
      readTime
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductBySlugGQL extends Apollo.Query<KitProductBySlugQuery, KitProductBySlugQueryVariables> {
    document = KitProductBySlugDocument;
    
  }
export const KitProductClearKitItemAlternateDocument = gql`
    mutation kitProductClearKitItemAlternate($kitItemId: UUID!, $id: UUID!) {
  kitProductClearKitItemAlternate(kitItemId: $kitItemId, id: $id) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductClearKitItemAlternateGQL extends Apollo.Mutation<KitProductClearKitItemAlternateMutation, KitProductClearKitItemAlternateMutationVariables> {
    document = KitProductClearKitItemAlternateDocument;
    
  }
export const KitProductCloneDocument = gql`
    mutation kitProductClone($id: UUID!, $sku: String!, $title: String!, $slug: String, $price: BigDecimal!) {
  kitProductClone(id: $id, sku: $sku, title: $title, slug: $slug, price: $price) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductCloneGQL extends Apollo.Mutation<KitProductCloneMutation, KitProductCloneMutationVariables> {
    document = KitProductCloneDocument;
    
  }
export const KitProductCreateDocument = gql`
    mutation kitProductCreate($sku: String!, $title: String!, $slug: String, $price: BigDecimal!) {
  kitProductCreate(sku: $sku, title: $title, slug: $slug, price: $price) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductCreateGQL extends Apollo.Mutation<KitProductCreateMutation, KitProductCreateMutationVariables> {
    document = KitProductCreateDocument;
    
  }
export const KitProductFilterDocument = gql`
    query kitProductFilter($active: Boolean, $pageable: GraphQLPageableInput!, $category: String, $sku: String, $title: String, $sort: GraphQLSortInput, $upc: String) {
  kitProductFilter(
    title: {pattern: $title}
    sku: {pattern: $sku}
    active: {value: $active}
    category: $category
    page: $pageable
    sort: $sort
    upc: $upc
  ) {
    data {
      id
      slug
      sku
      upc
      title
      active
      featured
      visible
      quantityAvailableForSale
      price
      specialPrice
      wholesalePrice
      cost
      medias {
        id
        mediaType
        url
      }
      explicitCategories {
        id
        slug
        path
        name
        parent {
          id
          slug
          path
          name
          parent {
            id
            slug
            path
            name
          }
        }
      }
      explicitDepartment {
        id
        slug
        name
        parent {
          id
          slug
          name
        }
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductFilterGQL extends Apollo.Query<KitProductFilterQuery, KitProductFilterQueryVariables> {
    document = KitProductFilterDocument;
    
  }
export const KitProductInfoDocument = gql`
    query kitProductInfo($id: UUID!) {
  kitProductInfo(id: $id) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductInfoGQL extends Apollo.Query<KitProductInfoQuery, KitProductInfoQueryVariables> {
    document = KitProductInfoDocument;
    
  }
export const KitProductReindexDocument = gql`
    mutation kitProductReindex($id: UUID!) {
  kitProductReindex(id: $id) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductReindexGQL extends Apollo.Mutation<KitProductReindexMutation, KitProductReindexMutationVariables> {
    document = KitProductReindexDocument;
    
  }
export const KitProductRemoveDocument = gql`
    mutation kitProductRemove($id: UUID!) {
  kitProductRemove(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductRemoveGQL extends Apollo.Mutation<KitProductRemoveMutation, KitProductRemoveMutationVariables> {
    document = KitProductRemoveDocument;
    
  }
export const KitProductRemoveAnimalDocument = gql`
    mutation kitProductRemoveAnimal($id: UUID!, $animalId: UUID!) {
  kitProductRemoveAnimal(id: $id, animalId: $animalId) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductRemoveAnimalGQL extends Apollo.Mutation<KitProductRemoveAnimalMutation, KitProductRemoveAnimalMutationVariables> {
    document = KitProductRemoveAnimalDocument;
    
  }
export const KitProductRemoveCrossSellItemDocument = gql`
    mutation kitProductRemoveCrossSellItem($id: UUID!, $crossSellItemId: UUID!) {
  kitProductRemoveCrossSellItem(id: $id, crossSellItemId: $crossSellItemId) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductRemoveCrossSellItemGQL extends Apollo.Mutation<KitProductRemoveCrossSellItemMutation, KitProductRemoveCrossSellItemMutationVariables> {
    document = KitProductRemoveCrossSellItemDocument;
    
  }
export const KitProductRemoveKitItemDocument = gql`
    mutation kitProductRemoveKitItem($id: UUID!, $kitItemId: UUID!) {
  kitProductRemoveKitItem(id: $id, kitItemId: $kitItemId) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductRemoveKitItemGQL extends Apollo.Mutation<KitProductRemoveKitItemMutation, KitProductRemoveKitItemMutationVariables> {
    document = KitProductRemoveKitItemDocument;
    
  }
export const KitProductRemovePriceTierDocument = gql`
    mutation kitProductRemovePriceTier($id: UUID!, $priceTierId: UUID!) {
  kitProductRemovePriceTier(id: $id, priceTierId: $priceTierId) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductRemovePriceTierGQL extends Apollo.Mutation<KitProductRemovePriceTierMutation, KitProductRemovePriceTierMutationVariables> {
    document = KitProductRemovePriceTierDocument;
    
  }
export const KitProductRemoveProductMediaDocument = gql`
    mutation kitProductRemoveProductMedia($id: UUID!, $productMediaId: UUID!) {
  kitProductRemoveProductMedia(id: $id, productMediaId: $productMediaId) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductRemoveProductMediaGQL extends Apollo.Mutation<KitProductRemoveProductMediaMutation, KitProductRemoveProductMediaMutationVariables> {
    document = KitProductRemoveProductMediaDocument;
    
  }
export const KitProductSetActiveDocument = gql`
    mutation kitProductSetActive($id: UUID!, $active: Boolean!) {
  kitProductSetActive(id: $id, active: $active) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetActiveGQL extends Apollo.Mutation<KitProductSetActiveMutation, KitProductSetActiveMutationVariables> {
    document = KitProductSetActiveDocument;
    
  }
export const KitProductSetCostDocument = gql`
    mutation kitProductSetCost($id: UUID!, $cost: BigDecimal) {
  kitProductSetCost(id: $id, cost: $cost) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetCostGQL extends Apollo.Mutation<KitProductSetCostMutation, KitProductSetCostMutationVariables> {
    document = KitProductSetCostDocument;
    
  }
export const KitProductSetCrossSellItemPositionDocument = gql`
    mutation kitProductSetCrossSellItemPosition($id: UUID!, $crossSellItemId: UUID!, $position: Int!) {
  kitProductSetCrossSellItemPosition(
    id: $id
    crossSellItemId: $crossSellItemId
    position: $position
  ) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetCrossSellItemPositionGQL extends Apollo.Mutation<KitProductSetCrossSellItemPositionMutation, KitProductSetCrossSellItemPositionMutationVariables> {
    document = KitProductSetCrossSellItemPositionDocument;
    
  }
export const KitProductSetFeaturedDocument = gql`
    mutation kitProductSetFeatured($id: UUID!, $featured: Boolean!) {
  kitProductSetFeatured(id: $id, featured: $featured) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetFeaturedGQL extends Apollo.Mutation<KitProductSetFeaturedMutation, KitProductSetFeaturedMutationVariables> {
    document = KitProductSetFeaturedDocument;
    
  }
export const KitProductSetKitItemAlternateDocument = gql`
    mutation kitProductSetKitItemAlternate($quantity: Long!, $productId: UUID!, $kitItemId: UUID!, $id: UUID!) {
  kitProductSetKitItemAlternate(
    quantity: $quantity
    productId: $productId
    kitItemId: $kitItemId
    id: $id
  ) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetKitItemAlternateGQL extends Apollo.Mutation<KitProductSetKitItemAlternateMutation, KitProductSetKitItemAlternateMutationVariables> {
    document = KitProductSetKitItemAlternateDocument;
    
  }
export const KitProductSetKitItemPositionDocument = gql`
    mutation kitProductSetKitItemPosition($id: UUID!, $kitItemId: UUID!, $position: Int!) {
  kitProductSetKitItemPosition(
    id: $id
    kitItemId: $kitItemId
    position: $position
  ) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetKitItemPositionGQL extends Apollo.Mutation<KitProductSetKitItemPositionMutation, KitProductSetKitItemPositionMutationVariables> {
    document = KitProductSetKitItemPositionDocument;
    
  }
export const KitProductSetMetaDescriptionDocument = gql`
    mutation kitProductSetMetaDescription($id: UUID!, $metaDescription: String) {
  kitProductSetMetaDescription(id: $id, metaDescription: $metaDescription) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetMetaDescriptionGQL extends Apollo.Mutation<KitProductSetMetaDescriptionMutation, KitProductSetMetaDescriptionMutationVariables> {
    document = KitProductSetMetaDescriptionDocument;
    
  }
export const KitProductSetMetaKeywordsDocument = gql`
    mutation kitProductSetMetaKeywords($id: UUID!, $metaKeywords: String) {
  kitProductSetMetaKeywords(id: $id, metaKeywords: $metaKeywords) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetMetaKeywordsGQL extends Apollo.Mutation<KitProductSetMetaKeywordsMutation, KitProductSetMetaKeywordsMutationVariables> {
    document = KitProductSetMetaKeywordsDocument;
    
  }
export const KitProductSetMetaTitleDocument = gql`
    mutation kitProductSetMetaTitle($id: UUID!, $metaTitle: String) {
  kitProductSetMetaTitle(id: $id, metaTitle: $metaTitle) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetMetaTitleGQL extends Apollo.Mutation<KitProductSetMetaTitleMutation, KitProductSetMetaTitleMutationVariables> {
    document = KitProductSetMetaTitleDocument;
    
  }
export const KitProductSetPriceDocument = gql`
    mutation kitProductSetPrice($id: UUID!, $price: BigDecimal!) {
  kitProductSetPrice(id: $id, price: $price) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetPriceGQL extends Apollo.Mutation<KitProductSetPriceMutation, KitProductSetPriceMutationVariables> {
    document = KitProductSetPriceDocument;
    
  }
export const KitProductSetProductMediaSequenceDocument = gql`
    mutation kitProductSetProductMediaSequence($id: UUID!, $productMediaId: UUID!, $sequence: Int!) {
  kitProductSetProductMediaSequence(
    id: $id
    productMediaId: $productMediaId
    sequence: $sequence
  ) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetProductMediaSequenceGQL extends Apollo.Mutation<KitProductSetProductMediaSequenceMutation, KitProductSetProductMediaSequenceMutationVariables> {
    document = KitProductSetProductMediaSequenceDocument;
    
  }
export const KitProductSetPublishedRevisionDocument = gql`
    mutation kitProductSetPublishedRevision($id: UUID!, $htmlContent: String) {
  kitProductSetPublishedRevision(id: $id, htmlContent: $htmlContent) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetPublishedRevisionGQL extends Apollo.Mutation<KitProductSetPublishedRevisionMutation, KitProductSetPublishedRevisionMutationVariables> {
    document = KitProductSetPublishedRevisionDocument;
    
  }
export const KitProductSetSkuDocument = gql`
    mutation kitProductSetSku($id: UUID!, $sku: String!) {
  kitProductSetSku(id: $id, sku: $sku) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetSkuGQL extends Apollo.Mutation<KitProductSetSkuMutation, KitProductSetSkuMutationVariables> {
    document = KitProductSetSkuDocument;
    
  }
export const KitProductSetSlugDocument = gql`
    mutation kitProductSetSlug($id: UUID!, $slug: String!) {
  kitProductSetSlug(id: $id, slug: $slug) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetSlugGQL extends Apollo.Mutation<KitProductSetSlugMutation, KitProductSetSlugMutationVariables> {
    document = KitProductSetSlugDocument;
    
  }
export const KitProductSetSpecialPriceDocument = gql`
    mutation kitProductSetSpecialPrice($id: UUID!, $specialPrice: BigDecimal) {
  kitProductSetSpecialPrice(id: $id, specialPrice: $specialPrice) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetSpecialPriceGQL extends Apollo.Mutation<KitProductSetSpecialPriceMutation, KitProductSetSpecialPriceMutationVariables> {
    document = KitProductSetSpecialPriceDocument;
    
  }
export const KitProductSetSubscriptionDocument = gql`
    mutation kitProductSetSubscription($id: UUID!, $subscription: Boolean!) {
  kitProductSetSubscription(id: $id, subscription: $subscription) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetSubscriptionGQL extends Apollo.Mutation<KitProductSetSubscriptionMutation, KitProductSetSubscriptionMutationVariables> {
    document = KitProductSetSubscriptionDocument;
    
  }
export const KitProductSetTitleDocument = gql`
    mutation kitProductSetTitle($id: UUID!, $title: String!) {
  kitProductSetTitle(id: $id, title: $title) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetTitleGQL extends Apollo.Mutation<KitProductSetTitleMutation, KitProductSetTitleMutationVariables> {
    document = KitProductSetTitleDocument;
    
  }
export const KitProductSetUpcDocument = gql`
    mutation kitProductSetUpc($id: UUID!, $upc: String) {
  kitProductSetUpc(id: $id, upc: $upc) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetUpcGQL extends Apollo.Mutation<KitProductSetUpcMutation, KitProductSetUpcMutationVariables> {
    document = KitProductSetUpcDocument;
    
  }
export const KitProductSetVisibleDocument = gql`
    mutation kitProductSetVisible($id: UUID!, $visible: Boolean!) {
  kitProductSetVisible(id: $id, visible: $visible) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetVisibleGQL extends Apollo.Mutation<KitProductSetVisibleMutation, KitProductSetVisibleMutationVariables> {
    document = KitProductSetVisibleDocument;
    
  }
export const KitProductSetWholesalePriceDocument = gql`
    mutation kitProductSetWholesalePrice($id: UUID!, $wholesalePrice: BigDecimal) {
  kitProductSetWholesalePrice(id: $id, wholesalePrice: $wholesalePrice) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductSetWholesalePriceGQL extends Apollo.Mutation<KitProductSetWholesalePriceMutation, KitProductSetWholesalePriceMutationVariables> {
    document = KitProductSetWholesalePriceDocument;
    
  }
export const KitProductUploadImageDocument = gql`
    mutation kitProductUploadImage($id: UUID!, $dataUrl: String!, $filename: String!, $keywords: String, $title: String) {
  kitProductUploadImage(
    id: $id
    dataUrl: $dataUrl
    filename: $filename
    keyWords: $keywords
    title: $title
  ) {
    ...ADMIN_KIT_PRODUCT_ENTITY
  }
}
    ${Admin_Kit_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductUploadImageGQL extends Apollo.Mutation<KitProductUploadImageMutation, KitProductUploadImageMutationVariables> {
    document = KitProductUploadImageDocument;
    
  }
export const KitProductWithCrossSellsDocument = gql`
    query kitProductWithCrossSells($slug: String!) {
  kitProductBySlug(slug: $slug) {
    id
    cls
    sku
    slug
    title
    quantityAvailableForSale
    price
    salePrice
    medias {
      id
      mediaType
      url
    }
    shippingRuleSet {
      liveArrivalGuarantees {
        minTemp
        maxTemp
        transitTimeType
      }
    }
    crossSellItems {
      id
      position
      crossSellProduct {
        id
        cls
        active
        title
        sku
        slug
        countRating
        averageRating
        quantityAvailableForSale
        price
        salePrice
        variationKey
        featured
        medias {
          id
          mediaType
          url
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class KitProductWithCrossSellsGQL extends Apollo.Query<KitProductWithCrossSellsQuery, KitProductWithCrossSellsQueryVariables> {
    document = KitProductWithCrossSellsDocument;
    
  }
export const ListedProductAddCrossSellItemsDocument = gql`
    mutation listedProductAddCrossSellItems($id: UUID!, $productIds: [UUID]!) {
  listedProductAddCrossSellItems(id: $id, productIds: $productIds) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductAddCrossSellItemsGQL extends Apollo.Mutation<ListedProductAddCrossSellItemsMutation, ListedProductAddCrossSellItemsMutationVariables> {
    document = ListedProductAddCrossSellItemsDocument;
    
  }
export const ListedProductAddImageDocument = gql`
    mutation listedProductAddImage($id: UUID!, $mediaId: UUID!) {
  listedProductAddImage(id: $id, mediaId: $mediaId) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductAddImageGQL extends Apollo.Mutation<ListedProductAddImageMutation, ListedProductAddImageMutationVariables> {
    document = ListedProductAddImageDocument;
    
  }
export const ListedProductAddListingImageDocument = gql`
    mutation listedProductAddListingImage($id: UUID!, $mediaId: UUID!) {
  listedProductAddListingImage(id: $id, mediaId: $mediaId) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductAddListingImageGQL extends Apollo.Mutation<ListedProductAddListingImageMutation, ListedProductAddListingImageMutationVariables> {
    document = ListedProductAddListingImageDocument;
    
  }
export const ListedProductAddWarehouseDocument = gql`
    mutation listedProductAddWarehouse($id: UUID!, $warehouse: String!) {
  listedProductAddWarehouse(productId: $id, warehouse: $warehouse) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductAddWarehouseGQL extends Apollo.Mutation<ListedProductAddWarehouseMutation, ListedProductAddWarehouseMutationVariables> {
    document = ListedProductAddWarehouseDocument;
    
  }
export const ListedProductAddZoneDocument = gql`
    mutation listedProductAddZone($id: UUID!, $zone: UUID!) {
  listedProductAddZone(productId: $id, zoneId: $zone) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductAddZoneGQL extends Apollo.Mutation<ListedProductAddZoneMutation, ListedProductAddZoneMutationVariables> {
    document = ListedProductAddZoneDocument;
    
  }
export const ListedProductBySlugDocument = gql`
    query listedProductBySlug($slug: String!) {
  listedProductBySlug(slug: $slug) {
    id
    cls
    slug
    sku
    title
    featured
    listedProductType
    quantityAvailableForSale
    price
    salePrice
    metaTitle
    metaDescription
    metaKeywords
    averageRating
    countRating
    shippingNeeds
    listings {
      id
      price
      salePrice
      birthDate
      sex
      listingWeightUnit
      weight
      listingWeightUnit
      weightDate
      quantity
      identifier
      title
      comments
      height
      length
      width
      listingMedias {
        id
        mediaType
        url
      }
    }
    publishedRevision {
      htmlContent
    }
    medias {
      id
      mediaType
      url
    }
    explicitCategories {
      active
      ancestors {
        active
        name
        path
      }
      id
      slug
      path
      name
    }
    variationSet {
      products {
        active
        cls
        id
        title
        slug
        price
        salePrice
        variationKey
        medias {
          id
          mediaType
          url
        }
      }
    }
    upsellProducts {
      id
      cls
      active
      title
      sku
      slug
      countRating
      averageRating
      quantityAvailableForSale
      price
      salePrice
      variationKey
      featured
      medias {
        id
        mediaType
        url
      }
    }
    shippingRuleSet {
      liveArrivalGuarantees {
        minTemp
        maxTemp
        transitTimeType
      }
      destinationRestriction {
        domesticOnly
        prohibitedStates
      }
    }
    careSheets {
      id
      title
      slug
      createdAt
      metaDescription
      active
      readTime
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductBySlugGQL extends Apollo.Query<ListedProductBySlugQuery, ListedProductBySlugQueryVariables> {
    document = ListedProductBySlugDocument;
    
  }
export const ListedProductClearBinDocument = gql`
    mutation listedProductClearBin($id: UUID!, $warehouse: String!) {
  listedProductClearBin(id: $id, warehouse: $warehouse) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductClearBinGQL extends Apollo.Mutation<ListedProductClearBinMutation, ListedProductClearBinMutationVariables> {
    document = ListedProductClearBinDocument;
    
  }
export const ListedProductClearBrandDocument = gql`
    mutation listedProductClearBrand($productId: UUID!) {
  listedProductClearBrand(productId: $productId) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductClearBrandGQL extends Apollo.Mutation<ListedProductClearBrandMutation, ListedProductClearBrandMutationVariables> {
    document = ListedProductClearBrandDocument;
    
  }
export const ListedProductCreateDocument = gql`
    mutation listedProductCreate($sku: String!, $upc: String, $title: String!, $slug: String, $cost: BigDecimal, $listedProductType: ListedProductType!, $weight: BigDecimal!) {
  listedProductCreate(
    sku: $sku
    upc: $upc
    title: $title
    slug: $slug
    cost: $cost
    type: $listedProductType
    weight: $weight
  ) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductCreateGQL extends Apollo.Mutation<ListedProductCreateMutation, ListedProductCreateMutationVariables> {
    document = ListedProductCreateDocument;
    
  }
export const ListedProductCreateListingDocument = gql`
    mutation listedProductCreateListing($id: UUID!, $title: String!, $identifier: String!, $quantity: Long!, $price: BigDecimal!, $birthDate: LocalDate, $comments: String, $sex: SexType, $length: BigDecimal, $width: BigDecimal, $height: BigDecimal, $weight: BigDecimal, $listingWeightUnit: ListingWeightUnit, $weightDate: LocalDate, $warehouseId: UUID!) {
  listedProductCreateListing(
    id: $id
    title: $title
    identifier: $identifier
    quantity: $quantity
    price: $price
    birthDate: $birthDate
    comments: $comments
    sex: $sex
    length: $length
    width: $width
    height: $height
    weight: $weight
    listingWeightUnit: $listingWeightUnit
    weightDate: $weightDate
    warehouseId: $warehouseId
  ) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductCreateListingGQL extends Apollo.Mutation<ListedProductCreateListingMutation, ListedProductCreateListingMutationVariables> {
    document = ListedProductCreateListingDocument;
    
  }
export const ListedProductFilterDocument = gql`
    query listedProductFilter($pageable: GraphQLPageableInput!, $title: String, $sku: String, $active: Boolean, $brand: String, $department: String, $category: String, $sort: GraphQLSortInput, $inStock: Boolean) {
  listedProductFilter(
    title: {pattern: $title}
    sku: {pattern: $sku}
    active: {value: $active}
    brand: $brand
    department: $department
    category: $category
    page: $pageable
    sort: $sort
    inStock: {value: $inStock}
  ) {
    data {
      id
      slug
      sku
      upc
      title
      active
      quantityAvailableForSale
      averageRating
      countRating
      explicitDepartment {
        id
        slug
        name
        parent {
          id
          slug
          name
        }
      }
      brand {
        id
        slug
        name
      }
      price
      salePrice
      shippingNeeds
      medias {
        id
        mediaType
        url
      }
      explicitCategories {
        id
        slug
        path
        name
        parent {
          id
          slug
          path
          name
          parent {
            id
            slug
            path
            name
          }
        }
      }
      shippingRuleSet {
        id
        slug
        name
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductFilterGQL extends Apollo.Query<ListedProductFilterQuery, ListedProductFilterQueryVariables> {
    document = ListedProductFilterDocument;
    
  }
export const ListedProductInfoDocument = gql`
    query listedProductInfo($id: UUID!) {
  listedProductInfo(id: $id) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductInfoGQL extends Apollo.Query<ListedProductInfoQuery, ListedProductInfoQueryVariables> {
    document = ListedProductInfoDocument;
    
  }
export const ListedProductReindexDocument = gql`
    mutation listedProductReindex($id: UUID!) {
  listedProductReindex(id: $id) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductReindexGQL extends Apollo.Mutation<ListedProductReindexMutation, ListedProductReindexMutationVariables> {
    document = ListedProductReindexDocument;
    
  }
export const ListedProductRemoveDocument = gql`
    mutation listedProductRemove($id: UUID!) {
  listedProductRemove(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductRemoveGQL extends Apollo.Mutation<ListedProductRemoveMutation, ListedProductRemoveMutationVariables> {
    document = ListedProductRemoveDocument;
    
  }
export const ListedProductRemoveCrossSellItemDocument = gql`
    mutation listedProductRemoveCrossSellItem($id: UUID!, $crossSellItemId: UUID!) {
  listedProductRemoveCrossSellItem(id: $id, crossSellItemId: $crossSellItemId) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductRemoveCrossSellItemGQL extends Apollo.Mutation<ListedProductRemoveCrossSellItemMutation, ListedProductRemoveCrossSellItemMutationVariables> {
    document = ListedProductRemoveCrossSellItemDocument;
    
  }
export const ListedProductRemoveListingDocument = gql`
    mutation listedProductRemoveListing($productId: UUID!, $listingId: UUID!) {
  listedProductRemoveListing(productId: $productId, listingId: $listingId) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductRemoveListingGQL extends Apollo.Mutation<ListedProductRemoveListingMutation, ListedProductRemoveListingMutationVariables> {
    document = ListedProductRemoveListingDocument;
    
  }
export const ListedProductRemoveListingMediaDocument = gql`
    mutation listedProductRemoveListingMedia($id: UUID!, $productMediaId: UUID!) {
  listedProductRemoveListingMedia(id: $id, productMediaId: $productMediaId) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductRemoveListingMediaGQL extends Apollo.Mutation<ListedProductRemoveListingMediaMutation, ListedProductRemoveListingMediaMutationVariables> {
    document = ListedProductRemoveListingMediaDocument;
    
  }
export const ListedProductRemoveProductMediaDocument = gql`
    mutation listedProductRemoveProductMedia($id: UUID!, $productMediaId: UUID!) {
  listedProductRemoveProductMedia(id: $id, productMediaId: $productMediaId) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductRemoveProductMediaGQL extends Apollo.Mutation<ListedProductRemoveProductMediaMutation, ListedProductRemoveProductMediaMutationVariables> {
    document = ListedProductRemoveProductMediaDocument;
    
  }
export const ListedProductRemoveWarehouseDocument = gql`
    mutation listedProductRemoveWarehouse($id: UUID!, $warehouse: String!) {
  listedProductRemoveWarehouse(productId: $id, warehouse: $warehouse) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductRemoveWarehouseGQL extends Apollo.Mutation<ListedProductRemoveWarehouseMutation, ListedProductRemoveWarehouseMutationVariables> {
    document = ListedProductRemoveWarehouseDocument;
    
  }
export const ListedProductRemoveZoneDocument = gql`
    mutation listedProductRemoveZone($id: UUID!, $zone: UUID!) {
  listedProductRemoveZone(productId: $id, zoneId: $zone) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductRemoveZoneGQL extends Apollo.Mutation<ListedProductRemoveZoneMutation, ListedProductRemoveZoneMutationVariables> {
    document = ListedProductRemoveZoneDocument;
    
  }
export const ListedProductSetActiveDocument = gql`
    mutation listedProductSetActive($id: UUID!, $active: Boolean!) {
  listedProductSetActive(id: $id, active: $active) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetActiveGQL extends Apollo.Mutation<ListedProductSetActiveMutation, ListedProductSetActiveMutationVariables> {
    document = ListedProductSetActiveDocument;
    
  }
export const ListedProductSetBinDocument = gql`
    mutation listedProductSetBin($id: UUID!, $bin: String!, $warehouse: String!) {
  listedProductSetBin(id: $id, bin: $bin, warehouse: $warehouse) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetBinGQL extends Apollo.Mutation<ListedProductSetBinMutation, ListedProductSetBinMutationVariables> {
    document = ListedProductSetBinDocument;
    
  }
export const ListedProductSetBrandDocument = gql`
    mutation listedProductSetBrand($productId: UUID!, $brandId: UUID!) {
  listedProductSetBrand(productId: $productId, brandId: $brandId) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetBrandGQL extends Apollo.Mutation<ListedProductSetBrandMutation, ListedProductSetBrandMutationVariables> {
    document = ListedProductSetBrandDocument;
    
  }
export const ListedProductSetCostDocument = gql`
    mutation listedProductSetCost($id: UUID!, $cost: BigDecimal) {
  listedProductSetCost(id: $id, cost: $cost) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetCostGQL extends Apollo.Mutation<ListedProductSetCostMutation, ListedProductSetCostMutationVariables> {
    document = ListedProductSetCostDocument;
    
  }
export const ListedProductSetCrossSellItemPositionDocument = gql`
    mutation listedProductSetCrossSellItemPosition($id: UUID!, $crossSellItemId: UUID!, $position: Int!) {
  listedProductSetCrossSellItemPosition(
    id: $id
    crossSellItemId: $crossSellItemId
    position: $position
  ) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetCrossSellItemPositionGQL extends Apollo.Mutation<ListedProductSetCrossSellItemPositionMutation, ListedProductSetCrossSellItemPositionMutationVariables> {
    document = ListedProductSetCrossSellItemPositionDocument;
    
  }
export const ListedProductSetDimensionsDocument = gql`
    mutation listedProductSetDimensions($id: UUID!, $length: BigDecimal!, $width: BigDecimal!, $height: BigDecimal!) {
  listedProductSetDimensions(
    id: $id
    length: $length
    width: $width
    height: $height
  ) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetDimensionsGQL extends Apollo.Mutation<ListedProductSetDimensionsMutation, ListedProductSetDimensionsMutationVariables> {
    document = ListedProductSetDimensionsDocument;
    
  }
export const ListedProductSetFeaturedDocument = gql`
    mutation listedProductSetFeatured($id: UUID!, $featured: Boolean!) {
  listedProductSetFeatured(id: $id, featured: $featured) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetFeaturedGQL extends Apollo.Mutation<ListedProductSetFeaturedMutation, ListedProductSetFeaturedMutationVariables> {
    document = ListedProductSetFeaturedDocument;
    
  }
export const ListedProductSetListedProductTypeDocument = gql`
    mutation listedProductSetListedProductType($id: UUID!, $listedProductType: ListedProductType!) {
  listedProductSetListedProductType(
    id: $id
    listedProductType: $listedProductType
  ) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetListedProductTypeGQL extends Apollo.Mutation<ListedProductSetListedProductTypeMutation, ListedProductSetListedProductTypeMutationVariables> {
    document = ListedProductSetListedProductTypeDocument;
    
  }
export const ListedProductSetListingBirthDateDocument = gql`
    mutation listedProductSetListingBirthDate($id: UUID!, $birthDate: LocalDate) {
  listedProductSetListingBirthDate(id: $id, birthDate: $birthDate) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetListingBirthDateGQL extends Apollo.Mutation<ListedProductSetListingBirthDateMutation, ListedProductSetListingBirthDateMutationVariables> {
    document = ListedProductSetListingBirthDateDocument;
    
  }
export const ListedProductSetListingCommentsDocument = gql`
    mutation listedProductSetListingComments($id: UUID!, $comments: String) {
  listedProductSetListingComments(id: $id, comments: $comments) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetListingCommentsGQL extends Apollo.Mutation<ListedProductSetListingCommentsMutation, ListedProductSetListingCommentsMutationVariables> {
    document = ListedProductSetListingCommentsDocument;
    
  }
export const ListedProductSetListingDimensionsDocument = gql`
    mutation listedProductSetListingDimensions($id: UUID!, $length: BigDecimal, $width: BigDecimal, $height: BigDecimal) {
  listedProductSetListingDimensions(
    id: $id
    length: $length
    width: $width
    height: $height
  ) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetListingDimensionsGQL extends Apollo.Mutation<ListedProductSetListingDimensionsMutation, ListedProductSetListingDimensionsMutationVariables> {
    document = ListedProductSetListingDimensionsDocument;
    
  }
export const ListedProductSetListingIdentifierDocument = gql`
    mutation listedProductSetListingIdentifier($id: UUID!, $identifier: String!) {
  listedProductSetListingIdentifier(id: $id, identifier: $identifier) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetListingIdentifierGQL extends Apollo.Mutation<ListedProductSetListingIdentifierMutation, ListedProductSetListingIdentifierMutationVariables> {
    document = ListedProductSetListingIdentifierDocument;
    
  }
export const ListedProductSetListingMediaSequenceDocument = gql`
    mutation listedProductSetListingMediaSequence($id: UUID!, $productMediaId: UUID!, $sequence: Int!) {
  listedProductSetListingMediaSequence(
    id: $id
    productMediaId: $productMediaId
    sequence: $sequence
  ) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetListingMediaSequenceGQL extends Apollo.Mutation<ListedProductSetListingMediaSequenceMutation, ListedProductSetListingMediaSequenceMutationVariables> {
    document = ListedProductSetListingMediaSequenceDocument;
    
  }
export const ListedProductSetListingPriceDocument = gql`
    mutation listedProductSetListingPrice($id: UUID!, $price: BigDecimal!) {
  listedProductSetListingPrice(id: $id, price: $price) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetListingPriceGQL extends Apollo.Mutation<ListedProductSetListingPriceMutation, ListedProductSetListingPriceMutationVariables> {
    document = ListedProductSetListingPriceDocument;
    
  }
export const ListedProductSetListingQuantityDocument = gql`
    mutation listedProductSetListingQuantity($id: UUID!, $quantity: Long!) {
  listedProductSetListingQuantity(id: $id, quantity: $quantity) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetListingQuantityGQL extends Apollo.Mutation<ListedProductSetListingQuantityMutation, ListedProductSetListingQuantityMutationVariables> {
    document = ListedProductSetListingQuantityDocument;
    
  }
export const ListedProductSetListingSexDocument = gql`
    mutation listedProductSetListingSex($id: UUID!, $sex: SexType) {
  listedProductSetListingSex(id: $id, sex: $sex) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetListingSexGQL extends Apollo.Mutation<ListedProductSetListingSexMutation, ListedProductSetListingSexMutationVariables> {
    document = ListedProductSetListingSexDocument;
    
  }
export const ListedProductSetListingTitleDocument = gql`
    mutation listedProductSetListingTitle($id: UUID!, $title: String!) {
  listedProductSetListingTitle(id: $id, title: $title) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetListingTitleGQL extends Apollo.Mutation<ListedProductSetListingTitleMutation, ListedProductSetListingTitleMutationVariables> {
    document = ListedProductSetListingTitleDocument;
    
  }
export const ListedProductSetListingWarehouseDocument = gql`
    mutation listedProductSetListingWarehouse($id: UUID!, $warehouseId: UUID!) {
  listedProductSetListingWarehouse(id: $id, warehouseId: $warehouseId) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetListingWarehouseGQL extends Apollo.Mutation<ListedProductSetListingWarehouseMutation, ListedProductSetListingWarehouseMutationVariables> {
    document = ListedProductSetListingWarehouseDocument;
    
  }
export const ListedProductSetListingWeightDocument = gql`
    mutation listedProductSetListingWeight($id: UUID!, $weight: BigDecimal) {
  listedProductSetListingWeight(id: $id, weight: $weight) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetListingWeightGQL extends Apollo.Mutation<ListedProductSetListingWeightMutation, ListedProductSetListingWeightMutationVariables> {
    document = ListedProductSetListingWeightDocument;
    
  }
export const ListedProductSetListingWeightDateDocument = gql`
    mutation listedProductSetListingWeightDate($id: UUID!, $weightDate: LocalDate) {
  listedProductSetListingWeightDate(id: $id, weightDate: $weightDate) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetListingWeightDateGQL extends Apollo.Mutation<ListedProductSetListingWeightDateMutation, ListedProductSetListingWeightDateMutationVariables> {
    document = ListedProductSetListingWeightDateDocument;
    
  }
export const ListedProductSetListingWeightUnitDocument = gql`
    mutation listedProductSetListingWeightUnit($id: UUID!, $listingWeightUnit: ListingWeightUnit) {
  listedProductSetListingWeightUnit(
    id: $id
    listingWeightUnit: $listingWeightUnit
  ) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetListingWeightUnitGQL extends Apollo.Mutation<ListedProductSetListingWeightUnitMutation, ListedProductSetListingWeightUnitMutationVariables> {
    document = ListedProductSetListingWeightUnitDocument;
    
  }
export const ListedProductSetLiveDocument = gql`
    mutation listedProductSetLive($id: UUID!, $live: Boolean!) {
  listedProductSetLive(id: $id, live: $live) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetLiveGQL extends Apollo.Mutation<ListedProductSetLiveMutation, ListedProductSetLiveMutationVariables> {
    document = ListedProductSetLiveDocument;
    
  }
export const ListedProductSetMetaDescriptionDocument = gql`
    mutation listedProductSetMetaDescription($id: UUID!, $metaDescription: String) {
  listedProductSetMetaDescription(id: $id, metaDescription: $metaDescription) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetMetaDescriptionGQL extends Apollo.Mutation<ListedProductSetMetaDescriptionMutation, ListedProductSetMetaDescriptionMutationVariables> {
    document = ListedProductSetMetaDescriptionDocument;
    
  }
export const ListedProductSetMetaKeywordsDocument = gql`
    mutation listedProductSetMetaKeywords($id: UUID!, $metaKeywords: String) {
  listedProductSetMetaKeywords(id: $id, metaKeywords: $metaKeywords) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetMetaKeywordsGQL extends Apollo.Mutation<ListedProductSetMetaKeywordsMutation, ListedProductSetMetaKeywordsMutationVariables> {
    document = ListedProductSetMetaKeywordsDocument;
    
  }
export const ListedProductSetMetaTitleDocument = gql`
    mutation listedProductSetMetaTitle($id: UUID!, $metaTitle: String) {
  listedProductSetMetaTitle(id: $id, metaTitle: $metaTitle) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetMetaTitleGQL extends Apollo.Mutation<ListedProductSetMetaTitleMutation, ListedProductSetMetaTitleMutationVariables> {
    document = ListedProductSetMetaTitleDocument;
    
  }
export const ListedProductSetPrepTypeDocument = gql`
    mutation listedProductSetPrepType($id: UUID!, $prepType: PrepType) {
  listedProductSetPrepType(id: $id, prepType: $prepType) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetPrepTypeGQL extends Apollo.Mutation<ListedProductSetPrepTypeMutation, ListedProductSetPrepTypeMutationVariables> {
    document = ListedProductSetPrepTypeDocument;
    
  }
export const ListedProductSetPriceDocument = gql`
    mutation listedProductSetPrice($id: UUID!, $price: BigDecimal!) {
  listedProductSetPrice(id: $id, price: $price) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetPriceGQL extends Apollo.Mutation<ListedProductSetPriceMutation, ListedProductSetPriceMutationVariables> {
    document = ListedProductSetPriceDocument;
    
  }
export const ListedProductSetProductMediaSequenceDocument = gql`
    mutation listedProductSetProductMediaSequence($id: UUID!, $productMediaId: UUID!, $sequence: Int!) {
  listedProductSetProductMediaSequence(
    id: $id
    productMediaId: $productMediaId
    sequence: $sequence
  ) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetProductMediaSequenceGQL extends Apollo.Mutation<ListedProductSetProductMediaSequenceMutation, ListedProductSetProductMediaSequenceMutationVariables> {
    document = ListedProductSetProductMediaSequenceDocument;
    
  }
export const ListedProductSetPublishedRevisionDocument = gql`
    mutation listedProductSetPublishedRevision($id: UUID!, $htmlContent: String) {
  listedProductSetPublishedRevision(id: $id, htmlContent: $htmlContent) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetPublishedRevisionGQL extends Apollo.Mutation<ListedProductSetPublishedRevisionMutation, ListedProductSetPublishedRevisionMutationVariables> {
    document = ListedProductSetPublishedRevisionDocument;
    
  }
export const ListedProductSetShippingNeedsTypeDocument = gql`
    mutation listedProductSetShippingNeedsType($id: UUID!, $shippingNeedsType: ShippingNeedsType!) {
  listedProductSetShippingNeedsType(
    id: $id
    shippingNeedsType: $shippingNeedsType
  ) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetShippingNeedsTypeGQL extends Apollo.Mutation<ListedProductSetShippingNeedsTypeMutation, ListedProductSetShippingNeedsTypeMutationVariables> {
    document = ListedProductSetShippingNeedsTypeDocument;
    
  }
export const ListedProductSetShippingRuleSetDocument = gql`
    mutation listedProductSetShippingRuleSet($productId: UUID!, $shippingRuleSetId: UUID!) {
  listedProductSetShippingRuleSet(
    productId: $productId
    shippingRuleSetId: $shippingRuleSetId
  ) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetShippingRuleSetGQL extends Apollo.Mutation<ListedProductSetShippingRuleSetMutation, ListedProductSetShippingRuleSetMutationVariables> {
    document = ListedProductSetShippingRuleSetDocument;
    
  }
export const ListedProductSetSkuDocument = gql`
    mutation listedProductSetSku($id: UUID!, $sku: String!) {
  listedProductSetSku(id: $id, sku: $sku) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetSkuGQL extends Apollo.Mutation<ListedProductSetSkuMutation, ListedProductSetSkuMutationVariables> {
    document = ListedProductSetSkuDocument;
    
  }
export const ListedProductSetSlugDocument = gql`
    mutation listedProductSetSlug($id: UUID!, $slug: String!) {
  listedProductSetSlug(id: $id, slug: $slug) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetSlugGQL extends Apollo.Mutation<ListedProductSetSlugMutation, ListedProductSetSlugMutationVariables> {
    document = ListedProductSetSlugDocument;
    
  }
export const ListedProductSetTitleDocument = gql`
    mutation listedProductSetTitle($id: UUID!, $title: String!) {
  listedProductSetTitle(id: $id, title: $title) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetTitleGQL extends Apollo.Mutation<ListedProductSetTitleMutation, ListedProductSetTitleMutationVariables> {
    document = ListedProductSetTitleDocument;
    
  }
export const ListedProductSetUpcDocument = gql`
    mutation listedProductSetUpc($id: UUID!, $upc: String) {
  listedProductSetUpc(id: $id, upc: $upc) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetUpcGQL extends Apollo.Mutation<ListedProductSetUpcMutation, ListedProductSetUpcMutationVariables> {
    document = ListedProductSetUpcDocument;
    
  }
export const ListedProductSetVisibleDocument = gql`
    mutation listedProductSetVisible($id: UUID!, $visible: Boolean!) {
  listedProductSetVisible(id: $id, visible: $visible) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetVisibleGQL extends Apollo.Mutation<ListedProductSetVisibleMutation, ListedProductSetVisibleMutationVariables> {
    document = ListedProductSetVisibleDocument;
    
  }
export const ListedProductSetWeightDocument = gql`
    mutation listedProductSetWeight($id: UUID!, $weight: BigDecimal!) {
  listedProductSetWeight(id: $id, weight: $weight) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductSetWeightGQL extends Apollo.Mutation<ListedProductSetWeightMutation, ListedProductSetWeightMutationVariables> {
    document = ListedProductSetWeightDocument;
    
  }
export const ListedProductUploadImageDocument = gql`
    mutation listedProductUploadImage($id: UUID!, $dataUrl: String!, $filename: String!, $keywords: String, $title: String) {
  listedProductUploadImage(
    id: $id
    dataUrl: $dataUrl
    filename: $filename
    keyWords: $keywords
    title: $title
  ) {
    ...ADMIN_LISTED_PRODUCT_ENTITY
  }
}
    ${Admin_Listed_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductUploadImageGQL extends Apollo.Mutation<ListedProductUploadImageMutation, ListedProductUploadImageMutationVariables> {
    document = ListedProductUploadImageDocument;
    
  }
export const ListedProductUploadListingImageDocument = gql`
    mutation listedProductUploadListingImage($id: UUID!, $dataUrl: String!, $filename: String!, $keywords: String, $title: String) {
  listedProductUploadListingImage(
    id: $id
    dataUrl: $dataUrl
    filename: $filename
    keyWords: $keywords
    title: $title
  ) {
    ...ADMIN_LISTING_ENTITY
  }
}
    ${Admin_Listing_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductUploadListingImageGQL extends Apollo.Mutation<ListedProductUploadListingImageMutation, ListedProductUploadListingImageMutationVariables> {
    document = ListedProductUploadListingImageDocument;
    
  }
export const ListedProductWithCrossSellsDocument = gql`
    query listedProductWithCrossSells($slug: String!) {
  listedProductBySlug(slug: $slug) {
    id
    cls
    sku
    slug
    title
    quantityAvailableForSale
    price
    salePrice
    medias {
      id
      mediaType
      url
    }
    shippingRuleSet {
      liveArrivalGuarantees {
        minTemp
        maxTemp
        transitTimeType
      }
    }
    crossSellItems {
      id
      position
      crossSellProduct {
        id
        cls
        active
        title
        sku
        slug
        countRating
        averageRating
        quantityAvailableForSale
        price
        salePrice
        variationKey
        featured
        medias {
          id
          mediaType
          url
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListedProductWithCrossSellsGQL extends Apollo.Query<ListedProductWithCrossSellsQuery, ListedProductWithCrossSellsQueryVariables> {
    document = ListedProductWithCrossSellsDocument;
    
  }
export const MakingStockStatusDocument = gql`
    mutation makingStockStatus($warehouse: String!, $departmentId: UUID, $requiresPrep: Boolean, $active: Boolean) {
  makingStockStatus(
    warehouse: $warehouse
    departmentId: $departmentId
    requiresPrep: $requiresPrep
    active: $active
  ) {
    product {
      id
      title
      sku
      medias {
        id
        mediaType
        url
      }
    }
    warehouseQuantityAvailable
    warehouseWeeklyConsumptionRate
    warehouseWeeklyConsumptionVariance
    warehouseRunOutDays50
    warehouseRunOutDays85
    warehouseRunOutDays97
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MakingStockStatusGQL extends Apollo.Mutation<MakingStockStatusMutation, MakingStockStatusMutationVariables> {
    document = MakingStockStatusDocument;
    
  }
export const MediaFilterDocument = gql`
    query mediaFilter($title: String, $keywords: String, $pageable: GraphQLPageableInput!) {
  mediaFilter(title: $title, page: $pageable, keywords: $keywords) {
    data {
      id
      keywords
      title
      url
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MediaFilterGQL extends Apollo.Query<MediaFilterQuery, MediaFilterQueryVariables> {
    document = MediaFilterDocument;
    
  }
export const MediaInfoDocument = gql`
    query mediaInfo($id: UUID!) {
  mediaInfo(id: $id) {
    ...ADMIN_MEDIA_ENTITY
  }
}
    ${Admin_Media_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MediaInfoGQL extends Apollo.Query<MediaInfoQuery, MediaInfoQueryVariables> {
    document = MediaInfoDocument;
    
  }
export const MediaSetKeywordsDocument = gql`
    mutation mediaSetKeywords($id: UUID!, $keywords: String) {
  mediaSetKeywords(id: $id, keywords: $keywords) {
    ...ADMIN_MEDIA_ENTITY
  }
}
    ${Admin_Media_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MediaSetKeywordsGQL extends Apollo.Mutation<MediaSetKeywordsMutation, MediaSetKeywordsMutationVariables> {
    document = MediaSetKeywordsDocument;
    
  }
export const MediaSetTitleDocument = gql`
    mutation mediaSetTitle($id: UUID!, $title: String!) {
  mediaSetTitle(id: $id, title: $title) {
    ...ADMIN_MEDIA_ENTITY
  }
}
    ${Admin_Media_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MediaSetTitleGQL extends Apollo.Mutation<MediaSetTitleMutation, MediaSetTitleMutationVariables> {
    document = MediaSetTitleDocument;
    
  }
export const MediaUploadImageDocument = gql`
    mutation mediaUploadImage($dataUrl: String!, $filename: String!, $keywords: String, $title: String) {
  mediaUploadImage(
    dataUrl: $dataUrl
    filename: $filename
    keyWords: $keywords
    title: $title
  ) {
    id
    keywords
    title
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MediaUploadImageGQL extends Apollo.Mutation<MediaUploadImageMutation, MediaUploadImageMutationVariables> {
    document = MediaUploadImageDocument;
    
  }
export const AffiliateAddProductListDocument = gql`
    mutation affiliateAddProductList($id: UUID!, $name: String!, $slug: String!, $htmlContent: String!) {
  affiliateAddProductList(
    id: $id
    name: $name
    slug: $slug
    htmlContent: $htmlContent
  ) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateAddProductListGQL extends Apollo.Mutation<AffiliateAddProductListMutation, AffiliateAddProductListMutationVariables> {
    document = AffiliateAddProductListDocument;
    
  }
export const AffiliateAddProductListItemsDocument = gql`
    mutation affiliateAddProductListItems($listId: UUID!, $productIds: [UUID]!) {
  affiliateAddProductListItems(listId: $listId, productIds: $productIds) {
    ...ADMIN_AFFILIATE_PRODUCT_LIST
  }
}
    ${Admin_Affiliate_Product_ListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateAddProductListItemsGQL extends Apollo.Mutation<AffiliateAddProductListItemsMutation, AffiliateAddProductListItemsMutationVariables> {
    document = AffiliateAddProductListItemsDocument;
    
  }
export const AffiliateBySlugDocument = gql`
    query affiliateBySlug($slug: String!) {
  affiliateBySlug(slug: $slug) {
    affiliateAttributionToken
    affiliateBiography
    name
    slug
    affiliateProductLists {
      name
      slug
      affiliateProductListItems {
        product {
          medias {
            url
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateBySlugGQL extends Apollo.Query<AffiliateBySlugQuery, AffiliateBySlugQueryVariables> {
    document = AffiliateBySlugDocument;
    
  }
export const AffiliateDeleteProductListDocument = gql`
    mutation affiliateDeleteProductList($id: UUID!, $listId: UUID!) {
  affiliateDeleteProductList(id: $id, listId: $listId) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateDeleteProductListGQL extends Apollo.Mutation<AffiliateDeleteProductListMutation, AffiliateDeleteProductListMutationVariables> {
    document = AffiliateDeleteProductListDocument;
    
  }
export const AffiliateGetProductListByIdDocument = gql`
    query affiliateGetProductListById($id: UUID!) {
  affiliateGetProductListById(id: $id) {
    ...ADMIN_AFFILIATE_PRODUCT_LIST
  }
}
    ${Admin_Affiliate_Product_ListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateGetProductListByIdGQL extends Apollo.Query<AffiliateGetProductListByIdQuery, AffiliateGetProductListByIdQueryVariables> {
    document = AffiliateGetProductListByIdDocument;
    
  }
export const AffiliateGetProductListBySlugDocument = gql`
    query affiliateGetProductListBySlug($slug: String!, $affiliateSlug: String!) {
  affiliateGetProductListBySlug(slug: $slug, affiliateSlug: $affiliateSlug) {
    htmlContent
    name
    slug
    affiliateProductListItems {
      product {
        cls
        price
        salePrice
        sku
        slug
        title
        quantityAvailableForSale
        medias {
          url
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateGetProductListBySlugGQL extends Apollo.Query<AffiliateGetProductListBySlugQuery, AffiliateGetProductListBySlugQueryVariables> {
    document = AffiliateGetProductListBySlugDocument;
    
  }
export const AffiliateRemoveProductListItemDocument = gql`
    mutation affiliateRemoveProductListItem($listId: UUID!, $affiliateProductListItemId: UUID!) {
  affiliateRemoveProductListItem(
    listId: $listId
    affiliateProductListItemId: $affiliateProductListItemId
  ) {
    ...ADMIN_AFFILIATE_PRODUCT_LIST
  }
}
    ${Admin_Affiliate_Product_ListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateRemoveProductListItemGQL extends Apollo.Mutation<AffiliateRemoveProductListItemMutation, AffiliateRemoveProductListItemMutationVariables> {
    document = AffiliateRemoveProductListItemDocument;
    
  }
export const AffiliateSetAffiliateBiographyDocument = gql`
    mutation affiliateSetAffiliateBiography($id: UUID!, $affiliateBiography: String!) {
  affiliateSetAffiliateBiography(id: $id, affiliateBiography: $affiliateBiography) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetAffiliateBiographyGQL extends Apollo.Mutation<AffiliateSetAffiliateBiographyMutation, AffiliateSetAffiliateBiographyMutationVariables> {
    document = AffiliateSetAffiliateBiographyDocument;
    
  }
export const AffiliateSetAttributionPeriodDocument = gql`
    mutation affiliateSetAttributionPeriod($id: UUID!, $period: Long!) {
  affiliateSetAttributionPeriod(id: $id, period: $period) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetAttributionPeriodGQL extends Apollo.Mutation<AffiliateSetAttributionPeriodMutation, AffiliateSetAttributionPeriodMutationVariables> {
    document = AffiliateSetAttributionPeriodDocument;
    
  }
export const AffiliateSetCommissionDocument = gql`
    mutation affiliateSetCommission($id: UUID!, $commission: BigDecimal!) {
  affiliateSetCommission(id: $id, commission: $commission) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetCommissionGQL extends Apollo.Mutation<AffiliateSetCommissionMutation, AffiliateSetCommissionMutationVariables> {
    document = AffiliateSetCommissionDocument;
    
  }
export const AffiliateSetProductListHtmlContentDocument = gql`
    mutation affiliateSetProductListHtmlContent($listId: UUID!, $htmlContent: String!) {
  affiliateSetProductListHtmlContent(listId: $listId, htmlContent: $htmlContent) {
    ...ADMIN_AFFILIATE_PRODUCT_LIST
  }
}
    ${Admin_Affiliate_Product_ListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetProductListHtmlContentGQL extends Apollo.Mutation<AffiliateSetProductListHtmlContentMutation, AffiliateSetProductListHtmlContentMutationVariables> {
    document = AffiliateSetProductListHtmlContentDocument;
    
  }
export const AffiliateSetProductListItemPositionDocument = gql`
    mutation affiliateSetProductListItemPosition($listId: UUID!, $affiliateProductListItemId: UUID!, $position: Int!) {
  affiliateSetProductListItemPosition(
    listId: $listId
    affiliateProductListItemId: $affiliateProductListItemId
    position: $position
  ) {
    ...ADMIN_AFFILIATE_PRODUCT_LIST
  }
}
    ${Admin_Affiliate_Product_ListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetProductListItemPositionGQL extends Apollo.Mutation<AffiliateSetProductListItemPositionMutation, AffiliateSetProductListItemPositionMutationVariables> {
    document = AffiliateSetProductListItemPositionDocument;
    
  }
export const AffiliateSetProductListNameDocument = gql`
    mutation affiliateSetProductListName($listId: UUID!, $name: String!) {
  affiliateSetProductListName(listId: $listId, name: $name) {
    ...ADMIN_AFFILIATE_PRODUCT_LIST
  }
}
    ${Admin_Affiliate_Product_ListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetProductListNameGQL extends Apollo.Mutation<AffiliateSetProductListNameMutation, AffiliateSetProductListNameMutationVariables> {
    document = AffiliateSetProductListNameDocument;
    
  }
export const AffiliateSetProductListSequenceDocument = gql`
    mutation affiliateSetProductListSequence($id: UUID!, $listId: UUID!, $sequence: Int!) {
  affiliateSetProductListSequence(id: $id, listId: $listId, sequence: $sequence) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetProductListSequenceGQL extends Apollo.Mutation<AffiliateSetProductListSequenceMutation, AffiliateSetProductListSequenceMutationVariables> {
    document = AffiliateSetProductListSequenceDocument;
    
  }
export const AffiliateSetProductListSlugDocument = gql`
    mutation affiliateSetProductListSlug($listId: UUID!, $slug: String!) {
  affiliateSetProductListSlug(listId: $listId, slug: $slug) {
    ...ADMIN_AFFILIATE_PRODUCT_LIST
  }
}
    ${Admin_Affiliate_Product_ListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetProductListSlugGQL extends Apollo.Mutation<AffiliateSetProductListSlugMutation, AffiliateSetProductListSlugMutationVariables> {
    document = AffiliateSetProductListSlugDocument;
    
  }
export const AffiliateSetSlugDocument = gql`
    mutation affiliateSetSlug($id: UUID!, $slug: String!) {
  affiliateSetSlug(id: $id, slug: $slug) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AffiliateSetSlugGQL extends Apollo.Mutation<AffiliateSetSlugMutation, AffiliateSetSlugMutationVariables> {
    document = AffiliateSetSlugDocument;
    
  }
export const PartnerJoinDocument = gql`
    mutation partnerJoin($firstName: String, $lastName: String, $company: String, $email: String!, $phone: String, $message: String) {
  partnerJoin(
    firstName: $firstName
    lastName: $lastName
    company: $company
    email: $email
    phone: $phone
    message: $message
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerJoinGQL extends Apollo.Mutation<PartnerJoinMutation, PartnerJoinMutationVariables> {
    document = PartnerJoinDocument;
    
  }
export const PartnerCreateDocument = gql`
    mutation partnerCreate($email: String!, $name: String!) {
  partnerCreate(email: $email, name: $name) {
    id
    affiliate
    dropshipper
    name
    reseller
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerCreateGQL extends Apollo.Mutation<PartnerCreateMutation, PartnerCreateMutationVariables> {
    document = PartnerCreateDocument;
    
  }
export const PartnerFilterDocument = gql`
    query partnerFilter($pageable: GraphQLPageableInput!) {
  partnerFilter(page: $pageable) {
    data {
      id
      affiliate
      dropshipper
      email
      name
      reseller
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerFilterGQL extends Apollo.Query<PartnerFilterQuery, PartnerFilterQueryVariables> {
    document = PartnerFilterDocument;
    
  }
export const PartnerInfoDocument = gql`
    query partnerInfo($id: UUID!) {
  partnerInfo(id: $id) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerInfoGQL extends Apollo.Query<PartnerInfoQuery, PartnerInfoQueryVariables> {
    document = PartnerInfoDocument;
    
  }
export const PartnerListDropshippersDocument = gql`
    query partnerListDropshippers {
  partnerListDropshippers {
    id
    affiliate
    dropshipper
    email
    name
    reseller
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerListDropshippersGQL extends Apollo.Query<PartnerListDropshippersQuery, PartnerListDropshippersQueryVariables> {
    document = PartnerListDropshippersDocument;
    
  }
export const PartnerListResellersDocument = gql`
    query partnerListResellers {
  partnerListResellers {
    id
    affiliate
    dropshipper
    email
    name
    reseller
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerListResellersGQL extends Apollo.Query<PartnerListResellersQuery, PartnerListResellersQueryVariables> {
    document = PartnerListResellersDocument;
    
  }
export const PartnerSetAffiliateDocument = gql`
    mutation partnerSetAffiliate($id: UUID!, $affiliate: Boolean!) {
  partnerSetAffiliate(id: $id, affiliate: $affiliate) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerSetAffiliateGQL extends Apollo.Mutation<PartnerSetAffiliateMutation, PartnerSetAffiliateMutationVariables> {
    document = PartnerSetAffiliateDocument;
    
  }
export const PartnerSetDropshipperDocument = gql`
    mutation partnerSetDropshipper($id: UUID!, $dropshipper: Boolean!) {
  partnerSetDropshipper(id: $id, dropshipper: $dropshipper) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerSetDropshipperGQL extends Apollo.Mutation<PartnerSetDropshipperMutation, PartnerSetDropshipperMutationVariables> {
    document = PartnerSetDropshipperDocument;
    
  }
export const PartnerSetEmailDocument = gql`
    mutation partnerSetEmail($id: UUID!, $email: String!) {
  partnerSetEmail(id: $id, email: $email) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerSetEmailGQL extends Apollo.Mutation<PartnerSetEmailMutation, PartnerSetEmailMutationVariables> {
    document = PartnerSetEmailDocument;
    
  }
export const PartnerSetNameDocument = gql`
    mutation partnerSetName($id: UUID!, $name: String!) {
  partnerSetName(id: $id, name: $name) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerSetNameGQL extends Apollo.Mutation<PartnerSetNameMutation, PartnerSetNameMutationVariables> {
    document = PartnerSetNameDocument;
    
  }
export const PartnerSetResellerDocument = gql`
    mutation partnerSetReseller($id: UUID!, $reseller: Boolean!) {
  partnerSetReseller(id: $id, reseller: $reseller) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerSetResellerGQL extends Apollo.Mutation<PartnerSetResellerMutation, PartnerSetResellerMutationVariables> {
    document = PartnerSetResellerDocument;
    
  }
export const PartnerUpdateAddressDocument = gql`
    mutation partnerUpdateAddress($id: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!, $residential: Boolean, $addressType: AddressType) {
  partnerUpdateAddress(
    id: $id
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
    residential: $residential
    addressType: $addressType
  ) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerUpdateAddressGQL extends Apollo.Mutation<PartnerUpdateAddressMutation, PartnerUpdateAddressMutationVariables> {
    document = PartnerUpdateAddressDocument;
    
  }
export const ResellerSetResellerShippingRateModeDocument = gql`
    mutation resellerSetResellerShippingRateMode($id: UUID!, $resellerShippingRateMode: ResellerShippingRateMode!) {
  resellerSetResellerShippingRateMode(
    id: $id
    resellerShippingRateMode: $resellerShippingRateMode
  ) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResellerSetResellerShippingRateModeGQL extends Apollo.Mutation<ResellerSetResellerShippingRateModeMutation, ResellerSetResellerShippingRateModeMutationVariables> {
    document = ResellerSetResellerShippingRateModeDocument;
    
  }
export const ResellerSetResellerTaxModeDocument = gql`
    mutation resellerSetResellerTaxMode($id: UUID!, $resellerTaxMode: ResellerTaxMode!) {
  resellerSetResellerTaxMode(id: $id, resellerTaxMode: $resellerTaxMode) {
    ...ADMIN_PARTNER_ENTITY
  }
}
    ${Admin_Partner_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResellerSetResellerTaxModeGQL extends Apollo.Mutation<ResellerSetResellerTaxModeMutation, ResellerSetResellerTaxModeMutationVariables> {
    document = ResellerSetResellerTaxModeDocument;
    
  }
export const DropshipperProfileCreateDocument = gql`
    mutation dropshipperProfileCreate($simpleProductId: UUID!, $dropshipperId: UUID!, $sku: String!, $title: String!, $cost: BigDecimal!) {
  dropshipperProfileCreate(
    simpleProductId: $simpleProductId
    dropshipperId: $dropshipperId
    sku: $sku
    title: $title
    cost: $cost
  ) {
    id
    dropshipperSku
    title
    cost
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DropshipperProfileCreateGQL extends Apollo.Mutation<DropshipperProfileCreateMutation, DropshipperProfileCreateMutationVariables> {
    document = DropshipperProfileCreateDocument;
    
  }
export const PetcoDsProfileCreateDocument = gql`
    mutation petcoDsProfileCreate($productId: UUID!, $sku: String!, $description: String!, $surcharge: BigDecimal!) {
  petcoDsProfileCreate(
    productId: $productId
    sku: $sku
    description: $description
    surcharge: $surcharge
  ) {
    id
    sku
    description
    surcharge
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PetcoDsProfileCreateGQL extends Apollo.Mutation<PetcoDsProfileCreateMutation, PetcoDsProfileCreateMutationVariables> {
    document = PetcoDsProfileCreateDocument;
    
  }
export const PetcoSdProfileCreateDocument = gql`
    mutation petcoSdProfileCreate($productId: UUID!, $sku: String!, $description: String!) {
  petcoSdProfileCreate(
    productId: $productId
    sku: $sku
    description: $description
  ) {
    id
    sku
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PetcoSdProfileCreateGQL extends Apollo.Mutation<PetcoSdProfileCreateMutation, PetcoSdProfileCreateMutationVariables> {
    document = PetcoSdProfileCreateDocument;
    
  }
export const ResellerProfileCreateDocument = gql`
    mutation resellerProfileCreate($productId: UUID!, $resellerId: UUID!, $sku: String!, $title: String!, $price: BigDecimal!, $retail: BigDecimal) {
  resellerProfileCreate(
    productId: $productId
    resellerId: $resellerId
    sku: $sku
    title: $title
    price: $price
    retail: $retail
  ) {
    id
    resellerSku
    title
    price
    retail
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResellerProfileCreateGQL extends Apollo.Mutation<ResellerProfileCreateMutation, ResellerProfileCreateMutationVariables> {
    document = ResellerProfileCreateDocument;
    
  }
export const ProductAddCategoryDocument = gql`
    mutation productAddCategory($productId: UUID!, $categoryId: UUID!) {
  productAddCategory(productId: $productId, categoryId: $categoryId) {
    id
    explicitCategories {
      id
      slug
      path
      name
      parent {
        id
        slug
        path
        name
        parent {
          id
          slug
          path
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductAddCategoryGQL extends Apollo.Mutation<ProductAddCategoryMutation, ProductAddCategoryMutationVariables> {
    document = ProductAddCategoryDocument;
    
  }
export const ProductBySlugDocument = gql`
    query productBySlug($slug: String!) {
  productBySlug(slug: $slug) {
    id
    cls
    slug
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductBySlugGQL extends Apollo.Query<ProductBySlugQuery, ProductBySlugQueryVariables> {
    document = ProductBySlugDocument;
    
  }
export const ProductFilterDocument = gql`
    query productFilter($pageable: GraphQLPageableInput!, $active: Boolean, $animal: String, $category: String, $department: String, $minRating: Long, $minStock: Long, $sku: String, $subscription: Boolean, $title: String, $upc: String, $visible: Boolean) {
  productFilter(
    page: $pageable
    active: $active
    animal: $animal
    category: $category
    department: $department
    minRating: $minRating
    minStock: $minStock
    sku: $sku
    subscription: $subscription
    title: $title
    upc: $upc
    visible: $visible
  ) {
    data {
      id
      cls
      slug
      title
      sku
      upc
      price
      quantityAvailableForSale
      subscription
      shippingRuleSet {
        liveArrivalGuarantees {
          id
        }
        destinationRestriction {
          domesticOnly
          prohibitedStates
        }
      }
      medias {
        id
        mediaType
        url
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductFilterGQL extends Apollo.Query<ProductFilterQuery, ProductFilterQueryVariables> {
    document = ProductFilterDocument;
    
  }
export const ProductListBySkusDocument = gql`
    query productListBySkus($skus: [String]!) {
  productListBySkus(skus: $skus) {
    id
    cls
    sku
    title
    price
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductListBySkusGQL extends Apollo.Query<ProductListBySkusQuery, ProductListBySkusQueryVariables> {
    document = ProductListBySkusDocument;
    
  }
export const ProductListBySkusForPedestalDocument = gql`
    query productListBySkusForPedestal($skus: [String]!) {
  productListBySkus(skus: $skus) {
    id
    cls
    active
    sku
    slug
    title
    price
    quantityAvailableForSale
    medias {
      url
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductListBySkusForPedestalGQL extends Apollo.Query<ProductListBySkusForPedestalQuery, ProductListBySkusForPedestalQueryVariables> {
    document = ProductListBySkusForPedestalDocument;
    
  }
export const ProductListBySkusForSpotlightDocument = gql`
    query productListBySkusForSpotlight($skus: [String]!) {
  productListBySkus(skus: $skus) {
    id
    cls
    active
    sku
    slug
    title
    price
    quantityAvailableForSale
    medias {
      url
    }
    explicitCategories {
      active
      wholesale
      name
      path
      slug
      ancestors {
        active
        wholesale
        name
        path
        slug
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductListBySkusForSpotlightGQL extends Apollo.Query<ProductListBySkusForSpotlightQuery, ProductListBySkusForSpotlightQueryVariables> {
    document = ProductListBySkusForSpotlightDocument;
    
  }
export const ProductRemoveCategoryDocument = gql`
    mutation productRemoveCategory($productId: UUID!, $categoryId: UUID!) {
  productRemoveCategory(productId: $productId, categoryId: $categoryId) {
    id
    explicitCategories {
      id
      slug
      path
      name
      parent {
        id
        slug
        path
        name
        parent {
          id
          slug
          path
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductRemoveCategoryGQL extends Apollo.Mutation<ProductRemoveCategoryMutation, ProductRemoveCategoryMutationVariables> {
    document = ProductRemoveCategoryDocument;
    
  }
export const ProductRequestBackInStockNotificationDocument = gql`
    mutation productRequestBackInStockNotification($id: UUID!, $email: String, $phone: String) {
  productRequestBackInStockNotification(id: $id, email: $email, phone: $phone)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductRequestBackInStockNotificationGQL extends Apollo.Mutation<ProductRequestBackInStockNotificationMutation, ProductRequestBackInStockNotificationMutationVariables> {
    document = ProductRequestBackInStockNotificationDocument;
    
  }
export const ProductSearchDocument = gql`
    query productSearch($pageable: GraphQLPageableInput!, $query: String, $category: String, $discount: String, $promotion: String, $animal: String, $brand: String, $featured: Boolean, $inStock: Boolean, $subscription: Boolean, $minPrice: BigDecimal, $maxPrice: BigDecimal, $minRating: Float, $sort: GraphQLSortInput) {
  productSearch(
    page: $pageable
    query: $query
    category: $category
    discount: $discount
    promotion: $promotion
    animal: $animal
    brand: $brand
    featured: {value: $featured}
    inStock: {value: $inStock}
    subscription: {value: $subscription}
    price: {from: $minPrice, to: $maxPrice}
    rating: {from: $minRating, to: null}
    sort: $sort
  ) {
    category {
      id
      slug
      path
      name
      parent {
        id
        slug
        path
        name
        children {
          id
          slug
          path
          name
        }
      }
      children {
        id
        active
        slug
        path
        name
      }
    }
    animal {
      id
      slug
      name
      parent {
        id
        slug
        name
        children {
          id
          slug
          name
        }
      }
      children {
        id
        slug
        name
      }
    }
    brand {
      id
      slug
      name
    }
    products {
      data {
        id
        cls
        slug
        sku
        title
        quantityAvailableForSale
        price
        salePrice
        averageRating
        countRating
        featured
        medias {
          id
          mediaType
          url
        }
      }
      count
      page
      pageSize
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductSearchGQL extends Apollo.Query<ProductSearchQuery, ProductSearchQueryVariables> {
    document = ProductSearchDocument;
    
  }
export const ProductSetDepartmentDocument = gql`
    mutation productSetDepartment($productId: UUID!, $departmentId: UUID!) {
  productSetDepartment(productId: $productId, departmentId: $departmentId) {
    id
    explicitDepartment {
      id
      slug
      name
      parent {
        id
        slug
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductSetDepartmentGQL extends Apollo.Mutation<ProductSetDepartmentMutation, ProductSetDepartmentMutationVariables> {
    document = ProductSetDepartmentDocument;
    
  }
export const PromotionAddBrandsDocument = gql`
    mutation promotionAddBrands($id: UUID!, $brandIds: [UUID]!) {
  promotionAddBrands(id: $id, brandIds: $brandIds) {
    ...ADMIN_PROMOTION
    ...ADMIN_PROMOTION_LISTS
  }
}
    ${Admin_PromotionFragmentDoc}
${Admin_Promotion_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionAddBrandsGQL extends Apollo.Mutation<PromotionAddBrandsMutation, PromotionAddBrandsMutationVariables> {
    document = PromotionAddBrandsDocument;
    
  }
export const PromotionAddCategoriesDocument = gql`
    mutation promotionAddCategories($id: UUID!, $categoryIds: [UUID]!) {
  promotionAddCategories(id: $id, categoryIds: $categoryIds) {
    ...ADMIN_PROMOTION
    ...ADMIN_PROMOTION_LISTS
  }
}
    ${Admin_PromotionFragmentDoc}
${Admin_Promotion_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionAddCategoriesGQL extends Apollo.Mutation<PromotionAddCategoriesMutation, PromotionAddCategoriesMutationVariables> {
    document = PromotionAddCategoriesDocument;
    
  }
export const PromotionAddDepartmentsDocument = gql`
    mutation promotionAddDepartments($id: UUID!, $departmentIds: [UUID]!) {
  promotionAddDepartments(id: $id, departmentIds: $departmentIds) {
    ...ADMIN_PROMOTION
    ...ADMIN_PROMOTION_LISTS
  }
}
    ${Admin_PromotionFragmentDoc}
${Admin_Promotion_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionAddDepartmentsGQL extends Apollo.Mutation<PromotionAddDepartmentsMutation, PromotionAddDepartmentsMutationVariables> {
    document = PromotionAddDepartmentsDocument;
    
  }
export const PromotionAddExplicitProductsDocument = gql`
    mutation promotionAddExplicitProducts($id: UUID!, $explicitProductIds: [UUID]!) {
  promotionAddExplicitProducts(id: $id, explicitProductIds: $explicitProductIds) {
    ...ADMIN_PROMOTION
    ...ADMIN_PROMOTION_LISTS
  }
}
    ${Admin_PromotionFragmentDoc}
${Admin_Promotion_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionAddExplicitProductsGQL extends Apollo.Mutation<PromotionAddExplicitProductsMutation, PromotionAddExplicitProductsMutationVariables> {
    document = PromotionAddExplicitProductsDocument;
    
  }
export const PromotionBySlugDocument = gql`
    query promotionBySlug($slug: String!) {
  promotionBySlug(slug: $slug) {
    ...CUSTOMER_PROMOTION
  }
}
    ${Customer_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionBySlugGQL extends Apollo.Query<PromotionBySlugQuery, PromotionBySlugQueryVariables> {
    document = PromotionBySlugDocument;
    
  }
export const PromotionCloneDocument = gql`
    mutation promotionClone($id: UUID!, $name: String!, $slug: String!) {
  promotionClone(id: $id, name: $name, slug: $slug) {
    ...ADMIN_PROMOTION
    ...ADMIN_PROMOTION_LISTS
  }
}
    ${Admin_PromotionFragmentDoc}
${Admin_Promotion_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionCloneGQL extends Apollo.Mutation<PromotionCloneMutation, PromotionCloneMutationVariables> {
    document = PromotionCloneDocument;
    
  }
export const PromotionCreateDocument = gql`
    mutation promotionCreate($name: String!, $slug: String!, $promotionType: PromotionType!, $permitStacking: Boolean!, $startDate: Instant, $endDate: Instant, $categoryIds: [UUID], $departmentIds: [UUID], $eligibleUserType: UserType, $eligibleCouponCode: String, $eligibleCartValue: BigDecimal, $eligibleItemValue: BigDecimal, $eligibleItemCount: Long, $discountAmount: BigDecimal) {
  promotionCreate(
    name: $name
    slug: $slug
    promotionType: $promotionType
    permitStacking: $permitStacking
    startDate: $startDate
    endDate: $endDate
    categoryIds: $categoryIds
    departmentIds: $departmentIds
    eligibleUserType: $eligibleUserType
    eligibleCouponCode: $eligibleCouponCode
    eligibleCartValue: $eligibleCartValue
    eligibleItemValue: $eligibleItemValue
    eligibleItemCount: $eligibleItemCount
    discountAmount: $discountAmount
  ) {
    ...ADMIN_PROMOTION
    ...ADMIN_PROMOTION_LISTS
  }
}
    ${Admin_PromotionFragmentDoc}
${Admin_Promotion_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionCreateGQL extends Apollo.Mutation<PromotionCreateMutation, PromotionCreateMutationVariables> {
    document = PromotionCreateDocument;
    
  }
export const PromotionDeleteDocument = gql`
    mutation promotionDelete($id: UUID!) {
  promotionDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionDeleteGQL extends Apollo.Mutation<PromotionDeleteMutation, PromotionDeleteMutationVariables> {
    document = PromotionDeleteDocument;
    
  }
export const PromotionEvaluateImplicitProductsDocument = gql`
    mutation promotionEvaluateImplicitProducts($id: UUID!) {
  promotionEvaluateImplicitProducts(id: $id) {
    ...ADMIN_PROMOTION
    ...ADMIN_PROMOTION_LISTS
  }
}
    ${Admin_PromotionFragmentDoc}
${Admin_Promotion_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionEvaluateImplicitProductsGQL extends Apollo.Mutation<PromotionEvaluateImplicitProductsMutation, PromotionEvaluateImplicitProductsMutationVariables> {
    document = PromotionEvaluateImplicitProductsDocument;
    
  }
export const PromotionFilterImplicitProductsDocument = gql`
    query promotionFilterImplicitProducts($id: UUID!, $page: GraphQLPageableInput!, $sku: String, $title: String) {
  promotionFilterImplicitProducts(id: $id, page: $page, sku: $sku, title: $title) {
    count
    page
    pageSize
    data {
      id
      sku
      title
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionFilterImplicitProductsGQL extends Apollo.Query<PromotionFilterImplicitProductsQuery, PromotionFilterImplicitProductsQueryVariables> {
    document = PromotionFilterImplicitProductsDocument;
    
  }
export const PromotionInfoDocument = gql`
    query promotionInfo($id: UUID!) {
  promotionInfo(id: $id) {
    ...ADMIN_PROMOTION
    ...ADMIN_PROMOTION_LISTS
  }
}
    ${Admin_PromotionFragmentDoc}
${Admin_Promotion_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionInfoGQL extends Apollo.Query<PromotionInfoQuery, PromotionInfoQueryVariables> {
    document = PromotionInfoDocument;
    
  }
export const PromotionListDocument = gql`
    query promotionList($pageable: GraphQLPageableInput) {
  promotionList(page: $pageable) {
    count
    page
    pageSize
    data {
      id
      active
      discountAmount
      promotionType
      eligibleCartValue
      eligibleCouponCode
      eligibleItemCount
      eligibleItemValue
      eligibleUserType
      endDate
      name
      permitStacking
      slug
      startDate
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionListGQL extends Apollo.Query<PromotionListQuery, PromotionListQueryVariables> {
    document = PromotionListDocument;
    
  }
export const PromotionRemoveBrandsDocument = gql`
    mutation promotionRemoveBrands($id: UUID!, $brandIds: [UUID]!) {
  promotionRemoveBrands(id: $id, brandIds: $brandIds) {
    ...ADMIN_PROMOTION
    ...ADMIN_PROMOTION_LISTS
  }
}
    ${Admin_PromotionFragmentDoc}
${Admin_Promotion_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionRemoveBrandsGQL extends Apollo.Mutation<PromotionRemoveBrandsMutation, PromotionRemoveBrandsMutationVariables> {
    document = PromotionRemoveBrandsDocument;
    
  }
export const PromotionRemoveCategoriesDocument = gql`
    mutation promotionRemoveCategories($id: UUID!, $categoryIds: [UUID]!) {
  promotionRemoveCategories(id: $id, categoryIds: $categoryIds) {
    ...ADMIN_PROMOTION
    ...ADMIN_PROMOTION_LISTS
  }
}
    ${Admin_PromotionFragmentDoc}
${Admin_Promotion_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionRemoveCategoriesGQL extends Apollo.Mutation<PromotionRemoveCategoriesMutation, PromotionRemoveCategoriesMutationVariables> {
    document = PromotionRemoveCategoriesDocument;
    
  }
export const PromotionRemoveDepartmentsDocument = gql`
    mutation promotionRemoveDepartments($id: UUID!, $departmentIds: [UUID]!) {
  promotionRemoveDepartments(id: $id, departmentIds: $departmentIds) {
    ...ADMIN_PROMOTION
    ...ADMIN_PROMOTION_LISTS
  }
}
    ${Admin_PromotionFragmentDoc}
${Admin_Promotion_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionRemoveDepartmentsGQL extends Apollo.Mutation<PromotionRemoveDepartmentsMutation, PromotionRemoveDepartmentsMutationVariables> {
    document = PromotionRemoveDepartmentsDocument;
    
  }
export const PromotionRemoveExplicitProductsDocument = gql`
    mutation promotionRemoveExplicitProducts($id: UUID!, $explicitProductIds: [UUID]!) {
  promotionRemoveExplicitProducts(
    id: $id
    explicitProductIds: $explicitProductIds
  ) {
    ...ADMIN_PROMOTION
    ...ADMIN_PROMOTION_LISTS
  }
}
    ${Admin_PromotionFragmentDoc}
${Admin_Promotion_ListsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionRemoveExplicitProductsGQL extends Apollo.Mutation<PromotionRemoveExplicitProductsMutation, PromotionRemoveExplicitProductsMutationVariables> {
    document = PromotionRemoveExplicitProductsDocument;
    
  }
export const PromotionSetActiveDocument = gql`
    mutation promotionSetActive($id: UUID!, $active: Boolean!) {
  promotionSetActive(id: $id, active: $active) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetActiveGQL extends Apollo.Mutation<PromotionSetActiveMutation, PromotionSetActiveMutationVariables> {
    document = PromotionSetActiveDocument;
    
  }
export const PromotionSetDiscountAmountDocument = gql`
    mutation promotionSetDiscountAmount($id: UUID!, $discountAmount: BigDecimal!) {
  promotionSetDiscountAmount(id: $id, discountAmount: $discountAmount) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetDiscountAmountGQL extends Apollo.Mutation<PromotionSetDiscountAmountMutation, PromotionSetDiscountAmountMutationVariables> {
    document = PromotionSetDiscountAmountDocument;
    
  }
export const PromotionSetEligibleCartValueDocument = gql`
    mutation promotionSetEligibleCartValue($id: UUID!, $eligibleCartValue: BigDecimal) {
  promotionSetEligibleCartValue(id: $id, eligibleCartValue: $eligibleCartValue) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetEligibleCartValueGQL extends Apollo.Mutation<PromotionSetEligibleCartValueMutation, PromotionSetEligibleCartValueMutationVariables> {
    document = PromotionSetEligibleCartValueDocument;
    
  }
export const PromotionSetEligibleCouponCodeDocument = gql`
    mutation promotionSetEligibleCouponCode($id: UUID!, $eligibleCouponCode: String) {
  promotionSetEligibleCouponCode(id: $id, eligibleCouponCode: $eligibleCouponCode) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetEligibleCouponCodeGQL extends Apollo.Mutation<PromotionSetEligibleCouponCodeMutation, PromotionSetEligibleCouponCodeMutationVariables> {
    document = PromotionSetEligibleCouponCodeDocument;
    
  }
export const PromotionSetEligibleItemCountDocument = gql`
    mutation promotionSetEligibleItemCount($id: UUID!, $eligibleItemCount: Long) {
  promotionSetEligibleItemCount(id: $id, eligibleItemCount: $eligibleItemCount) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetEligibleItemCountGQL extends Apollo.Mutation<PromotionSetEligibleItemCountMutation, PromotionSetEligibleItemCountMutationVariables> {
    document = PromotionSetEligibleItemCountDocument;
    
  }
export const PromotionSetEligibleItemValueDocument = gql`
    mutation promotionSetEligibleItemValue($id: UUID!, $eligibleItemValue: BigDecimal) {
  promotionSetEligibleItemValue(id: $id, eligibleItemValue: $eligibleItemValue) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetEligibleItemValueGQL extends Apollo.Mutation<PromotionSetEligibleItemValueMutation, PromotionSetEligibleItemValueMutationVariables> {
    document = PromotionSetEligibleItemValueDocument;
    
  }
export const PromotionSetEligibleUserTypeDocument = gql`
    mutation promotionSetEligibleUserType($id: UUID!, $eligibleUserType: UserType) {
  promotionSetEligibleUserType(id: $id, eligibleUserType: $eligibleUserType) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetEligibleUserTypeGQL extends Apollo.Mutation<PromotionSetEligibleUserTypeMutation, PromotionSetEligibleUserTypeMutationVariables> {
    document = PromotionSetEligibleUserTypeDocument;
    
  }
export const PromotionSetEndDateDocument = gql`
    mutation promotionSetEndDate($id: UUID!, $endDate: Instant) {
  promotionSetEndDate(id: $id, endDate: $endDate) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetEndDateGQL extends Apollo.Mutation<PromotionSetEndDateMutation, PromotionSetEndDateMutationVariables> {
    document = PromotionSetEndDateDocument;
    
  }
export const PromotionSetHtmlContentDocument = gql`
    mutation promotionSetHtmlContent($id: UUID!, $htmlContent: String) {
  promotionSetHtmlContent(id: $id, htmlContent: $htmlContent) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetHtmlContentGQL extends Apollo.Mutation<PromotionSetHtmlContentMutation, PromotionSetHtmlContentMutationVariables> {
    document = PromotionSetHtmlContentDocument;
    
  }
export const PromotionSetNameDocument = gql`
    mutation promotionSetName($id: UUID!, $name: String!) {
  promotionSetName(id: $id, name: $name) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetNameGQL extends Apollo.Mutation<PromotionSetNameMutation, PromotionSetNameMutationVariables> {
    document = PromotionSetNameDocument;
    
  }
export const PromotionSetPermitStackingDocument = gql`
    mutation promotionSetPermitStacking($id: UUID!, $permitStacking: Boolean!) {
  promotionSetPermitStacking(id: $id, permitStacking: $permitStacking) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetPermitStackingGQL extends Apollo.Mutation<PromotionSetPermitStackingMutation, PromotionSetPermitStackingMutationVariables> {
    document = PromotionSetPermitStackingDocument;
    
  }
export const PromotionSetPromotionTypeDocument = gql`
    mutation promotionSetPromotionType($id: UUID!, $promotionType: PromotionType!) {
  promotionSetPromotionType(id: $id, promotionType: $promotionType) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetPromotionTypeGQL extends Apollo.Mutation<PromotionSetPromotionTypeMutation, PromotionSetPromotionTypeMutationVariables> {
    document = PromotionSetPromotionTypeDocument;
    
  }
export const PromotionSetSlugDocument = gql`
    mutation promotionSetSlug($id: UUID!, $slug: String!) {
  promotionSetSlug(id: $id, slug: $slug) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetSlugGQL extends Apollo.Mutation<PromotionSetSlugMutation, PromotionSetSlugMutationVariables> {
    document = PromotionSetSlugDocument;
    
  }
export const PromotionSetStartDateDocument = gql`
    mutation promotionSetStartDate($id: UUID!, $startDate: Instant) {
  promotionSetStartDate(id: $id, startDate: $startDate) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetStartDateGQL extends Apollo.Mutation<PromotionSetStartDateMutation, PromotionSetStartDateMutationVariables> {
    document = PromotionSetStartDateDocument;
    
  }
export const PromotionSetTeaserDocument = gql`
    mutation promotionSetTeaser($id: UUID!, $teaser: String) {
  promotionSetTeaser(id: $id, teaser: $teaser) {
    ...ADMIN_PROMOTION
  }
}
    ${Admin_PromotionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionSetTeaserGQL extends Apollo.Mutation<PromotionSetTeaserMutation, PromotionSetTeaserMutationVariables> {
    document = PromotionSetTeaserDocument;
    
  }
export const PurchaseOrderCloseDocument = gql`
    mutation purchaseOrderClose($id: UUID!) {
  purchaseOrderClose(id: $id) {
    id
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderCloseGQL extends Apollo.Mutation<PurchaseOrderCloseMutation, PurchaseOrderCloseMutationVariables> {
    document = PurchaseOrderCloseDocument;
    
  }
export const PurchaseOrderDeleteItemDocument = gql`
    mutation purchaseOrderDeleteItem($purchaseOrderId: UUID!, $purchaseOrderItemId: UUID!) {
  purchaseOrderDeleteItem(
    purchaseOrderId: $purchaseOrderId
    purchaseOrderItemId: $purchaseOrderItemId
  ) {
    id
    purchaseOrderItems {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderDeleteItemGQL extends Apollo.Mutation<PurchaseOrderDeleteItemMutation, PurchaseOrderDeleteItemMutationVariables> {
    document = PurchaseOrderDeleteItemDocument;
    
  }
export const PurchaseOrderEditItemQtyDocument = gql`
    mutation purchaseOrderEditItemQty($purchaseOrderId: UUID!, $purchaseOrderItemId: UUID!, $quantity: Long!) {
  purchaseOrderEditItemQty(
    purchaseOrderId: $purchaseOrderId
    purchaseOrderItemId: $purchaseOrderItemId
    quantity: $quantity
  ) {
    id
    purchaseOrderItems {
      id
      total
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderEditItemQtyGQL extends Apollo.Mutation<PurchaseOrderEditItemQtyMutation, PurchaseOrderEditItemQtyMutationVariables> {
    document = PurchaseOrderEditItemQtyDocument;
    
  }
export const PurchaseOrderFilterDocument = gql`
    query purchaseOrderFilter($orderNumber: String, $supplier: String, $status: PurchaseOrderStatus, $sort: GraphQLSortInput, $pageable: GraphQLPageableInput!) {
  purchaseOrderFilter(
    orderNumber: {pattern: $orderNumber}
    status: $status
    supplier: $supplier
    sort: $sort
    page: $pageable
  ) {
    count
    page
    pageSize
    data {
      id
      cls
      placedTime
      orderNumber
      status
      supplier {
        name
      }
      warehouse {
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderFilterGQL extends Apollo.Query<PurchaseOrderFilterQuery, PurchaseOrderFilterQueryVariables> {
    document = PurchaseOrderFilterDocument;
    
  }
export const PurchaseOrderGenerateCsvDocument = gql`
    mutation purchaseOrderGenerateCSV($id: UUID!) {
  purchaseOrderGenerateCSV(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderGenerateCsvGQL extends Apollo.Mutation<PurchaseOrderGenerateCsvMutation, PurchaseOrderGenerateCsvMutationVariables> {
    document = PurchaseOrderGenerateCsvDocument;
    
  }
export const PurchaseOrderGeneratePdfDocument = gql`
    mutation purchaseOrderGeneratePDF($id: UUID!) {
  purchaseOrderGeneratePDF(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderGeneratePdfGQL extends Apollo.Mutation<PurchaseOrderGeneratePdfMutation, PurchaseOrderGeneratePdfMutationVariables> {
    document = PurchaseOrderGeneratePdfDocument;
    
  }
export const PurchaseOrderInfoDocument = gql`
    query purchaseOrderInfo($id: UUID!) {
  purchaseOrderInfo(id: $id) {
    cls
    id
    orderNumber
    alternateOrderNumber
    placedTime
    expectedTime
    status
    purchaseOrderItems {
      cls
      id
      name
      sku
      received
      total
      simpleProduct {
        id
      }
    }
    supplier {
      name
      phone
      email
      firstName
      lastName
      company
      line1
      line2
      state
      postalCode
      country
    }
    warehouse {
      name
      line1
      line2
      state
      postalCode
      country
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderInfoGQL extends Apollo.Query<PurchaseOrderInfoQuery, PurchaseOrderInfoQueryVariables> {
    document = PurchaseOrderInfoDocument;
    
  }
export const PurchaseOrderReOpenDocument = gql`
    mutation purchaseOrderReOpen($id: UUID!) {
  purchaseOrderReOpen(id: $id) {
    id
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseOrderReOpenGQL extends Apollo.Mutation<PurchaseOrderReOpenMutation, PurchaseOrderReOpenMutationVariables> {
    document = PurchaseOrderReOpenDocument;
    
  }
export const PurchaseRequestApproveDocument = gql`
    mutation purchaseRequestApprove($id: UUID!) {
  purchaseRequestApprove(id: $id) {
    id
    requestNumber
    purchaseRequestStatus
    team {
      id
      name
      slug
    }
    requester
    approver
    orderer
    receiver
    supplier
    requestedDate
    orderedDate
    receivedDate
    estimatedAmount
    finalAmount
    comments
    purchaseRequestItems {
      id
      quantity
      name
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseRequestApproveGQL extends Apollo.Mutation<PurchaseRequestApproveMutation, PurchaseRequestApproveMutationVariables> {
    document = PurchaseRequestApproveDocument;
    
  }
export const PurchaseRequestCreateDocument = gql`
    mutation purchaseRequestCreate($team: String!, $supplier: String, $comments: String, $items: [PurchaseRequestItemInput]) {
  purchaseRequestCreate(
    team: $team
    supplier: $supplier
    comments: $comments
    items: $items
  ) {
    id
    requestNumber
    purchaseRequestStatus
    team {
      id
      name
      slug
    }
    requester
    approver
    orderer
    receiver
    supplier
    requestedDate
    orderedDate
    receivedDate
    estimatedAmount
    finalAmount
    comments
    purchaseRequestItems {
      id
      quantity
      name
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseRequestCreateGQL extends Apollo.Mutation<PurchaseRequestCreateMutation, PurchaseRequestCreateMutationVariables> {
    document = PurchaseRequestCreateDocument;
    
  }
export const PurchaseRequestDenyDocument = gql`
    mutation purchaseRequestDeny($id: UUID!) {
  purchaseRequestDeny(id: $id) {
    id
    requestNumber
    purchaseRequestStatus
    team {
      id
      name
      slug
    }
    requester
    approver
    orderer
    receiver
    supplier
    requestedDate
    orderedDate
    receivedDate
    estimatedAmount
    finalAmount
    comments
    purchaseRequestItems {
      id
      quantity
      name
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseRequestDenyGQL extends Apollo.Mutation<PurchaseRequestDenyMutation, PurchaseRequestDenyMutationVariables> {
    document = PurchaseRequestDenyDocument;
    
  }
export const PurchaseRequestFilterDocument = gql`
    query purchaseRequestFilter($requestNumber: String, $pageable: GraphQLPageableInput!, $team: String, $status: PurchaseRequestStatus, $sort: GraphQLSortInput) {
  purchaseRequestFilter(
    requestNumber: {pattern: $requestNumber}
    team: $team
    status: $status
    page: $pageable
    sort: $sort
  ) {
    data {
      id
      requestNumber
      purchaseRequestStatus
      team {
        id
        name
        slug
      }
      requester
      approver
      orderer
      receiver
      supplier
      requestedDate
      orderedDate
      receivedDate
      estimatedAmount
      finalAmount
      comments
      purchaseRequestItems {
        id
        quantity
        name
        amount
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseRequestFilterGQL extends Apollo.Query<PurchaseRequestFilterQuery, PurchaseRequestFilterQueryVariables> {
    document = PurchaseRequestFilterDocument;
    
  }
export const PurchaseRequestInfoDocument = gql`
    query purchaseRequestInfo($id: UUID!) {
  purchaseRequestInfo(id: $id) {
    id
    requestNumber
    purchaseRequestStatus
    team {
      id
      name
      slug
    }
    requester
    approver
    orderer
    receiver
    supplier
    requestedDate
    orderedDate
    receivedDate
    estimatedAmount
    finalAmount
    comments
    purchaseRequestItems {
      id
      quantity
      name
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseRequestInfoGQL extends Apollo.Query<PurchaseRequestInfoQuery, PurchaseRequestInfoQueryVariables> {
    document = PurchaseRequestInfoDocument;
    
  }
export const PurchaseRequestOrderDocument = gql`
    mutation purchaseRequestOrder($id: UUID!, $amount: BigDecimal!) {
  purchaseRequestOrder(id: $id, amount: $amount) {
    id
    requestNumber
    purchaseRequestStatus
    team {
      id
      name
      slug
    }
    requester
    approver
    orderer
    receiver
    supplier
    requestedDate
    orderedDate
    receivedDate
    estimatedAmount
    finalAmount
    comments
    purchaseRequestItems {
      id
      quantity
      name
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseRequestOrderGQL extends Apollo.Mutation<PurchaseRequestOrderMutation, PurchaseRequestOrderMutationVariables> {
    document = PurchaseRequestOrderDocument;
    
  }
export const PurchaseRequestReceiveDocument = gql`
    mutation purchaseRequestReceive($id: UUID!) {
  purchaseRequestReceive(id: $id) {
    id
    requestNumber
    purchaseRequestStatus
    team {
      id
      name
      slug
    }
    requester
    approver
    orderer
    receiver
    supplier
    requestedDate
    orderedDate
    receivedDate
    estimatedAmount
    finalAmount
    comments
    purchaseRequestItems {
      id
      quantity
      name
      amount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseRequestReceiveGQL extends Apollo.Mutation<PurchaseRequestReceiveMutation, PurchaseRequestReceiveMutationVariables> {
    document = PurchaseRequestReceiveDocument;
    
  }
export const QueueUpdateDocument = gql`
    mutation queueUpdate($id: UUID!, $zoneId: UUID, $name: String!, $slug: String!, $priority: Long!, $template: String, $shippingNeedsType: ShippingNeedsType, $singlesOnly: Boolean, $combosOnly: Boolean, $enabled: Boolean!) {
  queueUpdate(
    id: $id
    zoneId: $zoneId
    name: $name
    slug: $slug
    priority: $priority
    template: $template
    shippingNeedsType: $shippingNeedsType
    singlesOnly: $singlesOnly
    combosOnly: $combosOnly
    enabled: $enabled
  ) {
    id
    enabled
    name
    slug
    priority
    template
    shippingNeeds
    singlesOnly
    combosOnly
    zone {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueueUpdateGQL extends Apollo.Mutation<QueueUpdateMutation, QueueUpdateMutationVariables> {
    document = QueueUpdateDocument;
    
  }
export const RedirectFindByPathDocument = gql`
    mutation redirectFindByPath($path: String!) {
  redirectFindByPath(path: $path)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RedirectFindByPathGQL extends Apollo.Mutation<RedirectFindByPathMutation, RedirectFindByPathMutationVariables> {
    document = RedirectFindByPathDocument;
    
  }
export const RefundInfoDocument = gql`
    query refundInfo($id: UUID!) {
  refundInfo(id: $id) {
    id
    createdAt
    refundNumber
    repFirstName
    subTotal
    productDiscountTotal
    taxCode
    productTaxTotal
    shippingTotal
    handlingTotal
    shippingDiscountTotal
    shippingTaxTotal
    grandTotal
    refundItems {
      id
      quantity
      salesOrderItem {
        sku
        name
        unitAmount
        lineAmount
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RefundInfoGQL extends Apollo.Query<RefundInfoQuery, RefundInfoQueryVariables> {
    document = RefundInfoDocument;
    
  }
export const ReviewCreateDocument = gql`
    mutation reviewCreate($productId: UUID!, $rating: Long!, $title: String!, $comments: String!, $mediaIds: [UUID]!) {
  reviewCreate(
    productId: $productId
    rating: $rating
    title: $title
    comments: $comments
    mediaIds: $mediaIds
  ) {
    ...USER_REVIEW
  }
}
    ${User_ReviewFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ReviewCreateGQL extends Apollo.Mutation<ReviewCreateMutation, ReviewCreateMutationVariables> {
    document = ReviewCreateDocument;
    
  }
export const ReviewListDocument = gql`
    query reviewList($page: GraphQLPageableInput!) {
  reviewList(page: $page) {
    count
    data {
      ...USER_REVIEW
    }
  }
}
    ${User_ReviewFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ReviewListGQL extends Apollo.Query<ReviewListQuery, ReviewListQueryVariables> {
    document = ReviewListDocument;
    
  }
export const ReviewListForProductDocument = gql`
    query reviewListForProduct($productId: UUID!, $rating: Long, $pageable: GraphQLPageableInput!, $sort: GraphQLSortInput) {
  reviewListForProduct(
    productId: $productId
    rating: $rating
    page: $pageable
    sort: $sort
  ) {
    count81To100
    count61To80
    count41To60
    count21To40
    count0To20
    averageRating
    reviews {
      data {
        id
        comments
        createdTime
        rating
        reviewer
        title
        updatedTime
        medias {
          id
          keywords
          title
          url
        }
      }
      count
      page
      pageSize
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReviewListForProductGQL extends Apollo.Query<ReviewListForProductQuery, ReviewListForProductQueryVariables> {
    document = ReviewListForProductDocument;
    
  }
export const ReviewRemoveDocument = gql`
    mutation reviewRemove($id: UUID!) {
  reviewRemove(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReviewRemoveGQL extends Apollo.Mutation<ReviewRemoveMutation, ReviewRemoveMutationVariables> {
    document = ReviewRemoveDocument;
    
  }
export const ReviewUpdateDocument = gql`
    mutation reviewUpdate($id: UUID!, $rating: Long!, $title: String!, $comments: String!, $mediaIds: [UUID]!) {
  reviewUpdate(
    id: $id
    rating: $rating
    title: $title
    comments: $comments
    mediaIds: $mediaIds
  ) {
    ...USER_REVIEW
  }
}
    ${User_ReviewFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ReviewUpdateGQL extends Apollo.Mutation<ReviewUpdateMutation, ReviewUpdateMutationVariables> {
    document = ReviewUpdateDocument;
    
  }
export const ReviewUploadImageDocument = gql`
    mutation reviewUploadImage($dataUrl: String!, $filename: String!) {
  reviewUploadImage(dataUrl: $dataUrl, filename: $filename) {
    id
    keywords
    title
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReviewUploadImageGQL extends Apollo.Mutation<ReviewUploadImageMutation, ReviewUploadImageMutationVariables> {
    document = ReviewUploadImageDocument;
    
  }
export const RewardPointsBalanceDocument = gql`
    query rewardPointsBalance {
  rewardPointsBalance
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RewardPointsBalanceGQL extends Apollo.Query<RewardPointsBalanceQuery, RewardPointsBalanceQueryVariables> {
    document = RewardPointsBalanceDocument;
    
  }
export const RewardPointsGetRewardsAccountDocument = gql`
    query rewardPointsGetRewardsAccount {
  rewardPointsGetRewardsAccount {
    balance
    transactions {
      amount
      comment
      timestamp
      transactionType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RewardPointsGetRewardsAccountGQL extends Apollo.Query<RewardPointsGetRewardsAccountQuery, RewardPointsGetRewardsAccountQueryVariables> {
    document = RewardPointsGetRewardsAccountDocument;
    
  }
export const ReshipSalesOrderInfoDocument = gql`
    query reshipSalesOrderInfo($id: UUID!) {
  reshipSalesOrderInfo(id: $id) {
    parentOrder {
      id
      cls
      orderNumber
      alternateOrderNumber
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReshipSalesOrderInfoGQL extends Apollo.Query<ReshipSalesOrderInfoQuery, ReshipSalesOrderInfoQueryVariables> {
    document = ReshipSalesOrderInfoDocument;
    
  }
export const SalesOrderAddBraintreeOfflinePaymentDocument = gql`
    mutation salesOrderAddBraintreeOfflinePayment($orderId: UUID!, $transactionId: String!) {
  salesOrderAddBraintreeOfflinePayment(
    transactionId: $transactionId
    orderId: $orderId
  ) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderAddBraintreeOfflinePaymentGQL extends Apollo.Mutation<SalesOrderAddBraintreeOfflinePaymentMutation, SalesOrderAddBraintreeOfflinePaymentMutationVariables> {
    document = SalesOrderAddBraintreeOfflinePaymentDocument;
    
  }
export const SalesOrderAddBraintreeOnlinePaymentDocument = gql`
    mutation salesOrderAddBraintreeOnlinePayment($orderId: UUID!, $amount: BigDecimal!, $deviceData: String!, $nonce: String!) {
  salesOrderAddBraintreeOnlinePayment(
    orderId: $orderId
    amount: $amount
    deviceData: $deviceData
    nonce: $nonce
  ) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderAddBraintreeOnlinePaymentGQL extends Apollo.Mutation<SalesOrderAddBraintreeOnlinePaymentMutation, SalesOrderAddBraintreeOnlinePaymentMutationVariables> {
    document = SalesOrderAddBraintreeOnlinePaymentDocument;
    
  }
export const SalesOrderAddCashPaymentDocument = gql`
    mutation salesOrderAddCashPayment($amount: BigDecimal!, $orderId: UUID!) {
  salesOrderAddCashPayment(amount: $amount, orderId: $orderId) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderAddCashPaymentGQL extends Apollo.Mutation<SalesOrderAddCashPaymentMutation, SalesOrderAddCashPaymentMutationVariables> {
    document = SalesOrderAddCashPaymentDocument;
    
  }
export const SalesOrderAddCheckPaymentDocument = gql`
    mutation salesOrderAddCheckPayment($amount: BigDecimal!, $orderId: UUID!, $checkNumber: String) {
  salesOrderAddCheckPayment(
    amount: $amount
    orderId: $orderId
    checkNumber: $checkNumber
  ) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderAddCheckPaymentGQL extends Apollo.Mutation<SalesOrderAddCheckPaymentMutation, SalesOrderAddCheckPaymentMutationVariables> {
    document = SalesOrderAddCheckPaymentDocument;
    
  }
export const SalesOrderAddCommentDocument = gql`
    mutation SalesOrderAddComment($comment: String!, $id: UUID!) {
  salesOrderAddComment(comment: $comment, id: $id) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderAddCommentGQL extends Apollo.Mutation<SalesOrderAddCommentMutation, SalesOrderAddCommentMutationVariables> {
    document = SalesOrderAddCommentDocument;
    
  }
export const SalesOrderAddGiftCardPaymentDocument = gql`
    mutation salesOrderAddGiftCardPayment($amount: BigDecimal!, $orderId: UUID!, $giftCardCode: String) {
  salesOrderAddGiftCardPayment(
    amount: $amount
    orderId: $orderId
    giftCardCode: $giftCardCode
  ) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderAddGiftCardPaymentGQL extends Apollo.Mutation<SalesOrderAddGiftCardPaymentMutation, SalesOrderAddGiftCardPaymentMutationVariables> {
    document = SalesOrderAddGiftCardPaymentDocument;
    
  }
export const SalesOrderAddPaymentTermsPaymentDocument = gql`
    mutation salesOrderAddPaymentTermsPayment($amount: BigDecimal!, $orderId: UUID!) {
  salesOrderAddPaymentTermsPayment(amount: $amount, orderId: $orderId) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderAddPaymentTermsPaymentGQL extends Apollo.Mutation<SalesOrderAddPaymentTermsPaymentMutation, SalesOrderAddPaymentTermsPaymentMutationVariables> {
    document = SalesOrderAddPaymentTermsPaymentDocument;
    
  }
export const SalesOrderCancelDocument = gql`
    mutation salesOrderCancel($id: UUID!) {
  salesOrderCancel(id: $id) {
    ...CUSTOMER_SALES_ORDER
  }
}
    ${Customer_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderCancelGQL extends Apollo.Mutation<SalesOrderCancelMutation, SalesOrderCancelMutationVariables> {
    document = SalesOrderCancelDocument;
    
  }
export const SalesOrderCancelByHashDocument = gql`
    mutation salesOrderCancelByHash($hash: String!) {
  salesOrderCancelByHash(hash: $hash) {
    ...CUSTOMER_SALES_ORDER
  }
}
    ${Customer_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderCancelByHashGQL extends Apollo.Mutation<SalesOrderCancelByHashMutation, SalesOrderCancelByHashMutationVariables> {
    document = SalesOrderCancelByHashDocument;
    
  }
export const SalesOrderCustomRefundDocument = gql`
    mutation salesOrderCustomRefund($id: UUID!, $subTotal: BigDecimal, $productDiscount: BigDecimal, $shipping: BigDecimal!, $handling: BigDecimal!, $shippingDiscount: BigDecimal!, $reason: RefundReason) {
  salesOrderCustomRefund(
    id: $id
    subTotal: $subTotal
    productDiscount: $productDiscount
    shipping: $shipping
    handling: $handling
    shippingDiscount: $shippingDiscount
    refundReason: $reason
  ) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderCustomRefundGQL extends Apollo.Mutation<SalesOrderCustomRefundMutation, SalesOrderCustomRefundMutationVariables> {
    document = SalesOrderCustomRefundDocument;
    
  }
export const SalesOrderCustomRefundSummarizeDocument = gql`
    mutation salesOrderCustomRefundSummarize($id: UUID!, $subTotal: BigDecimal, $productDiscount: BigDecimal, $shipping: BigDecimal!, $handling: BigDecimal!, $shippingDiscount: BigDecimal!, $reason: RefundReason) {
  salesOrderCustomRefundSummarize(
    id: $id
    subTotal: $subTotal
    productDiscount: $productDiscount
    shipping: $shipping
    handling: $handling
    shippingDiscount: $shippingDiscount
    reason: $reason
  ) {
    id
    createdAt
    refundNumber
    repFirstName
    subTotal
    productDiscountTotal
    taxCode
    productTaxTotal
    shippingTotal
    handlingTotal
    shippingDiscountTotal
    shippingTaxTotal
    grandTotal
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderCustomRefundSummarizeGQL extends Apollo.Mutation<SalesOrderCustomRefundSummarizeMutation, SalesOrderCustomRefundSummarizeMutationVariables> {
    document = SalesOrderCustomRefundSummarizeDocument;
    
  }
export const SalesOrderGetRefundableAmountsDocument = gql`
    mutation salesOrderGetRefundableAmounts($id: UUID!) {
  salesOrderGetRefundableAmounts(id: $id) {
    shipping
    handling
    shippingDiscount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderGetRefundableAmountsGQL extends Apollo.Mutation<SalesOrderGetRefundableAmountsMutation, SalesOrderGetRefundableAmountsMutationVariables> {
    document = SalesOrderGetRefundableAmountsDocument;
    
  }
export const SalesOrderPartialRefundSummarizeDocument = gql`
    mutation salesOrderPartialRefundSummarize($id: UUID!, $lineItems: [SalesOrderLineItemInput]!, $reason: RefundReason) {
  salesOrderPartialRefundSummarize(
    id: $id
    lineItems: $lineItems
    reason: $reason
  ) {
    id
    createdAt
    refundNumber
    repFirstName
    subTotal
    productDiscountTotal
    taxCode
    productTaxTotal
    shippingTotal
    handlingTotal
    shippingDiscountTotal
    shippingTaxTotal
    grandTotal
    refundListedItems {
      id
      quantity
      salesOrderListedItem {
        sku
        name
        unitAmount
        lineAmount
      }
    }
    refundItems {
      id
      quantity
      salesOrderItem {
        sku
        name
        unitAmount
        lineAmount
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderPartialRefundSummarizeGQL extends Apollo.Mutation<SalesOrderPartialRefundSummarizeMutation, SalesOrderPartialRefundSummarizeMutationVariables> {
    document = SalesOrderPartialRefundSummarizeDocument;
    
  }
export const SalesOrderSalesTaxRefundDocument = gql`
    mutation salesOrderSalesTaxRefund($id: UUID!) {
  salesOrderSalesTaxRefund(id: $id) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderSalesTaxRefundGQL extends Apollo.Mutation<SalesOrderSalesTaxRefundMutation, SalesOrderSalesTaxRefundMutationVariables> {
    document = SalesOrderSalesTaxRefundDocument;
    
  }
export const SalesOrderByHashDocument = gql`
    query salesOrderByHash($hash: String!) {
  salesOrderByHash(hash: $hash) {
    ...CUSTOMER_SALES_ORDER
  }
}
    ${Customer_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderByHashGQL extends Apollo.Query<SalesOrderByHashQuery, SalesOrderByHashQueryVariables> {
    document = SalesOrderByHashDocument;
    
  }
export const SalesOrderFilterDocument = gql`
    query salesOrderFilter($orderNumber: String, $alternateOrderNumber: String, $orderType: String, $billingFirstName: String, $billingLastName: String, $email: String, $status: SalesOrderStatus, $hold: Boolean, $pageable: GraphQLPageableInput!, $sort: GraphQLSortInput) {
  salesOrderFilter(
    orderNumber: {pattern: $orderNumber}
    alternateOrderNumber: {pattern: $alternateOrderNumber}
    orderType: {value: $orderType}
    billingFirstName: {pattern: $billingFirstName}
    billingLastName: {pattern: $billingLastName}
    email: {pattern: $email}
    status: {value: $status}
    hold: {value: $hold}
    page: $pageable
    sort: $sort
  ) {
    data {
      id
      cls
      orderNumber
      alternateOrderNumber
      status
      billingFirstName
      billingLastName
      email
      placedTime
      subTotal
      discountTotal
      taxTotal
      shippingTotal
      handlingTotal
      grandTotal
      salesOrderListedItems {
        id
        sku
        name
        quantity
        unitAmount
        discountAmount
        lineAmount
      }
      salesOrderItemGroups {
        id
        quantity
        unitAmount
        lineAmount
        name
        sku
        kitProduct {
          sku
          title
        }
      }
      salesOrderItems {
        id
        sku
        name
        quantity
        unitAmount
        discountAmount
        lineAmount
        salesOrderItemGroup {
          id
        }
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderFilterGQL extends Apollo.Query<SalesOrderFilterQuery, SalesOrderFilterQueryVariables> {
    document = SalesOrderFilterDocument;
    
  }
export const SalesOrderGenerateInvoiceDocument = gql`
    mutation salesOrderGenerateInvoice($id: UUID!) {
  salesOrderGenerateInvoice(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderGenerateInvoiceGQL extends Apollo.Mutation<SalesOrderGenerateInvoiceMutation, SalesOrderGenerateInvoiceMutationVariables> {
    document = SalesOrderGenerateInvoiceDocument;
    
  }
export const SalesOrderGenerateInvoiceByHashDocument = gql`
    mutation salesOrderGenerateInvoiceByHash($hash: String!) {
  salesOrderGenerateInvoiceByHash(hash: $hash)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderGenerateInvoiceByHashGQL extends Apollo.Mutation<SalesOrderGenerateInvoiceByHashMutation, SalesOrderGenerateInvoiceByHashMutationVariables> {
    document = SalesOrderGenerateInvoiceByHashDocument;
    
  }
export const SalesOrderGetRefundableItemsDocument = gql`
    mutation salesOrderGetRefundableItems($id: UUID!) {
  salesOrderGetRefundableItems(id: $id) {
    id
    quantity
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderGetRefundableItemsGQL extends Apollo.Mutation<SalesOrderGetRefundableItemsMutation, SalesOrderGetRefundableItemsMutationVariables> {
    document = SalesOrderGetRefundableItemsDocument;
    
  }
export const SalesOrderInfoDocument = gql`
    query salesOrderInfo($id: UUID!) {
  salesOrderInfo(id: $id) {
    ...CUSTOMER_SALES_ORDER
  }
}
    ${Customer_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderInfoGQL extends Apollo.Query<SalesOrderInfoQuery, SalesOrderInfoQueryVariables> {
    document = SalesOrderInfoDocument;
    
  }
export const SalesOrderInfoAdminDocument = gql`
    query salesOrderInfoAdmin($id: UUID!) {
  salesOrderInfo(id: $id) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderInfoAdminGQL extends Apollo.Query<SalesOrderInfoAdminQuery, SalesOrderInfoAdminQueryVariables> {
    document = SalesOrderInfoAdminDocument;
    
  }
export const SalesOrderListDocument = gql`
    query salesOrderList($pageable: GraphQLPageableInput!) {
  salesOrderList(page: $pageable, sort: {field: "placedTime", direction: DESC}) {
    count
    data {
      id
      orderNumber
      status
      placedTime
      grandTotal
      salesOrderListedItems {
        id
        name
        quantity
        unitAmount
        lineAmount
        listedProduct {
          slug
          title
          medias {
            url
          }
        }
        listing {
          identifier
          title
          listingMedias {
            url
          }
        }
      }
      salesOrderListedItems {
        id
        name
        quantity
        unitAmount
        lineAmount
        listedProduct {
          slug
          title
          medias {
            url
          }
        }
        listing {
          identifier
          title
          listingMedias {
            url
          }
        }
      }
      salesOrderItemGroups {
        quantity
        unitAmount
        lineAmount
        name
        kitProduct {
          slug
          title
          medias {
            url
          }
        }
        salesOrderItems {
          quantity
          name
        }
      }
      salesOrderItems {
        name
        quantity
        unitAmount
        lineAmount
        salesOrderItemGroup {
          id
        }
        simpleProduct {
          slug
          title
          medias {
            url
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderListGQL extends Apollo.Query<SalesOrderListQuery, SalesOrderListQueryVariables> {
    document = SalesOrderListDocument;
    
  }
export const SalesOrderOfflineRefundDocument = gql`
    mutation salesOrderOfflineRefund($id: UUID!, $reason: RefundReason) {
  salesOrderOfflineRefund(id: $id, reason: $reason) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderOfflineRefundGQL extends Apollo.Mutation<SalesOrderOfflineRefundMutation, SalesOrderOfflineRefundMutationVariables> {
    document = SalesOrderOfflineRefundDocument;
    
  }
export const SalesOrderOnlineRefundDocument = gql`
    mutation salesOrderOnlineRefund($id: UUID!, $reason: RefundReason) {
  salesOrderOnlineRefund(id: $id, reason: $reason) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderOnlineRefundGQL extends Apollo.Mutation<SalesOrderOnlineRefundMutation, SalesOrderOnlineRefundMutationVariables> {
    document = SalesOrderOnlineRefundDocument;
    
  }
export const SalesOrderPartialRefundDocument = gql`
    mutation salesOrderPartialRefund($id: UUID!, $lineItems: [SalesOrderLineItemInput]!, $reason: RefundReason) {
  salesOrderPartialRefund(id: $id, lineItems: $lineItems, reason: $reason) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderPartialRefundGQL extends Apollo.Mutation<SalesOrderPartialRefundMutation, SalesOrderPartialRefundMutationVariables> {
    document = SalesOrderPartialRefundDocument;
    
  }
export const SalesOrderPartialReshipDocument = gql`
    mutation salesOrderPartialReship($id: UUID!, $lineItems: [SalesOrderLineItemInput]!, $reason: RefundReason, $carrierPreference: Carrier, $serviceType: ServiceType) {
  salesOrderPartialReship(
    id: $id
    lineItems: $lineItems
    reason: $reason
    carrierPreference: $carrierPreference
    serviceType: $serviceType
  ) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderPartialReshipGQL extends Apollo.Mutation<SalesOrderPartialReshipMutation, SalesOrderPartialReshipMutationVariables> {
    document = SalesOrderPartialReshipDocument;
    
  }
export const SalesOrderSetEmailDocument = gql`
    mutation salesOrderSetEmail($id: UUID!, $email: String!) {
  salesOrderSetEmail(id: $id, email: $email) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderSetEmailGQL extends Apollo.Mutation<SalesOrderSetEmailMutation, SalesOrderSetEmailMutationVariables> {
    document = SalesOrderSetEmailDocument;
    
  }
export const SalesOrderSetHoldDocument = gql`
    mutation salesOrderSetHold($id: UUID!, $hold: Boolean!) {
  salesOrderSetHold(id: $id, hold: $hold) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderSetHoldGQL extends Apollo.Mutation<SalesOrderSetHoldMutation, SalesOrderSetHoldMutationVariables> {
    document = SalesOrderSetHoldDocument;
    
  }
export const SalesOrderUpdatePaymentsDocument = gql`
    mutation salesOrderUpdatePayments($id: UUID!) {
  salesOrderUpdatePayments(id: $id) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderUpdatePaymentsGQL extends Apollo.Mutation<SalesOrderUpdatePaymentsMutation, SalesOrderUpdatePaymentsMutationVariables> {
    document = SalesOrderUpdatePaymentsDocument;
    
  }
export const SalesOrderValidateAddressDocument = gql`
    mutation salesOrderValidateAddress($id: UUID!) {
  salesOrderValidateAddress(id: $id) {
    ...ADMIN_SALES_ORDER
  }
}
    ${Admin_Sales_OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SalesOrderValidateAddressGQL extends Apollo.Mutation<SalesOrderValidateAddressMutation, SalesOrderValidateAddressMutationVariables> {
    document = SalesOrderValidateAddressDocument;
    
  }
export const ScheduledShipmentContactCustomerServiceDocument = gql`
    mutation scheduledShipmentContactCustomerService($shipmentId: UUID!, $message: String!) {
  scheduledShipmentContactCustomerService(id: $shipmentId, message: $message)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduledShipmentContactCustomerServiceGQL extends Apollo.Mutation<ScheduledShipmentContactCustomerServiceMutation, ScheduledShipmentContactCustomerServiceMutationVariables> {
    document = ScheduledShipmentContactCustomerServiceDocument;
    
  }
export const ScheduledShipmentContactCustomerServiceByHashDocument = gql`
    mutation scheduledShipmentContactCustomerServiceByHash($hash: String!, $message: String!) {
  scheduledShipmentContactCustomerServiceByHash(hash: $hash, message: $message)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduledShipmentContactCustomerServiceByHashGQL extends Apollo.Mutation<ScheduledShipmentContactCustomerServiceByHashMutation, ScheduledShipmentContactCustomerServiceByHashMutationVariables> {
    document = ScheduledShipmentContactCustomerServiceByHashDocument;
    
  }
export const ScheduledShipmentHoldLocationsDocument = gql`
    query scheduledShipmentHoldLocations($id: UUID!, $miles: BigDecimal, $postalCode: String!) {
  scheduledShipmentHoldLocations(id: $id, miles: $miles, postalCode: $postalCode) {
    company
    line1
    line2
    city
    state
    postalCode
    country
    addressType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduledShipmentHoldLocationsGQL extends Apollo.Query<ScheduledShipmentHoldLocationsQuery, ScheduledShipmentHoldLocationsQueryVariables> {
    document = ScheduledShipmentHoldLocationsDocument;
    
  }
export const ScheduledShipmentHoldLocationsByHashDocument = gql`
    query scheduledShipmentHoldLocationsByHash($hash: String!, $miles: BigDecimal, $postalCode: String!) {
  scheduledShipmentHoldLocationsByHash(
    hash: $hash
    miles: $miles
    postalCode: $postalCode
  ) {
    company
    line1
    line2
    city
    state
    postalCode
    country
    addressType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduledShipmentHoldLocationsByHashGQL extends Apollo.Query<ScheduledShipmentHoldLocationsByHashQuery, ScheduledShipmentHoldLocationsByHashQueryVariables> {
    document = ScheduledShipmentHoldLocationsByHashDocument;
    
  }
export const ScheduledShipmentPossibleDeliveryDatesDocument = gql`
    mutation scheduledShipmentPossibleDeliveryDates($shipmentId: UUID!) {
  scheduledShipmentPossibleDeliveryDates(id: $shipmentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduledShipmentPossibleDeliveryDatesGQL extends Apollo.Mutation<ScheduledShipmentPossibleDeliveryDatesMutation, ScheduledShipmentPossibleDeliveryDatesMutationVariables> {
    document = ScheduledShipmentPossibleDeliveryDatesDocument;
    
  }
export const ScheduledShipmentPossibleDeliveryDatesByHashDocument = gql`
    mutation scheduledShipmentPossibleDeliveryDatesByHash($hash: String!) {
  scheduledShipmentPossibleDeliveryDatesByHash(hash: $hash)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduledShipmentPossibleDeliveryDatesByHashGQL extends Apollo.Mutation<ScheduledShipmentPossibleDeliveryDatesByHashMutation, ScheduledShipmentPossibleDeliveryDatesByHashMutationVariables> {
    document = ScheduledShipmentPossibleDeliveryDatesByHashDocument;
    
  }
export const ScheduledShipmentScheduleDocument = gql`
    mutation scheduledShipmentSchedule($date: LocalDate!, $id: UUID!) {
  scheduledShipmentSchedule(date: $date, id: $id) {
    ...CUSTOMER_SHIPMENT
  }
}
    ${Customer_ShipmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduledShipmentScheduleGQL extends Apollo.Mutation<ScheduledShipmentScheduleMutation, ScheduledShipmentScheduleMutationVariables> {
    document = ScheduledShipmentScheduleDocument;
    
  }
export const ScheduledShipmentScheduleByHashDocument = gql`
    mutation scheduledShipmentScheduleByHash($date: LocalDate!, $hash: String!) {
  scheduledShipmentScheduleByHash(date: $date, hash: $hash) {
    ...CUSTOMER_SHIPMENT_PUBLIC
  }
}
    ${Customer_Shipment_PublicFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduledShipmentScheduleByHashGQL extends Apollo.Mutation<ScheduledShipmentScheduleByHashMutation, ScheduledShipmentScheduleByHashMutationVariables> {
    document = ScheduledShipmentScheduleByHashDocument;
    
  }
export const ScheduledShipmentUpdateAddressByHashFullDocument = gql`
    mutation scheduledShipmentUpdateAddressByHashFull($hash: String!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!, $residential: Boolean, $addressType: AddressType) {
  scheduledShipmentUpdateAddressByHash(
    hash: $hash
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
    residential: $residential
    addressType: $addressType
  ) {
    ...CUSTOMER_SHIPMENT
  }
}
    ${Customer_ShipmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ScheduledShipmentUpdateAddressByHashFullGQL extends Apollo.Mutation<ScheduledShipmentUpdateAddressByHashFullMutation, ScheduledShipmentUpdateAddressByHashFullMutationVariables> {
    document = ScheduledShipmentUpdateAddressByHashFullDocument;
    
  }
export const ShelfLabelsGeneratePdfDocument = gql`
    query shelfLabelsGeneratePdf($format: String!, $zonePrefix: String!, $start: Long!, $end: Long!, $collate: Boolean!) {
  shelfLabelsGeneratePdf(
    format: $format
    zonePrefix: $zonePrefix
    start: $start
    end: $end
    collate: $collate
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShelfLabelsGeneratePdfGQL extends Apollo.Query<ShelfLabelsGeneratePdfQuery, ShelfLabelsGeneratePdfQueryVariables> {
    document = ShelfLabelsGeneratePdfDocument;
    
  }
export const ShipmentByHashDocument = gql`
    query shipmentByHash($hash: String!) {
  shipmentByHash(hash: $hash) {
    ...CUSTOMER_SHIPMENT_PUBLIC
  }
}
    ${Customer_Shipment_PublicFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentByHashGQL extends Apollo.Query<ShipmentByHashQuery, ShipmentByHashQueryVariables> {
    document = ShipmentByHashDocument;
    
  }
export const ShipmentFilterDocument = gql`
    query shipmentFilter($warehouse: String, $salesOrderCls: String, $shipmentNumber: String, $firstName: String, $lastName: String, $email: String, $late: Boolean, $shipmentStatus: ShipmentStatus, $pageable: GraphQLPageableInput!, $sort: GraphQLSortInput) {
  shipmentFilter(
    warehouse: {value: $warehouse}
    salesOrderCls: {value: $salesOrderCls}
    shipmentNumber: {pattern: $shipmentNumber}
    firstName: {pattern: $firstName}
    lastName: {pattern: $lastName}
    email: {pattern: $email}
    late: {value: $late}
    shipmentStatus: {value: $shipmentStatus}
    page: $pageable
    sort: $sort
  ) {
    data {
      id
      cls
      email
      firstName
      lastName
      shipmentNumber
      shipmentStatus
      shippingNeeds
      carrier
      service
      estimatedWeight
      actualWeight
      estimatedLength
      estimatedWidth
      estimatedHeight
      actualLength
      actualWidth
      actualHeight
      estimatedShipDate
      estimatedDeliveryDateStart
      estimatedDeliveryDateEnd
      shippedAt
      trackingNo
      originWarehouse {
        id
        name
      }
      departingWarehouse {
        id
        name
      }
      salesOrder {
        cls
        orderNumber
        alternateOrderNumber
        salesOrderType
      }
      shipmentListedItems {
        id
        cls
        quantity
        salesOrderListedItem {
          id
          cls
          sku
          name
        }
      }
      shipmentItems {
        id
        cls
        quantity
        salesOrderItem {
          id
          cls
          sku
          name
        }
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentFilterGQL extends Apollo.Query<ShipmentFilterQuery, ShipmentFilterQueryVariables> {
    document = ShipmentFilterDocument;
    
  }
export const ShipmentInfoAdminDocument = gql`
    query shipmentInfoAdmin($id: UUID!) {
  shipmentInfo(id: $id) {
    ...ADMIN_SHIPMENT_ENTITY
  }
}
    ${Admin_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentInfoAdminGQL extends Apollo.Query<ShipmentInfoAdminQuery, ShipmentInfoAdminQueryVariables> {
    document = ShipmentInfoAdminDocument;
    
  }
export const ShipmentInfoSchedulingDocument = gql`
    query shipmentInfoScheduling($id: UUID!) {
  shipmentInfo(id: $id) {
    ...SCHEDULED_SHIPMENT_ENTITY
  }
}
    ${Scheduled_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentInfoSchedulingGQL extends Apollo.Query<ShipmentInfoSchedulingQuery, ShipmentInfoSchedulingQueryVariables> {
    document = ShipmentInfoSchedulingDocument;
    
  }
export const ShipmentTrackDocument = gql`
    mutation shipmentTrack($id: UUID!) {
  shipmentTrack(id: $id) {
    comment
    location
    timestamp
    trackingEventType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentTrackGQL extends Apollo.Mutation<ShipmentTrackMutation, ShipmentTrackMutationVariables> {
    document = ShipmentTrackDocument;
    
  }
export const ShipmentTrackByHashDocument = gql`
    mutation shipmentTrackByHash($hash: String!) {
  shipmentTrackByHash(hash: $hash) {
    comment
    location
    timestamp
    trackingEventType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentTrackByHashGQL extends Apollo.Mutation<ShipmentTrackByHashMutation, ShipmentTrackByHashMutationVariables> {
    document = ShipmentTrackByHashDocument;
    
  }
export const ShipmentCancelAndRefundDocument = gql`
    mutation shipmentCancelAndRefund($id: UUID!, $reason: CancellationReason, $sendNotification: Boolean!, $refund: Boolean!) {
  shipmentCancelAndRefund(
    id: $id
    cancellationReason: $reason
    sendNotification: $sendNotification
    refund: $refund
  ) {
    ...ADMIN_SHIPMENT_ENTITY
  }
}
    ${Admin_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentCancelAndRefundGQL extends Apollo.Mutation<ShipmentCancelAndRefundMutation, ShipmentCancelAndRefundMutationVariables> {
    document = ShipmentCancelAndRefundDocument;
    
  }
export const ShipmentDashboardDocument = gql`
    mutation shipmentDashboard($warehouse: String!) {
  shipmentDashboard(warehouse: $warehouse) {
    warehouse
    timezone
    today
    nextOpenDate
    lateCount
    onHoldCount
    pendingCount
    needsSchedulingCount
    summaries {
      date
      onHold
      unshipped
      shipped
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentDashboardGQL extends Apollo.Mutation<ShipmentDashboardMutation, ShipmentDashboardMutationVariables> {
    document = ShipmentDashboardDocument;
    
  }
export const ShipmentDeliverOfflineDocument = gql`
    mutation shipmentDeliverOffline($id: UUID!) {
  shipmentDeliverOffline(id: $id) {
    id
    shipmentStatus
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentDeliverOfflineGQL extends Apollo.Mutation<ShipmentDeliverOfflineMutation, ShipmentDeliverOfflineMutationVariables> {
    document = ShipmentDeliverOfflineDocument;
    
  }
export const ShipmentGenerateCommercialInvoiceDocument = gql`
    mutation shipmentGenerateCommercialInvoice($id: UUID!) {
  shipmentGenerateCommercialInvoice(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentGenerateCommercialInvoiceGQL extends Apollo.Mutation<ShipmentGenerateCommercialInvoiceMutation, ShipmentGenerateCommercialInvoiceMutationVariables> {
    document = ShipmentGenerateCommercialInvoiceDocument;
    
  }
export const ShipmentGeneratePackingListDocument = gql`
    mutation shipmentGeneratePackingList($id: UUID!) {
  shipmentGeneratePackingList(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentGeneratePackingListGQL extends Apollo.Mutation<ShipmentGeneratePackingListMutation, ShipmentGeneratePackingListMutationVariables> {
    document = ShipmentGeneratePackingListDocument;
    
  }
export const ShipmentInfoDocument = gql`
    query shipmentInfo($id: UUID!) {
  shipmentInfo(id: $id) {
    ...CUSTOMER_SHIPMENT
  }
}
    ${Customer_ShipmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentInfoGQL extends Apollo.Query<ShipmentInfoQuery, ShipmentInfoQueryVariables> {
    document = ShipmentInfoDocument;
    
  }
export const ShipmentLookupWeatherDocument = gql`
    mutation shipmentLookupWeather($shipmentId: UUID!) {
  shipmentLookupWeather(id: $shipmentId) {
    date
    high
    low
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentLookupWeatherGQL extends Apollo.Mutation<ShipmentLookupWeatherMutation, ShipmentLookupWeatherMutationVariables> {
    document = ShipmentLookupWeatherDocument;
    
  }
export const ShipmentLookupWeatherByHashDocument = gql`
    mutation shipmentLookupWeatherByHash($shipmentIdHash: String!) {
  shipmentLookupWeatherByHash(hash: $shipmentIdHash) {
    date
    high
    low
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentLookupWeatherByHashGQL extends Apollo.Mutation<ShipmentLookupWeatherByHashMutation, ShipmentLookupWeatherByHashMutationVariables> {
    document = ShipmentLookupWeatherByHashDocument;
    
  }
export const ShipmentRerouteDocument = gql`
    mutation shipmentReroute($shipment: UUID!, $warehouse: String!) {
  shipmentReroute(shipment: $shipment, warehouse: $warehouse) {
    ...ADMIN_SHIPMENT_ENTITY
  }
}
    ${Admin_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentRerouteGQL extends Apollo.Mutation<ShipmentRerouteMutation, ShipmentRerouteMutationVariables> {
    document = ShipmentRerouteDocument;
    
  }
export const ShipmentSetShippingNeedsDocument = gql`
    mutation shipmentSetShippingNeeds($id: UUID!, $shippingNeeds: ShippingNeedsType!) {
  shipmentSetShippingNeeds(id: $id, shippingNeeds: $shippingNeeds) {
    ...ADMIN_SHIPMENT_ENTITY
  }
}
    ${Admin_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentSetShippingNeedsGQL extends Apollo.Mutation<ShipmentSetShippingNeedsMutation, ShipmentSetShippingNeedsMutationVariables> {
    document = ShipmentSetShippingNeedsDocument;
    
  }
export const ShipmentShipOfflineDocument = gql`
    mutation shipmentShipOffline($id: UUID!, $carrier: Carrier, $trackingNos: [String], $service: Service) {
  shipmentShipOffline(
    id: $id
    carrier: $carrier
    trackingNos: $trackingNos
    service: $service
  ) {
    ...ADMIN_SHIPMENT_ENTITY
  }
}
    ${Admin_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentShipOfflineGQL extends Apollo.Mutation<ShipmentShipOfflineMutation, ShipmentShipOfflineMutationVariables> {
    document = ShipmentShipOfflineDocument;
    
  }
export const ShipmentSplitDocument = gql`
    mutation shipmentSplit($id: UUID!, $lineItems: [ShipmentLineItemInput]!, $reroute: Boolean!) {
  shipmentSplit(id: $id, lineItems: $lineItems, reroute: $reroute) {
    id
    cls
    shipmentNumber
    shipmentStatus
    shippingNeeds
    carrier
    service
    estimatedWeight
    actualWeight
    estimatedLength
    estimatedWidth
    estimatedHeight
    actualLength
    actualWidth
    actualHeight
    placedAt
    estimatedShipDate
    estimatedDeliveryDateStart
    estimatedDeliveryDateEnd
    shippedAt
    email
    firstName
    lastName
    company
    line1
    line2
    city
    state
    postalCode
    country
    residential
    addressType
    addressValidationSource
    downloadStart
    printedStart
    printedEnd
    reprint
    salesOrder {
      id
      cls
      orderNumber
      alternateOrderNumber
      placedTime
      status
    }
    shipmentListedItems {
      id
      cls
      quantity
      salesOrderListedItem {
        id
        cls
        sku
        name
      }
    }
    shipmentItems {
      id
      cls
      quantity
      salesOrderItem {
        id
        cls
        sku
        name
      }
    }
    shipmentAddons {
      id
      cls
      quantity
      sku
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentSplitGQL extends Apollo.Mutation<ShipmentSplitMutation, ShipmentSplitMutationVariables> {
    document = ShipmentSplitDocument;
    
  }
export const ShipmentUpdateAddressDocument = gql`
    mutation shipmentUpdateAddress($id: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!, $residential: Boolean, $addressType: AddressType) {
  shipmentUpdateAddress(
    id: $id
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
    residential: $residential
    addressType: $addressType
  ) {
    ...ADMIN_SHIPMENT_ENTITY
  }
}
    ${Admin_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentUpdateAddressGQL extends Apollo.Mutation<ShipmentUpdateAddressMutation, ShipmentUpdateAddressMutationVariables> {
    document = ShipmentUpdateAddressDocument;
    
  }
export const ShipmentUpdateAddressFullDocument = gql`
    mutation shipmentUpdateAddressFull($id: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!, $residential: Boolean, $addressType: AddressType) {
  shipmentUpdateAddress(
    id: $id
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
    residential: $residential
    addressType: $addressType
  ) {
    ...CUSTOMER_SHIPMENT
  }
}
    ${Customer_ShipmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentUpdateAddressFullGQL extends Apollo.Mutation<ShipmentUpdateAddressFullMutation, ShipmentUpdateAddressFullMutationVariables> {
    document = ShipmentUpdateAddressFullDocument;
    
  }
export const ShipmentValidateAddressDocument = gql`
    mutation shipmentValidateAddress($id: UUID!) {
  shipmentValidateAddress(id: $id) {
    ...ADMIN_SHIPMENT_ENTITY
  }
}
    ${Admin_Shipment_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentValidateAddressGQL extends Apollo.Mutation<ShipmentValidateAddressMutation, ShipmentValidateAddressMutationVariables> {
    document = ShipmentValidateAddressDocument;
    
  }
export const ShippingRuleSetListDocument = gql`
    query shippingRuleSetList {
  shippingRuleSetList(
    page: {page: null, pageSize: null, sort: [{field: "name", direction: ASC}]}
  ) {
    count
    data {
      id
      name
      slug
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingRuleSetListGQL extends Apollo.Query<ShippingRuleSetListQuery, ShippingRuleSetListQueryVariables> {
    document = ShippingRuleSetListDocument;
    
  }
export const SimpleProductAddAnimalDocument = gql`
    mutation simpleProductAddAnimal($id: UUID!, $animalId: UUID!) {
  simpleProductAddAnimal(id: $id, animalId: $animalId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductAddAnimalGQL extends Apollo.Mutation<SimpleProductAddAnimalMutation, SimpleProductAddAnimalMutationVariables> {
    document = SimpleProductAddAnimalDocument;
    
  }
export const SimpleProductAddComponentItemDocument = gql`
    mutation simpleProductAddComponentItem($id: UUID!, $productId: UUID!, $quantity: BigDecimal!) {
  simpleProductAddComponentItem(
    id: $id
    productId: $productId
    quantity: $quantity
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductAddComponentItemGQL extends Apollo.Mutation<SimpleProductAddComponentItemMutation, SimpleProductAddComponentItemMutationVariables> {
    document = SimpleProductAddComponentItemDocument;
    
  }
export const SimpleProductAddCrossSellItemsDocument = gql`
    mutation simpleProductAddCrossSellItems($id: UUID!, $productIds: [UUID]!) {
  simpleProductAddCrossSellItems(id: $id, productIds: $productIds) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductAddCrossSellItemsGQL extends Apollo.Mutation<SimpleProductAddCrossSellItemsMutation, SimpleProductAddCrossSellItemsMutationVariables> {
    document = SimpleProductAddCrossSellItemsDocument;
    
  }
export const SimpleProductAddImageDocument = gql`
    mutation simpleProductAddImage($id: UUID!, $mediaId: UUID!) {
  simpleProductAddImage(id: $id, mediaId: $mediaId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductAddImageGQL extends Apollo.Mutation<SimpleProductAddImageMutation, SimpleProductAddImageMutationVariables> {
    document = SimpleProductAddImageDocument;
    
  }
export const SimpleProductAddPriceTierDocument = gql`
    mutation simpleProductAddPriceTier($id: UUID!, $price: BigDecimal!, $quantity: BigDecimal!, $userType: UserType) {
  simpleProductAddPriceTier(
    id: $id
    price: $price
    quantity: $quantity
    userType: $userType
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductAddPriceTierGQL extends Apollo.Mutation<SimpleProductAddPriceTierMutation, SimpleProductAddPriceTierMutationVariables> {
    document = SimpleProductAddPriceTierDocument;
    
  }
export const SimpleProductAddWarehouseDocument = gql`
    mutation simpleProductAddWarehouse($id: UUID!, $warehouse: String!) {
  simpleProductAddWarehouse(productId: $id, warehouse: $warehouse) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductAddWarehouseGQL extends Apollo.Mutation<SimpleProductAddWarehouseMutation, SimpleProductAddWarehouseMutationVariables> {
    document = SimpleProductAddWarehouseDocument;
    
  }
export const SimpleProductAddZoneDocument = gql`
    mutation simpleProductAddZone($id: UUID!, $zone: UUID!) {
  simpleProductAddZone(productId: $id, zoneId: $zone) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductAddZoneGQL extends Apollo.Mutation<SimpleProductAddZoneMutation, SimpleProductAddZoneMutationVariables> {
    document = SimpleProductAddZoneDocument;
    
  }
export const SimpleProductBySlugDocument = gql`
    query simpleProductBySlug($slug: String!) {
  simpleProductBySlug(slug: $slug) {
    id
    cls
    slug
    sku
    title
    featured
    subscription
    dropshipped
    quantityAvailableForSale
    discontinuationReason
    price
    salePrice
    metaTitle
    metaDescription
    metaKeywords
    averageRating
    countRating
    shippingNeeds
    tieredPrices {
      id
      quantity
      price
    }
    publishedRevision {
      htmlContent
    }
    medias {
      id
      mediaType
      url
    }
    explicitCategories {
      active
      ancestors {
        active
        name
        path
      }
      id
      slug
      path
      name
    }
    variationSet {
      products {
        active
        cls
        id
        title
        slug
        price
        salePrice
        variationKey
        medias {
          id
          mediaType
          url
        }
      }
    }
    upsellProducts {
      id
      cls
      active
      title
      sku
      slug
      countRating
      averageRating
      quantityAvailableForSale
      price
      salePrice
      variationKey
      featured
      medias {
        id
        mediaType
        url
      }
    }
    shippingRuleSet {
      liveArrivalGuarantees {
        minTemp
        maxTemp
        transitTimeType
      }
      destinationRestriction {
        domesticOnly
        prohibitedStates
      }
    }
    careSheets {
      id
      title
      slug
      createdAt
      metaDescription
      active
      readTime
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductBySlugGQL extends Apollo.Query<SimpleProductBySlugQuery, SimpleProductBySlugQueryVariables> {
    document = SimpleProductBySlugDocument;
    
  }
export const SimpleProductClearBinDocument = gql`
    mutation simpleProductClearBin($id: UUID!, $warehouse: String!) {
  simpleProductClearBin(id: $id, warehouse: $warehouse) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductClearBinGQL extends Apollo.Mutation<SimpleProductClearBinMutation, SimpleProductClearBinMutationVariables> {
    document = SimpleProductClearBinDocument;
    
  }
export const SimpleProductClearBrandDocument = gql`
    mutation simpleProductClearBrand($productId: UUID!) {
  simpleProductClearBrand(productId: $productId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductClearBrandGQL extends Apollo.Mutation<SimpleProductClearBrandMutation, SimpleProductClearBrandMutationVariables> {
    document = SimpleProductClearBrandDocument;
    
  }
export const SimpleProductClearDiscontinuationReasonDocument = gql`
    mutation simpleProductClearDiscontinuationReason($id: UUID!) {
  simpleProductClearDiscontinuationReason(id: $id) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductClearDiscontinuationReasonGQL extends Apollo.Mutation<SimpleProductClearDiscontinuationReasonMutation, SimpleProductClearDiscontinuationReasonMutationVariables> {
    document = SimpleProductClearDiscontinuationReasonDocument;
    
  }
export const SimpleProductClearShipsAloneDocument = gql`
    mutation simpleProductClearShipsAlone($id: UUID!) {
  simpleProductClearShipsAlone(id: $id) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductClearShipsAloneGQL extends Apollo.Mutation<SimpleProductClearShipsAloneMutation, SimpleProductClearShipsAloneMutationVariables> {
    document = SimpleProductClearShipsAloneDocument;
    
  }
export const SimpleProductClearSupplierDocument = gql`
    mutation simpleProductClearSupplier($productId: UUID!) {
  simpleProductClearSupplier(productId: $productId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductClearSupplierGQL extends Apollo.Mutation<SimpleProductClearSupplierMutation, SimpleProductClearSupplierMutationVariables> {
    document = SimpleProductClearSupplierDocument;
    
  }
export const SimpleProductCloneDocument = gql`
    mutation simpleProductClone($id: UUID!, $sku: String!, $upc: String, $vendorRef: String, $title: String!, $slug: String, $price: BigDecimal!, $cost: BigDecimal) {
  simpleProductClone(
    id: $id
    sku: $sku
    upc: $upc
    vendorRef: $vendorRef
    title: $title
    slug: $slug
    price: $price
    cost: $cost
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductCloneGQL extends Apollo.Mutation<SimpleProductCloneMutation, SimpleProductCloneMutationVariables> {
    document = SimpleProductCloneDocument;
    
  }
export const SimpleProductCreateDocument = gql`
    mutation simpleProductCreate($sku: String!, $upc: String, $vendorRef: String, $title: String!, $slug: String, $price: BigDecimal!, $cost: BigDecimal, $weight: BigDecimal!) {
  simpleProductCreate(
    sku: $sku
    upc: $upc
    vendorRef: $vendorRef
    title: $title
    slug: $slug
    price: $price
    cost: $cost
    weight: $weight
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductCreateGQL extends Apollo.Mutation<SimpleProductCreateMutation, SimpleProductCreateMutationVariables> {
    document = SimpleProductCreateDocument;
    
  }
export const SimpleProductRemoveDocument = gql`
    mutation simpleProductRemove($id: UUID!) {
  simpleProductRemove(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemoveGQL extends Apollo.Mutation<SimpleProductRemoveMutation, SimpleProductRemoveMutationVariables> {
    document = SimpleProductRemoveDocument;
    
  }
export const SimpleProductFilterDocument = gql`
    query simpleProductFilter($pageable: GraphQLPageableInput!, $title: String, $sku: String, $active: Boolean, $animal: String, $brand: String, $supplier: String, $department: String, $category: String, $sort: GraphQLSortInput, $inStock: Boolean, $upc: String, $subscription: Boolean) {
  simpleProductFilter(
    title: {pattern: $title}
    sku: {pattern: $sku}
    active: {value: $active}
    animal: $animal
    brand: $brand
    supplier: $supplier
    department: $department
    category: $category
    page: $pageable
    sort: $sort
    inStock: {value: $inStock}
    upc: $upc
    subscription: $subscription
  ) {
    data {
      id
      slug
      sku
      upc
      title
      active
      quantityAvailableForSale
      averageRating
      countRating
      explicitDepartment {
        id
        slug
        name
        parent {
          id
          slug
          name
        }
      }
      brand {
        id
        slug
        name
      }
      supplier {
        id
        slug
        name
      }
      price
      salePrice
      shippingNeeds
      medias {
        id
        mediaType
        url
      }
      explicitCategories {
        id
        slug
        path
        name
        parent {
          id
          slug
          path
          name
          parent {
            id
            slug
            path
            name
          }
        }
      }
      shippingRuleSet {
        id
        slug
        name
      }
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductFilterGQL extends Apollo.Query<SimpleProductFilterQuery, SimpleProductFilterQueryVariables> {
    document = SimpleProductFilterDocument;
    
  }
export const SimpleProductInfoDocument = gql`
    query simpleProductInfo($id: UUID!) {
  simpleProductInfo(id: $id) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductInfoGQL extends Apollo.Query<SimpleProductInfoQuery, SimpleProductInfoQueryVariables> {
    document = SimpleProductInfoDocument;
    
  }
export const SimpleProductReindexDocument = gql`
    mutation simpleProductReindex($id: UUID!) {
  simpleProductReindex(id: $id) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductReindexGQL extends Apollo.Mutation<SimpleProductReindexMutation, SimpleProductReindexMutationVariables> {
    document = SimpleProductReindexDocument;
    
  }
export const SimpleProductRemoveAnimalDocument = gql`
    mutation simpleProductRemoveAnimal($id: UUID!, $animalId: UUID!) {
  simpleProductRemoveAnimal(id: $id, animalId: $animalId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemoveAnimalGQL extends Apollo.Mutation<SimpleProductRemoveAnimalMutation, SimpleProductRemoveAnimalMutationVariables> {
    document = SimpleProductRemoveAnimalDocument;
    
  }
export const SimpleProductRemoveComponentItemDocument = gql`
    mutation simpleProductRemoveComponentItem($id: UUID!, $componentItemId: UUID!) {
  simpleProductRemoveComponentItem(id: $id, componentItemId: $componentItemId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemoveComponentItemGQL extends Apollo.Mutation<SimpleProductRemoveComponentItemMutation, SimpleProductRemoveComponentItemMutationVariables> {
    document = SimpleProductRemoveComponentItemDocument;
    
  }
export const SimpleProductRemoveCrossSellItemDocument = gql`
    mutation simpleProductRemoveCrossSellItem($id: UUID!, $crossSellItemId: UUID!) {
  simpleProductRemoveCrossSellItem(id: $id, crossSellItemId: $crossSellItemId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemoveCrossSellItemGQL extends Apollo.Mutation<SimpleProductRemoveCrossSellItemMutation, SimpleProductRemoveCrossSellItemMutationVariables> {
    document = SimpleProductRemoveCrossSellItemDocument;
    
  }
export const SimpleProductRemovePriceTierDocument = gql`
    mutation simpleProductRemovePriceTier($id: UUID!, $priceTierId: UUID!) {
  simpleProductRemovePriceTier(id: $id, priceTierId: $priceTierId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemovePriceTierGQL extends Apollo.Mutation<SimpleProductRemovePriceTierMutation, SimpleProductRemovePriceTierMutationVariables> {
    document = SimpleProductRemovePriceTierDocument;
    
  }
export const SimpleProductRemoveProductMediaDocument = gql`
    mutation simpleProductRemoveProductMedia($id: UUID!, $productMediaId: UUID!) {
  simpleProductRemoveProductMedia(id: $id, productMediaId: $productMediaId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemoveProductMediaGQL extends Apollo.Mutation<SimpleProductRemoveProductMediaMutation, SimpleProductRemoveProductMediaMutationVariables> {
    document = SimpleProductRemoveProductMediaDocument;
    
  }
export const SimpleProductRemoveWarehouseDocument = gql`
    mutation simpleProductRemoveWarehouse($id: UUID!, $warehouse: String!) {
  simpleProductRemoveWarehouse(productId: $id, warehouse: $warehouse) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemoveWarehouseGQL extends Apollo.Mutation<SimpleProductRemoveWarehouseMutation, SimpleProductRemoveWarehouseMutationVariables> {
    document = SimpleProductRemoveWarehouseDocument;
    
  }
export const SimpleProductRemoveZoneDocument = gql`
    mutation simpleProductRemoveZone($id: UUID!, $zone: UUID!) {
  simpleProductRemoveZone(productId: $id, zoneId: $zone) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductRemoveZoneGQL extends Apollo.Mutation<SimpleProductRemoveZoneMutation, SimpleProductRemoveZoneMutationVariables> {
    document = SimpleProductRemoveZoneDocument;
    
  }
export const SimpleProductSetActiveDocument = gql`
    mutation simpleProductSetActive($id: UUID!, $active: Boolean!) {
  simpleProductSetActive(id: $id, active: $active) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetActiveGQL extends Apollo.Mutation<SimpleProductSetActiveMutation, SimpleProductSetActiveMutationVariables> {
    document = SimpleProductSetActiveDocument;
    
  }
export const SimpleProductSetBinDocument = gql`
    mutation simpleProductSetBin($id: UUID!, $bin: String!, $warehouse: String!) {
  simpleProductSetBin(id: $id, bin: $bin, warehouse: $warehouse) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetBinGQL extends Apollo.Mutation<SimpleProductSetBinMutation, SimpleProductSetBinMutationVariables> {
    document = SimpleProductSetBinDocument;
    
  }
export const SimpleProductSetBrandDocument = gql`
    mutation simpleProductSetBrand($productId: UUID!, $brandId: UUID!) {
  simpleProductSetBrand(productId: $productId, brandId: $brandId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetBrandGQL extends Apollo.Mutation<SimpleProductSetBrandMutation, SimpleProductSetBrandMutationVariables> {
    document = SimpleProductSetBrandDocument;
    
  }
export const SimpleProductSetCaseQuantityDocument = gql`
    mutation simpleProductSetCaseQuantity($id: UUID!, $caseQuantity: Long) {
  simpleProductSetCaseQuantity(id: $id, caseQuantity: $caseQuantity) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetCaseQuantityGQL extends Apollo.Mutation<SimpleProductSetCaseQuantityMutation, SimpleProductSetCaseQuantityMutationVariables> {
    document = SimpleProductSetCaseQuantityDocument;
    
  }
export const SimpleProductSetComponentItemPositionDocument = gql`
    mutation simpleProductSetComponentItemPosition($id: UUID!, $componentItemId: UUID!, $position: Int!) {
  simpleProductSetComponentItemPosition(
    id: $id
    componentItemId: $componentItemId
    position: $position
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetComponentItemPositionGQL extends Apollo.Mutation<SimpleProductSetComponentItemPositionMutation, SimpleProductSetComponentItemPositionMutationVariables> {
    document = SimpleProductSetComponentItemPositionDocument;
    
  }
export const SimpleProductSetComponentItemQuantityDocument = gql`
    mutation simpleProductSetComponentItemQuantity($id: UUID!, $componentItemId: UUID!, $quantity: BigDecimal!) {
  simpleProductSetComponentItemQuantity(
    id: $id
    componentItemId: $componentItemId
    quantity: $quantity
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetComponentItemQuantityGQL extends Apollo.Mutation<SimpleProductSetComponentItemQuantityMutation, SimpleProductSetComponentItemQuantityMutationVariables> {
    document = SimpleProductSetComponentItemQuantityDocument;
    
  }
export const SimpleProductSetCostDocument = gql`
    mutation simpleProductSetCost($id: UUID!, $cost: BigDecimal) {
  simpleProductSetCost(id: $id, cost: $cost) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetCostGQL extends Apollo.Mutation<SimpleProductSetCostMutation, SimpleProductSetCostMutationVariables> {
    document = SimpleProductSetCostDocument;
    
  }
export const SimpleProductSetCountryOfOriginDocument = gql`
    mutation simpleProductSetCountryOfOrigin($id: UUID!, $country: String) {
  simpleProductSetCountryOfOrigin(id: $id, country: $country) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetCountryOfOriginGQL extends Apollo.Mutation<SimpleProductSetCountryOfOriginMutation, SimpleProductSetCountryOfOriginMutationVariables> {
    document = SimpleProductSetCountryOfOriginDocument;
    
  }
export const SimpleProductSetCrossSellItemPositionDocument = gql`
    mutation simpleProductSetCrossSellItemPosition($id: UUID!, $crossSellItemId: UUID!, $position: Int!) {
  simpleProductSetCrossSellItemPosition(
    id: $id
    crossSellItemId: $crossSellItemId
    position: $position
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetCrossSellItemPositionGQL extends Apollo.Mutation<SimpleProductSetCrossSellItemPositionMutation, SimpleProductSetCrossSellItemPositionMutationVariables> {
    document = SimpleProductSetCrossSellItemPositionDocument;
    
  }
export const SimpleProductSetDimensionsDocument = gql`
    mutation simpleProductSetDimensions($id: UUID!, $length: BigDecimal!, $width: BigDecimal!, $height: BigDecimal!) {
  simpleProductSetDimensions(
    id: $id
    length: $length
    width: $width
    height: $height
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetDimensionsGQL extends Apollo.Mutation<SimpleProductSetDimensionsMutation, SimpleProductSetDimensionsMutationVariables> {
    document = SimpleProductSetDimensionsDocument;
    
  }
export const SimpleProductSetDiscontinuationReasonDocument = gql`
    mutation simpleProductSetDiscontinuationReason($reason: DiscontinuationReasonType!, $id: UUID!) {
  simpleProductSetDiscontinuationReason(reason: $reason, id: $id) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetDiscontinuationReasonGQL extends Apollo.Mutation<SimpleProductSetDiscontinuationReasonMutation, SimpleProductSetDiscontinuationReasonMutationVariables> {
    document = SimpleProductSetDiscontinuationReasonDocument;
    
  }
export const SimpleProductSetFeaturedDocument = gql`
    mutation simpleProductSetFeatured($id: UUID!, $featured: Boolean!) {
  simpleProductSetFeatured(id: $id, featured: $featured) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetFeaturedGQL extends Apollo.Mutation<SimpleProductSetFeaturedMutation, SimpleProductSetFeaturedMutationVariables> {
    document = SimpleProductSetFeaturedDocument;
    
  }
export const SimpleProductSetHeightDocument = gql`
    mutation simpleProductSetHeight($id: UUID!, $height: BigDecimal) {
  simpleProductSetHeight(id: $id, height: $height) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetHeightGQL extends Apollo.Mutation<SimpleProductSetHeightMutation, SimpleProductSetHeightMutationVariables> {
    document = SimpleProductSetHeightDocument;
    
  }
export const SimpleProductSetHsCodeDocument = gql`
    mutation simpleProductSetHsCode($id: UUID!, $hsCode: String) {
  simpleProductSetHsCode(id: $id, hsCode: $hsCode) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetHsCodeGQL extends Apollo.Mutation<SimpleProductSetHsCodeMutation, SimpleProductSetHsCodeMutationVariables> {
    document = SimpleProductSetHsCodeDocument;
    
  }
export const SimpleProductSetLengthDocument = gql`
    mutation simpleProductSetLength($id: UUID!, $length: BigDecimal) {
  simpleProductSetLength(id: $id, length: $length) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetLengthGQL extends Apollo.Mutation<SimpleProductSetLengthMutation, SimpleProductSetLengthMutationVariables> {
    document = SimpleProductSetLengthDocument;
    
  }
export const SimpleProductSetLiveDocument = gql`
    mutation simpleProductSetLive($id: UUID!, $live: Boolean!) {
  simpleProductSetLive(id: $id, live: $live) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetLiveGQL extends Apollo.Mutation<SimpleProductSetLiveMutation, SimpleProductSetLiveMutationVariables> {
    document = SimpleProductSetLiveDocument;
    
  }
export const SimpleProductSetMapPriceDocument = gql`
    mutation simpleProductSetMapPrice($id: UUID!, $mapPrice: BigDecimal) {
  simpleProductSetMapPrice(id: $id, mapPrice: $mapPrice) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetMapPriceGQL extends Apollo.Mutation<SimpleProductSetMapPriceMutation, SimpleProductSetMapPriceMutationVariables> {
    document = SimpleProductSetMapPriceDocument;
    
  }
export const SimpleProductSetMetaDescriptionDocument = gql`
    mutation simpleProductSetMetaDescription($id: UUID!, $metaDescription: String) {
  simpleProductSetMetaDescription(id: $id, metaDescription: $metaDescription) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetMetaDescriptionGQL extends Apollo.Mutation<SimpleProductSetMetaDescriptionMutation, SimpleProductSetMetaDescriptionMutationVariables> {
    document = SimpleProductSetMetaDescriptionDocument;
    
  }
export const SimpleProductSetMetaKeywordsDocument = gql`
    mutation simpleProductSetMetaKeywords($id: UUID!, $metaKeywords: String) {
  simpleProductSetMetaKeywords(id: $id, metaKeywords: $metaKeywords) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetMetaKeywordsGQL extends Apollo.Mutation<SimpleProductSetMetaKeywordsMutation, SimpleProductSetMetaKeywordsMutationVariables> {
    document = SimpleProductSetMetaKeywordsDocument;
    
  }
export const SimpleProductSetMetaTitleDocument = gql`
    mutation simpleProductSetMetaTitle($id: UUID!, $metaTitle: String) {
  simpleProductSetMetaTitle(id: $id, metaTitle: $metaTitle) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetMetaTitleGQL extends Apollo.Mutation<SimpleProductSetMetaTitleMutation, SimpleProductSetMetaTitleMutationVariables> {
    document = SimpleProductSetMetaTitleDocument;
    
  }
export const SimpleProductSetMinimumOrderQuantityDocument = gql`
    mutation simpleProductSetMinimumOrderQuantity($id: UUID!, $minimumOrderQuantity: Long) {
  simpleProductSetMinimumOrderQuantity(
    id: $id
    minimumOrderQuantity: $minimumOrderQuantity
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetMinimumOrderQuantityGQL extends Apollo.Mutation<SimpleProductSetMinimumOrderQuantityMutation, SimpleProductSetMinimumOrderQuantityMutationVariables> {
    document = SimpleProductSetMinimumOrderQuantityDocument;
    
  }
export const SimpleProductSetPrepTypeDocument = gql`
    mutation simpleProductSetPrepType($id: UUID!, $prepType: PrepType) {
  simpleProductSetPrepType(id: $id, prepType: $prepType) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetPrepTypeGQL extends Apollo.Mutation<SimpleProductSetPrepTypeMutation, SimpleProductSetPrepTypeMutationVariables> {
    document = SimpleProductSetPrepTypeDocument;
    
  }
export const SimpleProductSetPriceDocument = gql`
    mutation simpleProductSetPrice($id: UUID!, $price: BigDecimal!) {
  simpleProductSetPrice(id: $id, price: $price) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetPriceGQL extends Apollo.Mutation<SimpleProductSetPriceMutation, SimpleProductSetPriceMutationVariables> {
    document = SimpleProductSetPriceDocument;
    
  }
export const SimpleProductSetProductMediaSequenceDocument = gql`
    mutation simpleProductSetProductMediaSequence($id: UUID!, $productMediaId: UUID!, $sequence: Int!) {
  simpleProductSetProductMediaSequence(
    id: $id
    productMediaId: $productMediaId
    sequence: $sequence
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetProductMediaSequenceGQL extends Apollo.Mutation<SimpleProductSetProductMediaSequenceMutation, SimpleProductSetProductMediaSequenceMutationVariables> {
    document = SimpleProductSetProductMediaSequenceDocument;
    
  }
export const SimpleProductSetPublishedRevisionDocument = gql`
    mutation simpleProductSetPublishedRevision($id: UUID!, $htmlContent: String) {
  simpleProductSetPublishedRevision(id: $id, htmlContent: $htmlContent) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetPublishedRevisionGQL extends Apollo.Mutation<SimpleProductSetPublishedRevisionMutation, SimpleProductSetPublishedRevisionMutationVariables> {
    document = SimpleProductSetPublishedRevisionDocument;
    
  }
export const SimpleProductSetShippingNeedsTypeDocument = gql`
    mutation simpleProductSetShippingNeedsType($id: UUID!, $shippingNeedsType: ShippingNeedsType!) {
  simpleProductSetShippingNeedsType(
    id: $id
    shippingNeedsType: $shippingNeedsType
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetShippingNeedsTypeGQL extends Apollo.Mutation<SimpleProductSetShippingNeedsTypeMutation, SimpleProductSetShippingNeedsTypeMutationVariables> {
    document = SimpleProductSetShippingNeedsTypeDocument;
    
  }
export const SimpleProductSetShippingRuleSetDocument = gql`
    mutation simpleProductSetShippingRuleSet($productId: UUID!, $shippingRuleSetId: UUID!) {
  simpleProductSetShippingRuleSet(
    productId: $productId
    shippingRuleSetId: $shippingRuleSetId
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetShippingRuleSetGQL extends Apollo.Mutation<SimpleProductSetShippingRuleSetMutation, SimpleProductSetShippingRuleSetMutationVariables> {
    document = SimpleProductSetShippingRuleSetDocument;
    
  }
export const SimpleProductSetShipsAloneDocument = gql`
    mutation simpleProductSetShipsAlone($id: UUID!) {
  simpleProductSetShipsAlone(id: $id) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetShipsAloneGQL extends Apollo.Mutation<SimpleProductSetShipsAloneMutation, SimpleProductSetShipsAloneMutationVariables> {
    document = SimpleProductSetShipsAloneDocument;
    
  }
export const SimpleProductSetSkuDocument = gql`
    mutation simpleProductSetSku($id: UUID!, $sku: String!) {
  simpleProductSetSku(id: $id, sku: $sku) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetSkuGQL extends Apollo.Mutation<SimpleProductSetSkuMutation, SimpleProductSetSkuMutationVariables> {
    document = SimpleProductSetSkuDocument;
    
  }
export const SimpleProductSetSlugDocument = gql`
    mutation simpleProductSetSlug($id: UUID!, $slug: String!) {
  simpleProductSetSlug(id: $id, slug: $slug) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetSlugGQL extends Apollo.Mutation<SimpleProductSetSlugMutation, SimpleProductSetSlugMutationVariables> {
    document = SimpleProductSetSlugDocument;
    
  }
export const SimpleProductSetSpecialPriceDocument = gql`
    mutation simpleProductSetSpecialPrice($id: UUID!, $specialPrice: BigDecimal) {
  simpleProductSetSpecialPrice(id: $id, specialPrice: $specialPrice) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetSpecialPriceGQL extends Apollo.Mutation<SimpleProductSetSpecialPriceMutation, SimpleProductSetSpecialPriceMutationVariables> {
    document = SimpleProductSetSpecialPriceDocument;
    
  }
export const SimpleProductSetSubscriptionDocument = gql`
    mutation simpleProductSetSubscription($id: UUID!, $subscription: Boolean!) {
  simpleProductSetSubscription(id: $id, subscription: $subscription) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetSubscriptionGQL extends Apollo.Mutation<SimpleProductSetSubscriptionMutation, SimpleProductSetSubscriptionMutationVariables> {
    document = SimpleProductSetSubscriptionDocument;
    
  }
export const SimpleProductSetSupplierDocument = gql`
    mutation simpleProductSetSupplier($productId: UUID!, $supplierId: UUID!) {
  simpleProductSetSupplier(productId: $productId, supplierId: $supplierId) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetSupplierGQL extends Apollo.Mutation<SimpleProductSetSupplierMutation, SimpleProductSetSupplierMutationVariables> {
    document = SimpleProductSetSupplierDocument;
    
  }
export const SimpleProductSetTitleDocument = gql`
    mutation simpleProductSetTitle($id: UUID!, $title: String!) {
  simpleProductSetTitle(id: $id, title: $title) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetTitleGQL extends Apollo.Mutation<SimpleProductSetTitleMutation, SimpleProductSetTitleMutationVariables> {
    document = SimpleProductSetTitleDocument;
    
  }
export const SimpleProductSetUpcDocument = gql`
    mutation simpleProductSetUpc($id: UUID!, $upc: String) {
  simpleProductSetUpc(id: $id, upc: $upc) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetUpcGQL extends Apollo.Mutation<SimpleProductSetUpcMutation, SimpleProductSetUpcMutationVariables> {
    document = SimpleProductSetUpcDocument;
    
  }
export const SimpleProductSetVendorRefDocument = gql`
    mutation simpleProductSetVendorRef($id: UUID!, $vendorRef: String) {
  simpleProductSetVendorRef(id: $id, vendorRef: $vendorRef) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetVendorRefGQL extends Apollo.Mutation<SimpleProductSetVendorRefMutation, SimpleProductSetVendorRefMutationVariables> {
    document = SimpleProductSetVendorRefDocument;
    
  }
export const SimpleProductSetVisibleDocument = gql`
    mutation simpleProductSetVisible($id: UUID!, $visible: Boolean!) {
  simpleProductSetVisible(id: $id, visible: $visible) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetVisibleGQL extends Apollo.Mutation<SimpleProductSetVisibleMutation, SimpleProductSetVisibleMutationVariables> {
    document = SimpleProductSetVisibleDocument;
    
  }
export const SimpleProductSetWeightDocument = gql`
    mutation simpleProductSetWeight($id: UUID!, $weight: BigDecimal!) {
  simpleProductSetWeight(id: $id, weight: $weight) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetWeightGQL extends Apollo.Mutation<SimpleProductSetWeightMutation, SimpleProductSetWeightMutationVariables> {
    document = SimpleProductSetWeightDocument;
    
  }
export const SimpleProductSetWholesalePriceDocument = gql`
    mutation simpleProductSetWholesalePrice($id: UUID!, $wholesalePrice: BigDecimal) {
  simpleProductSetWholesalePrice(id: $id, wholesalePrice: $wholesalePrice) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetWholesalePriceGQL extends Apollo.Mutation<SimpleProductSetWholesalePriceMutation, SimpleProductSetWholesalePriceMutationVariables> {
    document = SimpleProductSetWholesalePriceDocument;
    
  }
export const SimpleProductSetWidthDocument = gql`
    mutation simpleProductSetWidth($id: UUID!, $width: BigDecimal) {
  simpleProductSetWidth(id: $id, width: $width) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductSetWidthGQL extends Apollo.Mutation<SimpleProductSetWidthMutation, SimpleProductSetWidthMutationVariables> {
    document = SimpleProductSetWidthDocument;
    
  }
export const SimpleProductUploadImageDocument = gql`
    mutation simpleProductUploadImage($id: UUID!, $dataUrl: String!, $filename: String!, $keywords: String, $title: String) {
  simpleProductUploadImage(
    id: $id
    dataUrl: $dataUrl
    filename: $filename
    keyWords: $keywords
    title: $title
  ) {
    ...ADMIN_SIMPLE_PRODUCT_ENTITY
  }
}
    ${Admin_Simple_Product_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductUploadImageGQL extends Apollo.Mutation<SimpleProductUploadImageMutation, SimpleProductUploadImageMutationVariables> {
    document = SimpleProductUploadImageDocument;
    
  }
export const SimpleProductWithCrossSellsDocument = gql`
    query simpleProductWithCrossSells($slug: String!) {
  simpleProductBySlug(slug: $slug) {
    id
    cls
    sku
    slug
    title
    quantityAvailableForSale
    price
    salePrice
    medias {
      id
      mediaType
      url
    }
    shippingRuleSet {
      liveArrivalGuarantees {
        minTemp
        maxTemp
        transitTimeType
      }
    }
    crossSellItems {
      id
      position
      crossSellProduct {
        id
        cls
        active
        title
        sku
        slug
        countRating
        averageRating
        quantityAvailableForSale
        price
        salePrice
        variationKey
        featured
        medias {
          id
          mediaType
          url
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SimpleProductWithCrossSellsGQL extends Apollo.Query<SimpleProductWithCrossSellsQuery, SimpleProductWithCrossSellsQueryVariables> {
    document = SimpleProductWithCrossSellsDocument;
    
  }
export const SubscriptionAddItemDocument = gql`
    mutation subscriptionAddItem($subscriptionId: UUID!, $productId: UUID!, $quantity: Long!, $frequency: Long!, $promoCode: String) {
  subscriptionAddItem(
    subscriptionId: $subscriptionId
    productId: $productId
    quantity: $quantity
    frequency: $frequency
    promoCode: $promoCode
  ) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionAddItemGQL extends Apollo.Mutation<SubscriptionAddItemMutation, SubscriptionAddItemMutationVariables> {
    document = SubscriptionAddItemDocument;
    
  }
export const SubscriptionCreateDocument = gql`
    mutation subscriptionCreate($productId: UUID!, $quantity: Long!, $frequency: Long!, $deliveryDay: DayOfWeek, $phone: String!, $billingFirstName: String!, $billingLastName: String!, $billingCompany: String, $billingLine1: String!, $billingLine2: String, $billingCity: String!, $billingState: String!, $billingPostalCode: String!, $billingCountry: String!, $shippingFirstName: String!, $shippingLastName: String!, $shippingCompany: String, $shippingLine1: String!, $shippingLine2: String, $shippingCity: String!, $shippingState: String!, $shippingPostalCode: String!, $shippingCountry: String!, $shippingResidential: Boolean, $paymentToken: String, $taxExempt: Boolean, $promoCode: String) {
  subscriptionCreate(
    productId: $productId
    quantity: $quantity
    frequency: $frequency
    deliveryDay: $deliveryDay
    phone: $phone
    billingFirstName: $billingFirstName
    billingLastName: $billingLastName
    billingCompany: $billingCompany
    billingLine1: $billingLine1
    billingLine2: $billingLine2
    billingCity: $billingCity
    billingState: $billingState
    billingPostalCode: $billingPostalCode
    billingCountry: $billingCountry
    shippingFirstName: $shippingFirstName
    shippingLastName: $shippingLastName
    shippingCompany: $shippingCompany
    shippingLine1: $shippingLine1
    shippingLine2: $shippingLine2
    shippingCity: $shippingCity
    shippingState: $shippingState
    shippingPostalCode: $shippingPostalCode
    shippingCountry: $shippingCountry
    shippingResidential: $shippingResidential
    paymentToken: $paymentToken
    taxExempt: $taxExempt
    promoCode: $promoCode
  ) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionCreateGQL extends Apollo.Mutation<SubscriptionCreateMutation, SubscriptionCreateMutationVariables> {
    document = SubscriptionCreateDocument;
    
  }
export const SubscriptionInfoDocument = gql`
    query subscriptionInfo($id: UUID!) {
  subscriptionInfo(id: $id) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionInfoGQL extends Apollo.Query<SubscriptionInfoQuery, SubscriptionInfoQueryVariables> {
    document = SubscriptionInfoDocument;
    
  }
export const SubscriptionListDocument = gql`
    query subscriptionList {
  subscriptionList(page: {page: null, pageSize: null}) {
    data {
      id
      deliveryDay
      shippingFirstName
      shippingLastName
      shippingCompany
      shippingLine1
      shippingLine2
      shippingCity
      shippingState
      shippingPostalCode
      shippingCountry
      shippingAddressValidationSource
      shippingAddressType
      subscriptionItems {
        frequency
        quantity
        product {
          id
          title
          price
          salePrice
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionListGQL extends Apollo.Query<SubscriptionListQuery, SubscriptionListQueryVariables> {
    document = SubscriptionListDocument;
    
  }
export const SubscriptionRemoveItemDocument = gql`
    mutation subscriptionRemoveItem($subscriptionId: UUID!, $subscriptionItemId: UUID!) {
  subscriptionRemoveItem(
    subscriptionId: $subscriptionId
    subscriptionItemId: $subscriptionItemId
  ) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionRemoveItemGQL extends Apollo.Mutation<SubscriptionRemoveItemMutation, SubscriptionRemoveItemMutationVariables> {
    document = SubscriptionRemoveItemDocument;
    
  }
export const SubscriptionSetBillingAddressDocument = gql`
    mutation subscriptionSetBillingAddress($subscriptionId: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  subscriptionSetBillingAddress(
    subscriptionId: $subscriptionId
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionSetBillingAddressGQL extends Apollo.Mutation<SubscriptionSetBillingAddressMutation, SubscriptionSetBillingAddressMutationVariables> {
    document = SubscriptionSetBillingAddressDocument;
    
  }
export const SubscriptionSetDeliveryDayDocument = gql`
    mutation subscriptionSetDeliveryDay($subscriptionId: UUID!, $deliveryDay: DayOfWeek) {
  subscriptionSetDeliveryDay(
    subscriptionId: $subscriptionId
    deliveryDay: $deliveryDay
  ) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionSetDeliveryDayGQL extends Apollo.Mutation<SubscriptionSetDeliveryDayMutation, SubscriptionSetDeliveryDayMutationVariables> {
    document = SubscriptionSetDeliveryDayDocument;
    
  }
export const SubscriptionSetNextDeliveryDateDocument = gql`
    mutation subscriptionSetNextDeliveryDate($subscriptionId: UUID!, $subscriptionItemId: UUID, $nextDeliveryDate: LocalDate!) {
  subscriptionSetNextDeliveryDate(
    subscriptionId: $subscriptionId
    subscriptionItemId: $subscriptionItemId
    nextDeliveryDate: $nextDeliveryDate
  ) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionSetNextDeliveryDateGQL extends Apollo.Mutation<SubscriptionSetNextDeliveryDateMutation, SubscriptionSetNextDeliveryDateMutationVariables> {
    document = SubscriptionSetNextDeliveryDateDocument;
    
  }
export const SubscriptionSetPaymentTokenDocument = gql`
    mutation subscriptionSetPaymentToken($subscriptionId: UUID!, $paymentToken: String!) {
  subscriptionSetPaymentToken(
    subscriptionId: $subscriptionId
    paymentToken: $paymentToken
  ) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionSetPaymentTokenGQL extends Apollo.Mutation<SubscriptionSetPaymentTokenMutation, SubscriptionSetPaymentTokenMutationVariables> {
    document = SubscriptionSetPaymentTokenDocument;
    
  }
export const SubscriptionSetShippingAddressDocument = gql`
    mutation subscriptionSetShippingAddress($subscriptionId: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  subscriptionSetShippingAddress(
    subscriptionId: $subscriptionId
    firstName: $firstName
    lastName: $lastName
    company: $company
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionSetShippingAddressGQL extends Apollo.Mutation<SubscriptionSetShippingAddressMutation, SubscriptionSetShippingAddressMutationVariables> {
    document = SubscriptionSetShippingAddressDocument;
    
  }
export const SubscriptionSkipNextDeliveryDocument = gql`
    mutation subscriptionSkipNextDelivery($subscriptionId: UUID!) {
  subscriptionSkipNextDelivery(subscriptionId: $subscriptionId) {
    ...SUBSCRIPTION
  }
}
    ${SubscriptionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SubscriptionSkipNextDeliveryGQL extends Apollo.Mutation<SubscriptionSkipNextDeliveryMutation, SubscriptionSkipNextDeliveryMutationVariables> {
    document = SubscriptionSkipNextDeliveryDocument;
    
  }
export const SupplierClearEmailDocument = gql`
    mutation supplierClearEmail($id: UUID!) {
  supplierClearEmail(id: $id) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierClearEmailGQL extends Apollo.Mutation<SupplierClearEmailMutation, SupplierClearEmailMutationVariables> {
    document = SupplierClearEmailDocument;
    
  }
export const SupplierClearPhoneDocument = gql`
    mutation supplierClearPhone($id: UUID!) {
  supplierClearPhone(id: $id) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierClearPhoneGQL extends Apollo.Mutation<SupplierClearPhoneMutation, SupplierClearPhoneMutationVariables> {
    document = SupplierClearPhoneDocument;
    
  }
export const SupplierCreateDocument = gql`
    mutation supplierCreate($country: String!, $lastName: String!, $firstName: String!, $city: String!, $postalCode: String!, $name: String!, $company: String, $state: String!, $line2: String, $line1: String!) {
  supplierCreate(
    country: $country
    lastName: $lastName
    firstName: $firstName
    city: $city
    postalCode: $postalCode
    name: $name
    company: $company
    state: $state
    line2: $line2
    line1: $line1
  ) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierCreateGQL extends Apollo.Mutation<SupplierCreateMutation, SupplierCreateMutationVariables> {
    document = SupplierCreateDocument;
    
  }
export const SupplierDeleteDocument = gql`
    mutation supplierDelete($id: UUID!) {
  supplierDelete(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierDeleteGQL extends Apollo.Mutation<SupplierDeleteMutation, SupplierDeleteMutationVariables> {
    document = SupplierDeleteDocument;
    
  }
export const SupplierInfoDocument = gql`
    query supplierInfo($id: UUID!) {
  supplierInfo(id: $id) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierInfoGQL extends Apollo.Query<SupplierInfoQuery, SupplierInfoQueryVariables> {
    document = SupplierInfoDocument;
    
  }
export const SupplierListDocument = gql`
    query supplierList {
  supplierList(page: {page: null, pageSize: null}) {
    data {
      id
      slug
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierListGQL extends Apollo.Query<SupplierListQuery, SupplierListQueryVariables> {
    document = SupplierListDocument;
    
  }
export const SupplierSetEmailDocument = gql`
    mutation supplierSetEmail($id: UUID!, $email: String!) {
  supplierSetEmail(id: $id, email: $email) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierSetEmailGQL extends Apollo.Mutation<SupplierSetEmailMutation, SupplierSetEmailMutationVariables> {
    document = SupplierSetEmailDocument;
    
  }
export const SupplierSetLeadTimeDocument = gql`
    mutation supplierSetLeadTime($id: UUID!, $leadTime: Int!) {
  supplierSetLeadTime(id: $id, leadTime: $leadTime) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierSetLeadTimeGQL extends Apollo.Mutation<SupplierSetLeadTimeMutation, SupplierSetLeadTimeMutationVariables> {
    document = SupplierSetLeadTimeDocument;
    
  }
export const SupplierSetNameDocument = gql`
    mutation supplierSetName($id: UUID!, $name: String!) {
  supplierSetName(id: $id, name: $name) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierSetNameGQL extends Apollo.Mutation<SupplierSetNameMutation, SupplierSetNameMutationVariables> {
    document = SupplierSetNameDocument;
    
  }
export const SupplierSetOrderCycleDocument = gql`
    mutation supplierSetOrderCycle($id: UUID!, $orderCycle: Int!) {
  supplierSetOrderCycle(id: $id, orderCycle: $orderCycle) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierSetOrderCycleGQL extends Apollo.Mutation<SupplierSetOrderCycleMutation, SupplierSetOrderCycleMutationVariables> {
    document = SupplierSetOrderCycleDocument;
    
  }
export const SupplierSetPhoneDocument = gql`
    mutation supplierSetPhone($id: UUID!, $phone: String!) {
  supplierSetPhone(id: $id, phone: $phone) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierSetPhoneGQL extends Apollo.Mutation<SupplierSetPhoneMutation, SupplierSetPhoneMutationVariables> {
    document = SupplierSetPhoneDocument;
    
  }
export const SupplierUpdateAddressDocument = gql`
    mutation supplierUpdateAddress($id: UUID!, $firstName: String!, $lastName: String!, $company: String, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!, $residential: Boolean, $addressType: AddressType) {
  supplierUpdateAddress(
    country: $country
    lastName: $lastName
    firstName: $firstName
    city: $city
    postalCode: $postalCode
    company: $company
    state: $state
    line2: $line2
    line1: $line1
    id: $id
    residential: $residential
    addressType: $addressType
  ) {
    ...SUPPLIER_ENTITY
  }
}
    ${Supplier_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierUpdateAddressGQL extends Apollo.Mutation<SupplierUpdateAddressMutation, SupplierUpdateAddressMutationVariables> {
    document = SupplierUpdateAddressDocument;
    
  }
export const TeamListDocument = gql`
    query teamList {
  teamList(
    page: {page: null, pageSize: null, sort: [{field: "name", direction: ASC}]}
  ) {
    data {
      id
      slug
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TeamListGQL extends Apollo.Query<TeamListQuery, TeamListQueryVariables> {
    document = TeamListDocument;
    
  }
export const TradeShowAssignTerminalDocument = gql`
    mutation tradeShowAssignTerminal($tradeshowId: UUID!, $terminalId: UUID!) {
  tradeShowAssignTerminal(tradeshowId: $tradeshowId, terminalId: $terminalId) {
    id
    country
    city
    endDate
    startDate
    postalCode
    cls
    taxRate
    name
    state
    line2
    line1
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowAssignTerminalGQL extends Apollo.Mutation<TradeShowAssignTerminalMutation, TradeShowAssignTerminalMutationVariables> {
    document = TradeShowAssignTerminalDocument;
    
  }
export const TradeShowCreateShowDocument = gql`
    mutation tradeShowCreateShow($name: String!, $preOrderStartDate: LocalDate, $preOrderEndDate: LocalDate, $startDate: LocalDate!, $endDate: LocalDate!, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $url: String, $posTerminalsRequested: Long) {
  tradeShowCreateShow(
    name: $name
    preOrderStartDate: $preOrderStartDate
    preOrderEndDate: $preOrderEndDate
    startDate: $startDate
    endDate: $endDate
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    url: $url
    posTerminalsRequested: $posTerminalsRequested
  ) {
    id
    country
    city
    endDate
    startDate
    postalCode
    preOrderStartDate
    preOrderEndDate
    cls
    taxRate
    name
    state
    line2
    line1
    url
    posTerminalsRequested
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowCreateShowGQL extends Apollo.Mutation<TradeShowCreateShowMutation, TradeShowCreateShowMutationVariables> {
    document = TradeShowCreateShowDocument;
    
  }
export const TradeShowDeleteShowDocument = gql`
    mutation tradeShowDeleteShow($id: UUID!) {
  tradeShowDeleteShow(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowDeleteShowGQL extends Apollo.Mutation<TradeShowDeleteShowMutation, TradeShowDeleteShowMutationVariables> {
    document = TradeShowDeleteShowDocument;
    
  }
export const TradeShowFilterDocument = gql`
    query tradeShowFilter($name: String, $pageable: GraphQLPageableInput!, $sort: GraphQLSortInput) {
  tradeShowFilter(name: {pattern: $name}, sort: $sort, page: $pageable) {
    data {
      id
      country
      city
      endDate
      startDate
      postalCode
      preOrderEndDate
      preOrderStartDate
      cls
      name
      taxRate
      startDate
      endDate
      line1
      line2
      city
      state
      postalCode
      country
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowFilterGQL extends Apollo.Query<TradeShowFilterQuery, TradeShowFilterQueryVariables> {
    document = TradeShowFilterDocument;
    
  }
export const TradeShowInfoDocument = gql`
    query tradeShowInfo($id: UUID!) {
  tradeShowInfo(id: $id) {
    id
    cls
    name
    taxRate
    startDate
    endDate
    preOrderEndDate
    preOrderStartDate
    line1
    line2
    city
    state
    postalCode
    country
    url
    posTerminalsRequested
    posTerminals {
      id
      cls
      assetTag
    }
    salesOrders {
      id
      alternateOrderNumber
      billingAddressValidationSource
      billingAddressType
      billingCity
      billingCompany
      billingCountry
      billingFirstName
      billingLastName
      billingLine1
      billingLine2
      billingPostalCode
      billingState
      cls
      comments {
        id
      }
      couponCode
      createdAt
      discountTotal
      email
      fulfillmentChannelType
      grandTotal
      handlingTotal
      hold
      ipAddress
      orderNumber
      payments {
        id
      }
      phone
      placedTime
      salesOrderType
      shippingAddressValidationSource
      shippingAddressType
      shippingCity
      shippingCompany
      shippingCountry
      shippingFirstName
      shippingLastName
      shippingLine1
      shippingLine2
      shippingMethod
      shippingPostalCode
      shippingResidential
      shippingState
      shippingTotal
      status
      subTotal
      taxCode
      taxTotal
      updatedAt
      shipments {
        id
        actualHeight
        actualLength
        actualShippingCost
        actualWeight
        actualWidth
        addressValidationSource
        carrier
        carrierPreference
        city
        cls
        company
        contentsMd5
        country
        createdAt
        downloadStart
        email
        estimatedDeliveryDateStart
        estimatedDeliveryDateEnd
        estimatedHeight
        estimatedLength
        estimatedShipDate
        estimatedWeight
        estimatedWidth
        finalApproval
        firstName
        lastName
        line1
        line2
        options
        packaging
        placedAt
        postalCode
        printedEnd
        printedStart
        reprint
        reseller
        residential
        service
        shipmentListedItems {
          id
        }
        shipmentItems {
          id
        }
        shipmentNumber
        shipmentStatus
        shippedAt
        shippedBy
        shippingNeeds
        state
        trackingNo
        trackingNos
        updatedAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowInfoGQL extends Apollo.Query<TradeShowInfoQuery, TradeShowInfoQueryVariables> {
    document = TradeShowInfoDocument;
    
  }
export const TradeShowListInPreOrderDocument = gql`
    query tradeShowListInPreOrder {
  tradeShowListInPreOrder {
    id
    name
    url
    city
    state
    startDate
    endDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowListInPreOrderGQL extends Apollo.Query<TradeShowListInPreOrderQuery, TradeShowListInPreOrderQueryVariables> {
    document = TradeShowListInPreOrderDocument;
    
  }
export const TradeShowListTerminalsDocument = gql`
    mutation tradeShowListTerminals {
  tradeShowListTerminals {
    urn
    cls
    assetTag
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowListTerminalsGQL extends Apollo.Mutation<TradeShowListTerminalsMutation, TradeShowListTerminalsMutationVariables> {
    document = TradeShowListTerminalsDocument;
    
  }
export const TradeShowListUpcomingDocument = gql`
    query tradeShowListUpcoming {
  tradeShowListUpcoming {
    id
    name
    city
    state
    startDate
    endDate
    preOrderEndDate
    preOrderStartDate
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowListUpcomingGQL extends Apollo.Query<TradeShowListUpcomingQuery, TradeShowListUpcomingQueryVariables> {
    document = TradeShowListUpcomingDocument;
    
  }
export const TradeShowReportDocument = gql`
    mutation tradeShowReport($id: UUID!) {
  tradeShowReport(id: $id) {
    showName
    startDate
    endDate
    city
    state
    subTotal
    discountTotal
    handlingTotal
    shippingTotal
    taxTotal
    grandTotal
    lineItems {
      orderNumber
      sku
      name
      quantity
      unitAmount
      lineAmount
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowReportGQL extends Apollo.Mutation<TradeShowReportMutation, TradeShowReportMutationVariables> {
    document = TradeShowReportDocument;
    
  }
export const TradeShowSendReportDocument = gql`
    mutation tradeShowSendReport($id: UUID!, $emails: [String]!) {
  tradeShowSendReport(id: $id, emails: $emails)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowSendReportGQL extends Apollo.Mutation<TradeShowSendReportMutation, TradeShowSendReportMutationVariables> {
    document = TradeShowSendReportDocument;
    
  }
export const TradeShowUnassignTerminalDocument = gql`
    mutation tradeShowUnassignTerminal($tradeshowId: UUID!, $terminalId: UUID!) {
  tradeShowUnassignTerminal(tradeshowId: $tradeshowId, terminalId: $terminalId) {
    id
    country
    city
    endDate
    startDate
    postalCode
    cls
    taxRate
    name
    state
    line2
    line1
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowUnassignTerminalGQL extends Apollo.Mutation<TradeShowUnassignTerminalMutation, TradeShowUnassignTerminalMutationVariables> {
    document = TradeShowUnassignTerminalDocument;
    
  }
export const TradeShowUpdateShowDocument = gql`
    mutation tradeShowUpdateShow($id: UUID!, $name: String!, $preOrderStartDate: LocalDate, $preOrderEndDate: LocalDate, $startDate: LocalDate!, $endDate: LocalDate!, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $url: String, $posTerminalsRequested: Long) {
  tradeShowUpdateShow(
    id: $id
    name: $name
    preOrderStartDate: $preOrderStartDate
    preOrderEndDate: $preOrderEndDate
    startDate: $startDate
    endDate: $endDate
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    url: $url
    posTerminalsRequested: $posTerminalsRequested
  ) {
    id
    country
    city
    endDate
    startDate
    postalCode
    preOrderStartDate
    preOrderEndDate
    cls
    taxRate
    name
    state
    line2
    line1
    url
    posTerminalsRequested
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TradeShowUpdateShowGQL extends Apollo.Mutation<TradeShowUpdateShowMutation, TradeShowUpdateShowMutationVariables> {
    document = TradeShowUpdateShowDocument;
    
  }
export const UserChangePasswordByHashDocument = gql`
    mutation userChangePasswordByHash($hash: String!, $password: String!) {
  userChangePasswordByHash(hash: $hash, password: $password)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserChangePasswordByHashGQL extends Apollo.Mutation<UserChangePasswordByHashMutation, UserChangePasswordByHashMutationVariables> {
    document = UserChangePasswordByHashDocument;
    
  }
export const UserConfirmEmailByHashDocument = gql`
    mutation userConfirmEmailByHash($hash: String!) {
  userConfirmEmail(hash: $hash)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserConfirmEmailByHashGQL extends Apollo.Mutation<UserConfirmEmailByHashMutation, UserConfirmEmailByHashMutationVariables> {
    document = UserConfirmEmailByHashDocument;
    
  }
export const UserFilterDocument = gql`
    query userFilter($firstName: String, $lastName: String, $email: String, $admin: Boolean, $pageable: GraphQLPageableInput!, $sort: GraphQLSortInput, $userType: UserType) {
  userFilter(
    firstName: {pattern: $firstName}
    lastName: {pattern: $lastName}
    email: {pattern: $email}
    admin: {value: $admin}
    page: $pageable
    sort: $sort
    userType: $userType
  ) {
    data {
      id
      firstName
      lastName
      email
      admin
      userType
      taxExempt
      netTerms
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserFilterGQL extends Apollo.Query<UserFilterQuery, UserFilterQueryVariables> {
    document = UserFilterDocument;
    
  }
export const UserInfoDocument = gql`
    query userInfo($id: UUID!) {
  userInfo(id: $id) {
    ...ADMIN_USER_ENTITY
  }
}
    ${Admin_User_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserInfoGQL extends Apollo.Query<UserInfoQuery, UserInfoQueryVariables> {
    document = UserInfoDocument;
    
  }
export const UserSelfDocument = gql`
    query userSelf {
  userSelf {
    id
    email
    firstName
    lastName
    phoneNumber
    admin
    roles
    emailConfirmed
    taxExempt
    netTerms
    userType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserSelfGQL extends Apollo.Query<UserSelfQuery, UserSelfQueryVariables> {
    document = UserSelfDocument;
    
  }
export const UserSendPasswordResetDocument = gql`
    mutation userSendPasswordReset($email: String!) {
  userSendPasswordReset(email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserSendPasswordResetGQL extends Apollo.Mutation<UserSendPasswordResetMutation, UserSendPasswordResetMutationVariables> {
    document = UserSendPasswordResetDocument;
    
  }
export const UserUpdateAdminDocument = gql`
    mutation userUpdateAdmin($id: UUID!, $admin: Boolean!) {
  userUpdateAdmin(id: $id, admin: $admin) {
    ...ADMIN_USER_ENTITY
  }
}
    ${Admin_User_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateAdminGQL extends Apollo.Mutation<UserUpdateAdminMutation, UserUpdateAdminMutationVariables> {
    document = UserUpdateAdminDocument;
    
  }
export const UserUpdateEmailDocument = gql`
    mutation userUpdateEmail($id: UUID!, $email: String!) {
  userUpdateEmail(id: $id, email: $email) {
    ...ADMIN_USER_ENTITY
  }
}
    ${Admin_User_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateEmailGQL extends Apollo.Mutation<UserUpdateEmailMutation, UserUpdateEmailMutationVariables> {
    document = UserUpdateEmailDocument;
    
  }
export const UserUpdateNameDocument = gql`
    mutation userUpdateName($id: UUID!, $firstName: String!, $lastName: String!) {
  userUpdateName(id: $id, firstName: $firstName, lastName: $lastName) {
    ...ADMIN_USER_ENTITY
  }
}
    ${Admin_User_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateNameGQL extends Apollo.Mutation<UserUpdateNameMutation, UserUpdateNameMutationVariables> {
    document = UserUpdateNameDocument;
    
  }
export const UserUpdateNetTermsDocument = gql`
    mutation userUpdateNetTerms($id: UUID!, $netTerms: Int!) {
  userUpdateNetTerms(id: $id, netTerms: $netTerms) {
    ...ADMIN_USER_ENTITY
  }
}
    ${Admin_User_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateNetTermsGQL extends Apollo.Mutation<UserUpdateNetTermsMutation, UserUpdateNetTermsMutationVariables> {
    document = UserUpdateNetTermsDocument;
    
  }
export const UserUpdatePasswordDocument = gql`
    mutation userUpdatePassword($email: String!, $password: String!) {
  userUpdatePassword(email: $email, password: $password) {
    id
    email
    firstName
    lastName
    admin
    emailConfirmed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdatePasswordGQL extends Apollo.Mutation<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables> {
    document = UserUpdatePasswordDocument;
    
  }
export const UserUpdateTaxExemptDocument = gql`
    mutation userUpdateTaxExempt($taxExempt: Boolean!, $id: UUID!) {
  userUpdateTaxExempt(taxExempt: $taxExempt, id: $id) {
    ...ADMIN_USER_ENTITY
  }
}
    ${Admin_User_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateTaxExemptGQL extends Apollo.Mutation<UserUpdateTaxExemptMutation, UserUpdateTaxExemptMutationVariables> {
    document = UserUpdateTaxExemptDocument;
    
  }
export const UserUpdateTypeDocument = gql`
    mutation userUpdateType($id: UUID!, $type: UserType!) {
  userUpdateType(id: $id, type: $type) {
    ...ADMIN_USER_ENTITY
  }
}
    ${Admin_User_EntityFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserUpdateTypeGQL extends Apollo.Mutation<UserUpdateTypeMutation, UserUpdateTypeMutationVariables> {
    document = UserUpdateTypeDocument;
    
  }
export const WalmartSalesOrderRefreshDocument = gql`
    mutation walmartSalesOrderRefresh($purchaseOrderId: String!) {
  walmartSalesOrderRefresh(purchaseOrderId: $purchaseOrderId) {
    id
    cls
    orderNumber
    alternateOrderNumber
    placedTime
    email
    phone
    ipAddress
    status
    hold
    couponCode
    billingFirstName
    billingLastName
    billingCompany
    billingLine1
    billingLine2
    billingCity
    billingState
    billingPostalCode
    billingCountry
    shippingFirstName
    shippingLastName
    shippingCompany
    shippingLine1
    shippingLine2
    shippingCity
    shippingState
    shippingPostalCode
    shippingCountry
    shippingAddressValidationSource
    salesOrderType
    subTotal
    discountTotal
    taxCode
    taxTotal
    shippingTotal
    handlingTotal
    grandTotal
    salesOrderListedItems {
      id
      name
      quantity
      unitAmount
      lineAmount
      listedProduct {
        slug
        title
        medias {
          url
        }
      }
      listing {
        identifier
        title
        listingMedias {
          url
        }
      }
    }
    salesOrderItemGroups {
      id
      lineAmount
      quantity
      unitAmount
      salesOrderItems {
        sku
        name
        quantity
      }
      kitProduct {
        sku
        title
      }
    }
    salesOrderItems {
      id
      sku
      name
      quantity
      unitAmount
      discountAmount
      lineAmount
      salesOrderItemGroup {
        id
      }
    }
    shipments {
      id
      shipmentNumber
      shipmentStatus
      estimatedShipDate
      estimatedDeliveryDateStart
      estimatedDeliveryDateEnd
      estimatedWeight
      estimatedLength
      estimatedWidth
      estimatedHeight
      actualWeight
      actualLength
      actualWidth
      actualHeight
      placedAt
      printedStart
      printedEnd
      shippedAt
      carrier
      service
      departingWarehouse {
        id
        name
      }
      originWarehouse {
        id
        name
      }
      shippingNeeds
      shipmentListedItems {
        id
        quantity
        salesOrderListedItem {
          id
          sku
          name
        }
      }
      shipmentItems {
        id
        quantity
        salesOrderItem {
          id
          sku
          name
        }
      }
    }
    payments {
      id
      transactionId
      paymentStatus
      placedAt
      amount
      description
    }
    comments {
      id
      time
      name
      comment
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WalmartSalesOrderRefreshGQL extends Apollo.Mutation<WalmartSalesOrderRefreshMutation, WalmartSalesOrderRefreshMutationVariables> {
    document = WalmartSalesOrderRefreshDocument;
    
  }
export const WarehouseAddCarriersDocument = gql`
    mutation warehouseAddCarriers($id: UUID!, $carriers: [Carrier]!) {
  warehouseAddCarriers(id: $id, carriers: $carriers) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseAddCarriersGQL extends Apollo.Mutation<WarehouseAddCarriersMutation, WarehouseAddCarriersMutationVariables> {
    document = WarehouseAddCarriersDocument;
    
  }
export const WarehouseCreateDocument = gql`
    mutation warehouseCreate($name: String!, $slug: String!, $warehouseType: WarehouseType!, $carriers: [Carrier]!, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  warehouseCreate(
    name: $name
    slug: $slug
    warehouseType: $warehouseType
    carriers: $carriers
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseCreateGQL extends Apollo.Mutation<WarehouseCreateMutation, WarehouseCreateMutationVariables> {
    document = WarehouseCreateDocument;
    
  }
export const WarehouseCreateQueueDocument = gql`
    mutation warehouseCreateQueue($id: UUID!, $zoneId: UUID, $name: String!, $slug: String!, $priority: Long!, $template: String, $shippingNeedsType: ShippingNeedsType, $singlesOnly: Boolean, $combosOnly: Boolean) {
  warehouseCreateQueue(
    id: $id
    zoneId: $zoneId
    name: $name
    slug: $slug
    priority: $priority
    template: $template
    shippingNeedsType: $shippingNeedsType
    singlesOnly: $singlesOnly
    combosOnly: $combosOnly
  ) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseCreateQueueGQL extends Apollo.Mutation<WarehouseCreateQueueMutation, WarehouseCreateQueueMutationVariables> {
    document = WarehouseCreateQueueDocument;
    
  }
export const WarehouseCreateZoneDocument = gql`
    mutation warehouseCreateZone($id: UUID!, $name: String!, $slug: String!, $sequence: Long) {
  warehouseCreateZone(id: $id, name: $name, slug: $slug, sequence: $sequence) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseCreateZoneGQL extends Apollo.Mutation<WarehouseCreateZoneMutation, WarehouseCreateZoneMutationVariables> {
    document = WarehouseCreateZoneDocument;
    
  }
export const WarehouseFilterDocument = gql`
    query warehouseFilter($name: String, $warehouseType: WarehouseType, $enabled: Boolean, $sort: GraphQLSortInput, $pageable: GraphQLPageableInput!) {
  warehouseFilter(
    name: {pattern: $name}
    warehouseType: {value: $warehouseType}
    enabled: {value: $enabled}
    page: $pageable
    sort: $sort
  ) {
    data {
      id
      cls
      warehouseType
      name
      slug
      line1
      line2
      city
      state
      postalCode
      country
      latitude
      longitude
      enabled
    }
    count
    page
    pageSize
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseFilterGQL extends Apollo.Query<WarehouseFilterQuery, WarehouseFilterQueryVariables> {
    document = WarehouseFilterDocument;
    
  }
export const WarehouseInfoDocument = gql`
    query warehouseInfo($id: UUID!) {
  warehouseInfo(id: $id) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseInfoGQL extends Apollo.Query<WarehouseInfoQuery, WarehouseInfoQueryVariables> {
    document = WarehouseInfoDocument;
    
  }
export const WarehouseListDocument = gql`
    query warehouseList {
  warehouseList {
    id
    cls
    warehouseType
    name
    slug
    line1
    line2
    city
    state
    postalCode
    country
    latitude
    longitude
    enabled
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseListGQL extends Apollo.Query<WarehouseListQuery, WarehouseListQueryVariables> {
    document = WarehouseListDocument;
    
  }
export const WarehouseRemoveCarriersDocument = gql`
    mutation warehouseRemoveCarriers($id: UUID!, $carriers: [Carrier]!) {
  warehouseRemoveCarriers(id: $id, carriers: $carriers) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseRemoveCarriersGQL extends Apollo.Mutation<WarehouseRemoveCarriersMutation, WarehouseRemoveCarriersMutationVariables> {
    document = WarehouseRemoveCarriersDocument;
    
  }
export const WarehouseRemoveQueuesDocument = gql`
    mutation warehouseRemoveQueues($id: UUID!, $queueIds: [UUID]!) {
  warehouseRemoveQueues(id: $id, queueIds: $queueIds) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseRemoveQueuesGQL extends Apollo.Mutation<WarehouseRemoveQueuesMutation, WarehouseRemoveQueuesMutationVariables> {
    document = WarehouseRemoveQueuesDocument;
    
  }
export const WarehouseRemoveZonesDocument = gql`
    mutation warehouseRemoveZones($id: UUID!, $zoneIds: [UUID]!) {
  warehouseRemoveZones(id: $id, zoneIds: $zoneIds) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseRemoveZonesGQL extends Apollo.Mutation<WarehouseRemoveZonesMutation, WarehouseRemoveZonesMutationVariables> {
    document = WarehouseRemoveZonesDocument;
    
  }
export const WarehouseSetAddressDocument = gql`
    mutation warehouseSetAddress($id: UUID!, $line1: String!, $line2: String, $city: String!, $state: String!, $postalCode: String!, $country: String!) {
  warehouseSetAddress(
    id: $id
    line1: $line1
    line2: $line2
    city: $city
    state: $state
    postalCode: $postalCode
    country: $country
  ) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseSetAddressGQL extends Apollo.Mutation<WarehouseSetAddressMutation, WarehouseSetAddressMutationVariables> {
    document = WarehouseSetAddressDocument;
    
  }
export const WarehouseSetAlternateSlugDocument = gql`
    mutation warehouseSetAlternateSlug($id: UUID!, $alternateSlug: String) {
  warehouseSetAlternateSlug(id: $id, alternateSlug: $alternateSlug) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseSetAlternateSlugGQL extends Apollo.Mutation<WarehouseSetAlternateSlugMutation, WarehouseSetAlternateSlugMutationVariables> {
    document = WarehouseSetAlternateSlugDocument;
    
  }
export const WarehouseSetDropshipperDocument = gql`
    mutation warehouseSetDropshipper($id: UUID!, $partnerId: UUID) {
  warehouseSetDropshipper(id: $id, partnerId: $partnerId) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseSetDropshipperGQL extends Apollo.Mutation<WarehouseSetDropshipperMutation, WarehouseSetDropshipperMutationVariables> {
    document = WarehouseSetDropshipperDocument;
    
  }
export const WarehouseSetEnabledDocument = gql`
    mutation warehouseSetEnabled($id: UUID!, $enabled: Boolean!) {
  warehouseSetEnabled(id: $id, enabled: $enabled) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseSetEnabledGQL extends Apollo.Mutation<WarehouseSetEnabledMutation, WarehouseSetEnabledMutationVariables> {
    document = WarehouseSetEnabledDocument;
    
  }
export const WarehouseSetLatitudeDocument = gql`
    mutation warehouseSetLatitude($id: UUID!, $latitude: Float) {
  warehouseSetLatitude(id: $id, latitude: $latitude) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseSetLatitudeGQL extends Apollo.Mutation<WarehouseSetLatitudeMutation, WarehouseSetLatitudeMutationVariables> {
    document = WarehouseSetLatitudeDocument;
    
  }
export const WarehouseSetLongitudeDocument = gql`
    mutation warehouseSetLongitude($id: UUID!, $longitude: Float) {
  warehouseSetLongitude(id: $id, longitude: $longitude) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseSetLongitudeGQL extends Apollo.Mutation<WarehouseSetLongitudeMutation, WarehouseSetLongitudeMutationVariables> {
    document = WarehouseSetLongitudeDocument;
    
  }
export const WarehouseSetNameDocument = gql`
    mutation warehouseSetName($id: UUID!, $name: String!) {
  warehouseSetName(id: $id, name: $name) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseSetNameGQL extends Apollo.Mutation<WarehouseSetNameMutation, WarehouseSetNameMutationVariables> {
    document = WarehouseSetNameDocument;
    
  }
export const WarehouseSetSlugDocument = gql`
    mutation warehouseSetSlug($id: UUID!, $slug: String!) {
  warehouseSetSlug(id: $id, slug: $slug) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseSetSlugGQL extends Apollo.Mutation<WarehouseSetSlugMutation, WarehouseSetSlugMutationVariables> {
    document = WarehouseSetSlugDocument;
    
  }
export const WarehouseSetWarehouseTypeDocument = gql`
    mutation warehouseSetWarehouseType($id: UUID!, $warehouseType: WarehouseType!) {
  warehouseSetWarehouseType(id: $id, warehouseType: $warehouseType) {
    ...WAREHOUSE
  }
}
    ${WarehouseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WarehouseSetWarehouseTypeGQL extends Apollo.Mutation<WarehouseSetWarehouseTypeMutation, WarehouseSetWarehouseTypeMutationVariables> {
    document = WarehouseSetWarehouseTypeDocument;
    
  }
export const ZoneListDocument = gql`
    query zoneList {
  zoneList {
    id
    name
    warehouse {
      id
      name
      line1
      line2
      city
      state
      postalCode
      country
      latitude
      longitude
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ZoneListGQL extends Apollo.Query<ZoneListQuery, ZoneListQueryVariables> {
    document = ZoneListDocument;
    
  }
export const ZoneUpdateDocument = gql`
    mutation zoneUpdate($id: UUID!, $name: String!, $slug: String!, $sequence: Long) {
  zoneUpdate(id: $id, name: $name, slug: $slug, sequence: $sequence) {
    id
    name
    slug
    sequence
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ZoneUpdateGQL extends Apollo.Mutation<ZoneUpdateMutation, ZoneUpdateMutationVariables> {
    document = ZoneUpdateDocument;
    
  }