import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { fromEvent } from 'rxjs';
import { PlatformService } from './platform.service';

@Injectable({ providedIn: 'root' })
export class DeactivateGuardService implements CanDeactivate<any> {
  canDeactivateCheck: (event?: BeforeUnloadEvent) => boolean;

  constructor(private platformService: PlatformService) {
    if (platformService.isBrowser) {
      // Check for browser events such as refreshing, exiting, changing url, etc...
      fromEvent(window, 'beforeunload')
        .subscribe((event: BeforeUnloadEvent) => {
          if (this.canDeactivateCheck && !this.canDeactivateCheck(event)) {
            event.preventDefault();
            event.returnValue = 'Changes you made may not be saved.';
          }
        });
    }
  }

  canDeactivate(): boolean {
    if (this.canDeactivateCheck) {
      return this.canDeactivateCheck();
    } else {
      return true;
    }
  }
}
